import { TypographyOptions } from "@mui/material/styles/createTypography";

const fontFamily = '"Noto Sans", "Helvetica", "Tahoma", sans-serif';

const typography: TypographyOptions = {
  fontFamily,
  h1: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "44px",
    lineHeight: "120%",
  },
  h2: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "36px",
    lineHeight: "44px",
  },
  h3: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "28px",
    lineHeight: "124%",
  },
  h4: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "132%",
  },
  h5: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "132%",
  },
  h6: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "132%",
  },
  subtitle2: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.1px",
  },
  body1: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.15px",
  },
  body2: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.15px",
  },
  body3: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "116%",
  },
  link: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.15px",
    textDecorationLine: "underline",
    cursor: "pointer",
  },
  regular: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
  },
  title: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "26px",
  },
  subText: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
  },
  tooltip: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "116%",
  },
  input: {
    fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "100%",
    letterSpacing: "0.4px",
  },
};

export default typography;
