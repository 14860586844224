import { getLogger } from "logger/appLogger";
import { isDebugLogger } from "logger/logger";
import { formatBytes } from "utils/utils";

const logger = getLogger("memory");

let _first: number = 0;
let _last: number = 0;

let _interval: any;
let _backoff: any;

const _tickPeriodMs = 10000;
const _initialBackoffMs = 20000;

let _isDebugLoggerLast: boolean;

const getHeapUsage = () => {
  // @ts-ignore
  return performance.memory.usedJSHeapSize;
};

const haveHeapUsage = () => {
  // @ts-ignore
  return !!performance?.memory?.usedJSHeapSize;
};

const tick = (final: boolean = false) => {
  const current = getHeapUsage();

  // reset values (reference heap value) on toggle false=>true
  const isDebugLoggerCurrent = isDebugLogger(logger);
  const differ = _isDebugLoggerLast !== isDebugLoggerCurrent;
  _isDebugLoggerLast = isDebugLoggerCurrent;
  const toggleToTrue = differ && _isDebugLoggerLast;

  if (!_first || toggleToTrue) {
    _first = current;
    _last = 0;
    logger.debug(`current heap usage; diff to initial heap size; diff to previous value;`);
  }

  const d1 = current - _first;
  const dl = current - _last;
  _last = current;
  logger[final ? "info" : "debug"](`${formatBytes(current)}; ${formatBytes(d1)}; ${formatBytes(dl)};`);
};

export const startMemoryMonitor = () => {
  if (haveHeapUsage()) {
    if (!_backoff && !_interval) {
      _backoff = setTimeout(_startMemoryMonitor, _initialBackoffMs);
      logger.info(
        `Started periodic run with interval ${_tickPeriodMs / 1000}sec and backoff ${
          _initialBackoffMs / 1000
        }sec (dbg=${isDebugLogger(logger)})`
      );
    }
  } else {
    logger.warn("Performance memory statistics not available on this browser");
  }
};

export const _startMemoryMonitor = () => {
  if (!_interval) {
    _interval = setInterval(() => {
      try {
        tick();
      } catch (err) {
        logger.error(err);
      }
    }, _tickPeriodMs);
  }
  _backoff = null;
};

export const stopMemoryMonitor = () => {
  if (_backoff) {
    clearTimeout(_backoff);
    _backoff = null;
  }
  if (_interval) {
    clearInterval(_interval);
    _interval = null;
    tick(true);
  }
};
