import { useEffect } from "react";
import Avatar from "components/_shared/Avatar";
import { CallMediaType } from "types/UC";
import { CustomStyleToExtStream } from "services/CustomRenderer";
import { ParticipantBottomInfo } from "./ParticipantBottomInfo";

interface DefaultProps {
  tile: any;
  index: number;
  in169: boolean;
}

export const RemoteView = ({ tile, index, in169 }: DefaultProps) => {
  const audioTile = tile?.type === CallMediaType.audio;
  const { AssignViewToExtStream } = CustomStyleToExtStream();

  useEffect(() => {
    if (tile.isMatrixTile) {
      AssignViewToExtStream(tile.id, tile.isSelfView, tile.type);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tile.isMatrixTile]);

  return (
    <div
      id={`${tile.id}_${tile.type}`}
      className={`participant-tile ${tile.active && "active"} ${tile.type} participant${index} ${
        in169 ? "participant-tile-aspect-ratio-16-9" : ""
      }`}
    >
      {(audioTile || tile.isMatrixTile) && (
        <>
          <div id="p2p_audio_container_remote" className={`audio-tile ${!audioTile ? " audio-tile-muted" : ""}`}>
            <Avatar
              name={tile.name}
              src={tile.avatarUrl}
              disableBadge
              size="70"
              status={`${tile.isMatrixTile ? "call" : "conf"}`}
            />
          </div>
          <ParticipantBottomInfo name={tile.name || ""} />
        </>
      )}
    </div>
  );
};
