import { call, put, takeLatest, select } from "redux-saga/effects";
import * as actionTypes from "../actions/types/sip";
import sipUser from "services/SIPProvider/SIPProvider";
import { presenceActions } from "../actions/presence";
import { sipActions } from "../actions/sip";
import { navigateTo } from "utils/helpers";
import {
  searchPbxUserDetails,
  subscribeToPbxEvents,
  unSubscribeFromPbxEvents,
  isGroupCalStateConnected,
} from "../sagas/pbx";
import { fetchPresenceStatus } from "../sagas/presence";
import { CallMediaType } from "types/UC";
import { localStorageKeys } from "utils/constants";
import { createSipDisplayName } from "utils/helpers";
import { PbxEventListenerType } from "services/PBXServerApi/types/EventListener";
import { getLogger } from "logger/appLogger";
import { isEmpty } from "utils/helpers";

const log = getLogger("sip.saga");

function* getAvatar(matrixUser) {
  // async fetch of avatar and status info
  const { home_server } = JSON.parse(localStorage.getItem(localStorageKeys.CURRENT_USER) || "");
  const userId = `@${matrixUser}:${home_server}`;
  const presenceStatus = yield call(fetchPresenceStatus, { userId });
  return presenceStatus?.data?.resources?.im?.avatarUrl;
}

function* startSipUA(action) {
  const msg = "startSipUA";
  log.debug(`${msg}`);
  try {
    const { hostedPbxEnabled } = action.payload;
    if (!hostedPbxEnabled) {
      yield put({
        type: actionTypes.START_SIP_UA_FAILED,
        error: {
          action: actionTypes.START_SIP_UA,
          message: "Sip disabled",
        },
      });
    } else {
      yield sipUser.start(action.payload);

      const { userExtension, displayName } = action.payload?.userAgentOptions;
      const localPeer = yield call(fetchUserInfo, userExtension);

      if (isEmpty(localPeer.name)) {
        localPeer.name = displayName;
      }

      yield put({
        type: actionTypes.START_SIP_UA_SUCCEEDED,
        localPeer,
      });
    }
  } catch (e) {
    yield put({
      type: actionTypes.START_SIP_UA_FAILED,
      error: {
        action: actionTypes.START_SIP_UA,
        message: e.message,
      },
    });
    log.error(`${msg}: ${e}`);
  } finally {
    log.debug(`${msg} done`);
  }
}

function* reStartSipUA() {
  const msg = "reStartSipUA";
  log.debug(`${msg}`);
  try {
    yield sipUser.restart();

    const userAgentOptions = sipUser.getOptions().userAgentOptions;
    const { userExtension, displayName } = userAgentOptions;
    const localPeer = yield call(fetchUserInfo, userExtension);
    if (displayName) {
      localPeer.name = displayName;
    }
    yield put({
      type: actionTypes.START_SIP_UA_SUCCEEDED,
      localPeer,
    });
  } catch (e) {
    yield put({
      type: actionTypes.START_SIP_UA_FAILED,
      error: {
        action: actionTypes.START_SIP_UA,
        message: e.message,
      },
    });
    log.error(`${msg}: ${e}`);
  } finally {
    log.debug(`${msg} done`);
  }
}

function* stopSipUA() {
  const msg = "stopSipUA";
  log.debug(`${msg}`);
  try {
    yield sipUser.stop();
    localStorage.removeItem(localStorageKeys.PROVISIONING_CONFIG);
    yield put({
      type: actionTypes.STOP_SIP_UA_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.STOP_SIP_UA_FAILED,
      error: {
        action: actionTypes.STOP_SIP_UA,
        message: e.message,
      },
    });
    log.error(`${msg}: ${e}`);
  } finally {
    log.debug(`${msg} done`);
  }
}

function* acceptCallInvite(action) {
  const msg = "acceptCallInvite";
  log.debug(`${msg}`);
  try {
    const { callInfo } = action;
    yield call(navigateTo, "dialpad");
    yield sipUser.acceptCallInvitation(CallMediaType.audio, callInfo.id);

    // checks if already exists info about the user requested to pbx
    const incomingSipCalls = yield select((state) => state.sip.incomingSipCalls);
    const incomingSipCall = incomingSipCalls.find((call) => call.id === callInfo.id);
    if (!incomingSipCall) {
      yield put(sipActions.updateSipCallInfo(callInfo.id, callInfo.peer.user, callInfo.peer.name));
    } else {
      // gets and use the user info from the incomingSipCall
      sipUser.updateSipCallInfo(incomingSipCall.id, incomingSipCall.peer, null);
    }

    // update presence
    yield put(
      presenceActions.changePresence({
        transitionName: "incoming-call",
      })
    );

    yield put({
      type: actionTypes.ACCEPT_SIP_CALL_SUCCEEDED,
      callId: callInfo.id,
    });
  } catch (e) {
    yield put({
      type: actionTypes.SIP_CALL_ACTION_FAILED,
      error: {
        action: actionTypes.ACCEPT_SIP_CALL,
        message: e.message,
      },
    });
    log.error(`${msg}: ${e}`);
  } finally {
    log.debug(`${msg} done`);
  }
}

function* rejectCallInvite(action) {
  const msg = "rejectCallInvite";
  log.debug(`${msg}`);
  try {
    const { callId } = action;
    yield sipUser.rejectCallInvitation(callId);
    yield put({
      type: actionTypes.REJECT_SIP_CALL_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.SIP_CALL_ACTION_FAILED,
      error: {
        action: actionTypes.REJECT_SIP_CALL,
        message: e.message,
      },
    });
    log.error(`${msg}: ${e}`);
  } finally {
    log.debug(`${msg} done`);
  }
}

function* startSipCall(action) {
  const msg = "startSipCall";
  log.debug(`${msg} action=${JSON.stringify(action)}`);
  try {
    const { sipCallNumber, sipCallType, sipCallDisplayName } = action.payload;

    // initiate call
    const callId = yield sipUser.initiateOutgoingCallSession(sipCallNumber, sipCallDisplayName, sipCallType);

    // get sip user name from pbx asynchronously
    yield put(sipActions.updateSipCallInfo(callId, sipCallNumber, sipCallDisplayName));

    // update presence
    yield put(
      presenceActions.changePresence({
        transitionName: "outgoing-call",
      })
    );

    yield put({
      type: actionTypes.START_SIP_CALL_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.START_SIP_CALL_FAILED,
      error: {
        action: actionTypes.START_SIP_CALL,
        message: e.message,
      },
    });
    log.error(`${msg}: ${e}`);
  } finally {
    log.debug(`${msg} done`);
  }
}

function* updateSipCallInfo(action) {
  try {
    const { callId, user, name } = action;
    const extensionWithDomain = `${user}@${sipUser.domain}`;
    let displayName = name;
    let matrixUser = null;
    let avatarUrl = "";

    // fetch sip-user display name, do this first, don't keep an incoming call waiting
    const pbxResponse = yield call(searchPbxUserDetails, extensionWithDomain);
    if (pbxResponse) {
      const { username, firstName, lastName, isGroup } = pbxResponse;
      displayName = createSipDisplayName(firstName, lastName);
      matrixUser = username;

      // if peer is a group, subscribe and switch to special ringing-connected mode
      if (isGroup) {
        yield call(updateSipStateInfo, { callId });
      }
    }

    sipUser.updateSipCallInfo(callId, { user, name: displayName, matrixUser, avatarUrl }, null);

    if (matrixUser) {
      // async fetch of avatar and status info
      avatarUrl = yield getAvatar(matrixUser);
      if (avatarUrl) {
        sipUser.updateSipCallInfo(callId, { user, name: displayName, matrixUser, avatarUrl }, null);
      }
    }
  } catch (e) {
    yield put({
      type: actionTypes.SIP_CALL_ACTION_FAILED,
      error: {
        action: actionTypes.UPDATE_SIP_CALL_INFO,
        message: e.message,
      },
    });
  }
}

function* updateSipStateInfo(action) {
  const msg = "updateSipStateInfo";
  log.debug(`${msg} action=${JSON.stringify(action)}`);
  try {
    const { callId, user } = action;

    // poll to check if we're already connected
    let { connected, agent } = yield call(isGroupCalStateConnected, callId);
    if (connected) {
      log.debug(`${msg} call ${callId} already connected`);
    }
    const groupCallBeforeConnect = !connected;

    const changed = yield sipUser.changeGroupCallState(callId, groupCallBeforeConnect);
    if (changed) {
      if (groupCallBeforeConnect) {
        // subscribe to group-connect
        const subscriptionId = yield call(subscribeToPbxEvents, PbxEventListenerType.Call, callId);
        yield sipUser.setSubscription(callId, subscriptionId);
      } else {
        // we have received clear group-connect, unsubscribe
        const subscriptionId = sipUser.getSubscription(action.callId);
        if (subscriptionId) {
          yield call(unSubscribeFromPbxEvents, subscriptionId);
          sipUser.setSubscription(action.callId, null);
        }
      }
    }

    if (isEmpty(agent)) {
      agent = user;
    }
    if (!isEmpty(agent)) {
      yield put(sipActions.updateSipCallInfo(callId, agent));
    }
  } catch (e) {
    yield put({
      type: actionTypes.SIP_CALL_ACTION_FAILED,
      error: {
        action: actionTypes.UPDATE_SIP_STATE_INFO,
        message: e.message,
      },
    });
    log.error(`${msg}: ${e}`);
  } finally {
    log.debug(`${msg} done`);
  }
}

function* updateSipConferenceInfo(action) {
  try {
    let { callId, users, conferenceId, owner, userJoining, userLeaving, conferenceName, resetChannel } = action;

    // ConferenceInitialize
    if (users?.length > 0) {
      if (!callId) {
        callId = sipUser.attachConferencelId(null, owner, conferenceId);
      }
      if (!callId) {
        throw new Error(`Can not determine callId according by owner=${owner} conferenceId=${conferenceId}`);
      }

      let usersExt = [];
      for (let user of users) {
        const peer = yield call(fetchUserInfo, user);
        usersExt.push(peer);
      }
      const conferencePeer = conferenceName ? { user: conferenceId, name: conferenceName } : null;
      sipUser.updateSipCallInfo(callId, conferencePeer, usersExt);
    }

    // ConferenceJoin
    if (userJoining) {
      if (!conferenceId) {
        throw new Error(`ConferenceId undefined`);
      }
      const peer = yield call(fetchUserInfo, userJoining);
      sipUser.addConferenceCallUser(conferenceId, peer);
    }

    // ConferenceLeave
    if (userLeaving) {
      if (!conferenceId) {
        throw new Error(`ConferenceId undefined`);
      }
      sipUser.removeConferenceCallUser(conferenceId, userLeaving);
    }

    // Reset channel
    if (resetChannel) {
      sipUser.reInviteCallSession(callId, CallMediaType.audio);
    }
  } catch (e) {
    yield put({
      type: actionTypes.SIP_CALL_ACTION_FAILED,
      error: {
        action: actionTypes.UPDATE_SIP_CONFERENCE_INFO,
        message: e.message,
      },
    });
  }
}

function* fetchUserInfo(user) {
  const peer = { user, name: user, matrixUser: null, avatarUrl: null };
  const extensionWithDomain = `${user}@${sipUser.domain}`;
  const pbxResponse = yield call(searchPbxUserDetails, extensionWithDomain);
  if (pbxResponse) {
    const { username, firstName, lastName } = pbxResponse;
    peer.name = createSipDisplayName(firstName, lastName);
    peer.matrixUser = username;
  }
  if (peer.matrixUser) {
    peer.avatarUrl = yield getAvatar(peer.matrixUser);
  }
  return peer;
}

function* endSipCall(action) {
  const msg = "endSipCall";
  log.debug(`${msg}`);
  try {
    if (!action.callId) {
      action.callId = sipUser.getActiveCallId();
    }
    if (!action.callId) {
      throw new Error("endSipCall: no active call");
    }

    const subscriptionId = sipUser.getSubscription(action.callId);
    if (subscriptionId) {
      yield call(unSubscribeFromPbxEvents, subscriptionId);
      sipUser.setSubscription(action.callId, null);
    }

    yield sipUser.endCallSession(action.callId);

    // if only us in the call-list => we'll be idle soon
    const callIds = sipUser.getAllCallIds();
    if (callIds.length === 0 || (callIds.length === 1 && callIds[0] === action.callId)) {
      yield put(
        presenceActions.changePresence({
          transitionName: "exit-call",
        })
      );
    }

    yield put({
      type: actionTypes.END_SIP_CALL_SUCCEEDED,
      callId: action.callId,
    });
  } catch (e) {
    yield put({
      type: actionTypes.SIP_CALL_ACTION_FAILED,
      error: {
        action: actionTypes.END_SIP_CALL,
        message: e.message,
      },
    });
    log.error(`${msg}: ${e}`);
  } finally {
    log.debug(`${msg} done`);
  }
}

function* getSipCallInfo(action) {
  try {
    const { callId, user, viaUser } = action;

    // get sip user name from pbx asynchronously
    const peer = yield call(fetchUserInfo, user);
    const via = !isEmpty(viaUser) ? yield call(fetchUserInfo, viaUser) : null;

    // update sip-calls
    yield sipUser.updateSipCallInfo(callId, peer, null);

    // update ringing information
    yield put({
      type: actionTypes.GET_SIP_CALL_INFO_SUCCEEDED,
      incomingSipCalls: {
        id: callId,
        peer,
        via,
      },
    });
  } catch (e) {
    yield put({
      type: actionTypes.SIP_CALL_ACTION_FAILED,
      error: {
        action: actionTypes.GET_SIP_CALL_INFO,
        message: e.message,
      },
    });
  }
}

function* actionWatcher() {
  yield takeLatest(actionTypes.START_SIP_UA, startSipUA);
  yield takeLatest(actionTypes.RESTART_SIP_UA, reStartSipUA);
  yield takeLatest(actionTypes.STOP_SIP_UA, stopSipUA);
  yield takeLatest(actionTypes.ACCEPT_SIP_CALL, acceptCallInvite);
  yield takeLatest(actionTypes.REJECT_SIP_CALL, rejectCallInvite);
  yield takeLatest(actionTypes.START_SIP_CALL, startSipCall);
  yield takeLatest(actionTypes.UPDATE_SIP_CALL_INFO, updateSipCallInfo);
  yield takeLatest(actionTypes.UPDATE_SIP_STATE_INFO, updateSipStateInfo);
  yield takeLatest(actionTypes.UPDATE_SIP_CONFERENCE_INFO, updateSipConferenceInfo);
  yield takeLatest(actionTypes.END_SIP_CALL, endSipCall);
  yield takeLatest(actionTypes.GET_SIP_CALL_INFO, getSipCallInfo);
}

export default actionWatcher;
