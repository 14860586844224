import accent from "./accent";

const brand = {
  primary: {
    light: "#003594",
    dark: accent.accentBP2,
  },
  secondary: {
    light: "#6AB8D4",
    dark: accent.accentBS3,
  },
};

export type Brand = typeof brand;

export default brand;
