export const START_CALL = "CALL::START_CALL";
export const START_CALL_SUCCEEDED = "CALL::START_CALL_SUCCEEDED";
export const START_CALL_FAILED = "CALL::START_CALL_FAILED";

export const END_CALL = "CALL::END_CALL";
export const END_CALL_SUCCEEDED = "CALL::END_CALL_SUCCEEDED";
export const END_CALL_FAILED = "CALL::END_CALL_FAILED";

export const REJOIN_CALL = "CALL::REJOIN_CALL";
export const SET_CALL_ROOM_ID = "CALL::SET_CALL_ROOM_ID";
export const OUT_CALL = "CALL::OUT_CALL";
export const MEETING_NAME = "CALL::MEETING_NAME";
export const CALL_IN_FULL_SCREEN = "CALL::CALL_IN_FULL_SCREEN";

export const RECORDER_STATUS_CHANGES_SUBSCRIBE = "CALL::RECORDER_STATUS_CHANGES_SUBSCRIBE";
export const RECORDER_STATUS_CHANGES_SUBSCRIBE_SUCCEEDED = "CALL::RECORDER_STATUS_CHANGES_SUBSCRIBE_SUCCEEDED";
export const RECORDER_STATUS_CHANGES_SUBSCRIBE_FAILED = "CALL::RECORDER_STATUS_CHANGES_SUBSCRIBE_FAILED";

export const RECORDER_STATUS_CHANGES_UNSUBSCRIBE = "CALL::RECORDER_STATUS_CHANGES_UNSUBSCRIBE";
export const RECORDER_STATUS_CHANGES_UNSUBSCRIBE_SUCCEEDED = "CALL::RECORDER_STATUS_CHANGES_UNSUBSCRIBE_SUCCEEDED";
export const RECORDER_STATUS_CHANGES_UNSUBSCRIBE_FAILED = "CALL::RECORDER_STATUS_CHANGES_UNSUBSCRIBE_FAILED";

export const MODERATION_STATUS_CHANGES_SUBSCRIBE = "CALL::MODERATION_STATUS_CHANGES_SUBSCRIBE";
export const MODERATION_STATUS_CHANGES_SUBSCRIBE_SUCCEEDED = "CALL::MODERATION_STATUS_CHANGES_SUBSCRIBE_SUCCEEDED";
export const MODERATION_STATUS_CHANGES_SUBSCRIBE_FAILED = "CALL::MODERATION_STATUS_CHANGES_SUBSCRIBE_FAILED";

export const MODERATION_STATUS_CHANGES_UNSUBSCRIBE = "CALL::MODERATION_STATUS_CHANGES_UNSUBSCRIBE";
export const MODERATION_STATUS_CHANGES_UNSUBSCRIBE_SUCCEEDED = "CALL::MODERATION_STATUS_CHANGES_UNSUBSCRIBE_SUCCEEDED";
export const MODERATION_STATUS_CHANGES_UNSUBSCRIBE_FAILED = "CALL::MODERATION_STATUS_CHANGES_UNSUBSCRIBE_FAILED";

export const PARTICIPANTS_CHANGES_SUBSCRIBE = "CALL::PARTICIPANTS_CHANGES_SUBSCRIBE";
export const PARTICIPANTS_CHANGES_SUBSCRIBE_SUCCEEDED = "CALL::PARTICIPANTS_CHANGES_SUBSCRIBE_SUCCEEDED";
export const PARTICIPANTS_CHANGES_SUBSCRIBE_FAILED = "CALL::PARTICIPANTS_CHANGES_SUBSCRIBE_FAILED";

export const PARTICIPANTS_CHANGES_UNSUBSCRIBE = "CALL::PARTICIPANTS_CHANGES_UNSUBSCRIBE";
export const PARTICIPANTS_CHANGES_UNSUBSCRIBE_SUCCEEDED = "CALL::PARTICIPANTS_CHANGES_UNSUBSCRIBE_SUCCEEDED";
export const PARTICIPANTS_CHANGES_UNSUBSCRIBE_FAILED = "CALL::PARTICIPANTS_CHANGES_UNSUBSCRIBE_FAILED";

export const UPDATE_PARTICIPANTS = "CALL::UPDATE_PARTICIPANTS";
export const UPDATE_RECORDER_STATUS = "CALL::UPDATE_RECORDER_STATUS";
export const UPDATE_MODERATION_STATUS = "CALL::UPDATE_MODERATION_STATUS";
export const UPDATE_MODERATION_ACTION = "CALL::UPDATE_MODERATION_ACTION";

export const ASSIGN_VIDEO_RENDERER = "CALL::ASSIGN_VIDEO_RENDERER";
export const ASSIGN_VIDEO_RENDERER_SUCCEEDED = "CALL::ASSIGN_VIDEO_RENDERER_SUCCEEDED";
export const ASSIGN_VIDEO_RENDERER_FAILED = "CALL::ASSIGN_VIDEO_RENDERER_FAILED";

export const LOCAL_WINDOW_SHARE_CHANGES_SUBSCRIBE = "CALL::LOCAL_WINDOW_SHARE_CHANGES_SUBSCRIBE";
export const LOCAL_WINDOW_SHARE_CHANGES_SUBSCRIBE_SUCCEEDED = "CALL::LOCAL_WINDOW_SHARE_CHANGES_SUBSCRIBE_SUCCEEDED";
export const LOCAL_WINDOW_SHARE_CHANGES_SUBSCRIBE_FAILED = "CALL::LOCAL_WINDOW_SHARE_CHANGES_SUBSCRIBE_FAILED";

export const LOCAL_WINDOW_SHARE_CHANGES_UNSUBSCRIBE = "CALL::LOCAL_WINDOW_SHARE_CHANGES_UNSUBSCRIBE";
export const LOCAL_WINDOW_SHARE_CHANGES_UNSUBSCRIBE_SUCCEEDED =
  "CALL::LOCAL_WINDOW_SHARE_CHANGES_UNSUBSCRIBE_SUCCEEDED";
export const LOCAL_WINDOW_SHARE_CHANGES_UNSUBSCRIBE_FAILED = "CALL::LOCAL_WINDOW_SHARE_CHANGES_UNSUBSCRIBE_FAILED";

export const REMOTE_WINDOW_SHARE_CHANGES_SUBSCRIBE = "CALL::REMOTE_WINDOW_SHARE_CHANGES_SUBSCRIBE";
export const REMOTE_WINDOW_SHARE_CHANGES_SUBSCRIBE_SUCCEEDED = "CALL::REMOTE_WINDOW_SHARE_CHANGES_SUBSCRIBE_SUCCEEDED";
export const REMOTE_WINDOW_SHARE_CHANGES_SUBSCRIBE_FAILED = "CALL::REMOTE_WINDOW_SHARE_CHANGES_SUBSCRIBE_FAILED";

export const REMOTE_WINDOW_SHARE_CHANGES_UNSUBSCRIBE = "CALL::REMOTE_WINDOW_SHARE_CHANGES_UNSUBSCRIBE";
export const REMOTE_WINDOW_SHARE_CHANGES_UNSUBSCRIBE_SUCCEEDED =
  "CALL::REMOTE_WINDOW_SHARE_CHANGES_UNSUBSCRIBE_SUCCEEDED";
export const REMOTE_WINDOW_SHARE_CHANGES_UNSUBSCRIBE_FAILED = "CALL::REMOTE_WINDOW_SHARE_CHANGES_UNSUBSCRIBE_FAILED";

export const LOCAL_WINDOW_SHARES_UPDATE = "CALL::LOCAL_WINDOW_SHARES_UPDATE";
export const REMOTE_WINDOW_SHARES_UPDATE = "CALL::REMOTE_WINDOW_SHARES_UPDATE";

export const WINDOW_SHARE_START = "CALL::WINDOW_SHARE_START";
export const WINDOW_SHARE_START_SUCCEEDED = "CALL::WINDOW_SHARE_START_SUCCEEDED";
export const WINDOW_SHARE_START_FAILED = "CALL::WINDOW_SHARE_START_FAILED";

export const WINDOW_SHARE_STOP = "CALL::WINDOW_SHARE_STOP";
export const WINDOW_SHARE_STOP_SUCCEEDED = "CALL::WINDOW_SHARE_STOP_SUCCEEDED";
export const WINDOW_SHARE_STOP_FAILED = "CALL::WINDOW_SHARE_STOP_FAILED";

export const GET_CALL_PROPERTIES = "CALL::GET_PROPERTIES";
export const GET_CALL_PROPERTIES_SUCCEEDED = "CALL::GET_PROPERTIES_SUCCEEDED";
export const GET_CALL_PROPERTIES_FAILED = "CALL::GET_PROPERTIES_FAILED";

export const UPDATE_PARTICIPANT_INFO = "CALL::UPDATE_PARTICIPANT_INFO";
