import BaseCallAPIProvider from "./BaseCallAPIProvider";
import { throttle } from "throttle-debounce";
import { loadScript } from "utils/loaders.js";
import { getLogger } from "logger/appLogger";

const VCUtils = "./lib/VidyoClientPlugin/VCUtils.js";
const Dispatcher = "./lib/VidyoClientPlugin/VidyoClientDispatcher.js";
const Transport = "./lib/VidyoClientPlugin/VidyoConnectDesktopTransport.js";
const logger = getLogger("vidyo");

class VidyoEndpointDesktopAPI extends BaseCallAPIProvider {
  constructor() {
    if (VidyoEndpointDesktopAPI.instance) {
      return VidyoEndpointDesktopAPI.instance;
    }
    super();

    this.vc = null;
    this.vidyoEndpoint = null;
    this.vidyoUser = null;
    this.vidyoRoom = null;
    this.selfViews = [];
    this.rendererDevices = [];
    this.module = "VidyoEndpointDesktopApi";
  }

  async init() {
    const ref = this;
    logger.log(`${ref.module}: VidyoClient Desktop init`);

    await loadScript(VCUtils);
    await loadScript(Dispatcher);
    await loadScript(Transport);

    this.vc = new window.VidyoClientLib.VidyoClient("VidyoClientPlugIn", (status) => {});

    await this.vc
      .CreateVidyoEndpoint(
        "viewId",
        "viewStyle",
        16,
        "",
        "warning info@VidyoCef info@VidyoCefStats info@VidyoClient info@LmiPortalSession info@LmiPortalMembership info@LmiResourceManagerUpdates info@LmiPace info@LmiIce all@LmiSignaling ",
        "VidyoConnect_common.log"
      )
      .then((vidyoEndpoint) => {
        this.vidyoEndpoint = vidyoEndpoint;
      })
      .then(() => {
        window.vidyoApp.get(`ConstructConferenceScene?e=${this.vidyoEndpoint.objId}`);
      })
      .then(() => {
        return this.startDeviceListener(
          this.vidyoEndpoint.RegisterLocalRendererEventListener,
          ([vidyoRenderer, ...rest]) => {
            this.vidyoRenderer = vidyoRenderer;
          }
        );
      })
      .then(() => {
        return navigator.mediaDevices.enumerateDevices().then((rendererDevices) => {
          this.rendererDevices = rendererDevices.filter((d) => d.kind === "videoinput");
          logger.debug(`${ref.module}: rendererDevices:${this.rendererDevices}`);
        });
      })
      .then(() => {
        return this.vidyoEndpoint.SelectLocalCamera({ localCamera: null });
      })
      .then(() => {
        return this.vidyoEndpoint.SelectLocalMicrophone({
          localMicrophone: null,
        });
      })
      .then(() => {
        return this.vidyoEndpoint.SelectLocalSpeaker({ localSpeaker: null });
      })
      .catch((e) => {
        logger.error(`${ref.module}: Error in init - ${e}`);
      });
  }

  startCall({ renderer, host, roomKey, displayName, onDisconnected } = {}) {
    if (typeof onDisconnected !== "function") {
      onDisconnected = (reason, handler) => {
        logger.debug(`${module}: vidyoEndpoint.Connect : ${handler} callback received with ${reason}`);
      };
    }

    return new Promise((resolve, reject) => {
      this.vidyoEndpoint
        .SetCameraPrivacy({
          privacy: true,
        })
        .then(() => {
          return this.vidyoEndpoint.SetMicrophonePrivacy({
            privacy: true,
          });
        })
        .then(() => {
          return new Promise((resolve, reject) => {
            setTimeout(resolve, 3000);
          });
        })
        .then(() => {
          return this.vidyoEndpoint.AssignViewToCompositeRenderer({
            viewId: "renderer",
          });
        })
        .then(() => {
          return this.vidyoEndpoint.ConnectToRoomAsGuest({
            host,
            roomKey,
            displayName,
            // Define handlers for connection events.
            onSuccess: function () {
              resolve();
            },
            onFailure: function (reason) {
              onDisconnected && onDisconnected(reason, true);
            },
            onDisconnected: function (reason) {
              onDisconnected && onDisconnected(reason, false);
            },
          });
        })
        .then(function (status) {
          if (!status) {
            reject();
          }
        })
        .catch(reject);
    });
  }

  endCall() {
    return this.vidyoEndpoint
      .SetMicrophonePrivacy({
        privacy: true,
      })
      .then(() => {
        return this.vidyoEndpoint.SetCameraPrivacy({
          privacy: true,
        });
      })
      .then(() => {
        return this.vidyoEndpoint.Disconnect();
      });
  }

  toggleCamera(privacy) {
    return this.vidyoEndpoint.SetCameraPrivacy({
      privacy,
    });
  }

  async cameraTurnOn({ selectedCamera }) {
    if (selectedCamera && this.vidyoRenderer) {
      return selectedCamera.AddToLocalRenderer(this.vidyoRenderer).then((streamID) => {
        const rendererId = window.vidyoApp.getRendererId(streamID.toString());
        const vRendererId = `V${rendererId}`;
        const rendererDevice = this.rendererDevices.filter((d) => !d.used)[0];
        rendererDevice.used = true;
        window.vidyoApp.registerVidyoRenderer(vRendererId, rendererDevice.label);
        window.vidyoApp.updateVidyoRendererParameters([
          {
            srcID: vRendererId,
            width: 222,
            height: 118,
            ranking: 0,
            dynamic: false,
            show: true,
            crop: true,
          },
        ]);
        navigator.mediaDevices
          .getUserMedia({
            audio: false,
            video: {
              deviceId: rendererDevice.deviceId,
            },
          })
          .then((stream) => {
            this.selfViews.forEach((svId) => {
              const selfView = document.querySelector(`#${svId} video`);
              selfView.id = vRendererId;
              selfView.srcObject = stream;
              selfView.onloadedmetadata = function (e) {
                selfView.play();
              };
              selfView.rendererDevice = rendererDevice;
            });
          });
      });
    }
  }

  cameraTurnOff({ selectedCamera }) {
    if (selectedCamera && this.vidyoRenderer) {
      this.selfViews.forEach((svId) => {
        const selfView = document.querySelector(`#${svId} video`);
        selfView.removeAttribute("id");
        window.vidyoApp.unregisterVidyoRenderer(selfView.id);
        if (selfView.scrObject) {
          selfView.scrObject.getVideoTracks().forEach((track) => {
            track.stop();
          });
          selfView.srcObject = null;
        }
        if (selfView.rendererDevice) {
          selfView.rendererDevice.used = false;
        }
      });
      return selectedCamera.RemoveFromLocalRenderer(this.vidyoRenderer);
    }
  }

  toggleMicrophone(privacy) {
    return this.vidyoEndpoint.SetMicrophonePrivacy({
      privacy,
    });
  }

  toggleSpeaker(privacy) {
    return this.vidyoEndpoint.SetSpeakerPrivacy({
      privacy,
    });
  }

  microphoneTurnOff() {
    return this.toggleMicrophone(false);
  }

  microphoneTurnOn() {
    return this.toggleMicrophone(true);
  }

  speakerTurnOn() {
    return this.toggleSpeaker(true);
  }

  speakerTurnOff() {
    return this.toggleSpeaker(false);
  }

  async addSelfView({ viewId }) {
    this.selfViews.push(viewId);
    document.getElementById(viewId).innerHTML = `
      <video class="desktop-video-tile" data-ranking="0" autoplay vidyostate="canplay event triggered"></video>
    `;
  }

  async removeSelfView({ viewId }) {
    this.selfViews = this.selfViews.filter((id) => id === viewId);
    document.getElementById(viewId).innerHTML = ``;
  }

  selectCamera(localCamera) {
    return this.vidyoEndpoint.SelectLocalCamera({
      localCamera,
    });
  }

  selectMicrophone(localMicrophone) {
    return this.vidyoEndpoint.SelectLocalMicrophone({
      localMicrophone,
    });
  }

  selectSpeaker(localSpeaker) {
    return this.vidyoEndpoint.SelectLocalSpeaker({
      localSpeaker,
    });
  }

  startDeviceListener(targetListener, onChanged, selectDevice) {
    let devices = [];
    let deviceSelected = false;

    const notify = throttle(500, () => {
      if (this.vidyoEndpoint) {
        onChanged(devices);
      }
    });

    return targetListener.call(this.vidyoEndpoint, {
      onAdded: (device) => {
        if (!device || device.name === "WebPluginVirtualCamera") {
          return;
        }
        devices = [...devices, device];
        notify();
        if (!deviceSelected && selectDevice) {
          deviceSelected = true;
          selectDevice(device);
        }
      },
      onRemoved: (device) => {
        devices = devices.filter((d) => d.objId !== device.objId);
        notify();
      },
      onSelected: (device) => {
        device = devices.map((d) => {
          d.selected = d.objId === device.objId;
          return d;
        });
        notify();
      },
      onStateUpdated: (device, state) => {
        if (!device) {
          return;
        }
        devices = devices.map((d) => {
          if (d.objId === device.objId) {
            switch (state) {
              case "VIDYO_DEVICESTATE_Suspended":
                d.isSuspended = true;
                break;
              case "VIDYO_DEVICESTATE_Unsuspended":
                d.isSuspended = false;
                break;
              case "VIDYO_DEVICESTATE_Controllable":
                d.isControllable = true;
                break;
              case "VIDYO_DEVICESTATE_DefaultChanged":
                d.isDefault = true;
                break;
              case "VIDYO_DEVICESTATE_ConfigureError":
              case "VIDYO_DEVICESTATE_Error":
                d.isFailed = true;
                d.isStarted = false;
                break;
              case "VIDYO_DEVICESTATE_Started":
                d.isFailed = false;
                d.isStarted = true;
                break;
              case "VIDYO_DEVICESTATE_Stopped":
                d.isStarted = false;
                break;
              default:
            }
          }
          return d;
        });
        notify();
      },
    });
  }

  subscribeOnCamerasChanges(onChanged) {
    return this.startDeviceListener(this.vidyoEndpoint.RegisterLocalCameraEventListener, onChanged, (localCamera) => {
      return this.vidyoEndpoint.SelectLocalCamera({
        localCamera,
      });
    });
  }

  unsubscribeFromCamerasChanges() {
    return this.vidyoEndpoint.UnregisterLocalCameraEventListener();
  }

  subscribeOnMicrophonesChanges(onChanged) {
    return this.startDeviceListener(
      this.vidyoEndpoint.RegisterLocalMicrophoneEventListener,
      onChanged,
      (localMicrophone) => {
        return this.vidyoEndpoint.SelectLocalMicrophone({
          localMicrophone,
        });
      }
    );
  }

  unsubscribeFromMicrophonesChanges() {
    return this.vidyoEndpoint.UnregisterLocalMicrophoneEventListener();
  }

  subscribeOnSpeakersChanges(onChanged) {
    return this.startDeviceListener(this.vidyoEndpoint.RegisterLocalSpeakerEventListener, onChanged, (localSpeaker) => {
      return this.vidyoEndpoint.SelectLocalSpeaker({
        localSpeaker,
      });
    });
  }

  unsubscribeFromSpeakersChanges() {
    return this.vidyoEndpoint.UnregisterLocalSpeakerEventListener();
  }
}

export default VidyoEndpointDesktopAPI;
