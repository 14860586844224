import React from "react";
import { useTheme } from "@mui/material/styles";
import "./CallingAnimation.scss";
import { test } from "utils/helpers";

const CallingAnimation = ({ content }: { content: React.ReactElement }) => {
  const theme = useTheme();
  return (
    <div className="content-center" {...test("CALLING-ANIMATION")}>
      <div className="pulse" style={{ backgroundColor: theme.palette.brand.secondary.light }}>
        {" "}
        {content}{" "}
      </div>
    </div>
  );
};

export default CallingAnimation;
