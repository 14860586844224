import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { selectMicrophone } from "store/actions/devices";
import EnergyLevel from "components/EnergyLevel/EnergyLevel";
import { test, sortDevices } from "utils/helpers";
import { useSystemDefaultName } from "hooks/useLanguage";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import MenuItem from "@mui/material/MenuItem";
import GeneralSelectList from "components/GeneralSelectList/GeneralSelectList";
import { RootState } from "store/root-reducer";
import { SelectChangeEvent } from "@mui/material/Select";
import { Typography } from "@mui/material";
import { IconButton } from "components";

const MicrophoneSelectList = ({ ...props }) => {
  const selectedMicrophone = useSelector((state: RootState) => state.devices.selectedMicrophone);
  const microphones = useSelector((state: RootState) => state.devices.microphones);
  const sortedMicrophones = sortDevices(microphones);
  const processName = useSystemDefaultName();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showEnergyLevel, setShowEnergyLevel] = useState(false);
  const showTitle = props.showTitle !== undefined ? props.showTitle : true;

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const localMicrophone = event.target.value;
    if (localMicrophone) {
      dispatch(selectMicrophone(localMicrophone));
    }
    setShowEnergyLevel(false);
  };

  const getItems = () => {
    if (sortedMicrophones && sortedMicrophones.length > 0) {
      const result = sortedMicrophones.map((micro, index) => {
        const name = processName(micro);
        return (
          <MenuItem key={index + 1} value={micro} {...test("SELECT_MICROPHONE_ITEM")}>
            {name}
          </MenuItem>
        );
      });
      return result;
    }
  };

  const value = sortedMicrophones && sortedMicrophones.length > 0 ? (selectedMicrophone ? selectedMicrophone : "") : "";

  return (
    <div className="device-select-container">
      <GeneralSelectList
        id="microphone-select"
        title={showTitle ? t("MICROPHONE") : null}
        items={getItems()}
        onItemSelect={handleSelectChange}
        noResultsText={t("NO_MICROPHONE")}
        noSelectText={t("NO_ACTIVE_MICROPHONE")}
        selectedItemName={value}
        tooltip={processName(value)}
        {...test("SELECT_MICROPHONE")}
      />
      <div className="test-sound-container" {...test("TEST_MICROPHONE")}>
        <IconButton
          onClick={() => setShowEnergyLevel((prev) => !prev)}
          sx={{ marginRight: `${showEnergyLevel ? "5px" : ""}` }}
        >
          {showEnergyLevel ? <StopIcon /> : <PlayArrowIcon />}
        </IconButton>
        {showEnergyLevel ? (
          <EnergyLevel microphoneId={selectedMicrophone && selectedMicrophone.id} asIcon={false} isDisabled={false} />
        ) : (
          <Typography variant="regular">{t("TEST_MICROPHONE")}</Typography>
        )}
      </div>
    </div>
  );
};

export default MicrophoneSelectList;
