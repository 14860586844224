import React, { useState, useEffect, useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { getLogger } from "logger/appLogger";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import Badge from "@mui/material/Badge";
import styles from "./SideBarRoom.module.scss";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getStatusMessage } from "services/PresenceProvider/PresenceProvider";
import { isEmpty, isValidDisplayName, test } from "utils/helpers";

import { JoinLeaveRoomDialog } from "components";
import AvatarWithLabels from "components/_shared/AvatarWithLabels";
import { useMatrixAvatar, useMatrixRooms, useRouter } from "hooks";
import { useAppSelector } from "store/hooks";
import { getLocalRoom } from "store/selectors";

const StyledRoomDiv = styled("div")(({ theme }) => ({
  "&:hover": {
    color: theme.palette.icon.navLink.selected,
    backgroundColor: theme.palette.background.navLink.hover,
  },
}));

interface SideBarRoomProps {
  name: string;
  roomId: string;
}

const SideBarRoom = ({ roomId }: SideBarRoomProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const logger = useMemo(() => getLogger("sidebar"), []);

  const module = "SideBarRoom";

  const { push } = useRouter();
  const {
    room,
    roomName,
    joinRoom,
    leaveRoom,
    isGroup,
    handleRoomMembershipEvent,
    isDirect,
    isPrivate,
    isRoomLowPrio,
    markRoomAsLowPriority,
    markRoomAsFavorite,
    removeRoomAsFavorite,
  } = useMatrixRooms(roomId);
  const { getRoomAvatar, directUser, updateRoom, roomAvatar } = useMatrixAvatar(room);

  const [status, setStatus] = useState("offline");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [canLeave, setCanLeave] = useState(true);
  const [isFavorite, setIsFavorite] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [willJoin, setWillJoin] = useState(false);
  const [isDirectRoom, setIsDirectRoom] = useState(room ? isDirect(roomId) : false);
  const [isLeaving, setIsLeaving] = useState(false);
  const [currentRoomAvatar, setCurrentRoomAvatar] = useState<string | null | undefined>("");
  const [avatarUrl, setAvatarUrl] = useState(roomAvatar);
  const [currentRoomName, setCurrentRoomName] = useState<string>(roomName);

  const localRoom = useAppSelector((state) => getLocalRoom(state, roomId));
  const { updatedRoomAvatar, matrixCallRoomId } = useAppSelector((state) => state.matrix);
  const currentRoomId = useAppSelector((state) => state.matrix.roomId);
  const matrixChatVisible = useAppSelector((state) => state.matrix.chatVisible);
  const callCurrentRoom = useAppSelector((state) => state.call.callRoomId);

  const markAsFavorite = async () => {
    setAnchorEl(null);
    try {
      await markRoomAsFavorite(roomId);
    } catch (e) {
      logger.error(`${module}: Error in markAsFavorite - ${e.message}`);
    }
  };

  const markAsLowPriority = async () => {
    setAnchorEl(null);
    try {
      await markRoomAsLowPriority(roomId);
    } catch (e) {
      logger.error(`${module}: Error in markAsLowPriority - ${e.message}`);
    }
  };

  const removeAsFavorite = async () => {
    setAnchorEl(null);
    try {
      removeRoomAsFavorite(roomId);
    } catch (e) {
      logger.error(`${module}: Error in removeAsFavorite - ${e.message}`);
    }
  };

  const handleRoomClick = () => {
    push(`/room/${roomId}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleLeaveRoom = () => {
    try {
      setWillJoin(false);
      setOpenDialog(true);
      setAnchorEl(null);
    } catch (e) {
      setAnchorEl(null);
      logger.error(`${module}: Error in handleLeaveRoom - ${e.message}`);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogAgree = async () => {
    try {
      if (willJoin) {
        await joinRoom(roomId);
        setOpenDialog(false);
      } else {
        setIsLeaving(true);
        await leaveRoom(roomId);
        setIsLeaving(false);
        setOpenDialog(false);
        if (roomId === currentRoomId) {
          push("/home");
        }
      }
    } catch (e) {
      setIsLeaving(false);
      logger.error(`${module}: Error in handleDialogAgree - ${e.message}`);
    }
  };

  const hideUnreadNotificarionBadge = () => {
    if (currentRoomId !== roomId) {
      if (localRoom && localRoom.unreadNotificationCount > 0) {
        return false;
      }
    }

    if (currentRoomId === roomId) {
      if (
        (isDirectRoom ? roomId === matrixCallRoomId : roomId === callCurrentRoom) &&
        !matrixChatVisible &&
        localRoom &&
        localRoom.unreadNotificationCount > 0
      ) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (!room) return;
    updateRoom(room);
    const { tags }: any = room;
    setCanLeave(!Boolean(tags["scheduled_room"]));
    setIsFavorite(Boolean(tags["m.favourite"]));
    setCurrentRoomAvatar(getRoomAvatar({ roomId })!);
    if (!isGroup(roomId) && !isDirect(roomId)) setCurrentRoomName(room.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  useEffect(() => {
    if (!room) return;
    updateRoom(room);
    const direct = isDirect(roomId);
    if (isDirectRoom !== direct) {
      setIsDirectRoom(direct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleRoomMembershipEvent]);

  useEffect(() => {
    if (!room) return;
    if (!isEmpty(roomName) && roomName !== currentRoomName) setCurrentRoomName(roomName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, room?.name, roomName]);

  useEffect(() => {
    if (!localRoom || (localRoom && roomId && localRoom.roomId !== roomId)) return;
    if (!isGroup(roomId) && !isDirect(roomId)) return;
    if (localRoom && currentRoomName !== localRoom.name) {
      setCurrentRoomName(localRoom.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localRoom, localRoom?.name]);

  useEffect(() => {
    if (!directUser || !room) return;
    if (isDirectRoom) {
      const id = room.guessDMUserId();

      //check changes on directUser and update the user avatar and status
      const { userId, presenceStatus, presenceStatusMsg, presence, avatarUrl } = directUser;

      if (userId && userId === id) {
        setAvatarUrl(avatarUrl && avatarUrl.length > 0 ? avatarUrl : "");
        const userPresence = presenceStatus ? presenceStatus : presenceStatusMsg || presence;
        if (userPresence) {
          setStatus(userPresence);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    directUser,
    directUser?.presenceStatusMsg,
    directUser?.presence,
    directUser?.presenceStatus,
    directUser?.avatarUrl,
  ]);

  useEffect(() => {
    if (!updatedRoomAvatar) return;
    if (updatedRoomAvatar.roomId !== roomId) return;
    if (!isDirectRoom) {
      setCurrentRoomAvatar(updatedRoomAvatar.roomAvatar ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedRoomAvatar]);

  const navLinkStyle = (isActive: boolean) => {
    const style = {
      color: isActive ? theme.palette.icon.navLink.selected : theme.palette.icon.navLink.notSelected,
      backgroundColor: isActive ? theme.palette.background.navLink.selected : "",
      border: isActive ? `1px solid ${theme.palette.border.navLink}` : `1px solid transparent`,
      borderRadius: "4px",
      textDecoration: "none",
      display: (room ? isRoomLowPrio(room.roomId) : false) ? "none" : "block",
    };
    return style;
  };

  return (
    <>
      {room ? (
        <>
          <JoinLeaveRoomDialog
            openDialog={openDialog}
            isLoading={isLeaving}
            isJoining={willJoin}
            roomName={currentRoomName}
            handleDialogAgree={handleDialogAgree}
            handleDialogClose={handleDialogClose}
          />
          {isValidDisplayName(currentRoomName) ? (
            <>
              <NavLink
                key={room?.roomId}
                to={`/room/${roomId}`}
                className={styles.link}
                style={(isActive: boolean) => navLinkStyle(isActive)}
              >
                <StyledRoomDiv className={styles.room} onClick={handleRoomClick} {...test("SIDEBAR_ROOM")}>
                  <div style={{ width: `${hideUnreadNotificarionBadge() ? "100%" : "calc(95%)"}` }}>
                    <AvatarWithLabels
                      id={`sidebar-room-avatar-${roomId}`}
                      src={isDirectRoom ? avatarUrl : currentRoomAvatar}
                      name={currentRoomName}
                      disableBadge={(!isDirectRoom && !isPrivate(roomId)) || isGroup(room.roomId)}
                      isPerson={isDirectRoom}
                      isPrivateRoom={isPrivate(roomId) && !isGroup(room.roomId)}
                      isGroupRoom={isGroup(room.roomId)}
                      status={status}
                      statusMsg={isDirectRoom ? t(getStatusMessage(status).toUpperCase()) : ""}
                    />
                  </div>

                  <Badge
                    overlap="rectangular"
                    badgeContent={localRoom?.unreadNotificationCount}
                    invisible={hideUnreadNotificarionBadge()}
                    color="primary"
                    style={{ position: "absolute", right: "60px" }}
                  />
                  <IconButton aria-label="more" className={`${styles.moreMenu}`} onClick={handleMenuClick} size="large">
                    <MoreHorizIcon fontSize="small" />
                  </IconButton>
                </StyledRoomDiv>
              </NavLink>
              <Menu
                id="room-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                {...test("SIDEBAR_ROOM_MENU")}
              >
                {isFavorite ? (
                  <MenuItem onClick={removeAsFavorite}>
                    <ListItemIcon>
                      <FavoriteBorderIcon fontSize="small" />
                    </ListItemIcon>
                    {t("REMOVEFAV")}
                  </MenuItem>
                ) : (
                  <MenuItem onClick={markAsFavorite}>
                    <ListItemIcon>
                      <FavoriteIcon fontSize="small" />
                    </ListItemIcon>
                    {t("MARKFAV")}
                  </MenuItem>
                )}
                {canLeave && !isDirectRoom && !isGroup(roomId) ? (
                  <MenuItem onClick={handleLeaveRoom}>
                    <ListItemIcon>
                      <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    {t("LEAVEROOM")}
                  </MenuItem>
                ) : isDirectRoom || isGroup(roomId) ? (
                  <MenuItem onClick={markAsLowPriority}>
                    <ListItemIcon>
                      <RemoveCircleOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    {isGroup(roomId) ? t("HIDEGROUPCHAT") : t("HIDECONTACT")}
                  </MenuItem>
                ) : null}
              </Menu>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default SideBarRoom;
