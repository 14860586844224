import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { test } from "utils/helpers";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

interface RemoveUserDialogProps {
  userName: string;
  openDialog: boolean;
  errorMessage?: string;
  isLoading?: boolean;
  handleDialogClose: () => void;
  handleDialogAgree: () => void;
}

const RemoveUserDialog = ({
  userName,
  errorMessage,
  isLoading = false,
  openDialog,
  handleDialogClose,
  handleDialogAgree,
}: RemoveUserDialogProps) => {
  const [openAlert, setopenAlert] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    setopenAlert(Boolean(errorMessage));
  }, [errorMessage]);

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="join/leave confirmation"
      open={openDialog}
      PaperProps={{ sx: { width: "365px" } }}
      {...test("REMOVE_USER_ROOM_DIALOG")}
    >
      <DialogTitle id="simple-dialog-title">{t("REMOVE_MEMBER")}</DialogTitle>
      <DialogContent>
        <Collapse in={openAlert}>
          <Alert onClose={() => setopenAlert(false)} severity="error" style={{ marginBottom: "1rem" }}>
            <AlertTitle>
              {t("FAILEDTO")} {t("REMOVE_MEMBER").toLowerCase()}
            </AlertTitle>
            {errorMessage}
          </Alert>
        </Collapse>
        <Typography id="alert-dialog-description" variant="body2" sx={{ color: theme.palette.text.primary }}>
          {`${t("AREYOUSUREYOUWANTTO")} ${t("REMOVE").toLowerCase()} ${userName} ${t("FROM_THIS_ROOM")}?`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          id="remove_user_dialog_cancel_button"
          variant="outlined"
          onClick={handleDialogClose}
          disabled={isLoading}
        >
          {t("CANCEL")}
        </Button>
        <Button id="remove_user_dialog_remove_button" onClick={handleDialogAgree} autoFocus disabled={isLoading}>
          {t("REMOVE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveUserDialog;
