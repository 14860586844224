import React, { useRef, useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";

const CustomSelect = ({ ...props }) => {
  const { tooltip, label, children, onChange, helperText, error, width, id, ...other } = props;
  const labelRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    // @ts-ignore
    const labelChild = labelRef?.current?.childNodes[0];
    if (labelChild.scrollWidth > labelChild.clientWidth) {
      setIsEllipsisActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelRef?.current]);

  return (
    <FormControl variant="filled" sx={{ width: width ? width : "100%" }} id={id} error={error}>
      {label && <InputLabel id="select-label">{label}</InputLabel>}
      <Tooltip title={tooltip ? tooltip : props.value} disableHoverListener={!isEllipsisActive} arrow>
        <Select
          ref={labelRef}
          displayEmpty
          onChange={onChange}
          sx={
            !label
              ? {
                  "& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.MuiSelect-select": {
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  },
                }
              : null
          }
          {...other}
        >
          {children}
        </Select>
      </Tooltip>
      {helperText && <FormHelperText>helperText</FormHelperText>}
    </FormControl>
  );
};

export default CustomSelect;
