import { createHashHistory } from "history";
import { allowedPortalURLs } from "./constants.js";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import moment from "moment";
import { localStorageKeys } from "utils/constants";

const history = createHashHistory();

export const wrongDisplayNamePattern = /.*(@|#).+:.+/;

export const forwardTo = (location, autoJoin) => {
  if (autoJoin) {
    history.push(`/room/${location}/joinCall`);
  } /*validates if the location already contains /room/ */ else if (location.split("/room/").length > 1) {
    history.push(location);
  } else {
    history.push(`/room/${location}`);
  }
};

export const navigateTo = (location) => {
  history.push(`/${location}`);
};

export const tryParse = (data) => {
  try {
    if (!data) return null;
    if (typeof data === "object") return data;
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

export const getConferenceData = (roomURL, userName) => {
  if (!roomURL) {
    return;
  }
  const parsedURL = roomURL.split("/join/");
  const portal = parsedURL[0].replace("https://", "");
  const roomKey = parsedURL[1];
  return {
    host: portal,
    roomKey: roomKey,
    displayName: userName || "Guest",
    roomPin: "",
    hasExtData: false,
  };
};

export const generateUUID = () => {
  try {
    return uuidv4();
  } catch (e) {
    return null;
  }
};

export const isUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};
export const isValidConferenceLink = (url) => {
  if (url && url.includes("join") && isUrl(url)) {
    const urlObject = new URL(url);
    // feature is disabled until provisioning server whitelist endpoint
    // is supported.
    const urlCheckEnabled = false;
    if (urlCheckEnabled) {
      return allowedPortalURLs.some((item) => {
        return item === urlObject.host;
      });
    }
    return true;
  }
  return false;
};

export const generateRandomString = (length) => {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const convertHTMLToEmoji = (string, params) => {
  const container = document.createElement("div");
  container.innerHTML = string;
  container.querySelectorAll("img").forEach((el) => el.replaceWith(document.createTextNode(el.alt)));
  return container;
};

export const convertHTMLtoText = (string) => {
  const res = string
    .replace(/<(\/li|\/p)>$/g, "") // remove the last </p>|</li>
    .replaceAll(/<(\/li|\/p)>/g, `\n`) // replace all the </p>|</li> wiht a /n
    .replaceAll(/<.*?>/g, "") // remove all the initial elements
    .trim();
  return res;
};

export const convertLineBreakTextToHTML = (string) => {
  let html = "";
  let lbIndex = string.search(/\r?\n/g);
  for (; lbIndex > 0 && lbIndex <= string.length; ) {
    html += `<p>${string.slice(0, lbIndex)}</p>`;
    string = string.slice(lbIndex + 1);
    lbIndex = string.search(/\r?\n/g);
  }
  html += `<p>${string}</p>`;
  return html;
};

export const getInitials = (string) => {
  if (!string) {
    return "??";
  }
  return string
    .split(" ")
    .map((i) => i[0])
    .join("")
    .substr(0, 2)
    .toUpperCase();
};

export const arrayGroupBy = (array, predicate) => {
  return array.reduce((groups, item, i, self) => {
    self[i - 1] && predicate(self[i - 1] || item, item)
      ? groups[groups.length - 1].push(self[i])
      : groups.push([self[i]]);
    return groups;
  }, []);
};

export const unsafeParseTextFromHTMLString = (htmlString) => {
  let parser = new DOMParser();
  let parsedHTMLDoc = parser.parseFromString(htmlString, "text/html");

  let plainTextResult = parsedHTMLDoc?.body.innerText ?? "";
  plainTextResult = plainTextResult.replace(/\n{3,}/gim, "\n\n");
  return plainTextResult;
};

export const encodeHTMLString = (str) => {
  let span = document.createElement("span");
  span.textContent = str;
  return span.innerHTML;
};

export const test = (key) => {
  return {
    "data-testid": key,
  };
};

export const sortDevices = (devices) => {
  const sorted = [];
  let sysDefault;

  devices.forEach((item, index) => {
    if (item.id === "default") {
      sysDefault = item;
    } else {
      sorted.push(item);
    }
  });

  sorted.sort((item1, item2) => item1.name.localeCompare(item2.name));

  return sysDefault ? [sysDefault, ...sorted] : sorted;
};

export const filterList = (value, list, key) => {
  try {
    list = list.filter((element) => element[key]?.toLowerCase().includes(value?.toLowerCase()));
    return list;
  } catch (e) {
    console.error(`Error in helpers.filterList - ${e.message}`);
  }
};

export const fileName = (path) => {
  try {
    if (isDataURL(path)) return "Image";
    path = path?.substring(path?.lastIndexOf("/") + 1);
    return (path?.match(/[^.]+(\.[^?#]+)?/) || [])[0];
  } catch (e) {
    console.error(`Error in helpers.fileName - ${e.message}`);
  }
  return "Image";
};

export const arraysEqual = (array1, array2) => {
  const array1Sorted = array1.slice().sort();
  const array2Sorted = array2.slice().sort();
  return array1.length === array2.length && _.isEqual(array1Sorted, array2Sorted);
};

export const mergeArrays = (...arrays) => {
  let jointArray = [];

  arrays.forEach((array) => {
    jointArray = [...jointArray, ...array];
  });

  const uniqueArray = jointArray.reduce((newArray, item) => {
    if (newArray.includes(item)) {
      return newArray;
    } else {
      return [...newArray, item];
    }
  }, []);

  return uniqueArray.sort((a, b) => {
    return a.getTs() > b.getTs() ? 1 : b.getTs() > a.getTs() ? -1 : 0;
  });
};

export const isJson = (item) => {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === "object" && item !== null) {
    return true;
  }

  return false;
};

export const isDataURL = (s) => {
  if (typeof s !== "string") return false;
  isDataURL.regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;
  return s.match(isDataURL.regex);
};

export const getProperCase = (stringValue) => {
  if (stringValue == null || typeof stringValue !== "string") {
    return stringValue;
  }
  return stringValue.replace(/\w\S*/g, (t) => {
    return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
  });
};

export const isStringWithValue = (str) => {
  try {
    if (str != null) {
      if (typeof str === "string" || str instanceof String) {
        return str.length > 0;
      }
    }
  } catch (err) {
    console.error(`Error in helpers.isStringWithValue - str:${str} - ${err}`);
  }
  return false;
};

export const getUserName = (user) => {
  let userName = null;
  if (isStringWithValue(user)) {
    if (user.includes("@")) {
      const home_server = user.slice(user.indexOf(":") + 1);
      userName = user.replace(`:${home_server}`, "").replace("@", "").toLowerCase();
    } else {
      const { user_id, home_server } = JSON.parse(user);
      userName = user_id.replace(`:${home_server}`, "").replace("@", "");
    }
  }
  return userName;
};

export const isMatrixUserId = (user) => {
  if (isStringWithValue(user)) {
    if (user.startsWith("@")) {
      return true;
    } else return false;
  }
};

export const getApiResponseErrorMessage = (error) => {
  if (!error) {
    return "unknown error.";
  }
  let message = error?.response?.data?.message;
  if (message) {
    return message;
  }
  return error.message || error;
};

export const getGreetingMessage = (helloText, currentUser) => {
  try {
    let displayName = currentUser?.displayName;

    if (isStringWithValue(displayName)) {
      if (displayName !== currentUser?.userId) {
        let firstName = displayName.split(" ")[0];
        return helloText + ", " + getProperCase(firstName) + "!";
      }
    }
  } catch (err) {
    console.error(`Error in helpers.getGreetingMessage - ${err.message}`);
  }

  return helloText;
};

export const getFromLocalStorage = (key) => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (e) {
    console.error(`Error in helpers.getFromLocalStorage - key:${key} - ${e.message}`);
    return null;
  }
};

export const isValidDisplayName = (roomName) => {
  let valid = true;
  if (
    roomName !== null &&
    roomName !== undefined &&
    (roomName.length === 0 ||
      roomName === "Group Chat" ||
      wrongDisplayNamePattern.test(roomName) ||
      roomName.includes("Empty"))
  )
    valid = false;
  return valid;
};

export const updateUsersListWithNewUser = (list, newUser) => {
  const aux = list.find((user) => user.id === newUser.id);
  if (aux) {
    return list.map((user) => (user.id === newUser.id ? newUser : user));
  } else {
    list.push(newUser);
    return list;
  }
};

export const updateUsersList = (list, newUsers) => {
  let newList = [];
  if (newUsers) {
    newUsers.forEach((user) => (newList = updateUsersListWithNewUser(list, user)));
  }
  if (newList.length > 0) return newList;
  else return list;
};

export const addNewUsers = (oldList, newList) => {
  let newUsers = [];
  if (newList.length > 0) {
    newUsers = newList.filter((newUser) => !oldList.find((user) => user.id === newUser.id));
  }
  if (newUsers.length > 0) return oldList.concat(newUsers);
  else return oldList;
};

export const addNewUsername = (list, username) => {
  if (username) {
    const exists = list.find((item) => item === username);
    if (!exists) {
      return list.push(username);
    } else {
      return list.map((item) => (item === username ? username : item));
    }
  }
};

export const addListOfNewUsernames = (list, usernames) => {
  let newList = [];
  if (usernames) {
    usernames.forEach((username) => (newList = addNewUsername(list, username)));
  }
  if (newList.length > 0) return newList;
  else return list;
};

export const removeUsername = (list, username) => {
  let users = [];
  if (username) {
    users = list.filter((user) => user !== username);
  }
  if (users.length > 0) return users;
  else return list;
};

export const removeListUsernames = (list, usernames) => {
  let users = [];
  if (usernames) {
    usernames.forEach((username) => (users = removeUsername(list, username)));
  }
  if (users.length > 0) return users;
  else return list;
};

/**
 * validate phone numbers
 */
export const validatePhoneNumbers = (phone) => {
  /*eslint-disable-next-line*/
  const phoneNumberPattern = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{3,4}$/;
  return phoneNumberPattern.test(phone);
};

export const compareRoomLists = (roomList1, roomList2) => {
  const isLengthEqual =
    roomList1.favorites.length === roomList2.favorites.length &&
    roomList1.public.length === roomList2.public.length &&
    roomList1.private.length === roomList2.private.length &&
    roomList1.contacts.length === roomList2.contacts.length &&
    roomList1.invites.length === roomList2.invites.length;

  return isLengthEqual;
};

export const sortByLetters = (nameA, nameB) => {
  try {
    const user = JSON.parse(localStorage.getItem(localStorageKeys.CURRENT_USER));

    if (nameA) {
      nameA = nameA.replace(`:${user.home_server}`, "").replace("#", "");
    }

    if (nameB) {
      nameB = nameB.replace(`:${user.home_server}`, "").replace("#", "");
    }

    return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : nameB.toLowerCase() > nameA.toLowerCase() ? -1 : 0;
  } catch (e) {
    console.error(`Error in helpers.sortByLetters - ${e.message}`);
  }
  return 0;
};

export const sortByEvents = (roomA, roomB) => {
  try {
    return roomA.eventsTimestamp > roomB.eventsTimestamp ? -1 : roomB.eventsTimestamp > roomA.eventsTimestamp ? 1 : 0;
  } catch (e) {
    console.error(`Error in helpers.sortByEvents - ${e.message}`);
  }
  return 0;
};

export const getFormattedDate = (dt, language) => {
  try {
    if (!(dt instanceof Date)) {
      dt = new Date(dt.toString());
    }

    return new Intl.DateTimeFormat(language, {
      weekday: "long",
      month: "long",
      day: "numeric",
    }).format(dt);
  } catch (e) {
    console.error(`Error in helpers.getFormattedDate - ${e.message}`);
    return "";
  }
};
export const getFormattedDateShort = (dt, language) => {
  try {
    if (!(dt instanceof Date)) {
      dt = new Date(dt.toString());
    }

    return new Intl.DateTimeFormat(language, {
      month: "long",
      day: "numeric",
    }).format(dt);
  } catch (e) {
    console.error(`Error in helpers.getFormattedDate - ${e.message}`);
    return "";
  }
};

export const getFormattedDateFromTimestamp = (dt, language) => {
  try {
    if (!(dt instanceof Date)) {
      dt = new Date(parseInt(dt) * 1000);
    }
    return new Intl.DateTimeFormat(language, {
      weekday: "long",
      month: "long",
      day: "numeric",
    }).format(dt);
  } catch (e) {
    console.error(`Error in helpers.getFormattedDate - ${e.message}`);
    return "";
  }
};

export const getFormattedTime = (dt, language) => {
  try {
    if (!(dt instanceof Date)) {
      dt = new Date(dt.toString());
    }

    return new Intl.DateTimeFormat(language, {
      timeStyle: "short",
    }).format(dt);
  } catch (e) {
    console.error(`Error in helpers.getFormattedTime - ${e.message}`);
    return "";
  }
};

export const getDayOfTheWeek = (dt, language) => {
  try {
    if (!(dt instanceof Date)) {
      dt = new Date(parseInt(dt) * 1000);
    }

    return new Intl.DateTimeFormat(language, {
      weekday: "short",
    }).format(dt);
  } catch (e) {
    console.error(`Error in helpers.getDayOfTheWeek - ${e.message}`);
    return "";
  }
};

export const getMeetingHours = (start, end, language) => {
  try {
    if (!(start instanceof Date)) {
      start = moment.utc(start).toDate();
    }

    if (!(end instanceof Date)) {
      end = moment.utc(end).toDate();
    }
    return (
      new Intl.DateTimeFormat(language, {
        hour: "numeric",
        minute: "numeric",
      }).format(start) +
      " - " +
      new Intl.DateTimeFormat(language, {
        hour: "numeric",
        minute: "numeric",
      }).format(end)
    );
  } catch (e) {
    console.error(`Error in helpers.getMeetingHours - ${e.message}`);
    return "";
  }
};

export const isEmpty = (str) => {
  return !str || str.length === 0;
};

export const formatDate = (date, language) => {
  var monthday = new Intl.DateTimeFormat(language, {
    day: "2-digit",
    month: "short",
  })
    .format(new Date(date))
    .replaceAll(".", "");
  return monthday.charAt(0).toUpperCase() + monthday.slice(1) + moment(date).format(", YYYY, HH:mm");
};

export const getTimeFormat = (time, hoursChar, minutesChar, secondsChar) => {
  let timeSplitted = time.split(":").reverse();
  let hours = "";
  let minutes = "";
  let seconds = "";
  for (let i = 0; i < timeSplitted.length; i++) {
    if (i === 2) hours = timeSplitted[i] + hoursChar + " ";
    if (i === 1) minutes = timeSplitted[i] + minutesChar + " ";
    if (i === 0) seconds = timeSplitted[i] + secondsChar;
  }

  return hours + minutes + seconds;
};

export const asyncWaitWithTimer = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const isNumber = (str) => /^\d+$/.test(str);

export const isTestingEnvironment = () => process.env.JEST_WORKER_ID !== undefined;

// Get items that only occur in the left array,
// using the compareFunction to determine equality.
export const onlyInLeft = (left, right, compareFunction) =>
  left.filter((leftValue) => !right.some((rightValue) => compareFunction(leftValue, rightValue)));

export const createSipDisplayName = (firstName, lastName) => {
  let ret = isEmpty(firstName) ? "" : firstName.trim();
  if (!isEmpty(ret) && !isEmpty(lastName)) ret += " ";
  if (!isEmpty(lastName)) ret += lastName.trim();
  return ret.trim();
};
