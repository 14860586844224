import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { useProvisioning, useEffectAfterMount } from "hooks";
import { test } from "utils/helpers";
import { getLogger } from "logger/appLogger";
import styles from "./Login.module.scss";

interface LoginProps {
  isLoggingIn: boolean;
  currentTenantName: string;
  currentTenantServer: string;
  onSubmit: () => void;
  handleTenantNameChange: (value: string) => void;
  onError: (message: string) => void;
  clearErrors: () => void;
}

const Login = ({
  isLoggingIn = false,
  currentTenantName,
  currentTenantServer,
  onSubmit,
  handleTenantNameChange,
  onError,
  clearErrors,
}: LoginProps) => {
  const { t } = useTranslation();
  const logger = useMemo(() => getLogger("login"), []);
  const module = "Login";

  const { validateUrl } = useProvisioning();
  const [tenantServer, setTenantServer] = useState(currentTenantServer);
  const [tenantName, setTenantName] = useState(currentTenantName);
  const [error, setError] = useState(false);

  const layoutWidth = "335px";
  const isElectron = window.navigator.userAgent.indexOf("Electron") !== -1;

  const validateTenants = () => {
    let haveErrors = false;
    if (!tenantServer || tenantServer?.trim().length === 0) {
      onError(t("TENANT_SERVER_URL"));
      setError(true);
      haveErrors = true;
    }

    if (!tenantName || tenantName?.trim().length === 0) {
      onError(t("TENANT_NAME"));
      setError(true);
      haveErrors = true;
    }
    return haveErrors;
  };

  const getTenantUrl = (tenantServer: string, tenantName: string) => {
    let tenantUrl = tenantServer;
    if (tenantName) {
      const pathSep = tenantServer.charAt(tenantServer.length - 1) === "/" ? "" : "/";
      tenantUrl = `${tenantServer}${pathSep}tenant/${tenantName}`;
    }
    return tenantUrl;
  };

  const handleChanges = async () => {
    try {
      const haveErrors = validateTenants();

      if (!haveErrors) {
        const ts = tenantServer.trim();
        const tn = tenantName.trim();
        const tenantUrl = getTenantUrl(ts, tn);
        await validateUrl(tenantUrl);
        handleTenantNameChange(tn);

        setError(false);

        clearErrors();

        if (ts !== window.location.origin) {
          window.location.href = ts;
        }
      }
    } catch (e) {
      if (e.message.includes("404")) onError(t("TENANT_NAME"));
      if (e.message.includes("Network Error")) {
        onError(t("TENANT_SERVER_URL"));
      } else {
        onError(t("TENANT_SERVER_URL"));
      }
      setError(true);
      logger.error(`${module}: Error in handleChanges - ${e.message}`);
    }
  };

  const handleLoginClick = () => {
    const haveErrors = validateTenants();

    if (!haveErrors) onSubmit();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event && event.key === "Enter") {
      event.preventDefault();
      //@ts-ignore
      event.target.blur();
    }
  };

  const handleTenantServerChanges = (value: string) => {
    const server = value.trim();
    if (server.length > 0) setTenantServer(`https://${server}`);
    else setTenantServer(server);
  };

  useEffect(() => {
    if (!isElectron) return;
    window.ipcRenderer.invoke("getHSStore", currentTenantServer).then((result: any) => {
      if (!result) return;
      const { tenant } = result;
      // tenant name should be store in lower case to avoid token issues
      setTenantName(tenant ? tenant.toLowerCase() : tenant);
      handleChanges();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenantServer, isElectron]);

  useEffectAfterMount(() => {
    handleChanges();
  }, [tenantServer, tenantName]);

  return (
    <div className={styles.main} {...test("LOGIN")}>
      {!isLoggingIn && (
        <div {...test("HOME_SERVER")}>
          <div className={styles.form} style={layoutWidth ? { width: layoutWidth } : {}}>
            {isElectron && (
              <TextField
                variant="filled"
                label={t("TENANT_SERVER_URL")}
                sx={{ marginBottom: "10px" }}
                defaultValue={currentTenantServer.toLowerCase().replace("https://", "")}
                error={error}
                onBlur={(event: { target: { value: any } }) => {
                  handleTenantServerChanges(event.target.value);
                }}
                onKeyDown={handleKeyDown}
                inputProps={{
                  ...test("TENANT_SERVER_URL_INPUT"),
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: "2px", marginTop: "20px" }}>
                      <label
                        style={{
                          marginLeft: "10px",
                          marginTop: "3px",
                        }}
                      >
                        https://
                      </label>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <TextField
              variant="filled"
              label={t("TENANT_NAME")}
              sx={{ marginBottom: "20px" }}
              inputProps={{ ...test("TENANT_NAME_INPUT") }}
              defaultValue={currentTenantName}
              error={error}
              onBlur={(event: { target: { value: any } }) =>
                // tenant name should be store in lower case to avoid token issues
                setTenantName(event.target.value ? event.target.value.trim().toLowerCase() : event.target.value)
              }
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
      )}
      <Button
        id="login_submit_button"
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        style={{ width: layoutWidth }}
        disableElevation
        onClick={handleLoginClick}
        disabled={
          isLoggingIn ||
          !tenantName ||
          (tenantName && tenantName.length === 0) ||
          !tenantServer ||
          (tenantServer && tenantServer.length === 0) ||
          error
        }
        {...test("LOGIN_BUTTON")}
      >
        {isLoggingIn ? (
          <>
            <CircularProgress color="inherit" size={15} />
            &nbsp; {t("PROCEEDING")}
          </>
        ) : (
          t("PROCEED")
        )}
      </Button>
    </div>
  );
};

export default Login;
