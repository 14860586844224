import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import { matrixActions } from "store/actions/matrix";
import * as devicesActionCreators from "store/actions/devices";
import * as googleAnalytics from "store/actions/googleAnalytics";
import DeviceToggle, { Toggle_Devices } from "components/DeviceToggle/DeviceToggle";
import { test } from "utils/helpers";
import { MediaStreamDevices } from "types/UC";
import { RootState } from "store/root-reducer";
import { CallState } from "matrix-js-sdk/lib/webrtc/call";
import Switch from "components/_shared/Switch";

interface MicrophoneToggleOwnProps {
  showLabel?: boolean;
  showToggleAsSwitch?: boolean;
  isCallActive?: boolean;
  style?: React.CSSProperties;
}

const mapStateToProps = ({ devices, matrix, call }: RootState) => ({
  hasActiveMatrixCall: matrix.hasActiveMatrixCall,
  matrixAudioMuted: matrix.matrixAudioMuted,
  microphones: devices.microphones,
  selectedMicrophone: devices.selectedMicrophone,
  isMicrophoneTurnedOn: devices.isMicrophoneTurnedOn,
  isMicrophoneDisabled: devices.isMicrophoneDisabled,
  isMicrophoneHardMuted: call.moderationStatus.audioHardMute,
  matrixCallClientState: matrix.matrixCallClientState,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(devicesActionCreators, dispatch),
  ...bindActionCreators(googleAnalytics, dispatch),
  ...bindActionCreators(matrixActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type MicrophoneToggleProps = ConnectedProps<typeof connector> & MicrophoneToggleOwnProps;

const MicrophoneToggle = ({
  showLabel,
  showToggleAsSwitch = false,
  hasActiveMatrixCall,
  isCallActive,
  style,
  matrixAudioMuted,
  muteMatrixAudio,
  microphones,
  selectedMicrophone,
  isMicrophoneDisabled,
  isMicrophoneTurnedOn,
  isMicrophoneHardMuted,
  microphoneTurnOff,
  microphoneTurnOn,
  rightClickOnDevice,
  matrixCallClientState,
}: MicrophoneToggleProps) => {
  const { t } = useTranslation();

  const [intermediateMicrophoneMute, setIntermediateMicrophoneMute] = useState(false);
  const [microphoneOn, setMicrophoneOn] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const microphoneOnClick = () => {
    if (hasActiveMatrixCall) {
      muteMatrixAudio(!matrixAudioMuted);
    } else {
      isMicrophoneTurnedOn ? microphoneTurnOff() : microphoneTurnOn();
    }
  };

  const microphoneOnMouseDown = (event: { nativeEvent: { which: number } }) => {
    if (event.nativeEvent.which === 3) {
      rightClickOnDevice(MediaStreamDevices.microphone);
    }
  };

  useEffect(() => {
    // isMicrophoneHardMuted=>true <... stay disabled until ....> isMicrophoneTurnedOn=>false
    if (isMicrophoneHardMuted) {
      setIntermediateMicrophoneMute(true);
      // timer as safety mechanism if for some reason the transition to (!isMicrophoneTurnedOn) fails
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => setIntermediateMicrophoneMute(false), 1000);
    }
    if (intermediateMicrophoneMute && !isMicrophoneTurnedOn) {
      setIntermediateMicrophoneMute(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMicrophoneHardMuted, isMicrophoneTurnedOn]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = undefined;
      }
    };
  }, []);

  useEffect(() => {
    setMicrophoneOn(hasActiveMatrixCall ? !matrixAudioMuted : isMicrophoneTurnedOn);
  }, [hasActiveMatrixCall, isMicrophoneTurnedOn, matrixAudioMuted]);

  useEffect(() => {
    if (!hasActiveMatrixCall && !matrixCallClientState) return;
    if (hasActiveMatrixCall && matrixCallClientState === CallState.Connected) setDisabled(false);
    else setDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrixCallClientState]);

  return (
    <div className="device-toggle">
      {showToggleAsSwitch ? (
        <Switch
          color="primary"
          checked={microphoneOn}
          onChange={microphoneOnClick}
          onMouseDown={microphoneOnMouseDown}
          name="microphone_switch"
          label={t("MICROPHONE")}
          style={style}
          disabled={
            disabled ||
            !microphones.length ||
            !selectedMicrophone ||
            isMicrophoneDisabled ||
            isMicrophoneHardMuted ||
            isCallActive ||
            intermediateMicrophoneMute
          }
          {...test("MICROPHONE_TOGGLE")}
        />
      ) : (
        <>
          <DeviceToggle
            disabled={
              disabled ||
              !microphones.length ||
              !selectedMicrophone ||
              isMicrophoneDisabled ||
              isMicrophoneHardMuted ||
              intermediateMicrophoneMute
            }
            on={microphoneOn}
            onClick={microphoneOnClick}
            onMouseDown={microphoneOnMouseDown}
            tooltip={microphoneOn ? t("TOOLTIP_MICROPHONE_ON") : t("TOOLTIP_MICROPHONE_OFF")}
            {...test("MICROPHONE_TOGGLE")}
            device={Toggle_Devices.MICROPHONE}
          ></DeviceToggle>
          {showLabel && (
            <div className="toggle-label">
              {isMicrophoneDisabled
                ? t("MICROPHONE_DISABLED")
                : selectedMicrophone
                ? selectedMicrophone.name
                : microphones.length
                ? t("NO_ACTIVE_MICROPHONE")
                : t("NO_MICROPHONE")}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MicrophoneToggle);
