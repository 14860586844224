import { getLogger } from "logger/appLogger";
import { PresenceStates } from "types/Presence";

const StateMachine = require("javascript-state-machine");
const logger = getLogger("presence");

/*
    PresenceService Class

    Constructor requirements:
        presenceApi - This object must expose a method named "update_presentity" that
                      accepts a string to update the user's presence/status on the server.
        commService - This object must expose a method named "getActiveSession" that
                      returns one of: "call", "conf", "presenting" or null.
*/
class PresenceService {
  constructor(presenceApi, commService) {
    this.module = "PresenceService";
    logger.debug(`${this.module}: starting...`);

    this.presenceApi = presenceApi;
    this.commService = commService;

    this.isInCall = false;
    this.isInConf = false;
    this.isPresenting = false;
    this.lastManualState = PresenceStates.available;

    this.offlineIdleStates = [PresenceStates.offline, PresenceStates.idle];
    this.lowPriorityManualStates = [PresenceStates.available, PresenceStates.away];
    this.highPriorityManualStates = [PresenceStates.busy, PresenceStates.dnd];
    this.allManualStates = [
      PresenceStates.available,
      PresenceStates.away,
      PresenceStates.busy,
      PresenceStates.dnd,
      PresenceStates.offline,
    ];
    this.allAutoStates = [PresenceStates.call, PresenceStates.conf, PresenceStates.presenting];
    this.allActiveStates = [
      PresenceStates.available,
      PresenceStates.away,
      PresenceStates.busy,
      PresenceStates.dnd,
      PresenceStates.call,
      PresenceStates.conf,
      PresenceStates.presenting,
    ];

    this.newRemoteStatus = PresenceStates.offline;
    this.newLocalStatus = PresenceStates.offline;

    this.fsm = null;

    this.initStateMachine();
  }

  /* 
      Valid FSM states:
        - available
        - away
        - busy
        - dnd
        - call
        - conf
        - presenting
        - idle
        - offline

      Transitions:
        - local-status-change - the user has manually changed the local status
        - remote-status-update - a presence notification update was received from the server
        - in-focus (foreground) - the app is transitioning from being out-of-focus to in-focus
        - out-of-focus (background) - the app is transitioning from being in-focus to out-of-focus
        - network-online - the network has come back online after an outage
        - active - the user is actively engaged with the app or device
        - inactive - the app or device has been inactive for a period of time
        - login - the user just logged in
        - logout - the user just logged out
        - incoming-call - the user has answered an incoming call
        - outgoing-call - the user has made an outgoing call
        - exit-call - the user has exited a call
        - join-conf - the user has joined a conference
        - exit-conf - the user has exited a conference
        - start-sharing - the user is sharing their screen
        - stop-sharing - the user has stopped sharing their screen
    */
  initStateMachine() {
    const self = this;
    this.fsm = new StateMachine({
      init: PresenceStates.offline,
      transitions: [
        {
          name: "login",
          from: "*",
          to: function () {
            return self.handleLogin(this.state);
          },
        },
        { name: "logout", from: "*", to: PresenceStates.offline },
        {
          name: "out-of-focus",
          from: "*",
          to: function () {
            return this.state;
          },
        },
        {
          name: "in-focus",
          from: "*",
          to: function () {
            return self.handleInFocus(this.state);
          },
        },
        {
          name: "network-online",
          from: "*",
          to: function () {
            return self.handleInFocus(this.state);
          },
        }, // same as in-focus
        {
          name: "active",
          from: "*",
          to: function () {
            return self.handleInFocus(this.state);
          },
        }, // same as in-focus
        {
          name: "inactive",
          from: "*",
          to: function () {
            return self.handleInactive(this.state);
          },
        },
        {
          name: "outgoing-call",
          from: "*",
          to: function () {
            return self.handleCall(this.state);
          },
        },
        {
          name: "incoming-call",
          from: "*",
          to: function () {
            return self.handleCall(this.state);
          },
        },
        {
          name: "exit-call",
          from: "*",
          to: function () {
            return self.handleExitCall(this.state);
          },
        },
        {
          name: "join-conf",
          from: "*",
          to: function () {
            return self.handleJoinConf(this.state);
          },
        },
        {
          name: "exit-conf",
          from: "*",
          to: function () {
            return self.handleExitConf(this.state);
          },
        },
        {
          name: "start-sharing",
          from: "*",
          to: function () {
            return self.handleStartSharing(this.state);
          },
        },
        {
          name: "stop-sharing",
          from: "*",
          to: function () {
            return self.handleStopSharing(this.state);
          },
        },
        {
          name: "local-status-change",
          from: "*",
          to: function () {
            return self.handleLocalStatusChange(this.state);
          },
        },
        {
          name: "remote-status-update",
          from: "*",
          to: function () {
            return self.handleRemoteStatusUpdate(this.state);
          },
        },
      ],
      methods: {
        onLogout: () => {
          // Update server
          this.broadcastStatus(PresenceStates.offline);
        },
        onTransition: (lifecycle) => {
          logger.debug(
            `${this.module}: Transition: ${lifecycle.transition} from: ${lifecycle.from} to: ${lifecycle.to}`
          );
        },
      },
    });
  }

  /* Internal FSM Logic for login transition */
  handleLogin(currentState) {
    if (this.offlineIdleStates.includes(currentState)) {
      this.lastManualState = PresenceStates.available;
      this.broadcastStatus(this.lastManualState);
      return this.lastManualState;
    }

    if (this.allManualStates.includes(currentState)) {
      this.lastManualState = currentState;
      return currentState;
    }

    this.lastManualState = PresenceStates.available;
    return currentState;
  }

  /* Internal FSM Logic for in-focus transition */
  handleInFocus(currentState) {
    let activeSession = this.getActiveSession();
    if (activeSession) {
      return currentState;
    }

    if (this.offlineIdleStates.includes(currentState) && this.lastManualState == null) {
      this.lastManualState = PresenceStates.available;
      this.broadcastStatus(this.lastManualState);
      return this.lastManualState;
    }

    if (this.offlineIdleStates.includes(currentState) && this.lastManualState != null) {
      this.broadcastStatus(this.lastManualState);
      return this.lastManualState;
    }

    if (this.allManualStates.includes(currentState)) {
      this.lastManualState = currentState;
      return currentState;
    }

    return currentState;
  }

  /* Internal FSM Logic for inactive transition */
  handleInactive(currentState) {
    let activeSession = this.getActiveSession();
    if (activeSession) {
      return currentState;
    }

    this.broadcastStatus(PresenceStates.idle);
    return PresenceStates.idle;
  }

  /* Internal FSM Logic for (incoming|outgoing)-call transition */
  handleCall(currentState) {
    this.isInCall = true;
    if (this.allAutoStates.includes(currentState)) {
      return currentState;
    }

    this.broadcastStatus(PresenceStates.call);
    return PresenceStates.call;
  }

  /* Internal FSM Logic for exit-call transition */
  handleExitCall(currentState) {
    let activeCall = this.hasActiveCall();

    if (this.isInCall) {
      if (currentState === PresenceStates.call) {
        if (activeCall) {
          return currentState;
        } else {
          if (this.lowPriorityManualStates.includes(this.lastManualState)) {
            this.lastManualState = PresenceStates.available;
          }
          this.isInCall = false;
          this.broadcastStatus(this.lastManualState);
          return this.lastManualState;
        }
      } else if (currentState === PresenceStates.conf || currentState === PresenceStates.presenting) {
        if (!activeCall) {
          this.isInCall = false;
        }
        return currentState;
      } else {
        if (!activeCall) {
          this.isInCall = false;
          this.broadcastStatus(this.lastManualState);
          return this.lastManualState;
        }
      }
    }

    return currentState;
  }

  /* Internal FSM Logic for join-conf transition */
  handleJoinConf(currentState) {
    this.isInConf = true;

    if (currentState === PresenceStates.conf || currentState === PresenceStates.presenting) {
      return currentState;
    }

    this.broadcastStatus(PresenceStates.conf);
    return PresenceStates.conf;
  }

  /* Internal FSM Logic for exit-conf transition */
  handleExitConf(currentState) {
    if (this.isInConf) {
      this.isInConf = false;

      if (currentState === PresenceStates.conf) {
        if (this.lowPriorityManualStates.includes(this.lastManualState)) {
          this.lastManualState = PresenceStates.available;
        }
        this.broadcastStatus(this.lastManualState);
        return this.lastManualState;
      } else if (currentState === PresenceStates.presenting) {
        return currentState;
      } else {
        this.broadcastStatus(this.lastManualState);
        return this.lastManualState;
      }
    }

    return currentState;
  }

  /* Internal FSM Logic for start-sharing transition */
  handleStartSharing(currentState) {
    this.isPresenting = true;

    if (currentState === PresenceStates.presenting) {
      return currentState;
    }

    this.broadcastStatus(PresenceStates.presenting);
    return PresenceStates.presenting;
  }

  /* Internal FSM Logic for stop-sharing transition */
  handleStopSharing(currentState) {
    if (this.isPresenting) {
      this.isPresenting = false;

      if (this.isInConf) {
        this.broadcastStatus(PresenceStates.conf);
        return PresenceStates.conf;
      } else if (this.isInCall) {
        this.broadcastStatus(PresenceStates.call);
        return PresenceStates.call;
      }

      if (currentState === PresenceStates.presenting) {
        if (this.lowPriorityManualStates.includes(this.lastManualState)) {
          this.lastManualState = PresenceStates.available;
        }
        this.broadcastStatus(this.lastManualState);
        return this.lastManualState;
      } else {
        this.broadcastStatus(this.lastManualState);
        return this.lastManualState;
      }
    }

    return currentState;
  }

  /* Internal FSM Logic for local-status-change transition */
  handleLocalStatusChange(currentState) {
    let activeCall = this.hasActiveCall();
    let activeConf = this.hasActiveConf();
    let activeScreenShare = this.hasActiveScreenShare();
    this.lastManualState = this.newLocalStatus;

    if ((this.isInCall && activeCall) || (this.isInConf && activeConf) || (this.isPresenting && activeScreenShare)) {
      this.broadcastStatus(this.lastManualState);
      return currentState;
    }

    this.broadcastStatus(this.lastManualState);
    return this.lastManualState;
  }

  /* Internal FSM Logic for remote-status-update transition */
  handleRemoteStatusUpdate(currentState) {
    if (this.allManualStates.includes(this.newRemoteStatus) && (this.isInCall || this.isInConf || this.isPresenting)) {
      this.lastManualState = this.newRemoteStatus;

      if (this.isInCall) {
        this.broadcastStatus(PresenceStates.call);
        return PresenceStates.call;
      } else if (this.isInConf) {
        this.broadcastStatus(PresenceStates.conf);
        return PresenceStates.conf;
      } else {
        this.broadcastStatus(PresenceStates.presenting);
        return PresenceStates.presenting;
      }
    }

    if (currentState === this.newRemoteStatus) {
      return currentState;
    }

    if (this.allActiveStates.includes(currentState) && this.offlineIdleStates.includes(this.newRemoteStatus)) {
      this.broadcastStatus(currentState);
      return currentState;
    }

    // This section combines several transition states
    if (this.allManualStates.includes(currentState)) {
      if (this.allManualStates.includes(this.newRemoteStatus)) {
        this.lastManualState = this.newRemoteStatus;
      }
      return this.newRemoteStatus;
    }

    if (this.allAutoStates.includes(currentState)) {
      if (this.allManualStates.includes(this.newRemoteStatus)) {
        this.lastManualState = this.newRemoteStatus;

        if (this.isInCall || this.isInConf || this.isPresenting) {
          if (this.isInCall) {
            this.broadcastStatus(PresenceStates.call);
            return PresenceStates.call;
          } else if (this.isInConf) {
            this.broadcastStatus(PresenceStates.conf);
            return PresenceStates.conf;
          } else {
            this.broadcastStatus(PresenceStates.presenting);
            return PresenceStates.presenting;
          }
        } else {
          return this.lastManualState;
        }
      }
    }

    // Already checked for currentState === newRemoteSatus
    if (currentState === PresenceStates.call && this.allAutoStates.includes(this.newRemoteStatus)) {
      return this.newRemoteStatus;
    }

    if (currentState === PresenceStates.conf) {
      if (this.newRemoteStatus === PresenceStates.call) {
        if (this.isInConf) {
          this.broadcastStatus(currentState);
          return currentState;
        } else {
          return this.newRemoteStatus;
        }
      } else if (this.newRemoteStatus === PresenceStates.presenting) {
        return this.newRemoteStatus;
      }
    }

    if (currentState === PresenceStates.presenting) {
      if (this.allAutoStates.includes(this.newRemoteStatus)) {
        if (this.isPresenting) {
          this.broadcastStatus(currentState);
          return currentState;
        } else {
          return this.newRemoteStatus;
        }
      }
    }

    if (currentState === PresenceStates.idle) {
      if (this.newRemoteStatus === PresenceStates.offline) {
        this.broadcastStatus(currentState);
        return currentState;
      } else if (this.allManualStates.includes(this.newRemoteStatus)) {
        this.lastManualState = this.newRemoteStatus;
        return this.lastManualState;
      } else if (this.allAutoStates.includes(this.newRemoteStatus)) {
        return this.newRemoteStatus;
      }
    }

    if (currentState === PresenceStates.offline) {
      if (this.allManualStates.includes(this.newRemoteStatus)) {
        this.lastManualState = this.newRemoteStatus;
        return this.lastManualState;
      } else if (this.allAutoStates.includes(this.newRemoteStatus) || this.newRemoteStatus === PresenceStates.idle) {
        return this.newRemoteStatus;
      }
    }

    // Default...no change
    return currentState;
  }

  /*
        External method to trigger FSM transitions.

        Parameters:
            transitionName - a valid transition name
            status - optional, but required for local-status-change and remote-status-update transitionNames
    */
  doTransition(transitionName, status) {
    if (!this.fsm) {
      this.initStateMachine();
    }

    if (status === undefined) {
      status = null;
    }

    // Handle transition
    switch (transitionName) {
      case "local-status-change":
        if (status && this.allManualStates.includes(status)) {
          this.newLocalStatus = status;
          this.fsm.localStatusChange();
        } else {
          logger.log(`${this.module}: Error: local-status-change requires a valid manual status!`);
          return;
        }
        break;
      case "remote-status-update":
        if (status && (this.allActiveStates.includes(status) || this.offlineIdleStates.includes(status))) {
          this.newRemoteStatus = status;
          this.fsm.remoteStatusUpdate();
        } else {
          logger.log(`${this.module}: Error: remote-status-update requires a valid status!`);
          return;
        }
        break;
      case "in-focus":
        this.fsm.inFocus();
        break;
      case "out-of-focus":
        this.fsm.outOfFocus();
        break;
      case "network-online":
        this.fsm.networkOnline();
        break;
      case "active":
        this.fsm.active();
        break;
      case "inactive":
        this.fsm.inactive();
        break;
      case "login":
        this.fsm.login();
        break;
      case "logout":
        this.fsm.logout();
        break;
      case "incoming-call":
        this.fsm.incomingCall();
        break;
      case "outgoing-call":
        this.fsm.outgoingCall();
        break;
      case "exit-call":
        this.fsm.stopSharing();
        this.fsm.exitCall();
        break;
      case "join-conf":
        this.fsm.joinConf();
        break;
      case "exit-conf":
        this.fsm.stopSharing();
        this.fsm.exitConf();
        break;
      case "start-sharing":
        this.fsm.startSharing();
        break;
      case "stop-sharing":
        this.fsm.stopSharing();
        break;
      default:
        logger.log(`${this.module}: Invalid transition: ${transitionName}`);
        break;
    }
  }

  /* 
        External method to set FSM observers.

        Example:
        observers = {
            onAvailable: function() { logger.log("I'm available!"); },
            onEnterState: function(lifecycle) { logger.log("state: " + lifecycle.to); }
        }
    */
  setFSMObservers(observers) {
    if (observers) {
      this.fsm.observe(observers);
    }
  }

  /* Internal helper method */
  getActiveSession() {
    if (this.commService) {
      return this.commService.getActiveSession();
    }
    return null;
  }

  /* Internal helper method */
  hasActiveCall() {
    let activeSession = this.getActiveSession();
    return activeSession && activeSession === PresenceStates.call;
  }

  /* Internal helper method */
  hasActiveConf() {
    let activeSession = this.getActiveSession();
    return activeSession && activeSession === PresenceStates.conf;
  }

  /* Internal helper method */
  hasActiveScreenShare() {
    let activeSession = this.getActiveSession();
    return activeSession && activeSession === PresenceStates.presenting;
  }

  /* Internal helper method */
  async broadcastStatus(status) {
    try {
      if (!this.presenceApi) {
        logger.log(`${this.module}: Error: Presence API not found!`);
        return;
      }
      logger.debug(`${this.module}: Broadcasting status: ${status}`);
      await this.presenceApi.update_presentity(status, null);
    } catch (e) {
      logger.error(`${this.module}: Error in broadcastStatus - status:${status} - ${e.message}`);
    }
  }

  /* Internal helper method */
  async broadcastAvatar(avatarURL) {
    try {
      if (!this.presenceApi) {
        logger.log(`${this.module}: Error: Presence API not found!`);
        return;
      }
      logger.debug(`${this.module}: Broadcasting avatarUrl: ${avatarURL}`);
      await this.presenceApi.update_presentity(null, avatarURL);
    } catch (e) {
      logger.error(`${this.module}: Error in broadcastAvatar - avatarURL:${avatarURL} - ${e.message}`);
    }
  }

  async fetchPresenceStatus(userId) {
    try {
      if (!this.presenceApi) {
        logger.log(`${this.module}: Error: Presence API not found!`);
        return;
      }
      logger.debug(`${this.module}: Fetching presence status for user ${userId}`);
      return await this.presenceApi.get_presentity(userId);
    } catch (e) {
      logger.error(`${this.module}: Error fetching presence status for user ${userId} - ${e.message}`);
    }
  }

  resetState() {
    logger.debug(`${this.module} - Reset state machine`);
    this.fsm = null;
    this.lastManualState = PresenceStates.available;
    this.newRemoteStatus = PresenceStates.offline;
    this.newLocalStatus = PresenceStates.available;
  }
}

export default PresenceService;
