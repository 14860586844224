import moment from "moment";
import { getFromLocalStorage, isEmpty } from "utils/helpers";
import { closeChildWindow, openChildWindow } from "utils/childWindow";
import { localStorageKeys } from "utils/constants";
import { LocalStorageVariableUser } from "utils/localStorageVariableUser";
import { getLogger } from "logger/appLogger";
import getStore from "store/store";
import { googleCalendarEvent } from "store/actions/app";

const logger = getLogger("google");
const isElectron = window.navigator.userAgent.indexOf("Electron") !== -1;

const endpointData = getFromLocalStorage(localStorageKeys.ENDPOINT_DATA);
const host = endpointData ? `https://${endpointData.redirect_url}` : `${window.location.origin}`;
const redirectUrl = `${host}/google-auth`;

const SCOPE = ["https://www.googleapis.com/auth/calendar.readonly", "https://www.googleapis.com/auth/userinfo.email"];
const STATE = isElectron ? "desktop" : "web";

const AUTH_WINDOW_ID = "google.auth.signInWindow";

let loggedIn = false;

interface GoogleTokens {
  accessToken: string | null;
  refreshToken: string | null;
}

interface OAuthToken {
  access_token: string;
  refresh_token: string;
  issued_token_type: string;
  token_type: string;
  expires_in: number;
}

const defaultTokens: GoogleTokens = { refreshToken: null, accessToken: null };

const tokensFromStorage: LocalStorageVariableUser<GoogleTokens> = new LocalStorageVariableUser(
  localStorageKeys.GOOGLE_CALENDAR_AUTHENTICATION
);

let tokens = { ...defaultTokens }; // deep copy
let initDone = false;

const initialSetup = () => {
  if (!initDone && tokensFromStorage.ready()) {
    const tokensCached = tokensFromStorage.get();
    if (tokensCached) {
      tokens = tokensCached;
    }
    initDone = true;
  }
};

const isAuthorized = () => {
  initialSetup();
  return loggedIn;
};

function saveTokens(data: OAuthToken) {
  if (data.refresh_token) {
    tokens.refreshToken = data.refresh_token;
  }
  if (data.access_token) {
    tokens.accessToken = data.access_token;
  }
  tokensFromStorage.set(tokens);
}

function deleteTokens() {
  tokens = { ...defaultTokens }; // deep copy
  tokensFromStorage.delete();
}

function getClientId() {
  const provisiningConfig = getFromLocalStorage(localStorageKeys.PROVISIONING_CONFIG);
  return provisiningConfig?.googleIntegrationClientId || process.env.REACT_APP_GOOGLE_CLIENT_ID;
}

function getClientSecret() {
  const provisiningConfig = getFromLocalStorage(localStorageKeys.PROVISIONING_CONFIG);
  return provisiningConfig?.googleIntegrationSecret || process.env.REACT_APP_GOOGLE_SECRET;
}

function dispatchLogin() {
  if (!loggedIn) {
    loggedIn = true;
    getStore().dispatch(googleCalendarEvent(true));
  }
}

function dispatchLogout() {
  if (loggedIn) {
    loggedIn = false;
    getStore().dispatch(googleCalendarEvent(false));
  }
}

const getAuthUrl = function (): string {
  const ret = [
    `https://accounts.google.com/o/oauth2/v2/auth?scope=${SCOPE.join("+")}`,
    "include_granted_scopes=true",
    "access_type=offline",
    "prompt=consent",
    "state=" + STATE,
    "redirect_uri=" + encodeURIComponent(redirectUrl),
    "response_type=code",
    "client_id=" + getClientId(),
  ].join("&");
  logger.debug(`authUrl=${ret}`);
  return ret;
};

const getTokenFromRefreshToken = async function (refreshToken: string) {
  logger.debug(`getTokenFromRefreshToken code=${refreshToken}`);
  let data;
  try {
    let options = {
      method: "POST",
      body: new URLSearchParams({
        client_id: getClientId(),
        client_secret: getClientSecret(),
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      }),
    };
    let response = await fetch("https://www.googleapis.com/oauth2/v4/token", options);

    if (response.ok) {
      data = await response.json();
      saveTokens(data);
    } else {
      const error = `response=${response.status}, ${response.statusText}`;
      throw new Error(error);
    }
  } catch (error) {
    logger.error("Failed to fetch access token from refresh token: ", error);
    dispatchLogout();
    deleteTokens();
  }

  return data;
};

const logoutFromGoogle = async function () {
  deleteTokens();
  dispatchLogout();
  openChildWindow("https://mail.google.com/mail/u/0/?logout&hl=en", "Google logout", "signOutWindow");
};

const verifyAccessToken = async function (accessToken: string | null) {
  if (accessToken === null) {
    return false;
  }
  try {
    let response = await fetch("https://www.googleapis.com/oauth2/v2/tokeninfo?access_token=" + accessToken);
    if (response.status === 400) {
      if (tokens.refreshToken) {
        return await getTokenFromRefreshToken(tokens.refreshToken);
      } else {
        logger.error("Failed to verify access token, missing refresh token");
        dispatchLogout();
        return false;
      }
    } else if (response.status === 200) {
      dispatchLogin();
      return true;
    } else {
      const body = await response.json();
      const responseLog = `response=${response.status}, ${response.statusText}, ${JSON.stringify(body)}`;
      logger.error(`Failed to verify access token, ${responseLog}`);
      dispatchLogout();
      return false;
    }
  } catch (error) {
    logger.error("Failed to verify access token: ", error);
    return false;
  }
};

const events = async () => {
  initialSetup();
  const items = await _events();
  items?.forEach((item: any) => {
    // adapt google entries to outlook/msgraph-like entries

    if (!item.start.dateTime && item.start.date) {
      item.isAllDay = true;
      item.start.dateTime = item.start.date;
    } else {
      item.isAllDay = false;
    }

    if (!item.end.dateTime && item.end.date) {
      item.end.dateTime = item.end.date;
    }
  });
  return items;
};

const _events = async () => {
  return (await verifyAccessToken(tokens.accessToken)) ? await getUserCalendar() : [];
};

const profile = async () => {
  return (await verifyAccessToken(tokens.accessToken)) ? await getUserProfile() : null;
};

const getUserProfile = async function () {
  let response = await fetch("https://www.googleapis.com/oauth2/v3/userinfo?access_token=" + tokens.accessToken);
  return response.json();
};

const getUserCalendar = async function (): Promise<any[]> {
  try {
    const now = moment();
    const beginDateTime = now.startOf("day").format().replace("+", "-");
    const endDateTime = moment().add(4, "days").endOf("day").format().replace("+", "-");

    let response = await fetch(
      "https://www.googleapis.com/calendar/v3/users/me/calendarList?minAccessRole=owner&access_token=" +
        tokens.accessToken
    );

    if (response.status === 200) {
      let data = await response.json();
      var allCalendarsEvents = data.items.map(function (calendar: { id: string }) {
        var url = [
          "https://www.googleapis.com/calendar/v3/calendars/",
          encodeURIComponent(calendar.id),
          "/events?access_token=" + tokens.accessToken,
          "&timeMin=" + beginDateTime,
          "&timeMax=" + endDateTime,
          "&q=",
          "&singleEvents=true",
        ].join("");
        return url;
      });

      // TODO: only fetching events from first [0] calendar, this may not be the right one
      let events = await fetch(allCalendarsEvents[0]);
      let result = await events.json();
      return result.items;
    } else {
      return [];
    }
  } catch (e) {
    logger.error("getUserCalendar failed with ", e);
    return [];
  }
};

const getTokenFromCode = async function (code: string) {
  logger.debug(`getTokenFromCode code=${code}`);
  try {
    const options = {
      method: "POST",
      body: new URLSearchParams({
        client_id: getClientId(),
        client_secret: getClientSecret(),
        grant_type: "authorization_code",
        code: code,
        redirect_uri: redirectUrl,
      }),
    };
    let response = await fetch("https://www.googleapis.com/oauth2/v4/token", options);

    if (response.status === 200) {
      let tokens = await response.json();
      saveTokens(tokens);
      dispatchLogin();
    } else {
      const body = await response.json();
      const responseLog = `response=${response.status}, ${response.statusText}, ${JSON.stringify(body)}`;
      logger.error(`Failed to fetch access token from code, ${responseLog}`);
      dispatchLogout();
    }

    // remove google popup login window
    // closeChildWindow(AUTH_WINDOW_ID);
  } catch (error) {
    logger.debug("Failed to fetch access token from code: ", error);
  }
};

const loginToGoogle = (name: string) => {
  openChildWindow(getAuthUrl(), name, AUTH_WINDOW_ID);
};

const getCreateUrl = (subject: string, body: string, location: string): URL => {
  let url = new URL("https://calendar.google.com/calendar/render");
  url.searchParams.set("action", "TEMPLATE");
  url.searchParams.set("text", subject);
  url.searchParams.set("details", body);
  url.searchParams.set("location", location);
  url.searchParams.set("access_token", tokens.accessToken!);

  return url;
};

const isValidUrl = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

const isGoogleAuth = (url: string) => {
  return url.split("://", 2)[1].startsWith("google_auth");
};

const extractGoogleCodeFromUrl = async (url: string, caller: string): Promise<boolean> => {
  let ret = false;
  if (!isEmpty(url) && isValidUrl(url) && isGoogleAuth(url)) {
    const urlObject = new URL(url.replace("#/", "?"));
    const code = urlObject.searchParams.get("code");
    const state = urlObject.searchParams.get("state");

    if (state && state === STATE) {
      if (code) {
        logger.debug(`Received google code from ${caller}: ${code} path=${url}`);
        await getTokenFromCode(code);
        try {
          closeChildWindow(AUTH_WINDOW_ID);
        } catch (e) {
          logger.warn("Failed closing auth window");
        }
        ret = true;
      }
    } else {
      logger.warn(`Google code state mismatch recv:'${state}' sent:'${STATE}' from ${caller}`);
    }
  }
  return ret;
};

interface GoogleProviderType {
  loginToGoogle: (name: string) => void;
  getCreateUrl: (subject: string, body: string, location: string) => URL;
  isAuthorized: () => boolean;
  events: () => Promise<any[]>;
  profile: () => Promise<any>;
  deleteTokens: () => void;
  logoutFromGoogle: () => Promise<void>;
  extractGoogleCodeFromUrl: (url: string, caller: string) => Promise<boolean>;
}

const GoogleProvider: GoogleProviderType = {
  loginToGoogle,
  getCreateUrl,
  isAuthorized,
  events,
  profile,
  deleteTokens,
  logoutFromGoogle,
  extractGoogleCodeFromUrl,
};

export default GoogleProvider;
