import { ConfigState } from "store/types/config";
import * as actionTypes from "../actions/types/config";
import { Types } from "Types";

const initialState: ConfigState = {
  extData: "",
  extDataType: "",
  hasExtData: false,
  extDataFlag: false,
  isStatisticsOverlaySet: false,
};

const configReducer = (state = initialState, action: Types.RootAction) => {
  switch (action.type) {
    case actionTypes.SET_EXT_DATA:
      return {
        ...state,
        extData: action.payload.extData,
        extDataType: action.payload.extDataType,
      };

    case actionTypes.SET_EXT_DATA_SUCCEEDED:
      return {
        ...state,
        hasExtData: !!(state.extData && state.extDataType),
      };

    case actionTypes.SET_EXT_DATA_FAILED:
      return {
        ...state,
        extData: "",
        extDataType: "",
        hasExtData: false,
      };

    case actionTypes.SET_STATISTICS_OVERLAY_SUCCEEDED:
      return {
        ...state,
        isStatisticsOverlaySet: action.show,
      };

    default:
      return state;
  }
};

export default configReducer;
