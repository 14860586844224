import { ILogger, getLogger_ } from "./logger";
import { formatBytes, JsonStringify } from "utils/utils";

let _logger: ILogger = getLogger_("logStore");
let _consoleOnlyLogger: ILogger = getLogger_("logStore/console", { consoleOutput: true, logStorageOutput: false });

export interface ILogStore<T> {
  info(): string;
  initialize(): Promise<void>;
  push(t: T): Promise<void>;
  getAll(): Promise<T[]>;
  clearAll(): Promise<void>;
  cleanup(keepLogsInDbForHours: number): Promise<void>;
  flush(): Promise<void>;
}

interface DataBlock<T> {
  timestamp: number;
  data: T;
  size: number;
}

const hoursToMsec = (hours: number): number => {
  return hours * 60 * 60 * 1000;
};

// Trivial memory only storage
export class LogStoreMemory<T> implements ILogStore<T> {
  private _data: DataBlock<T>[] = [];
  private _limit: number;
  private _className = "LogStoreMemory";

  constructor(limit: number = 0) {
    this._limit = limit;
  }

  info(): string {
    return `${this._className}: ${this._data.length}/${this._limit}`;
  }

  initialize(): Promise<void> {
    return Promise.resolve();
  }

  async cleanup(keepLogsInDbForHours: number): Promise<void> {
    if (keepLogsInDbForHours) {
      await this.clearOlderThan(Date.now() - hoursToMsec(keepLogsInDbForHours));
    }
    return Promise.resolve();
  }

  push(t: T): Promise<void> {
    const dataBlock: DataBlock<T> = {
      timestamp: Date.now(),
      data: t,
      size: 0,
    };
    this._data.push(dataBlock);

    if (this._limit > 0) {
      while (this._data.length > this._limit) {
        this._data.shift();
      }
    }

    return Promise.resolve();
  }

  getAll(): Promise<T[]> {
    return Promise.resolve(this._data.map((dataBlock) => dataBlock.data));
  }

  clearAll(): Promise<void> {
    this._data = [];
    _logger.debug(`${this._className}: clearing all`);
    return Promise.resolve();
  }

  clearOlderThan(timestamp: number): Promise<void> {
    const index = this._data.findIndex((dataBlock) => dataBlock.timestamp > timestamp);
    if (index >= 0) {
      _logger.debug(`${this._className}: clearing log array [${index} - ${this._data.length}]`);
      this._data = this._data.slice(index, this._data.length);
    }
    return Promise.resolve();
  }

  flush(): Promise<void> {
    return Promise.resolve();
  }
}

// IndexedDB storage
export class LogStoreIndexedDB<T> implements ILogStore<T> {
  private readonly _dbName;
  private readonly _storeName;
  private readonly _waitForOpenInSec: number;
  private readonly _storeVersion: number;
  private readonly _notInitializedError = new Error("IndexedDB/Store not initialized");
  private _initialized!: Promise<IDBDatabase>;
  private _className = "LogStoreIndexedDB";

  constructor(dbName: string, storeName: string, waitForOpenInSec: number) {
    this._dbName = dbName;
    this._storeName = storeName;
    this._waitForOpenInSec = waitForOpenInSec;
    this._storeVersion = 4;
  }

  private async initializeIndexedDB(): Promise<IDBDatabase> {
    return new Promise<IDBDatabase>((resolve, reject) => {
      let openTimeout: any;
      if (this._waitForOpenInSec > 0) {
        openTimeout = setTimeout(() => {
          const errorTxt = `IndexedDb not ready in ${this._waitForOpenInSec > 0} sec, giving up`;
          _logger.error(errorTxt);
          reject(new Error(errorTxt));
        }, this._waitForOpenInSec * 1000);
        _logger.debug(`Set startup timer for ${this._waitForOpenInSec} sec`);
      }

      try {
        const indexedDB = window.indexedDB;
        _logger.info(`${this._className}: Opening indexedDb=${!!indexedDB}`);
        const openDB = indexedDB.open(this._dbName, this._storeVersion);
        let db: IDBDatabase;

        openDB.onsuccess = () => {
          db = openDB.result;
          _logger.info(`${this._className}: Opened object store ${this._storeName}`);
          resolve(db);
        };

        openDB.onupgradeneeded = (event: IDBVersionChangeEvent) => {
          db = openDB.result;
          if (event.oldVersion !== event.newVersion) {
            _logger.log(
              `${this._className}: Upgrading indexedDB: ${event.oldVersion} -> ${event.newVersion} : delete and re-create object store ${this._storeName}`
            );
            if (db.objectStoreNames.contains(this._storeName)) {
              db.deleteObjectStore(this._storeName);
            }
          }
          if (!db.objectStoreNames.contains(this._storeName)) {
            _logger.log(`${this._className}: Creating object store ${this._storeName}`);
            db.createObjectStore(this._storeName, { autoIncrement: true });
          }
        };

        openDB.onerror = (error) => {
          _logger.error(`${this._className}: Failed to open indexedDB: ${JsonStringify(error)}`);
          reject(error);
        };

        openDB.onblocked = (error) => {
          _logger.error(`${this._className}: Failed to open indexedDB - blocked: ${JsonStringify(error)}`);
          reject(error);
        };
      } catch (error) {
        _logger.error(`${this._className}: Failed to initialize indexedDB: ${JsonStringify(error)}`);
        reject(error);
      } finally {
        if (openTimeout) {
          clearTimeout(openTimeout);
          _logger.debug("Cleared startup timer");
        }
      }
    });
  }

  info(): string {
    return `${this._className}: #${this._dbName}/${this._storeName} init: ${!!this._initialized}`;
  }

  async initialize(): Promise<void> {
    const db = await this.initializeIndexedDB();
    this._initialized = Promise.resolve(db);
    return Promise.resolve();
  }

  async cleanup(keepLogsInDbForHours: number): Promise<void> {
    if (keepLogsInDbForHours) {
      await this.clearOlderThan(Date.now() - hoursToMsec(keepLogsInDbForHours));
    }
  }

  async push(t: T): Promise<void> {
    if (!this._initialized) {
      return Promise.reject(this._notInitializedError);
    }
    const db = await this._initialized;
    return new Promise<void>((resolve, reject) => {
      try {
        const transaction = db.transaction(this._storeName, "readwrite");
        const logs = transaction.objectStore(this._storeName);
        const request = logs.add(t);
        request.onerror = () => {
          throw request.error;
        };
        transaction.oncomplete = () => resolve();
        transaction.commit();
      } catch (error) {
        reject(error);
      }
    });
  }

  async getAll(): Promise<T[]> {
    if (!this._initialized) {
      return Promise.reject(this._notInitializedError);
    }
    const db = await this._initialized;
    return new Promise<T[]>((resolve, reject) => {
      try {
        const transaction = db.transaction(this._storeName, "readonly");
        const logs = transaction.objectStore(this._storeName);
        const request = logs.getAll();
        request.onsuccess = () => {
          resolve(request.result);
        };
        request.onerror = () => {
          throw request.error;
        };
        transaction.oncomplete = () => {};
        transaction.commit();
      } catch (error) {
        reject(error);
      }
    });
  }

  async clearAll(): Promise<void> {
    if (!this._initialized) {
      return Promise.reject(this._notInitializedError);
    }
    const db = await this._initialized;
    return new Promise<void>((resolve, reject) => {
      try {
        const transaction = db.transaction(this._storeName, "readwrite");
        const logs = transaction.objectStore(this._storeName);
        const request = logs.clear();
        request.onerror = () => {
          throw request.error;
        };
        transaction.oncomplete = () => resolve();
        transaction.commit();
        _logger.info(`${this._className}: clearing all`);
      } catch (error) {
        _logger.error(`${this._className}: error clearing ${error}`);
        reject(error);
      }
    });
  }

  private formatDate(timestamp: number): string {
    const date = new Date(timestamp);
    const dateFormatted = date.toLocaleString("sv", { timeZoneName: "short" });
    return `[${timestamp}: ${dateFormatted}]`;
  }

  async clearOlderThan(timestamp: number): Promise<void> {
    if (!this._initialized) {
      return Promise.reject(this._notInitializedError);
    }
    const db = await this._initialized;
    return new Promise<void>((resolve, reject) => {
      try {
        const transaction = db.transaction(this._storeName, "readwrite");
        const logs = transaction.objectStore(this._storeName);
        const openCursorRequest = logs.openCursor();
        let deleteRequest: IDBRequest[] = [];

        _logger.info(`${this._className}: clear older than ${this.formatDate(timestamp)}`);

        openCursorRequest.onsuccess = (event: any) => {
          const cursor: IDBCursorWithValue = event?.target?.result;

          if (cursor) {
            const cursorTimestamp: number = parseInt(cursor.value.timestamp);
            const sizeOfDataInPage: number = parseInt(cursor.value.size);
            const pageName = `${cursor.key} -> ${this.formatDate(
              cursorTimestamp
            )} (${sizeOfDataInPage.toLocaleString()} b)`;
            _logger.debug(`${this._className}: page cursor: ${pageName}`);

            if (cursorTimestamp < timestamp) {
              _logger.info(`${this._className}: deleting page ${pageName})`);

              deleteRequest[cursor.value.timestamp] = cursor.delete();

              // eslint-disable-next-line
              deleteRequest[cursor.value.timestamp].onsuccess = () => {
                _logger.debug(`${this._className}: done deleting ${pageName}`);
              };

              // eslint-disable-next-line
              deleteRequest[cursor.value.timestamp].onerror = () => {
                _logger.error(`${this._className}: error deleting page ${pageName}`);
                throw deleteRequest[cursor.value.timestamp].error;
              };
            }
            _logger.debug(`${this._className}: next cursor`);
            cursor.continue();
          } else {
            _logger.debug(`${this._className}: done iterating cursor`);
            resolve();
          }
        };

        openCursorRequest.onerror = () => {
          throw openCursorRequest.error;
        };
      } catch (error) {
        _logger.error(`${this._className}: error clearing ${error}`);
        reject(error);
      }
    });
  }

  async clearFirstPages(toDeleteBytes: number): Promise<void> {
    if (!this._initialized) {
      return Promise.reject(this._notInitializedError);
    }
    const db = await this._initialized;
    return new Promise<void>((resolve, reject) => {
      try {
        const transaction = db.transaction(this._storeName, "readwrite");
        const logs = transaction.objectStore(this._storeName);
        const openCursorRequest = logs.openCursor();
        let deleteRequest: IDBRequest[] = [];

        _logger.info(`${this._className}: clear ${toDeleteBytes.toLocaleString()} bytes`);

        openCursorRequest.onsuccess = (event: any) => {
          const cursor: IDBCursorWithValue = event?.target?.result;

          if (cursor) {
            const cursorTimestamp: number = parseInt(cursor.value.timestamp);
            const sizeOfDataInPage: number = parseInt(cursor.value.size);
            const pageName = `${cursor.key} -> ${this.formatDate(
              cursorTimestamp
            )} (${sizeOfDataInPage.toLocaleString()} b)`;
            _logger.debug(`${this._className}: page cursor: ${pageName}`);

            if (toDeleteBytes > 0) {
              _logger.info(`${this._className}: deleting page ${pageName})`);

              deleteRequest[cursor.value.timestamp] = cursor.delete();
              toDeleteBytes -= sizeOfDataInPage;

              // eslint-disable-next-line
              deleteRequest[cursor.value.timestamp].onsuccess = () => {
                _logger.debug(`${this._className}: done deleting ${pageName}`);
              };

              // eslint-disable-next-line
              deleteRequest[cursor.value.timestamp].onerror = () => {
                _logger.error(`${this._className}: error deleting page ${pageName}`);
                throw deleteRequest[cursor.value.timestamp].error;
              };
            }

            if (toDeleteBytes > 0) {
              _logger.debug(`${this._className}: next cursor, still to delete ${toDeleteBytes.toLocaleString()} b`);
              cursor.continue();
            } else {
              _logger.debug(`${this._className}: done deleting`);
              resolve();
            }
          } else {
            _logger.debug(`${this._className}: done iterating cursor`);
            resolve();
          }
        };

        openCursorRequest.onerror = () => {
          throw openCursorRequest.error;
        };
      } catch (error) {
        _logger.error(`${this._className}: error clearing ${error}`);
        reject(error);
      }
    });
  }

  flush(): Promise<void> {
    return Promise.resolve();
  }
}

// IndexedDB storage with memory buffering
export class LogStoreBufferedIndexedDB implements ILogStore<string> {
  private _indexedLogStore: LogStoreIndexedDB<DataBlock<string[]>>;
  private _data: string[] = [];
  private _datalen: number = 0;
  private readonly _bufferSize;
  private _className = "LogStoreBufferedIndexedDB";
  private readonly _maxGetAllSize: number;
  private readonly _newlineLength: number;

  constructor(
    dbName: string,
    storeName: string,
    bufferSize: number,
    maxGetAllSize: number,
    newlineLength: number,
    waitForOpenInSec: number
  ) {
    this._indexedLogStore = new LogStoreIndexedDB<DataBlock<string[]>>(dbName, storeName, waitForOpenInSec);
    this._bufferSize = bufferSize;
    this._maxGetAllSize = maxGetAllSize;
    this._newlineLength = newlineLength;
  }

  info(): string {
    return (
      `${this._className}: #${this._data.length} ` +
      `${this._datalen}b/${this._bufferSize}b ` +
      `(${this._indexedLogStore.info()})`
    );
  }

  async initialize(): Promise<void> {
    return await this._indexedLogStore.initialize();
  }

  async push(t: string): Promise<void> {
    this._data.push(t);
    this._datalen += t.length;
    return this._datalen >= this._bufferSize ? await this.flush() : Promise.resolve();
  }

  async getAll(): Promise<string[]> {
    await this.flush();
    await this.clearToFitIntoSize(this._maxGetAllSize);
    const sumBytes = await this.sizeInBytes();
    const dataBlocks: DataBlock<string[]>[] = await this._indexedLogStore.getAll();
    _logger.debug(
      `${this._className}: will collect ${sumBytes.toLocaleString()} bytes from ${dataBlocks.length} pages`
    );
    const ret = dataBlocks
      .map((dataBlock) => {
        _logger.debug(`${this._className}: collecting ${dataBlock.size} bytes`);
        return dataBlock.data;
      })
      .flat();
    _logger.debug(`${this._className}: collecting done`);
    return ret;
  }

  async clearAll(): Promise<void> {
    this._data = [];
    this._datalen = 0;
    return await this._indexedLogStore.clearAll();
  }

  async cleanup(keepLogsInDbForHours: number): Promise<void> {
    await this._indexedLogStore.cleanup(keepLogsInDbForHours);
    await this.clearToFitIntoSize(this._maxGetAllSize);
  }

  private async clearToFitIntoSize(bytesMax: number): Promise<void> {
    if (bytesMax) {
      const dbg = (size: number, max: number) => {
        const op = size < max ? "fits into" : "exceeds";
        _logger.debug(`${this._className}: checking size: ${size.toLocaleString()} ${op} max: ${max.toLocaleString()}`);
      };

      let sizeInBytes = await this.sizeInBytes();
      dbg(sizeInBytes, bytesMax);

      while (sizeInBytes > bytesMax) {
        await this._indexedLogStore.clearFirstPages(sizeInBytes - bytesMax);
        sizeInBytes = await this.sizeInBytes();
        dbg(sizeInBytes, bytesMax);
      }
    }
  }

  async sizeInBytes(): Promise<number> {
    const dataBlocks: DataBlock<string[]>[] = await this._indexedLogStore.getAll();
    return dataBlocks.reduce((acc, dataBlock) => acc + dataBlock.size, 0);
  }

  async flush(): Promise<void> {
    try {
      _consoleOnlyLogger.debug(`${this._className}:flush (memory) buffer: start`);

      const dataBlock: DataBlock<string[]> = {
        timestamp: Date.now(),
        data: this._data,
        size: this._datalen + this._data.length * this._newlineLength,
      };

      // clear buffer for upcoming writes before first await
      _consoleOnlyLogger.debug(`${this._className}:flush (memory) buffer: cleanup ${formatBytes(this._datalen)}`);
      this._data = [];
      this._datalen = 0;

      _consoleOnlyLogger.debug(`${this._className}:flush (memory) buffer: push`);
      await this._indexedLogStore.push(dataBlock);

      // explicit "delete" just for the sake of documenting where the buffer can be garbage collected
      dataBlock.data = [];
      dataBlock.size = 0;

      _consoleOnlyLogger.debug(`${this._className}:flush (memory) buffer: flush`);
      await this._indexedLogStore.flush();
    } catch (error) {
      _consoleOnlyLogger.error(`${this._className}:flush ${error}`);
    } finally {
      _consoleOnlyLogger.debug(`${this._className}:flush (memory) buffer: done`);
    }
  }
}
