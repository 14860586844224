import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { isSafari } from "react-device-detect";
import { selectSpeaker } from "store/actions/devices";
import { useTranslation } from "react-i18next";
import { useSystemDefaultName } from "hooks/useLanguage";
import { test, sortDevices } from "utils/helpers";

import MenuItem from "@mui/material/MenuItem";
import GeneralSelectList from "components/GeneralSelectList/GeneralSelectList";
import { RootState } from "store/root-reducer";
import { SelectChangeEvent } from "@mui/material/Select";

const useStyles = makeStyles((theme) => ({
  defaultDeviceMessage: {
    padding: "0px 25px",
    fontSize: "12px",
  },
}));

const SpeakerSelectList = ({ showTitle = true }: { showTitle?: boolean }) => {
  const selectedSpeaker = useSelector((state: RootState) => state.devices.selectedSpeaker);
  const speakers = useSelector((state: RootState) => state.devices.speakers);
  const sortedSpeakers = sortDevices(speakers);
  const processName = useSystemDefaultName();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const muiStyles = useStyles();
  const showDefaultOutputMessage = sortedSpeakers.length === 1 && isSafari;

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const localSpeaker = event.target.value;
    if (localSpeaker) {
      dispatch(selectSpeaker(localSpeaker));
    }
  };

  const getItems = () => {
    if (sortedSpeakers && sortedSpeakers.length > 0) {
      const result = sortedSpeakers.map((speaker, index) => {
        const list = [];
        const name = processName(speaker);
        list.push(
          <MenuItem key={index + 1} value={speaker} {...test("SELECT_SPEAKER_ITEM")}>
            {name}
          </MenuItem>
        );
        if (showDefaultOutputMessage) {
          list.push(
            <MenuItem
              key={index + 2}
              classes={{ root: muiStyles.defaultDeviceMessage }}
              disabled
              value={`BROWSER_USES_DEFAULT_DEVICES_MESSAGE_${index}`}
            >
              {t("BROWSER_USES_DEFAULT_DEVICES_MESSAGE")}
            </MenuItem>
          );
        }
        return list;
      });
      return result;
    }
  };

  const value = sortedSpeakers && sortedSpeakers.length > 0 ? (selectedSpeaker ? selectedSpeaker : "") : "";

  return (
    <div className="device-select-container">
      <GeneralSelectList
        id="speaker-select"
        title={showTitle ? t("SPEAKER") : null}
        items={getItems()}
        onItemSelect={handleSelectChange}
        noResultsText={t("NO_SPEAKER")}
        noSelectText={t("NO_ACTIVE_SPEAKER")}
        selectedItemName={value}
        tooltip={processName(value)}
        {...test("SELECT_SPEAKER")}
      />
    </div>
  );
};

export default SpeakerSelectList;
