import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { themeActions } from "store/actions/theme";

import useMediaQuery from "@mui/material/useMediaQuery";

const ToggleDrawer = () => {
  const canToggleDrawer = useSelector((state) => state.theme.canToggleDrawer);
  const openDrawer = useSelector((state) => state.theme.openDrawer);
  const dispatch = useDispatch();
  const { toggleDrawer } = themeActions;
  const hidden = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const onToggle = () => {
    if (canToggleDrawer) {
      dispatch(toggleDrawer(!openDrawer));
    }
  };

  return hidden ? null : (
    <IconButton style={{ color: "#3BA5D7" }} onClick={onToggle} size="large">
      <MenuIcon></MenuIcon>
    </IconButton>
  );
};

export default ToggleDrawer;
