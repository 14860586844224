import { Components, Theme } from "@mui/material";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

const MuiOutlinedInput: Components["MuiOutlinedInput"] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const palette = (theme as Theme).palette;
      return {
        borderColor: palette.border.input.notSelected,
        backgroundColor: palette.background.filledInputBackground,
        color: palette.text.secondary,
        [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
          color: palette.text.primary,
          borderColor: palette.border.input.focused,
        },
        [`&.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: palette.border.input.error,
        },
        [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: palette.border.input.disabled,
        },
      };
    },
    notchedOutline: ({ theme }) => {
      const palette = (theme as Theme).palette;
      return {
        borderColor: palette.border.input.notSelected,
      };
    },
    input: ({ theme }) => {
      const palette = (theme as Theme).palette;
      return {
        "&::placeholder": {
          color: palette.text.secondary,
          opacity: 1,
        },
      };
    },
  },
};

export default MuiOutlinedInput;
