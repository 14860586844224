import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import { matrixActions } from "store/actions/matrix";
import * as devicesActionCreators from "store/actions/devices";
import * as googleAnalytics from "store/actions/googleAnalytics";
import DeviceToggle, { Toggle_Devices } from "components/DeviceToggle/DeviceToggle";
import { test } from "utils/helpers";
import { RootState } from "store/root-reducer";

interface SpeakerToggleOwnProps {
  showLabel?: boolean;
}

const mapStateToProps = ({ devices, matrix }: RootState) => ({
  hasActiveMatrixCall: matrix.hasActiveMatrixCall,
  matrixSpeakerMuted: matrix.matrixSpeakerMuted,
  speakers: devices.speakers,
  selectedSpeaker: devices.selectedSpeaker,
  isSpeakerTurnedOn: devices.isSpeakerTurnedOn,
  isSpeakerDisabled: devices.isSpeakerDisabled,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(devicesActionCreators, dispatch),
  ...bindActionCreators(googleAnalytics, dispatch),
  ...bindActionCreators(matrixActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type SpeakerToggleProps = ConnectedProps<typeof connector> & SpeakerToggleOwnProps;

const SpeakerToggle = ({
  hasActiveMatrixCall,
  matrixSpeakerMuted,
  muteMatrixSpeaker,
  speakers,
  selectedSpeaker,
  isSpeakerDisabled,
  isSpeakerTurnedOn,
  speakerTurnOn,
  speakerTurnOff,
  showLabel,
  rightClickOnDevice,
}: SpeakerToggleProps) => {
  const { t } = useTranslation();
  const [speakerOn, setSpeakerOn] = useState(false);
  const speakerOnClick = () => {
    if (hasActiveMatrixCall) {
      muteMatrixSpeaker(!matrixSpeakerMuted);
    } else {
      isSpeakerTurnedOn ? speakerTurnOff() : speakerTurnOn();
    }
  };

  const speakerOnMouseDown = (event: { nativeEvent: { which: number } }) => {
    if (event.nativeEvent.which === 3) {
      rightClickOnDevice("speaker");
    }
  };

  useEffect(() => {
    setSpeakerOn(hasActiveMatrixCall ? !matrixSpeakerMuted : isSpeakerTurnedOn);
  }, [hasActiveMatrixCall, isSpeakerTurnedOn, matrixSpeakerMuted]);

  return (
    <div className="device-toggle">
      <DeviceToggle
        {...test("SPEAKER_TOGGLE")}
        disabled={!speakers.length || !selectedSpeaker || isSpeakerDisabled}
        on={speakerOn}
        onClick={speakerOnClick}
        onMouseDown={speakerOnMouseDown}
        tooltip={speakerOn ? t("TOOLTIP_SPEAKER_ON") : t("TOOLTIP_SPEAKER_OFF")}
        device={Toggle_Devices.SPEAKER}
      />
      {showLabel && (
        <div className="toggle-label">
          {isSpeakerDisabled
            ? t("SPEAKER_DISABLED")
            : selectedSpeaker
            ? selectedSpeaker.name
            : speakers.length
            ? t("NO_ACTIVE_SPEAKER")
            : t("NO_SPEAKER")}
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerToggle);
