export const deviceDisableReason = {
  DISABLED_BY_PROFILE: "DISABLED_BY_PROFILE",
  NO_PERMISSION: "NO_PERMISSION",
  NO_DEVICE: "NO_DEVICE",
  SUSPENDED: "SUSPENDED",
};
export const allowedPortalURLs = [window.location.host];

export const PRIVACY_POLICY_URL = "https://www.vidyo.com/privacy-policy";
export const TERMS_AND_CONDITIONS_URL = "https://www.vidyo.com/cloudservicestos";
export const HELP_LINK_URL = "https://docs.enghouseinteractive.com/enghouseconnect/help/Home.htm";

export const tcData = {
  LINK_URL: "https://links.vidyocloud.com",
  LINK_JSON_NAME: "links.json",
  TENANT_JSON_NAME: "tenantsdirectory.json",
  TERMS_JSON_NAME: "terms",
  PRIVACY_JSON_NAME: "privacy",
  LOCAL_VERSION: "tcConsentVersion",
  SERVER_TIMEOUT: 10 * 1000,
};

const APP_PREFIX = "ec.";

export const localStorageKeys = {
  // shared keys
  LANGUAGE: "language",
  SAVED_THEME: "savedTheme",
  PREFER_SYSTEM_SCHEME: "preferSystemScheme",

  // app-only keys
  APP_STORAGE: APP_PREFIX + "appStorage",
  PROVISIONING_CONFIG: APP_PREFIX + "provisioningConfig",
  ENDPOINT_DATA: APP_PREFIX + "endpointData",
  LOGGING_CONFIGURATION: APP_PREFIX + "loggingConfiguration",
  LOGSTORE_CONFIGURATION: APP_PREFIX + "logstoreConfiguration",
  USER_SETTINGS: APP_PREFIX + "userSettings",
  JOIN_LINK: APP_PREFIX + "joinLink",
  CURRENT_USER: APP_PREFIX + "currentUser",
  TENANT_NAME: APP_PREFIX + "tenantName",
  SPEED_DIAL: APP_PREFIX + "savedSpeedDial",
  PHONE_NUMBER_LIST: APP_PREFIX + "phoneNumberList",
  SHOW_LINK_PREVIEW: APP_PREFIX + "showLinkPreview",

  // prefix
  USER_STORAGE_PREFIX: APP_PREFIX + "userStorage.",

  // user-keys (fields)
  OUTLOOK_AUTHENTICATION_RECORD: "outlookAuthenticationRecord",
  GOOGLE_CALENDAR_AUTHENTICATION: APP_PREFIX + "googleCalendarAuthentication",
};

export const PBX_SEARCH_DEBOUNCE_DELAY_MS = 500;
export const MEETING_PARTICIPANTS_SIDEBAR_WIDTH = 280;

export const GUEST_APPSERVICE_USERNAME = "guest-appservice";
