import React, { useRef, useEffect } from "react";

const Video = ({ ...props }) => {
  const videoRef: React.LegacyRef<HTMLVideoElement> | undefined = useRef(null);
  let { stream, autoPlay } = props;
  autoPlay = autoPlay === "undefined" ? true : autoPlay;

  useEffect(() => {
    const currentVideo = videoRef.current;
    if (stream) {
      currentVideo!.srcObject = stream;
      if (autoPlay !== false) {
        currentVideo!.play().catch((e) => {});
      }
    } else {
      currentVideo!.pause();
    }
    return () => {
      if (stream) {
        (stream as MediaStream).getVideoTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [stream, autoPlay]);

  useEffect(() => {
    const { current: videoElement } = videoRef;
    videoElement?.setAttribute("muted", "");
  }, []);

  return <video playsInline muted ref={videoRef} {...props} />;
};

export default React.memo(Video);
