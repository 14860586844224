import { buffers } from "redux-saga";
import { eventChannel, END } from "redux-saga";
import { put, call, select, takeLatest } from "redux-saga/effects";
import { getCallAPIProvider } from "services/CallAPIProvider";
import * as devicesActions from "../actions/devices";
import * as callActionTypes from "../actions/types/call";
import * as googleAnalyticsActions from "../actions/googleAnalytics";
import * as deviceActionTypes from "../actions/types/devices";
import { callActions } from "../actions/call";
import { presenceActions } from "../actions/presence";
import { searchProvisioiningByListOfEmails } from "../sagas/provisioning";
import { matrixActions } from "store/actions/matrix";

const callProvider = getCallAPIProvider();

function* startCall(action) {
  try {
    yield put(devicesActions.speakerTurnOn());
    yield put(callActions.subscribeOnModerationStatusChanges());
    const callChannel = yield call(createCallChannel, action.payload);
    yield takeLatest(callChannel, handleCallStatus);
  } catch (e) {
    yield put(callActions.unsubscribeFromModerationStatusChanges());
    yield put({
      type: callActionTypes.START_CALL_FAILED,
      message: e.message,
    });
  }
}

function* getCallProperties(action) {
  try {
    const properties = yield callProvider.getCallProperties(action.payload);
    yield put({
      type: callActionTypes.GET_CALL_PROPERTIES_SUCCEEDED,
      properties,
    });
    yield put(googleAnalyticsActions.joinAnalytics("roomLink"));
    yield put(googleAnalyticsActions.joinRoomType("Guest"));
  } catch (e) {
    yield put({
      type: callActionTypes.GET_CALL_PROPERTIES_FAILED,
      message: e.message,
    });
  }
}

function createCallChannel(action) {
  return eventChannel((emit) => {
    const onDisconnected = (reason, error) => {
      emit({ isCallStarted: false, reason, error });
      emit(END);
    };

    callProvider.startCall({ ...action.data, onDisconnected }).then(() => {
      if (action.participantsEmails && action.participantsEmails.length > 0)
        emit({ isCallStarted: true, reason: null, error: null, participantsEmails: action.participantsEmails });
      else emit({ isCallStarted: true });
    });

    return () => {};
  });
}

function* handleCallStatus({ isCallStarted, reason, error, participantsEmails }) {
  if (isCallStarted) {
    const callStartedTime = new Date().getTime();

    const opts = {
      transitionName: "join-conf",
    };

    yield put(presenceActions.changePresence(opts));
    yield put(callActions.getCallProperties());
    yield put(callActions.subscribeOnParticipantsChanges());
    yield put(callActions.subscribeOnRecorderStatusChanges());
    yield put(callActions.subscribeOnLocalWindowShareChanges());
    yield put(callActions.subscribeOnRemoteWindowShareChanges());
    yield put(devicesActions.subscribeOnRemoteCamerasChanges());
    yield put(devicesActions.subscribeOnRemoteMicrophonesChanges());

    if (participantsEmails) {
      // If it's a meeting, gets the keycloak information about the participants
      const infos = [];
      const response = yield call(searchProvisioiningByListOfEmails, { payload: participantsEmails });
      if (response) {
        response.forEach((info) => {
          const { username, firstName, lastName, email, presence } = info;
          infos.push({
            username: username,
            displayName: `${firstName} ${lastName}`,
            email: email,
            avatarUrl: presence && presence.avatarUrl ? presence.avatarUrl : null,
            status: presence && presence.status ? presence.status : "offline",
          });
        });
      }
      yield put({
        type: callActionTypes.START_CALL_SUCCEEDED,
        callStartedTime: callStartedTime,
        participantsInfo: infos,
      });
    } else {
      yield put({
        type: callActionTypes.START_CALL_SUCCEEDED,
        callStartedTime: callStartedTime,
      });
    }
  } else {
    if (error) {
      yield put({ type: callActionTypes.START_CALL_FAILED, reason });
      if (reason !== "VIDYO_CONNECTORFAILREASON_InvalidToken") {
        yield put(devicesActions.cameraTurnOff());
        yield put(devicesActions.microphoneTurnOff());
        yield put(devicesActions.speakerTurnOff());
        yield put(devicesActions.unsubscribeFromRemoteCamerasChanges());
        yield put(devicesActions.unsubscribeFromRemoteMicrophonesChanges());

        yield put(callActions.unsubscribeFromParticipantsChanges());
        yield put(callActions.unsubscribeFromModerationStatusChanges());
        yield put(callActions.unsubscribeFromRecorderStatusChanges());
        yield put(callActions.unsubscribeFromLocalWindowShareChanges());
        yield put(callActions.unsubscribeFromRemoteWindowShareChanges());
      }
    } else {
      yield put({ type: callActionTypes.END_CALL_SUCCEEDED, reason });
      let label = "unknown";
      switch (reason) {
        case "VIDYO_CONNECTORDISCONNECTREASON_Disconnected":
          label = "userLeft";
          break;
        case "VIDYO_CONNECTORDISCONNECTREASON_ConnectionLost":
        case "VIDYO_CONNECTORDISCONNECTREASON_ConnectionTimeout":
        case "VIDYO_CONNECTORDISCONNECTREASON_NoResponse":
        case "VIDYO_CONNECTORDISCONNECTREASON_Terminated":
        case "VIDYO_CONNECTORDISCONNECTREASON_MiscLocalError":
        case "VIDYO_CONNECTORDISCONNECTREASON_MiscRemoteError":
        case "VIDYO_CONNECTORDISCONNECTREASON_MiscError":
          label = "userDropped";
          break;
        default:
          break;
      }
      yield put(googleAnalyticsActions.callEndAnalytics(label));
    }
  }
}

function* endCall() {
  try {
    yield put(callActions.updateRecorderStatus(false));
    yield callProvider.endCall();
  } catch (e) {
    yield put({
      type: callActionTypes.END_CALL_FAILED,
      message: e && e.message ? e.message : "End call failed",
    });
  } finally {
    const opts = {
      transitionName: "exit-conf",
    };
    yield put(presenceActions.changePresence(opts));

    yield put(devicesActions.cameraTurnOff());
    yield put(devicesActions.microphoneTurnOff());
    yield put(devicesActions.speakerTurnOff());
    yield put(devicesActions.unsubscribeFromRemoteCamerasChanges());
    yield put(devicesActions.unsubscribeFromRemoteMicrophonesChanges());

    yield put(callActions.unsubscribeFromParticipantsChanges());
    yield put(callActions.unsubscribeFromModerationStatusChanges());
    yield put(callActions.unsubscribeFromRecorderStatusChanges());
    yield put(callActions.unsubscribeFromLocalWindowShareChanges());
    yield put(callActions.unsubscribeFromRemoteWindowShareChanges());

    yield put(matrixActions.setRedirectToAdhocConf(false));
  }
}

function* assignVideoRenderer(action) {
  try {
    const result = yield callProvider.assignVideoRenderer(action.payload);

    yield put({
      type: callActionTypes.ASSIGN_VIDEO_RENDERER_SUCCEEDED,
      result,
    });
  } catch (e) {
    yield put({
      type: callActionTypes.ASSIGN_VIDEO_RENDERER_FAILED,
      message: e.message,
    });
  }
}

function* subscribeOnParticipantsChanges() {
  try {
    const participantsChannel = yield createParticipantsChangesChannel();
    yield takeLatest(participantsChannel, handleParticipantsChanges);

    yield put({
      type: callActionTypes.PARTICIPANTS_CHANGES_SUBSCRIBE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: callActionTypes.PARTICIPANTS_CHANGES_SUBSCRIBE_FAILED,
      message: e.message,
    });
  }
}

function createParticipantsChangesChannel() {
  return eventChannel((emit) => {
    callProvider.subscribeOnParticipantsChanges((participants) => {
      emit(participants);
    });
    return () => {
      callProvider.unsubscribeFromParticipantsChanges();
    };
  });
}

function* handleParticipantsChanges(participants) {
  yield put(callActions.updateParticipants(participants));
}

function* unsubscribeFromParticipantsChanges() {
  try {
    const result = yield callProvider.unsubscribeFromParticipantsChanges();

    yield put({
      type: callActionTypes.PARTICIPANTS_CHANGES_UNSUBSCRIBE_SUCCEEDED,
      result,
    });
  } catch (e) {
    yield put({
      type: callActionTypes.PARTICIPANTS_CHANGES_UNSUBSCRIBE_SUCCEEDED,
      message: e.message,
    });
  }
}

function* subscribeOnRecorderStatusChanges() {
  try {
    const recorderStatusChannel = yield createRecorderStatusChannel();
    yield takeLatest(recorderStatusChannel, handleRecorderStatusChanges);

    yield put({
      type: callActionTypes.RECORDER_STATUS_CHANGES_SUBSCRIBE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: callActionTypes.RECORDER_STATUS_CHANGES_SUBSCRIBE_FAILED,
      message: e.message,
    });
  }
}

function createRecorderStatusChannel() {
  return eventChannel((emit) => {
    callProvider.subscribeOnRecorderStatusChanges((recorderStatus, paused, webCasting) => {
      emit({
        recorderStatus: recorderStatus,
        paused: paused,
        webCasting: webCasting,
      });
    });
    return () => {
      callProvider.unsubscribeFromRecorderStatusChanges();
    };
  });
}

function* subscribeOnModerationStatusChanges() {
  try {
    const moderationStatusChannel = yield createModerationStatusChannel();
    yield takeLatest(moderationStatusChannel, handleModerationStatusChanges);

    yield put({
      type: callActionTypes.MODERATION_STATUS_CHANGES_SUBSCRIBE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: callActionTypes.MODERATION_STATUS_CHANGES_SUBSCRIBE_FAILED,
      message: e.message,
    });
  }
}

function* unsubscribeFromModerationStatusChanges() {
  try {
    const result = yield callProvider.unsubscribeFromModerationStatusChanges();

    yield put({
      type: callActionTypes.MODERATION_STATUS_CHANGES_UNSUBSCRIBE_SUCCEEDED,
      result,
    });
  } catch (e) {
    yield put({
      type: callActionTypes.MODERATION_STATUS_CHANGES_UNSUBSCRIBE_SUCCEEDED,
      message: e.message,
    });
  }
}

function createModerationStatusChannel() {
  return eventChannel((emit) => {
    callProvider.subscribeOnModerationStatusChanges((moderationStatus) => {
      emit(moderationStatus);
    });
    return () => {
      callProvider.unsubscribeFromModerationStatusChanges();
    };
  });
}

function* handleModerationStatusChanges(moderationStatus) {
  yield put(callActions.updateModerationStatus(moderationStatus));
}

function* handleRecorderStatusChanges(recorderStatus, paused, webCasting) {
  yield put(
    callActions.updateRecorderStatus(recorderStatus.recorderStatus, recorderStatus.paused, recorderStatus.webCasting)
  );
}

function* unsubscribeFromRecorderStatusChanges() {
  try {
    const result = yield callProvider.unsubscribeFromRecorderStatusChanges();

    yield put({
      type: callActionTypes.RECORDER_STATUS_CHANGES_UNSUBSCRIBE_SUCCEEDED,
      result,
    });
  } catch (e) {
    yield put({
      type: callActionTypes.RECORDER_STATUS_CHANGES_UNSUBSCRIBE_SUCCEEDED,
      message: e.message,
    });
  }
}

function* subscribeOnRemoteCamerasChanges() {
  try {
    const remoteCamerasChannel = yield createRemoteCameraChangesChannel();
    yield takeLatest(remoteCamerasChannel, handleRemoteCameraChanges);

    yield put({
      type: deviceActionTypes.REMOTE_CAMERAS_CHANGES_SUBSCRIBE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: deviceActionTypes.REMOTE_CAMERAS_CHANGES_SUBSCRIBE_FAILED,
      message: e.message,
    });
  }
}

function createRemoteCameraChangesChannel() {
  return eventChannel((emit) => {
    callProvider.subscribeOnRemoteCamerasChanges((cameras) => {
      emit(cameras);
    });
    return () => {
      callProvider.unsubscribeFromRemoteCamerasChanges();
    };
  });
}

function* handleRemoteCameraChanges(remoteCameras) {
  yield put(devicesActions.updateRemoteCameras(remoteCameras));
}

function* unsubscribeFromRemoteCamerasChanges() {
  try {
    const result = yield callProvider.unsubscribeFromRemoteCamerasChanges();

    yield put({
      type: deviceActionTypes.REMOTE_CAMERAS_CHANGES_UNSUBSCRIBE_SUCCEEDED,
      result,
    });
  } catch (e) {
    yield put({
      type: deviceActionTypes.REMOTE_CAMERAS_CHANGES_UNSUBSCRIBE_SUCCEEDED,
      message: e.message,
    });
  }
}

function* subscribeOnRemoteMicrophonesChanges() {
  try {
    const remoteMicrophonesChannel = yield createRemoteMicrophoneChangesChannel();
    yield takeLatest(remoteMicrophonesChannel, handleRemoteMicrophoneChanges);

    yield put({
      type: deviceActionTypes.REMOTE_MICROPHONES_CHANGES_SUBSCRIBE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: deviceActionTypes.REMOTE_MICROPHONES_CHANGES_SUBSCRIBE_FAILED,
      message: e.message,
    });
  }
}

function createRemoteMicrophoneChangesChannel() {
  return eventChannel((emit) => {
    callProvider.subscribeOnRemoteMicrophonesChanges((remoteMicrophones) => {
      emit(remoteMicrophones);
    });
    return () => {
      callProvider.unsubscribeFromRemoteMicrophonesChanges();
    };
  });
}

function* handleRemoteMicrophoneChanges(remoteMicrophones) {
  yield put(devicesActions.updateRemoteMicrophones(remoteMicrophones));
}

function* unsubscribeFromRemoteMicrophonesChanges() {
  try {
    const result = yield callProvider.unsubscribeFromRemoteMicrophonesChanges();

    yield put({
      type: deviceActionTypes.REMOTE_MICROPHONES_CHANGES_UNSUBSCRIBE_SUCCEEDED,
      result,
    });
  } catch (e) {
    yield put({
      type: deviceActionTypes.REMOTE_MICROPHONES_CHANGES_UNSUBSCRIBE_SUCCEEDED,
      message: e.message,
    });
  }
}

function* subscribeOnLocalWindowShareChanges(action) {
  try {
    const localWindowShareChannel = createLocalWindowShareChannel();
    yield takeLatest(localWindowShareChannel, handleLocalWindowShareChanges);

    yield put({
      type: callActionTypes.LOCAL_WINDOW_SHARE_CHANGES_SUBSCRIBE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: callActionTypes.LOCAL_WINDOW_SHARE_CHANGES_SUBSCRIBE_FAILED,
      message: e.message,
    });
  }
}

function createLocalWindowShareChannel() {
  return eventChannel((emit) => {
    callProvider.subscribeOnLocalWindowShareChanges((shares) => {
      emit(shares);
    });
    return () => {
      callProvider.unsubscribeFromLocalWindowShareChanges();
    };
  }, buffers.expanding());
}

function* handleLocalWindowShareChanges(shares) {
  const stateSelectedShare = yield select((state) => state.call.selectedShare);
  const newSelectedShare = shares.find((s) => s.selected) || null;
  if (stateSelectedShare && !newSelectedShare) {
    yield put(callActions.stopWindowShare());
  }
  yield put(callActions.updateLocalWindowShares(shares));
}

function* unsubscribeFromLocalWindowShareChanges() {
  try {
    const result = yield callProvider.unsubscribeFromLocalWindowShareChanges();

    yield put({
      type: callActionTypes.LOCAL_WINDOW_SHARE_CHANGES_UNSUBSCRIBE_SUCCEEDED,
      result,
    });
  } catch (e) {
    yield put({
      type: callActionTypes.LOCAL_WINDOW_SHARE_CHANGES_UNSUBSCRIBE_FAILED,
      message: e.message,
    });
  }
}

function* subscribeOnRemoteWindowShareChanges(action) {
  try {
    const remoteWindowShareChannel = createRemoteWindowShareChannel();
    yield takeLatest(remoteWindowShareChannel, handleRemoteWindowShareChanges);

    yield put({
      type: callActionTypes.REMOTE_WINDOW_SHARE_CHANGES_SUBSCRIBE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: callActionTypes.REMOTE_WINDOW_SHARE_CHANGES_SUBSCRIBE_FAILED,
      message: e.message,
    });
  }
}

function createRemoteWindowShareChannel() {
  return eventChannel((emit) => {
    callProvider.subscribeOnRemoteWindowShareChanges((shares) => {
      emit(shares);
    });
    return () => {
      callProvider.unsubscribeFromRemoteWindowShareChanges();
    };
  }, buffers.expanding());
}

function* handleRemoteWindowShareChanges(shares) {
  if (shares.length) {
    const selectedShare = yield select((state) => state.call.selectedShare);
    if (selectedShare) {
      yield put(callActions.stopWindowShare());
    }
  }
  yield put(callActions.updateRemoteWindowShares(shares));
}

function* unsubscribeFromRemoteWindowShareChanges() {
  try {
    const result = yield callProvider.unsubscribeFromRemoteWindowShareChanges();

    yield put({
      type: callActionTypes.REMOTE_WINDOW_SHARE_CHANGES_UNSUBSCRIBE_SUCCEEDED,
      result,
    });
  } catch (e) {
    yield put({
      type: callActionTypes.REMOTE_WINDOW_SHARE_CHANGES_UNSUBSCRIBE_FAILED,
      message: e.message,
    });
  }
}

function* startWindowShare(action) {
  try {
    const result = yield callProvider.startShare({
      localWindowShare: action.localWindowShare,
    });
    const opts = {
      transitionName: "start-sharing",
    };
    yield put(presenceActions.changePresence(opts));
    yield put({
      type: callActionTypes.WINDOW_SHARE_START_SUCCEEDED,
      result,
    });
    yield put(googleAnalyticsActions.shareAnalytics());
  } catch (e) {
    yield put({
      type: callActionTypes.WINDOW_SHARE_START_FAILED,
      message: e.message,
    });
  }
}

function* stopWindowShare(action) {
  try {
    const result = yield callProvider.stopShare();
    const opts = {
      transitionName: "stop-sharing",
    };
    yield put(presenceActions.changePresence(opts));
    yield put({
      type: callActionTypes.WINDOW_SHARE_STOP_SUCCEEDED,
      result,
    });
  } catch (e) {
    yield put({
      type: callActionTypes.WINDOW_SHARE_STOP_FAILED,
      message: e.message,
    });
  }
}

function* actionWatcher() {
  yield takeLatest(callActionTypes.START_CALL, startCall);
  yield takeLatest(callActionTypes.END_CALL, endCall);
  yield takeLatest(callActionTypes.ASSIGN_VIDEO_RENDERER, assignVideoRenderer);
  yield takeLatest(callActionTypes.WINDOW_SHARE_START, startWindowShare);
  yield takeLatest(callActionTypes.WINDOW_SHARE_STOP, stopWindowShare);
  yield takeLatest(callActionTypes.GET_CALL_PROPERTIES, getCallProperties);
  yield takeLatest(callActionTypes.PARTICIPANTS_CHANGES_SUBSCRIBE, subscribeOnParticipantsChanges);
  yield takeLatest(callActionTypes.PARTICIPANTS_CHANGES_UNSUBSCRIBE, unsubscribeFromParticipantsChanges);
  yield takeLatest(callActionTypes.RECORDER_STATUS_CHANGES_SUBSCRIBE, subscribeOnRecorderStatusChanges);
  yield takeLatest(callActionTypes.RECORDER_STATUS_CHANGES_UNSUBSCRIBE, unsubscribeFromRecorderStatusChanges);
  yield takeLatest(callActionTypes.MODERATION_STATUS_CHANGES_SUBSCRIBE, subscribeOnModerationStatusChanges);
  yield takeLatest(callActionTypes.MODERATION_STATUS_CHANGES_UNSUBSCRIBE, unsubscribeFromModerationStatusChanges);
  yield takeLatest(callActionTypes.LOCAL_WINDOW_SHARE_CHANGES_SUBSCRIBE, subscribeOnLocalWindowShareChanges);
  yield takeLatest(callActionTypes.LOCAL_WINDOW_SHARE_CHANGES_UNSUBSCRIBE, unsubscribeFromLocalWindowShareChanges);
  yield takeLatest(callActionTypes.REMOTE_WINDOW_SHARE_CHANGES_SUBSCRIBE, subscribeOnRemoteWindowShareChanges);
  yield takeLatest(callActionTypes.REMOTE_WINDOW_SHARE_CHANGES_UNSUBSCRIBE, unsubscribeFromRemoteWindowShareChanges);
  yield takeLatest(deviceActionTypes.REMOTE_CAMERAS_CHANGES_SUBSCRIBE, subscribeOnRemoteCamerasChanges);
  yield takeLatest(deviceActionTypes.REMOTE_MICROPHONES_CHANGES_SUBSCRIBE, subscribeOnRemoteMicrophonesChanges);
  yield takeLatest(deviceActionTypes.REMOTE_MICROPHONES_CHANGES_UNSUBSCRIBE, unsubscribeFromRemoteMicrophonesChanges);
  yield takeLatest(deviceActionTypes.REMOTE_CAMERAS_CHANGES_UNSUBSCRIBE, unsubscribeFromRemoteCamerasChanges);
}

export default actionWatcher;
