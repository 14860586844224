import DateRangeIcon from "@mui/icons-material/DateRange";
import DialpadIcon from "@mui/icons-material/Dialpad";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";

import { useTranslation } from "react-i18next";
import { ConnectedProps, connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { RootState } from "store/root-reducer";
import styled from "themes/theme/baseTheme";
import { test } from "utils/helpers";

const StyledButton = styled(Button)(({ theme }) => ({
  color: "inherit",
  height: "40px",
  minWidth: "40px",
  borderRadius: "4px",
  backgroundColor: "transparent",
  "&:hover": {
    color: theme.palette.icon.navLink.selected,
    backgroundColor: theme.palette.background.navLink.hover,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundImage: "none",
  height: "58px",
  maxHeight: "58px",
  minHeight: "58px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "@media (min-width: 600px)": {
    minHeight: "58px",
    padding: "8px 12px",
  },
}));

interface SideBarFooterOwnProps {
  notificationCount: number;
}

const mapStateToProps = (state: RootState) => ({
  sipEnabled: state.sip.sipEnabled,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type SideBarFooterProps = ConnectedProps<typeof connector> & SideBarFooterOwnProps;

const SideBarFooter = ({ notificationCount, sipEnabled }: SideBarFooterProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const ToolBarButtons = [
    {
      route: "/meeting",
      label: t("MEETING"),
      icon: <DateRangeIcon sx={{ fontSize: "2rem" }} />,
    },
    {
      route: "/dialpad",
      label: t("DIALPAD"),
      icon: <DialpadIcon sx={{ fontSize: "2rem" }} />,
      disabled: !sipEnabled,
    },
    {
      route: "/settings",
      label: t("SETTINGS"),
      icon: <SettingsIcon sx={{ fontSize: "2rem" }} />,
    },
  ];

  const isHomeActive = (match: any, location: any) => {
    const isInArray = ToolBarButtons.find((item) => location.pathname.includes(item.route));

    if (isInArray) {
      if (!match) return;
      return match.isExact;
    } else {
      return true;
    }
  };

  const navLinkStyle = (isActive: boolean) => {
    const style = {
      width: "42px",
      height: "42px",
      color: isActive ? theme.palette.icon.navLink.selected : theme.palette.icon.navLink.notSelected,
      backgroundColor: isActive ? theme.palette.background.navLink.selected : "",
      border: isActive ? `1px solid ${theme.palette.border.navLink}` : `1px solid transparent`,
      borderRadius: "4px",
      textDecoration: "none",
      padding: "0",
    };
    return style;
  };

  return (
    <AppBar
      position="static"
      color="default"
      sx={{ marginTop: "auto", "&.MuiPaper-root": { backgroundColor: theme.palette.background.navLink.notSelected } }}
      {...test("SIDEBAR_FOOTER")}
    >
      <StyledToolbar>
        <NavLink to="/home" isActive={isHomeActive} style={(isActive: boolean) => navLinkStyle(isActive)}>
          <Tooltip title={t("HOME")} aria-label="Home" placement="top" arrow>
            <StyledButton id="sidebar_footer_home_button" aria-label="Home" size="small">
              <Badge
                variant="dot"
                invisible={notificationCount <= 0}
                color="error"
                sx={{
                  "& .MuiBadge-badge": {
                    height: "10px",
                    width: "10px",
                    border: `1px solid ${theme.palette.common.white}`,
                    top: "4px",
                    right: "2px",
                    borderRadius: "50%",
                  },
                }}
              >
                <HomeIcon sx={{ fontSize: "2rem" }} />
              </Badge>
            </StyledButton>
          </Tooltip>
        </NavLink>
        {ToolBarButtons.map((b) =>
          b.disabled ? null : (
            <NavLink key={b.route} to={b.route} style={(isActive: boolean) => navLinkStyle(isActive)}>
              <Tooltip title={b.label} aria-label={b.label} placement="top" arrow>
                <StyledButton id={`sidebar_footer_${b.label}_button`} aria-label={b.label} size="small">
                  {b.icon}
                </StyledButton>
              </Tooltip>
            </NavLink>
          )
        )}
      </StyledToolbar>
    </AppBar>
  );
};

export default connector(SideBarFooter);
