import PresenceProviderAPI from "./src/PresenceProviderAPI";
import { isStringWithValue, getProperCase } from "utils/helpers";
import { StatusValues } from "types/Matrix";
import { getLogger } from "logger/appLogger";

let selectedProvider = null;

const logger = getLogger("presence");

export const getPresenceProvider = () => {
  if (selectedProvider) {
    return selectedProvider;
  }

  selectedProvider = new PresenceProviderAPI();

  window.presenceProvider = selectedProvider;
  return selectedProvider;
};

export const getStatusMessage = (status) => {
  const module = "PresenceProvider";
  if (!status) return "Offline";
  if (!isStringWithValue(status)) {
    logger.error(`${module}: Error in getStatusMessage => status ${status} is not valid.`);
    return "Offline";
  }

  switch (status.toLowerCase()) {
    case StatusValues.online:
    case StatusValues.available:
      return getProperCase(StatusValues.available);
    case StatusValues.offline:
    case StatusValues.unavailable:
    case StatusValues.away:
    case StatusValues.idle:
    case StatusValues.busy:
    case StatusValues.presenting:
      return getProperCase(status);
    case StatusValues.call:
      return "Incall";
    case StatusValues.conf:
      return "InConference";
    case StatusValues.dnd:
      return StatusValues.dnd;
    default:
      return "Offline";
  }
};
