import { useState, useEffect, useMemo, useRef } from "react";
import { getLogger } from "logger/appLogger";
import { MatrixUserProvider } from "services/MatrixAPIProvider";
import { MatrixRoomTypes, MatrixRoom, DirectUser, StatusValues, MatrixUser } from "types/Matrix";
import { getHttpUriForMxc } from "matrix-js-sdk";
import mxClient from "matrix/matrix";
import useProvisioning from "hooks/useProvisioning";
import { useAppSelector } from "store/hooks";

const useMatrixAvatar = (room?: MatrixRoom | null, userId?: string | null) => {
  const logger = useMemo(() => getLogger("matrix"), []);
  const module = "useMatrixAvatar";
  const mountedRef = useRef(true);

  const [client] = useState(mxClient.client);

  const { getUser, getUserAvatarUrl, getUserProfileInfo } = MatrixUserProvider();
  const { getUserKeycloakInfo } = useProvisioning();

  const [currentRoom, setCurrentRoom] = useState<MatrixRoom | null | undefined>(room);
  const [roomAvatar, setRoomAvatar] = useState<string | null | undefined>("");
  const [userAvatar, setUserAvatar] = useState("");
  const [directUser, setDirectUser] = useState<MatrixUser | DirectUser | null | undefined>({
    displayName: "",
    userId: null,
    avatarUrl: "",
  });

  const onOtherPresenceChange = useAppSelector((state) => state.presence.onOtherPresenceChange);
  const updatedRoomAvatar = useAppSelector((state) => state.matrix.updatedRoomAvatar);

  const getMatrixImage = (
    url: string | undefined | null,
    width?: number | undefined,
    height?: number | undefined,
    resizeMethod?: string | undefined,
    allowDirectLinks?: boolean | undefined
  ) => {
    try {
      //@ts-ignore
      const baseUrl = client.baseUrl;
      return url ? getHttpUriForMxc(baseUrl, url, width, height, resizeMethod, allowDirectLinks) ?? "" : "";
    } catch (e) {
      logger.error(`${module}: Error in getMatrixImage - url:${url} - ${e.message}`);
    }
  };

  const getHttpUrl = (url: string | undefined | null) => {
    try {
      return url ? client.mxcUrlToHttp(url) ?? "" : "";
    } catch (e) {
      logger.error(`${module}: Error in getHttpUrl - url:${url} - ${e.message}`);
    }
  };

  /** Method to get the room avatar */
  const getRoomAvatar = (args: { roomId?: string; room?: MatrixRoom | null }) => {
    try {
      if (!args.roomId && !args.room) return;
      let room = args.room;
      if (!room) {
        room = client.getRoom(args.roomId!) as MatrixRoom;
      }

      //@ts-ignore
      const baseUrl = client.baseUrl;
      if (room) {
        return room.getAvatarUrl(baseUrl, 120, 120, "scale", true);
      }
      return "";
    } catch (e) {
      logger.error(`${module}: Error in getRoomAvatar - roomId:${room ? room.roomId : args.roomId} - ${e.message}`);
    }
  };

  /** Method to get the room member avatar */
  const getRoomMemberAvatar = (roomId: string | undefined, userId: string) => {
    try {
      const user = getUser(userId);
      const room = client.getRoom(roomId);
      //@ts-ignore
      const baseUrl = client.baseUrl;
      if (user && room) {
        const member = room.getMember(userId);
        return member ? member.getAvatarUrl(baseUrl, 120, 120, "scale", true, true) : "";
      }
      return "";
    } catch (e) {
      logger.error(`${module}: Error in getRoomMemberAvatar - roomId:${roomId}, userId:${userId} - ${e.message}`);
    }
  };

  /** Method to get the user profile info avatar, if don't have a roomId
   * Important: avoid use this method if have a roomId, because is a async method
   */
  const getUserProfileAvatar = async (userId: string) => {
    let avatarUrl = "";
    try {
      const res = await getUserAvatarUrl(userId);
      if (res && mountedRef.current) {
        avatarUrl = res ?? "";
      }
    } catch (e) {
      logger.error(`${module}: Error in getUserAvatar - userId:${userId} - ${e.message}`);
    }
    return avatarUrl;
  };

  const calculateDirectUser = async (dmUserId: string, avatar?: string) => {
    const member = currentRoom ? currentRoom.getMember(dmUserId) : null;
    const dmUser = member ? member.user : null;
    const directUserInfo = {
      userId: dmUserId,
      presence: "",
      presenceStatusMsg: "",
      presenceStatus: StatusValues.offline,
    };
    let dAvatar = avatar ?? "";
    try {
      const profileInfo = await getUserProfileInfo(dmUserId);
      if (mountedRef.current) {
        if (profileInfo && profileInfo.displayname) {
          const displayName = profileInfo.displayname;
          dAvatar = avatar ?? getMatrixImage(profileInfo.avatar_url, 120, 120, "scale") ?? "";
          if (dmUser) {
            const user = Object.assign({}, dmUser);
            user.displayName = displayName;
            user.avatarUrl = dAvatar;
            setDirectUser(user);
          } else {
            setDirectUser({ displayName, avatarUrl: dAvatar, ...directUserInfo });
          }
        } else {
          if (dmUser) {
            const user = Object.assign({}, dmUser);
            user.avatarUrl = dAvatar;
            setDirectUser(user);
          } else {
            setDirectUser({ displayName: dmUserId, avatarUrl: dAvatar, ...directUserInfo });
          }
        }
      }
    } catch (e) {
      logger.error(
        `${module}: Error in calculateDirectUser - dmUserId:${dmUserId}, avatarUrl:${avatar} - ${e.message}`
      );
      setDirectUser(dmUser ? dmUser : { displayName: dmUserId, avatarUrl: avatar ?? "", ...directUserInfo });
    }
  };

  const updateRoom = (newRoom: MatrixRoom) => {
    if (!newRoom) return;
    setCurrentRoom(newRoom);
  };

  useEffect(() => {
    if (!room) return;
    setCurrentRoom(room);
  }, [room]);

  useEffect(() => {
    if (!currentRoom) return;

    if (currentRoom.type !== MatrixRoomTypes.direct) {
      setRoomAvatar(getRoomAvatar({ roomId: currentRoom.roomId }));
    } else {
      const dmUserId = currentRoom.guessDMUserId();
      // check if exists keycloak info of this member
      // if exists, use this info instead of the matrix (solves the problems related with members that not exists on matrix)
      const info = getUserKeycloakInfo(dmUserId);
      let avatar = "";
      if (info && info.presence) {
        const { avatarUrl } = info.presence;
        avatar = avatarUrl;
      } else {
        const roomMemberAvatar = getRoomMemberAvatar(currentRoom.roomId, dmUserId);
        avatar = roomMemberAvatar ?? "";
      }
      setRoomAvatar(avatar);
      calculateDirectUser(dmUserId, avatar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoom, currentRoom?.type]);

  useEffect(() => {
    if (!currentRoom) return;
    if (currentRoom.type === MatrixRoomTypes.direct) {
      const dmUserId = currentRoom.guessDMUserId();
      // checks if it was a presence change, and change the avatar with the new value
      if (onOtherPresenceChange && onOtherPresenceChange.userId === dmUserId) {
        calculateDirectUser(dmUserId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOtherPresenceChange]);

  useEffect(() => {
    if (!updatedRoomAvatar) return;
    if (!currentRoom) return;
    if (updatedRoomAvatar.roomId === currentRoom.roomId && currentRoom.type === MatrixRoomTypes.direct && directUser) {
      if (directUser.avatarUrl !== updatedRoomAvatar.roomAvatar) {
        directUser.avatarUrl = updatedRoomAvatar.roomAvatar ?? "";
        setDirectUser(directUser);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedRoomAvatar]);

  useEffect(() => {
    if (userId && room) {
      const avatar = getRoomMemberAvatar(room.roomId, userId);
      setUserAvatar(avatar ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return {
    userAvatar,
    roomAvatar,
    directUser,
    getRoomAvatar,
    getRoomMemberAvatar,
    getUserProfileAvatar,
    getMatrixImage,
    getHttpUrl,
    updateRoom,
  };
};

export default useMatrixAvatar;
