import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCamera } from "store/actions/devices";
import { useTranslation } from "react-i18next";
import { test, sortDevices } from "utils/helpers";

import MenuItem from "@mui/material/MenuItem";
import GeneralSelectList from "components/GeneralSelectList/GeneralSelectList";
import { RootState } from "store/root-reducer";
import { SelectChangeEvent } from "@mui/material/Select";

const CameraSelectList = ({ showTitle = true }: { showTitle: boolean }) => {
  const selectedCamera = useSelector((state: RootState) => state.devices.selectedCamera);
  const cameras = useSelector((state: RootState) => state.devices.cameras);
  const sortedCameras = sortDevices(cameras);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const localCamera = event.target.value;
    if (localCamera) {
      dispatch(selectCamera(localCamera));
    }
  };

  const getItems = () => {
    if (sortedCameras && sortedCameras.length > 0) {
      const result = sortedCameras.map((camera, index) => {
        const name = camera.name;
        return (
          <MenuItem key={index + 1} value={camera} {...test("SELECT_CAMERA_ITEM")}>
            {name}
          </MenuItem>
        );
      });
      return result;
    }
  };

  const value = sortedCameras && sortedCameras.length > 0 ? (selectedCamera ? selectedCamera : "") : "";

  return (
    <div className="device-select-container">
      <GeneralSelectList
        id="camera-select"
        title={showTitle ? t("CAMERA") : null}
        items={getItems()}
        onItemSelect={handleSelectChange}
        noResultsText={t("NO_CAMERA")}
        noSelectText={t("NO_ACTIVE_CAMERA")}
        selectedItemName={value}
        tooltip={value.name}
        {...test("SELECT_CAMERA")}
      />
    </div>
  );
};

export default CameraSelectList;
