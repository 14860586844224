import MenuItem from "@mui/material/MenuItem";

const CustomMenuItem = ({ ...props }) => {
  const { children } = props;
  return (
    <MenuItem sx={{ padding: "0.5rem 1rem" }} {...props}>
      {children}
    </MenuItem>
  );
};

export default CustomMenuItem;
