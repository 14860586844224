import { useRef } from "react";
import { Button } from "@mui/material";
import { test } from "utils/helpers";
interface FileUploaderOwnProps {
  handleFile: any;
  id: string;
  size: any;
  buttonLabel: string;
  buttonClass: string;
}

const FileUploader = ({ handleFile, id, size, buttonLabel, buttonClass }: FileUploaderOwnProps) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event: any) => {
    hiddenFileInput!.current!.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  return (
    <>
      <Button
        id={id}
        classes={{ root: buttonClass }}
        size={size}
        onClick={handleClick}
        sx={{ textTransform: "none", justifyContent: "start" }}
        {...test("upload-button")}
      >
        {buttonLabel}
      </Button>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }} // Make the file input element invisible
        {...test("file-input")}
      />
    </>
  );
};

export default FileUploader;
