import { Components, Theme } from "@mui/material";

const MuiSelect: Components["MuiSelect"] = {
  styleOverrides: {
    filled: ({ ownerState, theme }) => {
      const palette = (theme as Theme).palette;
      return {
        width: "100%",
        backgroundColor: palette.background.filledInputBackground,
      };
    },
  },
};

export default MuiSelect;
