import { eventChannel, buffers } from "redux-saga";
import { put, call, takeLatest } from "redux-saga/effects";
import { getLogger } from "logger/appLogger";
import { getCallAPIProvider } from "services/CallAPIProvider";
import { deviceDisableReason } from "utils/constants";
import * as deviceActions from "../actions/devices";
import * as actionTypes from "../actions/types/app";
import * as appActions from "../actions/app";
import * as googleAnalyticsActions from "../actions/googleAnalytics";
import { createLogs, clearLogs } from "logger/logCollector";
import { getApiResponseErrorMessage } from "utils/helpers";

const callProvider = getCallAPIProvider();

const logger = getLogger("redux");

function* init(action) {
  try {
    yield callProvider.init(action.payload);
    yield callProvider.startInsightsService();

    yield put(appActions.subscribeOnPermissionsChanges());
    yield put(deviceActions.subscribeOnCamerasChanges());
    yield put(deviceActions.subscribeOnMicrophonesChanges());
    yield put(deviceActions.subscribeOnSpeakersChanges());

    yield put(deviceActions.microphoneTurnOff());
    yield put(deviceActions.cameraTurnOff());

    yield put({
      type: actionTypes.INIT_SUCCEEDED,
    });
    yield put(googleAnalyticsActions.appLaunch("roomLink"));
  } catch (e) {
    yield put({
      type: actionTypes.INIT_FAILED,
      message: e.message,
    });
  }
}

function* generateLogs(action) {
  try {
    const { payload } = action;
    const blobUrl = yield call(createLogs, [payload]);
    yield put({
      type: actionTypes.GENERATE_LOGS_SUCCEEDED,
      url: blobUrl,
      onlyRequestLogs: !payload.submit,
    });
  } catch (e) {
    yield put({
      type: actionTypes.GENERATE_LOGS_FAILED,
      message: getApiResponseErrorMessage(e),
    });
  }
}

function* resetLogs(action) {
  try {
    const { payload } = action;
    yield call(clearLogs, payload);
  } catch (e) {
    yield put({
      type: actionTypes.GENERATE_LOGS_FAILED,
      message: getApiResponseErrorMessage(e),
    });
  }
}

function* enableDebugLogLevel(action) {
  try {
    yield callProvider.elevateLogs();
  } catch (e) {
    logger.error(`app-saga: Error in enableDebugLogLevel - ${e.message}`);
  }
}

function* subscribeOnPermissionsChanges(action) {
  try {
    const permissionsChannel = yield call(createPermissionsChannel);
    yield takeLatest(permissionsChannel, handlePermissionsChanges);

    yield put({
      type: actionTypes.PERMISSIONS_CHANGES_SUBSCRIBE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.PERMISSIONS_CHANGES_SUBSCRIBE_FAILED,
      message: e.message,
    });
  }
}

function createPermissionsChannel() {
  return eventChannel((emit) => {
    callProvider.subscribeOnPermissionsChanges((type, isPermitted) => {
      emit({ type, isPermitted });
    });
    return () => {
      callProvider.unsubscribeFromPermissionsChanges();
    };
  }, buffers.expanding());
}

function* handlePermissionsChanges({ type, isPermitted }) {
  if (type === "VIDYO_PERMISSION_Camera") {
    if (isPermitted) {
      yield put(deviceActions.enableCamera(deviceDisableReason.NO_PERMISSION));
    } else {
      yield put(deviceActions.disableCamera(deviceDisableReason.NO_PERMISSION));
    }
  }
  if (type === "VIDYO_PERMISSION_Microphone") {
    if (isPermitted) {
      yield put(deviceActions.enableMicrophone(deviceDisableReason.NO_PERMISSION));
    } else {
      yield put(deviceActions.disableMicrophone(deviceDisableReason.NO_PERMISSION));
    }
  }
}

function* actionWatcher() {
  yield takeLatest(actionTypes.INIT_APP, init);
  yield takeLatest(actionTypes.GENERATE_LOGS, generateLogs);
  yield takeLatest(actionTypes.RESET_LOG_STATE, resetLogs);
  yield takeLatest(actionTypes.ENABLE_DEBUG_LOG_LEVEL, enableDebugLogLevel);
  yield takeLatest(actionTypes.PERMISSIONS_CHANGES_SUBSCRIBE, subscribeOnPermissionsChanges);
}

export default actionWatcher;
