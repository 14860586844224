import BaseCallAPIProvider from "./BaseCallAPIProvider";
import { debounce } from "throttle-debounce";
import { loadScript, loadStyles } from "utils/loaders.js";
import { isChrome } from "react-device-detect";
import storage from "utils/storage";
import { CallMediaType } from "types/UC";
import { getLogger } from "logger/appLogger";
import { VidyoParticipantAppType } from "services/CallAPIProvider/types/Vidyo";
import { getFromLocalStorage } from "utils/helpers";
import { localStorageKeys } from "utils/constants";

const log = getLogger("vidyo");
const VidyoClientCSS = "./lib/VidyoConnector/VidyoClient.css";
const VidyoClientJS = "./lib/VidyoConnector/VidyoClient.js";
const VidyoLocalDeviceTypes = {
  VidyoLocalCamera: "Camera",
  VidyoLocalMicrophone: "Microphone",
  VidyoLocalSpeaker: "Speaker",
};

export function logCallbacks(target = {}) {
  let context = target.context;
  delete target.context;
  Object.keys(target).forEach((k) => {
    if (typeof target[k] === "function") {
      let method = target[k];
      target[k] = function (...args) {
        let callbackName = `Callback ${context || ""} ${k}`;
        log.debug(`VidyoConnectorApi: logCallbacks - callbackName:${callbackName} args:${args}`);
        method(...args);
      };
    }
  });
  return target;
}

class VidyoConnectorAPI extends BaseCallAPIProvider {
  constructor() {
    if (VidyoConnectorAPI.instance) {
      return VidyoConnectorAPI.instance;
    }
    super();

    this.vc = null;
    this.vidyoConnector = null;
    this.module = "VidyoConnectorApi";
  }

  init() {
    return new Promise((resolve, reject) => {
      loadStyles(VidyoClientCSS)
        .then(() => {
          return loadScript(VidyoClientJS);
        })
        .then(() => {
          this.vc = new window.VidyoClientLib.VidyoClient("", () => {
            this.vc
              .CreateVidyoConnector({
                viewId: null,
                viewStyle: "VIDYO_CONNECTORVIEWSTYLE_Default",
                remoteParticipants: 4,
                logFileFilter: "info@VidyoDevelopment info@VidyoClient info@VidyoSDP  info@VidyoSignaling",
                //logFileFilter: "all@VidyoDevelopment info@VidyoClient debug@VidyoSDP received@VidyoClient enter@VidyoClient info@VidyoResourceManager all@VidyoSignaling",
                logFileName: "",
                userData: 0,
              })
              .then((vidyoConnector) => {
                this.vidyoConnector = vidyoConnector;
                resolve(this.vc);
              })
              .catch(reject);
          });
        });
    });
  }

  startInsightsService() {
    return new Promise((resolve, reject) => {
      const provisioningConfig = getFromLocalStorage(localStorageKeys.PROVISIONING_CONFIG);
      const serverUrl = provisioningConfig?.lokiUrl;
      if (serverUrl && this.vidyoConnector) {
        log.debug(`Starting insight service (${serverUrl})`);
        this.vidyoConnector
          .StartInsightsService({ serverUrl })
          .then(() => this.vidyoConnector.IsInsightsServiceEnabled())
          .then((enabled) => {
            if (enabled) {
              log.info(`Started insight service on ${serverUrl}`);
              resolve();
            } else {
              log.error(`Failed to start insight service (${serverUrl}): disabled`);
              reject();
            }
          })
          .catch((err) => {
            log.error(`Failed to start insight service (${serverUrl}): ${err}`);
            reject();
          });
      } else {
        log.debug("Insight service url not configured");
        resolve();
      }
    });
  }

  setAdvancedConfiguration(config = {}) {
    return this.vidyoConnector.SetAdvancedConfiguration(config);
  }

  startCall({ host, roomKey, displayName, roomPin, onDisconnected } = {}) {
    if (typeof onDisconnected !== "function") {
      const ref = this;
      onDisconnected = (reason, handler) => {
        log.info(`${ref.module}: startCall : ${handler} callback received with ${reason}`);
      };
    }

    return new Promise((resolve, reject) => {
      this.vidyoConnector
        .ConnectToRoomAsGuest(
          logCallbacks({
            context: "ConnectToRoomAsGuest",
            host,
            roomKey,
            displayName,
            roomPin,
            // Define handlers for connection events.
            onSuccess: function () {
              resolve();
            },
            onFailure: function (reason) {
              onDisconnected && onDisconnected(reason, true);
            },
            onDisconnected: function (reason) {
              onDisconnected && onDisconnected(reason, false);
            },
          })
        )
        .then(function (status) {
          if (!status) {
            reject();
          }
        })
        .catch(reject);
    });
  }

  getCallProperties() {
    return this.vidyoConnector.GetConnectionProperties().then((resultArray) => {
      let result = resultArray.reduce((r, i) => {
        r[i.name] = i.value;
        return r;
      }, {});
      return {
        callName: result["Room.displayName"],
      };
    });
  }

  endCall() {
    return this.vidyoConnector.Disconnect();
  }

  assignVideoRenderer({ viewId, showAudioMeters = false, showPreview = false }) {
    if (viewId === null) {
      return null;
    } else {
      return this.vidyoConnector
        .AssignViewToCompositeRenderer({
          viewId,
        })
        .then(() => {
          return this.vidyoConnector.ShowAudioMeters({
            showMeters: showAudioMeters,
          });
        })
        .then(() => {
          return this.vidyoConnector.ShowPreview({
            preview: showPreview,
          });
        });
    }
  }

  assignViewToLocalWindowShare(data) {
    const ref = this;
    return this.vidyoConnector
      .AssignViewToLocalWindowShare({
        viewId: data.id,
        localWindowShare: data.mediaObject,
        displayCropped: false,
        allowZoom: false,
      })
      .catch(function (e) {
        log.info(`${ref.module}: Fail in assignViewToLocalWindowShare - ${e}`);
      });
  }

  assignViewToLocalCamera(data) {
    const ref = this;
    return this.vidyoConnector
      .AssignViewToLocalCamera({
        viewId: data.id,
        localCamera: data.mediaObject,
        displayCropped: false,
        allowZoom: false,
      })
      .catch(function (e) {
        log.info(`${ref.module}: Fail in assignViewToLocalCamera - ${e}`);
      });
  }

  assignViewToRemoteCamera(data) {
    const ref = this;
    return this.vidyoConnector
      .AssignViewToRemoteCamera({
        viewId: data.id,
        remoteCamera: data.mediaObject,
        displayCropped: false,
        allowZoom: false,
      })
      .catch(function (e) {
        log.info(`${ref.module}: Fail in assignViewToRemoteCamera - ${e}`);
      });
  }

  assignViewToRemoteWindowShare(data) {
    const ref = this;
    return this.vidyoConnector
      .AssignViewToRemoteWindowShare({
        viewId: data.id,
        remoteWindowShare: data.mediaObject,
        displayCropped: false,
        allowZoom: false,
      })
      .catch(function (e) {
        log.info(`${ref.module}: Fail in assignViewToRemoteWindowShare - ${e}`);
      });
  }

  assignViewToStream(data) {
    //     setTimeout(() => {
    if (data.mediaType === "localWindowShare") {
      this.assignViewToLocalWindowShare(data);
    }
    if (data.mediaType === "localCamera" || data.mediaType === CallMediaType.audio) {
      return this.assignViewToLocalCamera(data);
    }
    if (data.mediaType === "remoteCamera") {
      this.assignViewToRemoteCamera(data);
    }
    if (data.mediaType === "remoteWindowShare") {
      this.assignViewToRemoteWindowShare(data);
    }
    //     }, 1000);
  }

  toggleCamera(privacy) {
    return this.vidyoConnector.SetCameraPrivacy({
      privacy,
    });
  }

  cameraTurnOn() {
    return this.toggleCamera(false);
  }

  cameraTurnOff() {
    this.vidyoConnector.HideView({ viewId: "local_self_view" });
    return this.toggleCamera(true);
  }

  cycleCamera() {
    return this.vidyoConnector.CycleCamera();
  }

  setMicrophonePrivacy(privacy) {
    return this.vidyoConnector.SetMicrophonePrivacy({
      privacy,
    });
  }

  setSpeakerPrivacy(privacy) {
    return this.vidyoConnector.SetSpeakerPrivacy({
      privacy,
    });
  }

  microphoneTurnOff() {
    return this.setMicrophonePrivacy(true);
  }

  microphoneTurnOn() {
    return this.setMicrophonePrivacy(false);
  }

  speakerTurnOn() {
    return this.setSpeakerPrivacy(false);
  }

  speakerTurnOff() {
    return this.setSpeakerPrivacy(true);
  }

  addSelfView({ viewId }) {
    return this.vidyoConnector
      .AssignViewToCompositeRenderer({
        viewId,
      })
      .then(() => {
        return this.vidyoConnector.ShowViewLabel({ viewId, showLabel: false });
      })
      .then(() => {
        return this.vidyoConnector.ShowAudioMeters({
          viewId,
          showMeters: false,
        });
      });
  }

  removeSelfView({ viewId }) {
    return this.vidyoConnector.ShowViewLabel({ viewId, showLabel: true }).then(() => {
      return this.vidyoConnector.AssignViewToCompositeRenderer({
        viewId: null,
      });
    });
  }

  selectCamera(localCamera) {
    return this.vidyoConnector.SelectLocalCamera({
      localCamera,
    });
  }

  selectMicrophone(localMicrophone) {
    return this.vidyoConnector.SelectLocalMicrophone({
      localMicrophone,
    });
  }

  selectSpeaker(localSpeaker) {
    return this.vidyoConnector.SelectLocalSpeaker({
      localSpeaker,
    });
  }

  startShare({ localWindowShare }) {
    return this.vidyoConnector.SelectLocalWindowShare({
      localWindowShare,
    });
  }

  stopShare() {
    return this.vidyoConnector.SelectLocalWindowShare({
      localWindowShare: null,
    });
  }

  startParticipantsListener(onChanged) {
    const ref = this;
    let participants = {
      list: [],
      participantJoined: null,
      participantLeft: null,
    };

    this.vidyoConnector.ReportLocalParticipantOnJoined({
      reportLocalParticipant: true,
    });

    return this.vidyoConnector.RegisterParticipantEventListener(
      logCallbacks({
        context: "Participant",
        onJoined: (participantJoined) => {
          if (participantJoined && participantJoined.AppType === VidyoParticipantAppType.default) {
            participants.list.push(participantJoined);
            participants.participantJoined = participantJoined;
            onChanged(participants);
          }
        },
        onLeft: (participantLeft) => {
          if (participantLeft && participantLeft.AppType === VidyoParticipantAppType.default) {
            participants.list = participants.list.filter((participant) => participant.id !== participantLeft.id);
            participants.participantLeft = participantLeft;
            onChanged(participants);
          }
        },
        onDynamicChanged: function (list) {
          log.info(`${ref.module}: onDynamicChanged list:${list}`);
          participants.list.forEach((item) => {
            item.loudest = false;
            if (item.id === list[0].id) {
              item.loudest = true;
            }
          });

          onChanged(participants);
          // participant changes camera state
        },
        onLoudestChanged: function (participant, audioOnly) {
          participants.list.forEach((item) => {
            item.loudest = false;
            if (item.id === participant.id) {
              item.loudest = true;
            }
          });

          onChanged(participants);
          log.info(
            `${ref.module}: onLoudestChanged - participant:${participant}, audioOnly:${audioOnly}, participants:${participants}`
          );
          // participant changes audio state
        },
      })
    );
  }

  startRemoteCamerasListener(onChanged) {
    let cameras = [];
    const ref = this;

    this.vidyoConnector
      .RegisterRemoteCameraEventListener(
        logCallbacks({
          context: "RemoteCamera",
          onAdded: (camera, participant) => {
            camera.participant = participant;
            camera.mediaType = CallMediaType.remoteCamera;
            cameras.push(camera);
            onChanged(cameras);
          },
          onRemoved: (camera, participant) => {
            this.vidyoConnector.HideView({ viewId: participant.userId });
            cameras = cameras.filter((item) => item.id !== camera.id);
            onChanged(cameras);
          },
          onStateUpdated: (camera, participant, state) => {},
        })
      )
      .then(function () {
        log.debug(`${ref.module}: RegisterRemoteCameraEventListener Success`);
      })
      .catch(function (e) {
        log.error(`${ref.module}: Error in registerRemoteCameraEventListener - ${e}`);
      });
  }

  startRecorderStatusListener(onChanged) {
    const ref = this;
    this.vidyoConnector
      .RegisterRecorderInCallEventListener(
        logCallbacks({
          context: "RecorderInCall",
          onRecorderInCallChanged: (status, paused, webCasting) => {
            onChanged(status, paused, webCasting);
          },
        })
      )
      .then(function () {
        log.debug(`${ref.module}: RegisterRecorderInCallEventListener Success`);
      })
      .catch(function (e) {
        log.error(`${ref.module}: Error in registerRecorderInCallEventListener - ${e}`);
      });
  }

  startRemoteMicrophoneListener(onChanged) {
    let microphones = [];
    const ref = this;

    this.vidyoConnector
      .RegisterRemoteMicrophoneEventListener(
        logCallbacks({
          context: "RemoteMicrophone",
          onAdded: (microphone, participant) => {
            microphone.participant = participant;
            microphones.push(microphone);
            onChanged(microphones);
          },
          onRemoved: (microphone, participant) => {
            microphones = microphones.filter((item) => item.id !== microphone.id);
            onChanged(microphones);
          },
          onStateUpdated: (microphone, participant, state) => {
            let states = {
              VIDYO_DEVICESTATE_Resumed: true,
              VIDYO_DEVICESTATE_Paused: false,
            };
            microphones.forEach((item) => {
              if (item.id === microphone.id) {
                item.microphoneOn = states[state];
              }
            });
            onChanged(microphones);
          },
        })
      )
      .then(function () {
        log.info(`${ref.module}: RegisterRemotemicrophoneEventListener Success`);
      })
      .catch(function (e) {
        log.error(`${ref.module}: Error in registerRemotemicrophoneEventListener - ${e}`);
      });
  }

  startModerationListener(onChanged) {
    return this.vidyoConnector.RegisterModerationCommandEventListener(
      logCallbacks({
        context: "Moderation",
        onModerationCommandReceived: (deviceType, moderationType, state) => {
          let moderationStatus = {};

          if (moderationType === "VIDYO_ROOMMODERATIONTYPE_HardMute") {
            if (deviceType === "VIDYO_DEVICETYPE_LocalMicrophone") {
              moderationStatus = { ...moderationStatus, audioHardMute: state };
            } else if (deviceType === "VIDYO_DEVICETYPE_LocalCamera") {
              moderationStatus = { ...moderationStatus, videoHardMute: state };
            }
          }
          onChanged(moderationStatus);
        },
      })
    );
  }

  handleAddedDevice(devices, device) {
    const savedDevice = this.filterDevices(devices, "saved");
    if (devices.length === 1 && !device.selected) {
      this.selectLocalDevice(device);
    }
    if (savedDevice) {
      this.selectLocalDevice(savedDevice);
    }

    if ((device.objType === "VidyoLocalMicrophone" || device.objType === "VidyoLocalSpeaker") && !savedDevice) {
      this.selectLocalDevice(this.filterDevices(devices, "default"));
    }
  }

  handleRemovedDevice(device, selectedDevice, devices) {
    if (!(selectedDevice && device)) {
      return;
    }
    if (device.id === selectedDevice.id) {
      if (device.objType === "VidyoLocalMicrophone" || device.objType === "VidyoLocalSpeaker") {
        this.selectLocalDevice(this.filterDevices(devices, "default"));
      }
      if (device.objType === "VidyoLocalCamera") {
        if (devices[0]) {
          this.selectLocalDevice(devices[0]);
        }
      }
    }
  }

  filterDevices(devices, filterBy) {
    return devices.filter((device) => {
      const result = {
        default: device.id === "default",
        saved: device.id === storage.getItem("savedLocal" + VidyoLocalDeviceTypes[device.objType]),
      };
      return result[filterBy] || false;
    })[0];
  }

  selectLocalDevice(device) {
    if (!device) {
      return;
    }
    this.vidyoConnector["SelectLocal" + VidyoLocalDeviceTypes[device.objType]]({
      ["local" + VidyoLocalDeviceTypes[device.objType]]: device,
    });
  }

  startDeviceListener(targetListener, onChanged, context) {
    let devices = [];

    const notify = debounce(500, () => {
      if (this.vidyoConnector) {
        onChanged(devices);
      }
    });

    return targetListener.call(
      this.vidyoConnector,
      logCallbacks({
        context,
        onAdded: (device) => {
          if (device.id && device.id !== "communications") {
            if (device.name) {
              if (isChrome && device.id === "default") {
                device.name = device.name.replace(/^.+- /gu, "");
              }
              let parsedName = device.name.replace(/\s*\([a-zA-Z0-9]+:[a-zA-Z0-9]+\)/, "");
              device.name = parsedName;
              devices = [...devices, device];
              this.handleAddedDevice(devices, device);
              notify();
            }
          }
        },
        onRemoved: (device) => {
          const selectedDevice = devices.filter((d) => d.selected)[0];
          devices = devices.filter((d) => d.objId !== device.objId);
          this.handleRemovedDevice(device, selectedDevice, devices);
          notify();
        },
        onSelected: (device) => {
          for (let device_ of devices) {
            if (device) {
              device_.selected = device_.objId === device.objId;
            } else {
              device_.selected = false;
            }
          }
          notify();
        },
        onStateUpdated: (device, state) => {
          devices = devices.map((d) => {
            if (d.objId === device.objId) {
              switch (state) {
                case "VIDYO_DEVICESTATE_Suspended":
                  d.isSuspended = true;
                  break;
                case "VIDYO_DEVICESTATE_Unsuspended":
                  d.isSuspended = false;
                  break;
                case "VIDYO_DEVICESTATE_Controllable":
                  d.isControllable = true;
                  break;
                case "VIDYO_DEVICESTATE_DefaultChanged":
                  d.isDefault = true;
                  break;
                case "VIDYO_DEVICESTATE_ConfigureError":
                case "VIDYO_DEVICESTATE_Error":
                  d.isFailed = true;
                  d.isStarted = false;
                  break;
                case "VIDYO_DEVICESTATE_Started":
                  d.isFailed = false;
                  d.isStarted = true;
                  break;
                case "VIDYO_DEVICESTATE_Stopped":
                  d.isFailed = false;
                  d.isStarted = false;
                  break;
                default:
              }
            }
            return d;
          });
          notify();
        },
      })
    );
  }

  startLocalShareListener(targetListener, onChanged) {
    let shares = [];
    const ref = this;

    const notify = debounce(500, () => {
      if (this.vidyoConnector) {
        onChanged(shares);
      }
    });

    return targetListener
      .call(this.vidyoConnector, {
        onAdded: (share, participant) => {
          share.mediaType = "localWindowShare";
          shares.push(share);
          notify();
        },
        onRemoved: (share) => {
          shares = shares.filter((item) => item.id !== share.id);
          notify();
        },
        onSelected: (share) => {
          log.info(`${ref.module}: localWindowShare2 - ${share}`);
          for (let share_ of shares) {
            if (share) {
              share_.selected = share_.objId === share.objId;
            } else {
              share_.selected = false;
            }
          }
          if (share === null) {
            setTimeout(() => {
              this.vidyoConnector.HideView({ viewId: "local_share" });
            }, 2000);
          }
          notify();
        },
        onStateUpdated: (share, state) => {
          log.info(`${ref.module}: localWindowShare3 - share:${share} state:${state}`);
        },
      })
      .then(function (result) {
        log.info(`${ref.module}: ${targetListener.name} Success`);
      })
      .catch(function () {
        log.error(`${ref.module}: ${targetListener.name} Failed`);
      });
  }

  startRemoteShareListener(targetListener, onChanged) {
    let shares = [];
    const ref = this;

    const notify = debounce(500, () => {
      if (this.vidyoConnector) {
        onChanged(shares);
      }
    });

    return targetListener
      .call(
        this.vidyoConnector,
        logCallbacks({
          context: "RemoteShare",
          onAdded: (share, participant) => {
            log.info(`${ref.module}: RemoteShare - share`);
            share.mediaType = "remoteWindowShare";
            share.participant = participant;
            shares.push(share);
            notify();
          },
          onRemoved: (share, participant) => {
            this.vidyoConnector.HideView({ viewId: "remote_share" });
            shares = shares.filter((item) => item.id !== share.id);
            notify();
          },
          onStateUpdated: (share, participant, state) => {},
        })
      )
      .then(function (result) {
        log.info(`${ref.module}: ${targetListener.name} Success`);
      })
      .catch(function (e) {
        log.error(`${ref.module}: ${targetListener.name} Failed - ${e}`);
      });
  }

  subscribeOnCamerasChanges(onChanged) {
    return this.startDeviceListener(this.vidyoConnector.RegisterLocalCameraEventListener, onChanged, "LocalCamera");
  }

  subscribeOnRemoteCamerasChanges(onChanged) {
    return this.startRemoteCamerasListener(onChanged);
  }

  subscribeOnRemoteMicrophonesChanges(onChanged) {
    return this.startRemoteMicrophoneListener(onChanged);
  }

  subscribeOnRecorderStatusChanges(onChanged) {
    return this.startRecorderStatusListener(onChanged);
  }

  subscribeOnModerationStatusChanges(onChanged) {
    return this.startModerationListener(onChanged);
  }

  unsubscribeFromModerationStatusChanges() {
    return this.vidyoConnector.unsubscribeFromModerationStatusChanges();
  }

  unsubscribeFromRecorderStatusChanges() {
    return this.vidyoConnector.UnregisterRecorderInCallEventListener();
  }

  unsubscribeFromCamerasChanges() {
    return this.vidyoConnector.UnregisterLocalCameraEventListener();
  }

  subscribeOnMicrophonesChanges(onChanged) {
    return this.startDeviceListener(
      this.vidyoConnector.RegisterLocalMicrophoneEventListener,
      onChanged,
      "LocalMicrophone"
    );
  }

  unsubscribeFromMicrophonesChanges() {
    return this.vidyoConnector.UnregisterLocalMicrophoneEventListener();
  }

  subscribeOnSpeakersChanges(onChanged) {
    return this.startDeviceListener(this.vidyoConnector.RegisterLocalSpeakerEventListener, onChanged, "LocalSpeaker");
  }
  subscribeOnParticipantsChanges(onChanged) {
    return this.startParticipantsListener(onChanged);
  }

  unsubscribeFromSpeakersChanges() {
    return this.vidyoConnector.UnregisterLocalSpeakerEventListener();
  }

  subscribeOnLocalWindowShareChanges(onChanged) {
    return this.startLocalShareListener(this.vidyoConnector.RegisterLocalWindowShareEventListener, onChanged);
  }

  unsubscribeFromLocalWindowShareChanges() {
    return this.vidyoConnector.UnregisterLocalWindowShareEventListener();
  }

  subscribeOnRemoteWindowShareChanges(onChanged) {
    return this.startRemoteShareListener(this.vidyoConnector.RegisterRemoteWindowShareEventListener, onChanged);
  }

  unsubscribeFromRemoteWindowShareChanges() {
    return this.vidyoConnector.UnregisterRemoteWindowShareEventListener();
  }

  subscribeOnPermissionsChanges(onChanged) {
    return this.vidyoConnector.RegisterPermissionEventListener({
      onPermissionUpdated: onChanged,
    });
  }

  unsubscribeFromPermissionsChanges() {
    return this.vidyoConnector.UnregisterPermissionEventListener();
  }

  elevateLogs() {
    return this.vidyoConnector.SetAdvancedConfiguration({
      addLogCategory:
        "all@VidyoDevelopment debug@VidyoClient debug@VidyoSDP debug@VidyoResourceManager all@VidyoSignaling",
    });
  }
  hideView(viewId) {
    this.vidyoConnector.HideView({ viewId: viewId });
  }
}

export default VidyoConnectorAPI;
