import styled from "themes/theme/baseTheme";
import Dialog from "components/_shared/Dialog";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import IconButton from "@mui/material/IconButton";

export const StyledCallTransferOptionsIconButton = styled(IconButton)(({ theme }) => ({
  color: "inherit",
  padding: 0,
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export const StyledCallTransferDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "387px",
    height: "750px",
    "& .MuiDialogTitle-root > .MuiIconButton-root": {
      top: "10px",
    },
  },
}));

export const StyledCallTransferAccordion = styled(Accordion)(({ theme }) => ({
  width: "100%",
  backgroundColor: "inherit",
  backgroundImage: "none",
  padding: "10px",
  border: `1px solid transparent`,
  borderRadius: "8px !important",
  boxShadow: "none",
  "&.Mui-expanded": {
    border: `1px solid ${theme.palette.brand.secondary.light}`,
    backgroundColor: theme.palette.background.expanded,
    boxShadow: "none",
  },
}));

export const StyledCallTransferAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  margin: 0,
  minHeight: "min-content !important",
  "& .MuiAccordionSummary-content": {
    padding: 0,
    margin: 0,
    minHeight: "min-content !important",
  },
  "&.Mui-expanded": {
    margin: "0 !important",
    padding: "0 !important",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    marginRight: 0,
    padding: 0,
  },
}));

export const StyledCallTransferAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: "0 !important",
}));
