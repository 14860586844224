import { ParticipantBottomInfo } from "./ParticipantBottomInfo";

interface RemoteCameraProps {
  tile: any;
  index: number;
  in169: boolean;
}

export const RemoteCamera = ({ tile, index, in169 }: RemoteCameraProps) => {
  return (
    <div
      id={tile.id}
      className={`participant-tile ${tile.active && "active"} ${tile.type} participant${index} ${
        in169 ? "participant-tile-aspect-ratio-16-9" : ""
      }`}
    >
      <ParticipantBottomInfo name={tile.name || ""} />
    </div>
  );
};
