import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useConferenceModeration } from "hooks";

import { getLogger } from "logger/appLogger";
import styled from "themes/theme/baseTheme";
import { List, ListItemText, ListItemButton, ListItemIcon } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import VideocamIcon from "@mui/icons-material/Videocam";

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: "3px 0",
  marginLeft: "40px",
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "24px",
  marginRight: "10px",
}));

type ModerationUserProps = {
  participantId: number;
  microphoneOn: boolean;
  cameraOn: boolean;
};

export const ModerationUser = ({ participantId, microphoneOn, cameraOn }: ModerationUserProps) => {
  const logger = useMemo(() => getLogger("conference-moderation"), []);
  const module = "ModerationUser";
  const { t } = useTranslation();
  const isParticipantIdValid = participantId && participantId > 0;
  const {
    isRoomOwner,
    conferenceModerationError,
    muteMicrophone,
    pendingOperationMuteMicrophone,
    muteCamera,
    pendingOperationMuteCamera,
  } = useConferenceModeration();

  const [intermediateStateMuteMuteMicrophone, setIntermediateStateMuteMuteMicrophone] = useState<boolean>(false);
  const [intermediateStateMuteMuteCamera, setIntermediateStateMuteMuteCamera] = useState<boolean>(false);

  const muteMicrophoneDisabled = pendingOperationMuteMicrophone || !microphoneOn || intermediateStateMuteMuteMicrophone;
  const muteCameraDisabled = pendingOperationMuteCamera || !cameraOn || intermediateStateMuteMuteCamera;

  const muteMicrophoneUI = () => {
    setIntermediateStateMuteMuteMicrophone(true);
    muteMicrophone(participantId);
  };

  const muteCameraUI = () => {
    setIntermediateStateMuteMuteCamera(true);
    muteCamera(participantId);
  };

  useEffect(() => {
    // mic on->off
    if (!microphoneOn) {
      setIntermediateStateMuteMuteMicrophone(false);
    }
  }, [microphoneOn]);

  useEffect(() => {
    // camera on->off
    if (!cameraOn) {
      setIntermediateStateMuteMuteCamera(false);
    }
  }, [cameraOn]);

  useEffect(() => {
    if (conferenceModerationError) {
      logger.warn(`${module}: error: ${conferenceModerationError}`);
    }
    if (!isParticipantIdValid) {
      logger.warn(`${module}: error: invalid participantId: '${participantId}'`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conferenceModerationError, isParticipantIdValid]);

  const ui = () => {
    return (
      <List component="div" disablePadding sx={{ listStyle: "none" }}>
        <StyledListItemButton onClick={muteMicrophoneUI} disabled={muteMicrophoneDisabled}>
          <StyledListItemIcon>
            <MicIcon />
          </StyledListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: "body1",
            }}
            primary={t("MUTEMICROPHONE")}
          />
        </StyledListItemButton>

        <StyledListItemButton onClick={muteCameraUI} disabled={muteCameraDisabled}>
          <StyledListItemIcon>
            <VideocamIcon />
          </StyledListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: "body1",
            }}
            primary={t("TURN_OFF_CAMERA")}
          />
        </StyledListItemButton>
      </List>
    );
  };

  return isRoomOwner && isParticipantIdValid ? ui() : null;
};

export default ModerationUser;
