import { ClientEvent, MatrixEvent, IStartClientOpts } from "matrix-js-sdk";
import mxClient from "matrix/matrix";

import { useState, useEffect, useMemo } from "react";
import { LocalEventType } from "types/Matrix";

import useLocalStorage from "./useLocalStorage";
import { getLogger } from "logger/appLogger";
import { localStorageKeys } from "utils/constants";

const useMatrix = (redirectUrl: string) => {
  const logger = useMemo(() => getLogger("matrix.hook"), []);
  const module = "useMatrix";

  const [mxClass] = useState(mxClient);
  const [client, setClient] = useState(mxClass.client);
  const [ssoUrl, setSsoUrl] = useState<string | undefined>("");
  const [, setCurrentUser] = useLocalStorage(localStorageKeys.CURRENT_USER);

  const createClient = (opts: any, caller: string) => {
    mxClass.create(opts, caller);
    setClient(mxClass.client);

    // log matrix events with REACT_APP_MATRIX_DEBUG=1, include content withREACT_APP_MATRIX_DEBUG=2, defaults to 0
    const matrixDebug: number = process.env.REACT_APP_MATRIX_DEBUG ? parseInt(process.env.REACT_APP_MATRIX_DEBUG) : 0;
    logger.debug(`${module}: env matrix-dbg: ${process.env.REACT_APP_MATRIX_DEBUG} - matrixDebug:${matrixDebug}`);
    if (matrixDebug > 0) {
      mxClass.client.on(ClientEvent.Event, (event: MatrixEvent) => {
        const content = matrixDebug > 1 ? JSON.stringify(event.getContent()) : "";
        logger.debug(`${module}: matrix-event: ${event.getType()} (sender=${event.getSender()}) ${content}`);
      });
    }
  };

  const loginClient = async (u: string, p: string) => {
    try {
      const res = await mxClass.login(u, p);
      setCurrentUser(res);
      return res;
    } catch (e) {
      logger.error(`${module}: Error in loginClient - ${e.message}`);
    }
  };

  const loginWithTokenClient = async (token: string, homeServer: any) => {
    try {
      if (!client) {
        createClient(homeServer, "loginWithTokenClient");
      }
      const res = await mxClass.loginWithToken(token);
      setCurrentUser(res);
      return res;
    } catch (e) {
      throw e;
    }
  };

  const startClient = async (opts: IStartClientOpts) => {
    try {
      return await mxClass.start(opts);
    } catch (e) {
      logger.error(`${module}: Error in startClient - ${e.message}`);
    }
  };

  const stopClient = async () => {
    try {
      mxClass.stop();
    } catch (e) {
      logger.error(`${module}: Error in stopClient - ${e.message}`);
    }
  };

  const getSsoLoginUrl = (redirectUrl: string, homeServer?: any) => {
    try {
      if (!client) {
        createClient(homeServer, "getSsoLoginUrl");
      }
      //@ts-ignore
      return mxClass.client.getSsoLoginUrl(redirectUrl);
    } catch (e) {
      logger.error(
        `${module}: Error in getSsoLoginUrl - redirectUrl:${redirectUrl}, homeServer:${homeServer} - ${e.message}`
      );
    }
  };

  const createFilter = async (content: any) => {
    try {
      return await mxClass.createFilter(content);
    } catch (e) {
      logger.error(`${module}: Error in createFilter - content:${content} - ${e.message}`);
    }
  };

  const getPushers = async () => {
    try {
      return await mxClass.getPushers();
    } catch (e) {
      logger.error(`${module}: Error in getPushers - ${e.message}`);
    }
  };

  const sendEvent = async (roomId: string, event: any, content: any) => {
    try {
      return await mxClass.client.sendStateEvent(roomId, event, content, "");
    } catch (e) {
      logger.error(
        `${module}: Error in sendEvent - roomId:${roomId}, event:${event}, content:${content} - ${e.message}`
      );
      throw e;
    }
  };

  const sendInternalMessage = async (roomId: string, eventType: LocalEventType, content: any) => {
    try {
      return await mxClass.sendInternalMessageEvent(roomId, eventType, content);
    } catch (e) {
      logger.error(
        `${module}: Error in sendInternalMessage - roomId:${roomId}, eventType:${eventType}, content:${content} - ${e.message}`
      );
      throw e;
    }
  };

  const uploadContent = async (file: any, opts: any) => {
    try {
      return await mxClass.uploadContent(file, opts);
    } catch (e) {
      logger.error(`${module}: Error in uploadContent - opts:${opts} - ${e.message}`);
      throw e;
    }
  };

  useEffect(() => {
    if (!client) return;
    try {
      setSsoUrl(getSsoLoginUrl(redirectUrl));
    } catch (e) {
      logger.error(
        `${module}: Error in useEffect to set Sso url - client:${client}, redirectUrl:${redirectUrl} - ${e.message}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, redirectUrl]);

  return {
    client,
    ssoUrl,
    createClient,
    loginClient,
    startClient,
    loginWithTokenClient,
    stopClient,
    getSsoLoginUrl,
    getPushers,
    createFilter,
    sendEvent,
    uploadContent,
    sendInternalMessage,
  };
};

export default useMatrix;
