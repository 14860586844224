import React, { useState, useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import { matrixActions } from "store/actions/matrix";
import * as devicesActionCreators from "store/actions/devices";
import * as googleAnalytics from "store/actions/googleAnalytics";
import DeviceToggle, { Toggle_Devices } from "components/DeviceToggle/DeviceToggle";
import { CallState } from "matrix-js-sdk/lib/webrtc/call";
import { test } from "utils/helpers";
import { RootState } from "store/root-reducer";
import Switch from "components/_shared/Switch";

interface CameraToggleOwnProps {
  showLabel?: boolean;
  showToggleAsSwitch?: boolean;
  isCallActive?: boolean;
  style?: React.CSSProperties;
}

const mapStateToProps = ({ devices, matrix, call }: RootState) => ({
  hasActiveMatrixCall: matrix.hasActiveMatrixCall,
  matrixCallClientState: matrix.matrixCallClientState,
  matrixVideoMuted: matrix.matrixVideoMuted,
  cameras: devices.cameras,
  selectedCamera: devices.selectedCamera,
  isCameraTurnedOn: devices.isCameraTurnedOn,
  isCameraDisabled: devices.isCameraDisabled,
  isCameraHardMuted: call.moderationStatus.videoHardMute,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(devicesActionCreators, dispatch),
  ...bindActionCreators(googleAnalytics, dispatch),
  ...bindActionCreators(matrixActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type CameraToggleProps = ConnectedProps<typeof connector> & CameraToggleOwnProps;

const CameraToggle = ({
  showLabel,
  showToggleAsSwitch = false,
  isCallActive,
  style,
  hasActiveMatrixCall,
  matrixCallClientState,
  matrixVideoMuted,
  muteMatrixVideo,
  cameras,
  selectedCamera,
  cameraTurnOn,
  cameraTurnOff,
  isCameraDisabled,
  isCameraTurnedOn,
  isCameraHardMuted,
  rightClickOnDevice,
}: CameraToggleProps) => {
  const { t } = useTranslation();

  const [intermediateCameraMute, setIntermediateCameraMute] = useState(false);
  const [cameraOn, setCameraOn] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const cameraOnClick = () => {
    if (hasActiveMatrixCall) {
      muteMatrixVideo(!matrixVideoMuted);
    } else {
      isCameraTurnedOn ? cameraTurnOff({ selectedCamera }) : cameraTurnOn({ selectedCamera });
    }
  };

  const cameraOnMouseDown = (event: { nativeEvent: { which: number } }) => {
    if (event.nativeEvent.which === 3) {
      rightClickOnDevice("camera");
    }
  };

  useEffect(() => {
    // isCameraHardMuted=>true <... stay disabled until ....> isCameraTurnedOn=>false
    if (isCameraHardMuted) {
      setIntermediateCameraMute(true);
      // timer as safety mechanism if for some reason the transition to (!isCameraTurnedOn) fails
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => setIntermediateCameraMute(false), 1000);
    }
    if (intermediateCameraMute && !isCameraTurnedOn) {
      setIntermediateCameraMute(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCameraHardMuted, isCameraTurnedOn]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = undefined;
      }
    };
  }, []);

  useEffect(() => {
    setCameraOn(hasActiveMatrixCall ? !matrixVideoMuted : isCameraTurnedOn);
  }, [hasActiveMatrixCall, isCameraTurnedOn, matrixVideoMuted]);

  useEffect(() => {
    if (!hasActiveMatrixCall && !matrixCallClientState) return;
    if (hasActiveMatrixCall && matrixCallClientState === CallState.Connected) setDisabled(false);
    else setDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrixCallClientState]);

  return (
    <div className="device-toggle">
      {showToggleAsSwitch ? (
        <Switch
          color="primary"
          checked={cameraOn}
          onChange={cameraOnClick}
          onMouseDown={cameraOnMouseDown}
          name="camera_switch"
          label={t("CAMERA")}
          disabled={
            disabled ||
            !cameras.length ||
            !selectedCamera ||
            isCameraDisabled ||
            isCallActive ||
            isCameraHardMuted ||
            intermediateCameraMute
          }
          style={style}
          {...test("CAMERA_TOGGLE")}
        />
      ) : (
        <>
          <DeviceToggle
            disabled={
              disabled ||
              !cameras.length ||
              !selectedCamera ||
              isCameraDisabled ||
              isCameraHardMuted ||
              intermediateCameraMute
            }
            on={cameraOn}
            onClick={cameraOnClick}
            onMouseDown={cameraOnMouseDown}
            tooltip={cameraOn ? t("TOOLTIP_CAMERA_ON") : t("TOOLTIP_CAMERA_OFF")}
            {...test("CAMERA_TOGGLE")}
            device={Toggle_Devices.CAMERA}
          />
          {showLabel && (
            <div className="toggle-label">
              {isCameraDisabled
                ? t("CAMERA_DISABLED")
                : selectedCamera
                ? selectedCamera.name
                : cameras.length
                ? t("NO_ACTIVE_CAMERA")
                : t("NO_CAMERA")}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CameraToggle);
