//
// cleanup storage items no longer used
//

import { getLogger } from "logger/appLogger";
import { localStorageKeys } from "./constants";
import { LocalStorageVariableUser } from "./localStorageVariableUser";
import { LocalStorageVariable } from "./localStorageVariable";

const logger = getLogger("migrations");

const itemsToRemove = [
  "o365_token_state",
  "o365_refresh_token",
  "o365_access_token",
  "provisiningConfig",
  "g_refresh_token",
  "g_access_token",
  "loggingConfiguration",
  "logstoreConfiguration",
  "currentUser",
  "tenantName",
  "googleCode",
  "ec.googleCode",
  "savedSpeedDial",
  "id",
  "phoneNumberList",
  "showLinkPreview",
  "VIDYO_CONNECT",
  "appStorage",
  "provisioningConfig",
  "outlookAuthenticationRecord",
  "googleCalendarAuthentication",
  "ec.outlookAuthenticationRecord",
  "ec.googleCalendarAuthentication",
  "endpointData",
  "ecLoggingConfiguration",
  "ecLogstoreConfiguration",
  "userSettings",
  "joinLink",
  "windowId",
  "ec.windowId",
];

function migrateOutlookAuthenticationRecord() {
  try {
    const oldKey = "ec.outlookAuthenticationRecord";
    const outlookAuthenticationRecordOld: LocalStorageVariable<any> = new LocalStorageVariable(oldKey);
    const outlookAuthenticationRecordOldValue = outlookAuthenticationRecordOld.get();
    const outlookAuthenticationRecordNew = new LocalStorageVariableUser<any>(
      localStorageKeys.OUTLOOK_AUTHENTICATION_RECORD
    );
    if (
      outlookAuthenticationRecordOldValue &&
      !outlookAuthenticationRecordNew.get() &&
      outlookAuthenticationRecordNew.ready()
    ) {
      outlookAuthenticationRecordNew.set(outlookAuthenticationRecordOldValue);
      logger.info(
        `Migrated key value '${oldKey}' to '${outlookAuthenticationRecordNew.getKey()}/${
          localStorageKeys.OUTLOOK_AUTHENTICATION_RECORD
        }'`
      );
    }
  } catch (e) {
    logger.error("Error in migrateOutlookAuthenticationRecord: ", e);
  }
}

const migrateFunctions: any[] = [migrateOutlookAuthenticationRecord];

export function migrationsCleanup() {
  try {
    migrateFunctions.forEach((foo) => foo());
    itemsToRemove.forEach((item, index) => localStorage.removeItem(item));
  } catch (e) {
    // NOP
  }
}
