import { Components, Theme } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

const MuiChip: Components["MuiChip"] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const typography = (theme as Theme).typography as TypographyOptions;
      const font = typography.body3
        ? `${typography.body3.fontStyle} ${typography.body3.fontWeight} ${typography.body3.fontSize}/${typography.body3.lineHeight} ${typography.body3.fontFamily}`
        : "";
      const palette = (theme as Theme).palette;
      return {
        font: font,
        ...(ownerState.variant === "outlined" &&
          ownerState.color === "default" && {
            color: `${palette.text.chip.outlined.default}`,
            backgroundColor: palette.background.chip.outlined.default,
            border: `1px solid ${palette.border.chip.outlined.default}`,
            "&:hover": {
              backgroundColor: `${palette.background.chip.outlined.default}`,
              color: `${palette.text.chip.outlined.default}`,
              border: `1px solid ${palette.border.chip.outlined.default}`,
            },
          }),
        ...(ownerState.variant === "outlined" &&
          ownerState.color === "primary" && {
            color: `${palette.text.chip.outlined.primary}`,
            backgroundColor: palette.background.chip.outlined.primary,
            border: `1px solid ${palette.border.chip.outlined.primary}`,
            "&:hover": {
              backgroundColor: `${palette.background.chip.outlined.primary}`,
              color: `${palette.text.chip.outlined.primary}`,
              border: `1px solid ${palette.border.chip.outlined.primary}`,
            },
          }),
        ...(ownerState.variant === "filled" &&
          ownerState.color === "default" && {
            color: `${palette.text.chip.filled.default}`,
            backgroundColor: palette.background.chip.filled.default,
            border: `1px solid ${palette.border.chip.filled.default}`,
            "&:hover": {
              backgroundColor: `${palette.background.chip.filled.default}`,
              color: `${palette.text.chip.filled.default}`,
              border: `1px solid ${palette.border.chip.filled.default}`,
            },
          }),
        ...(ownerState.variant === "filled" &&
          ownerState.color === "primary" && {
            color: `${palette.text.chip.filled.primary}`,
            backgroundColor: palette.background.chip.filled.primary,
            border: `1px solid ${palette.border.chip.filled.primary}`,
            "&:hover": {
              backgroundColor: `${palette.background.chip.filled.primary}`,
              color: `${palette.text.chip.filled.primary}`,
              border: `1px solid ${palette.border.chip.filled.primary}`,
            },
          }),
        borderRadius: "16px",
        "& .MuiChip-deleteIcon": { color: palette.icon.main },
      };
    },
  },
};

export default MuiChip;
