import Chip from "components/_shared/FilterChip";
import { test } from "utils/helpers";
import styles from "./GlobalFilter.module.scss";
import { useTranslation } from "react-i18next";

const GlobalFilter = ({
  filters = [],
  onSelectFilter,
  activeFilter,
}: {
  filters: string[];
  onSelectFilter: (filter: string) => void;
  activeFilter: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.filterContainer} {...test("SIDEBAR_ROOM_CATEGORIES")}>
      {filters.map((filter: string) => (
        <Chip
          key={filter}
          label={t(filter.toUpperCase())}
          onClick={() => onSelectFilter(filter)}
          clickable
          isActive={activeFilter === filter}
        />
      ))}
    </div>
  );
};

export default GlobalFilter;
