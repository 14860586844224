const continuedTypes = ["m.sticker", "m.room.message"];

export const shouldFormContinuationByDate = (prevEvent, mxEvent) => {
  // sanity check inputs
  if (!prevEvent || !prevEvent.getDate() || !mxEvent.getDate()) return true;

  const currentEventDate = mxEvent.getDate();
  const prevEventDate = prevEvent.getDate();

  // Some events should appear as continuations from previous events of different types.
  if (
    mxEvent.getType() !== prevEvent.getType() &&
    (!continuedTypes.includes(mxEvent.getType()) || !continuedTypes.includes(prevEvent.getType()))
  )
    return true;

  return !(
    currentEventDate.getFullYear() === prevEventDate.getFullYear() &&
    currentEventDate.getMonth() === prevEventDate.getMonth() &&
    currentEventDate.getDate() === prevEventDate.getDate()
  );
};

// check if there is a previous event and it has the same sender as this event
// and the types are the same/is in continuedTypes and the time between them is <= CONTINUATION_MAX_INTERVAL
export const shouldFormContinuation = (prevEvent, mxEvent) => {
  // sanity check inputs
  if (!prevEvent || !prevEvent.sender || !mxEvent.sender) return false;

  // Some events should appear as continuations from previous events of different types.
  if (
    mxEvent.getType() !== prevEvent.getType() &&
    (!continuedTypes.includes(mxEvent.getType()) || !continuedTypes.includes(prevEvent.getType()))
  )
    return false;

  // Check if the sender is the same and hasn't changed their displayname/avatar between these events
  if (
    mxEvent.sender.userId !== prevEvent.sender.userId ||
    mxEvent.sender.name !== prevEvent.sender.name ||
    mxEvent.sender.getMxcAvatarUrl() !== prevEvent.sender.getMxcAvatarUrl()
  )
    return false;

  return true;
};

// check if there is a previous event and it has the same sender as this event
// and the types are the same/is in continuedTypes and the time between them is <= CONTINUATION_MAX_INTERVAL
export const isLastFormContinuation = (nextEvent, mxEvent) => {
  // sanity check inputs
  if (!nextEvent || !nextEvent.sender || !mxEvent.sender) return true;

  // Some events should appear as continuations from previous events of different types.
  if (
    mxEvent.getType() !== nextEvent.getType() &&
    (!continuedTypes.includes(mxEvent.getType()) || !continuedTypes.includes(nextEvent.getType()))
  )
    return true;

  // Check if the sender is the same and hasn't changed their displayname/avatar between these events
  if (
    mxEvent.sender.userId !== nextEvent.sender.userId ||
    mxEvent.sender.name !== nextEvent.sender.name ||
    mxEvent.sender.getMxcAvatarUrl() !== nextEvent.sender.getMxcAvatarUrl()
  )
    return true;

  return false;
};

export const isCurrentUser = (mxEvent, userId) => {
  // sanity check inputs
  if (!mxEvent.sender) return false;

  // Check if the sender is the same and hasn't changed their displayname/avatar between these events
  if (mxEvent.sender.userId !== userId) return false;

  return true;
};

export const isImageTypeAllowed = (type) => {
  const notAllowed = ["tiff", "mng", "heif", "heic", "jxr", "hdp", "wdp", "jp2", "j2k", "jpf", "jpx", "jpm", "mj2"];
  try {
    if (notAllowed.includes(type)) return false;
  } catch (e) {
    console.error(`Error in isImageTypeAllowed - type:${type} - ${e.message}`);
  }
  return true;
};

export const imageDimensions = (file) =>
  new Promise((resolve, reject) => {
    const img = new Image();

    // the following handler will fire after the successful loading of the image
    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img;
      resolve({ width, height });
    };

    // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
    img.onerror = () => {
      reject("There was some problem with the image.");
    };

    img.src = URL.createObjectURL(file);
  });

export const convertBytes = (bytes) => {
  if (!bytes) return "0 B";

  const c = 1024,
    d = 2 || 2,
    e = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(bytes) / Math.log(c));

  bytes = parseFloat((bytes / Math.pow(c, f)).toFixed(d)) + " " + e[f];

  return bytes;
};
