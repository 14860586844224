import { eventChannel, buffers, END } from "redux-saga";
import { put, call, takeLatest } from "redux-saga/effects";
import { getPresenceProvider } from "services/PresenceProvider";
import { getMatrixAPIProvider } from "services/MatrixAPIProvider";
import { presenceActions } from "../actions/presence";
import * as actionTypes from "../actions/types/presence";

const presenceProvider = getPresenceProvider();
const matrixProvider = getMatrixAPIProvider();

function* startPresenceService(action) {
  try {
    const res = yield presenceProvider.startPresenceService(action.config);
    yield put(presenceActions.subscribeToPresenceChange());
    const opts = {
      transitionName: "login",
    };
    yield put(presenceActions.changePresence(opts));
    yield put({
      type: actionTypes.START_PRESENCE_SERVICE_SUCCEEDED,
      currentPresenceAvatarUrl: res.avatarUrl,
    });
  } catch (e) {
    yield put({
      type: actionTypes.START_PRESENCE_SERVICE_FAILED,
      message: e.message,
    });
  }
}

function* stopPresenceService(action) {
  try {
    yield presenceProvider.stopPresenceService();
    yield presenceProvider.unSubscribeToPresenceChange();
    yield put({
      type: actionTypes.STOP_PRESENCE_SERVICE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.START_PRESENCE_SERVICE_FAILED,
      message: e.message,
    });
  }
}

function* changePresence(action) {
  try {
    const { opts } = action;
    yield presenceProvider.changePresence(opts.transitionName, opts.status);
    yield put({
      type: actionTypes.CHANGE_PRESENCE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.CHANGE_PRESENCE_FAILED,
      message: e.message,
    });
  }
}

function* updatePresenceAvatar(action) {
  try {
    const { avatarUrl } = action;
    yield presenceProvider.updatePresenceAvatar(avatarUrl);
    yield put({
      type: actionTypes.UPDATE_PRESENCE_AVATAR_SUCCEEDED,
      avatarUrl: avatarUrl,
    });
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_PRESENCE_AVATAR_FAILED,
      message: e.message,
    });
  }
}

export function* fetchPresenceStatus(action) {
  try {
    const { userId } = action;
    const result = yield presenceProvider.fetchPresenceStatus(userId);
    return result;
  } catch (e) {
    return null;
  }
}

function* subscribeOnPresenceChange(action) {
  try {
    const eventChannel = yield call(createEventChannel, action);
    yield takeLatest(eventChannel, handleEventChanges, action);

    yield put({
      type: actionTypes.SUBSCRIBE_PRESENCE_CHANGE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.SUBSCRIBE_PRESENCE_CHANGE_FAILED,
      message: e.message,
    });
  }
}

function createEventChannel(action) {
  return eventChannel((emit) => {
    presenceProvider.subscribeToPresenceChange((...args) => {
      emit(...args);
    });
    return () => {
      emit(END);
    };
  }, buffers.expanding());
}

function* handleEventChanges(...args) {
  const [, state] = args;
  if (!state.userId) {
    yield put(presenceActions.onPresenceChange(state));
    yield matrixProvider.updateCurrentUserStatus(state);
  } else {
    yield put(presenceActions.onOtherPresenceChange(state));
    yield matrixProvider.updateOtherCurrentUserStatus(state);
  }
}

function* actionWatcher() {
  yield takeLatest(actionTypes.START_PRESENCE_SERVICE, startPresenceService);
  yield takeLatest(actionTypes.STOP_PRESENCE_SERVICE, stopPresenceService);
  yield takeLatest(actionTypes.CHANGE_PRESENCE, changePresence);
  yield takeLatest(actionTypes.UPDATE_PRESENCE_AVATAR, updatePresenceAvatar);
  yield takeLatest(actionTypes.SUBSCRIBE_PRESENCE_CHANGE, subscribeOnPresenceChange);
}

export default actionWatcher;
