import { ApiProvider } from "services/Providers/ApiProvider";
import { getTenantName } from "services/Providers/ProvisioningTokenProvider";
import getStore from "store/store";
import { RootState } from "store/root-reducer";
import { MeetingInfo } from "./types/MeetingInfo";

export class MeetingServiceProvider {
  module = "MeetingServiceProvider";
  // singleton
  private static _instance: MeetingServiceProvider;
  static Instance(): MeetingServiceProvider {
    if (!this._instance) {
      this._instance = new MeetingServiceProvider();
    }
    return this._instance;
  }

  private apiProvider: ApiProvider;

  constructor() {
    const extraHeaders = {
      "X-Enghouse-UC-Tenant": getTenantName(),
    };
    const state: RootState = getStore().getState();
    const endpointData = state.provisioning.endpointData;
    let meetingServiceUrl: string;
    try {
      meetingServiceUrl =
        endpointData && endpointData.meetingService.length > 0
          ? endpointData.meetingService.substring(endpointData.meetingService.indexOf("/"))
          : `/meeting`;
    } catch (e) {
      meetingServiceUrl = "/meeting"; //if there is no endpointData->meeting it should use the old system level url.
    }
    this.apiProvider = new ApiProvider(`${meetingServiceUrl}/api/v1/`, extraHeaders);
  }

  public getMeetingInfo(meetingId: string): Promise<MeetingInfo> {
    return this.apiProvider.restApiCall<MeetingInfo>({
      path: `/meetings/${meetingId}`,
      method: "get",
      withToken: false,
    });
  }

  public getMeetingInfoWithShareLink(shareLink: string): Promise<MeetingInfo> {
    const meetingId = shareLink.split("/join/")[1];

    return this.getMeetingInfo(meetingId);
  }
}
