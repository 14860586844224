import * as actionTypes from "../actions/types/pbx";
import { Types } from "Types";
import { PbxState } from "store/types/pbx";
import { PBXUserConfig } from "services/PBXServerApi/types/PBXUserConfig";
import { PbxUserData } from "services/PBXServerApi/types/PbxUsers";

const initialState: PbxState = {
  pbxConfig: null,
  pbxUserSearchResults: [],
  pbxUserDetails: null,
};

const pbxReducer = (
  state = initialState,
  action: Types.RootAction
): PbxState => {
  switch (action.type) {
    case actionTypes.GET_PBX_CONFIG_SUCCEEDED:
      return {
        ...state,
        pbxConfig: action.config as PBXUserConfig,
      };
    case actionTypes.GET_PBX_USERS_SUCCEEDED:
      return {
        ...state,
        pbxUserSearchResults: action.pbxUserSearchResults as PbxUserData[],
      };
    case actionTypes.GET_PBX_USERS_FAILED:
      return {
        ...state,
        pbxUserSearchResults: [],
      };
    case actionTypes.GET_PBX_USERS_RESET:
      return {
        ...state,
        pbxUserSearchResults: [],
      };
    case actionTypes.GET_PBX_USER_DETAILS_SUCCEEDED:
      return {
        ...state,
        pbxUserDetails: action.pbxUserDetails,
      };
    case actionTypes.GET_PBX_USER_DETAILS_FAILED:
      return {
        ...state,
        pbxUserDetails: null,
      };
    default:
      return state;
  }
};

export default pbxReducer;
