import { LogStoreBufferedIndexedDB, ILogStore, LogStoreMemory } from "./logStore";
import JSZip from "jszip";
import { TenantProvisioningApiProvider } from "services/ProvisioningApiProvider/TenantProvisioningApiProvider";
import { getLogger_, ILogger } from "./logger";
import { localStorageKeys } from "utils/constants";
import { JsonStringify } from "utils/utils";

interface ILogStorageConfig {
  WITH_INDEXEDDB: boolean;
  DB_NAME: string;
  LOGS_STORAGE: string;
  LINES_OF_LOGS_BUFFERED_BEFORE_INIT: number;
  BYTES_PER_BLOCK: number;
  CLEANUP_PERIODIC_RUN_SEC: number;
  KEEP_LOGS_IN_DB_FOR_HOURS: number;
  MAX_COLLECT_SIZE_BYTES: number;
  WAIT_FOR_DB_TO_OPEN_BEFORE_ERROR_IN_SEC: number;
}

const _defaultLogStorageConfig: ILogStorageConfig = {
  WITH_INDEXEDDB: true,
  DB_NAME: "database",
  LOGS_STORAGE: "logs",
  LINES_OF_LOGS_BUFFERED_BEFORE_INIT: 100,
  BYTES_PER_BLOCK: 1024 * 1024, // flush when 1 Mb memory full
  CLEANUP_PERIODIC_RUN_SEC: 60 * 60, // every hour once
  KEEP_LOGS_IN_DB_FOR_HOURS: 1 * 24, // keep logs for a day
  MAX_COLLECT_SIZE_BYTES: 10 * 1024 * 1024, // max 10 Mb of plain log data
  WAIT_FOR_DB_TO_OPEN_BEFORE_ERROR_IN_SEC: 30, // fail if indexedDb not ready in 30sec
};

let _logStorageConfig: ILogStorageConfig = {
  ..._defaultLogStorageConfig,
};

let _logger: ILogger;
let _logStorage: ILogStore<string> = new LogStoreMemory<string>(_logStorageConfig.LINES_OF_LOGS_BUFFERED_BEFORE_INIT);
let _module: string;

const newline = "\r\n";

const cleanupStorage = () => {
  _logStorage.cleanup(_logStorageConfig.KEEP_LOGS_IN_DB_FOR_HOURS);
};

export const haveIndexedDb = () => window.indexedDB !== undefined;

const readLogStorageConfigFromLocalStorage = () => {
  const logstoreConfigurationAsJsonString = localStorage.getItem(localStorageKeys.LOGSTORE_CONFIGURATION);
  if (logstoreConfigurationAsJsonString) {
    const logStoragePartial = JSON.parse(logstoreConfigurationAsJsonString);
    _logStorageConfig = {
      ..._defaultLogStorageConfig,
      ...logStoragePartial,
    };
    _logger.info(`Completed configuration from localStore: ${JSON.stringify(_logStorageConfig)}`);
  }
};

export async function logCollectorInitialize(): Promise<boolean> {
  _module = "logCollector";
  _logger = getLogger_("logstore");
  let initialized = false;

  // read config
  readLogStorageConfigFromLocalStorage();

  if (_logStorageConfig.WITH_INDEXEDDB) {
    if (haveIndexedDb()) {
      // create indexedDB storage
      const logStorageIndexedDb = new LogStoreBufferedIndexedDB(
        _logStorageConfig.DB_NAME,
        _logStorageConfig.LOGS_STORAGE,
        _logStorageConfig.BYTES_PER_BLOCK,
        _logStorageConfig.MAX_COLLECT_SIZE_BYTES,
        newline.length,
        _logStorageConfig.WAIT_FOR_DB_TO_OPEN_BEFORE_ERROR_IN_SEC
      );

      // asynchronous indexedDb setup
      await logStorageIndexedDb.initialize();

      _logger.debug(`${_module}: switching logStorage/before: ${_logStorage.info()}`);

      // switch logStorage, copy existing lines to real logStorage
      (await _logStorage.getAll()).forEach((line) => logStorageIndexedDb.push(line));
      await _logStorage.clearAll();
      _logStorage = logStorageIndexedDb;

      _logger.debug(`${_module}: switching logStorage/after: ${_logStorage.info()}`);

      // run once on startup
      cleanupStorage();

      // run periodically to cleanup
      setInterval(cleanupStorage, _logStorageConfig.CLEANUP_PERIODIC_RUN_SEC * 1000);

      initialized = true;
    } else {
      const errorText = "IndexedDb not available, log collector not initialized.";
      _logger.error(errorText);
      throw Error(errorText);
    }
  } else {
    _logger.info("IndexedDb not used as per configuration");
  }
  return initialized;
}

export function logCollectorFlushMemoryBufferToDb() {
  _logger.log(`${_module}: flushing logs still in memory to indexedDb`);
  _logStorage.flush();
}

function _stringify(obj: any) {
  try {
    const type = typeof obj;
    if (type === "function") {
      return undefined;
    } else if (["string", "number", "boolean", "symbol"].includes(type)) {
      return obj.toString();
    } else if (typeof obj === "object") {
      return JsonStringify(obj);
    } else {
      return obj.toString();
    }
  } catch (err) {
    const errorText = `(# _stringify error: ${err})`;
    _logger.warn(errorText);
    return errorText;
  }
}

const _cssEntryMatch = "color: ";
const _stringsToSkip = ["%c next state", "%c prev state"];

// main logCollector
// INPUT: data0: prefix_with_data_0, [ data_0, data_1 ... data_n ]
export async function logCollector(data0: string, data: any[]) {
  if (data?.length > 0) {
    // remove redux prev,next lines first, these json structures include cyclic dependencies breaking json.stringify()
    // also remove start-group, end-group
    if (data0 && _stringsToSkip.some((substring) => data0.includes(substring))) return;
  }

  // take prefix version
  if (data0) {
    data[0] = data0;
  }

  // stringify and remove invalid objects, filter out css entries (e.g. color: #03A9F4; font-weight: bold)
  const filtered = data
    ?.filter((value) => value !== undefined)
    .map((value) => _stringify(value))
    .filter((value) => value && !value.includes(_cssEntryMatch));

  if (filtered.length > 0) {
    const str = filtered.join(" ");
    await _logStorage.push(str);
  }
}

export function clearLogs(payload: any) {
  if (payload) {
    _logger.log(`${_module}: Clearing logs ${payload}`);
    const { url } = payload;
    window.URL.revokeObjectURL(url);
    _logStorage.clearAll();
  }
}

export async function createLogs(payload: any): Promise<string> {
  _logger.log(`${_module}: Generating logs...`);
  try {
    let url;
    const [{ description, files, submit }] = payload;
    const logsArray = await _logStorage.getAll();
    const logs = logsArray.join(newline);

    // Temporarily Disabled Until Electron Logging Working.
    // if (isElectron) {
    //   zip.file(`logs.log`, data);
    // } else {
    // zip.file(`logs.log`, content.join("\r\n"));
    // }
    if (submit) {
      const blob = await createZipBlob(description, files, logs);
      const formData = new FormData();
      for (let i in description) {
        if (i) {
          formData.append(i, description[i]);
        }
      }
      const zipFileName = `Enghouse_Connect_Logs_${new Date().toISOString()}.zip`;
      formData.append("logFile", blob, zipFileName); // saveAs(c, zipFileName);
      await TenantProvisioningApiProvider.Instance.uploadLogFiles(formData);
      url = zipFileName;
    } else {
      var log = new Blob([logs], { type: "octet/stream" });
      var a = document.createElement("a");
      a.download = `Enghouse_Connect_Logs_${new Date().toISOString()}.log`;
      a.href = url = URL.createObjectURL(log);
      a.click();
      a.remove();
    }
    _logger.log(`${_module}: Done generating logs`);
    return url;
  } catch (error) {
    _logger.error(`${_module}: Error generating logs: ${error}`);
    throw error;
  }
}

async function createZipBlob(description: any, files: any, content: string): Promise<Blob> {
  try {
    const zip = new JSZip();
    const desc = JSON.stringify(description, null, 5);

    zip.file(`logs.log`, content);
    zip.file(`description.log`, desc);

    if (files && files.length > 0) {
      files.forEach((file: any) => {
        if (file) {
          zip.file(file.name, file);
        }
      });
    }
    return await zip.generateAsync({ type: "blob" });
  } catch (error) {
    _logger.error(`${_module}: error creating Zip: ${error}`);
    throw error;
  }
}
