export function stripCN(description: RTCSessionDescriptionInit): Promise<RTCSessionDescriptionInit> {
  description.sdp = stripPayload(description.sdp || "", "CN");
  return Promise.resolve(description);
}

// from sipjs code
export const stripPayload = (sdp: string, payload: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mediaDescs: Array<any> = [];

  const lines: Array<string> = sdp.split(/\r\n/);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let currentMediaDesc: any;
  for (let i = 0; i < lines.length; ) {
    const line: string = lines[i];
    if (/^m=(?:audio|video)/.test(line)) {
      currentMediaDesc = {
        index: i,
        stripped: [],
      };
      mediaDescs.push(currentMediaDesc);
    } else if (currentMediaDesc) {
      const rtpmap = /^a=rtpmap:(\d+) ([^/]+)\//.exec(line);
      if (rtpmap && payload === rtpmap[2]) {
        lines.splice(i, 1);
        currentMediaDesc.stripped.push(rtpmap[1]);
        continue; // Don't increment 'i'
      }
    }

    i++;
  }

  for (const mediaDesc of mediaDescs) {
    const mline: Array<string> = lines[mediaDesc.index].split(" ");

    // Ignore the first 3 parameters of the mline. The codec information is after that
    for (let j = 3; j < mline.length; ) {
      if (mediaDesc.stripped.indexOf(mline[j]) !== -1) {
        mline.splice(j, 1);
        continue;
      }
      j++;
    }

    lines[mediaDesc.index] = mline.join(" ");
  }

  return lines.join("\r\n");
};
