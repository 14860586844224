export const GET_PBX_CONFIG_SUCCEEDED = "GET_PBX_CONFIG_SUCCEEDED";

export const GET_PBX_USERS = "GET_PBX_USERS";
export const GET_PBX_USERS_SUCCEEDED = "GET_PBX_USERS_SUCCEEDED";
export const GET_PBX_USERS_FAILED = "GET_PBX_USERS_FAILED";
export const GET_PBX_USERS_RESET = "GET_PBX_USERS_RESET";

export const GET_PBX_USER_DETAILS = "GET_PBX_USER_DETAILS";
export const GET_PBX_USER_DETAILS_SUCCEEDED = "GET_PBX_USER_DETAILS_SUCCEEDED";
export const GET_PBX_USER_DETAILS_FAILED = "GET_PBX_USER_DETAILS_FAILED";
