import React from "react";
import PropTypes from "prop-types";
import styles from "./SplashScreen.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { test } from "utils/helpers";

const SplashScreen = ({ message = "Loading" }) => {
  return (
    <div className={styles.main} {...test("SPLASH_SCREEN")}>
      <CircularProgress color="inherit" size={25} />
      <div className={styles.message}>{message}</div>
    </div>
  );
};

SplashScreen.propTypes = {
  message: PropTypes.string,
};

export default SplashScreen;
