import { RefObject, useEffect, useRef } from "react";

export const useEventListener = (
  eventName: string,
  handler: (event: any) => void,
  element?: RefObject<HTMLElement | Window | Document> | null,
  options?: boolean | AddEventListenerOptions
) => {
  const savedHandler = useRef(handler);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    if (element === null) return;

    const targetElement: HTMLElement | Window | Document = element?.current ?? window;

    if (!(targetElement && targetElement.addEventListener)) return;

    const listener: typeof handler = (event) => savedHandler.current(event);

    targetElement.addEventListener(eventName, listener, options);

    return () => {
      targetElement.removeEventListener(eventName, listener, options);
    };
  }, [eventName, element, options]);
};
