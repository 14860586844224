import { Components, Theme } from "@mui/material";
import { iconButtonClasses } from "@mui/material/IconButton";

const MuiIconButton: Components["MuiIconButton"] = {
  styleOverrides: {
    root: ({ theme }) => {
      const palette = (theme as Theme).palette;
      return {
        color: palette.icon.main,
        [`&.${iconButtonClasses.disabled}`]: {
          color: palette.icon.disabled,
        },
      };
    },
  },
};

export default MuiIconButton;
