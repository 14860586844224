import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/root-reducer";
import { TenantProvisioningApiProvider } from "services/ProvisioningApiProvider/TenantProvisioningApiProvider";
import { MatrixUserProvider } from "services/MatrixAPIProvider";
import {
  EndpointData,
  KeycloakPresenceData,
  UserKeycloakData,
} from "services/ProvisioningApiProvider/types/Provisioning";
import { CreateChatRoom } from "services/ProvisioningApiProvider/types/Rooms";
import { getStatusMessage } from "services/PresenceProvider/PresenceProvider";
import { provisioningActionsCreator } from "store/actions/provisioning";
import { AdHocInviteType, MatrixUser, SearchData, StatusValues } from "types/Matrix";
import { getUserName } from "utils/helpers";
import { debounce } from "throttle-debounce";
import { PBX_SEARCH_DEBOUNCE_DELAY_MS, localStorageKeys } from "utils/constants";

export const useProvisioning = () => {
  const dispatch = useDispatch();

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const { createGroupChatRoom, setProvisioiningEndpointData } = provisioningActionsCreator;

  const { usersList } = useSelector((state: RootState) => state.provisioning);

  const createGroupRoom = async (participantsList: string[], currentUserData: any, type: AdHocInviteType) => {
    try {
      setLoading(true);
      const opts: CreateChatRoom = {
        invite: participantsList,
        matrix_token: currentUserData.access_token,
        user_id: currentUserData.user_id,
      };

      dispatch(
        createGroupChatRoom({
          data: opts,
          type: type,
        })
      );
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
      throw e;
    }
  };

  const validateUrl = async (url: string) => {
    try {
      setLoading(true);
      const tenantProvisioningProvider = new TenantProvisioningApiProvider(true);
      await tenantProvisioningProvider.validateUrl(url);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
      throw e;
    }
  };

  const getEndpointData = async () => {
    try {
      setLoading(true);
      let data: EndpointData | null = await TenantProvisioningApiProvider.Instance.getEndpointData();
      dispatch(setProvisioiningEndpointData(data));
      setLoading(false);
      return data;
    } catch (e) {
      setError(e.message);
      setLoading(false);
      throw e;
    }
  };

  const provisioningLogout = async (token: string) => {
    try {
      setLoading(true);
      const result: string = await TenantProvisioningApiProvider.Instance.provisioningLogout(token);
      setLoading(false);
      return result;
    } catch (e) {
      setError(e.message);
      setLoading(false);
      throw e;
    }
  };

  const uploadLogFiles = async (formData: any) => {
    try {
      setLoading(true);
      await TenantProvisioningApiProvider.Instance.uploadLogFiles(formData);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
      throw e;
    }
  };

  const getUserKeycloakInfo = (userId: string) => {
    const username = getUserName(userId);
    return usersList?.find((user: UserKeycloakData) => user.username === username);
  };

  return {
    createGroupRoom,
    getEndpointData,
    provisioningLogout,
    uploadLogFiles,
    validateUrl,
    getUserKeycloakInfo,
    loading,
    error,
  };
};

export default useProvisioning;

export interface UseSearchProvisioningType {
  searchResult: SearchData[];
  search: (value: string, participants?: string[]) => void;
  loading: boolean;
}

export const useSearchProvisioning = (): UseSearchProvisioningType => {
  const dispatch = useDispatch();

  const [searchResult, setSearchResult] = useState<SearchData[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const userSearchResults = useSelector((state: RootState) => state.provisioning.userSearchResults);
  const { searchProvisioiningUser, resetProvisioiningSearch } = provisioningActionsCreator;
  const { getUser } = MatrixUserProvider();

  const startSearchWithDebounce = useMemo(
    () =>
      debounce(PBX_SEARCH_DEBOUNCE_DELAY_MS, (searchString) => {
        setSearchValue(searchString);
        setLoading(true);
        dispatch(searchProvisioiningUser(searchString));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSearchValue]
  );

  const search = useCallback(
    (value: string, participants?: string[]) => {
      if (participants) setSelectedParticipants(participants);
      startSearchWithDebounce(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const checkPresence = (
    userId: string,
    presence: KeycloakPresenceData
  ): { presenceStatus: string; presenceStatusMsg: string } => {
    if (presence && presence.status) {
      return {
        presenceStatus: presence.status,
        presenceStatusMsg: getStatusMessage(presence.status),
      };
    } else {
      const user: MatrixUser | null | undefined = getUser(userId);
      if (!user) {
        return {
          presenceStatus: StatusValues.offline.toString(),
          presenceStatusMsg: getStatusMessage(StatusValues.offline),
        };
      } else {
        return {
          presenceStatus: user.presenceStatus ? user.presenceStatus.toString() : user.presence,
          presenceStatusMsg: getStatusMessage(
            user.presenceStatus ? user.presenceStatus : user.presenceStatusMsg || user.presence
          ),
        };
      }
    }
  };

  useEffect(() => {
    if (!userSearchResults || (userSearchResults && userSearchResults.length === 0)) {
      setSearchResult([]);
      setLoading(false);
      return;
    }

    if (!searchValue || searchValue.length === 0) return;

    const { user_id, home_server } = JSON.parse(localStorage.getItem(localStorageKeys.CURRENT_USER) || "");
    const newList: SearchData[] = userSearchResults.map((user: UserKeycloakData) => {
      const { username, firstName, lastName, presence, email } = user;
      const userId: string = `@${username}:${home_server}`;
      const presenceStatus = checkPresence(userId, presence);
      return {
        userId,
        roomId: userId,
        avatarUrl: presence && presence.avatarUrl ? presence.avatarUrl : "",
        displayName: `${firstName} ${lastName}`,
        isRoom: false,
        isPrivateRoom: false,
        email: email,
        ...presenceStatus,
      };
    });
    setSearchResult(
      newList.filter(
        (u: SearchData) =>
          u.userId !== user_id && (selectedParticipants ? !selectedParticipants.includes(u.userId) : true)
      )
    );
    setSearchValue("");
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSearchResults]);

  useEffect(() => {
    return () => {
      if (userSearchResults && userSearchResults.length > 0) {
        dispatch(resetProvisioiningSearch());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { searchResult, search, loading };
};

export const useSearchByListOfUsernamesProvisioning = () => {
  const dispatch = useDispatch();

  const [searchResults, setSearchResults] = useState<UserKeycloakData[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const userSearchByListOfUsernamesResults = useSelector(
    (state: RootState) => state.provisioning.userSearchByListOfUsernamesResults
  );
  const { searchProvisioiningByListOfUsernames, resetProvisioiningSearchByListUsernames } = provisioningActionsCreator;

  const search = async (users: string[]) => {
    try {
      setLoading(true);
      dispatch(searchProvisioiningByListOfUsernames(users));
    } catch (e) {
      setError(e.messagege);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userSearchByListOfUsernamesResults) {
      setSearchResults([]);
      setLoading(false);
      return;
    }

    setSearchResults(userSearchByListOfUsernamesResults);
    setLoading(false);
  }, [userSearchByListOfUsernamesResults]);

  useEffect(() => {
    return () => {
      if (userSearchByListOfUsernamesResults && userSearchByListOfUsernamesResults.length > 0) {
        dispatch(resetProvisioiningSearchByListUsernames());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { searchResults, search, loading, error };
};

export const useSearchByListOfEmailsProvisioning = () => {
  const dispatch = useDispatch();

  const [searchResults, setSearchResults] = useState<UserKeycloakData[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const userSearchByListOfEmailsResults = useSelector(
    (state: RootState) => state.provisioning.userSearchByListOfEmailsResults
  );
  const { searchProvisioiningByListOfEmails, resetProvisioiningSearchByListEmails } = provisioningActionsCreator;

  const search = async (emails: string[]) => {
    try {
      setLoading(true);
      dispatch(searchProvisioiningByListOfEmails(emails));
    } catch (e) {
      setError(e.messagege);
      setLoading(false);
    }
  };

  const directSearch = async (emails: string[]) => {
    try {
      const result: UserKeycloakData[] = await TenantProvisioningApiProvider.Instance.searchByListOfEmails(emails);

      return result;
    } catch (e) {
      setError(e.messagege);
    }
  };

  useEffect(() => {
    if (!userSearchByListOfEmailsResults) {
      setSearchResults([]);
      setLoading(false);
      return;
    }

    const { user_id, home_server } = JSON.parse(localStorage.getItem(localStorageKeys.CURRENT_USER) || "");
    setSearchResults(
      userSearchByListOfEmailsResults.filter((u: UserKeycloakData) => `@${u.username}:${home_server}` !== user_id)
    );

    setLoading(false);
  }, [userSearchByListOfEmailsResults]);

  useEffect(() => {
    return () => {
      if (userSearchByListOfEmailsResults && userSearchByListOfEmailsResults.length > 0) {
        dispatch(resetProvisioiningSearchByListEmails());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { searchResults, search, loading, error, directSearch };
};
