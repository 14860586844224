import { UserAgent, TransportState, UserAgentState, UserAgentOptions } from "sip.js";
import { getLogger } from "logger/appLogger";

const logger = getLogger("sip");

class SipUserAgent extends UserAgent {
  // Number of times to attempt reconnection before giving up
  _maxReconnectionAttempts = 10;
  // Used to guard against overlapping reconnection attempts
  _attemptingReconnection = false;
  // If false, reconnection attempts will be discontinued or otherwise prevented
  _shouldBeConnected = true;
  // seq
  _seq = 0;
  // last timeout
  _lastTimeout: any = undefined;
  // restart callback
  _onReconnectsFailed: () => void;

  module = "SipUserAgent";

  constructor(options: Partial<UserAgentOptions>, onReconnectsFailed: () => void) {
    super(options);
    this._onlineListener();
    this._onReconnectsFailed = onReconnectsFailed;
  }

  set shouldBeConnected(bool) {
    this._shouldBeConnected = bool;
  }

  get shouldBeConnected() {
    return this._shouldBeConnected;
  }

  /**
   * Function which recursively attempts reconnection
   * @param reconnectionAttempt - indicates how many times we have attempted to reconnect. Default to 1.
   */
  doAttemptReconnection(caller: string, reconnectionAttempt = 1) {
    const seq = ++this._seq;
    logger.debug(
      `doAttemptReconnection seq=${seq} caller=${caller} reconnectionAttempt=${reconnectionAttempt} maxReconnectionAttempts=${this._maxReconnectionAttempts}`
    );

    // If not intentionally connected, don't reconnect.
    if (!this.shouldBeConnected) {
      return;
    }
    // Reconnection attempt already in progress
    if (this._attemptingReconnection) {
      return;
    }
    // Reconnection maximum attempts reached
    if (reconnectionAttempt > this._maxReconnectionAttempts) {
      logger.info(`doAttemptReconnection seq=${seq} maxReconnectionAttempts reached => onReconnectsFailed`);
      if (this._lastTimeout) {
        clearTimeout(this._lastTimeout);
        this._lastTimeout = undefined;
      }
      if (this._onReconnectsFailed) {
        this._onReconnectsFailed();
      }
      return;
    }

    this._attemptingReconnection = true;
    const timeoutMs = reconnectionAttempt === 1 ? 0 : this.configuration.reconnectionDelay * 1000;

    logger.debug(`doAttemptReconnection seq=${seq} timeoutMs=${timeoutMs}`);

    this._lastTimeout = setTimeout(() => {
      // If not intentionally connected, don't reconnect.
      if (!this.shouldBeConnected) {
        this._attemptingReconnection = false;
        return;
      }
      // Attempt reconnect
      logger.debug(`doAttemptReconnection seq=${seq} reconnect`);
      this.reconnect()
        .then(() => {
          // Reconnect attempt succeeded
          this._attemptingReconnection = false;
          logger.debug(`doAttemptReconnection seq=${seq} success`);
        })
        .catch((e) => {
          // Reconnect attempt failed
          logger.error(`${this.module}: Reconnect (doAttemptReconnection) failed. Try again - ${e.message}`);
          this._attemptingReconnection = false;
          this.doAttemptReconnection("doAttemptReconnection", ++reconnectionAttempt);
        });
    }, timeoutMs);
  }

  // Monitor network connectivity and attempt reconnection when browser goes online
  _onlineListener() {
    try {
      window.ononline = () => {
        if (this.transport.state === TransportState.Disconnected && this.state !== UserAgentState.Stopped) {
          this.doAttemptReconnection("networkMonitor");
        }
      };
    } catch (e) {
      logger.error(`${this.module}: Online listener failed - ${e.message}`);
    }
  }
}

export default SipUserAgent;
