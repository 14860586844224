import React, { useRef, useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useAppSelector } from "store/hooks";
import { getLogger } from "logger/appLogger";
import styles from "./RoomPage.module.scss";
import { useTheme } from "@mui/material/styles";
import { test, convertHTMLToEmoji, convertHTMLtoText, convertLineBreakTextToHTML } from "utils/helpers";
import Avatar from "components/_shared/Avatar";
import IconButton from "components/_shared/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ReplyIcon from "@mui/icons-material/Reply";
import ForwardIcon from "@mui/icons-material/Forward";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MicNoneIcon from "@mui/icons-material/MicNone";
import SendIcon from "@mui/icons-material/Send";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import { Alert, AlertTitle, Stack, Typography } from "@mui/material";
import ReactQuill, { Quill } from "react-quill";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";
import { useMatrix, useMatrixMessage, useMatrixRooms, useMessageStorage, useMatrixAvatar } from "hooks";
import { useTranslation } from "react-i18next";
import twemoji from "twemoji";
import { isImageTypeAllowed, convertBytes } from "matrix/message";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { editMessageAction, replyMessageAction, forwardMessageAction } from "store/actions/user";
import styled from "themes/theme/baseTheme";
import "./RoomPage.scss";
import { isEmpty } from "utils/helpers";

Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const RoomFooter = ({ room, memberList }) => {
  const logger = useMemo(() => getLogger("matrix"), []);
  const module = "RoomFooter";
  const { t } = useTranslation();
  const theme = useTheme();

  const RECORDAUDIO_ENABLED = false;

  const dispatch = useDispatch();
  const { changeRoomHidden, calculateUserName } = useMatrixRooms(room.roomId);
  const {
    sendTextMessage,
    sendTyping,
    sendHtmlMessage,
    getSenderDisplayName,
    sendImage,
    sendFile,
    getMessageData,
    updateMessage,
    replyMessage,
    forwardMessage,
    getEventContent,
    isReplyMessage,
    isForwardedMessage,
    processForwardMessage,
    getForwardMessageData,
  } = useMatrixMessage(room);
  const { getRoomMemberAvatar } = useMatrixAvatar();
  const { uploadContent } = useMatrix("");
  const {
    savedMessage,
    editMessageData,
    replyMessageData,
    forwardMessageData,
    saveMessageText,
    saveEditMessage,
    saveReplyMessage,
    saveForwardMessage,
  } = useMessageStorage(room.roomId);

  const [openDialog, setOpenDialog] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [, setChosenEmoji] = useState(null);
  const [isEmojiOpen, setOpenEmoji] = useState(false);
  const [currentMsg, setCurrentMsg] = useState("");
  const [isQuillOpen, setQuillOpen] = useState(false);
  const [isUserListOpen, setIsUserListOpen] = useState(false);
  const [filterKey, setFilterKey] = useState();
  const [filteredMemberList, setFilteredMemberList] = useState([]);
  const [sendMessageDisabled, setSendMessageDisabled] = useState(true);
  const [mentionsIndex, setMentionsIndex] = useState(0);

  const currentTheme = useAppSelector((state) => state.theme.currentTheme);
  const editMessageDataFromState = useAppSelector((state) => state.user.editMessageData);
  const replyMessageDataFromState = useAppSelector((state) => state.user.replyMessageData);
  const forwardMessageDataFromState = useAppSelector((state) => state.user.forwardMessageData);

  const fileInput = useRef();
  const quillRef = useRef();
  const emojiRef = useRef();
  const emojiBtnRef = useRef();

  const imageHandler = (imageDataUrl, type, imageData) => {
    const filename = imageData.name;
    const file = imageData.toFile(filename);
    const item = {
      file,
      type,
    };
    setFilesToUpload((prevItems) => [...prevItems, item]);
    setOpenDialog(true);
  };

  const [modules] = useState({
    toolbar: [["bold", "italic", "underline", "strike"], ["blockquote", "code-block"], ["clean"]],
    clipboard: {
      matchVisual: false,
    },
    imageDropAndPaste: {
      handler: imageHandler,
    },
    // prevent default behavior
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: () => {
            return;
          },
        },
      },
    },
  });

  const onEmojiClick = (emojiObject) => {
    setChosenEmoji(emojiObject);
    setOpenEmoji(false);

    let newMessage;
    const img = twemoji.parse(emojiObject.native, {
      folder: "svg",
      ext: ".svg",
    });
    const editor = quillRef.current.getEditor();
    const selection = editor.selection.savedRange.index || 0;
    editor.clipboard.dangerouslyPasteHTML(selection, img);
    newMessage = editor.root.innerHTML;

    setCurrentMsg(newMessage);
    setEditorCursor(newMessage);
  };

  const onEmojiClickOutside = (emojiObject) => {
    if (
      emojiRef &&
      emojiRef.current &&
      !emojiRef.current.contains(emojiObject.target) &&
      emojiBtnRef &&
      emojiBtnRef.current &&
      !emojiBtnRef.current.contains(emojiObject.target)
    ) {
      setOpenEmoji(!isEmojiOpen);
    }
  };

  const closeReplyMessage = () => {
    saveReplyMessage(null);
    setCurrentMsg("");
  };

  const closeEditMessage = () => {
    saveEditMessage(null);
    setCurrentMsg("");
  };

  const closeForwardMessage = () => {
    saveForwardMessage(null);
    setCurrentMsg("");
  };

  const handleEmojiOpen = () => {
    let editor = quillRef.current.getEditor();
    editor.blur();
    setOpenEmoji(!isEmojiOpen);
  };

  const handleQuillOpen = (value) => {
    setQuillOpen(!isQuillOpen);
  };

  const onFileChange = (e) => {
    try {
      const { target } = e;
      const { files } = target;
      if (!files || files.length <= 0) return;
      setOpenDialog(true);
      Array.from(files).forEach(async (file) => {
        const { type } = file;
        const fileType = type.match("image.*") && isImageTypeAllowed(type) ? "image" : file;
        const item = {
          file: file,
          type: fileType,
        };
        setFilesToUpload((prevItems) => [...prevItems, item]);
      });
    } catch (e) {
      logger.error(`${module}: Error in onFileChange - ${e.message}`);
    }
  };

  const checkForMention = (editor) => {
    try {
      const text = editor.getText();
      const textsArr = text.replace(/(\r\n|\n|\r)/gm, "").split("");
      const filterKeyNew = text
        .replace(/(\r\n|\n|\r)/gm, "")
        .split("@")
        .pop()
        .split(/\s+/)[0];

      if (textsArr && textsArr.length < 1) {
        setIsUserListOpen(false);
        return;
      }

      if (textsArr && textsArr[textsArr.length - 1] === " ") {
        setIsUserListOpen(false);
        setFilterKey("");
        return;
      }

      if (textsArr && textsArr[textsArr.length - 2] === "@") {
        setIsUserListOpen(true);
      }

      if (filterKey !== filterKeyNew) {
        setFilterKey(filterKeyNew);
      }
    } catch (e) {
      logger.error(`${module}: Error in checkForMention - ${e.message}`);
    }
  };

  const mentionUser = (member) => {
    try {
      const editor = quillRef.current.getEditor();
      const mentionFormat = `<a href="https://matrix.to/#/${member.userId}" class="mentionStyle">@${
        member.name || member.userId
      }</a> `;
      //exclude @filterKey inside of href (.../@filterkey) and display name\userids (<a...>@filterkey</a>)
      const regex = new RegExp(`(?<!/)@${filterKey}\t*(?!.*</a>)`, "g");
      const newMessage = currentMsg.replace(regex, mentionFormat);
      editor.container.firstChild.innerHTML = newMessage.replace("<p><br></p>", "");
      setTimeout(() => {
        editor.setSelection(newMessage.length + 1);
      }, 100);
      setIsUserListOpen(false);
    } catch (e) {
      logger.error(`${module}: Error in mentionUser - member:${member} - ${e.message}`);
    }
  };

  const handleChange = async (htmlMessage) => {
    try {
      if (!htmlMessage) return;
      setCurrentMsg(htmlMessage);
      if (isTyping) return;
      setIsTyping(true);
      await sendTyping(room.roomId, true);
      setTimeout(() => {
        setIsTyping(false);
      }, 5000);
    } catch (e) {
      setIsTyping(false);
      logger.error(`${module}: Error in handleChange - ${e.message}`);
    }
  };

  const handleBlurContainer = (e) => {
    saveMessageText(currentMsg);
  };

  const handleBlur = async (e) => {
    setOpenEmoji(false);
    let toolbar = document.getElementsByClassName("ql-container");
    toolbar[0]?.classList.remove("quill-focus");
    try {
      if (!isTyping) return;
      setIsTyping(false);
      await sendTyping(room.roomId, false);
    } catch (e) {
      setIsTyping(false);
      logger.error(`${module}: Error in handleBlur - ${e.message}`);
    }
  };

  const handleFocus = async (e) => {
    try {
      let toolbar = document.getElementsByClassName("ql-container");
      toolbar[0].classList.add("quill-focus");
    } catch (e) {
      logger.error(`${module}: Error in handleFocus - ${e.message}`);
    }
  };

  const handleSendMessage = async () => {
    if (sendMessageDisabled) {
      return;
    }
    let editor = quillRef.current.getEditor();
    let editorHTML = convertHTMLToEmoji(editor.root.innerHTML);
    let htmlMessage = editorHTML.innerHTML;
    let currentText = convertHTMLtoText(htmlMessage).replaceAll(/[\r\n]+/g, "\n");

    if (editMessageData) {
      updateMessage(editMessageData, htmlMessage);
      closeEditMessage();
      return;
    }
    if (replyMessageData) {
      replyMessage(replyMessageData, currentText || currentMsg, htmlMessage);
      closeReplyMessage();
      return;
    }
    if (forwardMessageData) {
      forwardMessage(forwardMessageData, currentText || currentMsg, htmlMessage);
      closeForwardMessage();
      return;
    }

    if (currentMsg) {
      await sendHtmlMessage(currentText || currentMsg, htmlMessage);
      setCurrentMsg("");
      changeRoomHidden(room.roomId, false);
    } else {
      return;
    }
  };

  const handleKeyUp = async (e) => {
    try {
      if (isUserListOpen) {
        if (e.key === "ArrowUp" || e.keyCode === 38) {
          if (mentionsIndex > 0) {
            e.preventDefault();
            setMentionsIndex(mentionsIndex - 1);
          }
        } else if (e.key === "ArrowDown" || e.keyCode === 40) {
          if (mentionsIndex < memberList.length - 1) {
            e.preventDefault();
            setMentionsIndex(mentionsIndex + 1);
          }
        } else if (!e.shiftKey && (e.key === "Enter" || e.keyCode === 13)) {
          e.preventDefault();
          mentionUser(filteredMemberList[mentionsIndex]);
        } else if (!e.shiftKey && (e.key === "Tab" || e.keyCode === 9)) {
          // NOTE: we're arriving here *after* onChange() so we have added a tab
          // like this: @User\t. It is removed in mentionUser() by replacing a regex with
          // optional trailing \t. Also note filter key splitting in checkForMention()
          // on white space - including this eventual tab.
          e.preventDefault();
          mentionUser(filteredMemberList[mentionsIndex]);
        }
      } else {
        if (!e.shiftKey && (e.key === "Enter" || e.keyCode === 13)) {
          handleSendMessage();
        }
      }
    } catch (e) {
      logger.error(`${module}: Error in handleKeyUp - ${e.message}`);
    }
  };

  const handleOnClick = (e) => {
    fileInput.current.click();
  };

  const setEditorCursor = (message) => {
    let editor = quillRef.current.getEditor();
    const selection = editor.selection.savedRange.index;
    if (message) {
      setTimeout(() => {
        editor.setSelection(selection + message.length);
      }, 100);
      editor.focus();
      editor.getSelection(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { target } = e;
    const messageData = target.message.value;
    if (messageData) {
      sendTextMessage(messageData);
      target.message.value = null;
      setCurrentMsg("");
    }
  };

  const handleClose = (e, reason) => {
    clearFiles();
  };

  const handleAgreeClick = async (e, reason) => {
    try {
      setIsUploading(true);
      setUploadError(null);
      const promises = filesToUpload.map(async (item) => {
        const { file } = item;
        const { type } = file;
        const opts = {
          progressHandler: (progress) => {},
        };
        try {
          const res = await uploadContent(file, opts);
          if (type.match("image.*") && isImageTypeAllowed(type)) {
            sendImage(res, file);
          } else {
            sendFile(res, file);
          }
        } catch (e) {
          logger.error(`${module}: Error in handleAgreeClick - ${e.message}`);
          throw e;
        }
      });
      await Promise.all(promises);
      setIsUploading(false);
      clearFiles();
    } catch (e) {
      setUploadError(e.message);
      setIsUploading(false);
      setFilesToUpload([]);
      logger.error(`${module}: Error in handleAgreeClick - ${e.message}`);
    }
  };

  const handleCloseClick = async (e, reason) => {
    setUploadError(null);
    setIsUploading(false);
    clearFiles();
  };

  const clearFiles = () => {
    setOpenDialog(false);
    setFilesToUpload([]);
    if (fileInput && fileInput.current) {
      fileInput.current.value = "";
    }
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        emojiRef &&
        emojiRef.current &&
        !emojiRef.current.contains(event.target) &&
        emojiBtnRef &&
        emojiBtnRef.current &&
        !emojiBtnRef.current.contains(event.target)
      ) {
        setOpenEmoji(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiRef]);

  useEffect(() => {
    const toolbar = document.getElementsByClassName("ql-toolbar");
    const msgInput = document.getElementById("msgInput");
    if (isQuillOpen) {
      toolbar[0].className = "ql-toolbar ql-snow show-toolbar";
      msgInput.classList.add("show-container");
    } else {
      toolbar[0].className = "ql-toolbar ql-snow";
      msgInput.classList.remove("show-container");
    }
  }, [isQuillOpen]);

  useEffect(() => {
    if (!uploadError) {
      setOpenAlert(false);
    } else {
      setOpenAlert(true);
    }
  }, [uploadError]);

  useEffect(() => {
    if (currentMsg) {
      let editor = quillRef.current.getEditor();
      let editorHTML = convertHTMLToEmoji(editor.root.innerHTML);
      setSendMessageDisabled(editorHTML.textContent.trim().length === 0);
      checkForMention(editor);
      editor.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMsg]);

  useEffect(() => {
    if (editMessageData) {
      let body = editMessageData.event.content.formatted_body;
      const eventContent = getEventContent(editMessageData);
      const isReplyMsg = isReplyMessage(eventContent);
      const isForwardedMsg = isForwardedMessage(eventContent);
      if (eventContent && (isReplyMsg || isForwardedMsg)) {
        if (isReplyMsg) body = eventContent.body;
        if (isForwardedMsg) {
          const fwdData = processForwardMessage(eventContent);
          body = fwdData.fwdMsg;
        }
      }

      setCurrentMsg(
        twemoji.parse(body, {
          folder: "svg",
          ext: ".svg",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMessageData]);

  useEffect(() => {
    if (room && room.roomId) {
      setCurrentMsg(savedMessage); // quill
      setEditorCursor(savedMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room.roomId, savedMessage]);

  useEffect(() => {
    if (room && room.roomId) {
      if (editMessageDataFromState) {
        dispatch(editMessageAction(null)); // ack & remove from state
        saveEditMessage(editMessageDataFromState); // to storage and to us via re-render change
      }
      if (replyMessageDataFromState) {
        dispatch(replyMessageAction(null)); // ack & remove from state
        saveReplyMessage(replyMessageDataFromState); // to storage and to us via re-render change
      }
      if (forwardMessageDataFromState) {
        dispatch(forwardMessageAction(null)); // ack & remove from state
        saveForwardMessage(forwardMessageDataFromState); // to storage and to us via re-render change
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room.roomId, editMessageDataFromState, replyMessageDataFromState, forwardMessageDataFromState]);

  useEffect(() => {
    if (isUserListOpen && memberList?.length > 0) {
      const filteredMemberListLocal = isEmpty(filterKey)
        ? memberList
        : memberList.filter((member) => member.name.toLowerCase().includes(filterKey.toLowerCase()));
      setFilteredMemberList(filteredMemberListLocal);
    } else {
      setFilteredMemberList([]);
    }
    setMentionsIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserListOpen, memberList, filterKey]);

  useEffect(() => {
    if (forwardMessageData) {
      setSendMessageDisabled(false);
    } else {
      setSendMessageDisabled(true);
    }
  }, [forwardMessageData]);

  return (
    <AppBar
      color="transparent"
      position="static"
      elevation={0}
      className={`container ${styles.roomFooter}`}
      {...test("ROOM_FOOTER")}
    >
      <div ref={emojiRef} style={!isEmojiOpen ? { display: "none" } : {}} className={styles.roomFooterEmojiPicker}>
        <Picker
          data={data}
          onEmojiSelect={onEmojiClick}
          onClickOutside={onEmojiClickOutside}
          theme={currentTheme.palette.mode}
          title={t("SELECT_EMOJI")}
        />
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="upload confirmation"
        open={openDialog}
        PaperProps={{ sx: { width: "450px" } }}
      >
        <DialogTitle id="simple-dialog-title">{t("UPLOADCONFIRMATION")}</DialogTitle>
        <DialogContent>
          <Collapse in={openAlert}>
            <Alert onClose={() => setOpenAlert(false)} severity="error" style={{ marginBottom: "1rem" }}>
              <AlertTitle>{t("FAILEDTOUPLOADFILE")}: </AlertTitle>
              {uploadError}
            </Alert>
          </Collapse>
          <StyledTypography id="alert-dialog-description" variant="body2">
            {t("AREYOUSUREUPLOADFILE")}
          </StyledTypography>
          {isUploading ? (
            <StyledTypography variant="body2">{t("UPLOADINGITEMS")}...</StyledTypography>
          ) : (
            <List>
              {filesToUpload.map((item) => (
                <ListItem key={`${item.file.name}${item.file.lastModified}`} sx={{ padding: "8px 0" }}>
                  <StyledTypography variant="body2">
                    {t("NAME")}: {item.file.name} &nbsp; {t("SIZE")}: {convertBytes(item.file.size)}
                  </StyledTypography>
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          {!uploadError ? (
            <>
              <Button
                id="room_footer_dialog_disagree_button"
                onClick={handleClose}
                color="primary"
                variant="outlined"
                disabled={isUploading}
              >
                {t("DISAGREE")}
              </Button>
              <Button
                id="room_footer_dialog_agree_button"
                onClick={handleAgreeClick}
                color="primary"
                autoFocus
                disabled={isUploading}
              >
                {t("AGREE")}
              </Button>
            </>
          ) : (
            <Button
              id="room_footer_dialog_close_button"
              onClick={handleCloseClick}
              color="primary"
              autoFocus
              disabled={isUploading}
            >
              {t("CLOSE")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Toolbar sx={{ margin: "5px 0 10px", alignItems: "flex-end" }}>
        <IconButton
          tooltip={t("ATTACHFILES")}
          tooltipPlacement="top"
          sx={{ width: "42px", height: "42px" }}
          onClick={handleOnClick}
          size="large"
        >
          <AttachFileIcon style={{ fontSize: 24 }}></AttachFileIcon>
        </IconButton>
        {RECORDAUDIO_ENABLED && (
          <IconButton
            tooltip={t("RECORDAUDIO")}
            tooltipPlacement="top"
            disabled
            sx={{ width: "42px", height: "42px" }}
            size="large"
          >
            size="large"
            <MicNoneIcon></MicNoneIcon>
          </IconButton>
        )}
        {isUserListOpen && filteredMemberList.length > 0 && (
          <Paper className={styles.mentionsMemberList}>
            <List disablePadding aria-label="member list">
              {filteredMemberList.map((member, index) => {
                const name = calculateUserName(member.userId, member.user);
                return (
                  <ListItemButton
                    selected={index === mentionsIndex}
                    className={styles.mentionsMember}
                    onClick={() => mentionUser(member)}
                    key={member.userId}
                  >
                    <Avatar
                      src={getRoomMemberAvatar(room.roomId, member.userId)}
                      name={name}
                      disableBadge={true}
                      size="25"
                    />
                    <ListItemText primary={name} />
                  </ListItemButton>
                );
              })}
            </List>
          </Paper>
        )}
        <form onSubmit={handleSubmit} className={styles.messageForm}>
          <div
            onBlur={handleBlurContainer}
            style={{
              "--inputPlaceholderColor": theme.palette.text.chat.inputPlaceholder,
              "--inputBorderColor": theme.palette.border.chat.inputBorderColor,
              "--inputBorderFocusColor": theme.palette.border.chat.inputBorderFocusColor,
              "--inputBackgroundColor": theme.palette.background.chat.currentUser,
            }}
          >
            <input
              style={{ display: "none", height: "0", width: "0" }}
              type="file"
              name="file"
              onChange={onFileChange}
              ref={fileInput}
            />
            {editMessageData && (
              <div className="edit-message-wrapper">
                <Stack
                  id="edit_message_wrapper"
                  direction="row"
                  alignItems="center"
                  spacing={"12px"}
                  sx={{
                    backgroundColor: theme.palette.background.chat.currentUserReply,
                    padding: "3px 8px",
                    borderRadius: "4px",
                  }}
                >
                  <EditIcon />
                  <Stack id="edit_message" direction="column">
                    <Typography variant="h6">{getSenderDisplayName(editMessageData)}</Typography>
                    <div
                      className="message-body scroll-bar"
                      dangerouslySetInnerHTML={{
                        __html: twemoji.parse(editMessageData.event.content.formatted_body, {
                          folder: "svg",
                          ext: ".svg",
                        }),
                      }}
                    ></div>
                  </Stack>
                  <div className="close" onClick={closeEditMessage}>
                    <CloseIcon sx={{ fontSize: "1rem" }} />
                  </div>
                </Stack>
              </div>
            )}
            {replyMessageData && (
              <div className="edit-message-wrapper">
                <Stack
                  id="reply_message_wrapper"
                  direction="row"
                  alignItems="center"
                  spacing={"12px"}
                  sx={{
                    backgroundColor: theme.palette.background.chat.currentUserReply,
                    padding: "3px 8px",
                    borderRadius: "4px",
                  }}
                >
                  <ReplyIcon />
                  <Stack id="reply_message" direction="column">
                    <Typography variant="h6">{getSenderDisplayName(replyMessageData)}</Typography>
                    <div
                      className="message-body scroll-bar"
                      style={{ marginTop: "5px" }}
                      dangerouslySetInnerHTML={{
                        __html: getMessageData(
                          replyMessageData.event.content,
                          false,
                          convertLineBreakTextToHTML(replyMessageData.event.content.body)
                        ),
                      }}
                    ></div>
                  </Stack>
                  <div className="close" onClick={closeReplyMessage}>
                    <CloseIcon sx={{ fontSize: "1rem" }} />
                  </div>
                </Stack>
              </div>
            )}
            {forwardMessageData && (
              <div className="edit-message-wrapper">
                <Stack
                  id="forward_message_wrapper"
                  direction="row"
                  alignItems="center"
                  spacing={"12px"}
                  sx={{
                    backgroundColor: theme.palette.background.chat.currentUserReply,
                    padding: "3px 8px",
                    borderRadius: "4px",
                  }}
                >
                  <ForwardIcon />
                  <Stack id="reply_message" direction="column">
                    <Typography variant="h6">{getSenderDisplayName(forwardMessageData)}</Typography>
                    <div
                      className="message-body scroll-bar"
                      style={{ marginTop: "5px" }}
                      dangerouslySetInnerHTML={{
                        __html: getForwardMessageData(forwardMessageData),
                      }}
                    ></div>
                  </Stack>
                  <div className="close" onClick={closeForwardMessage}>
                    <CloseIcon sx={{ fontSize: "1rem" }} />
                  </div>
                </Stack>
              </div>
            )}
            <ReactQuill
              name="message"
              type="text"
              id="msgInput"
              placeholder={`${t("TYPEYOURMESSAGE")}...`}
              autoComplete="off"
              className={`${styles.messageInput} ${currentTheme.palette.mode}`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={currentMsg}
              onFocus={handleFocus}
              onKeyUp={handleKeyUp}
              ref={quillRef}
              theme="snow"
              modules={modules}
            />
          </div>
        </form>

        <IconButton
          tooltip={t("SENDM")}
          tooltipPlacement="top"
          sx={{ width: "42px", height: "42px" }}
          onClick={handleSendMessage}
          disabled={sendMessageDisabled}
          size="large"
        >
          <SendIcon style={{ fontSize: 24 }}></SendIcon>
        </IconButton>

        <IconButton
          tooltip={t("TOOLS")}
          tooltipPlacement="top"
          sx={{ width: "42px", height: "42px" }}
          onClick={handleQuillOpen}
          size="large"
        >
          <TextFormatIcon style={{ fontSize: 24 }}></TextFormatIcon>
        </IconButton>
        <IconButton
          tooltip={t("SENDEMOJI")}
          tooltipPlacement="top"
          sx={{
            width: "42px",
            height: "42px",
          }}
          ref={emojiBtnRef}
          onClick={handleEmojiOpen}
          size="large"
        >
          <EmojiEmotionsIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

RoomFooter.propTypes = {
  room: PropTypes.object.isRequired,
};

export default RoomFooter;
