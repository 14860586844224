import { getMatrixAPIProvider } from "services/MatrixAPIProvider";
import { CallMediaType } from "types/UC";

const CustomStyleToExtStream = () => {
  const matrixProvider = getMatrixAPIProvider();

  const localAudioElemContainer = "p2p_audio_container_local";
  const remoteAudioElemContainer = "p2p_audio_container_remote";

  const localVideoElemContainer = "p2p_video_container_local";
  const remoteVideoElemContainer = "p2p_video_container_remote";

  const localVideoElem = "local_video_render";
  const remoteVideoElem = "remote_video_render";

  const localElemContainer = document.createElement("div");
  localElemContainer.className = "p2p_video_container";
  localElemContainer.id = localVideoElemContainer;
  const localElem = document.createElement("video");
  localElem.id = localVideoElem;

  const remoteElemContainer = document.createElement("div");
  remoteElemContainer.className = "p2p_video_container";
  remoteElemContainer.id = remoteVideoElemContainer;
  const remoteElem = document.createElement("video");
  remoteElem.id = remoteVideoElem;

  const UpdateElemStyleToExtStream = (type: CallMediaType, isLocal: boolean) => {
    const audioElementId = isLocal ? localAudioElemContainer : remoteAudioElemContainer;
    const videoElementId = isLocal ? localVideoElemContainer : remoteVideoElemContainer;

    const audioElement = document.getElementById(audioElementId);
    const videoElement = document.getElementById(videoElementId);

    if (audioElement && audioElement.className && videoElement && videoElement.className) {
      const isVideoMuted = videoElement.className.includes("p2p_video_container_muted");
      const isAudioMuded = audioElement.className.includes("audio-tile-muted");
      if (type === CallMediaType.audio) {
        if (isAudioMuded) {
          audioElement.className = audioElement.className.replace(" audio-tile-muted", "");
        }
        if (!isVideoMuted) {
          videoElement.className += " p2p_video_container_muted";
        }
      } else {
        if (isVideoMuted) {
          videoElement.className = videoElement.className.replace(" p2p_video_container_muted", "");
        }
        if (!isAudioMuded) {
          audioElement.className += " audio-tile-muted";
        }
      }
    }
  };

  const AssignViewToExtStream = (divId: string, isSelfView: any, type: CallMediaType) => {
    const rndr = !isSelfView ? document.getElementById(`${divId}_${type}`) : document.getElementById(divId);
    if (!rndr) return;
    if (isSelfView) {
      const elementExists = document.getElementById(localVideoElemContainer);
      if (!elementExists) {
        localElemContainer?.appendChild(localElem);
        rndr?.insertBefore(localElemContainer, rndr?.lastElementChild);
        matrixProvider.setCallElements(null, localElem);
      }
      UpdateElemStyleToExtStream(type, true);
    } else {
      const elementExists = document.getElementById(remoteVideoElemContainer);
      if (!elementExists) {
        remoteElemContainer?.appendChild(remoteElem);
        rndr?.insertBefore(remoteElemContainer, rndr?.lastElementChild);
        matrixProvider.setCallElements(remoteElem, null);
        UpdateElemStyleToExtStream(type, false);
      }
    }
  };

  return {
    UpdateElemStyleToExtStream,
    AssignViewToExtStream,
  };
};

export default CustomStyleToExtStream;
