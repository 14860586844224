import { Components, Theme } from "@mui/material";

const MuiAppBar: Components["MuiAppBar"] = {
  styleOverrides: {
    colorDefault: ({ theme, ownerState }) => {
      const palette = (theme as Theme).palette;
      return {
        backgroundColor: palette.background.default,
        backgroundImage: "unset",
      };
    },
  },
};

export default MuiAppBar;
