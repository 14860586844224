import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import { Alert, AlertTitle, DialogActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { test } from "utils/helpers";

import { useTranslation } from "react-i18next";
import FileUploader from "components/FileUploader/FileUploader";

interface EditRoomAvatarDialogProps {
  errorMessage?: string | null;
  isLoading?: boolean;
  openDialog: boolean;
  handleRoomAvatar: (file: File) => void;
  handleRemoveRoomAvatar: () => void;
  handleDialogClose: () => void;
}

const EditRoomAvatarDialog = ({
  errorMessage,
  openDialog,
  handleRoomAvatar,
  handleRemoveRoomAvatar,
  handleDialogClose,
}: EditRoomAvatarDialogProps) => {
  const { t } = useTranslation();
  const [openAlert, setopenAlert] = useState(false);

  const handleFile = (file: File) => {
    try {
      if (file) {
        handleRoomAvatar(file);
      }
    } catch (e) {}
  };

  useEffect(() => {
    setopenAlert(Boolean(errorMessage));
  }, [errorMessage]);

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="upload room avatar"
      open={openDialog}
      PaperProps={{ sx: { width: "320px" } }}
      {...test("ADD_CONTACTS_DIALOG")}
    >
      <DialogTitle id="simple-dialog-title">{"Avatar"}</DialogTitle>

      <DialogContent>
        <Collapse in={openAlert}>
          <Alert onClose={() => setopenAlert(false)} severity="error" style={{ marginBottom: "1rem" }}>
            <AlertTitle>Failed to upload avatar</AlertTitle>
            {errorMessage}
          </Alert>
        </Collapse>

        <FileUploader
          handleFile={handleFile}
          id="edit_room_avatar_dialog_chooseimg_button"
          size="large"
          buttonLabel={t("CHOOSE_IMAGE")}
          buttonClass="muiStyles.dialogContentText"
        />
        <DialogContentText id="alert-dialog-description">
          <Button
            id="edit_room_avatar_dialog_removeavt_button"
            size="large"
            variant={"text"}
            sx={{ textTransform: "none", justifyContent: "start" }}
            onClick={() => {
              handleRemoveRoomAvatar();
            }}
          >
            {t("REMOVE_PHOTO")}
          </Button>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id="edit_room_avatar_dialog_close_button"
          onClick={() => {
            handleDialogClose();
          }}
        >
          {t("CANCEL")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditRoomAvatarDialog;
