import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "themes/theme/baseTheme";
import Dialog from "components/_shared/Dialog";
import MicrophoneSelectList from "./selects/MicrophoneSelectList";
import SpeakerSelectList from "./selects/SpeakerSelectList";
import CameraSelectList from "./selects/CameraSelectList";
import SelfView from "containers/SelfView/SelfView";
import "./MediaSettings.scss";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "386px",
    height: "610px",
    backgroundColor: theme.palette.background.default,
  },
}));

const MediaSettings = ({ closeSettings }) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(true);
  const onClose = () => {
    if (closeSettings) {
      closeSettings();
      setOpenDialog(false);
    }
  };

  return (
    <StyledDialog
      id={"media_settings"}
      open={openDialog}
      dialogTitle={t("SETTINGS")}
      showIconClose={true}
      onClose={onClose}
    >
      <div className="settingsContainer scroll-bar">
        <div className="section">
          <div className="separator titleContainer">
            <span>{t("CAMERA")}</span>
          </div>
          <div className="deviceContainer">
            <CameraSelectList showTitle={false} />
            <div className="selfViewContainer">
              <SelfView ignoreMuteState={true} />
            </div>
          </div>
        </div>
        <div className="section">
          <div className="separator titleContainer">
            <span>{t("SPEAKER")}</span>
          </div>
          <div className="deviceContainer">
            <SpeakerSelectList showTitle={false} />
          </div>
        </div>
        <div className="section">
          <div className="separator titleContainer">
            <span>{t("MICROPHONE")}</span>
          </div>
          <div className="deviceContainer">
            <MicrophoneSelectList showTitle={false} />
          </div>
        </div>
      </div>
    </StyledDialog>
  );
};

export default MediaSettings;
