import { CallParticipantsInfo, CallState } from "store/types/call";
import { Types } from "Types";
import * as actionTypes from "../actions/types/call";

const initialState: CallState = {
  active: false,
  joining: false,
  leaving: false,
  callRoomId: null,
  outCall: false,
  properties: {},
  participants: {
    list: [],
    participantJoined: null,
    participantLeft: null,
  },
  callStartedTime: 0,
  recorderOn: false,
  moderationStatus: {
    audioHardMute: false,
    videoHardMute: false,
  },
  moderationAction: {
    audioHardMute: false,
    videoHardMute: false,
  },
  remoteWindowShares: [],
  localWindowShares: [],
  selectedShare: null,
  screenShareSelecting: false,
  disconnectReason: null,
  detached: false,
  meetingName: "",
  recorderPaused: false,
  recorderWebCasting: false,
  participantsInfo: [],
  callInFullScreen: false,
};

const callReducer = (state = initialState, action: Types.RootAction): CallState => {
  switch (action.type) {
    case actionTypes.START_CALL:
      const { outCall, meetingName, participantsInfo } = action.payload;
      return {
        ...state,
        joining: true,
        disconnectReason: null,
        outCall: outCall ? outCall : state.outCall,
        meetingName: meetingName ? meetingName : state.meetingName,
        participantsInfo: participantsInfo ? participantsInfo : state.participantsInfo,
      };

    case actionTypes.SET_CALL_ROOM_ID:
      return {
        ...state,
        callRoomId: action.payload || "",
      };

    case actionTypes.OUT_CALL:
      return {
        ...state,
        outCall: action.payload,
      };

    case actionTypes.START_CALL_SUCCEEDED:
      return {
        ...state,
        joining: false,
        active: true,
        callStartedTime: action.callStartedTime || 0,
        participantsInfo: action.participantsInfo ? action.participantsInfo : state.participantsInfo,
      };

    case actionTypes.START_CALL_FAILED:
      return {
        ...state,
        joining: false,
        disconnectReason: action.reason || null,
        meetingName: "",
        participantsInfo: [],
      };

    case actionTypes.GET_CALL_PROPERTIES:
      return {
        ...state,
        properties: {},
      };

    case actionTypes.GET_CALL_PROPERTIES_SUCCEEDED:
      return {
        ...state,
        properties: action.properties || {},
      };

    case actionTypes.END_CALL:
      return {
        ...state,
        leaving: true,
        properties: {},
      };

    case actionTypes.END_CALL_SUCCEEDED:
    case actionTypes.END_CALL_FAILED:
      return {
        ...state,
        disconnectReason: action.type === actionTypes.END_CALL_FAILED ? action.reason : null,
        joining: false,
        leaving: false,
        active: false,
        detached: false,
        outCall: false,
        callRoomId: null,
        meetingName: "",
        participantsInfo: [],
        moderationStatus: {
          ...initialState.moderationStatus,
        },
        moderationAction: {
          ...initialState.moderationAction,
        },
        callInFullScreen: false,
      };

    case actionTypes.UPDATE_PARTICIPANTS:
      return {
        ...state,
        participants: {
          list: [...action.participants.list],
          participantLeft: action.participants.participantLeft,
          participantJoined: action.participants.participantJoined,
        },
      };

    case actionTypes.WINDOW_SHARE_START:
      return {
        ...state,
        screenShareSelecting: true,
      };

    case actionTypes.WINDOW_SHARE_START_SUCCEEDED:
      return {
        ...state,
        screenShareSelecting: false,
      };

    case actionTypes.WINDOW_SHARE_START_FAILED:
      return {
        ...state,
        screenShareSelecting: false,
      };

    case actionTypes.LOCAL_WINDOW_SHARES_UPDATE:
      return {
        ...state,
        localWindowShares: [...action.localWindowShares],
        selectedShare: action.localWindowShares.find((s: { selected: any }) => s.selected) || null,
      };

    case actionTypes.REMOTE_WINDOW_SHARES_UPDATE:
      return {
        ...state,
        remoteWindowShares: [...action.remoteWindowShares],
      };

    case actionTypes.UPDATE_RECORDER_STATUS:
      return {
        ...state,
        recorderOn: action.recorderStatus,
        recorderPaused: action.paused,
        recorderWebCasting: action.webCasting,
      };

    case actionTypes.UPDATE_MODERATION_STATUS:
      return {
        ...state,
        moderationStatus: {
          ...state.moderationStatus,
          ...action.moderationStatus,
        },
      };

    case actionTypes.UPDATE_MODERATION_ACTION:
      return {
        ...state,
        moderationAction: {
          ...state.moderationAction,
          ...action.moderationAction,
        },
      };

    case actionTypes.REJOIN_CALL:
      return {
        ...initialState,
      };

    case actionTypes.MEETING_NAME:
      return {
        ...state,
        meetingName: action.payload,
      };

    case actionTypes.CALL_IN_FULL_SCREEN:
      return {
        ...state,
        callInFullScreen: action.payload,
      };

    case actionTypes.UPDATE_PARTICIPANT_INFO: {
      const participant: CallParticipantsInfo = action.participant;
      let participantsInfo = state.participantsInfo;

      participantsInfo = participantsInfo.map((info: CallParticipantsInfo) =>
        info.username === participant.username ? participant : info
      );

      return {
        ...state,
        participantsInfo: participantsInfo,
      };
    }

    default:
      return state;
  }
};

export default callReducer;
