import { useTheme } from "@mui/material/styles";
import { getLogger } from "logger/appLogger";
import { useEffect, useState, useMemo, useRef } from "react";
import { isAndroid, isMobileSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import CallEndIcon from "@mui/icons-material/CallEnd";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import OpenInFullIcon from "@mui/icons-material/OpenInFullRounded";
import PeopleIcon from "@mui/icons-material/People";
import ReplyIcon from "@mui/icons-material/Reply";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ChatIcon from "icons/ChatIcon";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CallStatusMessage from "components/CallStatusMessage";
import { showToastNotification } from "components/Notifications";
import IconButton from "components/_shared/IconButton";
import FlipCameraButton from "containers/FlipCameraButton";
import MediaSettings from "containers/MediaSettings/MediaSettings";
import ParticipantsList from "containers/ParticipantsList/ParticipantsList";
import CameraToggle from "containers/QuickMediaSettings/toggles/CameraToggle";
import MicrophoneToggle from "containers/QuickMediaSettings/toggles/MicrophoneToggle";
import SpeakerToggle from "containers/QuickMediaSettings/toggles/SpeakerToggle";
import RecorderStatus from "containers/RecorderStatus/RecorderStatus";
import ShareButton from "containers/Share/Button/Button";
import { useEventListener, useRouter, useWebRTCStats, useConferenceModeration, useMatrixJoinConference } from "hooks";
import { useUserSettings } from "hooks/useUserSettings";
import { useKeyboardShortcut } from "hooks/useHandleWindowsEvents";
import { useMobileDimension } from "hooks/useMobile";
import { VidyoParticipantAppType } from "services/CallAPIProvider/types/Vidyo";
import Render from "services/CustomRenderer";
import { callActions } from "store/actions/call";
import * as configActionCreators from "store/actions/config";
import * as googleAnalytics from "store/actions/googleAnalytics";
import { matrixActions } from "store/actions/matrix";
import { useAppSelector } from "store/hooks";
import { getLocalRoom, selectDetached } from "store/selectors";
import styled from "themes/theme/baseTheme";
import { Events, TemplateKey } from "types/UC";
import { getProperCase, test, unsafeParseTextFromHTMLString } from "utils/helpers";
import ConferenceActiveIndicator from "views/room/RoomHeader/ConferenceActiveIndicator.jsx";
import "./Call.scss";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.neutrals.pureWhite,
}));

const StyledEndIconButton = styled(IconButton)(({ theme }) => ({
  height: "42px",
  width: "42px",
  padding: 0,
  backgroundColor: theme.palette.sentiment.error.base,
  color: theme.palette.common.white,
  border: "none",
  outline: "none",
  "&:hover": {
    backgroundColor: theme.palette.sentiment.error.dark1,
    boxShadow: "none",
  },
  "&:focus": {
    outline: "none",
  },
}));

type modeType = "" | "full-screen" | "chat-screen";

const Call = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { push } = useRouter();
  const theme = useTheme();

  const callRoomId = useAppSelector((state) => state.call.callRoomId);

  const detached = useAppSelector((state) => selectDetached(state, location.pathname));
  const chatVisible = useAppSelector((state) => state.matrix.chatVisible);
  const hasActiveMatrixCall = useAppSelector((state) => state.matrix.hasActiveMatrixCall);
  const matrixSelectedShareStream = useAppSelector((state) => state.matrix.matrixSelectedShareStream);
  const matrixCallRoomId = useAppSelector((state) => state.matrix.matrixCallRoomId);
  const isCallActive = useAppSelector((state) => state.call.active);
  const isCallLeaving = useAppSelector((state) => state.call.leaving);
  const isOutCall = useAppSelector((state) => state.call.outCall);
  const participants = useAppSelector((state) => state.call.participants);
  const callProperties = useAppSelector((state) => state.call.properties);
  const callStartedTime = useAppSelector((state) => state.call.callStartedTime);
  const recorderOn = useAppSelector((state) => state.call.recorderOn);
  const recorderPaused = useAppSelector((state) => state.call.recorderPaused);
  const isStatisticsOverlaySet = useAppSelector((state) => state.config.isStatisticsOverlaySet);
  const localRoom = useAppSelector((state) =>
    getLocalRoom(state, callRoomId && callRoomId.length > 0 ? callRoomId : matrixCallRoomId!)
  );
  const currentRoomId = useAppSelector((state) => state.matrix.roomId);
  const isMicrophoneHardMuted = useAppSelector((state) => state.call.moderationStatus.audioHardMute);
  const isCameraHardMuted = useAppSelector((state) => state.call.moderationStatus.videoHardMute);
  const actionMicrophoneHardMuted = useAppSelector((state) => state.call.moderationAction.audioHardMute);
  const actionCameraHardMuted = useAppSelector((state) => state.call.moderationAction.videoHardMute);

  const { updateParticipantsInfo } = useMatrixJoinConference();
  const {
    isRoomOwner,
    getLocalParticipantId,
    disableAllMicrophones,
    disableAllCameras,
    pendingOperationAllEnableDisableMicrophones,
    pendingOperationAllEnableDisableCameras,
  } = useConferenceModeration();

  const [isMobileDimension, isPortrait] = useMobileDimension();
  const [isParticipantsListOpen, setParticipanstListState] = useState(false);
  const [mediaSettingsOpen, setMediaSettingsOpen] = useState(false);
  const [mode, setMode] = useState<{ current: modeType; old: modeType }>({
    current: "full-screen",
    old: "full-screen",
  });
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

  const callName = (callProperties as any).callName;
  const afterCallStarted = (new Date().getTime() - callStartedTime) / 1000 > 5;
  const ONLY_PARTICIPANT_CALL_END_TIME = 30;
  const showToggleParticipants = isCallActive;
  const logger = useMemo(() => getLogger("call"), []);
  const { getUserSettings } = useUserSettings();
  const { notifications } = getUserSettings();
  const documentRef = useRef<Document>(document);

  useWebRTCStats(isCallActive, callRoomId ?? "");

  function handleEndCallClick() {
    if (hasActiveMatrixCall) {
      dispatch(matrixActions.hangupMatrixCall());
    } else {
      dispatch(callActions.endCall());
    }
  }

  function toggleParticipantsList() {
    setParticipanstListState(!isParticipantsListOpen);
  }

  async function toggleFullScreen() {
    if (chatVisible) {
      await dispatch(matrixActions.setChatVisible(false));
    }

    if (mode.current === "full-screen") {
      setMode((prevState) => ({
        old: prevState.current,
        current: "",
      }));
    } else {
      setMode((prevState) => ({
        old: prevState.current,
        current: "full-screen",
      }));
    }
  }

  async function toggleChatScreen() {
    if (mode.current === "chat-screen") {
      await dispatch(matrixActions.setChatVisible(false));

      setMode((prevState) => ({
        old: prevState.current,
        current: prevState.old,
      }));
    } else {
      await dispatch(matrixActions.setChatVisible(true));

      setMode((prevState) => ({
        old: prevState.current,
        current: "chat-screen",
      }));
    }
  }

  function toggleMediaSettings() {
    setMediaSettingsOpen(!mediaSettingsOpen);
  }

  function onBack() {
    if (isOutCall) {
      setMode((prevState) => ({
        old: prevState.current,
        current: "full-screen",
      }));
    } else {
      if (hasActiveMatrixCall) {
        push(`/room/${matrixCallRoomId}`);
      } else if (callRoomId) {
        push(`/room/${callRoomId}`);
      }
    }
  }

  const handleEndShare = () => {
    if (hasActiveMatrixCall) {
      dispatch(matrixActions.stopMatrixScreenShare());
    } else {
      dispatch(callActions.stopWindowShare());
    }
  };

  useEffect(() => {
    if (mode.current === "full-screen") dispatch(callActions.setCallInFullScreen(true));
    else dispatch(callActions.setCallInFullScreen(false));
    // eslint-disable-next-line
  }, [mode.current]);

  useEffect(() => {
    dispatch(
      callActions.assignVideoRenderer({
        viewId: null,
        showPreview: isMobileSafari || (isMobileDimension && isPortrait),
        showAudioMeters: null,
      })
    );
  }, [isMobileDimension, isPortrait, dispatch]);

  useEffect(() => {
    if (isCallActive) {
      if (isOutCall) {
        setMode((prevState) => ({
          old: prevState.current,
          current: "full-screen",
        }));
      }
    }
  }, [isCallActive, isOutCall]);

  useEffect(() => {
    if (isCallActive && currentRoomId && callRoomId && currentRoomId !== callRoomId) {
      setMediaSettingsOpen(false);
      setParticipanstListState(false);
    }
  }, [currentRoomId, isCallActive, callRoomId]);

  useEffect(() => {
    const participant = participants.participantJoined as any;
    if (!participant) return;
    if (participant.AppType !== VidyoParticipantAppType.default) return;
    if (
      !participant.isLocal &&
      afterCallStarted &&
      !hasActiveMatrixCall &&
      !notifications.disableJoinLeaveNotifications
    ) {
      showToastNotification(TemplateKey.banner, {
        title: unsafeParseTextFromHTMLString(getProperCase(participant.name)),
        message: t("HAS_JOINED_THE_CONFERENCE"),
        icon: <LoginIcon sx={{ color: theme.palette.icon.notification.login }} />,
      });

      updateParticipantsInfo(participant);
    }
    // eslint-disable-next-line
  }, [participants.participantJoined]);

  useEffect(() => {
    const participant = participants.participantLeft as any;
    if (!participant) return;
    if (participant.AppType !== VidyoParticipantAppType.default) return;
    if (
      !participant.isLocal &&
      isCallActive &&
      !isCallLeaving &&
      afterCallStarted &&
      !hasActiveMatrixCall &&
      !notifications.disableJoinLeaveNotifications
    ) {
      showToastNotification(TemplateKey.banner, {
        title: unsafeParseTextFromHTMLString(getProperCase(participant.name)),
        message: t("LEFT_THE_CONFERENCE"),
        icon: <LogoutIcon sx={{ color: theme.palette.icon.notification.logout }} />,
      });
    }
    // eslint-disable-next-line
  }, [participants.participantLeft]);

  useEffect(() => {
    if (participants.list.length === 1) {
      let onlyParticipantLastTime = new Date().getTime();
      const interval = setInterval(() => {
        if ((new Date().getTime() - onlyParticipantLastTime) / 1000 / 60 > ONLY_PARTICIPANT_CALL_END_TIME) {
          logger.warn(`User is alone in the call more than ${ONLY_PARTICIPANT_CALL_END_TIME} minutes. Exiting.`);
          dispatch(callActions.endCall());
          dispatch(googleAnalytics.exitAfterAloneInCall());
        }
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [participants, dispatch, logger]);

  useEffect(() => {
    return () => {
      dispatch(configActionCreators.setStatisticsOverlay(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!matrixSelectedShareStream) return;
    const track = matrixSelectedShareStream.getVideoTracks()[0];
    if (track)
      track.addEventListener("ended", () => {
        handleEndShare();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrixSelectedShareStream]);

  useEffect(() => {
    // conference-moderation (vidyo) hard mute FIX:
    // this is a special fix for the case where mic/camera hard-muted and room owner leaves and rejoins conference
    // - on active disable audio/video, we receive a hard-mute callback (true) followed by hard-mute callback (false)
    // - but when rejoining a hard-muted room, we recieve a hard-mute callback (true) from vidyo, like plain non-owner
    //   participants do. However, as room-owners, we should always be able to unmute ourselves.
    // - so reinvoke disableAllMicrophones()/disableAllCameras()
    // - action...HardMuted are used to differentiate from manually invoked disableAllMicrophones()/disableAllCameras()
    //   from conference-moderation call menu
    if (isRoomOwner) {
      if (isMicrophoneHardMuted && !actionMicrophoneHardMuted && !pendingOperationAllEnableDisableMicrophones) {
        const participantId = getLocalParticipantId();
        if (participantId > 0) {
          logger.info("overriding audioHardMute for room-owner");
          disableAllMicrophones(participantId);
        }
      }
      if (isCameraHardMuted && !actionCameraHardMuted && !pendingOperationAllEnableDisableCameras) {
        const participantId = getLocalParticipantId();
        if (participantId > 0) {
          logger.info("overriding videoHardMute for room-owner");
          disableAllCameras(participantId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    participants,
    isRoomOwner,
    actionMicrophoneHardMuted,
    actionCameraHardMuted,
    isMicrophoneHardMuted,
    isCameraHardMuted,
    pendingOperationAllEnableDisableMicrophones,
    pendingOperationAllEnableDisableCameras,
  ]);

  useKeyboardShortcut(
    {
      shiftKey: true,
      ctrlKey: true,
      code: "KeyT",
    },
    () => {
      dispatch(configActionCreators.setStatisticsOverlay(!isStatisticsOverlaySet));
    }
  );

  useEventListener(
    Events.RECORDER_ERROR,
    () => {
      setShowSnackbar(true);
    },
    documentRef
  );

  return (
    <div className={`in-call ${mode.current}`} {...test("IN_CALL_MARKER")}>
      <div className="call-screen">
        <div className="header">
          <div className="room-name" {...test("CONFERENCE_NAME")}>
            {callName}
          </div>
        </div>
        <div className="render-container">
          {participants.list.length === 1 && (
            <CallStatusMessage
              title={t("YOU_ONLY_ONE_PERSON_IN_CALL")}
              description={t("AS_OTHERS_JOIN_CALL_YOU_WILL_SEE_THEM")}
            />
          )}
          <div id="renderer"></div>
          <Render />
          {mode.current === "full-screen" && (
            <div className="conference-status-container">
              <div className="header-section-right"></div>
              <ConferenceActiveIndicator fullScreen={true} />
            </div>
          )}

          {isParticipantsListOpen && <ParticipantsList onClose={toggleParticipantsList} />}
        </div>

        <div className="snackbar-container">
          {recorderOn && !recorderPaused ? <RecorderStatus status={t("THE_CONFERENCE_IS_BEING_RECORDED")} /> : <></>}
          {recorderPaused ? <RecorderStatus status={t("RECORDING_PAUSED")} /> : <></>}
          {showSnackbar && (
            <RecorderStatus error onCloseCallback={() => setShowSnackbar(false)} status={t("RECORDING_ERROR")} />
          )}
        </div>

        <div className="call-controls">
          <div className="call-controls-buttons">
            {((!detached && !isOutCall) || (isOutCall && mode.current !== "")) && (
              <>
                <StyledEndIconButton
                  id="call_end_button"
                  tooltip={t("TOOLTIP_END_CALL")}
                  onClick={handleEndCallClick}
                  {...test("END_CALL_BUTTON")}
                >
                  <CallEndIcon />
                </StyledEndIconButton>

                {showToggleParticipants && (
                  <StyledIconButton
                    id="call_participants_button"
                    tooltip={t("TOOLTIP_PARTICIPANTS")}
                    tooltipPlacement="top"
                    className="toggle"
                    onClick={toggleParticipantsList}
                    {...test("CALL_PARTICIPANTS_BUTTON")}
                  >
                    <PeopleIcon />
                  </StyledIconButton>
                )}
              </>
            )}

            <MicrophoneToggle />

            {((detached && !isOutCall) || (isOutCall && mode.current === "")) && (
              <StyledIconButton
                id="call_go_back_button"
                tooltip={t("TOOLTIP_GO_BACK")}
                tooltipPlacement="top"
                className="toggle go-back"
                onClick={onBack}
                {...test("CALL_GO_BACK_BUTTON")}
              >
                <ReplyIcon />
              </StyledIconButton>
            )}

            {((!detached && !isOutCall) || (isOutCall && mode.current !== "")) && (
              <>
                <CameraToggle />

                {(isMobileSafari || isAndroid || isMobileDimension) && (
                  <Tooltip title={t("TOOLTIP_FLIP_CAMERA")} placement="top" arrow>
                    <FlipCameraButton />
                  </Tooltip>
                )}

                <SpeakerToggle />

                {!isOutCall && (
                  <StyledIconButton
                    id="call_chat_button"
                    tooltip={mode.current === "chat-screen" ? t("TOOLTIP_CHAT_ON") : t("TOOLTIP_CHAT_OFF")}
                    tooltipPlacement="top"
                    className="toggle"
                    aria-label="Chat"
                    onClick={toggleChatScreen}
                    {...test("CALL_CHAT_BUTTON")}
                    style={
                      mode.current === "chat-screen" ? { backgroundColor: theme.palette.sentiment.success.dark1 } : {}
                    }
                  >
                    <Badge
                      overlap="rectangular"
                      badgeContent={localRoom?.unreadNotificationCount}
                      invisible={!(localRoom && localRoom.unreadNotificationCount > 0) || chatVisible}
                      color="primary"
                    >
                      <ChatIcon />
                    </Badge>
                  </StyledIconButton>
                )}

                <ShareButton />

                <StyledIconButton
                  id="call_fullscreen_button"
                  tooltip={mode.current === "" ? t("TOOLTIP_FULLSCREEN_OFF") : t("TOOLTIP_FULLSCREEN_ON")}
                  tooltipPlacement="top"
                  className="toggle"
                  onClick={toggleFullScreen}
                  {...test("CALL_EXPAND_BUTTON")}
                >
                  {mode.current === "full-screen" ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
                </StyledIconButton>
                <StyledIconButton
                  id="call_settings_button"
                  tooltip={t("TOOLTIP_SETTINGS")}
                  tooltipPlacement="top"
                  className="toggle"
                  onClick={toggleMediaSettings}
                  {...test("CALL_SETTINGS_BUTTON")}
                >
                  <SettingsOutlinedIcon />
                </StyledIconButton>
              </>
            )}
          </div>
          {((!detached && !isOutCall) || (isOutCall && mode.current !== "")) && (
            <div className="call-controls-label">
              <Typography style={{ fontSize: "10px", color: theme.palette.common.white }}>
                {t("DEVICE_MODERATION_CONTROLS")}
              </Typography>
            </div>
          )}
        </div>
        {mediaSettingsOpen && <MediaSettings closeSettings={toggleMediaSettings} />}
      </div>
    </div>
  );
};

export default Call;
