const portalFeatures = 
'<?xml version="1.0" encoding="utf-8"?>' +
'<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
'<soap:Body>' +
'<GetPortalFeaturesRequest xmlns="http://portal.vidyo.com/user/v1_1" >' +
'</GetPortalFeaturesRequest>' +
'</soap:Body>' +
'</soap:Envelope>';

// ROOM --------------------------------------------------
const pinRestrictions =
'<?xml version="1.0" encoding="utf-8"?>' +
'<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v1="http://portal.vidyo.com/user/v1_1">' +
'<soap:Body>' +
'<v1:GetPINLengthRangeRequest/>' +
'</soap:Body>' +
'</soap:Envelope>';

const searchByEntityID = (id) => {
    const message = 
    '<?xml version="1.0" encoding="utf-8"?>' +
    '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
    '<soap:Body>' +
    '<SearchByEntityIDRequest xmlns="http://portal.vidyo.com/user/v1_1">' +
    '<entityID xmlns="http://portal.vidyo.com/user/v1_1">' +
    id +
    '</entityID>' +
    '<Filter xmlns="http://portal.vidyo.com/user/v1_1">' +
    '<EntityType  xmlns="http://portal.vidyo.com/user/v1_1">Room</EntityType>' +
    '</Filter>' +
    '</SearchByEntityIDRequest>' +
    '</soap:Body>' +
    '</soap:Envelope>';
    return message
}

const searchPersonalRoomByEntityID = (id) => {
    const message = 
    '<?xml version="1.0" encoding="utf-8"?>' +
    '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
    '<soap:Body>' +
    '<SearchByEntityIDRequest xmlns="http://portal.vidyo.com/user/v1_1">' +
    '<entityID xmlns="http://portal.vidyo.com/user/v1_1">' +
    id +
    '</entityID>' +
    '<Filter xmlns="http://portal.vidyo.com/user/v1_1">' +
    '<EntityType  xmlns="http://portal.vidyo.com/user/v1_1">Member</EntityType>' +
    '</Filter>' +
    '</SearchByEntityIDRequest>' +
    '</soap:Body>' +
    '</soap:Envelope>';
    return message
}

const deleteRoom = (roomId) => {
    const message = 
    '<?xml version="1.0" encoding="utf-8"?>' +
    '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
    '<soap:Body>' +
    '<DeleteRoomRequest xmlns="http://portal.vidyo.com/user/v1_1">' +
    '<roomID xmlns="http://portal.vidyo.com/user/v1_1">' +
    roomId +
    '</roomID>' +
    '</DeleteRoomRequest>' +
    '</soap:Body>' +
    '</soap:Envelope>';
    return message
}

const createScheduledRoom = (pin) => {
    const message = 
    '<?xml version="1.0" encoding="utf-8"?>' +
    '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
    '<soap:Body>' +
    '<CreateScheduledRoomRequest xmlns="http://portal.vidyo.com/user/v1_1">' +
    '<setPIN xmlns="http://portal.vidyo.com/user/v1_1">' +
    pin +
    '</setPIN>' +
    '</CreateScheduledRoomRequest>' +
    '</soap:Body>' +
    '</soap:Envelope>';
    return message
}

const createPublicRoom = (displayName, inMyContacts, locked, pin, description) => {
    let message = ''
    if (pin) {
        message = 
        '<?xml version="1.0" encoding="utf-8"?>' +
        '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
        '<soap:Body>' +
        '<CreatePublicRoomRequest xmlns="http://portal.vidyo.com/user/v1_1">' +
        '<displayName xmlns="http://portal.vidyo.com/user/v1_1">' +
        displayName +
        '</displayName>' +
        '<inMyContacts xmlns="http://portal.vidyo.com/user/v1_1">' + 
        inMyContacts + 
        '</inMyContacts>' +
        '<locked xmlns="http://portal.vidyo.com/user/v1_1">' +
        locked +
        '</locked>' +
        '<setPIN xmlns="http://portal.vidyo.com/user/v1_1">' +
        pin +
        '</setPIN>' +
        '<description xmlns="http://portal.vidyo.com/user/v1_1">' +
        description +
        '</description>' +
        '</CreatePublicRoomRequest>' +
        '</soap:Body>' +
        '</soap:Envelope>';
    } else {
        message = 
        '<?xml version="1.0" encoding="utf-8"?>' +
        '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
        '<soap:Body>' +
        '<CreatePublicRoomRequest xmlns="http://portal.vidyo.com/user/v1_1">' +
        '<displayName xmlns="http://portal.vidyo.com/user/v1_1">' +
        displayName +
        '</displayName>' +
        '<inMyContacts xmlns="http://portal.vidyo.com/user/v1_1">' + 
        inMyContacts + 
        '</inMyContacts>' +
        '<locked xmlns="http://portal.vidyo.com/user/v1_1">' +
        locked +
        '</locked>' +
        '<description xmlns="http://portal.vidyo.com/user/v1_1">' +
        description +
        '</description>' +
        '</CreatePublicRoomRequest>' +
        '</soap:Body>' +
        '</soap:Envelope>';
    }
    return message
}

const createRoomPin = (roomId, pin) => {
    let message = ''
    if(pin) {
        message = '<?xml version="1.0" encoding="utf-8"?>' +
        '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
        '<soap:Body>' +
        '<CreateRoomPINRequest xmlns="http://portal.vidyo.com/user/v1_1">' +
        '<roomID xmlns="http://portal.vidyo.com/user/v1_1">' +
        roomId +
        '</roomID>' +
        '<PIN xmlns="http://portal.vidyo.com/user/v1_1">' +
        pin +
        '</PIN>' +
        '</CreateRoomPINRequest>' +
        '</soap:Body>' +
        '</soap:Envelope>';
    } else {
        message =
        '<?xml version="1.0" encoding="utf-8"?>' +
        '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
        '<soap:Body>' +
        '<RemoveRoomPINRequest xmlns="http://portal.vidyo.com/user/v1_1">' +
        '<roomID xmlns="http://portal.vidyo.com/user/v1_1">' +
        roomId +
        '</roomID>' +
        '</RemoveRoomPINRequest>' +
        '</soap:Body>' +
        '</soap:Envelope>';
    }
    return message
}

const inviteContent = (roomId) => {
    const message = 
    '<?xml version="1.0" encoding="utf-8"?>' +
    '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
    '<soap:Body>' +
    '<GetInviteContentRequest xmlns="http://portal.vidyo.com/user/v1_1">' +
    '<roomID xmlns="http://portal.vidyo.com/user/v1_1">' +
    roomId +
    '</roomID>' +
    '<returnHtmlContent xmlns="http://portal.vidyo.com/user/v1_1">true</returnHtmlContent>' +
    '</GetInviteContentRequest>' +
    '</soap:Body>' +
    '</soap:Envelope>';
    return message
}

// ACCOUNT --------------------------------------------------
const account =
'<?xml version="1.0" encoding="utf-8"?>' +
'<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
'<soap:Body>' +
'<MyAccountRequest xmlns="http://portal.vidyo.com/user/v1_1" />' +
'</soap:Body>' +
'</soap:Envelope>';

// LOGIN ----------------------------------------------------
const logInType = 
'<?xml version="1.0" encoding="utf-8"?>' +
'<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
'<soap:Body>' +
'<LogInTypeRequest xmlns="http://portal.vidyo.com/general" >' +
'<samlToken>true</samlToken>' +
'</LogInTypeRequest>' +
'</soap:Body>' +
'</soap:Envelope>';

const logIn = 
'<?xml version="1.0" encoding="utf-8"?>' +
'<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
'<soap:Body>' +
'<LogInRequest xmlns="http://portal.vidyo.com/user/v1_1" >' +
'<returnAuthToken xmlns="http://portal.vidyo.com/user/v1_1">true</returnAuthToken>' +
'</LogInRequest>' +
'</soap:Body>' +
'</soap:Envelope>';

const logOut = 
'<?xml version="1.0" encoding="utf-8"?>' +
'<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
'<soap:Body>' +
'<LogOutRequest xmlns="http://portal.vidyo.com/user/v1_1" >' +
'</LogOutRequest>' +
'</soap:Body>' +
'</soap:Envelope>'

const Messages = {
    portalFeaturesMessage : portalFeatures,
    loginMessage : logIn,
    logOutMessage : logOut,
    loginTypeMessage : logInType,
    accountMessage : account,
    pinRestrictionsMessage: pinRestrictions,
    searchByEntityIDMessage : searchByEntityID,
    searchPersonalRoomByEntityIDMessage : searchPersonalRoomByEntityID,
    deleteRoomMessage : deleteRoom,
    createScheduledRoomMessage : createScheduledRoom,
    createPublicRoomMessage : createPublicRoom,
    createRoomPinMessage : createRoomPin,
    inviteContentMessage : inviteContent,
}

export default Messages