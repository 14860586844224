export enum LogLevel {
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Log = 5,
  None = 6,
}

type printerName = "debug" | "info" | "warn" | "error" | "log" | "groupCollapsed" | "group";

export interface IConsole {
  debug(...args: any[]): void;
  info(...args: any[]): void;
  warn(...args: any[]): void;
  error(...args: any[]): void;
  log(...args: any[]): void;
  groupCollapsed(...args: any[]): void;
  group(...args: any[]): void;
  groupEnd(): void;
}

export interface ILogger extends IConsole {
  setLevel(logLevel: LogLevel): void;
  getLevel(): LogLevel;
  getName(): string;
  configured: boolean;
}

const loglevelMap: string[] = ["", "DBG", "INF", "WARN", "ERR", "LOG", "NONE"];

export class DummyLogger implements ILogger {
  debug(...args: any[]): void {}
  info(...args: any[]): void {}
  warn(...args: any[]): void {}
  error(...args: any[]): void {}
  log(...args: any[]): void {}
  groupCollapsed(...args: any[]): void {}
  group(...args: any[]): void {}
  groupEnd(): void {}
  setLevel(logLevel: LogLevel): void {}
  getLevel(): LogLevel {
    return LogLevel.None;
  }
  getName(): string {
    return "";
  }
  configured: boolean = false;
}

class FormatEntry {
  logLevel: LogLevel;
  name: string;
  timestamp: Date;

  constructor(loglevel: LogLevel, name: string) {
    this.logLevel = loglevel;
    this.name = name;
    this.timestamp = new Date();
  }
}

interface Config {
  defaultLoglevel: LogLevel;
  formatter: (fe: FormatEntry, withTimestamp: boolean, arg0: string) => string;
  loggers: Map<string, ILogger>;
  defaultLoggerName: string;
  withTimestamp: boolean;
  hook: ((data0: string, data: any[]) => void) | undefined;
}

const zeroPad = (num: number, places: number) => String(num).padStart(places, "0");

const defaultTimestampFormatter = (date: Date): string =>
  `${zeroPad(date.getDate(), 2)}.${zeroPad(date.getMonth() + 1, 2)} ` +
  `${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}:` +
  `${zeroPad(date.getSeconds(), 2)}.${zeroPad(date.getMilliseconds(), 3)} `; // trailing space

const defaultFormatter = (fe: FormatEntry, withTimestamp: boolean, arg0: string): string =>
  `${withTimestamp ? defaultTimestampFormatter(fe.timestamp) : ""}${loglevelMap[fe.logLevel]} |${fe.name}| - ${arg0}`;

const _config: Config = {
  defaultLoglevel: LogLevel.Info,
  formatter: defaultFormatter,
  loggers: new Map<string, ILogger>(),
  defaultLoggerName: "default",
  withTimestamp: true,
  hook: undefined,
};

let _console: IConsole | null;

export interface ILoggerOptions {
  consoleOutput: boolean;
  logStorageOutput: boolean;
}

export const defaultLoggerOptions: ILoggerOptions = {
  consoleOutput: true,
  logStorageOutput: true,
};

class Logger implements ILogger {
  private _logLevel: LogLevel = _config.defaultLoglevel;
  private _name: string = _config.defaultLoggerName;
  private _options: ILoggerOptions = defaultLoggerOptions;

  constructor(name: string = _config.defaultLoggerName, loggerOptions: ILoggerOptions = defaultLoggerOptions) {
    this._name = name;
    this._options = loggerOptions;
  }

  public setLevel(logLevel: LogLevel): void {
    this._logLevel = logLevel;
  }

  public getLevel(): LogLevel {
    return this._logLevel;
  }

  public getName(): string {
    return this._name;
  }

  configured: boolean = false;

  public print(logLevel: LogLevel, printer: printerName, ...args: any[]): void {
    args = args?.filter((value) => value && value !== undefined);
    if (args && args.length > 0 && args[0].toString() !== "" && logLevel >= this._logLevel) {
      // args[0] expanding with logger header is required to keep css working in console
      const args0withPrefix = _config.formatter(new FormatEntry(logLevel, this._name), _config.withTimestamp, args[0]);
      if (_config.hook && this._options.logStorageOutput) {
        _config.hook(args0withPrefix, [...args]);
      }
      if (_console && this._options.consoleOutput) {
        args[0] = args0withPrefix;
        _console[printer](...args);
      }
    }
  }

  public debug(...args: any[]): void {
    this.print(LogLevel.Debug, "debug", ...args);
  }

  public info(...args: any[]): void {
    this.print(LogLevel.Info, "info", ...args);
  }

  public warn(...args: any[]): void {
    this.print(LogLevel.Warn, "warn", ...args);
  }

  public error(...args: any[]): void {
    this.print(LogLevel.Error, "error", ...args);
  }

  public log(...args: any[]): void {
    this.print(LogLevel.Log, "log", ...args);
  }

  public groupCollapsed(...args: any[]): void {
    if (LogLevel.Log >= this._logLevel) {
      if (_console) {
        _console.groupCollapsed(...args);
      }
    }
  }

  public group(...args: any[]): void {
    if (LogLevel.Log >= this._logLevel) {
      if (_console) {
        _console.group(...args);
      }
    }
  }

  public groupEnd(): void {
    if (LogLevel.Log >= this._logLevel) {
      if (_console) {
        _console.groupEnd();
      }
    }
  }
}

export function getLogger_(
  name: string | undefined = undefined,
  loggerOptions: ILoggerOptions = defaultLoggerOptions
): ILogger {
  if (!name) name = _config.defaultLoggerName;
  let logger = _config.loggers.get(name);
  if (!logger) {
    logger = new Logger(name, loggerOptions);
    _config.loggers.set(name, logger);
  }
  return logger;
}

export function getLoggers(): ILogger[] {
  return Array.from(_config.loggers.values());
}

export function setDefaultLevel(logLevel: LogLevel) {
  _config.defaultLoglevel = logLevel;
}

export function setWithTimestamp(withTimestamp: boolean) {
  _config.withTimestamp = withTimestamp;
}

export function setLoggerHook(hook: ((data0: string, data: any[]) => void) | undefined) {
  _config.hook = hook;
}

export function storeOriginalConsole(): IConsole {
  _console = {
    log: console.log,
    error: console.error,
    warn: console.warn,
    info: console.info,
    debug: console.debug,
    groupCollapsed: console.groupCollapsed,
    group: console.group,
    groupEnd: console.groupEnd,
  };
  return _console;
}

export function getConsole(): IConsole | null {
  return _console;
}

export function setConsole(console_: IConsole | null) {
  _console = console_;
}

export function isDebugLogger(logger: ILogger) {
  return LogLevel.Debug >= logger.getLevel();
}
