import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import styled from "themes/theme/baseTheme";
import { presenceActions } from "store/actions/presence";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "components/_shared/MenuItem";
import Avatar from "components/_shared/Avatar";
import { getProperCase } from "utils/helpers";
import { PresenceStates } from "types/Presence";
import styles from "./CurrentUserAvatar.module.scss";

const StyledDiv = styled("div")(({ theme }) => ({
  height: "24px",
  width: "24px",
  borderRadius: "50%",
  border: `1px solid ${theme.palette.common.white}`,
}));

const CurrentUserAvatar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const { changePresence } = presenceActions;

  const { currentPresence } = useAppSelector((state) => state.presence);
  const currentUser = useAppSelector((state) => state.matrix.currentUser);

  const [presenceAnchorEl, setPresenceAnchorEl] = useState<null | HTMLElement>(null);

  const handlePresenceClick = (event: React.MouseEvent<HTMLElement>) => {
    setPresenceAnchorEl(event.currentTarget);
  };

  const handlePresenceClose = () => {
    setPresenceAnchorEl(null);
  };

  const handlePresenceStatusClick = (status: PresenceStates) => {
    const opts = {
      transitionName: "local-status-change",
      status,
    };
    dispatch(changePresence(opts));
    handlePresenceClose();
  };

  return (
    <>
      <Button
        id="current_user_avatar_button"
        aria-label="actions"
        aria-haspopup="true"
        aria-controls="room-menu"
        color="inherit"
        sx={{ borderRadius: "50%", padding: 0, width: "40px", minWidth: "40px", height: "40px", minHeight: "40px" }}
        onClick={handlePresenceClick}
      >
        <Avatar
          status={currentPresence}
          name={currentUser ? getProperCase(currentUser.displayName) : ""}
          src={currentUser ? currentUser.avatarUrl : ""}
          size="40"
        />
      </Button>
      <Menu
        anchorEl={presenceAnchorEl}
        keepMounted
        open={Boolean(presenceAnchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={handlePresenceClose}
        sx={{ minWidth: "217px" }}
      >
        <div>
          <MenuItem
            id="presence-menu-item-available"
            selected={currentPresence === PresenceStates.available}
            onClick={() => handlePresenceStatusClick(PresenceStates.available)}
          >
            <StyledDiv
              style={{
                backgroundColor: theme.palette.sentiment.success.base,
              }}
            ></StyledDiv>
            <span className={`${styles.status}`}>{t("AVAILABLE")}</span>
          </MenuItem>
          <MenuItem
            id="presence-menu-item-busy"
            selected={currentPresence === PresenceStates.busy}
            onClick={() => handlePresenceStatusClick(PresenceStates.busy)}
          >
            <StyledDiv
              style={{
                backgroundColor: theme.palette.sentiment.error.base,
              }}
            ></StyledDiv>
            <span className={`${styles.status}`}>{t("BUSY")}</span>
          </MenuItem>
          <MenuItem
            id="presence-menu-item-away"
            selected={currentPresence === PresenceStates.away}
            onClick={() => handlePresenceStatusClick(PresenceStates.away)}
          >
            <StyledDiv
              style={{
                backgroundColor: theme.palette.sentiment.warning.base,
              }}
            ></StyledDiv>
            <span className={`${styles.status}`}>{t("AWAY")}</span>
          </MenuItem>
          <MenuItem
            id="presence-menu-item-dnd"
            selected={currentPresence === PresenceStates.dnd}
            onClick={() => handlePresenceStatusClick(PresenceStates.dnd)}
          >
            <StyledDiv
              style={{
                backgroundColor: theme.palette.sentiment.error.dark1,
              }}
            ></StyledDiv>
            <span className={`${styles.status}`}>{t("DND")}</span>
          </MenuItem>
          <MenuItem
            id="presence-menu-item-offline"
            selected={currentPresence === PresenceStates.offline}
            onClick={() => handlePresenceStatusClick(PresenceStates.offline)}
          >
            <StyledDiv
              style={{
                backgroundColor: theme.palette.neutrals.lightGrey5,
              }}
            ></StyledDiv>
            <span className={`${styles.status}`}>{t("APPEAR_OFFLINE")}</span>
          </MenuItem>
        </div>
      </Menu>
    </>
  );
};

export default CurrentUserAvatar;
