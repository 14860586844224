import * as actionTypes from "../actions/types/presence";

const initialState = {
  presenceConfig: null,
  presenceIntialized: false,
  presenceError: false,
  currentPresence: "offline",
  currentPresenceAvatarUrl: "",
  updatePresenceAvatarError: null,
  onPresenceChange: null,
  onOtherPresenceChange: null,
};

const presenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_PRESENCE_SERVICE:
      return {
        ...state,
        presenceConfig: action.config,
      };
    case actionTypes.START_PRESENCE_SERVICE_SUCCEEDED:
      return {
        ...state,
        presenceIntialized: true,
        currentPresenceAvatarUrl: action.currentPresenceAvatarUrl,
      };
    case actionTypes.START_PRESENCE_SERVICE_FAILED:
      return {
        ...state,
        presenceIntialized: false,
        presenceError: action.message,
      };
    case actionTypes.STOP_PRESENCE_SERVICE_SUCCEEDED:
      return {
        ...state,
        presenceIntialized: false,
      };
    case actionTypes.STOP_PRESENCE_SERVICE_FAILED:
      return {
        ...state,
        presenceIntialized: false,
        presenceError: action.message,
      };
    case actionTypes.ON_PRESENCE_CHANGE:
      return {
        ...state,
        onPresenceChange: action.status,
        currentPresence: action.status.to,
      };
    case actionTypes.ON_OTHER_PRESENCE_CHANGE:
      return {
        ...state,
        onOtherPresenceChange: action.status,
      };
    case actionTypes.UPDATE_PRESENCE_AVATAR_SUCCEEDED:
      return {
        ...state,
        currentPresenceAvatarUrl: action.avatarUrl,
      };
    case actionTypes.UPDATE_PRESENCE_AVATAR_FAILED:
      return {
        ...state,
        updatePresenceAvatarError: action.message,
      };
    default:
      return state;
  }
};

export default presenceReducer;
