import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { Variant } from "@mui/material/styles/createTypography";

interface CustomSwitchProps extends SwitchProps {
  label: string;
  style?: React.CSSProperties;
  variant?: Variant;
}

const CustomSwitch = ({ ...props }: CustomSwitchProps) => {
  const { label, style, variant, ...other } = props;
  return (
    <FormControlLabel
      control={<Switch sx={{ marginLeft: "-8px" }} {...other} />}
      label={
        label ? (
          <Typography variant={variant ?? "body2"} sx={{ color: "inherit", minWidth: "70px" }}>
            {label}
          </Typography>
        ) : (
          ""
        )
      }
      sx={{ margin: 0, ...style }}
    />
  );
};

export default CustomSwitch;
