import { put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/types/pbx";
import { Types } from "Types";
import { getApiResponseErrorMessage } from "utils/helpers";
import { PBXServerApiProvider } from "services/PBXServerApi/Providers/PBXServerApiProvider";
import { PBXServerApiClient } from "services/PBXServerApi/PBXServerApiClient";
import { GetPbxUsersRequest, GetPbxUsersResponse, PbxUserDetails } from "services/PBXServerApi/types/PbxUsers";
import { PbxCallInfo, PbxEventListenerType, PbxEventSubscription } from "services/PBXServerApi/types/EventListener";
import { PBXEventListenerProvider } from "services/PBXServerApi/Providers/PBXEventListenerProvider";
import { getLogger } from "logger/appLogger";

const eventListenerLogger = getLogger("pbx.event-listener");

function* searchPbxUsers(action: Types.RootAction) {
  try {
    const response: GetPbxUsersResponse = yield PBXServerApiProvider.Instance.getPbxUsers(
      action.payload as GetPbxUsersRequest
    );
    yield put({
      type: actionTypes.GET_PBX_USERS_SUCCEEDED,
      pbxUserSearchResults: response.results,
    });
  } catch (e) {
    yield put({
      type: actionTypes.GET_PBX_USERS_FAILED,
      message: getApiResponseErrorMessage(e),
    });
  }
}

export function* searchPbxUserDetails(action: Types.RootAction) {
  try {
    const username = action;
    const response: PbxUserDetails = yield PBXServerApiProvider.Instance.getPbxUserDetails(username);
    yield put({
      type: actionTypes.GET_PBX_USER_DETAILS_SUCCEEDED,
      pbxUserDetails: response,
    });
    return response;
  } catch (e) {
    yield put({
      type: actionTypes.GET_PBX_USER_DETAILS_FAILED,
      message: getApiResponseErrorMessage(e),
    });
  }
}

export function* subscribeToPbxEvents(type: PbxEventListenerType, callId: string) {
  const msg = "subscribeToPbxEvents/saga";
  eventListenerLogger.debug(`${msg} type=${type} callId=${callId}`);
  try {
    const subscription: PbxEventSubscription = yield PBXServerApiClient.Instance.postSubscription(type, callId);
    yield PBXEventListenerProvider.Instance.createSubscription(subscription, type);
    return subscription.subscriptionId;
  } catch (e) {
    eventListenerLogger.error(`${msg} error: ${e}`);
  }
}

export function* unSubscribeFromPbxEvents(subscriptionId: string) {
  const msg = "unSubscribeFromPbxEvents/saga";
  eventListenerLogger.debug(`${msg} subscriptionId=${subscriptionId}`);
  try {
    yield PBXEventListenerProvider.Instance.removeSubscription(subscriptionId, true);
  } catch (e) {
    eventListenerLogger.error(`${msg} error: ${e}`);
  } finally {
    eventListenerLogger.debug(`${msg} done subscriptionId=${subscriptionId}`);
  }
}

export function* isGroupCalStateConnected(callId: string) /* : { connected: boolean; agent: string | undefined } */ {
  let connected = false;
  let agent = undefined;
  const msg = "isGroupCalStateConnected";
  eventListenerLogger.debug(`${msg} callId=${callId}`);
  try {
    const pbxCallInfo: PbxCallInfo = yield PBXServerApiClient.Instance.getCallInfo(callId);
    eventListenerLogger.debug(`${msg} ${JSON.stringify(pbxCallInfo)}`);
    if (
      pbxCallInfo.type === "agent-connect" &&
      pbxCallInfo.origCallInfo === "active" &&
      pbxCallInfo.termCallInfo === "active"
    ) {
      connected = true;
      agent = pbxCallInfo.agent;
    }
  } catch (e) {
    eventListenerLogger.error(`${msg} error: ${e}`);
  }
  const ret = { connected, agent };
  eventListenerLogger.debug(`${msg} done ret=${JSON.stringify(ret)}`);
  return ret;
}

function* actionWatcher() {
  yield takeLatest(actionTypes.GET_PBX_USERS, searchPbxUsers);
  yield takeLatest(actionTypes.GET_PBX_USER_DETAILS, searchPbxUserDetails);
}

export default actionWatcher;
