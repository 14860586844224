import { PaletteMode, ThemeOptions } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { createStyled } from "@mui/system";
import { CustomPalette } from "themes/theme/types";
import components from "./components";
import typography from "./typography";
import enghousePalette from "themes/brand/enghouse/enghousePalette";

export const baseTheme: ThemeOptions = {
  typography,
  components: {
    ...components,
  },
};

export const customPalette: { [key in string]: CustomPalette } = {
  enghouse: enghousePalette,
};

export const getTheme = (theme: string, mode: PaletteMode): ThemeOptions => {
  return { ...baseTheme, palette: customPalette[theme][mode] };
};

export const defaultTheme: ThemeOptions = getTheme("enghouse", "light");

export const lightTheme: ThemeOptions = getTheme("enghouse", "light");

export const darkTheme = createTheme(getTheme("enghouse", "dark"));

export const createCustomTheme = (theme: string, mode: PaletteMode) => createTheme(getTheme(theme, mode));

const styled = createStyled({ defaultTheme: createTheme(defaultTheme) });

export default styled;
