import React, { useEffect, useState, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { getLogger } from "logger/appLogger";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSearchPbxUsers, useGetPbxCallQueues, useMatrixRooms } from "hooks";
import { pbxActions } from "store/actions/pbx";
import { useAppSelector } from "store/hooks";
import { getSipCallActive } from "store/selectors";
import { DialoutCallTransferActions } from "./DialoutCallTransferActions";
import { DialoutCallTransferChat } from "./DialoutCallTransferChat";

import { getStatusMessage } from "services/PresenceProvider/PresenceProvider";
import { PbxUserData } from "services/PBXServerApi/types/PbxUsers";
import { PbxCallQueue } from "services/PBXServerApi/types/PBXUserConfig";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DialpadIcon from "@mui/icons-material/Dialpad";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";

import SearchField from "components/_shared/SearchField";
import Avatar from "components/_shared/Avatar";
import Dialpad from "containers/Dialpad/Dialpad";
import Typography from "@mui/material/Typography";

import { localStorageKeys } from "utils/constants";
import { CallActionType } from "types/UC";
import { createSipDisplayName, test } from "utils/helpers";
import "./DialoutCallTransfer.scss";
import {
  StyledCallTransferAccordion,
  StyledCallTransferAccordionDetails,
  StyledCallTransferAccordionSummary,
  StyledCallTransferDialog,
  StyledCallTransferOptionsIconButton,
} from "./DialoutCallTransferStyled";
import Stack from "@mui/material/Stack";

interface DialoutCallTransferOwnProps {
  close: () => void;
}

enum DialogTab {
  Contacts,
  DialQueues,
  Dialpad,
}

const DialoutCallTransfer = ({ close }: DialoutCallTransferOwnProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const logger = useMemo(() => getLogger("sip.transfer"), []);

  const [searchValue, setSearchValue] = useState<string>("");
  const [suggestedUsers, setSuggestedUsers] = useState<PbxUserData[]>([]);
  const [suggestedCallQueues, setSuggestedCallQueues] = useState<PbxCallQueue[]>([]);

  const [dialogTab, setDialogTab] = useState<DialogTab>(DialogTab.Contacts);
  const [openDialog, setOpenDialog] = useState(true);
  const [dataToChat, setDataToChat] = useState<{ user: PbxUserData; roomId: string } | null>(null);
  const [openChatDialog, setOpenChatDialog] = useState(false);

  const { search: searchContacts, response: responseContacts, loading: loadingContacts } = useSearchPbxUsers();
  const { search: searchCallQueues, response: responseCallQueues, loading: loadingCallQueues } = useGetPbxCallQueues();

  const { getOrCreateDirectRoom } = useMatrixRooms();

  const [lastExpanded, setLastExpanded] = useState<string>("");
  const [dialpadNumber, setDialpadNumber] = useState<string>("");

  const sipCallActive = useAppSelector((state) => getSipCallActive(state, true));

  if (!sipCallActive) {
    throw new Error("No call to transfer");
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
    close();
  };

  const handleCloseCallChat = () => {
    setDataToChat(null);
    handleDialogClose();
  };

  const handleBackCallChat = () => {
    setDataToChat(null);
    setOpenChatDialog(false);
  };

  const startChat = async (user: PbxUserData) => {
    const msg = "startChat";
    try {
      logger.debug(`${msg} user=${user}`);
      const { username, firstName, lastName } = user;
      const roomName = `${firstName} ${lastName}`;
      const data = await getOrCreateDirectRoom(username, roomName, true);

      if (data && data.roomId) {
        setDataToChat({ user: user, roomId: data.roomId });
        setOpenChatDialog(true);
      }
    } catch (err) {
      logger.error(`${msg}: ${err}`);
    }
  };

  const getDialpadPbxUserData = (): PbxUserData => {
    return {
      username: dialpadNumber,
      firstName: dialpadNumber,
      lastName: "",
      extension: dialpadNumber,
      did: "",
      isGroup: false,
      presence: {
        status: "",
        avatarUrl: "",
        timestamp: "",
      },
    };
  };

  useEffect(() => {
    const maxResults = !searchValue || searchValue.length === 0 ? 30 : 100;
    switch (dialogTab) {
      case DialogTab.Contacts:
        searchContacts(searchValue, maxResults);
        // reset other search results
        if (setSuggestedCallQueues.length > 0) {
          setSuggestedCallQueues([]);
        }
        break;
      case DialogTab.DialQueues:
        searchCallQueues(searchValue);
        // reset other search results
        if (suggestedUsers.length > 0) {
          setSuggestedUsers([]);
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, dialogTab /* kickstart new search on tab change */]);

  useEffect(() => {
    logger.debug(`responseContacts: ${responseContacts?.length}`);

    if (dialogTab === DialogTab.Contacts && responseContacts && responseContacts.length > 0) {
      const { user_id, home_server } = JSON.parse(localStorage.getItem(localStorageKeys.CURRENT_USER) || "");

      // filter out ourselves
      const sugUsers = responseContacts.filter(
        (user: PbxUserData) =>
          `@${user.username}:${home_server}` !== user_id &&
          (user.extension ? user.extension !== sipCallActive.peer.user : false)
      );

      setSuggestedUsers(sugUsers);
    } else {
      setSuggestedUsers([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseContacts]);

  useEffect(() => {
    logger.debug(`responseCallQueues: ${responseCallQueues?.length}`);

    if (dialogTab === DialogTab.DialQueues && responseCallQueues && responseCallQueues.length > 0) {
      setSuggestedCallQueues(responseCallQueues);
    } else {
      setSuggestedCallQueues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseCallQueues]);

  useEffect(() => {
    setSearchValue("");
    return () => {
      dispatch(pbxActions.resetPbxUserSearch());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = () => {};

  const transferActionButtonsStyle = (isActive: boolean) => {
    const style = {
      color: isActive ? theme.palette.icon.navLink.selected : theme.palette.icon.navLink.notSelected,
      backgroundColor: isActive ? theme.palette.background.navLink.selected : theme.palette.background.expanded,
      border: isActive ? `1px solid ${theme.palette.border.navLink}` : `1px solid transparent`,
    };
    return style;
  };

  const createPBXUserInfo = (callQueue: PbxCallQueue): PbxUserData => {
    return {
      username: callQueue.queue_name,
      firstName: callQueue.description,
      lastName: "",
      did: "",
      extension: callQueue.queue_name,
      isGroup: true,
      presence: {
        status: "",
        avatarUrl: "",
        timestamp: "",
      },
      statusMsg: `${callQueue.agent_count} ${callQueue.agent_count === 1 ? t("MEMBER") : t("MEMBERS")}`,
    };
  };

  const getAccordion = (user: PbxUserData) => {
    const { username, firstName, lastName, presence, extension } = user;
    const { status, avatarUrl } = presence;
    const displayName = createSipDisplayName(firstName, lastName);
    const statusMessage = user.statusMsg || t(getStatusMessage(status).toUpperCase());

    return (
      <div key={username} style={{ display: "flex", margin: "5px 0" }}>
        <StyledCallTransferAccordion
          onChange={(e, expanded) => {
            setLastExpanded(expanded ? username : "");
          }}
          expanded={lastExpanded === username}
        >
          <StyledCallTransferAccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  height: "32px",
                  width: "32px",
                  backgroundColor: "inherit",
                  color: theme.palette.icon.main,
                  borderRadius: "50%",
                }}
              />
            }
          >
            <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
              <Avatar
                status={status}
                size="44.74"
                name={displayName}
                disableBadge
                src={avatarUrl || ""}
                isPerson={!user.isGroup}
              />
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "1rem" }}>
                <Typography variant="body1">
                  {displayName}
                  {extension ? ` (${extension})` : ""}
                </Typography>
                <Typography variant="body2">{statusMessage}</Typography>
              </div>
            </div>
          </StyledCallTransferAccordionSummary>
          <StyledCallTransferAccordionDetails>
            <div className="transferActionsContainer">
              <DialoutCallTransferActions
                user={user}
                withText={true}
                size={32}
                startChat={user.isGroup ? undefined : startChat}
                consultDoneCallback={handleDialogClose}
              />
            </div>
          </StyledCallTransferAccordionDetails>
        </StyledCallTransferAccordion>
      </div>
    );
  };

  return (
    <>
      <StyledCallTransferDialog
        id={"call-transfer"}
        dialogTitle={t("TRANSFER_CALL")}
        showIconClose={true}
        onClose={handleDialogClose}
        open={openDialog}
        {...test("TRANSFER_CALL_DIALOG")}
      >
        <Stack
          id="transfer-options"
          direction="row"
          spacing={"20px"}
          justifyContent={"space-around"}
          sx={{
            margin: "0 4px 20px",
            padding: "4px",
            backgroundColor: theme.palette.background.expanded,
            borderRadius: "4px",
          }}
        >
          <div
            className="transferOptionsOuterSelectionContainer"
            style={transferActionButtonsStyle(dialogTab === DialogTab.Contacts)}
          >
            <div className="transferOptionsSelectionContainer" onClick={() => setDialogTab(DialogTab.Contacts)}>
              <StyledCallTransferOptionsIconButton size="small">
                <AccountCircleIcon sx={{ height: "20px", width: "20px" }} />
              </StyledCallTransferOptionsIconButton>
              <Typography variant="body2" sx={{ color: "inherit" }}>
                {t("CONTACTS")}
              </Typography>
            </div>
          </div>

          <div
            className="transferOptionsOuterSelectionContainer"
            style={transferActionButtonsStyle(dialogTab === DialogTab.DialQueues)}
          >
            <div className="transferOptionsSelectionContainer" onClick={() => setDialogTab(DialogTab.DialQueues)}>
              <StyledCallTransferOptionsIconButton size="small">
                <GroupsRoundedIcon />
              </StyledCallTransferOptionsIconButton>
              <Typography variant="body2" sx={{ color: "inherit" }}>
                {t("HUNT_GROUPS")}
              </Typography>
            </div>
          </div>

          <div
            className="transferOptionsOuterSelectionContainer"
            style={transferActionButtonsStyle(dialogTab === DialogTab.Dialpad)}
          >
            <div className="transferOptionsSelectionContainer" onClick={() => setDialogTab(DialogTab.Dialpad)}>
              <StyledCallTransferOptionsIconButton size="small">
                <DialpadIcon style={{ height: "20px", width: "20px" }} />
              </StyledCallTransferOptionsIconButton>
              <Typography variant="body2" sx={{ color: "inherit" }}>
                {t("DIALPAD")}
              </Typography>
            </div>
          </div>
        </Stack>

        {(dialogTab === DialogTab.Contacts || dialogTab === DialogTab.DialQueues) && (
          <div style={{ display: "flex", margin: "0 20px" }}>
            <div
              style={{
                width: "100%",
              }}
            >
              <SearchField
                autoFocus
                onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  const value: string = event.target.value;
                  setSearchValue(value);
                }}
                size="small"
                sx={{ height: "36px" }}
              />
            </div>
          </div>
        )}

        {dialogTab === DialogTab.Contacts && !loadingContacts && (
          <div className="contactsContainer">
            <div className={`contactsList scroll-bar`} onScroll={handleScroll}>
              {suggestedUsers.map((user) => getAccordion(user))}
            </div>
          </div>
        )}
        {dialogTab === DialogTab.DialQueues &&
          !loadingCallQueues &&
          suggestedCallQueues &&
          suggestedCallQueues.length > 0 && (
            <div className="contactsContainer">
              <div className={`contactsList scroll-bar`} onScroll={handleScroll}>
                {suggestedCallQueues
                  ?.map((callQueue) => createPBXUserInfo(callQueue))
                  .map((user) => getAccordion(user))}
              </div>
            </div>
          )}
        {dialogTab === DialogTab.Dialpad && (
          <div className="dialpad">
            <Dialpad callType={CallActionType.Transfer} hideCallButton={true} onChange={setDialpadNumber} />
            <div className="transferActionsContainer">
              <DialoutCallTransferActions
                user={getDialpadPbxUserData()}
                withText={true}
                size={32}
                consultDoneCallback={handleDialogClose}
              />
            </div>
            <div style={{ marginTop: "42px " }}>
              <Typography
                variant="body1"
                sx={{ padding: "0 16px", textAlign: "center", color: theme.palette.text.secondary }}
              >
                {sipCallActive.peer.user ? `${t("FROM")} ${sipCallActive.peer.user}` : ""}
              </Typography>
            </div>
          </div>
        )}
      </StyledCallTransferDialog>
      {dataToChat && (
        <DialoutCallTransferChat
          data={dataToChat!}
          openDialog={openChatDialog}
          handleDialogClose={handleCloseCallChat}
          handleDialogBack={handleBackCallChat}
        />
      )}
    </>
  );
};

export default DialoutCallTransfer;
