import { useState, useEffect, useMemo } from "react";
import useRouter from "./useRouter";
import { useMatrixRooms, useMatrixAvatar } from "hooks";
import mxClient from "matrix/matrix";
import { useDispatch } from "react-redux";
import { changeNotificationCount } from "store/actions/app";
import isElectron from "is-electron";
import { MatrixUserProvider } from "services/MatrixAPIProvider";
import { getHttpUriForMxc } from "matrix-js-sdk";
import { LocalEventType, LocalEventTypeEnum, MsgType } from "types/Matrix";
import { convertHTMLtoText } from "utils/helpers";
import { getLogger } from "logger/appLogger";
import { useTranslation } from "react-i18next";
import { JsonStringify, getRoomFromRoomList } from "utils/utils";
import { matrixActions } from "store/actions/matrix";
import { useAppSelector } from "store/hooks";
import { UiSipCallInfo } from "types/SIP";
import { localStorageKeys } from "utils/constants";

const Favico = require("favico.js");

const favIconJS = new Favico({
  animation: "none",
});

interface ShowTrayNotificationArgs {
  matrix?: {
    roomId: string | undefined;
    userId: string | undefined;
    content: any;
    eventType: LocalEventType;
  };
  sipCallInfo?: UiSipCallInfo;
}

interface useNotificationType {
  showTrayNotification: (a: ShowTrayNotificationArgs) => Promise<void>;
  setNotificationCount: (count: number) => void;
  handleBadgeCount: () => void;
  notificationCount: number;
  toggleMuteRoomNotification: (roomId: string, willMute: boolean) => Promise<void>;
  deleteRoomLowPrio: (roomId: string) => Promise<boolean | undefined>;
}

const useNotification = (): useNotificationType => {
  const logger = useMemo(() => getLogger("notification"), []);
  const { t } = useTranslation();
  const client = mxClient.client;
  const { push } = useRouter();
  const { deleteRoomLowPrio } = useMatrixRooms();
  const { getRoomMemberAvatar } = useMatrixAvatar();
  const [notificationCount, setNotificationCount] = useState(0);
  const { setAccountData, getUser } = MatrixUserProvider();
  const { permission, requestPermission } = Notification;
  const dispatch = useDispatch();

  const matrixChatVisible = useAppSelector((state) => state.matrix.chatVisible);
  const localRoomList = useAppSelector((state) => state.matrix.roomList);
  const currentRoomId = useAppSelector((state) => state.matrix.roomId);

  const handleBadgeCount = () => {
    try {
      const rooms = client.getRooms();
      let count = 0;
      rooms.forEach((room) => {
        if (
          room &&
          //@ts-ignore
          !room.tags["m.lowpriority"]
        ) {
          const unreadNotificationCount = room.getUnreadNotificationCount();
          let localRoom = null;

          if (localRoomList) {
            localRoom = getRoomFromRoomList(localRoomList, room.roomId);
            if (localRoom) {
              if (localRoom.unreadNotificationCount !== unreadNotificationCount) {
                dispatch(matrixActions.updateUnreadNotificationCount(room.roomId, unreadNotificationCount));
              }
            }
          }

          if (
            unreadNotificationCount === 0 ||
            (localRoom && localRoom.isHidden) ||
            (room.roomId === currentRoomId && matrixChatVisible)
          ) {
            return;
          }

          count = count + 1;
        }
      });
      if (notificationCount === count) return;
      setNotificationCount(count);
    } catch (e) {
      logger.error(`Error in useNotification.handleBadgeCount - roomId:${currentRoomId} - ${e.message}`);
    }
  };

  const toggleMuteRoomNotification = async (roomId: string, willMute: boolean) => {
    try {
      let mNotificationData = client.getAccountData("m.room_notification");
      let content: { [id: string]: any[] } = {};
      let user = JSON.parse(localStorage.getItem(localStorageKeys.CURRENT_USER)!);

      if (willMute) {
        if (!mNotificationData) {
          logger.debug("no account data");
          content = {
            [user?.user_id]: [roomId],
          };
        } else {
          logger.debug("has account data");
          //@ts-ignore
          content = mNotificationData.getContent();

          const isInArray =
            content[user.user_id].find((el) => {
              return el === roomId;
            }) !== undefined;
          if (!isInArray) {
            content[user?.user_id].push(roomId);
          }
        }
        await setAccountData("m.room_notification", content);
      } else {
        logger.debug("unmuting room");
        if (!mNotificationData) return;

        //@ts-ignore
        content = mNotificationData.getContent();

        const isInArray =
          content[user.user_id].find((el) => {
            return el === roomId;
          }) !== undefined;

        if (isInArray) {
          content = {
            [user.user_id]: content[user.user_id].filter((room_id) => room_id !== roomId),
          };
        }

        await setAccountData("m.room_notification", content);
      }
    } catch (e) {
      logger.error(
        `Error in useNotification.toggleMuteRoomNotification - roomId:${roomId}, willMute:${willMute} - ${e.message}`
      );
    }
  };

  useEffect(() => {
    if (notificationCount <= 0) {
      favIconJS.reset();
    } else {
      favIconJS.badge(notificationCount);
    }
    dispatch(changeNotificationCount(notificationCount));
    if (isElectron()) {
      window.ipcRenderer.send("badgeCount", notificationCount);
      window.ipcRenderer.send("badgeCountIcon", createBadgeIcon(notificationCount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationCount]);

  const createBadgeIcon = (count: number) => {
    try {
      if (!count || count <= 0) return null;
      const canvas = document.createElement("canvas");
      const size = 16;
      canvas.height = size;
      canvas.width = size;
      const ctx = canvas.getContext("2d");

      // Draw Notification Circle
      if (ctx !== null) {
        ctx.beginPath();
        ctx.arc(8, 8, 7, 0, 2 * Math.PI);
        ctx.fillStyle = "#FF0000";
        ctx.fill();

        // Draw Notification Number
        ctx.font = '10px "helvetica", sans-serif';
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#FFFFFF";

        if (count >= 9) {
          ctx.fillText("9+", 7, 8);
        } else {
          ctx.fillText(count.toString(), 8, 8);
        }
      }
      return canvas.toDataURL("image/png");
    } catch (e) {
      logger.error(`Error in useNotification.createBadgeIcon - count:${count} - ${e.message}`);
    }
    return null;
  };

  const showTrayNotification = async (a: ShowTrayNotificationArgs) => {
    logger.debug(
      `showTrayNotification: roomId=${a.matrix?.roomId} matrix/user=${a?.matrix?.userId} sip=${
        a.sipCallInfo ? JsonStringify(a.sipCallInfo) : ""
      } permission=${permission}`
    );

    if (!("Notification" in window)) {
      logger.log("This browser does not support desktop notification");
      return;
    }

    if (permission === "denied") {
      logger.debug("Permission is denied");
      return;
    }
    if (permission === "default") {
      const res = await requestPermission();
      if (res !== "granted") {
        logger.debug("Permission is not granted");
        return;
      }
    }

    if (a.matrix && a.matrix.roomId) {
      if (isRoomMuted(a.matrix.roomId)) {
        logger.debug("Room is muted");
        return;
      }
      await deleteRoomLowPrio(a.matrix.roomId);
    }

    let user = null;
    if (a.matrix && a.matrix.userId) {
      user = getUser(a.matrix.userId);
    }

    let title = "";
    if (a.matrix && user) {
      title = user.displayName || "";
    } else if (a.sipCallInfo) {
      title = `${a.sipCallInfo.peer.name}\n${a.sipCallInfo.peer.user}`;
    }

    let notification: Notification | null = new Notification(`${title}`, getOptions(a));

    notification.onclick = (...args) => {
      logger.debug(`notification onclick: ${JsonStringify(a)}`);
      if (a.matrix) {
        logger.debug(`navigate to ${a.matrix.roomId}`);
        push(`/room/${a.matrix.roomId}`);
      }
      window.focus();
    };
    notification.onclose = (...args) => {
      notification = null;
    };
    notification.onerror = (err) => {
      logger.error(`Notification failed: ${err}`);
    };
  };

  const getOptions = (a: ShowTrayNotificationArgs) => {
    let options: NotificationOptions = {
      image: "",
      body: "",
    };

    if (a.matrix) {
      let avatar = a.matrix.userId && a.matrix.roomId ? getRoomMemberAvatar(a.matrix.roomId, a.matrix.userId) : "";
      options.icon = avatar ?? "";

      const { msgtype } = a.matrix.content;
      const contentUrl =
        a.matrix.content.url && a.matrix.content.url.content_uri
          ? a.matrix.content.url.content_uri
          : a.matrix.content.url;

      switch (msgtype) {
        case MsgType.Emote:
        case MsgType.Notice:
        case MsgType.Text:
          options.body = convertHTMLtoText(a.matrix.content.body);
          break;
        case MsgType.Image:
          options.body = `${t("USER_SENT_AN_IMAGE")}: ${a.matrix.content.body}`;
          //@ts-ignore
          options.image = getHttpUriForMxc(client.baseUrl, contentUrl);
          break;
        case MsgType.File:
          options.body = `${t("USER_SENT_A_FILE")}: ${a.matrix.content.body}`;
          break;
        case MsgType.Audio:
          options.body = `${t("USER_SENT_AN_AUDIO")}: ${a.matrix.content.body}`;
          break;
        case MsgType.Video:
          options.body = `${t("USER_SENT_A_VIDEO")}: ${a.matrix.content.body}`;
          break;
        default:
          if (a.matrix.eventType === LocalEventTypeEnum.CallInvite) {
            options.body = t("INCOMING_CALL");
          } else {
            options.body = t("USER_SENT_A_MESSAGE");
          }
          break;
      }
    }

    if (a.sipCallInfo) {
      options.body = t("INCOMING_CALL_TYPE", { type: "pbx" });
    }

    return options;
  };

  const isRoomMuted = (roomId: string) => {
    try {
      const mNotificationData = client?.getAccountData("m.room_notification");
      if (!mNotificationData) return false;
      //@ts-ignore
      const mNotificationDataContent = mNotificationData.getContent();
      const isInArray =
        mNotificationDataContent[client.credentials.userId!].find((el: string) => {
          return el === roomId;
        }) !== undefined;
      if (isInArray) return true;
    } catch (error) {
      logger.error(`Error in isRoomMuted - roomId:${roomId} - ${error.message}`);
      return false;
    }
    return false;
  };

  return {
    showTrayNotification,
    setNotificationCount,
    handleBadgeCount,
    notificationCount,
    toggleMuteRoomNotification,
    deleteRoomLowPrio,
  };
};

export default useNotification;
