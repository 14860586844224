import { Components, Theme } from "@mui/material";

const MuiDrawer: Components["MuiDrawer"] = {
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      const palette = (theme as Theme).palette;
      return {
        "& .MuiPaper-root": {
          backgroundColor: palette.background.default,
          backgroundImage: "none",
          //borderRight: "none",
        },
      };
    },
  },
};

export default MuiDrawer;
