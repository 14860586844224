import * as actionTypes from "../actions/types/call";

export const callActions = {
  startCall: (payload) => ({
    type: actionTypes.START_CALL,
    payload,
  }),
  setCallRoomId: (payload) => ({
    type: actionTypes.SET_CALL_ROOM_ID,
    payload,
  }),
  outCall: (payload) => ({
    type: actionTypes.OUT_CALL,
    payload,
  }),
  getCallProperties: (payload) => ({
    type: actionTypes.GET_CALL_PROPERTIES,
    payload,
  }),
  endCall: () => ({
    type: actionTypes.END_CALL,
  }),
  rejoinCall: () => ({
    type: actionTypes.REJOIN_CALL,
  }),
  subscribeOnParticipantsChanges: () => ({
    type: actionTypes.PARTICIPANTS_CHANGES_SUBSCRIBE,
  }),
  unsubscribeFromParticipantsChanges: () => ({
    type: actionTypes.PARTICIPANTS_CHANGES_UNSUBSCRIBE,
  }),
  subscribeOnRecorderStatusChanges: () => ({
    type: actionTypes.RECORDER_STATUS_CHANGES_SUBSCRIBE,
  }),
  unsubscribeFromRecorderStatusChanges: () => ({
    type: actionTypes.RECORDER_STATUS_CHANGES_UNSUBSCRIBE,
  }),
  subscribeOnModerationStatusChanges: () => ({
    type: actionTypes.MODERATION_STATUS_CHANGES_SUBSCRIBE,
  }),
  unsubscribeFromModerationStatusChanges: () => ({
    type: actionTypes.MODERATION_STATUS_CHANGES_UNSUBSCRIBE,
  }),
  updateRecorderStatus: (recorderStatus, paused, webCasting) => ({
    type: actionTypes.UPDATE_RECORDER_STATUS,
    recorderStatus,
    paused,
    webCasting,
  }),
  updateModerationStatus: (moderationStatus) => ({
    type: actionTypes.UPDATE_MODERATION_STATUS,
    moderationStatus,
  }),
  updateModerationAction: (moderationAction) => ({
    type: actionTypes.UPDATE_MODERATION_ACTION,
    moderationAction,
  }),
  updateParticipants: (participants) => ({
    type: actionTypes.UPDATE_PARTICIPANTS,
    participants,
  }),
  subscribeOnLocalWindowShareChanges: () => ({
    type: actionTypes.LOCAL_WINDOW_SHARE_CHANGES_SUBSCRIBE,
  }),
  unsubscribeFromLocalWindowShareChanges: () => ({
    type: actionTypes.LOCAL_WINDOW_SHARE_CHANGES_UNSUBSCRIBE,
  }),
  subscribeOnRemoteWindowShareChanges: () => ({
    type: actionTypes.REMOTE_WINDOW_SHARE_CHANGES_SUBSCRIBE,
  }),
  unsubscribeFromRemoteWindowShareChanges: () => ({
    type: actionTypes.REMOTE_WINDOW_SHARE_CHANGES_UNSUBSCRIBE,
  }),
  updateLocalWindowShares: (localWindowShares) => ({
    type: actionTypes.LOCAL_WINDOW_SHARES_UPDATE,
    localWindowShares,
  }),
  updateRemoteWindowShares: (remoteWindowShares) => ({
    type: actionTypes.REMOTE_WINDOW_SHARES_UPDATE,
    remoteWindowShares,
  }),
  startWindowShare: (localWindowShare) => ({
    type: actionTypes.WINDOW_SHARE_START,
    localWindowShare,
  }),
  stopWindowShare: () => ({
    type: actionTypes.WINDOW_SHARE_STOP,
  }),
  assignVideoRenderer: ({ viewId, showAudioMeters, showPreview }) => ({
    type: actionTypes.ASSIGN_VIDEO_RENDERER,
    payload: {
      showAudioMeters,
      showPreview,
      viewId,
    },
  }),
  meetingName: (payload) => ({
    type: actionTypes.MEETING_NAME,
    payload,
  }),
  setCallInFullScreen: (payload) => ({
    type: actionTypes.CALL_IN_FULL_SCREEN,
    payload,
  }),
  updateParticipantInfo: (participant) => ({
    type: actionTypes.UPDATE_PARTICIPANT_INFO,
    participant,
  }),
};
