import { SnackbarProvider as NotistackSnackbarProvider } from "notistack";
import styled from "themes/theme/baseTheme";
import { useTheme } from "@mui/material/styles";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const iconStyle = {
  marginRight: "12px",
};

const SnackbarProvider = styled(NotistackSnackbarProvider)(() => {
  const theme = useTheme();
  const palette = theme.palette;

  return {
    "&.notistack-MuiContent": {
      boxShadow: "none",
    },
    "&.notistack-MuiContent-info": {
      border: `1px solid ${palette.info.main}`,
      backgroundColor: palette.mode === "light" ? palette.info.light : palette.info.dark,
      color: palette.info.contrastText,
    },
    "&.notistack-MuiContent-success": {
      border: `1px solid ${palette.success.main}`,
      backgroundColor: palette.mode === "light" ? palette.success.light : palette.success.dark,
      color: palette.success.contrastText,
    },
    "&.notistack-MuiContent-warning": {
      border: `1px solid ${palette.warning.main}`,
      backgroundColor: palette.mode === "light" ? palette.warning.light : palette.warning.dark,
      color: palette.warning.contrastText,
    },
    "&.notistack-MuiContent-error": {
      border: `1px solid ${palette.error.main}`,
      backgroundColor: palette.mode === "light" ? palette.error.light : palette.error.dark,
      color: palette.error.contrastText,
    },
  };
});

SnackbarProvider.defaultProps = {
  iconVariant: {
    info: <InfoOutlinedIcon sx={iconStyle} />,
    success: <CheckCircleOutlineIcon sx={iconStyle} />,
    warning: <ReportProblemOutlinedIcon sx={iconStyle} />,
    error: <ErrorOutlineOutlinedIcon sx={iconStyle} />,
  },
};

export default SnackbarProvider;
