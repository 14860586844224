import { Components, Theme } from "@mui/material";

const MuiTextField: Components["MuiTextField"] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const palette = (theme as Theme).palette;
      return {
        width: "100%",
        backgroundColor: palette.background.filledInputBackground,
      };
    },
  },
};

export default MuiTextField;
