import TypographyWithTooltip from "components/_shared/Typography";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

interface ParticipantBottomInfoProps {
  name: string;
  you?: string;
}

export const ParticipantBottomInfo = ({ name, you }: ParticipantBottomInfoProps) => {
  const theme = useTheme();

  return (
    <div className="bottom-info">
      <TypographyWithTooltip
        variant="subText"
        className="participant-name"
        sx={{
          backgroundColor: theme.palette.background.callTiles.participantName,
          color: theme.palette.text.callTiles,
        }}
      >
        {name}
      </TypographyWithTooltip>
      {you && (
        <Typography
          variant="subText"
          className="you"
          sx={{
            backgroundColor: theme.palette.background.callTiles.youLabel,
            color: theme.palette.text.callTiles,
          }}
        >
          {you}
        </Typography>
      )}
    </div>
  );
};
