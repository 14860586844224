import { Components } from "@mui/material";

const MuiDialogTitle: Components["MuiDialogTitle"] = {
  styleOverrides: {
    root: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "132%",
      padding: "16px",
    },
  },
};

export default MuiDialogTitle;
