export class ApiError extends Error {

    public data: string;
    public status: number;
    public headers: any;
    public request: any;
    public message: string;

    constructor(msg: string) {
        super(msg);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ApiError.prototype);

        this.data = "";
        this.status = 0;
        this.message = "";
    }

}
