import Avatar from "components/_shared/Avatar";
import { CallMediaType } from "types/UC";
import { ParticipantBottomInfo } from "./ParticipantBottomInfo";

interface SelfProps {
  tile: any;
  selfViewState: string;
  you: string;
  in169: boolean;
}

export const Self = ({ tile, selfViewState, you, in169 }: SelfProps) => {
  const audioTile = tile?.type === CallMediaType.audio;

  return (
    <div
      id="self_view"
      className={`participant-tile ${selfViewState} ${in169 ? "participant-tile-aspect-ratio-16-9" : ""}`}
    >
      {(audioTile || tile.isMatrixTile) && (
        <>
          <div
            className={`audio-tile ${tile.type !== CallMediaType.audio ? " audio-tile-muted" : ""}`}
            id="p2p_audio_container_local"
          >
            {audioTile && (
              <Avatar
                name={tile.name}
                src={tile.avatarUrl}
                size="70"
                disableBadge
                status={`${tile.isMatrixTile ? "call" : "conf"}`}
              />
            )}
          </div>
          <ParticipantBottomInfo name={tile.name || ""} you={you} />
        </>
      )}
    </div>
  );
};
