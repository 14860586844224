import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SxProps, Theme, styled, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Badge, { BadgeProps } from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import RoomsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "icons/LockIcon";
import GroupChatIcon from "icons/GroupChatIcon";
import { getStatusMessage } from "services/PresenceProvider/PresenceProvider";
import { StatusValues } from "types/Matrix";
import { test } from "utils/helpers";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  borderRadius: "50%",
  "& .MuiBadge-badge": {
    border: `1px solid ${theme.palette.common.white}`,
    minHeight: "31%",
    minWidth: "31%",
    maxHeight: "31%",
    maxWidth: "31%",
  },
}));

export interface CustomAvatarProps {
  name?: string;
  disableBadge?: boolean;
  size?: string;
  status?: string;
  src?: string | null;
  isPerson?: boolean;
  isPrivateRoom?: boolean;
  isGroupRoom?: boolean;
  sx?: SxProps<Theme>;
  className?: string;
  customBadge?: JSX.Element;
}

const CustomAvatar = memo<CustomAvatarProps>(
  ({
    status = "offline",
    src = "",
    name = "User",
    size = "40",
    disableBadge = false,
    isPerson = true,
    isPrivateRoom = false,
    isGroupRoom = false,
    sx = undefined,
    className = "",
    customBadge = null,
  }) => {
    const theme = useTheme();
    const [statusDesc, setStatusDesc] = useState(status);
    const { t } = useTranslation();

    const getAvatarIcon = () => {
      const iconSize = parseInt(size) * 0.73;
      if (src) return null;
      if (isPerson)
        return (
          <PersonIcon {...test("PersonIcon")} sx={{ color: theme.palette.icon.primary, fontSize: `${iconSize}px` }} />
        );
      if (isGroupRoom)
        return (
          <GroupChatIcon
            {...test("GroupChatIcon")}
            sx={{ color: theme.palette.icon.primary, fontSize: `${iconSize}px` }}
          />
        );
      return <RoomsIcon {...test("RoomsIcon")} sx={{ color: theme.palette.icon.primary, fontSize: `${iconSize}px` }} />;
    };

    const getAvatar = () => {
      const dimensions = {
        ...sx,
        height: `${size}px`,
        width: `${size}px`,
        bgcolor: theme.palette.background.icons,
      };
      return (
        <Avatar alt={`${name} - ${status}`} src={src ?? ""} sx={dimensions} className={className}>
          {getAvatarIcon()}
        </Avatar>
      );
    };

    const getStatusColor = () => {
      switch (status) {
        case StatusValues.available:
        case StatusValues.online: {
          return theme.palette.sentiment.success.base;
        }
        case StatusValues.offline: {
          return theme.palette.neutrals.lightGrey5;
        }
        case StatusValues.away:
        case StatusValues.idle:
        case StatusValues.unavailable: {
          return theme.palette.sentiment.warning.base;
        }
        case StatusValues.dnd: {
          return theme.palette.sentiment.error.dark1;
        }
        case StatusValues.busy:
        case StatusValues.call:
        case StatusValues.conf: {
          return theme.palette.sentiment.error.base;
        }
        case StatusValues.presenting: {
          return theme.palette.common.white;
        }
      }
    };

    useEffect(() => {
      if (!status) return;
      const s = t(getStatusMessage(status).toUpperCase());
      setStatusDesc(s);
    }, [status, t]);

    return (
      <Tooltip
        title={name ? `${name} ${disableBadge || isPrivateRoom ? "" : `- ${statusDesc}`}` : ""}
        aria-label="User's Name"
        placement="right"
        arrow
      >
        {disableBadge ? (
          <>{getAvatar()}</>
        ) : customBadge ? (
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={customBadge}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: theme.palette.secondary.dark,
                height: "50px",
                width: "50px",
                borderRadius: "50%",
              },
            }}
          >
            {getAvatar()}
          </Badge>
        ) : isPrivateRoom ? (
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <LockIcon sx={{ fontSize: "13px", color: theme.palette.icon.secondary }} {...test("LockIcon")} />
            }
            sx={{ height: `${size}px` }}
          >
            {getAvatar()}
          </Badge>
        ) : (
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              status === StatusValues.presenting ? <DoDisturbOnIcon color="error" sx={{ fontSize: "14px" }} /> : ""
            }
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: getStatusColor(),
              },
            }}
          >
            {getAvatar()}
          </StyledBadge>
        )}
      </Tooltip>
    );
  }
);

export default CustomAvatar;
