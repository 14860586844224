import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import { useMatrixMessage, useMatrixRooms, useLocalStorage } from "hooks";
import styles from "./Messages.module.scss";
import { editMessageAction, replyMessageAction, forwardMessageAction } from "store/actions/user";
import { localStorageKeys } from "utils/constants";
import { MatrixEvent } from "matrix-js-sdk";
import { UCUser } from "types/UC";
import MessageForwardDialog from "./MessageForwardDialog";
import { LocalMatrixRoom } from "types/Matrix";
import { forwardTo } from "utils/helpers";

const MessageActions = ({ data, filteredRoomList }: { data: MatrixEvent; filteredRoomList: LocalMatrixRoom[] }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { room } = useMatrixRooms(data.event.room_id);
  const { removeMessage } = useMatrixMessage(room);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [currentUser] = useLocalStorage<UCUser>(localStorageKeys.CURRENT_USER);
  const [openDialog, setOpenDialog] = useState(false);
  const [openForwardDialog, setOpenForwardDialog] = useState(false);
  const [isUserAllowed, setIsUserAllowed] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const [isAttachment, setIsAttachment] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDeleteDialog = () => {
    setOpenDialog(!openDialog);
    setAnchorEl(null);
  };

  const toggleEdit = () => {
    dispatch(editMessageAction(data));
    setAnchorEl(null);
  };

  const toggleReply = () => {
    dispatch(replyMessageAction(data));
    setAnchorEl(null);
  };

  const toggleForwardDialog = () => {
    setOpenForwardDialog(!openForwardDialog);
    setAnchorEl(null);
  };

  const forwardMessage = (room: LocalMatrixRoom) => {
    toggleForwardDialog();
    forwardTo(room.roomId);
    // give some time to redirect to the new room before dispatching the action
    if (data) setTimeout(() => dispatch(forwardMessageAction(data)), 500);
  };

  const deleteMessage = () => {
    if (data.event.room_id) removeMessage(data.event.room_id, data.event.event_id);
    toggleDeleteDialog();
    setIsDeleted(true);
  };

  useEffect(() => {
    if (!data) return;
    const sender = data.getSender();
    if (currentUser.user_id !== sender) return;
    setIsUserAllowed(true);
  }, [data, currentUser]);

  useEffect(() => {
    if (!data) return;
    if (data.event && data.event.content) {
      if (data.event.content.msgtype === "m.image" || data.event.content.msgtype === "m.file") setIsAttachment(true);
      if (data.event.content.formatted_body && data.event.content.formatted_body.includes("<mx-forward><blockquote>"))
        setIsForward(true);
      if (!data.event.content.body) setIsDeleted(true);
    } else setIsDeleted(true);
  }, [data]);

  return (
    <div className={styles.memberActionsContainer}>
      {openDialog && (
        <ConfirmDialog
          title={t("DELETE_MESSAGE")}
          openDialog={openDialog}
          handleDialogClose={toggleDeleteDialog}
          handleDialogAgree={deleteMessage}
          message={`${t("AREYOUSUREYOUWANTTO")} ${t("DELETE_THIS_MESSAGE")}?`}
        />
      )}
      {openForwardDialog && (
        <MessageForwardDialog
          filteredRoomList={filteredRoomList}
          openDialog={openForwardDialog}
          handleDialogClose={toggleForwardDialog}
          handleDialogAgree={forwardMessage}
        />
      )}
      {!isDeleted && data.event && data.event.content && data.event.content.body && (
        <IconButton sx={{ width: "42px", height: "42px" }} onClick={handleClick} size="large">
          <MoreVertIcon></MoreVertIcon>
        </IconButton>
      )}
      <Menu id="room-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            toggleReply();
          }}
        >
          {t("REPLY")}
        </MenuItem>
        {room && (
          <MenuItem
            onClick={() => {
              toggleForwardDialog();
            }}
          >
            {t("FORWARD")}
          </MenuItem>
        )}
        {data.event &&
          data.event.content &&
          data.event.content.body &&
          isUserAllowed &&
          (isForward || !isAttachment) && (
            <MenuItem
              onClick={() => {
                toggleEdit();
              }}
            >
              {t("EDIT")}
            </MenuItem>
          )}
        {/* <MenuItem
        >
          {t("PINMESSAGE")}
        </MenuItem> */}
        {/* <MenuItem
        >
          {t("SELECTMESSAGE")}
        </MenuItem>
        <MenuItem
        >
          {t("HIDEMESSAGE")}
        </MenuItem> */}
        {data.event && data.event.content && data.event.content.body && isUserAllowed && (
          <MenuItem
            onClick={() => {
              toggleDeleteDialog();
            }}
          >
            {t("DELETE_MESSAGE")}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
export default MessageActions;
