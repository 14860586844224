import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import styled from "themes/theme/baseTheme";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import ClearIcon from "@mui/icons-material/ClearRounded";
import RefreshIcon from "@mui/icons-material/RefreshRounded";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import { useMatrixMessage, useMatrixRooms } from "hooks";
import { MatrixEvent } from "matrix-js-sdk";

const StyledButton = styled(Button)(({ theme }) => ({
  height: "30px",
  width: "30px",
  minWidth: "30px",
}));

const MessageErrorActions = ({ data }: { data: MatrixEvent }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { room } = useMatrixRooms(data.event.room_id);
  const { cancelPendingMessage, resendMessage } = useMatrixMessage(room);
  const [openDialog, setOpenDialog] = useState(false);

  const toggleDeleteDialog = () => {
    setOpenDialog(!openDialog);
  };

  const retryMessage = () => {
    if (data.event.room_id && room) resendMessage(data, room);
  };

  const deleteMessage = () => {
    cancelPendingMessage(data);
    toggleDeleteDialog();
  };

  return (
    <div style={{ margin: "0 10px 5px" }}>
      <ConfirmDialog
        title={t("DELETE_MESSAGE")}
        openDialog={openDialog}
        handleDialogClose={toggleDeleteDialog}
        handleDialogAgree={deleteMessage}
        message={`${t("AREYOUSUREYOUWANTTO")} ${t("DELETE_THIS_MESSAGE")}?`}
      />

      <Stack direction="row" alignItems="center" spacing={"10px"}>
        <Tooltip title={t("DELETE")} aria-label="delete" placement="top" arrow>
          <StyledButton
            variant="outlined"
            color="error"
            onClick={toggleDeleteDialog}
            sx={{ borderColor: theme.palette.sentiment.error.base }}
          >
            <ClearIcon />
          </StyledButton>
        </Tooltip>
        <Tooltip title={t("RETRY")} aria-label="retry" placement="top" arrow>
          <StyledButton variant="outlined" onClick={retryMessage}>
            <RefreshIcon />
          </StyledButton>
        </Tooltip>
      </Stack>
    </div>
  );
};
export default MessageErrorActions;
