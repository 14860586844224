import soapRequest from "easy-soap-request";
import xmlParser from "fast-xml-parser";
import _get from "lodash/get";
import _has from "lodash/has";
import Messages from "./soapMessages";
import { getLogger } from "logger/appLogger";

const logger = getLogger("vidyo");

const VidyoClient = (() => {
  const vidyo = {};
  const {
    portalFeaturesMessage,
    loginTypeMessage,
    loginMessage,
    logOutMessage,
    accountMessage,
    pinRestrictionsMessage,
    searchByEntityIDMessage,
    searchPersonalRoomByEntityIDMessage,
    deleteRoomMessage,
    createScheduledRoomMessage,
    createPublicRoomMessage,
    createRoomPinMessage,
    inviteContentMessage,
  } = Messages;

  const portalServices = {
    user: "/services/v1_1/VidyoPortalUserService",
    general: "/services/VidyoPortalGeneralService",
    guest: "/services/VidyoPortalGuestService",
  };

  const handleError = (e) => {
    if (e.message) {
      return e;
    } else {
      const data = xmlParser.parse(e, { ignoreNameSpace: true });
      let errorPath = "html.head.title";
      let hasError = _has(data, errorPath);
      if (hasError) {
        return new Error(`Vidyo Fail ${_get(data, errorPath)}`);
      } else {
        errorPath = "Envelope.Body.Fault.faultstring";
        hasError = _has(data, errorPath);
        if (hasError) {
          return new Error(`Vidyo Fail ${_get(data, errorPath)}`);
        }
      }
      return new Error("Vidyo API Error");
    }
  };

  vidyo.apiUrl = (url, serviceType) => {
    let returl = url;
    if (!url.endsWith("Service"))
      switch (serviceType) {
        case portalServices.user:
          returl = url + serviceType;
          break;
        case portalServices.general:
          returl = url + serviceType;
          break;
        case portalServices.guest:
          returl = url + serviceType;
          break;
        default:
          returl = url + "/services/v1_1/VidyoPortalUserService";
      }
    return returl;
  };

  vidyo.getPortalFeatures = async (url, token) => {
    try {
      logger.debug("Getting portal Features");
      const reqHeaders = {
        Authorization: "Basic " + token,
        "Content-Type": "text/xml;charset=UTF-8",
        SOAPAction: "getPortalFeatures",
      };
      const requestOpts = {
        url: vidyo.apiUrl(url, portalServices.user),
        headers: reqHeaders,
        xml: portalFeaturesMessage,
      };
      const { response } = await soapRequest(requestOpts);
      const { body } = response;
      if (xmlParser.validate(body) === true) {
        const data = xmlParser.parse(body, { ignoreNameSpace: true });
        const path = "Envelope.Body.GetPortalFeaturesResponse";
        const hasPath = _has(data, path);
        if (hasPath) {
          logger.debug(" Get portal Features Successful");
          return _get(data, path);
        } else {
          throw new Error("Response not found");
        }
      } else {
        throw new Error("Get Features invalid");
      }
    } catch (e) {
      logger.error(`Failed to getportal features: ${e.message}`);
      throw handleError(e);
    }
  };

  // LOGIN ------------------------------------------------------------------
  vidyo.getLoginType = async (url) => {
    try {
      const reqHeaders = {
        "Content-Type": "text/xml;charset=UTF-8",
        SOAPAction: "getLogInType",
      };
      const requestOpts = {
        url: vidyo.apiUrl(url, portalServices.general),
        headers: reqHeaders,
        xml: loginTypeMessage,
      };
      const res = await soapRequest(requestOpts);
      logger.debug(`Get login type - ${res}`);
    } catch (e) {
      logger.error(`Failed to get vidyo login type: ${e.message}`);
    }
  };

  vidyo.login = async (url, username, password) => {
    try {
      logger.log("Logging into vidyo");
      const reqHeaders = {
        Authorization: "Basic " + btoa(username + ":" + password),
        "Content-Type": "text/xml;charset=UTF-8",
        SOAPAction: "logIn",
      };
      const requestOpts = {
        url: vidyo.apiUrl(url, portalServices.user),
        headers: reqHeaders,
        xml: loginMessage,
        timeout: 30000,
      };
      const { response } = await soapRequest(requestOpts);
      const { body } = response;
      if (xmlParser.validate(body) === true) {
        const data = xmlParser.parse(body, { ignoreNameSpace: true });
        const authPath = "Envelope.Body.LogInResponse";
        const hasAuth = _has(data, authPath);
        if (hasAuth) {
          logger.log("Login Successful");
          return _get(data, authPath);
        } else {
          throw new Error("Token not found");
        }
      } else {
        throw new Error("Login response invalid");
      }
    } catch (e) {
      logger.log("LogIn Failed");
      throw handleError(e);
    }
  };

  vidyo.logout = async (url, token) => {
    try {
      logger.log("Logging out from vidyo");
      const reqHeaders = {
        Authorization: "Basic " + token,
        "Content-Type": "text/xml;charset=UTF-8",
        SOAPAction: "logOut",
      };
      const requestOpts = {
        url: vidyo.apiUrl(url, portalServices.user),
        headers: reqHeaders,
        xml: logOutMessage,
        timeout: 30000,
      };
      const { response } = await soapRequest(requestOpts);
      const { body } = response;
      if (xmlParser.validate(body) === true) {
        const data = xmlParser.parse(body, { ignoreNameSpace: true });
        const path = "Envelope.Body.LogOutResponse.OK";
        const hasOK = _has(data, path);
        if (hasOK) {
          logger.log("Logout Successful");
          return _get(data, path);
        } else {
          throw new Error("Response not found");
        }
      } else {
        throw new Error("Logout response invalid");
      }
    } catch (e) {
      logger.error("Logout Failed: ", e.message);
    }
  };

  // ACCOUNT -------------------------------------------------------------------------
  vidyo.getMyAccount = async (url, token) => {
    try {
      const reqHeaders = {
        Authorization: "Basic " + token,
        "Content-Type": "text/xml;charset=UTF-8",
        SOAPAction: "myAccount",
      };
      const requestOpts = {
        url: vidyo.apiUrl(url, portalServices.user),
        headers: reqHeaders,
        xml: accountMessage,
      };
      const res = await soapRequest(requestOpts);
      logger.log(`res`, res);
    } catch (e) {
      logger.error(`Failed to get user account: ${e.message}`);
    }
  };

  // ROOM --------------------------------------------------------------------------
  vidyo.getPinRestrictions = async (url, token) => {
    try {
      const reqHeaders = {
        Authorization: "Basic " + token,
        "Content-Type": "text/xml;charset=UTF-8",
        SOAPAction: "pinRestrictions",
      };
      const requestOpts = {
        url: vidyo.apiUrl(url, portalServices.user),
        headers: reqHeaders,
        xml: pinRestrictionsMessage,
      };
      const res = await soapRequest(requestOpts);
      logger.log(`res`, res);
    } catch (e) {
      logger.error(`Failed to get pin restrictions: ${e.message}`);
    }
  };

  vidyo.searchByEntityID = async (url, token, entityID) => {
    try {
      const reqHeaders = {
        Authorization: "Basic " + token,
        "Content-Type": "text/xml;charset=UTF-8",
        SOAPAction: "searchByEntityID",
      };
      const requestOpts = {
        url: vidyo.apiUrl(url, portalServices.user),
        headers: reqHeaders,
        xml: searchByEntityIDMessage(entityID),
      };
      const res = await soapRequest(requestOpts);
      logger.log(`res`, res);
    } catch (e) {
      logger.error(`Failed to search by entity id: ${e.message}`);
    }
  };

  vidyo.searchPersonalRoomByEntityID = async (url, token, entityID) => {
    try {
      const reqHeaders = {
        Authorization: "Basic " + token,
        "Content-Type": "text/xml;charset=UTF-8",
        SOAPAction: "searchByEntityID",
      };
      const requestOpts = {
        url: vidyo.apiUrl(url, portalServices.user),
        headers: reqHeaders,
        xml: searchPersonalRoomByEntityIDMessage(entityID),
      };
      const res = await soapRequest(requestOpts);
      logger.log(`res`, res);
    } catch (e) {
      logger.error(`Failed to search personal room by entity id: ${e.message}`);
    }
  };

  vidyo.deleteRoom = async (url, token, roomId) => {
    try {
      logger.log("Delete Vidyo Room");
      const reqHeaders = {
        Authorization: "Basic " + token,
        "Content-Type": "text/xml;charset=UTF-8",
        SOAPAction: "deleteRoom",
      };
      const requestOpts = {
        url: vidyo.apiUrl(url, portalServices.user),
        headers: reqHeaders,
        xml: deleteRoomMessage(roomId),
      };
      const { response } = await soapRequest(requestOpts);
      const { body } = response;
      if (xmlParser.validate(body) === true) {
        const data = xmlParser.parse(body, { ignoreNameSpace: true });
        const path = "Envelope.Body.DeleteRoomResponse.OK";
        const hasOK = _has(data, path);
        if (hasOK) {
          logger.log("Delete Room Successful");
          return _get(data, path);
        } else {
          throw new Error("Delete room response not found");
        }
      } else {
        throw new Error("Delete room response invalid");
      }
    } catch (e) {
      throw handleError(e);
    }
  };

  vidyo.createScheduledRoom = async (url, token, pin = false) => {
    try {
      const reqHeaders = {
        Authorization: "Basic " + token,
        "Content-Type": "text/xml;charset=UTF-8",
        SOAPAction: "createScheduledRoom",
      };
      const requestOpts = {
        url: vidyo.apiUrl(url, portalServices.user),
        headers: reqHeaders,
        xml: createScheduledRoomMessage(pin),
      };
      const { response } = await soapRequest(requestOpts);
      const { body } = response;
      if (xmlParser.validate(body) === true) {
        const data = xmlParser.parse(body, { ignoreNameSpace: true });
        const roomPath = "Envelope.Body.CreateScheduledRoomResponse";
        const hasRoom = _has(data, roomPath);
        if (hasRoom) {
          logger.log("Create Scheduled Room Successful");
          return _get(data, roomPath);
        } else {
          throw new Error("Create Scheduled room response not found");
        }
      } else {
        throw new Error("Create Scheduled room response invalid");
      }
    } catch (e) {
      throw handleError(e);
    }
  };

  vidyo.createPublicRoom = async (
    url,
    token,
    displayName,
    inMyContacts = false,
    locked = false,
    pin = null,
    description = null
  ) => {
    try {
      logger.log("Creating Vidyo Room");
      const reqHeaders = {
        Authorization: "Basic " + token,
        "Content-Type": "text/xml;charset=UTF-8",
        SOAPAction: "createPublicRoom",
      };
      const requestOpts = {
        url: vidyo.apiUrl(url, portalServices.user),
        headers: reqHeaders,
        xml: createPublicRoomMessage(displayName, inMyContacts, locked, pin, description),
      };
      const { response } = await soapRequest(requestOpts);
      const { body } = response;
      if (xmlParser.validate(body) === true) {
        const data = xmlParser.parse(body, { ignoreNameSpace: true });
        const roomPath = "Envelope.Body.CreatePublicRoomResponse";
        const hasRoom = _has(data, roomPath);
        if (hasRoom) {
          logger.log("Create Public Room Successful");
          return _get(data, roomPath);
        } else {
          throw new Error("Create Public room response not found");
        }
      } else {
        throw new Error("Create Public room response invalid");
      }
    } catch (e) {
      throw handleError(e);
    }
  };

  vidyo.createRoomPin = async (url, token, roomId, pin) => {
    try {
      const reqHeaders = {
        Authorization: "Basic " + token,
        "Content-Type": "text/xml;charset=UTF-8",
        SOAPAction: pin ? "createRoomPIN" : "removeRoomPIN",
      };
      const requestOpts = {
        url: vidyo.apiUrl(url, portalServices.user),
        headers: reqHeaders,
        xml: createRoomPinMessage(roomId, pin),
      };
      const res = await soapRequest(requestOpts);
      logger.log(`res`, res);
    } catch (e) {
      logger.error(`Failed to create room pin: ${e.message}`);
    }
  };

  vidyo.getInviteContent = async (url, token, roomId, pin) => {
    try {
      await vidyo.createRoomPin(url, token, roomId, pin);
      const reqHeaders = {
        Authorization: "Basic " + token,
        "Content-Type": "text/xml;charset=UTF-8",
        SOAPAction: "getInviteContent",
      };
      const requestOpts = {
        url: vidyo.apiUrl(url, portalServices.user),
        headers: reqHeaders,
        xml: inviteContentMessage(roomId),
      };
      const res = await soapRequest(requestOpts);
      logger.log(`res`, res);
    } catch (e) {
      logger.error(`Failed to get invite content: ${e.message}`);
    }
  };

  return vidyo;
})();

export default VidyoClient;
