import React from "react";
import styles from "../MeetingSideBar/MeetingSideBar.module.scss";
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import { test } from "utils/helpers";
import MeetingParticipant from "./MeetingParticipant";
import { useTranslation } from "react-i18next";
import { UserKeycloakData } from "services/ProvisioningApiProvider/types/Provisioning";
import { MEETING_PARTICIPANTS_SIDEBAR_WIDTH } from "utils/constants";
import Typography from "@mui/material/Typography";

const MeetingSideBar = ({ participants }: { participants: UserKeycloakData[] }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <div style={{ position: "relative" }} {...test("MEETING_SIDEBAR")}>
      <Drawer
        anchor="right"
        variant="permanent"
        className={styles.main}
        sx={{
          [theme.breakpoints.up("md")]: {
            width: MEETING_PARTICIPANTS_SIDEBAR_WIDTH,
            flexShrink: 1,
          },
          backgroundColor: "#2F3138",
          "& .MuiPaper-root": {
            [theme.breakpoints.down("lg")]: {
              width: MEETING_PARTICIPANTS_SIDEBAR_WIDTH,
              flexShrink: 1,
            },
            width: MEETING_PARTICIPANTS_SIDEBAR_WIDTH,
          },
        }}
      >
        <div className={`${styles.participants} scroll-bar`}>
          <div className={`${styles.title}`}>
            <Typography variant="h6">{t("PARTICIPANTS")}</Typography>
          </div>
          <div className={`${styles.list}`}>
            {participants.map((item, index) => {
              return <MeetingParticipant key={index} participantInfo={item} />;
            })}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default MeetingSideBar;
