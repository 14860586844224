import { AnswerRule } from "../types/AnswerRule";
import { SubscriberInformation } from "../types/SubscriberInformation";
import { PBXUserConfig, PBXUserToken, PbxCallQueueResult } from "../types/PBXUserConfig";
import { CallHistoryEntries } from "../types/CallHistoryEntries";

import { ApiProvider } from "services/Providers/ApiProvider";
import { getTenantName } from "services/Providers/ProvisioningTokenProvider";
import { DEVICES } from "types/UC";
import { GetPbxUsersRequest, PbxMergeCallsResult, PbxUserData, PbxUserDetails } from "../types/PbxUsers";
import getStore from "store/store";
import { RootState } from "store/root-reducer";
import { isEmpty } from "utils/helpers";
import { PbxEventListenerType, PbxEventSubscription, PbxCallInfo } from "../types/EventListener";

export class PBXServerApiProvider {
  private apiProvider: ApiProvider;

  // constructor
  constructor() {
    const state: RootState = getStore().getState();
    const endpointData = state.provisioning.endpointData;
    const pbxUrl = endpointData
      ? endpointData.pbx.substring(endpointData.pbx.indexOf("/"))
      : `/tenant/${getTenantName()}/pbx`;
    this.apiProvider = new ApiProvider(`${pbxUrl}/api/v1/`);
  }

  // singleton
  private static _instance: PBXServerApiProvider;
  static get Instance(): PBXServerApiProvider {
    if (!this._instance) {
      this._instance = new PBXServerApiProvider();
    }
    return this._instance;
  }

  //
  // API
  //
  public getPBXUserConfig(deviceType: DEVICES): Promise<PBXUserConfig> {
    return this.apiProvider.restApiCall<PBXUserConfig>({
      path: "/pbx-access-token",
      method: "get",
      params: { deviceType: deviceType },
    });
  }

  public postPBXRefreshToken(pbxRefreshToken: string): Promise<PBXUserToken> {
    return this.apiProvider.restApiCall<PBXUserToken>({
      path: "/pbx-access-token/refresh",
      method: "post",
      body: { refresh_token: pbxRefreshToken },
    });
  }

  public getAnswerRule(pbxToken: string, username: string): Promise<AnswerRule[]> {
    return this.apiProvider.restApiCall<AnswerRule[]>({
      path: `/answer-rule/${username}`,
      method: "post",
      body: { access_token: pbxToken },
    });
  }

  public putAnswerRule(pbxToken: string, username: string, answerRule: AnswerRule): Promise<AnswerRule> {
    return this.apiProvider.restApiCall<AnswerRule>({
      path: `/answer-rule/${username}`,
      method: "put",
      body: {
        access_token: pbxToken,
        config: answerRule,
      },
    });
  }

  public getSubscriberInformation(pbxToken: string, user: string): Promise<SubscriberInformation[]> {
    return this.apiProvider.restApiCall<SubscriberInformation[]>({
      path: `/subscribers/${user}`,
      method: "post",
      body: { access_token: pbxToken },
    });
  }

  public getCallHistory(
    pbxToken: string,
    username: string,
    first: number,
    max: number,
    startDate: Date,
    endDate: Date
  ): Promise<CallHistoryEntries> {
    return this.apiProvider.restApiCall<CallHistoryEntries>({
      path: `/call-history/${username}`,
      method: "post",
      params: {
        first: first,
        max: max,
        startDate: startDate,
        endDate: endDate,
      },
      body: { access_token: pbxToken },
    });
  }

  public getPbxUsers(args: GetPbxUsersRequest): Promise<PbxUserData> {
    const { includePresence, didAssignedOnly, queryString, max } = args;
    return this.apiProvider.restApiCall<PbxUserData>({
      path: `/pbx-users?includePresence=${includePresence}${queryString ? `&search=${queryString}` : ``}${
        didAssignedOnly ? `&didAssignedOnly=${didAssignedOnly}` : ``
      }${max ? `&max=${max}` : ``}`,
      method: "get",
    });
  }

  public getPbxUserDetails(username: string): Promise<PbxUserDetails> {
    return this.apiProvider.restApiCall<PbxUserDetails>({
      path: `/user-details/${username}`,
      method: "get",
      ignoreErrors: [404],
    });
  }

  public mergeCalls(pbxToken: string, username: string, callIds: string[]): Promise<PbxMergeCallsResult> {
    return this.apiProvider.restApiCall<PbxMergeCallsResult>({
      path: `/call/merge/${username}`,
      method: "post",
      body: { access_token: pbxToken, callIds },
    });
  }

  public getCallQueues(pbxToken: string, search: string | undefined): Promise<PbxCallQueueResult> {
    return this.apiProvider.restApiCall<PbxCallQueueResult>({
      path: `/call-queues`,
      method: "get",
      params: {
        ...(!isEmpty(search) && { search }),
      },
      body: { access_token: pbxToken },
    });
  }

  public getCallInfo(pbxToken: string, callId: string): Promise<PbxCallInfo> {
    return this.apiProvider.restApiCall<PbxCallInfo>({
      path: `/call/${callId}`,
      method: "get",
      body: { access_token: pbxToken },
    });
  }

  public async postSubscription(
    pbxToken: string,
    pbxSubscriptionType: PbxEventListenerType,
    id: string
  ): Promise<PbxEventSubscription> {
    if (isEmpty(id)) {
      throw new Error("Empty id");
    }
    let idDefinition = {};
    switch (pbxSubscriptionType) {
      case PbxEventListenerType.Conference:
        idDefinition = { conferenceId: id };
        break;
      case PbxEventListenerType.Call:
        idDefinition = { callId: id };
        break;
    }
    return await this.apiProvider.restApiCall<PbxEventSubscription>({
      path: `/subscriptions/${pbxSubscriptionType.toString()}`,
      method: "post",
      body: { access_token: pbxToken, ...idDefinition },
    });
  }
}
