import * as actionTypes from "../actions/types/user";

export const editMessageAction = (data) => ({
  type: actionTypes.EDIT_MESSAGE,
  data,
});

export const replyMessageAction = (data) => ({
  type: actionTypes.REPLY_MESSAGE,
  data,
});

export const forwardMessageAction = (data) => ({
  type: actionTypes.FORWARD_MESSAGE,
  data,
});
