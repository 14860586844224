import { Components, Theme } from "@mui/material";
import { buttonClasses } from "@mui/material/Button";

const MuiDialogActions: Components["MuiDialogActions"] = {
  styleOverrides: {
    root: ({ theme }) => {
      const palette = (theme as Theme).palette;
      return {
        height: "68px",
        padding: "16px",
        "& .MuiButton-root": {
          width: "fit-content",
          "&:last-of-type": {
            backgroundColor: `${palette.background.button.contained.active}`,
            color: `${palette.text.button.contained.active}`,
            "&:hover": {
              backgroundColor: `${palette.background.button.contained.hover}`,
              color: `${palette.text.button.contained.hover}`,
            },
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: `${palette.background.button.contained.disabled}`,
              border: `1px solid ${palette.border.button.contained.disabled}`,
              color: `${palette.text.button.contained.disabled}`,
            },
          },
        },
      };
    },
  },
};

export default MuiDialogActions;
