import { Components, Theme } from "@mui/material";

const MuiTooltip: Components["MuiTooltip"] = {
  styleOverrides: {
    tooltip: ({ theme }) => {
      const palette = (theme as Theme).palette;
      return {
        backgroundColor: palette.background.snackbarBackground,
        color: palette.common.white,
        fontWeight: 500,
        padding: "8px 4px",
      };
    },
  },
};

export default MuiTooltip;
