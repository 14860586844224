import axios from "axios";
import { TokenInfo } from "./types/TokenInfo";
import { getLogger } from "logger/appLogger";
import { localStorageKeys } from "utils/constants";

namespace local {
  const getRefreshTokenUrl = () => {
    const url = "/tenant-provisioning/api/v1/auth/refreshtoken";
    let tenantName = getTenantName();
    return tenantName ? `tenant/${tenantName}${url}` : url;
  };

  export const getRefreshToken = async (refreshToken: string): Promise<TokenInfo> => {
    const response = await axios.post(getRefreshTokenUrl(), {
      refresh_token: refreshToken,
    });
    return Promise.resolve(response.data);
  };
}

export class ProvisioningTokenProvider {
  // singleton
  private static _instance: ProvisioningTokenProvider;
  static get Instance(): ProvisioningTokenProvider {
    if (!this._instance) {
      this._instance = new ProvisioningTokenProvider();
    }
    return this._instance;
  }

  // log
  private log = getLogger("token-provider");

  // timer
  private _timerHandle: any;

  // watchdog timer
  private _watchdogIntervalHandle: any;
  private _watchdogIntervalPeriodMs: number = 10 * 1000;

  // token
  private _tokenInfo!: TokenInfo;

  // token info timestamp
  private _tokenInfoTimestamp: number = 0;

  // refresh fails counter
  private _refreshFailCount = 0;
  private readonly _refreshFailCountMax = 10;

  // upcoming timeout run
  private _nextTimerRun: number = 0;

  // callback onRefreshTokenFail
  private _onRefreshTokenFail: () => void = () => {};

  // idToken
  private _idToken: string = "";

  // properties
  get accessToken(): string {
    this.checkInvariant();
    return this._tokenInfo.access_token;
  }

  get refreshToken(): string {
    this.checkInvariant();
    return this._tokenInfo.refresh_token;
  }

  get idToken(): string {
    return this._idToken;
  }

  constructor() {
    this._watchdogIntervalHandle = setInterval(async () => {
      this.log.debug(`watchdog: validate access token (expires ${this.formatDate(this.accessTokenExpiresAt())})`);
      await validateAccessToken();
    }, this._watchdogIntervalPeriodMs);
  }

  // setter
  private setTokenInfo(tokenInfo: TokenInfo, caller: string) {
    this.log.debug(`set token from '${caller}' : ${JSON.stringify(tokenInfo)}`);
    this._tokenInfo = tokenInfo;
    this._tokenInfoTimestamp = Date.now();
    this.checkInvariant();
  }

  private haveToken() {
    return (
      this._tokenInfo &&
      this._tokenInfo.refresh_token &&
      this._tokenInfo.refresh_token.length > 0 &&
      this._tokenInfo.access_token &&
      this._tokenInfo.access_token.length > 0
    );
  }

  // check for consistency
  private checkInvariant() {
    if (this.haveToken()) {
      // ok
      if (this._tokenInfo.refresh_expires_in < this._tokenInfo.expires_in) {
        this.log.error(`Configuration warning, refreshToken expires ${this._tokenInfo.refresh_expires_in} 
                          smaller than accessToken expires ${this._tokenInfo.expires_in}`);
      }
      this.hasAccessTokenExpired();
    } else {
      throw new Error("ProvisioningTokenProvider not initialized");
    }
  }

  private isInitialAccessToken() {
    return this._tokenInfo && this._tokenInfo.expires_in === 0;
  }

  private accessTokenExpiresAt() {
    return this._tokenInfo ? this._tokenInfoTimestamp + this._tokenInfo.expires_in * 1000 : 0;
  }

  private hasAccessTokenExpired() {
    if (this.isInitialAccessToken()) {
      this.log.info("Still using initial access token, not yet refreshed.");
    } else if (Date.now() > this.accessTokenExpiresAt()) {
      this.log.error(`Access token has expired ${this.formatDate(this.accessTokenExpiresAt())}`);
      return true;
    }
    return false;
  }

  // map token expires time to interval
  private calculateSleepTimeFromTokenExpiresSeconds(expiresSec: number): number {
    return expiresSec > 0
      ? Math.max((expiresSec * 2) / 3, 5) // 2/3 of expires time but not less than 5
      : 0; // as soon as possible
  }

  // stop timer
  private stopTimer() {
    if (this._timerHandle) {
      clearTimeout(this._timerHandle);
      this._timerHandle = undefined;
    }
  }

  // calculate sleep time and schedule runner
  private startTimer() {
    const timeoutTimeSec = this.calculateSleepTimeFromTokenExpiresSeconds(this._tokenInfo.expires_in);
    const timeoutTimeMSec = timeoutTimeSec * 1000;

    this._nextTimerRun = Date.now() + timeoutTimeMSec;
    const nextTimerRunAsTimeString = new Date(this._nextTimerRun).toLocaleTimeString("en-GB");

    this.log.debug(`sleeping ${timeoutTimeSec} from ${this._tokenInfo.expires_in} till ${nextTimerRunAsTimeString}`);

    this._timerHandle = setTimeout(async () => {
      this.missedTimerRun(); // just for the log entry
      await this.refreshAccessToken();
      this.startTimer();
    }, timeoutTimeMSec);
  }

  // initial setting from login page
  public async initialSetToken(accessToken: string, refreshToken: string, idToken: string) {
    this._idToken = idToken;
    this.stopTimer();
    this.setTokenInfo(
      {
        access_token: accessToken,
        refresh_token: refreshToken,
        expires_in: 0,
        refresh_expires_in: 0,
      },
      "initial"
    );
    await this.refreshAccessToken();
    this.startTimer();
  }

  // callback onRefreshTokenFail
  public setOnRefreshTokenFailCallback(foo: () => void) {
    this._onRefreshTokenFail = foo;
  }

  // refresh token
  public async refreshAccessToken() {
    try {
      this.setTokenInfo(await local.getRefreshToken(this.refreshToken), "refresh");
      this._refreshFailCount = 0;
    } catch (error) {
      this._refreshFailCount++;
      const networkError = error.message.includes("Network Error");
      const now = Date.now();
      const refreshExpiresAt = this._tokenInfoTimestamp + this._tokenInfo.refresh_expires_in * 1000;
      if (networkError && now < refreshExpiresAt && this._refreshFailCount < this._refreshFailCountMax) {
        const msg = `#${this._refreshFailCount}/${this._refreshFailCountMax} (valid until ${this.formatDate(
          refreshExpiresAt
        )})`;
        this.log.warn(`ignoring refresh-token error ${msg} : ${error}`);
      } else {
        this.log.error(`refreshToken failed: ${error}`);
        this._onRefreshTokenFail();
      }
    }
  }

  // format date
  private formatDate(timestamp: number): string {
    const date = new Date(timestamp);
    const dateFormatted = date.toLocaleString("sv", { timeZoneName: "short" });
    return `[${timestamp}: ${dateFormatted}]`;
  }

  // have we missed a timer run and token-refresh (due to hibernate)?
  public missedTimerRun(): boolean {
    const now: number = Date.now();
    const ret: boolean = this._nextTimerRun > 0 && this._nextTimerRun < now && now - this._nextTimerRun > 1000;
    if (ret) {
      const nextTimerRunAsTimeString = new Date(this._nextTimerRun).toLocaleTimeString("en-GB");
      this.log.info(`missed timeout schedule at ${nextTimerRunAsTimeString}`);
    }
    return ret;
  }

  // run refresh if missed timeout
  public async validateAccessToken() {
    if (this.haveToken()) {
      if (this.hasAccessTokenExpired() || this.missedTimerRun()) {
        this.stopTimer();
        await this.refreshAccessToken();
        this.startTimer();
      }
    }
  }
}

// provider callback
export async function validateAccessToken() {
  await ProvisioningTokenProvider.Instance.validateAccessToken();
}

export async function refreshAccessToken() {
  await ProvisioningTokenProvider.Instance.refreshAccessToken();
}

export function getAccessToken(): string {
  return ProvisioningTokenProvider.Instance.accessToken;
}

// provider callback
export function getTenantName() {
  let tenantName = localStorage.getItem(localStorageKeys.TENANT_NAME);
  tenantName = tenantName ? JSON.parse(tenantName) : null;
  if (!tenantName) {
    throw new Error("Missing tenantName in localstorage");
  }
  return tenantName;
}
