import { useEffect, useState, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Cookies from "js-cookie";
import { getLogger } from "logger/appLogger";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// styles
import styles from "./LoginScreen.module.scss";

import { Alert } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";
import { BrandLogo, Login } from "components";
import { useDispatch } from "react-redux";
import { authActions } from "store/actions/auth";

import { useRouter, useMatrix, useLocalStorage, useProvisioning, useLanguage } from "hooks";
import { ProvisioningTokenProvider } from "services/Providers/ProvisioningTokenProvider";
import { localStorageKeys, PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from "utils/constants";
import { getFromLocalStorage, test, isEmpty } from "utils/helpers";
import { RootState } from "store/root-reducer";
import { schemify } from "utils/utils";

const LoginScreen = () => {
  const { restoreLanguage } = useLanguage();
  const { t } = useTranslation();
  const theme = useTheme();

  const logger = useMemo(() => getLogger("login"), []);
  const module = "LoginScreen";

  const { origin, pathname, hash, search } = window.location;
  const { login } = authActions;

  const router = useRouter();
  const dispatch = useDispatch();

  const provisioningConfig = useSelector((state: RootState) => state.provisioning.provisioningConfig);
  const endpointData = useSelector((state: RootState) => state.provisioning.endpointData);

  const currentTenantServer = `${origin}`;
  const [redirectUrl, setRedirectUrl] = useState(endpointData ? `https://${endpointData.redirect_url}/` : `${origin}/`);
  const { loginWithTokenClient } = useMatrix(redirectUrl);
  const { getEndpointData } = useProvisioning();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [openAlert, setopenAlert] = useState(false);
  const { permission, requestPermission } = Notification;
  const [currentTenantName, setCurrentTenantName] = useLocalStorage<string>(localStorageKeys.TENANT_NAME);
  const [errorMessage, setErrorMessage] = useState("");
  const [links, SetLinks] = useState({
    termsOfServive: "",
    privacyPolicy: "",
  });
  const isElectron = window.navigator.userAgent.indexOf("Electron") !== -1;

  const clearParams = () => {
    const pathName = pathname.replace(/\/+$/, "/");
    window.history.replaceState(null, "", `${origin}${pathName}${hash}`);
    setRedirectUrl(window.location.href);
  };

  const handleSubmit = async () => {
    if (!isEmpty(currentTenantName)) {
      const path = `${origin}/tenant/${currentTenantName}/tenant-provisioning/api/v1/auth/login?originalRedirectUrl=${redirectUrl}`;
      window.location.href = path;
    } else {
      logger.error("Tenant undefined on submit");
    }
  };

  const onError = (message: string) => {
    setopenAlert(true);
    setErrorMessage(t("TENANT_ERROR"));
    logger.error(`${module}: Error - ${message}`);
  };

  const clearErrors = () => {
    setopenAlert(false);
    setErrorMessage("");
  };

  const handleTokens = async (loginToken: string, accessToken: string | null) => {
    try {
      setIsLoggingIn(true);
      setopenAlert(false);
      const refreshToken = Cookies.get("refresh_token") || "";
      if (!accessToken) {
        accessToken = Cookies.get("access_token") || "";
      }
      const idToken = Cookies.get("id_token") || "";

      logger.debug(
        `${module}: initial set token with loginToken/len=${loginToken?.length} accessToken/len=${accessToken?.length} refreshToken/len=${refreshToken?.length} idToken/len=${idToken?.length}`
      );
      await ProvisioningTokenProvider.Instance.initialSetToken(accessToken!, refreshToken, idToken);

      const endpointData = await getEndpointData();
      const { matrix } = endpointData;

      loginCoolio(matrix, loginToken);
    } catch (e) {
      setIsLoggingIn(false);
      onError(e.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const loginCoolio = async (matrix: any, loginToken: string) => {
    try {
      logger.debug(`${module}: loginMatrix pathname=${router.pathname}`);
      await loginMatrix(matrix, loginToken);
      dispatch(login());
      Cookies.set("tenant_name", currentTenantName);
      clearParams();
      setIsLoggingIn(false);
      if (!router.pathname.includes("auth")) {
        router.push(router.pathname);
      } else {
        router.push("/home");
      }
      logger.debug(`${module}: loginMatrix done`);
    } catch (e) {
      setIsLoggingIn(false);
      setopenAlert(true);
      let errorMsg = "";
      if (e.message === "Invalid token") {
        clearParams();
        errorMsg = t("MATRIX_LOGIN_TOKEN_EXPIRED");
      } else {
        errorMsg = t("TENANT_ERROR");
      }
      setErrorMessage(errorMsg);
      logger.error(`${module}: Failed to login to coolio: ${e.message}`);
    }
  };

  const loginMatrix = async (matrix: any, token: string) => {
    try {
      const matrixUrl = schemify(matrix, "https");
      const res = await loginWithTokenClient(token, matrixUrl);
      return res;
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    if (isElectron) {
      const hsStore = { hs: currentTenantServer, tenant: currentTenantName, last: true };
      logger.debug(`${module}: loginScreen:setHSStore ${JSON.stringify(hsStore)}`);
      window.ipcRenderer.invoke("setHSStore", hsStore).then((result: any) => {
        logger.debug(`${module}: handle submit result: ${result}`);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isElectron, currentTenantServer, currentTenantName]);

  useEffect(() => {
    if (!search) return;

    const urlParams = new URLSearchParams(search);
    const loginToken = urlParams.get("loginToken");
    const access_token = urlParams.get("access_token");

    if (loginToken) {
      handleTokens(loginToken, access_token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    restoreLanguage();
    if (permission === "default") {
      requestPermission();
      logger.debug(`${module}: requested permission`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!provisioningConfig) {
      const savedProvisiningConfig = getFromLocalStorage(localStorageKeys.PROVISIONING_CONFIG);
      if (savedProvisiningConfig) {
        SetLinks({
          termsOfServive: savedProvisiningConfig.termsAndConditionsUrl,
          privacyPolicy: savedProvisiningConfig.privacyPolicyUrl,
        });
      } else {
        SetLinks({
          termsOfServive: TERMS_AND_CONDITIONS_URL,
          privacyPolicy: PRIVACY_POLICY_URL,
        });
      }
    } else {
      SetLinks({
        termsOfServive: provisioningConfig.termsAndConditionsUrl,
        privacyPolicy: provisioningConfig.privacyPolicyUrl,
      });
    }
  }, [provisioningConfig]);

  return isElectron ? (
    <></>
  ) : (
    <div className={styles.main} {...test("LOGIN_SCREEN")}>
      <div className={`${styles.logo} ${styles.mq} col-sm-6`}>
        <BrandLogo />
      </div>
      <Typography
        variant="h4"
        sx={{ color: theme.palette.text.primary, textAlign: "center", marginBottom: "11px" }}
        {...test("LOGIN_MESSAGE")}
      >
        {t("WELCOME")}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: "40px !important",
          textAlign: "center",
          color: theme.palette.text.secondary,
        }}
      >
        {t("PLEASE_ENTER_VALUE", { value: t("TENANT_DETAILS") })}
      </Typography>
      <Collapse in={openAlert}>
        <Alert severity="error" variant="outlined" sx={{ marginBottom: "1rem" }}>
          {errorMessage}
        </Alert>
      </Collapse>
      <div className={`${styles.mq} d-flex flex-column col-sm-6`}>
        <Login
          currentTenantName={currentTenantName}
          currentTenantServer={currentTenantServer}
          handleTenantNameChange={setCurrentTenantName}
          onSubmit={handleSubmit}
          isLoggingIn={isLoggingIn}
          onError={onError}
          clearErrors={clearErrors}
        />
      </div>
      <div className={styles.links}>
        <Link href={links.termsOfServive} id="tos-link" target="_blank" underline="always" sx={{ marginBottom: "5px" }}>
          {t("TERMS_OF_SERVICE")}
        </Link>
        <Link href={links.privacyPolicy} id="privacy-link" target="_blank" underline="always">
          {t("PRIVACY_POLICY")}
        </Link>
      </div>
    </div>
  );
};

export default LoginScreen;
