import Call from "containers/Call/Call";
import DialoutCall from "containers/DialoutCall/DialoutCall";
import { DialoutCallsOnHold } from "containers/DialoutCall/DialoutCallsOnHold";
import React from "react";
import { useLocation } from "react-router-dom";
import MatrixUserProvider from "services/MatrixAPIProvider/providers/MatrixUserAPI";
import { useAppSelector } from "store/hooks";
import { getSipCallsHeld, selectDetachedSipCall, selectDialpadActive } from "store/selectors";
import { getProperCase } from "utils/helpers";

const CallPage = () => {
  const { getUser } = MatrixUserProvider();
  let location = useLocation();

  const dialpadActive = selectDialpadActive(location.pathname);
  const currentUser = useAppSelector((state) => state.matrix.currentUser);
  const detachedSipCall = useAppSelector((state) => selectDetachedSipCall(state, location.pathname));
  const sipCallsHeld = useAppSelector((state) => getSipCallsHeld(state, dialpadActive));
  const vidyoCallActive = useAppSelector((state) => state.call.active);
  const hasActiveMatrixCall = useAppSelector((state) => state.matrix.hasActiveMatrixCall);
  const vidyoCallLeaving = useAppSelector((state) => state.call.leaving);
  const vidyoOrMatrixCall = (vidyoCallActive || hasActiveMatrixCall) && !vidyoCallLeaving;
  const user = currentUser ? getUser(currentUser.userId) : null;
  return (
    <>
      {vidyoOrMatrixCall && <Call />}
      {detachedSipCall && <DialoutCall localUserName={user ? getProperCase(user.displayName) : ""} />}
      {sipCallsHeld.length > 0 && <DialoutCallsOnHold dialpadActive={dialpadActive} />}
    </>
  );
};

export default React.memo(CallPage);
