export interface IUserSettings_Notifications {
  disableJoinLeaveNotifications: boolean;
}

export interface IUserSettings_Application {
  keepRunningOnClose: boolean;
  appAutostart: boolean;
}

export interface IUserSettings {
  version: number;
  notifications: IUserSettings_Notifications;
  application: IUserSettings_Application;
}

export const USER_SETTINGS_VERSION = 2;

export class UserSettings implements IUserSettings {
  version: number;
  notifications: IUserSettings_Notifications;
  application: IUserSettings_Application;

  constructor() {
    this.version = USER_SETTINGS_VERSION;
    this.notifications = {
      disableJoinLeaveNotifications: false,
    };
    this.application = {
      keepRunningOnClose: false,
      appAutostart: false,
    };
  }
}
