import { getLogger } from "logger/appLogger";
import { ILogger } from "logger/logger";

// @ts-ignore
import ringtone from "assets/audio/Ringtone.mp3";
// @ts-ignore
import ringback from "assets/audio/matrix/ringback.mp3";
// @ts-ignore
import busy from "assets/audio/matrix/busy.mp3";
// @ts-ignore
import callEnd from "assets/audio/matrix/callend.mp3";
// @ts-ignore
import callWaiting from "assets/audio/CallWaiting.mp3";
// @ts-ignore
import error from "assets/audio/matrix/error.mp3";

const matrixAudioElements: HTMLAudioElement[] = [
  new Audio(ringtone),
  new Audio(ringback),
  new Audio(busy),
  new Audio(callEnd),
  new Audio(callWaiting),
  new Audio(error),
];

export enum Tones {
  Ringtone,
  Ringback,
  Busy,
  CallEnd,
  CallWaiting,
  Error,
}

export class SIPRinger {
  audioElements: HTMLAudioElement[];
  audioElement: HTMLAudioElement;
  speakerId: string | undefined = undefined;
  logger: ILogger = getLogger("sip.ringer");

  playPauseRaceConditionError: string = "The play() request was interrupted by a call to pause()";

  get playing() {
    return !this.audioElement.paused;
  }

  constructor() {
    this.audioElements = matrixAudioElements;
    this.audioElement = this.audioElements[0];
  }

  getTone(tone: Tones): HTMLAudioElement {
    return this.audioElements[tone];
  }

  async play(tone: Tones, sinkId: string | null = null) {
    const msg = "play";
    try {
      this.logger.debug(`${msg}: tone=${tone} sinkId=${sinkId} ${this}`);
      if (this.playing) {
        return;
      }

      this.audioElement = this.getTone(tone);

      this.audioElement.volume = 0.2;
      this.audioElement.loop = true;
      this.audioElement.currentTime = 0;

      if (sinkId && !this.audioElement.setSinkId) {
        throw new Error(`SetSinkId broken`);
      }
      if (sinkId && this.audioElement.setSinkId) {
        await this.audioElement.setSinkId(sinkId);
      }

      this.audioElement.load();
      await this.audioElement.play();
    } catch (e) {
      if (e.message.includes(this.playPauseRaceConditionError)) {
        this.logger.debug(`${msg}: detected '${this.playPauseRaceConditionError}', stopping`);
        this.audioElement.pause();
      } else {
        this.logger.error(`${msg}: failed: ${e}`);
      }
    }
  }

  stop() {
    const msg = "stop";
    try {
      this.logger.debug(`${msg}: ${this}`);
      this.audioElement.pause();
    } catch (e) {
      this.logger.error(`${msg}: failed: ${e}`);
    }
  }

  toString(): string {
    return `(playing=${this.playing})`;
  }
}
