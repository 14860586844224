import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { getLogger } from "logger/appLogger";
import styled from "themes/theme/baseTheme";
import AvatarWithLabels from "components/_shared/AvatarWithLabels";
import Dialog from "components/_shared/Dialog";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { filterList, getProperCase, test } from "utils/helpers";
import { LocalMatrixRoom, MatrixUser, StatusValues } from "types/Matrix";
import { useMatrixRooms, useMatrixAvatar } from "hooks";
import SearchField from "components/_shared/SearchField";
import { useMemo, useState } from "react";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "350px",
    height: "750px",
  },
}));

interface MessageForwardDialogProps {
  filteredRoomList: LocalMatrixRoom[];
  openDialog: boolean;
  handleDialogClose: () => void;
  handleDialogAgree: (selectedRoom: LocalMatrixRoom) => void;
  errorMessage?: string | null;
}

const MessageForwardDialog = ({
  filteredRoomList,
  openDialog,
  handleDialogClose,
  handleDialogAgree,
}: MessageForwardDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const logger = useMemo(() => getLogger("forward-dialog"), []);

  const { isDirect, isPrivate, isGroup, generateRoomName } = useMatrixRooms();
  const { getRoomAvatar, getRoomMemberAvatar } = useMatrixAvatar();

  const [currentRoomList, setCurrentRoomList] = useState(filteredRoomList);
  const [searchValue, setSearchValue] = useState<string>("");

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, room: LocalMatrixRoom) => {
    handleDialogAgree(room);
  };

  const handleSearchRoom = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    try {
      const value = e.target.value;
      setSearchValue(value);
      if (value.length <= 0) {
        setCurrentRoomList(filteredRoomList);
        return;
      }
      const filteredMemberList = [...filteredRoomList];
      setCurrentRoomList(filterList(value, filteredMemberList, "name"));
    } catch (e) {
      logger.error(`${module}: Error in handleSearchRoom - event:${e} - ${e.message}`);
    }
  };

  return (
    <StyledDialog
      id={"message-forward-dialog"}
      dialogTitle={t("FORWARD_TO")}
      showIconClose={true}
      onClose={handleDialogClose}
      open={openDialog}
      {...test("MESSAGE_FORWARD_DIALOG")}
    >
      <div style={{ paddingBottom: "2px" }} {...test("MESSAGE_FORWARD_SEARCH")}>
        <SearchField
          defaultValue={searchValue}
          onChange={handleSearchRoom}
          size="small"
          sx={{ height: "36px", width: "100%", marginBottom: "0px" }}
        />
      </div>
      {currentRoomList.length > 0 && (
        <List disablePadding aria-label="member list" className="scroll-bar" {...test("MESSAGE_FORWARD_LIST")}>
          {currentRoomList.map((room) => {
            let avatar = null;
            let presenceStatus = "",
              presenceStatusMsg = "";
            const isDirectRoom = isDirect(room.roomId);
            if (isDirectRoom) {
              const dmUserId = room.guessDMUserId();
              const member = room ? room.getMember(dmUserId) : null;
              const dmUser: MatrixUser | null | undefined = member ? member.user : null;
              if (dmUser) {
                presenceStatus = dmUser.presenceStatus ?? StatusValues.offline.toString();
                presenceStatusMsg = dmUser.presenceStatusMsg ?? getProperCase(presenceStatus);
              }
              avatar = getRoomMemberAvatar(room.roomId, dmUserId);
            } else avatar = getRoomAvatar({ roomId: room.roomId });
            const roomName = generateRoomName(room);
            return (
              <ListItemButton onClick={(e) => handleListItemClick(e, room)} key={room.roomId} sx={{ padding: "5px 0" }}>
                <AvatarWithLabels
                  id={`message-forward-avatar-${room.roomId}`}
                  src={avatar}
                  name={roomName}
                  disableBadge={(!isDirectRoom && !isPrivate(room.roomId)) || isGroup(room.roomId)}
                  isPerson={isDirectRoom}
                  isPrivateRoom={isPrivate(room.roomId) && !isGroup(room.roomId)}
                  isGroupRoom={isGroup(room.roomId)}
                  status={presenceStatus}
                  statusMsg={presenceStatusMsg}
                  sx={{ border: `1px solid ${avatar ? "transparent" : theme.palette.neutrals.lightGrey3}` }}
                />
              </ListItemButton>
            );
          })}
        </List>
      )}
    </StyledDialog>
  );
};

export default MessageForwardDialog;
