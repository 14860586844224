import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getLogger } from "logger/appLogger";

import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SearchField from "components/_shared/SearchField";
import SearchUsersAutocomplete, { ParamsWithInputAdornment } from "components/_shared/SearchUsersAutocomplete";

import { useSearchProvisioning } from "hooks";
import { test, sortByLetters } from "utils/helpers";
import { SearchData } from "types/Matrix";
import { RoomMember } from "matrix-js-sdk";

interface InviteToRoomDialogProps {
  openDialog: boolean;
  isInviting: boolean;
  memberList: RoomMember[];
  handleDialogClose: () => void;
  handleDialogAgree: (list: string[]) => void;
  errorMessage?: string | null;
}

const InviteToRoomDialog = ({
  openDialog,
  isInviting,
  errorMessage,
  handleDialogClose,
  handleDialogAgree,
  memberList,
}: InviteToRoomDialogProps) => {
  const { t } = useTranslation();
  const logger = useMemo(() => getLogger("matrix"), []);
  const module = "InviteToRoomDialog";

  const { searchResult, search, loading } = useSearchProvisioning();

  const [selectedParticipants, setSelectedParticipants] = useState<SearchData[]>([]);
  const [suggestedUsers, setSuggestedUsers] = useState<SearchData[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [openAlert, setopenAlert] = useState(false);

  const handleInput = (value: string) => {
    try {
      setSearchValue(value);

      if (!value || !value.length) {
        return;
      }

      const members = memberList.map((member) => member.userId);
      search(value, members);
    } catch (e) {
      logger.error(`${module}: Error in handleInput - value:${value} - ${e.message}`);
    }
  };

  const handleItemClick = (newValue: SearchData[]) => {
    setSelectedParticipants(newValue);
    setSearchValue("");
  };

  const handleInvite = () => {
    const list: string[] = [];
    selectedParticipants.forEach((u) => {
      list.push(u.userId);
    });
    handleDialogAgree(list);
  };

  useEffect(() => {
    setSuggestedUsers([]);
    setopenAlert(false);
  }, [openDialog]);

  useEffect(() => {
    setopenAlert(Boolean(errorMessage));
  }, [errorMessage]);

  useEffect(() => {
    if (!searchValue || searchValue.length === 0) {
      setSuggestedUsers([]);
      return;
    }
  }, [searchValue]);

  useEffect(() => {
    setSuggestedUsers(searchResult.sort((a, b) => sortByLetters(a.displayName, b.displayName)));
  }, [searchResult]);

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="upload confirmation"
      open={openDialog}
      PaperProps={{ sx: { width: "400px" } }}
      {...test("INVITE_ROOM_DIALOG")}
    >
      <DialogTitle id="simple-dialog-title">{t("ADDMEMBERS")}</DialogTitle>
      <DialogContent>
        <Collapse in={openAlert}>
          <Alert onClose={() => setopenAlert(false)} severity="error" style={{ marginBottom: "1rem" }}>
            <AlertTitle>{t("FAILED_ADD_USERS")}</AlertTitle>
            {errorMessage}
          </Alert>
        </Collapse>
        <SearchUsersAutocomplete
          id="invite-to-room"
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          multiple
          loading={loading}
          options={suggestedUsers}
          isOptionEqualToValue={(option, value) => option.userId === value.userId}
          getOptionLabel={(option) => (option as SearchData).displayName}
          filterSelectedOptions
          renderInput={(params) => (
            <SearchField
              {...ParamsWithInputAdornment(params)}
              size="small"
              placeholder={selectedParticipants.length === 0 ? t("SEARCH") : ""}
            />
          )}
          onInputChange={(event, value, reason) => {
            let newValue = reason === "reset" ? "" : value;
            handleInput(newValue);
          }}
          onChange={(event, newValue, reason) => {
            handleItemClick(newValue as SearchData[]);
          }}
          sx={{ margin: "10px 26px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          id="invite_toroom_dialog_cancel_button"
          variant="outlined"
          onClick={handleDialogClose}
          disabled={isInviting}
        >
          {t("CANCEL")}
        </Button>
        <Button
          id="invite_toroom_dialog_add_button"
          autoFocus
          onClick={handleInvite}
          disabled={isInviting || selectedParticipants.length <= 0}
        >
          {t("ADDMEMBER")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteToRoomDialog;
