import { ColoursSentiment, ColoursVariants } from "../../../theme/types";

const sentiment: { [key in ColoursSentiment]: ColoursVariants } = {
  inform: {
    light1: "#EBF2FF",
    light2: "#ADCAFF",
    base: "#1F6DFF",
    dark1: "#004FE0",
    dark2: "#00153B",
  },
  success: {
    light1: "#D6FFE9",
    light2: "#85FFBE",
    base: "#00C65E",
    dark1: "#006630",
    dark2: "#002913",
  },
  warning: {
    light1: "#FFEBD6",
    light2: "#FFCE99",
    base: "#FF9628",
    dark1: "#A35400",
    dark2: "#291500",
    yellow: "#E4CF0F",
  },
  error: {
    light1: "#FCEAEA",
    light2: "#F19393",
    base: "#E42C2C",
    dark1: "#A21515",
    dark2: "#360707",
  },
};

export type Sentiment = typeof sentiment;

export default sentiment;
