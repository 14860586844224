import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useState } from "react";
import { styled } from "@mui/material";

const StyledSnackbar = styled(Snackbar)<SnackbarProps>(({ theme }) => ({
  padding: 0,
  position: "absolute",
  "& .MuiSnackbarContent-message": { padding: "0 10px" },
  "& .MuiSnackbar-anchorOriginBottomCenter": {
    borderRadius: 4,
    minWidth: 200,
    maxWidth: 320,
    lineHeight: 1,
  },
}));

interface RecorderStatusProps {
  status: string;
  error?: boolean;
  onCloseCallback?: () => void;
}

const RecorderStatus = ({ status, error = false, onCloseCallback }: RecorderStatusProps) => {
  const [open, setOpen] = useState(true);
  const duration = 5000;

  return open ? (
    <StyledSnackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={() => {
        setOpen(false);
        if (onCloseCallback) onCloseCallback();
      }}
      open={open}
      autoHideDuration={duration}
    >
      <Alert severity={error ? "error" : "info"} variant="outlined" sx={{ width: "100%" }}>
        {status}
      </Alert>
    </StyledSnackbar>
  ) : (
    <></>
  );
};

export default RecorderStatus;
