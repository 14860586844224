import * as actionTypes from "../actions/types/sip";
import { SipState } from "../types/sip";
import { Types } from "Types";

const initialState: SipState = {
  sipCalls: [],
  canInitateSipCall: true,
  savedSpeedDial: [],
  sipEnabled: false,
  sipRegistered: false,
  sipCallError: null,
  incomingSipCalls: [],
  localPeer: null,
};

const sipReducer = (state = initialState, action: Types.RootAction): SipState => {
  switch (action.type) {
    case actionTypes.START_SIP_UA_SUCCEEDED:
      return {
        ...state,
        sipEnabled: true,
        localPeer: { ...action.localPeer },
      };
    case actionTypes.START_SIP_UA_FAILED:
      return {
        ...state,
        sipEnabled: false,
        sipCallError: action.error,
      };
    case actionTypes.STOP_SIP_UA_SUCCEEDED:
      return {
        ...state,
        sipEnabled: false,
      };
    case actionTypes.STOP_SIP_UA_FAILED:
      return {
        ...state,
        sipEnabled: false,
        sipCallError: action.error,
      };
    case actionTypes.UPDATE_SIP_CALLS: {
      return {
        ...state,
        sipCalls: action.sipCalls,
        canInitateSipCall: action.canInitateSipCall,
      };
    }
    case actionTypes.UPDATE_SIP_REGISTER_STATE: {
      return {
        ...state,
        sipRegistered: action.registered,
      };
    }
    case actionTypes.START_SIP_CALL_SUCCEEDED: {
      return {
        ...state,
      };
    }
    case actionTypes.START_SIP_CALL_FAILED: {
      return {
        ...state,
        sipCallError: action.error,
      };
    }
    case actionTypes.ACCEPT_SIP_CALL_SUCCEEDED:
      return {
        ...state,
        incomingSipCalls: state.incomingSipCalls.filter((call) => call.id !== action.callId),
      };
    case actionTypes.REJECT_SIP_CALL:
      return {
        ...state,
        incomingSipCalls: state.incomingSipCalls.filter((call) => call.id !== action.callId),
      };
    case actionTypes.SIP_CALL_ADD_SPEEDDIAL:
      return {
        ...state,
        savedSpeedDial: [...state.savedSpeedDial, ...action.payload],
      };
    case actionTypes.END_SIP_CALL_SUCCEEDED:
      return {
        ...state,
        incomingSipCalls: state.incomingSipCalls.filter((call) => call.id !== action.callId),
      };
    case actionTypes.SIP_CALL_ACTION_FAILED:
      return {
        ...state,
        sipCallError: action.error,
      };
    case actionTypes.GET_SIP_CALL_INFO_SUCCEEDED:
      return {
        ...state,
        incomingSipCalls: [...state.incomingSipCalls, action.incomingSipCalls],
      };
    default:
      return state;
  }
};

export default sipReducer;
