import { EndpointData } from "services/ProvisioningApiProvider/types/Provisioning";
import * as actionTypes from "../actions/types/provisioning";
import { CreateRoomData, CreateGroupChatData, UpdateRoomData } from "../types/provisioning";

export const provisioningActionsCreator = {
  getProvisioningConfig: () => ({
    type: actionTypes.GET_PROVISIONING_CONFIG,
  }),
  setProvisioiningEndpointData: (payload: EndpointData) => ({
    type: actionTypes.SET_ENDPOINT_DATA,
    payload,
  }),
  searchProvisioiningUser: (payload: string) => ({
    type: actionTypes.SEARCH_PROVISIONING_USER,
    payload,
  }),
  resetProvisioiningSearch: () => ({
    type: actionTypes.SEARCH_PROVISIONING_USER_RESET,
  }),
  searchProvisioiningByUsername: (payload: string) => ({
    type: actionTypes.SEARCH_BY_USERNAME_PROVISIONING_USER,
    payload,
  }),
  searchProvisioiningByListOfUsernames: (payload: string[]) => ({
    type: actionTypes.SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS,
    payload,
  }),
  resetProvisioiningSearchByListUsernames: () => ({
    type: actionTypes.SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS_RESET,
  }),
  searchProvisioiningByListOfEmails: (payload: string[]) => ({
    type: actionTypes.SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS,
    payload,
  }),
  resetProvisioiningSearchByListEmails: () => ({
    type: actionTypes.SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS_RESET,
  }),
  createRoom: (payload: CreateRoomData) => ({
    type: actionTypes.CREATE_ROOM,
    payload,
  }),
  createGroupChatRoom: (payload: CreateGroupChatData) => ({
    type: actionTypes.CREATE_GROUP_CHAT_ROOM,
    payload,
  }),
  updateRoom: (payload: UpdateRoomData) => ({
    type: actionTypes.UPDATE_ROOM,
    payload,
  }),
  updateGroupChatRoom: (payload: UpdateRoomData) => ({
    type: actionTypes.UPDATE_GROUP_CHAT_ROOM,
    payload,
  }),
  clearUpdateRoomError: () => ({
    type: actionTypes.CLEAR,
  }),
} as const;
