import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/root-reducer";
import { useTranslation } from "react-i18next";
import { getCallAPIProvider } from "services/CallAPIProvider/CallAPIProvider";
import { LocalShare } from "./Templates/LocalShare";
import { RemoteShare } from "./Templates/RemoteShare";
import { RemoteCamera } from "./Templates/RemoteCamera";
import { RemoteView } from "./Templates/RemoteView";
import { LocalCamera } from "./Templates/LocalCamera";
import { Self } from "./Templates/SelfView";
import Tooltip from "@mui/material/Tooltip";
import { CallMediaType, TileData } from "types/UC";
import { CustomStyleToExtStream } from "services/CustomRenderer";
import "./layout.scss";

const Layout = ({ tiles }: { tiles: TileData[] }) => {
  const MAX_TILES = 9;
  const [selfView, setSelfView] = useState<TileData | null>(null);
  const [localShare, setLocalShare] = useState<TileData | null>(null);
  const [remoteShare, setRemoteShare] = useState<TileData | null>(null);
  const [selfViewState, setSelfViewState] = useState("visible");
  const [nTiles, setNTiles] = useState(0);
  const [mode, setMode] = useState("grid");
  const [modePreSharing, setModePreSharing] = useState("grid");
  const [isSharing, setIsSharing] = useState(false);
  const { t } = useTranslation();

  const isCallActive = useSelector((state: RootState) => state.call.active);
  const hasActiveMatrixCall = useSelector((state: RootState) => state.matrix.hasActiveMatrixCall);
  const inMatrixScreenShare = useSelector((state: RootState) => state.matrix.inMatrixScreenShare);
  const inMatrixRemoteScreenShare = useSelector((state: RootState) => state.matrix.inMatrixRemoteScreenShare);

  const callProvider = getCallAPIProvider();
  const { AssignViewToExtStream } = CustomStyleToExtStream();

  useEffect(() => {
    if (tiles) {
      if (tiles.length > 0 && tiles[0].isSelfView) setSelfView(tiles[0]);

      let participants = tiles.length;

      // handle changes on vidyo local and remote shares
      if (tiles.length > 1 && isCallActive) {
        if (tiles[1].type === "localWindowShare") {
          setLocalShare(tiles[1]);
          participants = participants - 1;
        } else setLocalShare(null);
        if (tiles[1].type === "remoteWindowShare") {
          setRemoteShare(tiles[1]);
          participants = participants - 1;
        } else setRemoteShare(null);
      } else setLocalShare(null);

      setNTiles(participants < MAX_TILES ? participants : MAX_TILES);

      // hide own tile when sharing screen
      if (localShare && mode === "grid") {
        setSelfViewState("hidden");
      }
      onLayoutReady(tiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tiles]);

  useEffect(() => {
    if (tiles) {
      // handle changes on matrix local and remote shares
      if (tiles.length > 1 && hasActiveMatrixCall) {
        if (tiles[0].isSelfView && inMatrixScreenShare) {
          setIsSharing(true);
        }
        if (!tiles[1].isSelfView && inMatrixRemoteScreenShare) {
          setIsSharing(true);
        }
        if (!inMatrixScreenShare && !inMatrixRemoteScreenShare) {
          setIsSharing(false);
        }
      } else setIsSharing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inMatrixScreenShare, inMatrixRemoteScreenShare]);

  useEffect(() => {
    if (remoteShare) {
      setIsSharing(true);
    }
    if (localShare) {
      setIsSharing(true);
    }
    if (!remoteShare && !localShare) {
      setIsSharing(false);
    }
    return () => {
      setIsSharing(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteShare, localShare]);

  useEffect(() => {
    if (isSharing) {
      setModePreSharing(mode);

      if (mode === "grid") {
        setMode("strip");
      }
    } else {
      setMode(modePreSharing);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSharing]);

  const isIn169 = () => {
    return mode === "grid" && nTiles === 2;
  };

  /**
   * Assign views to video streams
   */
  const onLayoutReady = (data: TileData[]) => {
    if (!data) return;
    data.forEach((tile) => {
      if (tile.isMatrixTile) {
        AssignViewToExtStream(tile.id, tile.isSelfView, tile.type);
        return;
      }
      if (tile.stream && tile.id !== "self_view" && tile.id !== "local_self_view") {
        callProvider.assignViewToStream({
          id: tile.id,
          mediaObject: tile.stream,
          mediaType: tile.type,
        });
      }
    });
  };

  /**
   * Calulate css class based on the number of tiles
   */
  const getTileGridClass = (length: number) => {
    switch (length) {
      case 1:
      case 2:
        return length;
      case 3:
      case 4:
        return 2;
      default:
        return 3;
    }
  };

  /**
   * Filter tiles to show only what's needed
   */
  const tilesFiltered = (): any[] => {
    if (mode === "strip") {
      if (localShare) {
        let newTiles = tiles.slice();
        return [newTiles[1], newTiles[0]].concat(newTiles.slice(2));
      }
      return tiles;
    }
    if (remoteShare)
      return tiles.filter((x: { id: any }) => x.id !== remoteShare.stream.participant.userId).slice(0, nTiles);
    if (localShare) return tiles.slice(1, nTiles + 1);
    return tiles.slice(0, nTiles);
  };

  /**
   * Compute element tile list to render
   */
  const tree = () => {
    let result: any[] = [];
    tilesFiltered().map((tile: any, index: number) => {
      if (!tile) return <></>;
      if (tile.isSelfView) {
        if (tile.type === CallMediaType.localCamera)
          return result.push(
            <LocalCamera key={tile.id} tile={tile} selfViewState={selfViewState} you={t("YOU")} in169={isIn169()} />
          );
        else
          return result.push(
            <Self key="self" tile={tile} selfViewState={selfViewState} you={t("YOU")} in169={isIn169()} />
          );
      }
      if (tile.type === CallMediaType.localWindowShare)
        return result.push(<LocalShare key="local_share" tile={tile} name={selfView?.name} you={t("YOU")} />);
      if (tile.type === CallMediaType.remoteWindowShare)
        return result.push(<RemoteShare key="remote_share" tile={tile} />);
      if (!tile.isMatrixTile && tile.type === CallMediaType.remoteCamera)
        return result.push(<RemoteCamera key={`${tile.id}_rc`} tile={tile} index={index} in169={isIn169()} />);
      if (tile.isMatrixTile || tile.type === CallMediaType.audio)
        return result.push(<RemoteView key={tile.id} tile={tile} index={index} in169={isIn169()} />);
      return null;
    });
    return result;
  };

  return (
    <div className={`compositor-container ${mode} ${localShare && "local-share"} ${remoteShare && "remote-share"}`}>
      <div
        className={`compositor-${mode} tiles-${getTileGridClass(nTiles)} ${isIn169() ? "grid-aspect-ration-16-9" : ""}`}
      >
        {tree()}
        {!isSharing ? (
          <div key="toggle" className="toggle-layout">
            <div className="layout-mode-selector">
              <div
                key="strip"
                className={`layout-mode-strip ${mode === "strip" ? "active" : ""}`}
                onClick={() => setMode("strip")}
              ></div>
              <div
                key="grid"
                className={`layout-mode-grid ${mode === "grid" ? "active" : ""}`}
                onClick={() => setMode("grid")}
              ></div>
            </div>
          </div>
        ) : (
          <div key="toggle" className="toggle-layout">
            <Tooltip title={t("DISABLED_SHARING")} arrow>
              <div className="layout-mode-selector">
                <div key="strip" className={`layout-mode-strip active`}></div>
                <div key="grid" className={`layout-mode-grid`}></div>
              </div>
            </Tooltip>
          </div>
        )}
        {mode === "strip" && <div key="footer" className="footer"></div>}
      </div>
    </div>
  );
};

export default Layout;
