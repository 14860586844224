import { IContent, MatrixClient, getHttpUriForMxc } from "matrix-js-sdk";
import mxClient from "matrix/matrix";
import { getLogger } from "logger/appLogger";
import { useEffect, useRef } from "react";

export const MatrixUserProvider = () => {
  const client: MatrixClient = mxClient.client;
  const logger = getLogger("matrix");
  const module = "MatrixUserApi";
  const mountedRef = useRef(true);

  /** Method to get the user info from matrix
   * --> Don't use to get the avatarUrl
   */
  const getUser = (userId: string) => {
    try {
      const user = client.getUser(userId);
      logger.debug(`${module}: getUser - userId:${userId} user:${user}`);
      return user;
    } catch (e) {
      logger.error(`${module}: Error in getUser - userId:${userId} - ${e.message}`);
      return null;
    }
  };

  /** Method to get the list of users info from matrix
   * --> Don't use to get the avatarUrl
   */
  const getUsers = () => {
    try {
      const users = client.getUsers();
      logger.debug(`${module}: getUsers - users:${users}`);
      return users;
    } catch (e) {
      logger.error(`${module}: Error in getUsers - ${e.message}`);
      return [];
    }
  };

  const getAccountData = (type: string) => {
    try {
      const result = client.getAccountData(type);
      logger.debug(`${module}: getAccountData - type:${type} result:${result}`);
      return result;
    } catch (e) {
      logger.error(`${module}: Error in getAccountData - type:${type} - ${e.message}`);
      throw e;
    }
  };

  const getAccountDataFromServer = async (type: string) => {
    try {
      const result = await client.getAccountDataFromServer(type);
      logger.debug(`${module}: getAccountDataFromServer - type:${type} result:${result}`);
      return result;
    } catch (e) {
      logger.error(`${module}: Error in getAccountDataFromServer - type:${type} - ${e.message}`);
      throw e;
    }
  };

  const setAccountData = async (type: string, data: IContent) => {
    try {
      const result = await client.setAccountData(type, data);
      logger.debug(`${module}: setAccountData - type:${type}, data:${data} - result:${result}`);
    } catch (e) {
      logger.error(`${module}: Error in setAccountData - type:${type}, data:${data} - ${e.message}`);
      throw e;
    }
  };

  const getUserProfileInfo = async (userId: string) => {
    try {
      logger.debug(`${module}: getUserProfileInfo - userId:${userId}`);
      return await client.getProfileInfo(userId);
    } catch (e) {
      logger.error(`${module}: Error in getUserProfileInfo - userId:${userId} - ${e.message}`);
      throw e;
    }
  };

  const getUserDisplayName = async (userId: string) => {
    try {
      logger.debug(`${module}: getUserDisplayName - userId:${userId}`);
      const res = await client.getProfileInfo(userId, "displayname");
      return res.displayname;
    } catch (e) {
      logger.error(`${module}: Error in getUserDisplayName - userId:${userId} - ${e.message}`);
      throw e;
    }
  };

  const getUserAvatarUrl = async (userId: string) => {
    try {
      logger.debug(`${module}: getUserAvatarUrl - userId:${userId}`);
      const res = await client.getProfileInfo(userId, "avatar_url");
      //@ts-ignore
      const baseUrl = client.baseUrl;
      return res.avatar_url ? getHttpUriForMxc(baseUrl, res.avatar_url) ?? "" : "";
    } catch (e) {
      logger.error(`${module}: Error in getUserAvatarUrl - userId:${userId} - ${e.message}`);
      throw e;
    }
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return {
    getAccountData,
    setAccountData,
    getAccountDataFromServer,
    getUser,
    getUserDisplayName,
    getUserAvatarUrl,
    getUserProfileInfo,
    getUsers,
  };
};

export default MatrixUserProvider;
