import withStyles, { ClassNameMap } from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";

const styles = {
  footer: {
    margin: "0.5rem 0",
  },
  footerLabel: {
    color: "#757575",
    padding: "0.5rem",
  },
  footerSeparator: {
    flex: "1",
    opacity: "0.4",
    borderBottom: "1px solid #d8d8e8",
    margin: "0.5rem 0",
  },
};

const CustomFooter = withStyles(styles)(
  ({ classes, label }: { classes: Partial<ClassNameMap<keyof typeof styles>>; label: string }) => {
  return (
    <div className={classes.footer}>
      {label && (
        <Typography variant="caption" className={classes.footerLabel}>
          {label}
        </Typography>
      )}
      <div className={classes.footerSeparator} />
    </div>
  );
});

export default CustomFooter;
