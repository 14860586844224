import * as actionTypes from "../actions/types/devices";

export const updateCameras = (cameras) => ({
  type: actionTypes.UPDATE_CAMERAS,
  cameras,
});

export const updateRemoteCameras = (remoteCameras) => ({
  type: actionTypes.UPDATE_REMOTE_CAMERAS,
  remoteCameras,
});

export const updateMicrophones = (microphones) => ({
  type: actionTypes.UPDATE_MICROPHONES,
  microphones,
});

export const updateRemoteMicrophones = (remoteMicrophones) => ({
  type: actionTypes.UPDATE_REMOTE_MICROPHONES,
  remoteMicrophones,
});

export const updateSpeakers = (speakers) => ({
  type: actionTypes.UPDATE_SPEAKERS,
  speakers,
});

export const cameraTurnOn = (payload) => ({
  type: actionTypes.CAMERA_TURN_ON,
  payload,
});

export const cameraTurnOff = (payload) => ({
  type: actionTypes.CAMERA_TURN_OFF,
  payload,
});

export const cycleCamera = (payload) => ({
  type: actionTypes.CYCLE_CAMERA,
  payload,
});

export const subscribeOnCamerasChanges = () => ({
  type: actionTypes.CAMERAS_CHANGES_SUBSCRIBE,
});

export const unsubscribeFromCamerasChanges = () => ({
  type: actionTypes.CAMERAS_CHANGES_UNSUBSCRIBE,
});

export const subscribeOnRemoteCamerasChanges = () => ({
  type: actionTypes.REMOTE_CAMERAS_CHANGES_SUBSCRIBE,
});

export const unsubscribeFromRemoteCamerasChanges = () => ({
  type: actionTypes.REMOTE_CAMERAS_CHANGES_UNSUBSCRIBE,
});

export const microphoneTurnOn = () => ({
  type: actionTypes.MICROPHONE_TURN_ON,
});

export const microphoneTurnOff = () => ({
  type: actionTypes.MICROPHONE_TURN_OFF,
});

export const subscribeOnMicrophonesChanges = () => ({
  type: actionTypes.MICROPHONES_CHANGES_SUBSCRIBE,
});

export const unsubscribeFromMicrophonesChanges = () => ({
  type: actionTypes.MICROPHONES_CHANGES_UNSUBSCRIBE,
});

export const subscribeOnRemoteMicrophonesChanges = () => ({
  type: actionTypes.REMOTE_MICROPHONES_CHANGES_SUBSCRIBE,
});

export const unsubscribeFromRemoteMicrophonesChanges = () => ({
  type: actionTypes.REMOTE_MICROPHONES_CHANGES_UNSUBSCRIBE,
});

export const speakerTurnOn = () => ({
  type: actionTypes.SPEAKER_TURN_ON,
});

export const speakerTurnOff = () => ({
  type: actionTypes.SPEAKER_TURN_OFF,
});

export const subscribeOnSpeakersChanges = () => ({
  type: actionTypes.SPEAKERS_CHANGES_SUBSCRIBE,
});

export const unsubscribeFromSpeakersChanges = () => ({
  type: actionTypes.SPEAKERS_CHANGES_UNSUBSCRIBE,
});

export const selectCamera = (localCamera) => ({
  type: actionTypes.SELECT_CAMERA,
  localCamera,
});

export const selectMicrophone = (localMicrophone) => ({
  type: actionTypes.SELECT_MICROPHONE,
  localMicrophone,
});

export const selectSpeaker = (localSpeaker) => ({
  type: actionTypes.SELECT_SPEAKER,
  localSpeaker,
});

export const disableCamera = (disableReason) => ({
  type: actionTypes.DISABLE_CAMERA,
  disableReason,
});

export const disableMicrophone = (disableReason) => ({
  type: actionTypes.DISABLE_MICROPHONE,
  disableReason,
});

export const disableSpeaker = (disableReason) => ({
  type: actionTypes.DISABLE_SPEAKER,
  disableReason,
});

export const enableCamera = (disableReason) => ({
  type: actionTypes.ENABLE_CAMERA,
  disableReason,
});

export const enableMicrophone = (disableReason) => ({
  type: actionTypes.ENABLE_MICROPHONE,
  disableReason,
});

export const enableSpeaker = (disableReason) => ({
  type: actionTypes.ENABLE_SPEAKER,
  disableReason,
});
