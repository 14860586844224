import * as actionTypes from "../actions/types/auth";

export const login = () => {
  return { type: actionTypes.AUTH_LOGIN };
};

export const logout = () => {
  return { type: actionTypes.AUTH_LOGOUT };
};

export const authActions = {
  login: login,
  logout: logout,
};
