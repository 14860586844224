import { Components, Theme } from "@mui/material";
import { formHelperTextClasses } from "@mui/material/FormHelperText";

const MuiFormHelperText: Components["MuiFormHelperText"] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const palette = (theme as Theme).palette;
      return {
        [`&.${formHelperTextClasses.error}`]: {
          color: palette.text.error,
        },
        [`&.${formHelperTextClasses.disabled}`]: {
          color: palette.text.disabled,
        },
      };
    },
  },
};

export default MuiFormHelperText;
