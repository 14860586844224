import * as actionTypes from "../actions/types/config";

export const setExtData = (payload) => ({
  type: actionTypes.SET_EXT_DATA,
  payload,
});

export const setStatisticsOverlay = (show) => ({
  type: actionTypes.SET_STATISTICS_OVERLAY,
  show,
});
