export const START_SIP_UA = "START_SIP_UA";
export const RESTART_SIP_UA = "RESTART_SIP_UA";
export const START_SIP_UA_SUCCEEDED = "START_SIP_UA_SUCCEEDED";
export const START_SIP_UA_FAILED = "START_SIP_UA_FAILED";

export const STOP_SIP_UA = "STOP_SIP_UA";
export const STOP_SIP_UA_SUCCEEDED = "STOP_SIP_UA_SUCCEEDED";
export const STOP_SIP_UA_FAILED = "STOP_SIP_UA_FAILED";

export const START_SIP_CALL = "START_SIP_CALL";
export const START_SIP_CALL_SUCCEEDED = "START_SIP_CALL_SUCCEEDED";
export const START_SIP_CALL_FAILED = "START_SIP_CALL_FAILED";

export const ACCEPT_SIP_CALL = "ACCEPT_SIP_CALL";
export const ACCEPT_SIP_CALL_SUCCEEDED = "ACCEPT_SIP_CALL_SUCCEEDED";

export const REJECT_SIP_CALL = "REJECT_SIP_CALL";
export const REJECT_SIP_CALL_SUCCEEDED = "REJECT_SIP_CALL_SUCCEEDED";

export const END_SIP_CALL = "END_SIP_CALL";
export const END_SIP_CALL_SUCCEEDED = "END_SIP_CALL_SUCCEEDED";

export const SIP_CALL_ADD_SPEEDDIAL = "SIP_CALL_ADD_SPEEDDIAL";

export const UPDATE_SIP_CALLS = "UPDATE_SIP_CALLS";

export const UPDATE_SIP_REGISTER_STATE = "UPDATE_SIP_REGISTER_STATE";

export const UPDATE_SIP_CALL_INFO = "UPDATE_SIP_CALL_INFO";
export const UPDATE_SIP_CONFERENCE_INFO = "UPDATE_SIP_CONFERENCE_INFO";
export const UPDATE_SIP_STATE_INFO = "UPDATE_SIP_STATE_INFO";

export const SIP_CALL_ACTION_FAILED = "SIP_CALL_ACTION_FAILED";

export const GET_SIP_CALL_INFO = "GET_SIP_CALL_INFO";
export const GET_SIP_CALL_INFO_SUCCEEDED = "GET_SIP_CALL_INFO_SUCCEEDED";
