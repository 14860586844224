import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import styles from "../Messages.module.scss";
import Typography from "@mui/material/Typography";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import { convertBytes } from "matrix/message";
import { test } from "utils/helpers";

const File = ({ content, contentSrc }: { content: any; contentSrc: string | undefined }) => {
  const [src] = useState(contentSrc);
  const theme = useTheme();
  const { t } = useTranslation();

  let fileName = "";
  const regex = /(> ?.*: )?(?<file>.*)\..*/;
  const lineBreakIndex = content.body.search(/\r?\n/g);
  let match = null;
  if (lineBreakIndex > 0) {
    const firstPart = content.body.slice(0, lineBreakIndex);
    match = firstPart.match(regex);
  } else {
    match = content.body.match(regex);
  }

  if (match) {
    const groups = match ? match.groups : null;
    if (groups) {
      fileName = groups.file;
    }
  }

  const customFileIcon = (type: string, color: string) => {
    return (
      <div
        style={{
          height: "59px",
          width: "51px",
          borderRadius: "8px",
          backgroundColor: color,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="subText"
          sx={{ fontSize: "15px", lineHeight: "normal", color: `${theme.palette.common.white} !important` }}
        >
          {type.toUpperCase()}
        </Typography>
      </div>
    );
  };

  const getFileIcon = (type: string | undefined) => {
    // first convert type to recognized and common types
    type = type?.includes("image/") ? "image" : type;
    type = type?.includes("audio/") ? "audio" : type;
    type = type?.includes("video/") ? "video" : type;
    const iconStyle = { fontSize: "3.4rem", color: "var(--icon-color)" };
    switch (type) {
      case "image":
        return <ImageIcon sx={{ ...iconStyle, marginTop: "-4px" }} />;
      case "audio":
        return <AudioFileIcon sx={iconStyle} />;
      case "video":
        return <VideoFileIcon sx={iconStyle} />;
      case "application/pdf":
        return customFileIcon("pdf", "var(--pdf-icon-color)");
      // DOC section
      case "text/plain":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/msword":
      case "application/vnd.oasis.opendocument.text":
      case "application/rtf":
        return customFileIcon("doc", "var(--doc-icon-color)");
      // XLS section
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.oasis.opendocument.spreadsheet":
      case "application/vnd.ms-excel":
        return customFileIcon("xls", "var(--xls-icon-color)");
      // ZIP sction
      case "application/x-zip-compressed":
      case "application/zip":
      case "application/x-7z-compressed":
      case "application/gzip":
        return customFileIcon("zip", "var(--zip-icon-color)");
      //PPT section
      case "application/vnd.oasis.opendocument.presentation":
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return customFileIcon("ppt", "var(--ppt-icon-color)");
      default:
        return <InsertDriveFileIcon sx={iconStyle} />;
    }
  };

  return (
    <div className={styles.fileContainer} {...test("MESSAGE_EVENT_FILE")}>
      <div className={styles.file}>
        {getFileIcon(content.info?.mimetype)}
        <div className={styles.fileInfo}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              lineHeight: "normal",
              paddingBottom: "4px",
            }}
          >
            {fileName}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "normal",
              color: theme.palette.text.secondary,
              paddingBottom: "4px",
            }}
          >
            {`${t("SIZE")}: ${convertBytes(content.info?.size)}`}
          </Typography>
          <a href={src} download target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
            {t("CLICK_TO_DOWNLOAD", { type: content.info?.mimetype?.includes("image/") ? t("IMAGE") : t("FILE") })}
          </a>
        </div>
      </div>
    </div>
  );
};

export default File;
