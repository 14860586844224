import React, { useState, useEffect, forwardRef, useRef, useLayoutEffect, ChangeEvent, MouseEvent } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { sipActions } from "store/actions/sip";
import { IconButton, Input } from "components";
import styled from "themes/theme/baseTheme";
import BackspaceIcon from "@mui/icons-material/Backspace";
import InputAdornment from "@mui/material/InputAdornment";
import CallIcon from "@mui/icons-material/Call";
import { isEmpty, test } from "utils/helpers";

import "./Dialpad.scss";
import { CallActionType } from "types/UC";
import { useSip } from "hooks";
import { useAppSelector } from "store/hooks";
import { getSipCallActive } from "store/selectors";
import { InputBaseProps, InputBaseComponentProps } from "@mui/material";

const StyledDiv = styled("div")(({ theme }) => ({
  color: `${theme.palette.text.button.outlined.active}`,
  backgroundColor: `${theme.palette.background.secondary}`,
  border: `1px solid ${theme.palette.border.button.outlined.active}`,
  "& .bottom": {
    color: `${theme.palette.text.button.outlined.active}`,
  },
}));

const StyledInput = styled(Input)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.input.notSelected}`,
  [`&.Mui-focused`]: {
    border: `1px solid ${theme.palette.border.input.focused}`,
  },
  [`&.Mui-disabled`]: {
    border: `1px solid ${theme.palette.border.input.disabled}`,
  },
  "& input::placeholder": {
    color: theme.palette.text.disabled,
    opacity: 1,
  },
}));

const DialpadInput = forwardRef<InputBaseProps, InputBaseComponentProps>((props, ref) => {
  return <StyledInput inputRef={ref} {...props} />;
});

interface DialpadProps {
  callType: CallActionType;
  hideCallButton?: boolean;
  onChange?: (inputField: string) => void;
}

const Dialpad = ({ callType, hideCallButton = false, onChange }: DialpadProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const [inputField, setInputField] = useState<string>("");
  const [pressedSymbol, setPressedSymbol] = useState<string | null>("");
  const [carretPos, setCarretPos] = useState(0);
  const { transferCallToUser } = useSip();
  const inputRef = useRef<InputBaseProps>(null);

  const canInitateSipCall = useAppSelector((state) => state.sip.canInitateSipCall);
  const sipCallActive = useAppSelector((state) => getSipCallActive(state, true)); // includes active held call

  const enabled = callType === CallActionType.Transfer || canInitateSipCall;
  const disableButtons = !enabled || isEmpty(inputField);

  // we need to reset the carret position after the delete button is pressed
  useLayoutEffect(() => {
    if (inputRef && inputField.length > 0) setInputSelectionRange(carretPos, carretPos);
  });

  useEffect(() => {
    if (onChange) {
      onChange(inputField);
    }
  }, [inputField, onChange]);

  const startCall = () => {
    if (callType === CallActionType.Call) {
      dispatch(
        sipActions.startSipCall({
          sipCallNumber: inputField,
          sipCallType: callType,
          sipCallDisplayName: inputField,
        })
      );
    } else if (callType === CallActionType.Transfer) {
      if (sipCallActive) {
        transferCallToUser(sipCallActive, inputField);
      }
    }
  };

  const validSymbols = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "*", "#", "+"];

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (/^[0-9(+*#)]*$/.test(event.target.value)) {
      setInputField(event.target.value);
      setCarretPos(getInputSelectionStart());
    }
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (!enabled) return;
    const value = (event.target as HTMLDivElement).dataset.value;
    const pos = getInputSelectionStart();
    const textWithoutSelection = getInputSelection();
    if (value && validSymbols.indexOf(value) !== -1) {
      setInputField(
        textWithoutSelection.substring(0, pos) +
          value +
          textWithoutSelection.substring(pos, textWithoutSelection.length)
      );
      setCarretPos(pos + 1);
      setPressedSymbol(value);
    }
    focusInput();
  };

  const focusInput = () => {
    getRefCurrent()!.focus();
  };

  const getRefCurrent = () => {
    if (inputRef && inputRef.current) return inputRef.current as InputBaseComponentProps;
  };

  const setInputSelectionRange = (start: number, end: number) => {
    getRefCurrent()!.setSelectionRange(start, end);
  };

  const getInputSelectionStart = () => {
    return getRefCurrent()!.selectionStart;
  };

  const getInputSelection = () => {
    const input = getRefCurrent()!;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    const value = input.value;
    return start !== end ? value.substring(0, start) + value.substring(end, value.length) : value;
  };

  const handleDelete = () => {
    const pos = getInputSelectionStart();
    const textWithoutSelection = getInputSelection();
    // validates the result after removing the selected text is the same of the input.
    // If it's the same, it's because there's no text selected and it should be treated as a backspace
    if (textWithoutSelection !== inputField) {
      setInputField(textWithoutSelection);
      setCarretPos(pos);
    } else if (pos > 0) {
      setInputField(inputField.slice(0, pos - 1) + inputField.slice(pos));
      setCarretPos(pos - 1);
    }
  };

  const dialpadButton = (number: string, text?: string, haveButtonAction?: boolean) => {
    const pressed = pressedSymbol === number || (text && pressedSymbol === text);
    return (
      <StyledDiv className={`numpad-button ${pressed ? "pressed" : ""}`}>
        <div className="top" data-value={`${number}`} style={{ paddingTop: text === undefined ? "13px" : "3px" }}>
          {number}
        </div>
        {text ? (
          haveButtonAction ? (
            <div className="bottom" data-value={`${text}`}>
              {text}
            </div>
          ) : (
            <div className="bottom">{text}</div>
          )
        ) : null}
      </StyledDiv>
    );
  };

  return (
    <div key={`dialpad-${callType}`} id={`dialpad-${callType}`} className="dialpad-container" {...test("DIALPAD_PAGE")}>
      <div className="dialer">
        <div className="dialer-input">
          <DialpadInput
            ref={inputRef}
            onChange={handleOnChange}
            value={inputField}
            placeholder={t("ENTER_PHONE_NUMBER")}
            type="text"
            name="dialer-input"
            autoComplete="off"
            autoFocus={true}
            disabled={!enabled}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  disabled={disableButtons}
                  onClick={handleDelete}
                  onMouseDown={(event) => event.preventDefault()}
                  sx={{ color: theme.palette.icon.main }}
                >
                  <BackspaceIcon />
                </IconButton>
                {/* <div className="delete"></div> */}
              </InputAdornment>
            }
            sx={{ backgroundColor: `${theme.palette.background.secondary}` }}
          />
        </div>
        <div className="dialer-numpad" onClick={handleClick}>
          {dialpadButton("1", "")}
          {dialpadButton("2", "ABC")}
          {dialpadButton("3", "DEF")}
          {dialpadButton("4", "GHI")}
          {dialpadButton("5", "GKL")}
          {dialpadButton("6", "MNO")}
          {dialpadButton("7", "PQRS")}
          {dialpadButton("8", "TUV")}
          {dialpadButton("9", "WXYZ")}
          {dialpadButton("*")}
          {dialpadButton("0", "+", true)}
          {dialpadButton("#")}
        </div>
        <div className="dialer-actions">
          <div className="togle"></div>
          {!hideCallButton && (
            <IconButton
              disabled={disableButtons}
              onClick={startCall}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  boxShadow: "1px 1px 2px #9191918f",
                  transform: "scale(1.1)",
                },
              }}
            >
              <CallIcon sx={{ fontSize: "40px" }} />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dialpad;
