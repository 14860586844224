import * as actionTypes from "../actions/types/matrix";
import { MatrixState } from "../types/matrix";
import { Types } from "Types";
import { DevicesSettings, RoomListFilters } from "types/Matrix";
import * as callActionTypes from "../actions/types/call";

const initialMatrixState: MatrixState = {
  roomId: null,
  matrixEvent: null,
  incomingMatrixCallList: [],
  matrixCallClient: null,
  callParticipants: [],
  matrixCallRoomId: null,
  hasActiveMatrixCall: false,
  matrixVideoMuted: true,
  matrixAudioMuted: true,
  matrixSpeakerMuted: true,
  callSuccess: false,
  callClientError: null,
  updatedRoomAvatar: null,
  matrixCallClientState: null,
  currentlyPlayingMatrixRingtone: false,
  matrixScreenShareSelecting: false,
  inMatrixScreenShare: false,
  inMatrixRemoteScreenShare: false,
  matrixSelectedShareStream: null,
  avatarUploadError: false,
  roomList: {
    invites: [],
    private: [],
    public: [],
    contacts: [],
    favorites: [],
  },
  chatVisible: true,
  isRedirectToAdhocConf: false,
  sendMessageErrorData: null,
  roomEventsList: [],
  currentUser: null,
  matrixSyncState: "INITIALIZING",
  roomListFilter: RoomListFilters.all,
};

const matrixReducer = (state: MatrixState = initialMatrixState, action: Types.RootAction): MatrixState => {
  switch (action.type) {
    case actionTypes.SET_CURRENTROOM:
      return { ...state, roomId: action.roomId };
    case actionTypes.PLAY_MATRIX_CALL_RINGTONE_SUCCEEDED:
      return {
        ...state,
        currentlyPlayingMatrixRingtone: true,
      };
    case actionTypes.PLACE_MATRIX_SCREENSHARE:
      return {
        ...state,
        matrixScreenShareSelecting: true,
      };
    case actionTypes.PLACE_MATRIX_SCREENSHARE_SUCCEEDED:
      return {
        ...state,
        inMatrixScreenShare: true,
        matrixSelectedShareStream: action.matrixSelectedShareStream,
        matrixScreenShareSelecting: false,
      };
    case actionTypes.PLACE_MATRIX_SCREENSHARE_CANCELED:
      return {
        ...state,
        inMatrixScreenShare: false,
        matrixSelectedShareStream: null,
        matrixScreenShareSelecting: false,
        callClientError: "",
      };
    case actionTypes.PLACE_MATRIX_SCREENSHARE_FAILED:
      return {
        ...state,
        inMatrixScreenShare: false,
        matrixSelectedShareStream: null,
        matrixScreenShareSelecting: false,
        callClientError: action.message,
      };
    case actionTypes.STOP_MATRIX_SCREENSHARE_SUCCEEDED:
      return {
        ...state,
        inMatrixScreenShare: false,
        matrixSelectedShareStream: null,
      };
    case actionTypes.STOP_MATRIX_SCREENSHARE_FAILED:
      return {
        ...state,
        callClientError: action.message,
        matrixSelectedShareStream: null,
      };
    case actionTypes.PLAY_MATRIX_CALL_RINGTONE_FAILED:
      return {
        ...state,
        currentlyPlayingMatrixRingtone: false,
      };
    case actionTypes.STOP_MATRIX_CALL_RINGTONE_SUCCEEDED:
      return {
        ...state,
        currentlyPlayingMatrixRingtone: false,
      };
    case actionTypes.STOP_MATRIX_CALL_RINGTONE_FAILED:
      return {
        ...state,
        currentlyPlayingMatrixRingtone: true,
      };
    case actionTypes.CREATE_MATRIX_CALL_CLIENT_SUCCEEDED:
      return {
        ...state,
        matrixCallClient: action.callClient,
        hasActiveMatrixCall: true,
        callClientError: null,
        chatVisible: false,
      };
    case actionTypes.SET_MATRIX_CALL_ROOM_ID:
      return {
        ...state,
        matrixCallRoomId: action.roomId,
      };
    case actionTypes.SET_MATRIX_CALL_PARTICIPANTS:
      return {
        ...state,
        callParticipants: action.participants,
      };
    case actionTypes.CREATE_MATRIX_CALL_CLIENT_FAILED:
      return {
        ...state,
        matrixCallClient: null,
        hasActiveMatrixCall: false,
        callClientError: action.message,
        callParticipants: [],
        matrixCallRoomId: null,
        chatVisible: true,
      };
    case actionTypes.DELETE_MATRIX_CALL_CLIENT:
      return {
        ...state,
        matrixCallClient: state.matrixCallClient
          ? action.callClient.callId === state.matrixCallClient.callId
            ? null
            : state.matrixCallClient
          : state.matrixCallClient,
        callParticipants: state.matrixCallClient
          ? action.callClient.callId === state.matrixCallClient.callId
            ? []
            : state.callParticipants
          : state.callParticipants,
        matrixCallRoomId: state.matrixCallClient
          ? action.callClient.callId === state.matrixCallClient.callId
            ? null
            : state.matrixCallRoomId
          : state.matrixCallRoomId,
        hasActiveMatrixCall: state.matrixCallClient
          ? action.callClient.callId === state.matrixCallClient.callId
            ? false
            : state.hasActiveMatrixCall
          : state.hasActiveMatrixCall,
        matrixCallClientState: null,
        chatVisible: true,
      };
    case actionTypes.INCOMING_MATRIX_CALL:
      return {
        ...state,
        incomingMatrixCallList: [...state.incomingMatrixCallList, action.callClient],
      };
    case actionTypes.DELETE_INCOMING_MATRIX_CALL:
      return {
        ...state,
        incomingMatrixCallList: state.incomingMatrixCallList.filter((item) => action.callClient.callId !== item.callId),
      };
    case actionTypes.ANSWER_MATRIX_CALL:
      return {
        ...state,
        incomingMatrixCallList: state.incomingMatrixCallList.filter(
          (item) => action.opts.matrixCall.callId !== item.callId
        ),
      };
    case actionTypes.ANSWER_MATRIX_CALL_SUCCEEDED:
      return {
        ...state,
        matrixCallClient: action.callClient,
        hasActiveMatrixCall: true,
        incomingMatrixCallList: [],
        callClientError: null,
        chatVisible: false,
      };
    case actionTypes.ANSWER_MATRIX_CALL_FAILED:
      return {
        ...state,
        matrixCallClient: null,
        hasActiveMatrixCall: false,
        callClientError: action.message,
        callParticipants: [],
        matrixCallRoomId: null,
        chatVisible: true,
      };
    case actionTypes.REJECT_MATRIX_CALL_SUCCEEDED:
      return {
        ...state,
        callClientError: null,
        incomingMatrixCallList: state.incomingMatrixCallList.filter((item) => action.callClient.callId !== item.callId),
        chatVisible: true,
      };
    case actionTypes.REJECT_MATRIX_CALL_FAILED:
      return {
        ...state,
        callClientError: action.message,
        chatVisible: true,
      };
    case actionTypes.PLACE_MATRIX_CALL_SUCCEEDED:
      return {
        ...state,
        callSuccess: true,
        callClientError: null,
      };
    case actionTypes.PLACE_MATRIX_CALL_FAILED:
      return {
        ...state,
        callSuccess: false,
        callClientError: action.message,
        callParticipants: [],
        matrixCallRoomId: null,
      };
    case actionTypes.HANGUP_MATRIX_CALL_SUCCEEDED:
      return {
        ...state,
        hasActiveMatrixCall: false,
        callClientError: null,
        callParticipants: [],
        matrixCallRoomId: null,
        inMatrixScreenShare: false,
        chatVisible: true,
      };
    case actionTypes.HANGUP_MATRIX_CALL_FAILED:
      return {
        ...state,
        callClientError: action.message,
      };
    case actionTypes.MATRIX_CALL_MUTE_SPEAKER_SUCCEEDED:
      return {
        ...state,
        matrixSpeakerMuted: action.muted,
      };
    case actionTypes.MATRIX_CALL_MUTE_SPEAKER_FAILED:
      return {
        ...state,
        callClientError: action.message,
      };
    case actionTypes.MATRIX_CALL_MUTE_AUDIO_SUCCEEDED:
      return {
        ...state,
        matrixAudioMuted: action.muted,
      };
    case actionTypes.MATRIX_CALL_MUTE_AUDIO_FAILED:
      return {
        ...state,
        callClientError: action.message,
      };
    case actionTypes.MATRIX_CALL_MUTE_VIDEO_SUCCEEDED:
      return {
        ...state,
        matrixVideoMuted: action.muted,
      };
    case actionTypes.MATRIX_CALL_MUTE_VIDEO_FAILED:
      return {
        ...state,
        callClientError: action.message,
      };
    case actionTypes.UPDATE_MATRIX_CALL_STATE:
      return { ...state, matrixCallClientState: action.callClientState };
    case actionTypes.UPDATE_MATRIX_EVENT:
      return { ...state, matrixEvent: action.matrixEvent };
    case actionTypes.UNSUBSCRIBE_MATRIX_CALL_STATE_SUCCEEDED:
      return {
        ...state,
        matrixCallClientState: null,
      };
    case actionTypes.UPDATE_MATRIX_ROOM_AVATAR:
      return {
        ...state,
        updatedRoomAvatar: action.opts,
      };
    case actionTypes.MATRIX_UPLOAD_AVATAR_FAILED:
      return {
        ...state,
        avatarUploadError: action.message,
      };
    case actionTypes.MATRIX_UPLOAD_AVATAR_CLEAR_ERROR:
      return {
        ...state,
        avatarUploadError: action.message,
      };
    case actionTypes.SET_ROOM_LIST:
      return {
        ...state,
        roomList: action.roomList,
      };
    case actionTypes.UPDATE_ROOM_IN_ROOM_LIST:
      const room = action.room;
      const roomList = state.roomList;
      Object.keys(roomList).forEach((list) =>
        roomList[list].forEach((localRoom) => (localRoom.roomId === room.roomId ? { ...room } : localRoom))
      );
      return {
        ...state,
        roomList: { ...roomList },
      };
    case actionTypes.REMOVE_ROOM_FROM_ROOM_LIST:
      const roomToRemove = action.roomId;
      const newRoomList = state.roomList;
      Object.keys(newRoomList).forEach(
        (list) => (newRoomList[list] = newRoomList[list].filter((localRoom) => localRoom.roomId !== roomToRemove))
      );
      return {
        ...state,
        roomList: { ...newRoomList },
      };
    case actionTypes.UPDATE_UNREAD_NOTIFICATION_COUNT:
      const roomId = action.roomId;
      const updateNotificationCount = action.updateNotificationCount;
      const list = state.roomList;
      Object.keys(list).forEach((key) =>
        list[key].forEach((localRoom) =>
          localRoom.roomId === roomId ? (localRoom.unreadNotificationCount = updateNotificationCount) : localRoom
        )
      );
      return {
        ...state,
        roomList: { ...list },
      };
    case actionTypes.SET_DEVICE_SETTINGS:
      const { speakerMuted, microMuted, videoMuted } = action.opts as DevicesSettings;
      return {
        ...state,
        matrixSpeakerMuted: speakerMuted,
        matrixVideoMuted: videoMuted !== undefined ? videoMuted : state.matrixVideoMuted,
        matrixAudioMuted: microMuted !== undefined ? microMuted : state.matrixAudioMuted,
      };

    case actionTypes.SET_IN_MATRIX_REMOTE_SCREENSHARE:
      return {
        ...state,
        inMatrixRemoteScreenShare: action.inRemoteScreenshare,
      };

    case actionTypes.SET_CHAT_VISIBLE:
      return {
        ...state,
        chatVisible: action.chatVisible,
      };

    case callActionTypes.START_CALL_SUCCEEDED:
      return {
        ...state,
        chatVisible: false,
      };

    case callActionTypes.START_CALL_FAILED:
      return {
        ...state,
        chatVisible: true,
      };

    case callActionTypes.END_CALL:
      return {
        ...state,
        chatVisible: true,
      };

    case actionTypes.SET_REDIRECT_TO_ADHOC_CONF:
      return {
        ...state,
        isRedirectToAdhocConf: action.isRedirectToAdhocConf,
      };
    case actionTypes.SEND_MESSAGE_ERROR_DATA:
      return {
        ...state,
        sendMessageErrorData: action.data,
      };
    case actionTypes.SET_ROOM_EVENTS_LIST:
      return {
        ...state,
        roomEventsList: action.eventsList,
      };
    case actionTypes.SET_CURRENTUSER:
      return {
        ...state,
        currentUser: action.currentUser,
      };
    case actionTypes.SET_MATRIX_SYNCSTATE:
      return {
        ...state,
        matrixSyncState: action.syncState,
      };
    case actionTypes.SET_ROOMLIST_FILTER:
      return {
        ...state,
        roomListFilter: action.filter,
      };
    default:
      return state;
  }
};

export default matrixReducer;
