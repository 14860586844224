export const START_PRESENCE_SERVICE = "START_PRESENCE_SERVICE";
export const START_PRESENCE_SERVICE_SUCCEEDED = "START_PRESENCE_SERVICE_SUCCEEDED";
export const START_PRESENCE_SERVICE_FAILED = "START_PRESENCE_SERVICE_FAILED";

export const STOP_PRESENCE_SERVICE = "STOP_PRESENCE_SERVICE";
export const STOP_PRESENCE_SERVICE_SUCCEEDED = "STOP_PRESENCE_SERVICE_SUCCEEDED";
export const STOP_PRESENCE_SERVICE_FAILED = "STOP_PRESENCE_SERVICE_FAILED";

export const CHANGE_PRESENCE = "CHANGE_PRESENCE";
export const CHANGE_PRESENCE_SUCCEEDED = "CHANGE_PRESENCE_SUCCEEDED";
export const CHANGE_PRESENCE_FAILED = "CHANGE_PRESENCE_FAILED";

export const SUBSCRIBE_PRESENCE_CHANGE = "SUBSCRIBE_PRESENCE_CHANGE";
export const SUBSCRIBE_PRESENCE_CHANGE_SUCCEEDED = "SUBSCRIBE_PRESENCE_CHANGE_SUCCEEDED";
export const SUBSCRIBE_PRESENCE_CHANGE_FAILED = "SUBSCRIBE_PRESENCE_CHANGE_FAILED";

export const ON_PRESENCE_CHANGE = "ON_PRESENCE_CHANGE";
export const ON_OTHER_PRESENCE_CHANGE = "ON_OTHER_PRESENCE_CHANGE";

export const UPDATE_PRESENCE_AVATAR = "UPDATE_PRESENCE_AVATAR";
export const UPDATE_PRESENCE_AVATAR_SUCCEEDED = "UPDATE_PRESENCE_AVATAR_SUCCEEDED";
export const UPDATE_PRESENCE_AVATAR_FAILED = "UPDATE_PRESENCE_AVATAR_FAILED";
