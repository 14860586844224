import { ParticipantInfo } from "./ParticipantInfo";
import { RoomDetails } from "./RoomDetails";

export enum RecordingState {
  RECORDING_STATE_ERROR = "Error",
  RECORDING_STATE_OFF = "Off",
  RECORDING_STATE_PAUSED = "Paused",
  RECORDING_STATE_RECORDING = "Recording",
}

export interface RoomInfo {
  id: string;
  name: string;
  extension: string;
  description: string;
  ownerName: string;
  locked: boolean;
  recordingState: string;
  participants: ParticipantInfo[];
  roomDetails: RoomDetails;
}
