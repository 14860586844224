import { GetPbxUsersRequest } from "services/PBXServerApi/types/PbxUsers";
import * as actionTypes from "../actions/types/pbx";

export const pbxActions = {
  getPBXUsers: (payload: GetPbxUsersRequest) => ({
    type: actionTypes.GET_PBX_USERS,
    payload,
  }),
  resetPbxUserSearch: () => ({
    type: actionTypes.GET_PBX_USERS_RESET,
  }),
  getPBXUserDetails: (username: string) => ({
    type: actionTypes.GET_PBX_USER_DETAILS,
    username,
  }),
};
