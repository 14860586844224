import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Autocomplete, {
  AutocompleteRenderInputParams,
  AutocompleteProps,
  AutocompleteRenderOptionState,
} from "@mui/material/Autocomplete";
import { Box, Chip, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SearchData } from "types/Matrix";
import { HTMLAttributes } from "react";
import AvatarWithLabels from "./AvatarWithLabels";
import { getProperCase } from "utils/helpers";

export const ParamsWithInputAdornment = (params: AutocompleteRenderInputParams) => {
  const theme = useTheme();
  params.InputProps.startAdornment = (
    <>
      <InputAdornment position="start" sx={{ marginRight: "0px" }}>
        <SearchIcon sx={{ color: theme.palette.text.secondary }} />
      </InputAdornment>
      {params.InputProps.startAdornment}
    </>
  );
  return params;
};

const SearchUsersAutocomplete = ({
  ...props
}: AutocompleteProps<SearchData, boolean | undefined, boolean | undefined, boolean | undefined>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { renderTags, renderOption, ...params } = props;

  const defaultOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: SearchData,
    state: AutocompleteRenderOptionState
  ) => {
    const { displayName, avatarUrl, presenceStatus, presenceStatusMsg, userId } = option;
    return (
      <Box component="li" {...props} key={userId}>
        <AvatarWithLabels
          id={`invite-room-avatar-${userId}`}
          src={avatarUrl ? avatarUrl : ""}
          name={displayName}
          isPerson={true}
          disableBadge={false}
          status={presenceStatus}
          statusMsg={presenceStatusMsg ? presenceStatusMsg : getProperCase(t("OFFLINE"))}
          sx={{ border: `1px solid ${avatarUrl ? "transparent" : theme.palette.neutrals.lightGrey3}` }}
        />
      </Box>
    );
  };

  return (
    <Autocomplete
      {...params}
      componentsProps={{
        paper: {
          sx: {
            margin: "0px",
            "& .MuiAutocomplete-noOptions, .MuiAutocomplete-loading": {
              maxHeight: "300px",
            },
          },
        },
      }}
      ListboxProps={{
        className: "scroll-bar",
        sx: {
          maxHeight: "300px",
          "& .MuiAutocomplete-option": {
            // Hover
            "&.Mui-focused": {
              backgroundColor: theme.palette.background.filledInputBackground,
            },
          },
        },
      }}
      noOptionsText={t("NO_RESULTS")}
      loadingText={t("SEARCHING")}
      blurOnSelect={true}
      clearOnBlur={true}
      size="small"
      renderTags={(value, getTagProps) =>
        renderTags ??
        value.map((option: SearchData, index: number) => (
          <Chip variant="outlined" label={option.displayName} {...getTagProps({ index })} />
        ))
      }
      renderOption={renderOption ?? defaultOption}
    />
  );
};

export default SearchUsersAutocomplete;
