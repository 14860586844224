import * as actionTypes from "../actions/types/provisioning";
import {
  updateUsersList,
  removeUsername,
  addNewUsername,
  updateUsersListWithNewUser,
  removeListUsernames,
  addListOfNewUsernames,
} from "utils/helpers";
import { Types } from "Types";
import { ProvisioningState } from "store/types/provisioning";

const initialState: ProvisioningState = {
  provisioningConfig: null,
  endpointData: null,
  createdRoom: null,
  createdRoomFailed: null,
  updateRoomFailed: null,
  updateRoomData: null,
  updateGroupChatRoomFailed: null,
  updateGroupChatRoomData: null,
  userSearch: null,
  userSearchResults: [],
  userSearchByUsername: null,
  userSearchByUsernameResults: [],
  userSearchByListOfUsernames: [],
  userSearchByListOfUsernamesResults: [],
  userSearchByListOfEmails: [],
  userSearchByListOfEmailsResults: [],
  usersList: [],
  usersListToIgnore: [],
};

const provisioningReducer = (state = initialState, action: Types.RootAction): ProvisioningState => {
  switch (action.type) {
    case actionTypes.SEARCH_PROVISIONING_USER:
      return state;
    case actionTypes.SEARCH_BY_USERNAME_PROVISIONING_USER:
      return {
        ...state,
        userSearchByUsername: action.payload.queryString,
      };
    case actionTypes.SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS:
      return {
        ...state,
        userSearchByListOfUsernames: action.payload.request,
      };
    case actionTypes.SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS:
      return {
        ...state,
        userSearchByListOfEmails: action.payload.request,
      };
    case actionTypes.GET_PROVISIONING_CONFIG_SUCCEEDED:
      return {
        ...state,
        provisioningConfig: action.config,
      };
    case actionTypes.GET_PROVISIONING_CONFIG_FAILED:
      return {
        ...state,
        provisioningConfig: null,
      };
    case actionTypes.SET_ENDPOINT_DATA_SUCCEEDED:
      return {
        ...state,
        endpointData: action.data,
      };
    case actionTypes.SET_ENDPOINT_DATA_FAILED:
      return {
        ...state,
        endpointData: null,
      };
    case actionTypes.SEARCH_PROVISIONING_USER_SUCCEEDED:
      return {
        ...state,
        userSearchResults: action.userSearchResults,
        usersList: updateUsersList(state.usersList, action.userSearchResults),
        usersListToIgnore: removeListUsernames(state.usersListToIgnore, state.userSearch),
      };
    case actionTypes.SEARCH_PROVISIONING_USER_FAILED:
      return {
        ...state,
        userSearchResults: [],
      };
    case actionTypes.SEARCH_PROVISIONING_USER_RESET:
      return {
        ...state,
        userSearchResults: [],
      };
    case actionTypes.SEARCH_BY_USERNAME_PROVISIONING_USER_SUCCEEDED:
      return {
        ...state,
        userSearchByUsernameResults: action.userSearchByUsernameResults,
        usersList: updateUsersListWithNewUser(state.usersList, action.userSearchByUsernameResults),
        usersListToIgnore: removeUsername(state.usersListToIgnore, state.userSearchByUsername),
      };
    case actionTypes.SEARCH_BY_USERNAME_PROVISIONING_USER_FAILED:
      return {
        ...state,
        userSearchByUsernameResults: null,
        usersListToIgnore: addNewUsername(state.usersListToIgnore, state.userSearchByUsername),
      };
    case actionTypes.SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS_SUCCEEDED:
      return {
        ...state,
        userSearchByListOfUsernamesResults: action.userSearchByListOfUsernamesResults,
        usersList: updateUsersList(state.usersList, action.userSearchByListOfUsernamesResults),
        usersListToIgnore: removeListUsernames(state.usersListToIgnore, state.userSearchByListOfUsernames),
      };
    case actionTypes.SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS_FAILED:
      return {
        ...state,
        userSearchByListOfUsernamesResults: [],
        usersListToIgnore: addListOfNewUsernames(state.usersListToIgnore, state.userSearchByListOfUsernames),
      };
    case actionTypes.SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS_RESET:
      return {
        ...state,
        userSearchByListOfUsernamesResults: [],
      };
    case actionTypes.SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS_SUCCEEDED:
      return {
        ...state,
        userSearchByListOfEmailsResults: action.userSearchByListOfEmailsResults,
        usersList: updateUsersList(state.usersList, action.userSearchByListOfEmailsResult),
        usersListToIgnore: removeListUsernames(state.usersListToIgnore, state.userSearchByListOfEmailsResults),
      };
    case actionTypes.SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS_FAILED:
      return {
        ...state,
        userSearchByListOfEmailsResults: [],
        usersListToIgnore: addListOfNewUsernames(state.usersListToIgnore, state.userSearchByListOfEmailsResults),
      };
    case actionTypes.SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS_RESET:
      return {
        ...state,
        userSearchByListOfEmailsResults: [],
      };
    case actionTypes.CREATE_ROOM:
      return {
        ...state,
        createdRoom: null,
        createdRoomFailed: null,
      };
    case actionTypes.CREATE_ROOM_SUCCEEDED:
      return {
        ...state,
        createdRoomFailed: null,
        createdRoom: action.createdRoom,
      };
    case actionTypes.CREATE_ROOM_FAILED:
      return {
        ...state,
        createdRoom: null,
        createdRoomFailed: action.message,
      };
    case actionTypes.UPDATE_ROOM:
      return {
        ...state,
        updateRoomData: null,
        updateRoomFailed: null,
      };
    case actionTypes.UPDATE_ROOM_SUCCEEDED:
      return {
        ...state,
        updateRoomData: action.data,
        updateRoomFailed: null,
      };
    case actionTypes.UPDATE_ROOM_FAILED:
      return {
        ...state,
        updateRoomData: null,
        updateRoomFailed: action.message,
      };
    case actionTypes.UPDATE_GROUP_CHAT_ROOM:
      return {
        ...state,
        updateGroupChatRoomData: null,
        updateGroupChatRoomFailed: null,
      };
    case actionTypes.UPDATE_GROUP_CHAT_ROOM_SUCCEEDED:
      return {
        ...state,
        updateGroupChatRoomData: action.data,
        updateGroupChatRoomFailed: null,
      };
    case actionTypes.UPDATE_GROUP_CHAT_ROOM_FAILED:
      return {
        ...state,
        updateGroupChatRoomData: null,
        updateGroupChatRoomFailed: action.message,
      };
    case actionTypes.CLEAR:
      return {
        ...state,
        updateRoomData: null,
        updateRoomFailed: null,
        updateGroupChatRoomData: null,
        updateGroupChatRoomFailed: null,
      };
    default:
      return state;
  }
};

export default provisioningReducer;
