import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactComponent as BrandLogo } from "themes/brand/enghouse/logo/brand_logo.svg";
import { useTheme } from "@mui/material/styles";

const BrandLogoIcon = (props: SvgIconProps) => {
  const theme = useTheme();
  return (
    <SvgIcon
      {...props}
      component={BrandLogo}
      inheritViewBox
      sx={{ ...props.sx, color: theme.palette.text.primary, height: "100%", width: "100%" }}
    />
  );
};

export default BrandLogoIcon;
