import { Components } from "@mui/material";
import MuiAccordionSummary from "./MuiAccordionSummary";
import MuiAlert from "./MuiAlert";
import MuiAppBar from "./MuiAppBar";
import MuiButton from "./MuiButton";
import MuiCheckbox from "./MuiCheckbox";
import MuiChip from "./MuiChip";
import MuiDialog from "./MuiDialog";
import MuiDialogActions from "./MuiDialogActions";
import MuiDialogContent from "./MuiDialogContent";
import MuiDialogTitle from "./MuiDialogTitle";
import MuiDrawer from "./MuiDrawer";
import MuiFilledInput from "./MuiFilledInput";
import MuiFormHelperText from "./MuiFormHelperText";
import MuiIconButton from "./MuiIconButton";
import MuiInputBase from "./MuiInputBase";
import MuiInputLabel from "./MuiInputLabel";
import MuiLink from "./MuiLink";
import MuiMenu from "./MuiMenu";
import MuiMenuItem from "./MuiMenuItem";
import MuiOutlinedInput from "./MuiOutlinedInput";
import MuiRadio from "./MuiRadio";
import MuiSelect from "./MuiSelect";
import MuiSwitch from "./MuiSwitch";
import MuiTextField from "./MuiTextField";
import MuiTooltip from "./MuiTooltip";
import MuiTypography from "./MuiTypography";

const components: Components = {
  MuiAccordionSummary: MuiAccordionSummary,
  MuiAlert: MuiAlert,
  MuiAppBar: MuiAppBar,
  MuiButton: MuiButton,
  MuiCheckbox: MuiCheckbox,
  MuiChip: MuiChip,
  MuiDialog: MuiDialog,
  MuiDialogActions: MuiDialogActions,
  MuiDialogContent: MuiDialogContent,
  MuiDialogTitle: MuiDialogTitle,
  MuiDrawer: MuiDrawer,
  MuiFilledInput: MuiFilledInput,
  MuiFormHelperText: MuiFormHelperText,
  MuiIconButton: MuiIconButton,
  MuiInputBase: MuiInputBase,
  MuiInputLabel: MuiInputLabel,
  MuiLink: MuiLink,
  MuiMenu: MuiMenu,
  MuiMenuItem: MuiMenuItem,
  MuiOutlinedInput: MuiOutlinedInput,
  MuiRadio: MuiRadio,
  MuiSelect: MuiSelect,
  MuiSwitch: MuiSwitch,
  MuiTextField: MuiTextField,
  MuiTooltip: MuiTooltip,
  MuiTypography: MuiTypography,
};

export default components;
