import { applyMiddleware, createStore, PreloadedState, AnyAction } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { gaMiddleware } from "./analytics/googleAnalytics";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import rootReducer from "./root-reducer";
import rootSaga from "./root-saga";
import { getLogger } from "logger/appLogger";

type GenericReducer<S> = (state: S, action: AnyAction) => S;

export interface ConfigureStoreParams<S> {
  reducer: GenericReducer<S>;
  initState?: PreloadedState<S>;
  devToolsName?: string;
}

export default function configureStore<S>(config: ConfigureStoreParams<S>) {
  const reduxLogger = getLogger("redux");
  const logger = createLogger({ logger: reduxLogger });
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [logger, gaMiddleware, sagaMiddleware];

  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];

  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, config.initState || {}, composedEnhancers);

  sagaMiddleware.run(rootSaga);

  return store;
}
