import MatrixAPI from "./providers/MatrixAPI";
export { MatrixUserProvider } from "./providers/MatrixUserAPI";

let selectedProvider: MatrixAPI | null = null;

export const getMatrixAPIProvider = () => {
  if (selectedProvider) {
    return selectedProvider;
  }
  selectedProvider = MatrixAPI.Instance();
  return selectedProvider;
};
