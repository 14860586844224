import { Components, Theme } from "@mui/material";

const MuiMenu: Components["MuiMenu"] = {
  styleOverrides: {
    root: ({ theme }) => {
      const palette = (theme as Theme).palette;
      return {
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "140%",
        "& .Mui-selected": {
          backgroundColor: palette.background.menu.selected,
          fontWeight: 600,
          color: palette.text.regular,
        },
        "& .MuiMenuItem-root:hover": {
          backgroundColor: palette.background.menu.hover,
        },
        " .Mui-disabled": {
          color: palette.text.disabled,
        },
      };
    },
    paper: ({ theme }) => {
      const palette = (theme as Theme).palette;
      return {
        color: palette.text.primary,
        background: palette.background.menu.notSelected,
        boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "4px",
      };
    },
  },
};

export default MuiMenu;
