import { ThemeOptions } from "@mui/material/styles";
import * as actionTypes from "../actions/types/theme";

export const themeActions = {
  setTheme: (currentTheme: ThemeOptions) => ({
    type: actionTypes.SET_THEME,
    currentTheme,
  }),
  setDarkMode: (isDarkMode: boolean) => ({
    type: actionTypes.SET_DARK_MODE,
    isDarkMode,
  }),
  setPreferSystemScheme: (preferSystemScheme: boolean) => ({
    type: actionTypes.SET_PREFER_SYSTEM_SCHEME,
    preferSystemScheme,
  }),
  toggleDrawer: (openDrawer: boolean) => ({
    type: actionTypes.SET_TOGGLE,
    openDrawer,
  }),
  canToggleDrawer: (canToggleDrawer: boolean) => ({
    type: actionTypes.SET_CANTOGGLE,
    canToggleDrawer,
  }),
};
