import React, { useState, useEffect, useMemo } from "react";
import { getLogger } from "logger/appLogger";
import { LightBox, UCImage } from "types/Hooks";
import Lightbox from "utils/Lightbox";

const useLightBox = (): LightBox => {
  const logger = useMemo(() => getLogger("app"), []);
  const module = "useLightBox";

  const [images, setImages] = useState<UCImage[]>([]);
  const [startIndex, setStartIndex] = useState(0);

  const handleImageNavigate = (current?: number | undefined) => {
    try {
      const currentImg = (document.getElementsByClassName("lb-img")[0] as HTMLImageElement).src;
      const lbDownloadButton = document.getElementsByClassName("lb-icon-download")[0];

      if (!current) {
        if (!lbDownloadButton) return;
        if (!currentImg) return;
        lbDownloadButton.setAttribute("href", currentImg);
        return;
      }
      const image = images[current];
      if (!image) return;
      if (!lbDownloadButton) return;
      const src = image.url;
      lbDownloadButton.setAttribute("href", src);
    } catch (e) {
      logger.error(`${module}: Error in handleImageNavigate - cuurent:${current} - ${e.message}`);
    }
  };

  const showLightBox = (e: React.MouseEvent): void => {
    try {
      const { target } = e;
      if (target) {
        const { src } = target as HTMLImageElement;
        const imageElements = document.querySelectorAll(
          "img[data-attribute='mxImages']"
        ) as NodeListOf<HTMLImageElement>;
        imageElements.forEach((el, i) => {
          const image: UCImage = {
            url: el.src,
            title: el.alt,
          };
          if (src === el.src) {
            setStartIndex(i);
          }
          setImages((prevImages) => [...prevImages, image]);
        });
      }
    } catch (err) {
      logger.error(`Error in showLightBox - event:${e} - ${err.message}`);
    }
  };

  const renderLightBox = (): JSX.Element | undefined => {
    if (images.length === 1) {
      return (
        <Lightbox
          image={images[0].url}
          title={images[0].title}
          onNavigateImage={(current: number | undefined) => handleImageNavigate(current)}
          onClose={() => setImages([])}
        />
      );
    } else if (images.length > 1) {
      return (
        <Lightbox
          images={images}
          startIndex={startIndex}
          onNavigateImage={(current: number | undefined) => handleImageNavigate(current)}
          onClose={() => setImages([])}
        />
      );
    }
  };

  useEffect(() => {
    if (!images || images.length < 1) return;
    // Manipulate the dom
    addDownloadButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  const addDownloadButton = () => {
    try {
      const lbHeader = document.getElementsByClassName("lb-header")[0];
      const lbDownloadButton = document.getElementsByClassName("lb-icon-download")[0];
      if (!lbHeader) return;
      if (!lbDownloadButton) {
        const downloadButton = document.createElement("a");
        downloadButton.setAttribute("title", "Download");
        downloadButton.setAttribute("download", "");
        downloadButton.setAttribute("target", "_blank");
        downloadButton.classList.add("lb-button");
        downloadButton.classList.add("lb-icon-download");
        lbHeader.insertBefore(downloadButton, lbHeader.children[7]);
      }
      handleImageNavigate();
    } catch (e) {
      logger.error(`Error in addDownloadButton - ${e.messsage}`);
    }
  };

  return { showLightBox, renderLightBox };
};

export default useLightBox;
