import { Info } from "./types/Info";
import { RecordingState, RoomInfo } from "./types/RoomInfo";
import { RecordingEntry } from "./types/RecordingEntry";
import { ApiProvider } from "services/Providers/ApiProvider";
import { getTenantName } from "services/Providers/ProvisioningTokenProvider";
import { getLogger } from "logger/appLogger";
import getStore from "store/store";
import { RootState } from "store/root-reducer";

const logger = getLogger("conference-moderation");

export class ConferenceModerationApiProvider {
  module = "ConferenceModerationApiProvider";
  // singleton
  private static _instance: ConferenceModerationApiProvider;
  static Instance(): ConferenceModerationApiProvider {
    if (!this._instance) {
      this._instance = new ConferenceModerationApiProvider();
    }
    return this._instance;
  }

  private apiProvider: ApiProvider;

  // constructor
  constructor() {
    const extraHeaders = {
      "X-Enghouse-UC-Tenant": getTenantName(),
    };
    const state: RootState = getStore().getState();
    const endpointData = state.provisioning.endpointData;
    let conferenceModerationUrl: string;
    try {
      conferenceModerationUrl =
        endpointData && endpointData.conferenceModeration.length > 0
          ? endpointData.conferenceModeration.substring(endpointData.conferenceModeration.indexOf("/"))
          : `/conference-moderation`;
    } catch (e) {
      conferenceModerationUrl = "/conference-moderation"; //if there is no endpointData->conferenceModeration it should use the old system level url.
    }
    this.apiProvider = new ApiProvider(`${conferenceModerationUrl}/api/v1/`, extraHeaders);
  }

  // API

  public getInfo(): Promise<Info> {
    return this.apiProvider.restApiCall<Info>({
      path: "/info",
      method: "get",
      withToken: false,
    });
  }

  public muteAllParticipantsAudio(roomId: number): Promise<void> {
    return this.apiProvider.restApiCall({
      path: "/mute-audio-all-participants",
      method: "post",
      params: { roomId },
    });
  }

  public disableAllParticipantsAudio(roomId: number, participantId: number): Promise<void> {
    return this.apiProvider.restApiCall({
      path: "disable-audio-all-participants",
      method: "post",
      params: { roomId, participantId },
    });
  }

  public enableAllParticipantsAudio(roomId: number): Promise<void> {
    return this.apiProvider.restApiCall({
      path: "enable-audio-all-participants",
      method: "post",
      params: { roomId },
    });
  }

  public disableAllParticipantsVideo(roomId: number, participantId: number): Promise<void> {
    return this.apiProvider.restApiCall({
      path: "disable-video-all-participants",
      method: "post",
      params: { roomId, participantId },
    });
  }

  public enableAllParticipantsVideo(roomId: number): Promise<void> {
    return this.apiProvider.restApiCall({
      path: "enable-video-all-participants",
      method: "post",
      params: { roomId },
    });
  }

  public muteAllParticipantsVideo(roomId: number): Promise<void> {
    return this.apiProvider.restApiCall({
      path: "/mute-video-all-participants",
      method: "post",
      params: { roomId },
    });
  }

  public muteParticipantAudio(roomId: number, participantId: number): Promise<void> {
    return this.apiProvider.restApiCall({
      path: "/mute-audio-participant",
      method: "post",
      params: { roomId, participantId },
    });
  }

  public muteParticipantVideo(roomId: number, participantId: number): Promise<void> {
    return this.apiProvider.restApiCall({
      path: "/mute-video-participant",
      method: "post",
      params: { roomId, participantId },
    });
  }

  // params: { filterContainsName: '', filterContainsDescription: '' }
  public getAllRoomsInfo(params: any): Promise<RoomInfo[]> {
    return this.apiProvider.restApiCall<RoomInfo[]>({
      path: "/all-rooms-info",
      method: "get",
      params,
    });
  }

  public getRoomInfo(roomId: number): Promise<RoomInfo> {
    return this.apiProvider.restApiCall<RoomInfo>({
      path: "/room-info",
      method: "get",
      params: { roomId },
    });
  }

  public startRecordingConferenceAsync(roomId: number): Promise<string> {
    return this.apiProvider.restApiCall<string>({
      path: "/start-recording-conference",
      method: "post",
      params: { roomId },
    });
  }

  public pauseRecordingConferenceAsync(roomId: number): Promise<string> {
    return this.apiProvider.restApiCall<string>({
      path: "/pause-recording-conference",
      method: "post",
      params: { roomId },
    });
  }

  public resumeRecordingConferenceAsync(roomId: number): Promise<string> {
    return this.apiProvider.restApiCall<string>({
      path: "/resume-recording-conference",
      method: "post",
      params: { roomId },
    });
  }

  public stopRecordingConferenceAsync(roomId: number): Promise<string> {
    return this.apiProvider.restApiCall<string>({
      path: "/stop-recording-conference",
      method: "post",
      params: { roomId },
    });
  }

  public getRecordingConferenceState(roomId: number): Promise<string> {
    return this.apiProvider.restApiCall<string>({
      path: "/conference-recording-state",
      method: "get",
      params: { roomId },
    });
  }

  public getLastConferenceRecording(roomId: number): Promise<RecordingEntry> {
    return this.apiProvider.restApiCall<RecordingEntry>({
      path: "/last-conference-recording",
      method: "get",
      params: { roomId },
    });
  }

  public getAllConferenceRecordings(roomId: number, start: number, limit: number): Promise<RecordingEntry[]> {
    return this.apiProvider.restApiCall<RecordingEntry[]>({
      path: "/all-conference-recordings",
      method: "get",
      params: { roomId, start, limit },
    });
  }

  /// Validate recording state
  public validateRecordingState(value: string): string {
    if (Object.values(RecordingState).includes(value as RecordingState)) {
      return value;
    }

    logger.error(`${this.module}: Error in validateRecordingState - Value '${value}' not recognized as RecordingState`);
    return RecordingState.RECORDING_STATE_ERROR.toString();
  }
}
