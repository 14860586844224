import { MatrixClient } from "matrix-js-sdk";
import { useRef, useEffect, useMemo } from "react";
import { MatrixHandler } from "types/Hooks";
import { getLogger } from "logger/appLogger";

const useEventEmitter = (emitter: MatrixClient, eventName: any, handler: MatrixHandler) => {
  const logger = useMemo(() => getLogger("matrix"), []);
  const module = "useEventEmitter";

  // Create a ref that stores handler
  const savedHandler = useRef(handler);
  // Update ref.current value if handler changes.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    // allow disabling this hook by passing a falsy emitter
    if (!emitter) return;
    if (!eventName) return;

    // Create event listener that calls handler function stored in ref
    const eventListener = (...args: any[]) => {
      savedHandler.current(...args);
    };

    // Add event listener
    emitter.on(eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      logger.debug(`${module}: Removed emitter - ${eventName}`);
      if (emitter) emitter.removeListener(eventName, eventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName, emitter]);
};

export default useEventEmitter;
