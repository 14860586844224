import { LocalMatrixRoom } from "types/Matrix";

import SideBarRoom from "./SideBarRoom";
import { Accordion } from "components";
import { test } from "utils/helpers";

const SideBarRoomList = ({ list, category }: { list: LocalMatrixRoom[]; category: string }) => {
  return (
    <>
      <Accordion id={`accordion-${category}`} defaultExpanded title={category} {...test("SIDEBAR_ROOM_LIST")}>
        {list.map((room) => (
          <SideBarRoom key={room.roomId} {...room} />
        ))}
      </Accordion>
    </>
  );
};

export default SideBarRoomList;
