// ====================================================
// local storage variable stored in user struct
// ====================================================

import { localStorageKeys } from "./constants";
import { isEmpty } from "./helpers";
import { getLogger } from "logger/appLogger";

const logger = getLogger("local-storage");

export class LocalStorageVariableUser<T> {
  private _key: string;

  constructor(key: string) {
    if (isEmpty(key)) {
      throw new Error("Empty key");
    }
    this._key = key;
  }
  public get = (): T | undefined => {
    const key = this.getKey();
    if (key) {
      const str = localStorage.getItem(key);
      const obj = str ? JSON.parse(str) : undefined;
      return obj ? obj[this._key] : undefined;
    } else {
      logger.warn(`LocalStorageVariableUser.get: empty key access for '${this._key}'`);
    }
    return undefined;
  };

  public set = (value: T | null) => {
    const key = this.getKey();
    if (key) {
      const str = localStorage.getItem(key);
      const obj = str ? JSON.parse(str) : {};
      if (value) {
        obj[this._key] = value;
      } else {
        delete obj[this._key];
      }
      localStorage.setItem(key, JSON.stringify(obj));
    } else {
      logger.warn(`LocalStorageVariableUser.set: empty key access for '${this._key}'`);
    }
  };

  public delete = () => {
    this.set(null);
  };

  public ready = () => {
    return !!this.getKey();
  };

  public getKey = () => {
    const currentUserItem = localStorage.getItem(localStorageKeys.CURRENT_USER);
    const currentUser = currentUserItem ? JSON.parse(currentUserItem) : undefined;
    if (currentUser) {
      const { user_id } = currentUser;
      return !isEmpty(user_id) ? localStorageKeys.USER_STORAGE_PREFIX + user_id : undefined;
    }
    return undefined;
  };
}
