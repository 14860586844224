import styles from "./Router.module.scss";
import Button from "@mui/material/Button";
import { Alert, AlertTitle } from "@mui/material";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div className={styles.main}>
      <Paper className={`col col-xl-6 col-lg-8 col-md-10 ${styles.content}`}>
        <Alert severity="error" className={styles.alertContent}>
          <AlertTitle>Something went wrong!</AlertTitle>
          {error?.message}
        </Alert>
        {error?.stack ? (
          <Accordion className={styles.errorStack}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="error-stack" id="error-stack">
              <Typography>Show Error Stack</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Alert severity="error" style={{ marginBottom: "1rem" }}>
                <AlertTitle>Error Stack</AlertTitle>
                {error?.stack}
              </Alert>
            </AccordionDetails>
          </Accordion>
        ) : null}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={resetErrorBoundary}
          className={`${styles.errorButton} ${styles.baseButton}`}
        >
          Reload the app
        </Button>
      </Paper>
    </div>
  );
};

export default ErrorFallback;
