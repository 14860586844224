import axios from "axios";
import { ApiProvider } from "services/Providers/ApiProvider";
import { getTenantName } from "services/Providers/ProvisioningTokenProvider";
import { EndpointData, ProvisioningConfiguration, SearchResult, UserKeycloakData } from "./types/Provisioning";
import {
  CreateChatRoom,
  UpdateChatRoom,
  ChatRoomInfo,
  CreateRoom,
  UpdateRoom,
  RoomInfo,
} from "services/ProvisioningApiProvider/types/Rooms";

export class TenantProvisioningApiProvider {
  private apiProvider: ApiProvider;

  // constructor
  constructor(directRequest?: boolean) {
    if (!directRequest) {
      this.apiProvider = new ApiProvider(`/tenant/${getTenantName()}/tenant-provisioning/api/v1/`);
    } else this.apiProvider = new ApiProvider("");
  }

  // singleton
  private static _instance: TenantProvisioningApiProvider;
  static get Instance(): TenantProvisioningApiProvider {
    if (!this._instance) {
      this._instance = new TenantProvisioningApiProvider();
    }
    return this._instance;
  }

  // Auth API

  public provisioningLogout(refreshToken: string): Promise<string> {
    return this.apiProvider.restApiCall<string>({
      path: "/auth/logout",
      method: "post",
      body: { refresh_token: refreshToken },
      withToken: false,
    });
  }

  // API

  public getEndpointData(): Promise<EndpointData> {
    return this.apiProvider.restApiCall<EndpointData>({
      path: "/endpointData",
      method: "get",
    });
  }

  public getProvisioningConfiguration(): Promise<ProvisioningConfiguration> {
    return this.apiProvider.restApiCall<ProvisioningConfiguration>({
      path: "/tenant-config",
      method: "get",
    });
  }

  public searchUser(query: string): Promise<SearchResult> {
    return this.apiProvider.restApiCall<SearchResult>({
      path: "/users",
      method: "get",
      params: { search: query, includePresence: 1 },
    });
  }

  public searchByUsername(query: string): Promise<UserKeycloakData> {
    return this.apiProvider.restApiCall<UserKeycloakData>({
      path: `user/${query}`,
      method: "get",
    });
  }

  public searchByListOfUsernames(request: string[]): Promise<UserKeycloakData[]> {
    return this.apiProvider.restApiCall<UserKeycloakData[]>({
      path: "users/list",
      method: "post",
      params: { includePresence: 1 },
      body: request,
    });
  }

  public searchByListOfEmails(request: string[]): Promise<UserKeycloakData[]> {
    return this.apiProvider.restApiCall<UserKeycloakData[]>({
      path: "users/list-by-email",
      method: "post",
      params: { includePresence: 1 },
      body: request,
    });
  }

  public createProvisioningGroupChatRoom(createChatRoom: CreateChatRoom): Promise<ChatRoomInfo> {
    return this.apiProvider.restApiCall<ChatRoomInfo>({
      path: "group-chat-rooms",
      method: "post",
      body: createChatRoom,
    });
  }

  public updateProvisioningGroupChatRoom(roomId: string, updateChatRoom: UpdateChatRoom): Promise<void> {
    return this.apiProvider.restApiCall<void>({
      path: `group-chat-rooms/${roomId}`,
      method: "put",
      body: updateChatRoom,
    });
  }

  public createProvisioningRoom(createRoom: CreateRoom): Promise<RoomInfo> {
    return this.apiProvider.restApiCall<RoomInfo>({
      path: "rooms",
      method: "post",
      body: createRoom,
    });
  }

  public updateProvisioningRoom(roomId: string, updateRoom: UpdateRoom): Promise<void> {
    return this.apiProvider.restApiCall<void>({
      path: `rooms/${roomId}`,
      method: "put",
      body: updateRoom,
    });
  }

  public uploadLogFiles(formData: any): Promise<void> {
    const headers = { "Content-Type": "multipart/form-data" };
    return this.apiProvider.restApiCall<void>({
      path: "upload/log",
      method: "post",
      body: formData,
      headers,
    });
  }

  public validateUrl(url: string): Promise<void> {
    const axioxInstance = axios.create({ baseURL: url });
    return axioxInstance.head(`${url}/tenant-provisioning/healthz`);
  }
}
