import {
  DevicesSettings,
  LocalMatrixRoom,
  MappedRoomList,
  MatrixSendMessageError,
  RoomListFilters,
} from "types/Matrix";
import * as actionTypes from "./types/matrix";

export const matrixActions = {
  setCurrentRoom: (roomId: string | null) => ({ type: actionTypes.SET_CURRENTROOM, roomId }),
  uploadMatrixAvatar: (data: any) => ({ type: actionTypes.MATRIX_UPLOAD_AVATAR, data }),
  clearMatrixAvatarError: () => ({ type: actionTypes.MATRIX_UPLOAD_AVATAR_CLEAR_ERROR }),
  playMatrixCallRingtone: () => ({ type: actionTypes.PLAY_MATRIX_CALL_RINGTONE }),
  stopMatrixCallRingtone: () => ({ type: actionTypes.STOP_MATRIX_CALL_RINGTONE }),
  setParticipants: (participants: any[]) => ({
    type: actionTypes.SET_MATRIX_CALL_PARTICIPANTS,
    participants,
  }),
  setMatrixCallRoomId: (roomId: string) => ({ type: actionTypes.SET_MATRIX_CALL_ROOM_ID, roomId }),
  createMatrixCallClient: (opts: any) => ({ type: actionTypes.CREATE_MATRIX_CALL_CLIENT, opts }),
  deleteMatrixCallClient: (callClient: any) => ({
    type: actionTypes.DELETE_MATRIX_CALL_CLIENT,
    callClient,
  }),
  incomingMatrixCall: (callClient: any) => ({
    type: actionTypes.INCOMING_MATRIX_CALL,
    callClient,
  }),
  answerMatrixCall: (opts: any) => ({ type: actionTypes.ANSWER_MATRIX_CALL, opts }),
  rejectMatrixCall: (callClient: any) => ({ type: actionTypes.REJECT_MATRIX_CALL, callClient }),
  deleteIncomingMatrixCall: (callClient: any) => ({
    type: actionTypes.DELETE_INCOMING_MATRIX_CALL,
    callClient,
  }),
  placeMatrixCall: (opts: any) => ({ type: actionTypes.PLACE_MATRIX_CALL, opts }),
  placeMatrixScreenShare: () => ({ type: actionTypes.PLACE_MATRIX_SCREENSHARE }),
  stopMatrixScreenShare: () => ({ type: actionTypes.STOP_MATRIX_SCREENSHARE }),
  hangupMatrixCall: () => ({ type: actionTypes.HANGUP_MATRIX_CALL }),
  muteMatrixAudio: (muted: boolean) => ({ type: actionTypes.MATRIX_CALL_MUTE_AUDIO, muted }),
  muteMatrixVideo: (muted: boolean) => ({ type: actionTypes.MATRIX_CALL_MUTE_VIDEO, muted }),
  muteMatrixSpeaker: (muted: boolean) => ({ type: actionTypes.MATRIX_CALL_MUTE_SPEAKER, muted }),
  updateMatrixCallState: (callClientState: any) => ({
    type: actionTypes.UPDATE_MATRIX_CALL_STATE,
    callClientState,
  }),
  updateMatrixEvent: (matrixEvent: any) => ({
    type: actionTypes.UPDATE_MATRIX_EVENT,
    matrixEvent,
  }),
  subscribeOnMatrixCallState: (callClient: any) => ({
    type: actionTypes.SUBSCRIBE_MATRIX_CALL_STATE,
    callClient,
  }),
  subscribeOnMatrixEvent: (eventName: any) => ({
    type: actionTypes.SUBSCRIBE_MATRIX_EVENT,
    eventName,
  }),
  unsubscribeOnMatrixCallState: (eventEmitter: any) => ({
    type: actionTypes.UNSUBSCRIBE_MATRIX_CALL_STATE,
    eventEmitter,
  }),
  unsubscribeOnMatrixEvent: (eventName: any) => ({
    type: actionTypes.UNSUBSCRIBE_MATRIX_EVENT,
    eventName,
  }),
  updateRoomAvatar: (opts: any) => ({
    type: actionTypes.UPDATE_MATRIX_ROOM_AVATAR,
    opts,
  }),
  setRoomList: (roomList: MappedRoomList) => ({ type: actionTypes.SET_ROOM_LIST, roomList }),
  updateRoomInRoomList: (room: LocalMatrixRoom) => ({ type: actionTypes.UPDATE_ROOM_IN_ROOM_LIST, room }),
  removeRoomFromRoomList: (roomId: string) => ({ type: actionTypes.REMOVE_ROOM_FROM_ROOM_LIST, roomId }),
  setDeviceSettings: (opts: DevicesSettings) => ({ type: actionTypes.SET_DEVICE_SETTINGS, opts }),
  setInMatrixRemoteScreenshare: (inRemoteScreenshare: boolean) => ({
    type: actionTypes.SET_IN_MATRIX_REMOTE_SCREENSHARE,
    inRemoteScreenshare,
  }),
  updateUnreadNotificationCount: (roomId: string, updateNotificationCount: number) => ({
    type: actionTypes.UPDATE_UNREAD_NOTIFICATION_COUNT,
    roomId,
    updateNotificationCount,
  }),
  setChatVisible: (chatVisible: boolean) => ({
    type: actionTypes.SET_CHAT_VISIBLE,
    chatVisible,
  }),
  setRedirectToAdhocConf: (isRedirectToAdhocConf: boolean, roomId?: string) => ({
    type: actionTypes.SET_REDIRECT_TO_ADHOC_CONF,
    isRedirectToAdhocConf,
    roomId,
  }),
  sendMessageErrorData: (data: MatrixSendMessageError | null) => ({ type: actionTypes.SEND_MESSAGE_ERROR_DATA, data }),
  setRoomEventsList: (eventsList: any[]) => ({ type: actionTypes.SET_ROOM_EVENTS_LIST, eventsList }),
  setMatrixCurrentUser: (currentUser: any) => ({ type: actionTypes.SET_CURRENTUSER, currentUser }),
  getCurrentUserProfileInfo: () => ({ type: actionTypes.GET_CURRENTUSER_PROFILEINFO }),
  setMatrixSyncState: (syncState: string) => ({ type: actionTypes.SET_MATRIX_SYNCSTATE, syncState }),
  setRoomListFilter: (filter: RoomListFilters) => ({ type: actionTypes.SET_ROOMLIST_FILTER, filter }),
};
