import { Components, Theme } from "@mui/material";
import { filledInputClasses } from "@mui/material/FilledInput";

const MuiFilledInput: Components["MuiFilledInput"] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const palette = (theme as Theme).palette;
      return {
        backgroundColor: palette.background.filledInputBackground,
        [`&:hover, .${filledInputClasses.focused}, .${filledInputClasses.disabled}`]: {
          backgroundColor: palette.background.filledInputBackground,
        },
      };
    },
    input: ({ ownerState, theme }) => {
      const palette = (theme as Theme).palette;
      return {
        backgroundColor: palette.background.filledInputBackground,
      };
    },
  },
};

export default MuiFilledInput;
