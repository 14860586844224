import React, { useEffect, useState, useMemo } from "react";
import { Detector } from "react-detect-offline";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { useAppSelector } from "store/hooks";

import loadable from "@loadable/component";

import { themeActions } from "store/actions/theme";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import { StyledEngineProvider, ThemeProvider, createTheme } from "@mui/material/styles";

import { SplashScreen } from "components";
import ApplicationScreen from "screens/ApplicationScreen";
import InitialScreen from "screens/InitialScreen/InitialScreen";
import LoginScreen from "screens/LoginScreen/LoginScreen";

import ErrorFallback from "./ErrorFallback";

import { useDarkMode, useLocalStorage, useLogout } from "hooks";
import { getPresenceProvider } from "services/PresenceProvider";
import { ProvisioningTokenProvider } from "services/Providers/ProvisioningTokenProvider";
import { localStorageKeys } from "utils/constants";

import { getLogger } from "logger/appLogger";
import { getTheme } from "themes/theme/baseTheme";

import SnackbarProvider from "components/SnackbarProvider/SnackbarProvider";

const HomePage = loadable(() => import(/* webpackChunkName: "HomePage" */ "../views/home/HomePage"), {
  fallback: <SplashScreen />,
});

const DialpadPage = loadable(() => import(/* webpackChunkName: "HomePage" */ "../views/dialpad/DialpadPage"), {
  fallback: <SplashScreen />,
});

const RoomPage = loadable(() => import(/* webpackChunkName: "RoomPage" */ "../views/room/RoomPage"), {
  fallback: <SplashScreen />,
});

const SettingsPage = loadable(() => import(/* webpackChunkName: "SettingsPage" */ "../views/settings/SettingsPage"), {
  fallback: <SplashScreen />,
});

const MeetingPage = loadable(() => import(/* webpackChunkName: "MeetingPage" */ "../views/meeting/MeetingPage"), {
  fallback: <SplashScreen />,
});

const ErrorPage = loadable(() => import(/* webpackChunkName: "ErrorPage" */ "../views/error/ErrorPage"), {
  fallback: <SplashScreen />,
});

// uncomment to use sip feature test page
// const TestPage = loadable(() => import(/* webpackChunkName: "TestPage" */ "../views/test/TestPage"), {
//   fallback: <SplashScreen />,
// });

const RouterComponent = () => {
  const logger = useMemo(() => getLogger("router"), []);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setTheme, setDarkMode, setPreferSystemScheme } = themeActions;
  const [savedPreference, setPreference] = useLocalStorage<boolean>(localStorageKeys.PREFER_SYSTEM_SCHEME);
  const [savedTheme] = useLocalStorage(localStorageKeys.SAVED_THEME);

  const { doLogout } = useLogout();
  const [theme, darkMode] = useDarkMode();

  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);
  const preferSystemScheme = useAppSelector((state) => state.theme.preferSystemScheme);
  const currentTheme = useAppSelector((state) => state.theme.currentTheme);
  const currentBrand = useAppSelector((state) => state.theme.currentBrand);

  const [showMessageBar, setShowMessageBar] = useState(false);
  const [receivedAuthCode] = useState(false);
  const [runLogout, setRunLogout] = useState(false);

  useEffect(() => {
    if (runLogout) {
      doLogout(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runLogout]);

  useEffect(() => {
    if (preferSystemScheme) {
      dispatch(setTheme(theme));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferSystemScheme, theme]);

  useEffect(() => {
    if (preferSystemScheme) {
      dispatch(setDarkMode(darkMode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferSystemScheme, darkMode]);

  useEffect(() => {
    // logout on token refresh failure
    ProvisioningTokenProvider.Instance.setOnRefreshTokenFailCallback(() => {
      setRunLogout(true);
    });

    if (savedPreference === undefined || savedPreference === null) {
      setPreference(preferSystemScheme);
    } else {
      dispatch(setPreferSystemScheme(savedPreference));
    }
    switch (savedTheme) {
      case "light":
      case "dark":
        dispatch(setTheme(getTheme(currentBrand, savedTheme)));
        dispatch(setDarkMode(savedTheme === "dark" ? true : false));
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const networkChange = (isOnline: boolean) => {
    try {
      logger.debug("isOnline", isOnline);
      setShowMessageBar(true);
      if (isOnline) {
        checkPresence();
        setTimeout(() => {
          setShowMessageBar(false);
        }, 3000);
      }
    } catch (e) {
      logger.error(`Error in Router.networkChange - isOnline:${isOnline} - ${e.message}`);
    }
  };

  const handleClick = () => {
    setShowMessageBar(false);
  };

  const netWorkMessageBar = (online: boolean) => {
    return (
      <>
        {showMessageBar ? (
          <div className={`networkMessageBar ${online ? "online" : "offline"}`}>
            <p className="networkMessage">{`${t("NETWORK_IS_CURRENTLY")} ${online ? t("ONLINE") : t("OFFLINE")}`}</p>
            <IconButton onClick={handleClick} size="large">
              <HighlightOffIcon></HighlightOffIcon>
            </IconButton>
          </div>
        ) : null}
      </>
    );
  };

  const checkPresence = () => {
    try {
      const presenceProvider = getPresenceProvider();
      if (!presenceProvider?.presenceApi?.connection) return;
      const { presenceApi } = presenceProvider;
      presenceApi.createWSKeepAlive();
    } catch (e) {
      logger.error(`Error in Router.checkPresence - ${e.message}`);
    }
  };

  return receivedAuthCode ? (
    <div>{`${t("LOGIN_ATTEMPT_INFO_SUCCESS")}`}</div>
  ) : (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(currentTheme ? currentTheme : theme)}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Detector
              polling={false}
              render={({ online }: { online: boolean }) => netWorkMessageBar(online)}
              onChange={networkChange}
            />

            <HashRouter>
              <Switch>
                <Route path="/InitialScreen" component={InitialScreen} />
                <Route path="/auth" component={LoginScreen} />
                <Route path="/error" component={ErrorPage} />
                {/* <Route path="/test" component={TestPage} /> */}

                {isAuthorized && (
                  <ApplicationScreen>
                    <Switch>
                      <Redirect exact from="/" to="/home" />
                      <Route path="/home" component={HomePage} />
                      <Route path="/room/:roomId" component={RoomPage} />
                      <Route path="/meeting" component={MeetingPage} />
                      <Route path="/history/room/:roomId" component={RoomPage} />
                      <Route path="/history" component={HomePage} />
                      <Route path="/dialpad" component={DialpadPage} />
                      <Route path="/settings" component={SettingsPage} />
                      <Redirect exact from="/room" to="/home" />
                      <Redirect to="construction" />
                    </Switch>
                  </ApplicationScreen>
                )}

                {!isAuthorized && <Redirect to={{ ...window.location, pathname: "/InitialScreen" }} />}
              </Switch>
            </HashRouter>
          </ErrorBoundary>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default RouterComponent;
