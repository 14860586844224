export interface UCUser {
  user_id: string;
  access_token: string;
  home_server: string;
  device_id: string;
  well_known: {
    "m.homeserver": {
      base_url: string;
    };
  };
}

export enum ROOM_INFO_CATEGORIES {
  "Members" = "MEMBERS",
  "Recordings" = "RECORDINGS",
}

export enum CALL_FORWARDING {
  Forward_Call = "FORWARD_CALL",
  Sim_Ring = "SIMULTANEOUS_RING",
}

export enum DEVICES {
  Web = 0,
  Android = 1,
  Ios = 2,
}

export interface CallForwardSelect {
  onBusy: string;
  always: string;
  noAnswer: string;
  notReachable: string;
}

export enum CallType {
  voice = "voice",
  video = "video",
}

export enum CallMediaType {
  video = "video",
  audio = "audio",
  screenshare = "screenshare",
  localCamera = "localCamera",
  remoteCamera = "remoteCamera",
  localWindowShare = "localWindowShare",
  remoteWindowShare = "remoteWindowShare",
}

export enum MediaStreamDevices {
  microphone = "microphone",
  speaker = "speaker",
  camera = "camera",
}

export enum CallActionType {
  Call = "call",
  Transfer = "transfer",
  Conference = "conference",
  Ignore = "ignore",
}

export enum Events {
  END_SIP_CALL = "endSipCall",
  SHARE_CLICKED = "shareClicked",
  RECORDER_ERROR = "recorderError,",
  GOOGLE_SET_CODE = "googleSetCode",
}

// inbound: ringing->accepting->connected
// outboubd: calling->connected
export enum CallingState {
  NONE = "none",
  RINGING = "ringing", // inbound
  ACCEPTING = "accepting", // inbound
  CALLING = "calling", // outbound
  CONNECTED = "connected",
  TERMINATING = "terminating",
}

export interface MutedState {
  microphone: boolean;
  camera: boolean;
  speaker: boolean;
}

export enum TemplateKey {
  "default",
  "banner",
  "invite",
}

export interface TemplateData {
  title: string;
  message: string;
  icon: string | JSX.Element;
}

export type TemplateType = {
  [key in TemplateKey]: (data: TemplateData) => JSX.Element;
};

export enum Themes {
  Light = "light",
  Dark = "dark",
  System = "system",
}

export type TileData = {
  title: string;
  id: string;
  type: CallMediaType;
  stream: any;
  name?: string;
  active?: boolean;
  avatarUrl?: string;
  isSelfView?: boolean;
  isMatrixTile?: boolean;
};
