import { useMatrixRooms, useRouter, useMatrixAvatar } from "hooks";
import { getLogger } from "logger/appLogger";
import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { CalendarEvent } from "services/CalendarProvider/types/CalendarEvent";
import { MeetingServiceProvider } from "services/MeetingServiceProvider/MeetingServiceProvider";
import { callActions } from "store/actions/call";
import { matrixActions } from "store/actions/matrix";
import { useAppSelector } from "store/hooks";
import { CallParticipantsInfo } from "store/types/call";
import { RoomInContacts } from "types/Matrix";
import { isEmpty } from "utils/helpers";
import { JsonStringify, getMeetingParticipantsEmails } from "utils/utils";

interface useMatrixJoinConferenceInterface {
  callStarting: boolean;
  findRoomInContactsByEvent: (event: CalendarEvent) => RoomInContacts | undefined;
  findRoomInContactsByMeetingLink: (meetingLink: string) => RoomInContacts | undefined;
  startConferenceWithRoom: (roomInContacts: RoomInContacts) => Promise<void>;
  startConferenceNoRoomByEvent: (event: CalendarEvent) => Promise<void>;
  startConferenceNoRoomByArgs: (
    meetingLink: string,
    meetingName: string,
    participantsEmails: string[]
  ) => Promise<void>;
  startConferenceByEvent: (event: CalendarEvent) => Promise<void>;
  startConferenceByEventWithPrecallSettings: (event: CalendarEvent) => Promise<void>;
  startConferenceByArgsWithPrecallSettings: (meetingLink: string) => Promise<void>;
  addRoomURLToEvent: (event: CalendarEvent) => Promise<void>;
  getMeetingURL: (link: string) => Promise<string>;
  getMeetingURLWithMeetingId: (meetingId: string) => Promise<string | undefined>;
  preCallSettingsVisible: boolean;
  setPreCallSettingsVisible: (value: boolean) => void;
  updateParticipantsInfo: (participant: { isLocal: boolean; name: string | null }) => void;
}

const useMatrixJoinConference = (): useMatrixJoinConferenceInterface => {
  const logger = useMemo(() => getLogger("matrix.joinconference"), []);
  const dispatch = useDispatch();
  const { getRoomsWithPortalLink } = useMatrixRooms();
  const { getRoomMemberAvatar } = useMatrixAvatar();
  const { push } = useRouter();
  const currentUser = useAppSelector((state) => state.matrix.currentUser);
  const isCallActive = useAppSelector((state) => state.call.active);
  const callRoomId = useAppSelector((state) => state.call.callRoomId);
  const participantsInfo = useAppSelector((state) => state.call.participantsInfo);
  const endpointData = useAppSelector((state) => state.provisioning.endpointData);
  const [callStarting, setCallStarting] = useState(false);
  const [preCallSettingsVisible, setPreCallSettingsVisible] = useState(false);

  const findRoomInContactsByEvent = (event: CalendarEvent): RoomInContacts | undefined => {
    let inContacts = undefined;
    if (event.hasMeetingLink) {
      const rooms = getRoomsWithPortalLink();
      inContacts = rooms.find((room) => {
        return room.shareURL === event.location;
      });
    }
    logger.debug(`findRoomInContactsByEvent: ${inContacts ? JsonStringify(inContacts) : null}`);
    return inContacts;
  };

  const findRoomInContactsByMeetingLink = (meetingLink: string): RoomInContacts | undefined => {
    let inContacts = undefined;
    if (!isEmpty(meetingLink)) {
      const rooms = getRoomsWithPortalLink();
      inContacts = rooms.find((room) => {
        return room.portalLink === meetingLink;
      });
    }
    logger.debug(`findRoomInContactsByMeetingLink: ${inContacts ? JsonStringify(inContacts) : null}`);
    return inContacts;
  };

  const findRoomInContactsByShareLink = (shareLink: string): RoomInContacts | undefined => {
    let inContacts = undefined;
    if (!isEmpty(shareLink)) {
      const rooms = getRoomsWithPortalLink();
      inContacts = rooms.find((room) => {
        return room.shareURL === shareLink;
      });
    }
    logger.debug(`findRoomInContactsByShareLink: ${inContacts ? JsonStringify(inContacts) : null}`);
    return inContacts;
  };

  const startConferenceWithRoom = async (roomInContacts: RoomInContacts) => {
    logger.debug(`startConferenceWithRoom: ${JsonStringify(roomInContacts)}`);
    dispatch(matrixActions.setCurrentRoom(roomInContacts.id));
    dispatch(callActions.outCall());
    push(`/room/${roomInContacts.id}/joinCall`);
  };

  const startConferenceNoRoomByArgs = async (
    meetingLink: string,
    meetingName: string,
    participantsEmails: string[]
  ) => {
    logger.debug(`startConferenceNoRoomByArgs: meetingLink=${meetingLink} meetingName=${meetingName}`);
    if (!isEmpty(meetingLink)) {
      const parsedURL = meetingLink.split("/join/");
      const portal = parsedURL[0];
      const roomKey = parsedURL[1];

      const opts = {
        data: {
          host: portal.replace("https://", ""),
          roomKey: roomKey,
          displayName: currentUser ? currentUser.displayName : "Enghouse Connect User",
          roomPin: "",
          hasExtData: false,
        },
        outCall: true,
        meetingName,
        participantsEmails,
      };

      dispatch(callActions.startCall(opts));
      setCallStarting(true);
    }
  };

  const startConferenceNoRoomByEvent = async (event: CalendarEvent) => {
    logger.debug(`startConferenceNoRoomByEvent: ${JsonStringify(event)}`);
    if (event.hasMeetingLink) {
      await startConferenceNoRoomByArgs(
        event.roomURL,
        event.summary || event.subject,
        getMeetingParticipantsEmails(event.attendees)
      );
    }
  };

  const startConferenceByEvent = async (event: CalendarEvent) => {
    logger.debug(`startConferenceByEvent: ${JsonStringify(event)}`);
    if (event.hasMeetingLink) {
      const inContacts = findRoomInContactsByEvent(event);
      if (inContacts) {
        await startConferenceWithRoom(inContacts);
      } else {
        await startConferenceNoRoomByEvent(event);
      }
    }
  };

  const startConferenceByEventWithPrecallSettings = async (event: CalendarEvent) => {
    logger.debug(`startConferenceByEventWithPrecallSettings: ${JsonStringify(event)}`);
    if (event.hasMeetingLink) {
      const inContacts = findRoomInContactsByEvent(event);
      if (inContacts) {
        await startConferenceWithRoom(inContacts);
      } else {
        setPreCallSettingsVisible(true);
      }
    }
  };

  const addRoomURLToEvent = async (event: CalendarEvent) => {
    logger.debug(`addRoomURLToEvent: ${JsonStringify(event)}`);

    if (isEmpty(event.roomURL)) {
      let roomURL = event.location;

      event.roomURL = await getMeetingURL(roomURL);
    }
  };

  const getMeetingURL = async (link: string) => {
    const roomInContacts = findRoomInContactsByShareLink(link);

    if (roomInContacts) {
      return roomInContacts.portalLink;
    } else {
      if (link.indexOf(endpointData?.portal!) !== -1) {
        return link;
      } else {
        try {
          const meetingInfo = await MeetingServiceProvider.Instance().getMeetingInfoWithShareLink(link);

          return `${meetingInfo.portalUrl}/join/${meetingInfo.roomKey}`;
        } catch (e) {
          logger.error(`Error in getMeetingURL - ${e.message}`);
          return link;
        }
      }
    }
  };

  const getMeetingURLWithMeetingId = async (meetingId: string) => {
    try {
      const meetingInfo = await MeetingServiceProvider.Instance().getMeetingInfo(meetingId);

      const meetingLink = `${meetingInfo.portalUrl}/join/${meetingInfo.roomKey}`;

      return meetingLink;
    } catch (e) {
      logger.error(`Error in getMeetingURLWithMeetingId - ${e.message}`);
    }
  };

  const startConferenceByArgsWithPrecallSettings = async (meetingLink: string) => {
    logger.debug(`startConferenceByArgsWithPrecallSettings: ${meetingLink}`);
    const inContacts = findRoomInContactsByMeetingLink(meetingLink);
    if (inContacts) {
      await startConferenceWithRoom(inContacts);
    } else {
      setPreCallSettingsVisible(true);
    }
  };

  const updateParticipantsInfo = (participant: { isLocal: boolean; name: string | null }) => {
    try {
      if (callRoomId && participant && !participant.isLocal) {
        // checks if the participant is a member of the room,
        // checking in participantsInfo, filled with information from all members of the room
        const participantInfo = participantsInfo.find(
          (info) => info.displayName?.toLowerCase() === participant.name?.toLowerCase()
        );
        if (participantInfo && participantInfo.userId) {
          const participant: CallParticipantsInfo = JSON.parse(JSON.stringify(participantInfo));
          // get the avatar from the room, and update the participantInfo
          const avatar = getRoomMemberAvatar(callRoomId, participantInfo.userId);
          participant.avatarUrl = avatar ?? "";
          dispatch(callActions.updateParticipantInfo(participant));
        }
      }
    } catch (e) {
      logger.error(`Error in updateParticipantsList - ${e.message}`);
    }
  };

  useEffect(() => {
    if (isCallActive) {
      setCallStarting(false);
    } else {
      setPreCallSettingsVisible(false);
    }
  }, [isCallActive]);

  return {
    callStarting,
    findRoomInContactsByEvent,
    findRoomInContactsByMeetingLink,
    startConferenceWithRoom,
    startConferenceNoRoomByEvent,
    startConferenceNoRoomByArgs,
    startConferenceByEvent,
    startConferenceByEventWithPrecallSettings,
    startConferenceByArgsWithPrecallSettings,
    addRoomURLToEvent,
    getMeetingURL,
    getMeetingURLWithMeetingId,
    preCallSettingsVisible,
    setPreCallSettingsVisible,
    updateParticipantsInfo,
  };
};

export default useMatrixJoinConference;
