import * as actionTypes from "../actions/types/devices";
import { DevicesState } from "store/types/devices";
import { Types } from "Types";

const initialState: DevicesState = {
  cameras: [],
  remoteCameras: [],
  microphones: [],
  remoteMicrophones: [],
  speakers: [],
  isCameraDisabled: false,
  isCameraTurnedOn: false,
  cameraDisableReasons: [],
  cameraStateChanging: false,
  isMicrophoneDisabled: false,
  isMicrophoneTurnedOn: false,
  microphoneDisableReasons: [],
  microphoneStateChanging: false,
  isSpeakerDisabled: false,
  isSpeakerTurnedOn: true,
  speakerDisableReasons: [],
  speakerStateChanging: false,
  selectedCamera: null,
  selectedMicrophone: null,
  selectedSpeaker: null,
};

const devicesReducer = (state = initialState, action: Types.RootAction): DevicesState => {
  switch (action.type) {
    case actionTypes.UPDATE_CAMERAS:
      const cameras = JSON.parse(JSON.stringify(action.cameras));
      return {
        ...state,
        cameras: cameras,
        selectedCamera: cameras.find((d: { selected: any }) => d.selected) || null,
      };
    case actionTypes.UPDATE_REMOTE_CAMERAS:
      return {
        ...state,
        remoteCameras: JSON.parse(JSON.stringify(action.remoteCameras)),
      };
    case actionTypes.UPDATE_MICROPHONES:
      const microphones = JSON.parse(JSON.stringify(action.microphones));
      return {
        ...state,
        microphones: microphones,
        selectedMicrophone: microphones.find((d: { selected: any }) => d.selected) || null,
      };
    case actionTypes.UPDATE_REMOTE_MICROPHONES:
      return {
        ...state,
        remoteMicrophones: JSON.parse(JSON.stringify(action.remoteMicrophones)),
      };

    case actionTypes.UPDATE_SPEAKERS:
      const speakers = JSON.parse(JSON.stringify(action.speakers));
      return {
        ...state,
        speakers: speakers,
        selectedSpeaker: speakers.find((d: { selected: any }) => d.selected) || null,
      };

    case actionTypes.ENABLE_CAMERA: {
      const cameraDisableReasons = state.cameraDisableReasons.filter((reason) => reason !== action.disableReason);
      return {
        ...state,
        cameraDisableReasons,
        isCameraDisabled: !!cameraDisableReasons.length,
      };
    }

    case actionTypes.ENABLE_MICROPHONE: {
      const microphoneDisableReasons = state.microphoneDisableReasons.filter(
        (reason) => reason !== action.disableReason
      );
      return {
        ...state,
        microphoneDisableReasons,
        isMicrophoneDisabled: !!microphoneDisableReasons.length,
      };
    }

    case actionTypes.ENABLE_SPEAKER: {
      const speakerDisableReasons = state.speakerDisableReasons.filter((reason) => reason !== action.disableReason);
      return {
        ...state,
        speakerDisableReasons,
        isSpeakerDisabled: !!speakerDisableReasons.length,
      };
    }

    case actionTypes.DISABLE_CAMERA:
      return {
        ...state,
        cameraStateChanging: true,
      };

    case actionTypes.DISABLE_CAMERA_SUCCEEDED: {
      const cameraDisableReasons = new Set([...state.cameraDisableReasons, action.disableReason]);
      return {
        ...state,
        isCameraDisabled: true,
        cameraStateChanging: false,
        cameraDisableReasons: [...cameraDisableReasons],
      };
    }

    case actionTypes.DISABLE_CAMERA_FAILED:
      return {
        ...state,
        cameraStateChanging: false,
      };

    case actionTypes.DISABLE_MICROPHONE:
      return {
        ...state,
        microphoneStateChanging: true,
      };

    case actionTypes.DISABLE_MICROPHONE_SUCCEEDED: {
      const microphoneDisableReasons = new Set([...state.microphoneDisableReasons, action.disableReason]);
      return {
        ...state,
        isMicrophoneDisabled: true,
        microphoneStateChanging: false,
        microphoneDisableReasons: [...microphoneDisableReasons],
      };
    }

    case actionTypes.DISABLE_MICROPHONE_FAILED:
      return {
        ...state,
        microphoneStateChanging: false,
      };

    case actionTypes.DISABLE_SPEAKER:
      return {
        ...state,
        speakerStateChanging: true,
      };

    case actionTypes.DISABLE_SPEAKER_SUCCEEDED:
      return {
        ...state,
        isSpeakerDisabled: true,
        speakerStateChanging: false,
        speakerDisableReasons: [...state.speakerDisableReasons, action.disableReason],
      };

    case actionTypes.DISABLE_SPEAKER_FAILED:
      return {
        ...state,
        speakerStateChanging: false,
      };

    case actionTypes.SELECT_CAMERA:
      return {
        ...state,
        cameraStateChanging: true,
      };

    case actionTypes.SELECT_CAMERA_SUCCEEDED:
    case actionTypes.SELECT_CAMERA_FAILED:
      return {
        ...state,
        cameraStateChanging: false,
      };

    case actionTypes.SELECT_MICROPHONE:
      return {
        ...state,
        microphoneStateChanging: true,
      };

    case actionTypes.SELECT_MICROPHONE_SUCCEEDED:
    case actionTypes.SELECT_MICROPHONE_FAILED:
      return {
        ...state,
        microphoneStateChanging: false,
      };

    case actionTypes.SELECT_SPEAKER:
      return {
        ...state,
        speakerStateChanging: true,
      };

    case actionTypes.SELECT_SPEAKER_SUCCEEDED:
    case actionTypes.SELECT_SPEAKER_FAILED:
      return {
        ...state,
        speakerStateChanging: false,
      };

    case actionTypes.CAMERA_TURN_ON:
      return {
        ...state,
        isCameraTurnedOn: false,
        cameraStateChanging: true,
      };

    case actionTypes.CAMERA_TURN_ON_SUCCEEDED:
      return {
        ...state,
        isCameraTurnedOn: true,
        cameraStateChanging: false,
      };

    case actionTypes.CAMERA_TURN_ON_FAILED:
      return {
        ...state,
        isCameraTurnedOn: false,
        cameraStateChanging: false,
      };

    case actionTypes.CAMERA_TURN_OFF:
      return {
        ...state,
        isCameraTurnedOn: true,
        cameraStateChanging: true,
      };

    case actionTypes.CAMERA_TURN_OFF_SUCCEEDED:
    case actionTypes.CAMERA_TURN_OFF_FAILED:
      return {
        ...state,
        isCameraTurnedOn: false,
        cameraStateChanging: false,
      };

    case actionTypes.MICROPHONE_TURN_ON:
      return {
        ...state,
        isMicrophoneTurnedOn: false,
        microphoneStateChanging: true,
      };

    case actionTypes.MICROPHONE_TURN_ON_SUCCEEDED:
      return {
        ...state,
        isMicrophoneTurnedOn: true,
        microphoneStateChanging: false,
      };

    case actionTypes.MICROPHONE_TURN_ON_FAILED:
      return {
        ...state,
        isMicrophoneTurnedOn: false,
        microphoneStateChanging: false,
      };

    case actionTypes.MICROPHONE_TURN_OFF:
      return {
        ...state,
        isMicrophoneTurnedOn: true,
        microphoneStateChanging: true,
      };

    case actionTypes.MICROPHONE_TURN_OFF_SUCCEEDED:
    case actionTypes.MICROPHONE_TURN_OFF_FAILED:
      return {
        ...state,
        isMicrophoneTurnedOn: false,
        microphoneStateChanging: false,
      };

    case actionTypes.SPEAKER_TURN_ON:
      return {
        ...state,
        isSpeakerTurnedOn: false,
        speakerStateChanging: true,
      };

    case actionTypes.SPEAKER_TURN_ON_SUCCEEDED:
      return {
        ...state,
        isSpeakerTurnedOn: true,
        speakerStateChanging: false,
      };

    case actionTypes.SPEAKER_TURN_ON_FAILED:
      return {
        ...state,
        isSpeakerTurnedOn: false,
        speakerStateChanging: false,
      };

    case actionTypes.SPEAKER_TURN_OFF:
      return {
        ...state,
        isSpeakerTurnedOn: true,
        speakerStateChanging: true,
      };

    case actionTypes.SPEAKER_TURN_OFF_SUCCEEDED:
    case actionTypes.SPEAKER_TURN_OFF_FAILED:
      return {
        ...state,
        isSpeakerTurnedOn: false,
        speakerStateChanging: false,
      };

    default:
      return state;
  }
};

export default devicesReducer;
