import { RootState } from "./root-reducer";
import { UiSipCall, UiSipCallInfo, UiSipPeer } from "types/SIP";
import { getRoomFromRoomList } from "utils/utils";

export const selectDetached = (state: RootState, pathname: string) => {
  return (
    selectDetachedSipCall(state, pathname) ||
    (state.call.active && state.call.callRoomId && state.matrix.roomId !== state.call.callRoomId) ||
    (state.matrix.hasActiveMatrixCall && state.matrix.roomId !== state.matrix.matrixCallRoomId)
  );
};

export const selectDialpadActive = (pathname: string) => {
  return pathname === "/dialpad";
};

// display detached (minimized) sipphone display in upper left corner?
export const selectDetachedSipCall = (state: RootState, pathname: string) => {
  const sipCallActiveNotHeld = getSipCallActive(state, false);
  return sipCallActiveNotHeld && !selectDialpadActive(pathname);
};

export const getSipCallActive = (state: RootState, includeActiveHeld: boolean): UiSipCall | undefined => {
  let activeCall = state.sip.sipCalls.find((call) => call.active);
  if (!activeCall && includeActiveHeld) {
    activeCall = state.sip.sipCalls.find((call) => call.held && call.lastHeld);
  }
  return activeCall;
};

// fix for "Selector unknown returned a different result when called with the same parameters. This can lead to unnecessary rerenders.""
export const EMPTY_CALLS_HELD = [];

export const getSipCallsHeld = (state: RootState, excludeActiveHeld: boolean): UiSipCall[] => {
  // dialpad active => excludeActiveHeld (since displayed in UI)
  // dialpad not active => show all held calls
  // dialpad active and have active call  => show all held calls
  // dialpad active and have 1 held call => show none
  // dialpad active and have 2 held calls => show held not displayed => held && not lastHeld

  let heldCalls = state.sip.sipCalls.filter((call) => call.held);
  if (excludeActiveHeld) {
    const activeCall = getSipCallActive(state, true);
    if (activeCall && activeCall.held) {
      heldCalls = heldCalls.filter((call) => call.id !== activeCall.id);
    }
  }
  return heldCalls.length > 0 ? heldCalls : EMPTY_CALLS_HELD;
};

export const getActiveIncomingCallInfo = (state: RootState, callId: string): UiSipCallInfo | undefined => {
  const activeIncomingSipCall = state.sip.incomingSipCalls.find((call) => call.id === callId);
  return activeIncomingSipCall;
};

export const getLocalRoom = (state: RootState, roomId: string) => {
  return getRoomFromRoomList(state.matrix.roomList, roomId);
};

export const getSipExtension = (state: RootState): string | undefined => {
  return state.pbx?.pbxConfig?.hostedPbxConfig?.userExtension;
};

export const getSipLocalPeer = (state: RootState): UiSipPeer | null => {
  return state.sip?.localPeer;
};
