import { common } from "@mui/material/colors";
import { CustomPalette } from "themes/theme/types";
import accent from "./palette/accent";
import brand from "./palette/brand";
import neutrals from "./palette/neutrals";
import sentiment from "./palette/sentiment";

const enghouse: CustomPalette = {
  light: {
    mode: "light",
    neutrals,
    accent,
    brand,
    sentiment,
    background: {
      default: neutrals.lightGrey2,
      secondary: neutrals.lightGrey1,
      paper: neutrals.pureWhite,
      snackbarBackground: neutrals.darkGrey4,
      backdrop: "rgba(0, 0, 0, 0.6)",
      dark: "rgba(0, 0, 0, 0.8)",
      filledInputBackground: neutrals.pureWhite,
      icons: neutrals.pureWhite,
      widgets: neutrals.pureWhite,
      detached: neutrals.pureWhite,
      expanded: neutrals.lightGrey1,
      paperWithOpacity: "rgb(233, 235, 236, 0.95)",
      menu: {
        selected: accent.accentBS1,
        notSelected: neutrals.pureWhite,
        hover: neutrals.lightGrey1,
      },
      button: {
        contained: {
          active: brand.primary.light,
          hover: accent.accentBP5,
          disabled: neutrals.lightGrey2,
        },
        outlined: {
          active: neutrals.pureWhite,
          hover: neutrals.lightGrey1,
          disabled: neutrals.lightGrey1,
        },
        text: {
          active: neutrals.pureWhite,
          hover: neutrals.lightGrey2,
          disabled: neutrals.pureWhite,
        },
      },
      chip: {
        filled: {
          default: accent.accentBS1,
          primary: accent.accentBS3,
        },
        outlined: {
          default: accent.accentBS1,
          primary: accent.accentBS3,
        },
      },
      filterChip: {
        selected: accent.accentBS2,
        notSelected: neutrals.pureWhite,
      },
      radioAndCheckbox: {
        selected: accent.accentBS3,
        notSelected: neutrals.darkGrey4,
        disabled: neutrals.darkGrey2,
        focused: neutrals.lightGrey2,
        hover: neutrals.lightGrey1,
      },
      switch: {
        notSelected: neutrals.pureWhite,
        notSelectedSlide: neutrals.lightGrey6,
        selected: accent.accentBS3,
        selectedSlide: accent.accentBS2,
        disabled: neutrals.pureWhite,
        disabledSlide: neutrals.lightGrey3,
        hover: neutrals.lightGrey1,
        focused: neutrals.lightGrey2,
      },
      navLink: {
        selected: accent.accentBS2,
        hover: accent.accentBS2,
      },
      chat: {
        currentUser: common.white,
        otherUser: accent.accentBS2,
        currentUserReply: "rgba(0, 0, 0, 0.16)",
        otherUserReply: accent.accentBS2,
        messageError: sentiment.error.light1,
      },
      callTiles: {
        participantName: neutrals.regularBlack,
        youLabel: "#25814A",
      },
    },
    primary: {
      main: brand.primary.light,
      light: accent.accentBP4,
      dark: accent.accentBP5,
      contrastText: "#fff",
    },
    secondary: {
      main: brand.secondary.light,
      light: accent.accentBS2,
      dark: accent.accentBS3,
      contrastText: neutrals.almostBlack,
    },
    error: {
      main: sentiment.error.base,
      light: sentiment.error.light1,
      dark: sentiment.error.dark1,
      contrastText: sentiment.error.dark2,
    },
    warning: {
      main: sentiment.warning.base,
      light: sentiment.warning.light1,
      dark: sentiment.warning.dark1,
      contrastText: sentiment.warning.dark2,
    },
    info: {
      main: sentiment.inform.base,
      light: sentiment.inform.light1,
      dark: sentiment.inform.dark1,
      contrastText: sentiment.inform.dark2,
    },
    success: {
      main: sentiment.success.base,
      light: sentiment.success.light1,
      dark: sentiment.success.dark1,
      contrastText: sentiment.success.dark2,
    },
    text: {
      regular: neutrals.almostBlack,
      primary: neutrals.darkGrey6,
      secondary: neutrals.darkGrey4,
      mainTitle: "090A0B",
      subTitle: accent.accentBS5,
      subText: neutrals.regularBlack,
      disabled: neutrals.darkGrey3,
      inactive: neutrals.darkGrey1,
      link: brand.primary.light,
      error: sentiment.error.dark1,
      focused: accent.accentBS4,
      callTiles: "#FBFBFD",
      button: {
        contained: {
          active: neutrals.pureWhite,
          hover: neutrals.pureWhite,
          disabled: neutrals.darkGrey3,
        },
        outlined: {
          active: brand.primary.light,
          hover: accent.accentBS4,
          disabled: neutrals.darkGrey3,
        },
        text: {
          active: accent.accentBS3,
          hover: accent.accentBS4,
          disabled: neutrals.darkGrey3,
        },
      },
      chip: {
        filled: {
          default: neutrals.darkGrey6,
          primary: neutrals.pureWhite,
        },
        outlined: {
          default: neutrals.darkGrey6,
          primary: neutrals.darkGrey6,
        },
      },
      filterChip: {
        selected: neutrals.darkGrey6,
        notSelected: accent.accentBS3,
      },
      navLink: {
        primary: neutrals.regularBlack,
        secondary: neutrals.darkGrey4,
      },
      chat: {
        currentUser: neutrals.darkGrey6,
        otherUser: neutrals.darkGrey6,
        currentUserReply: sentiment.inform.dark2,
        otherUserReply: sentiment.inform.dark2,
        mention: sentiment.inform.dark2,
        inputPlaceholder: neutrals.darkGrey2,
      },
    },
    border: {
      button: {
        contained: {
          active: brand.primary.light,
          hover: accent.accentBP5,
          disabled: neutrals.lightGrey4,
        },
        outlined: {
          active: accent.accentBS3,
          hover: accent.accentBS4,
          disabled: neutrals.lightGrey2,
        },
        text: {
          active: neutrals.pureWhite,
          hover: neutrals.lightGrey2,
          disabled: neutrals.pureWhite,
        },
      },
      input: {
        notSelected: neutrals.darkGrey3,
        hover: neutrals.darkGrey3,
        focused: accent.accentBS3,
        disabled: neutrals.lightGrey4,
        error: sentiment.error.dark1,
      },
      searchField: {
        notSelected: "#CED4DA",
        focused: accent.accentBS3,
      },
      drawer: neutrals.lightGrey5,
      chip: {
        filled: {
          default: accent.accentBS1,
          primary: accent.accentBS3,
        },
        outlined: {
          default: brand.secondary.light,
          primary: accent.accentBS3,
        },
      },
      filterChip: {
        selected: brand.secondary.light,
        notSelected: accent.accentBS2,
      },
      navLink: brand.secondary.light,
      accordion: neutrals.lightGrey5,
      chat: {
        currentUser: neutrals.lightGrey4,
        currentUserReply: accent.accentBS3,
        otherUserReply: accent.accentBS3,
        inputBorderColor: neutrals.lightGrey4,
        inputBorderFocusColor: accent.accentBS3,
        messageError: sentiment.error.base,
      },
      switch: {
        selected: accent.accentBS3,
        notSelected: neutrals.lightGrey6,
        disabled: neutrals.lightGrey3,
      },
    },
    icon: {
      main: accent.accentBS3,
      primary: neutrals.darkGrey2,
      secondary: neutrals.almostBlack,
      selected: accent.accentBS2,
      disabled: "#616161",
      off: "#E84B40",
      navLink: {
        notSelected: accent.accentBS3,
        selected: neutrals.almostBlack,
      },
      calls: {
        incoming: sentiment.success.dark1,
        outgoing: sentiment.inform.dark1,
        missed: sentiment.error.base,
      },
      notification: {
        error: sentiment.error.base,
        logout: sentiment.error.base,
        login: sentiment.success.base,
      },
      integrations: {
        connected: sentiment.success.base,
      },
    },
  },
  dark: {
    mode: "dark",
    neutrals,
    accent,
    brand,
    sentiment,
    background: {
      default: neutrals.darkGrey6,
      secondary: neutrals.darkGrey5,
      paper: neutrals.darkGrey5,
      snackbarBackground: neutrals.darkGrey4,
      backdrop: "rgba(256, 256, 256, 0.16)",
      dark: neutrals.darkGrey4,
      filledInputBackground: neutrals.darkGrey5,
      icons: neutrals.almostBlack,
      widgets: neutrals.darkGrey6,
      detached: neutrals.darkGrey4,
      expanded: neutrals.almostBlack,
      paperWithOpacity: "rgba(0, 0, 0, 0.7)",
      menu: {
        selected: accent.accentBS5,
        notSelected: neutrals.darkGrey4,
        hover: neutrals.darkGrey4,
      },
      button: {
        contained: {
          active: brand.primary.dark,
          hover: accent.accentBP3,
          disabled: neutrals.darkGrey5,
        },
        outlined: {
          active: neutrals.darkGrey6,
          hover: neutrals.darkGrey6,
          disabled: neutrals.darkGrey6,
        },
        text: {
          active: neutrals.darkGrey6,
          hover: neutrals.darkGrey6,
          disabled: neutrals.darkGrey6,
        },
      },
      chip: {
        filled: {
          default: accent.accentBS5,
          primary: accent.accentBS2,
        },
        outlined: {
          default: accent.accentBS5,
          primary: neutrals.darkGrey6,
        },
      },
      filterChip: {
        notSelected: neutrals.darkGrey5,
        selected: accent.accentBS6,
      },
      radioAndCheckbox: {
        selected: accent.accentBS2,
        notSelected: neutrals.lightGrey4,
        disabled: neutrals.darkGrey1,
        focused: neutrals.darkGrey4,
        hover: neutrals.darkGrey5,
      },
      switch: {
        notSelected: neutrals.lightGrey1,
        notSelectedSlide: neutrals.darkGrey1,
        selected: accent.accentBS2,
        selectedSlide: accent.accentBS3,
        disabled: neutrals.darkGrey5,
        disabledSlide: neutrals.darkGrey4,
        hover: neutrals.darkGrey5,
        focused: neutrals.darkGrey4,
      },
      navLink: {
        selected: accent.accentBS6,
        hover: neutrals.darkGrey4,
        notSelected: neutrals.almostBlack,
      },
      chat: {
        currentUser: neutrals.almostBlack,
        otherUser: accent.accentBS5,
        currentUserReply: "rgba(255, 255, 255, 0.16)",
        otherUserReply: "rgba(0, 0, 0, 0.16)",
        messageError: sentiment.error.dark2,
      },
      callTiles: {
        participantName: neutrals.regularBlack,
        youLabel: "#25814A",
      },
    },
    primary: {
      main: brand.primary.dark,
      light: brand.primary.dark,
      dark: brand.primary.dark,
      contrastText: "#fff",
    },
    secondary: {
      main: brand.secondary.dark,
      light: accent.accentBS2,
      dark: accent.accentBS6,
      contrastText: "#fff",
    },
    error: {
      main: sentiment.error.base,
      light: sentiment.error.light2,
      dark: sentiment.error.dark2,
      contrastText: sentiment.error.light1,
    },
    warning: {
      main: sentiment.warning.base,
      light: sentiment.warning.light2,
      dark: sentiment.warning.dark2,
      contrastText: sentiment.warning.light1,
    },
    info: {
      main: sentiment.inform.base,
      light: sentiment.inform.light2,
      dark: sentiment.inform.dark2,
      contrastText: sentiment.inform.light1,
    },
    success: {
      main: sentiment.success.base,
      light: sentiment.success.light2,
      dark: sentiment.success.dark2,
      contrastText: sentiment.success.light1,
    },
    text: {
      regular: neutrals.pureWhite,
      primary: neutrals.lightGrey1,
      secondary: neutrals.lightGrey4,
      mainTitle: neutrals.pureWhite,
      subTitle: accent.accentBS2,
      subText: neutrals.lightGrey1,
      disabled: neutrals.darkGrey1,
      inactive: neutrals.lightGrey4,
      error: sentiment.error.light2,
      focused: accent.accentBS2,
      link: accent.accentBP2,
      callTiles: "#FBFBFD",
      button: {
        contained: {
          active: neutrals.almostBlack,
          hover: neutrals.almostBlack,
          disabled: neutrals.darkGrey1,
        },
        outlined: {
          active: accent.accentBP2,
          hover: brand.secondary.light,
          disabled: neutrals.darkGrey1,
        },
        text: {
          active: accent.accentBS2,
          hover: brand.secondary.light,
          disabled: neutrals.darkGrey1,
        },
      },
      chip: {
        filled: {
          default: neutrals.lightGrey1,
          primary: neutrals.darkGrey6,
        },
        outlined: {
          default: neutrals.lightGrey1,
          primary: neutrals.lightGrey1,
        },
      },
      filterChip: {
        selected: neutrals.lightGrey3,
        notSelected: accent.accentBS2,
      },
      navLink: {
        primary: neutrals.lightGrey1,
        secondary: neutrals.lightGrey4,
      },
      chat: {
        currentUser: neutrals.lightGrey1,
        otherUser: neutrals.lightGrey1,
        currentUserReply: sentiment.inform.light2,
        otherUserReply: sentiment.inform.light2,
        mention: sentiment.inform.light2,
        inputPlaceholder: neutrals.lightGrey2,
      },
    },
    border: {
      button: {
        contained: {
          active: accent.accentBP2,
          hover: accent.accentBP3,
          disabled: neutrals.darkGrey3,
        },
        outlined: {
          active: accent.accentBS2,
          hover: accent.accentBS2,
          disabled: neutrals.darkGrey3,
        },
        text: {
          active: neutrals.darkGrey6,
          hover: neutrals.darkGrey6,
          disabled: neutrals.darkGrey6,
        },
      },
      input: {
        notSelected: neutrals.lightGrey4,
        hover: neutrals.lightGrey4,
        focused: accent.accentBS2,
        disabled: neutrals.darkGrey1,
        error: sentiment.error.light2,
      },
      searchField: {
        notSelected: neutrals.darkGrey3,
        focused: accent.accentBS2,
      },
      drawer: neutrals.almostBlack,
      chip: {
        filled: {
          default: accent.accentBS5,
          primary: accent.accentBS2,
        },
        outlined: {
          default: brand.secondary.light,
          primary: accent.accentBS2,
        },
      },
      filterChip: {
        selected: brand.secondary.light,
        notSelected: accent.accentBS4,
      },
      navLink: brand.secondary.light,
      accordion: neutrals.darkGrey4,
      chat: {
        currentUser: neutrals.darkGrey3,
        currentUserReply: accent.accentBS2,
        otherUserReply: accent.accentBS2,
        inputBorderColor: neutrals.darkGrey4,
        inputBorderFocusColor: accent.accentBS2,
        messageError: sentiment.error.base,
      },
      switch: {
        selected: accent.accentBS2,
        notSelected: neutrals.darkGrey1,
        disabled: neutrals.darkGrey4,
      },
    },
    icon: {
      main: accent.accentBS2,
      primary: neutrals.lightGrey2,
      secondary: neutrals.darkGrey4,
      selected: neutrals.pureWhite,
      disabled: "#616161",
      off: "#E84B40",
      navLink: {
        selected: neutrals.lightGrey1,
        notSelected: accent.accentBS2,
      },
      calls: {
        incoming: sentiment.success.base,
        outgoing: sentiment.inform.base,
        missed: sentiment.error.base,
      },
      notification: {
        error: sentiment.error.base,
        logout: sentiment.error.base,
        login: sentiment.success.base,
      },
      integrations: {
        connected: sentiment.success.base,
      },
    },
  },
};

export default enghouse;
