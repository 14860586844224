import { useEffect, useState, useMemo } from "react";
import mxClient from "matrix/matrix";
import { VidyoClient } from "vidyo";
import { getLogger } from "logger/appLogger";

import useMatrixEvent from "./useMatrixEvent";
import useLocalStorage from "./useLocalStorage";
import useRouter from "./useRouter";
import {
  isJson,
  tryParse,
  getProperCase,
  getUserName,
  wrongDisplayNamePattern,
  compareRoomLists,
  isValidDisplayName,
  onlyInLeft,
} from "utils/helpers";
import { MatrixUserProvider } from "services/MatrixAPIProvider";

import { useDispatch } from "react-redux";
import { useAppSelector } from "store/hooks";
import { getLocalRoom } from "store/selectors";
import { matrixActions } from "store/actions/matrix";
import { MatrixEvent, Room, RoomMember } from "matrix-js-sdk";
import {
  LocalEventTypeEnum,
  MappedRoomList,
  MatrixRoom,
  SyncState,
  Visibility,
  ICreateRoomOpts,
  Membership,
  MatrixUser,
  SearchData,
  MatrixRoomTopics,
  Preset,
  Mx_IContent,
  MatrixRoomTypes,
  LocalMatrixRoom,
  RoomInContacts,
  InternalMessageContentType,
} from "types/Matrix";
import { UCUser } from "types/UC";
import { CalendarEvent } from "services/CalendarProvider/types/CalendarEvent";
import { getMeetingParticipantsEmails, getRoomFromRoomList } from "utils/utils";
import { useSearchByListOfEmailsProvisioning } from "./useProvisioning";
import useMatrix from "./useMatrix";
import { GUEST_APPSERVICE_USERNAME, localStorageKeys } from "utils/constants";

const useMatrixRooms = (
  roomId?: string,
  roomEvent: string | null = null,
  deleteEvent: string | null = null,
  accountDataEvent: string | null = null
) => {
  const dispatch = useDispatch();
  const { sendInternalMessage } = useMatrix("");
  const logger = useMemo(() => getLogger("matrix"), []);
  const module = "useMatrixRooms";

  const [vidyoUser] = useLocalStorage<any>("vidyoUser");
  const [currentUser] = useLocalStorage<UCUser>(localStorageKeys.CURRENT_USER);
  const guestAppServiceUsername = `@${GUEST_APPSERVICE_USERNAME}:${currentUser.home_server}`;
  const [roomName, setRoomName] = useState("");
  const [room, setRoom] = useState<MatrixRoom | null>();
  const client = mxClient.client;
  const { getAccountDataFromServer, setAccountData, getUserDisplayName } = MatrixUserProvider();
  const { directSearch } = useSearchByListOfEmailsProvisioning();
  const onRoomEvent = useMatrixEvent(client, roomEvent);
  const onDeleteRoomEvent = useMatrixEvent(client, deleteEvent);
  const onAccountDataEvent = useMatrixEvent(client, accountDataEvent);
  const { push } = useRouter();
  const { setRoomList, updateRoomInRoomList } = matrixActions;

  const usersList = useAppSelector((state) => state.provisioning.usersList);
  const roomList = useAppSelector((state) => state.matrix.roomList);
  const currentRoomId = useAppSelector((state) => state.matrix.roomId);
  const reduxLocalRoom = useAppSelector((state) => (roomId ? getLocalRoom(state, roomId) : null));
  const currentSyncState = useAppSelector((state) => state.matrix.matrixSyncState);

  const getRoom = (id: string): MatrixRoom | undefined | null => {
    try {
      if (!client) return;
      const room: MatrixRoom = client.getRoom(id) as MatrixRoom;
      return room;
    } catch (e) {
      logger.error(`${module}: Error in getRoom - roomId:${id} - ${e.message}`);
      return null;
    }
  };

  const getRoomList = () => {
    try {
      if (!client) return {};
      return mxClient.getRoomList();
    } catch (e) {
      logger.error(`${module}: Error in getRoomList - type:all - ${e.message}`);
      return {};
    }
  };

  const getRoomListByType = (type = "all") => {
    try {
      if (!client) return [];
      return mxClient.getRoomListWithType(type);
    } catch (e) {
      logger.error(`${module}: Error in getRoomList - type:${type} - ${e.message}`);
      return [];
    }
  };

  const getRoomsWithPortalLink = () => {
    try {
      const list: RoomInContacts[] = [];
      const rooms = getRoomList();
      Object.keys(rooms).forEach((item) => {
        rooms[item].forEach((room: LocalMatrixRoom) => {
          const roomTopic = tryParse(getRoomTopic({ roomId: room.roomId }));
          if (roomTopic) {
            list.push({
              name: room.name,
              id: room.roomId,
              portalLink: room.roomURL,
              shareURL: roomTopic.shareURL ? roomTopic.shareURL : room.roomURL,
              isHidden: room.isHidden,
            });
          }
        });
      });
      logger.debug(`${module}: getRoomsWithPortalLink -> rooms:${rooms}`);
      return list;
    } catch (e) {
      logger.error(`${module}: Error in getRoomsWithPortalLink - ${e.message}`);
      return [];
    }
  };

  const getPublicRoomList = async (searchText?: string) => {
    try {
      return await mxClient.getPublicRooms(searchText);
    } catch (e) {
      logger.error(`${module}: Error in getPublicRoomList - ${e.message}`);
      throw e;
    }
  };

  const isPublic = async (room: LocalMatrixRoom) => {
    try {
      return await mxClient.isPublic(room);
    } catch (e) {
      logger.error(`${module}: Error in getPublicRoomList - ${e.message}`);
      throw e;
    }
  };

  const getRoomCreator = (roomId: string) => {
    try {
      if (!roomId) return;
      const room = getRoom(roomId);

      if (room) {
        return mxClient.getRoomCreator(room);
      } else {
        return null;
      }
    } catch (e) {
      logger.error(`${module}: Error in getRoomCreator - roomId:${roomId} - ${e.message}`);
      return null;
    }
  };

  const setRoomTag = async (roomId: string, tagName: string, metadata: any) => {
    try {
      return await mxClient.setRoomTag(roomId, tagName, metadata);
    } catch (e) {
      logger.error(`${module}: Error in setRoomTag - roomId:${roomId}, tagName:${tagName} - ${e.message}`);
      throw e;
    }
  };

  const deleteRoomTag = async (roomId: string, tagName: string) => {
    try {
      return await mxClient.deleteRoomTag(roomId, tagName);
    } catch (e) {
      logger.error(`${module}: Error in deleteRoomTag - roomId:${roomId}, tagName:${tagName} - ${e.message}`);
      throw e;
    }
  };

  const getRoomTags = async (roomId: string) => {
    try {
      return await mxClient.getRoomTags(roomId);
    } catch (e) {
      logger.error(`${module}: Error in getRoomTag - roomId:${roomId} - ${e.message}`);
      throw e;
    }
  };

  const setRoomTopic = async (roomId: string, topic: string) => {
    try {
      return await mxClient.setRoomTopic(roomId, topic);
    } catch (e) {
      logger.error(`${module}: Error in setRoomTopic - roomId:${roomId}, topic:${topic} - ${e.message}`);
      throw e;
    }
  };

  /**
   * Get topic of the matrix room with the roomId or with the MatrixRoom object. If you invoke with roomId the method make a new call to getRoom method.
   * @param roomId - Matrix room id
   * @param room - MatrixRoom object
   *  */
  const getRoomTopic = (args: { roomId?: string; room?: MatrixRoom | null }) => {
    try {
      if (!args.roomId && !args.room) return;
      let room = args.room;
      if (!room) {
        room = getRoom(args.roomId!);
      }
      const topic = room ? mxClient.getRoomTopic(room!) : null;
      logger.debug(`${module}: getRoomTopic -> args:${args} roomId:${room?.roomId} -> topic:${topic}`);
      return topic;
    } catch (e) {
      logger.error(`${module}: Error in getRoomTopic - roomId:${roomId} - ${e.message}`);
      return null;
    }
  };

  const updateRoomTopic = async (roomId: string, topic: MatrixRoomTopics, value: any, roomTopic?: any) => {
    logger.debug(
      `${module}: updateRoomTopic - roomId:${roomId}, topic:${topic}, value:${value}, roomTopic:${roomTopic}`
    );
    try {
      let rTopic: any = roomTopic!;
      if (!roomTopic) rTopic = await getRoomTopic({ roomId });

      const roomTopicObj = tryParse(rTopic);
      if (!roomTopicObj) return;
      if (topic === MatrixRoomTopics.description) roomTopicObj.description = value;
      if (topic === MatrixRoomTopics.isHidden) roomTopicObj.isHidden = value;
      const result = setRoomTopic(roomId, JSON.stringify(roomTopicObj));
      logger.debug(`${module}: updateRoomTopic -> result:${result}`);
      return result;
    } catch (e) {
      logger.error(`${module}: Error in updateRoomTopic - ${e.message}`);
    }
  };

  const setRoomEventsTimestamp = async (event: any) => {
    try {
      mxClient.setRoomEventsTimestamp(event);
    } catch (e) {
      logger.error(`${module}: Error in setRoomEventsTimestamp - ${e.message}`);
    }
  };

  //accept and join all the rooms that are invites
  const joiningInvitedRooms = () => {
    const rooms: Room[] = mxClient.client.getRooms();

    const invites: Room[] = [];
    rooms.forEach((room) => {
      if (room.getMyMembership() === Membership.invite) {
        invites.push(room);
      }
    });
    logger.debug(`${module}: joiningInvitedRooms - invites:${invites}`);

    if (invites.length > 0) {
      var promises = invites.map(async (room) => {
        joinRoom(room.roomId)
          .then((room) => room)
          .catch((err) => err);
      });
      return Promise.all(promises);
    } else return Promise.resolve([]);
  };

  const mapRoomList = async () => {
    try {
      if (!client) return;
      await mxClient.setPublicRooms();

      return joiningInvitedRooms().then((data) => {
        return mxClient.mapRoomList().then((list) => {
          const finalList: MappedRoomList = { ...roomList, ...list };
          dispatch(setRoomList(finalList));
          return finalList;
        });
      });
    } catch (e) {
      logger.error(`${module}: Error in mapRoomList - ${e.message}`);
      return Promise.resolve([]);
    }
  };

  const inviteToRoom = async (roomId: string, userId: string) => {
    try {
      return await mxClient.inviteToRoom(roomId, userId);
    } catch (e) {
      logger.error(`${module}: Error in inviteToRoom - userId:${userId}, roomId:${roomId} - ${e.message}`);
      throw e;
    }
  };

  const removeUserFromRoom = async (roomId: string, userId: string, reason: string) => {
    try {
      return await mxClient.removeUserFromRoom(roomId, userId, reason);
    } catch (e) {
      logger.error(`${module}: Error in removeUserFromRoom - userId:${userId}, roomId:${roomId} - ${e.message}`);
      throw e;
    }
  };

  const createDirectRoom = async (userId: string, displayName: string) => {
    try {
      const aliasname: string | undefined = createAliasName(userId);
      const roomTopic = {
        [userId]: {
          name: displayName,
        },
        [currentUser.user_id]: {
          name: await getUserDisplayName(currentUser.user_id),
        },
      };
      logger.debug(`${module}: createDirectRoom - userId:${userId}, displayName:${displayName} -> `);
      const opts: ICreateRoomOpts = {
        preset: Preset.TrustedPrivateChat,
        visibility: Visibility.Private,
        invite: [userId],
        is_direct: true,
        room_alias_name: aliasname,
        topic: JSON.stringify(roomTopic),
      };

      const { room_id }: any = await mxClient.createRoom(opts);
      await setDirectAccountData(room_id);
      return room_id;
    } catch (e) {
      logger.error(
        `${module}: Error in createDirectRoom - userId:${userId}, displayName:${displayName} - ${e.message}`
      );
      return null;
    }
  };

  const setDirectAccountData = async (roomId: string) => {
    try {
      logger.debug(`${module}: setDirectAccountData - roomId:${roomId}`);
      let mDirectData = await getAccountDataFromServer("m.direct");
      let content = mDirectData;
      if (!content || (content && !content[currentUser.user_id])) {
        content = {
          [currentUser.user_id]: [],
        };
      }
      const isInArray =
        content[currentUser.user_id].find((el: string) => {
          return el === roomId;
        }) !== undefined;
      if (!isInArray) {
        content[currentUser.user_id].push(roomId);
      }
      await setAccountData("m.direct", content);
    } catch (e) {
      logger.error(`${module}: Error in setDirectAccountData - roomId:${roomId} - ${e.message}`);
      throw e;
    }
  };

  const joinRoom = async (roomId: string) => {
    try {
      const room = client.getRoom(roomId) as MatrixRoom;
      if (room) {
        const { myUserId } = room;
        const me: RoomMember = room.getMember(myUserId!)!;
        const memberEventContent: Mx_IContent = me?.events?.member?.getContent() as Mx_IContent;

        if (memberEventContent?.is_direct) {
          await setDirectAccountData(roomId);
        }
      }
      const res = await mxClient.joinRoom(roomId);
      logger.debug(`${module}: joinRoom -> roomId:${room?.roomId} -> result:${res}`);
      return res;
    } catch (e) {
      logger.error(`${module}: Error in joinRoom - roomId:${roomId} - ${e.message}`);
      return null;
    }
  };

  const setRoomDirectoryVisibility = async (roomId: string, visibility: Visibility) => {
    try {
      return await mxClient.setRoomDirectoryVisibility(roomId, visibility);
    } catch (e) {
      logger.error(
        `${module}: Error in setRoomDirectoryVisibility - roomId:${roomId}, visibility:${visibility} - ${e.message}`
      );
      throw e;
    }
  };

  const getRoomDirectoryVisibility = async (roomId: string) => {
    try {
      return await mxClient.getRoomDirectoryVisibility(roomId);
    } catch (e) {
      logger.error(`${module}: Error in getRoomDirectoryVisibility - roomId:${roomId} - ${e.message}`);
      throw e;
    }
  };

  const leaveRoom = async (roomId: string) => {
    try {
      const room = getRoom(roomId);
      const creator = getRoomCreator(roomId);
      const topic = getRoomTopic({ room });
      logger.debug(`${module}: leaveRoom - roomId:${roomId} -> creator:${creator}, topic:${topic}`);
      if (room && creator && topic) {
        const members = room.getJoinedMembers();
        const member = members.find((u) => u.userId === currentUser.user_id);
        const isValidJson = isJson(topic);

        if (members.length <= 1 && member && isValidJson) {
          const { userId } = member;
          const { roomID } = JSON.parse(topic);

          if (vidyoUser && roomID && userId === creator) {
            const { portal, token } = vidyoUser;
            await VidyoClient.deleteRoom(portal, token, roomID);
          }
        }
      }
      await mxClient.leaveRoom(roomId);
      removeRoom(roomId);
      await mxClient.forgetRoom(roomId, true);
    } catch (e) {
      logger.error(`${module}: Error in leaveRoom - roomId:${roomId} - ${e.message}`);
      throw e;
    }
  };

  const removeRoom = (roomId: string) => {
    try {
      const removed = mxClient.removeRoom(roomId);

      if (removed) {
        dispatch(matrixActions.removeRoomFromRoomList(roomId));
      }
    } catch (e) {
      logger.error(`${module}: Error in removeRoom - roomId:${roomId} - ${e.message}`);
    }
  };

  const handleRoomEvent = async (roomEvent: any[]) => {
    try {
      const [room] = roomEvent;
      logger.debug(`${module}: handleRoomEvent - room:${room}`);
      if (!room) return;
      const myMembership = room.getMyMembership();
      logger.debug(`${module}: handleRoomEvent - myMembership:${myMembership}`);

      if (myMembership === Membership.invite) {
        logger.debug(`${module}: handleRoomEvent - invite`);
        const newRoom = await joinRoom(room.roomId);
        if (newRoom) await categorizeRoom(newRoom);
      }
      if (myMembership === Membership.join) {
        logger.debug(`${module}: handleRoomEvent - join`);
        await mxClient.setPublicRooms();
        await categorizeRoom(room);
      }
    } catch (e) {
      logger.error(`${module}: Error in handleRoomEvent - ${e.message}`);
    }
  };

  const handleDeleteRoomEvent = async (deleteRoomEvent: any[]) => {
    try {
      const [roomId] = deleteRoomEvent;
      logger.debug(`${module}: handleDeleteRoomEvent - roomId:${roomId}`);
      removeRoom(roomId);
    } catch (e) {
      logger.error(`${module}: Error in handleDeleteRoomEvent - ${e.message}`);
    }
  };

  const handleRoomTagEvent = async (roomTagEvent: [event: MatrixEvent, room: Room, currentRoomId: string | null]) => {
    try {
      const [event, room] = roomTagEvent;
      logger.debug(`${module}: handleRoomTagEvent - roomId:${room?.roomId}`);
      if (room) {
        await categorizeRoom(room);
        const { tags } = event.getContent();
        if (tags && currentRoomId && room.roomId === currentRoomId && tags["m.lowpriority"]) {
          push("/home");
        }
      }
    } catch (e) {
      logger.error(`${module}: Error in handleRoomTagEvent - ${e.message}`);
    }
  };

  const handleAccountDataEvent = ([event]: [any]) => {
    try {
      logger.debug(`${module}: handleAccountDataEvent - event:${event}`);
      if (!event) return;
      const content = event.getContent();
      const type = event.getType();
      logger.debug(`${module}: handleAccountDataEvent - content:${content} type:${type}`);
      if (type && type === LocalEventTypeEnum.Direct) {
        const mRoomList = getRoomList() as MappedRoomList;
        for (let k in mRoomList) {
          if (k !== MatrixRoomTypes.contacts) {
            mRoomList[k].forEach(async (room: LocalMatrixRoom) => {
              for (let i in content) {
                const dmRoomIdList = content[i];
                const isInArray = dmRoomIdList.find((id: string) => id === room.roomId) !== undefined;
                if (isInArray) {
                  let mRoom = getRoom(room.roomId);
                  if (mRoom) {
                    mRoom.type = MatrixRoomTypes.direct;
                    await categorizeRoom(mRoom as Room);
                  }
                }
              }
            });
          }
        }
      }
    } catch (e) {
      logger.error(`${module}: Error in handleAccountDataEvent - ${e.message}`);
    }
  };

  const handleRoomMembershipEvent = async (
    args: [event: MatrixEvent, member: RoomMember, oldMembership?: string | undefined]
  ) => {
    try {
      const { membership, userId, roomId } = args[1];
      logger.debug(`${module}: handleRoomMembershipEvent - roomId:${roomId} membership:${membership} userId:${userId}`);
      const room = getRoom(roomId);
      if (room) {
        room.recalculate();
        handleRoomEvent([room]);
      }
    } catch (e) {
      logger.error(`${module}: Error in handleRoomMembershipEvent ${e.message}`);
    }
  };

  const categorizeRoom = async (room: Room) => {
    try {
      let mRoom = room as MatrixRoom;
      await mxClient.categorizeRoom(mRoom);
      setRoom(mRoom);

      const list = mxClient.getRoomList();
      dispatch(setRoomList(list));
    } catch (e) {
      logger.error(`${module}: Error in categorizeRoom - roomId:${room.roomId} - ${e.message}`);
    }
  };

  const handleRoomNameUpdate = async (mRoom: MatrixRoom | null | undefined) => {
    try {
      logger.debug(`${module}: handleRoomNameUpdate - roomId:${mRoom?.roomId}`);
      if (!mRoom) return;
      const mappedRoom = convertMatrixRoomToMappedRoom(mRoom);
      if (mappedRoom) {
        let newName = calculateRoomName(mappedRoom);
        const isDisplayNameWrong = wrongDisplayNamePattern.test(newName);
        const localRoom = getRoomFromRoomList(roomList, mRoom.roomId);
        logger.debug(
          `${module}: handleRoomNameUpdate -- mappedRoomName:${mappedRoom.name} -- roomName:${roomName} -- localRoom.name:${localRoom?.name} 
          -- newName:${newName} - isDisplayNameWrong:${isDisplayNameWrong}`
        );
        if (
          !isDisplayNameWrong &&
          mappedRoom.type !== undefined &&
          (newName !== roomName || newName !== mappedRoom.name || (localRoom && newName !== localRoom.name))
        ) {
          // updates the local MatrixRoom name and the local roomName
          setRoomName(newName);
          mRoom.name = newName;
        }
      }
    } catch (e) {
      logger.error(`${module}: Error in handleRoomNameUpdate - roomId:${room?.roomId} - ${e.message}`);
    }
  };

  const updateLocalRoomName = (mRoom: MatrixRoom, name: string) => {
    try {
      logger.debug(`${module}: updateLocalRoomName - roomId:${mRoom.roomId}, name:${name}`);
      if (name && mRoom && isValidDisplayName(name)) {
        // updates the local MatrixRoom name and the local roomName
        if (currentRoomId === mRoom.roomId) setRoomName(name);
        const localRoom = getRoomFromRoomList(roomList, mRoom.roomId);
        // only dispatch the action if the name change is not for the current room
        // because if it's the current room, will be handle in the useEffect of roomName
        if (localRoom && localRoom.roomId !== currentRoomId && localRoom.name !== name) {
          localRoom.name = name;
          // updates the room name in the roomList (redux)
          dispatch(updateRoomInRoomList(localRoom));
        }
      }
    } catch (e) {
      logger.error(`${module}: Error in updateLocalRoomName - room:${room}, name:${name} - ${e.message}`);
    }
  };

  const updateRoomName = async (roomId: string, name: string, isOwner: boolean) => {
    try {
      logger.debug(`${module}: updateRoomName - roomId:${roomId}, name:${name}, isOwner:${isOwner}`);
      if (isOwner) await mxClient.setRoomName(roomId, name);
      const mRoom = getRoom(roomId);
      if (mRoom) {
        mRoom.name = name;
        updateLocalRoomName(mRoom, name);
      }
    } catch (e) {
      logger.error(
        `${module}: Error in updateRoomName - roomId:${roomId}, name:${name}, isOwner:${isOwner} - ${e.message}`
      );
    }
  };

  const isGroup = (roomId: string) => {
    try {
      let roomTopic = getRoomTopic({ roomId });
      const isGroupChat = roomTopic?.includes('"isGroupChat":true');
      logger.debug(`${module}: isGroup - roomId:${roomId}, roomTopic:${roomTopic} -> isGroupChat:${isGroupChat}`);
      return isGroupChat;
    } catch (e) {
      logger.error(`${module}: Error in isGroup - roomId:${roomId} - ${e.message}`);
      return false;
    }
  };

  const isDirect = (roomId: string) => {
    let room: LocalMatrixRoom | MatrixRoom | undefined | null = getRoomFromRoomList(roomList, roomId);
    if (!room) room = getRoom(roomId) as MatrixRoom;
    return room ? room.type === MatrixRoomTypes.direct : false;
  };

  const isPrivate = (roomId: string) => {
    let room: LocalMatrixRoom | MatrixRoom | undefined | null = getRoomFromRoomList(roomList, roomId);
    if (!room) room = getRoom(roomId) as MatrixRoom;
    return room ? room.type === MatrixRoomTypes.private : false;
  };

  const calculateRoomName = (room: LocalMatrixRoom) => {
    try {
      const isDirectRoom = room ? isDirect(room.roomId) : false;
      logger.debug(`${module}: calculateRoomName - room:${room}, isDirectRoom:${isDirectRoom}`);
      //CHECK IF ROOM IS A DIRECT ROOM
      if (room && isDirectRoom) {
        // GET THE OTHER USER DIRECT ROOM USER
        const members = room.members;
        const otheruser = members.find((member) => member.userId !== room.myUserId);
        logger.debug(`${module}: calculateRoomName - otheruser:${otheruser}`);
        if (otheruser) {
          /* IF OTHER USER's NAME IS THE USER ID THEN CONVERT ROOM NAME TO MEMBER NAMES 
            ELSE USE THE OTHER USER's DISPLAY NAME 
          */
          return calculateUserName(otheruser.name);
        }
        // DEFAULT IS TO CONVERT ROOM NAME TO MEMBER NAMES
        return generateRoomName(room);
      } else {
        /* IF THE ROOM IS NOT A DIRECT ROOM THEN USE THE ROOM NAME
            EXCEPT WHEN THE ROOM NAME IS THE ROOM ALIAS
        */
        return generateRoomName(room);
      }
    } catch (e) {
      logger.error(`${module}: Error in calculateRoomName - roomId:${room.roomId} - ${e.message}`);
      // SET ROOM NAME AS THE DEFAULT ROOM NAME WHEN ERROR OCCURS
      return room.name;
    }
  };

  /**
   * Calculate the user name. If the userName is the user id, then checks if it already exists in the keycloak users list
   * else, use the userName
   * @param userName
   * @returns string
   */
  const calculateUserName = (userName: string | undefined, user?: MatrixUser) => {
    try {
      logger.debug(`${module}: calculateUserName - userName:${userName}`);
      if (userName && userName.length > 0) {
        const isDisplayNameWrong = wrongDisplayNamePattern.test(userName);
        logger.debug(
          `${module}: calculateUserName - isDisplayNameWrong:${isDisplayNameWrong} - usersList:${usersList}`
        );
        if (isDisplayNameWrong) {
          const home_server = userName.slice(userName.indexOf(":") + 1);
          const usernameToSearch = userName.replace(`:${home_server}`, "").replace("@", "").toLowerCase();
          let userToDisplay = null;
          logger.debug(`${module}: calculateUserName - usersList:${usersList}`);
          if (usersList) {
            userToDisplay = usersList?.find((user: any) => user.username === usernameToSearch);
          }
          logger.debug(`${module}: calculateUserName - user:${user}, userToDisplay:${userToDisplay}`);

          if (userToDisplay) {
            return `${userToDisplay.firstName} ${userToDisplay.lastName}`;
          } else if (user) {
            return user.displayName;
          } else {
            return getProperCase(userName);
          }
        } else {
          return getProperCase(userName);
        }
      } else {
        return "";
      }
    } catch (error) {
      logger.error(`${module}: Error in calculateUserName - userName:${userName} - ${error.message}`);
      return null;
    }
  };

  const generateRoomName = (room: LocalMatrixRoom) => {
    try {
      logger.debug(`${module}: generateRoomName - room:${room}`);
      if (room) {
        let rName = room.name;
        const currentUserId = room.myUserId;
        const members = room.members;
        const namesToResolve: string[] = [];

        // verify if is a group chat or the room name is on wrong format
        const isValidDName = isValidDisplayName(rName);
        logger.debug(`${module}: generateRoomName - isValidDName:${isValidDName}`);
        if (!isValidDName) {
          members.forEach((member) => {
            if (member.userId !== currentUserId) {
              namesToResolve.push(member.name);
            }
          });
          logger.debug(`${module}: generateRoomName - namesToResolve:${namesToResolve}`);

          rName = namesToResolve
            .map((name) => {
              let displayName = name;
              if (wrongDisplayNamePattern.test(displayName)) {
                const username = getUserName(name);
                // verify if the user has been searched before
                const userSearched = usersList?.find((userInfo: { username: any }) => userInfo.username === username);
                // and if so, use the first name to the name
                if (userSearched) {
                  return userSearched.firstName;
                }
              }
              const firstNamePattern = /([A-Z])\w+ /;
              if (firstNamePattern.test(displayName)) {
                const result = firstNamePattern.exec(displayName);
                displayName =
                  result !== null && result.length > 0
                    ? result[0].slice(0, result[0].length - 1) //remove the space in the end
                    : displayName;
              }

              return displayName;
            })
            .join(", ");
        }
        room.name = rName;
        logger.debug(`${module}: generateRoomName - name:${rName}`);
        return rName;
      } else return "";
    } catch (e) {
      logger.error(`${module}: Error in generateRoomName - roomId:${room.roomId} - ${e.message}`);
      return "";
    }
  };

  const createAliasName = (userId: string) => {
    try {
      const alias = [
        userId.replace(`:${currentUser.home_server}`, "").replace("@", ""),
        currentUser.user_id.replace(`:${currentUser.home_server}`, "").replace("@", ""),
      ]
        .sort()
        .join("")
        .replace(/\s/g, "")
        .toLowerCase();
      logger.debug(`${module}: createAliasName - userId:${userId} -> alias:${alias}`);
      return alias;
    } catch (e) {
      logger.error(`${module}: Error in createAliasName - userId:${userId} - ${e.message}`);
      return undefined;
    }
  };

  const deleteRoomLowPrio = async (roomId: string) => {
    try {
      const room = getRoom(roomId);
      logger.debug(`${module}: deleteRoomLowPrio - roomId:${roomId}`);
      if (room) {
        const { tags }: { tags: any } = room;
        if (tags && tags["m.lowpriority"]) {
          await deleteRoomTag(roomId, "m.lowpriority");
          return true;
        }
      }
      return false;
    } catch (e) {
      logger.error(`${module}: Error in deleteRoomLowPrio - roomId:${roomId} - ${e.message}`);
      return false;
    }
  };

  const isRoomLowPrio = (roomId: string) => {
    try {
      const room = getRoom(roomId);
      let isLowPrio = false;
      if (room) {
        const { tags }: { tags: any } = room;
        if (tags && tags["m.lowpriority"]) {
          isLowPrio = true;
        }
      }
      logger.debug(`${module}: isRoomLowPrio - roomId:${roomId} -> isLowPrio:${isLowPrio}`);
      return isLowPrio;
    } catch (e) {
      logger.error(`${module}: Error in isRoomLowPrio - roomId:${roomId} - ${e.message}`);
      return false;
    }
  };

  const markRoomAsLowPriority = async (roomId: string) => {
    try {
      logger.debug(`${module}: markRoomAsLowPriority - roomId:${roomId}`);
      await setRoomTag(roomId, "m.lowpriority", {});
    } catch (e) {
      logger.error(`${module}: Error in markAsLowPriority - ${e.message}`);
    }
  };

  const markRoomAsFavorite = async (roomId: string) => {
    try {
      logger.debug(`${module}: markRoomAsFavorite - roomId:${roomId}`);
      await setRoomTag(roomId, "m.favourite", {});
    } catch (e) {
      logger.error(`${module}: Error in markAsFavorite - ${e.message}`);
    }
  };

  const removeRoomAsFavorite = async (roomId: string) => {
    try {
      logger.debug(`${module}: removeRoomAsFavorite - roomId:${roomId}`);
      deleteRoomTag(roomId, "m.favourite");
    } catch (e) {
      logger.error(`${module}: Error in removeAsFavorite - ${e.message}`);
    }
  };

  const sortRoomMembers = (list: RoomMember[]) => {
    try {
      list = list.sort((a, b) =>
        a.name.localeCompare(b.name, "en", {
          ignorePunctuation: true,
        })
      );
    } catch (e) {
      logger.error(`${module}: Error in sortRoomMembers - ${e.message}`);
    }
    return list;
  };

  const getJoinedMemberCount = (room: MatrixRoom | null | undefined) => {
    let joinedMemberCount = 0;

    try {
      if (room) {
        const members = room.getJoinedMembers().filter((member) => member.userId !== guestAppServiceUsername);
        joinedMemberCount = members.length;
      }
    } catch (e) {
      logger.error(`${module}: Error in getJoinedMemberCount - ${e.message}`);
    }
    return joinedMemberCount;
  };

  /** Check if the user have a direct room on the matrix rooms */
  const checkForExistingRoom = (username: string) => {
    try {
      const userId: string = `@${username}:${currentUser.home_server}`;
      logger.debug(`${module}: checkForExistingRoom - username:${username} - userId:${userId}`);

      const userRoomId: string[] = [];
      for (let i in roomList) {
        if (roomList[i] && roomList[i].length > 0) {
          roomList[i].forEach((room: LocalMatrixRoom) => {
            if (room.type === MatrixRoomTypes.direct) {
              const { roomId } = room;
              const contactUser: string = room.guessDMUserId();
              if (userId === contactUser) {
                userRoomId.push(roomId);
                return;
              }
            }
          });
        }
      }
      return userRoomId.length > 0 ? userRoomId[0] : null;
    } catch (e) {
      logger.error(`${module}: Error in checkForExistingRoom - username:${username} - ${e.message}`);
      return null;
    }
  };

  /** Check if the rooms already exists on the matrix rooms */
  const checkForExistingRooms = (list: SearchData[], room: LocalMatrixRoom) => {
    try {
      const { roomId, type } = room;
      logger.debug(`${module}: checkForExistingRooms - list:${list} - room:${room}`);
      if (type !== MatrixRoomTypes.direct) {
        const joinedRoom: SearchData | undefined = list.find((r) => r.roomId === roomId);
        if (joinedRoom) {
          joinedRoom.roomId = roomId;
          const { tags }: { tags: any } = room;
          if (!tags["m.lowpriority"]) {
            joinedRoom.hasExistingRoom = true;
          } else if (tags["m.lowpriority"]) {
            joinedRoom.hasExistingRoom = false;
          }
        }
      } else if (type === MatrixRoomTypes.direct) {
        const contactUser: string = room.guessDMUserId();
        const userInList: SearchData | undefined = list.find((u) => u.userId === contactUser);
        if (!userInList) return;
        userInList.roomId = roomId;
        const { tags }: { tags: any } = room;
        if (!tags["m.lowpriority"]) {
          userInList.hasExistingRoom = true;
        } else if (tags["m.lowpriority"]) {
          userInList.hasExistingRoom = false;
        }
      }
    } catch (e) {
      logger.error(`${module}: Error in checkForExistingRooms - roomId:${room.roomId} - ${e.message}`);
    }
  };

  /** Gets the private, public and favorites matrix rooms */
  const getNoDirectAndPublicMatrixRooms = (matrixRoomList: any) => {
    try {
      logger.debug(`${module}: getNoDirectAndPublicMatrixRooms - matrixRoomList:${matrixRoomList}`);
      let rooms: SearchData[] = [];
      for (let i in matrixRoomList) {
        if (
          (i === MatrixRoomTypes.private || i === MatrixRoomTypes.favorites) &&
          matrixRoomList[i] &&
          matrixRoomList[i].length > 0
        ) {
          matrixRoomList[i].forEach(async (room: LocalMatrixRoom) => {
            // filter rooms that are hidden and direct and public rooms that may be in the favorites list
            if (!room.isHidden && room.type !== MatrixRoomTypes.direct && room.type !== MatrixRoomTypes.public) {
              const roomName: string | undefined = isGroup(room.roomId)
                ? generateRoomName(room as LocalMatrixRoom)
                : room.name;
              const item: any = {
                roomId: room.roomId,
                userId: room.roomId,
                avatarUrl: "",
                displayName: roomName ? roomName : "",
                isRoom: true,
                isPrivateRoom: isPrivate(room.roomId) && !isGroup(room.roomId),
                hasExistingRoom: true,
              };
              rooms.push(item);
            }
          });
        }
      }
      logger.debug(`${module}: getNoDirectAndPublicMatrixRooms -> rooms:${rooms}`);
      return rooms;
    } catch (e) {
      logger.error(`${module}: Error in getNoDirectAndPublicMatrixRooms - ${e.message}`);
      return [];
    }
  };

  /** Check if the user have a direct room on the matrix rooms */
  /** If not, create a new direct room, than can be visible or not */
  const getOrCreateDirectRoom = async (username: string, displayName: string, turnVisible: boolean) => {
    try {
      let roomId = checkForExistingRoom(username);
      logger.debug(
        `${module}: getOrCreateDirectRoom - username:${username}, displayName:${displayName}, turnVisible:${turnVisible} -> roomId:${roomId}`
      );
      let newRoom = false;
      if (!roomId) {
        const { home_server } = JSON.parse(localStorage.getItem(localStorageKeys.CURRENT_USER) || "");
        const userId: string = `@${username}:${home_server}`;
        roomId = await createDirectRoom(userId, displayName);
        newRoom = true;
        // if the room is supposed to not be visible (ex to send an internal message ),
        // we need to set the lowPriority tag to true
        if (roomId && !turnVisible) {
          markRoomAsLowPriority(roomId);
        }
      }
      const result = { roomId: roomId, newRoom: newRoom };
      logger.debug(`${module}: getOrCreateDirectRoom -> result:${result}`);
      return result;
    } catch (e) {
      logger.error(
        `${module}: Error in getOrCreateDirectRoom - username:${username}, displayName:${displayName}, turnVisible:${turnVisible} - ${e.message}`
      );
      return null;
    }
  };

  const convertMatrixRoomToMappedRoom = (room: MatrixRoom) => {
    try {
      if (!room) return null;
      return mxClient.convertMatrixRoomToMappedRoom(room);
    } catch (e) {
      logger.error(`${module}: Error in convertMatrixRoomToMappedRoom - ${e.message}`);
      return null;
    }
  };

  const updateRoomsMembersBasedOnCalendarEvents = (calendarEventList: CalendarEvent[]) => {
    const rooms = getRoomsWithPortalLink();
    const { user_id } = JSON.parse(localStorage.getItem(localStorageKeys.CURRENT_USER) || "");
    logger.debug(
      `${module}: updateRoomsMembersBasedOnCalendarEvents - calendarEventList:${calendarEventList} - rooms:${rooms}, user_id:${user_id}`
    );

    calendarEventList.forEach(async (calendarEvent) => {
      if (calendarEvent && calendarEvent?.hasMeetingLink) {
        const inContacts = rooms.find((room) => {
          return room.shareURL === calendarEvent.location;
        });
        if (inContacts) {
          const room = getRoom(inContacts.id);

          if (room) {
            const ownerId = getRoomCreator(inContacts.id);

            if (ownerId === user_id) {
              if (calendarEvent.subject !== inContacts.name) {
                await updateRoomName(inContacts.id, calendarEvent.subject, true);
              }
            }

            if (calendarEvent?.attendees?.length > 0) {
              const participantsInfo = await directSearch(getMeetingParticipantsEmails(calendarEvent.attendees));

              if (participantsInfo) {
                const roomMembers = room.getMembers();

                if (ownerId === user_id && roomMembers.length > 0) {
                  updateRoomMembers(
                    participantsInfo.map((p) => p.username),
                    roomMembers,
                    inContacts.id
                  );
                }
              }
            }
          }
        }
      }
    });
  };

  const updateRoomMembers = (participants: string[], roomMembers: any[], roomId: string) => {
    logger.debug(
      `${module}: updateRoomMembers - participants:${participants}, roomMembers:${roomMembers}, roomId:${roomId}`
    );
    const isNewMember = (participantUserName: string, member: any) =>
      `@${participantUserName}:${home_server}` === member.userId;
    const isMemberToRemove = (member: any, participantUserName: string) =>
      `@${participantUserName}:${home_server}` === member.userId;

    const { user_id, home_server } = JSON.parse(localStorage.getItem(localStorageKeys.CURRENT_USER) || "");
    const newMembers: string[] = onlyInLeft(participants, roomMembers, isNewMember);
    const removeMembers: any[] = onlyInLeft(roomMembers, participants, isMemberToRemove).filter(
      (member: any) =>
        member.userId !== user_id && member.userId !== guestAppServiceUsername && member.membership !== Membership.leave
    );
    logger.debug(`${module}: updateRoomMembers - newMembers:${newMembers}, removeMembers:${removeMembers}`);

    if (newMembers.length > 0) {
      newMembers.forEach(async (participantUserName) => {
        await inviteToRoom(roomId, `@${participantUserName}:${home_server}`);
      });
    }
    if (removeMembers.length > 0) {
      removeMembers.forEach(async (participant) => {
        await removeUserFromRoom(roomId, participant.userId, "");
      });
    }
  };

  /**
   * Change room hidden
   * @param roomId - Room matrix id
   * @param isHidden - hidden room if it is true
   */
  const changeRoomHidden = (roomId: string, isHidden: boolean) => {
    try {
      let currentRoom = room;
      if (room) {
        if (roomId !== room.roomId) {
          currentRoom = getRoom(roomId);
        }
      } else {
        currentRoom = getRoom(roomId);
      }
      logger.debug(`${module}: changeRoomHidden - roomId:${roomId}, isHidden:${isHidden} - currentRoom:${currentRoom}`);
      if (!currentRoom) return;
      if (isPrivate(roomId) && !isGroup(roomId)) {
        const ownerId = getRoomCreator(currentRoom.roomId);
        const { user_id } = JSON.parse(localStorage.getItem(localStorageKeys.CURRENT_USER) || "");

        const roomTopic = getRoomTopic({ room: currentRoom });
        logger.debug(`${module}: changeRoomHidden - roomTopic:${roomTopic}`);
        if (roomTopic) {
          const roomTopicObj = tryParse(roomTopic);
          if (roomTopicObj.isHidden && roomTopicObj.isHidden !== isHidden) {
            if (user_id === ownerId) {
              updateRoomTopic(currentRoom.roomId, MatrixRoomTopics.isHidden, isHidden, roomTopic);
            } else {
              const content = {
                type: InternalMessageContentType.RoomChangeHidden,
                body: { roomId, isHidden, ownerId },
              };
              sendInternalMessage(room?.roomId!, LocalEventTypeEnum.InternalMessage, content);
            }
          }
        }
      }
    } catch (e) {
      logger.error(`${module}: Error in changeRoomHidden - ${e.message}`);
    }
  };

  const updateRoomsName = (rooms: LocalMatrixRoom[]) => {
    [...rooms].forEach(async (mRoom) => {
      if (mRoom.type === MatrixRoomTypes.direct || (mRoom.type === MatrixRoomTypes.private && isGroup(mRoom.roomId))) {
        let newName = calculateRoomName(mRoom);
        logger.debug(`${module}: updateRoomsName newName:${newName} - room:${mRoom.roomId}`);
        if (newName !== mRoom.name) {
          // updates the local MatrixRoom name and the local roomName
          setRoomName(newName);
          mRoom.name = newName;
          const localRoom = getRoomFromRoomList(roomList, mRoom.roomId);
          // only dispatch the action if the name change is not for the current room
          // because if it's the current room, will be handle in the useEffect of roomName
          if (localRoom && localRoom.roomId !== currentRoomId) {
            localRoom.name = newName;
            // updates the room name in the roomList (redux)
            dispatch(updateRoomInRoomList(localRoom));
          }
        }
      }
    });
  };

  useEffect(() => {
    const matrixRooms = mxClient.getRoomList();

    if (
      Object.keys(roomList).length > 0 &&
      Object.keys(matrixRooms).length > 0 &&
      !compareRoomLists(roomList, matrixRooms)
    ) {
      dispatch(setRoomList(matrixRooms));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!room) return;
    handleRoomNameUpdate(room);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, room?.name]);

  useEffect(() => {
    if (!room) return;
    if (!reduxLocalRoom || (reduxLocalRoom && reduxLocalRoom.roomId !== room.roomId)) return;
    handleRoomNameUpdate(room);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxLocalRoom?.members]);

  useEffect(() => {
    if (!room) return;
    const localRoom = getRoomFromRoomList(roomList, room.roomId);
    if (localRoom && localRoom.name !== roomName) {
      localRoom.name = roomName;
      // updates the room name in the roomList (redux)
      dispatch(updateRoomInRoomList(localRoom));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomName]);

  useEffect(() => {
    if (!roomId) return;
    const room = getRoom(roomId);
    setRoom(room);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  useEffect(() => {
    if (!onRoomEvent) return;
    if (currentSyncState !== SyncState.Syncing) return;
    handleRoomEvent(onRoomEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRoomEvent]);

  useEffect(() => {
    if (!onDeleteRoomEvent) return;
    if (currentSyncState !== SyncState.Syncing) return;
    handleDeleteRoomEvent(onDeleteRoomEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDeleteRoomEvent]);

  useEffect(() => {
    if (!onAccountDataEvent) return;
    if (currentSyncState !== SyncState.Syncing) return;
    handleAccountDataEvent(onAccountDataEvent as [any]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onAccountDataEvent]);

  return {
    room,
    roomList,
    roomName,
    reduxLocalRoom,
    setRoomName,
    getRoomList,
    getRoomListByType,
    getRoomsWithPortalLink,
    getRoom,
    getPublicRoomList,
    isPublic,
    setRoomTopic,
    getRoomCreator,
    getRoomTopic,
    setRoomTag,
    deleteRoomTag,
    categorizeRoom,
    handleRoomTagEvent,
    createDirectRoom,
    inviteToRoom,
    joinRoom,
    mapRoomList,
    leaveRoom,
    handleRoomEvent,
    removeRoom,
    handleDeleteRoomEvent,
    handleRoomMembershipEvent,
    removeUserFromRoom,
    setRoomDirectoryVisibility,
    getRoomDirectoryVisibility,
    setDirectAccountData,
    createAliasName,
    calculateRoomName,
    calculateUserName,
    isGroup,
    generateRoomName,
    isDirect,
    isPrivate,
    deleteRoomLowPrio,
    isRoomLowPrio,
    getRoomTags,
    updateRoomName,
    setRoomEventsTimestamp,
    sortRoomMembers,
    checkForExistingRoom,
    checkForExistingRooms,
    getNoDirectAndPublicMatrixRooms,
    updateRoomTopic,
    convertMatrixRoomToMappedRoom,
    updateRoomsMembersBasedOnCalendarEvents,
    updateRoomMembers,
    changeRoomHidden,
    getOrCreateDirectRoom,
    markRoomAsLowPriority,
    markRoomAsFavorite,
    removeRoomAsFavorite,
    updateLocalRoomName,
    updateRoomsName,
    getJoinedMemberCount,
  };
};

export default useMatrixRooms;
