export const SET_CURRENTROOM = "MATRIX::SET_CURRENTROOM";
export const SET_NEWMESSAGE = "MATRIX::SET_NEWMESSAGE";
export const SET_MATRIX_TOKEN = "MATRIX::SET_MATRIX_TOKEN";
export const SET_MATRIX_LOGIN_TOKEN = "MATRIX::SET_MATRIX_LOGIN_TOKEN";
export const SET_MATRIX_HOMESERVER = "MATRIX::SET_MATRIX_HOMESERVER";

export const START_MATRIX = "MATRIX::START_MATRIX";
export const START_MATRIX_SUCCEEDED = "MATRIX::START_MATRIX_SUCCEEDED";
export const START_MATRIX_FAILED = "MATRIX::START_MATRIX_FAILED";

export const STOP_MATRIX = "MATRIX::STOP_MATRIX";
export const STOP_MATRIX_SUCCEEDED = "MATRIX::STOP_MATRIX_SUCCEEDED";
export const STOP_MATRIX_FAILED = "MATRIX::STOP_MATRIX_FAILED";

export const PLAY_MATRIX_CALL_RINGTONE = "MATRIX::PLAY_MATRIX_CALL_RINGTONE";
export const PLAY_MATRIX_CALL_RINGTONE_SUCCEEDED = "MATRIX::PLAY_MATRIX_CALL_RINGTONE_SUCCEEDED";
export const PLAY_MATRIX_CALL_RINGTONE_FAILED = "MATRIX::PLAY_MATRIX_CALL_RINGTONE_FAILED";

export const STOP_MATRIX_CALL_RINGTONE = "MATRIX::STOP_MATRIX_CALL_RINGTONE";
export const STOP_MATRIX_CALL_RINGTONE_SUCCEEDED = "MATRIX::STOP_MATRIX_CALL_RINGTONE_SUCCEEDED";
export const STOP_MATRIX_CALL_RINGTONE_FAILED = "MATRIX::STOP_MATRIX_CALL_RINGTONE_FAILED";

export const SET_MATRIX_CALL_PARTICIPANTS = "MATRIX::SET_MATRIX_CALL_PARTICIPANTS";
export const SET_MATRIX_CALL_ROOM_ID = "MATRIX::SET_MATRIX_CALL_ROOM_ID";

export const CREATE_MATRIX_CALL_CLIENT = "MATRIX::CREATE_MATRIX_CALL_CLIENT";
export const CREATE_MATRIX_CALL_CLIENT_SUCCEEDED = "MATRIX::CREATE_MATRIX_CALL_CLIENT_SUCCEEDED";
export const CREATE_MATRIX_CALL_CLIENT_FAILED = "MATRIX::CREATE_MATRIX_CALL_CLIENT_FAILED";

export const DELETE_MATRIX_CALL_CLIENT = "MATRIX::DELETE_MATRIX_CALL_CLIENT";

export const INCOMING_MATRIX_CALL = "MATRIX::INCOMING_MATRIX_CALL";
export const DELETE_INCOMING_MATRIX_CALL = "MATRIX::DELETE_INCOMING_MATRIX_CALL";

export const ANSWER_MATRIX_CALL = "MATRIX::ANSWER_MATRIX_CALL";
export const ANSWER_MATRIX_CALL_SUCCEEDED = "MATRIX::ANSWER_MATRIX_CALL_SUCCEEDED";
export const ANSWER_MATRIX_CALL_FAILED = "MATRIX::ANSWER_MATRIX_CALL_FAILED";

export const REJECT_MATRIX_CALL = "MATRIX::REJECT_MATRIX_CALL";
export const REJECT_MATRIX_CALL_SUCCEEDED = "MATRIX::REJECT_MATRIX_CALL_SUCCEEDED";
export const REJECT_MATRIX_CALL_FAILED = "MATRIX::REJECT_MATRIX_CALL_FAILED";

export const PLACE_MATRIX_CALL = "MATRIX::PLACE_MATRIX_CALL";
export const PLACE_MATRIX_CALL_SUCCEEDED = "MATRIX::PLACE_MATRIX_CALL_SUCCEEDED";
export const PLACE_MATRIX_CALL_FAILED = "MATRIX::PLACE_MATRIX_CALL_FAILED";

export const PLACE_MATRIX_SCREENSHARE = "MATRIX::PLACE_MATRIX_SCREENSHARE";
export const PLACE_MATRIX_SCREENSHARE_SUCCEEDED = "MATRIX::PLACE_MATRIX_SCREENSHARE_SUCCEEDED";
export const PLACE_MATRIX_SCREENSHARE_FAILED = "MATRIX::PLACE_MATRIX_SCREENSHARE_FAILED";
export const PLACE_MATRIX_SCREENSHARE_CANCELED = "MATRIX::PLACE_MATRIX_SCREENSHARE_CANCELED";

export const STOP_MATRIX_SCREENSHARE = "MATRIX::STOP_MATRIX_SCREENSHARE";
export const STOP_MATRIX_SCREENSHARE_SUCCEEDED = "MATRIX::STOP_MATRIX_SCREENSHARE_SUCCEEDED";
export const STOP_MATRIX_SCREENSHARE_FAILED = "MATRIX::STOP_MATRIX_SCREENSHARE_FAILED";

export const HANGUP_MATRIX_CALL = "MATRIX::HANGUP_MATRIX_CALL";
export const HANGUP_MATRIX_CALL_SUCCEEDED = "MATRIX::HANGUP_MATRIX_CALL_SUCCEEDED";
export const HANGUP_MATRIX_CALL_FAILED = "MATRIX::HANGUP_MATRIX_CALL_FAILED";

export const MATRIX_CALL_MUTE_AUDIO = "MATRIX::MATRIX_CALL_MUTE_AUDIO";
export const MATRIX_CALL_MUTE_AUDIO_SUCCEEDED = "MATRIX::MATRIX_CALL_MUTE_AUDIO_SUCCEEDED";
export const MATRIX_CALL_MUTE_AUDIO_FAILED = "MATRIX::MATRIX_CALL_MUTE_AUDIO_FAILED";

export const MATRIX_CALL_MUTE_SPEAKER = "MATRIX::MATRIX_CALL_MUTE_SPEAKER";
export const MATRIX_CALL_MUTE_SPEAKER_SUCCEEDED = "MATRIX::MATRIX_CALL_MUTE_SPEAKER_SUCCEEDED";
export const MATRIX_CALL_MUTE_SPEAKER_FAILED = "MATRIX::MATRIX_CALL_MUTE_SPEAKER_FAILED";

export const MATRIX_CALL_MUTE_VIDEO = "MATRIX::MATRIX_CALL_MUTE_VIDEO";
export const MATRIX_CALL_MUTE_VIDEO_SUCCEEDED = "MATRIX::MATRIX_CALL_MUTE_VIDEO_SUCCEEDED";
export const MATRIX_CALL_MUTE_VIDEO_FAILED = "MATRIX::MATRIX_CALL_MUTE_VIDEO_FAILED";

export const UPDATE_MATRIX_CALL_STATE = "MATRIX::UPDATE_MATRIX_CALL_STATE";
export const SUBSCRIBE_MATRIX_CALL_STATE = "MATRIX::SUBSCRIBE_MATRIX_CALL_STATE";
export const UNSUBSCRIBE_MATRIX_CALL_STATE = "MATRIX::UNSUBSCRIBE_MATRIX_CALL_STATE";

export const SUBSCRIBE_MATRIX_CALL_STATE_SUCCEEDED = "MATRIX::SUBSCRIBE_MATRIX_CALL_STATE_SUCCEEDED";
export const SUBSCRIBE_MATRIX_CALL_STATE_FAILED = "MATRIX::SUBSCRIBE_MATRIX_CALL_STATE_FAILED";
export const UNSUBSCRIBE_MATRIX_CALL_STATE_SUCCEEDED = "MATRIX::UNSUBSCRIBE_MATRIX_CALL_STATE_SUCCEEDED";
export const UNSUBSCRIBE_MATRIX_CALL_STATE_FAILED = "MATRIX::UNSUBSCRIBE_MATRIX_CALL_STATE_FAILED";

export const UPDATE_MATRIX_EVENT = "MATRIX::UPDATE_MATRIX_EVENT";
export const SUBSCRIBE_MATRIX_EVENT = "MATRIX::SUBSCRIBE_MATRIX_EVENT";
export const SUBSCRIBE_MATRIX_EVENT_SUCCEEDED = "MATRIX::SUBSCRIBE_MATRIX_EVENT_SUCCEEDED";
export const SUBSCRIBE_MATRIX_EVENT_FAILED = "MATRIX::SUBSCRIBE_MATRIX_EVENT_FAILED";

export const UNSUBSCRIBE_MATRIX_EVENT = "MATRIX::UNSUBSCRIBE_MATRIX_EVENT";
export const UNSUBSCRIBE_MATRIX_EVENT_SUCCEEDED = "MATRIX::UNSUBSCRIBE_MATRIX_EVENT_SUCCEEDED";
export const UNSUBSCRIBE_MATRIX_EVENT_FAILED = "MATRIX::UNSUBSCRIBE_MATRIX_EVENT_FAILED";

export const MATRIX_UPLOAD_AVATAR = "MATRIX::MATRIX_UPLOAD_AVATAR";
export const MATRIX_UPLOAD_AVATAR_SUCCEEDED = "MATRIX::MATRIX_UPLOAD_AVATAR_SUCCEEDED";
export const MATRIX_UPLOAD_AVATAR_FAILED = "MATRIX::MATRIX_UPLOAD_AVATAR_FAILED";
export const MATRIX_UPLOAD_AVATAR_CLEAR_ERROR = "MATRIX::MATRIX_UPLOAD_AVATAR_CLEAR_ERROR";

export const UPDATE_MATRIX_ROOM_AVATAR = "MATRIX::UPDATE_MATRIX_ROOM_AVATAR";
export const SET_ROOM_LIST = "MATRIX::SET_ROOM_LIST";
export const UPDATE_ROOM_IN_ROOM_LIST = "MATRIX::UPDATE_ROOM_IN_ROOM_LIST";
export const REMOVE_ROOM_FROM_ROOM_LIST = "MATRIX::REMOVE_ROOM_FROM_ROOM_LIST";
export const UPDATE_UNREAD_NOTIFICATION_COUNT = "MATRIX::UPDATE_UNREAD_NOTIFICATION_COUNT";
export const SET_DEVICE_SETTINGS = "MATRIX::SET_DEVICE_SETTINGS";
export const SET_IN_MATRIX_REMOTE_SCREENSHARE = "MATRIX::SET_IN_MATRIX_REMOTE_SCREENSHARE";

export const SET_CHAT_VISIBLE = "MATRIX::SET_CHAT_VISIBLE";
export const SET_REDIRECT_TO_ADHOC_CONF = "MATRIX::SET_REDIRECT_TO_ADHOC_CONF";
export const SEND_MESSAGE_ERROR_DATA = "MATRIX::SEND_MESSAGE_ERROR_DATA";
export const SET_ROOM_EVENTS_LIST = "MATRIX::SET_ROOM_EVENTS_LIST";
export const SET_CURRENTUSER = "MATRIX::SET_CURRENTUSER";
export const GET_CURRENTUSER_PROFILEINFO = "MATRIX::GET_CURRENTUSER_PROFILEINFO";
export const SET_MATRIX_SYNCSTATE = "MATRIX::SET_MATRIX_SYNCSTATE";
export const SET_ROOMLIST_FILTER = "MATRIX::SET_ROOMLIST_FILTER";
