import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store/hooks";
import mxClient from "matrix/matrix";
import { mergeArrays } from "utils/helpers";
import useLocalStorage from "./useLocalStorage";
import useMatrixEvent from "./useMatrixEvent";
import useMatrixMessage from "./useMatrixMessage";
import { LocalEventType, MatrixRoom } from "types/Matrix";
import { Direction, Filter, MatrixEvent, Room } from "matrix-js-sdk";
import { UCUser } from "types/UC";
import { getLogger } from "logger/appLogger";
import { localStorageKeys } from "utils/constants";
import { matrixActions } from "store/actions/matrix";

let roomMessageFilter: Filter | undefined;

const useMatrixTimeline = (currentRoom: MatrixRoom, roomRef: any, eventsType: LocalEventType[]) => {
  const dispatch = useDispatch();
  const logger = useMemo(() => getLogger("matrix.timeline"), []);
  const module = "useMatrixTimeline";

  const client = mxClient.client;
  const [user] = useLocalStorage<UCUser>(localStorageKeys.CURRENT_USER);
  const [filterId] = useLocalStorage<string>(`mxjssdk_memory_filter_FILTER_SYNC_${user.user_id}`);
  const [fromToken, setFromToken] = useState<string | null | undefined>(null); //null for initial state, undefined if there are no more events to request
  const [populateRoom, setPopulateRoom] = useState(false);

  const [roomInitialized, setRoomInitialized] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [triggerScrollBottom, setTriggerScrollBottom] = useState(0);

  const roomTimelineEvent = useMatrixEvent(client, "Room.timeline");
  const roomlocalEchoUpdated = useMatrixEvent(client, "Room.localEchoUpdated");
  const { sendReceipt } = useMatrixMessage();

  const { setRoomEventsList } = matrixActions;
  const events = useAppSelector((state) => state.matrix.roomEventsList);

  const MAX_EVENTS = 300;
  const NR_EVENTS = 50;

  const resetState = () => {
    dispatch(setRoomEventsList([]));
    setRoomInitialized(false);
    setCanLoadMore(true);
    setTriggerScrollBottom(0);
    setFromToken(null);
  };

  const handleScroll = async (element: HTMLUListElement | undefined) => {
    try {
      if (element) {
        const { scrollTop } = element;

        if (scrollTop === 0) {
          if (!roomInitialized) return;
          if (!canLoadMore) return;
          setIsFetching(true);
          await loadMoreHistory();
        }
      }
    } catch (e) {
      logger.error(`${module}: Error in handleScroll - ${e.message}`);
    }
  };

  const scrollIntoView = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    } else {
      if (roomRef && roomRef.current) {
        roomRef.current.scrollTop = MAX_EVENTS;
      }
    }
  };

  const createRoomMessageFilter = async () => {
    if (!roomMessageFilter) {
      const roomFilter = {
        room: {
          timeline: {
            limit: NR_EVENTS,
            types: eventsType,
          },
        },
      };

      try {
        roomMessageFilter = await await client.createFilter(roomFilter);
        roomMessageFilter.setDefinition(roomFilter);
        logger.debug(`created room message filter with id=${roomMessageFilter.getFilterId()}`);
      } catch (e) {
        logger.error(`${module}: Error in createRoomMessageFilter - ${e.message}`);
      }
    }
    return roomMessageFilter;
  };

  const updateEvents = async (isStart = false) => {
    try {
      const filter = await createRoomMessageFilter();
      const [liveEvents, lastToken] = await mxClient.createMessagesRequest(
        currentRoom,
        fromToken!,
        NR_EVENTS,
        Direction.Backward,
        filter
      );
      logger.debug(
        `UseMatrixTimeline - updateEvents isStart=${isStart} fromToken=${fromToken} => ${liveEvents.length} events`
      );
      setFromToken(lastToken);

      if (isStart) {
        dispatch(setRoomEventsList(mergeArrays([], liveEvents)));
        setTriggerScrollBottom((prevCount: number) => prevCount + 1);
        //get the last event of the room and send the receipt to update the unread notifications
        const liveTimeline = currentRoom.getLiveTimeline();
        const totalLiveEvents = liveTimeline.getEvents();
        const lastEvent = totalLiveEvents[totalLiveEvents.length - 1];
        if (lastEvent) await sendReceipt(lastEvent);
        if (!fromToken) {
          setCanLoadMore(false);
        }
      } else {
        // gets the last event read before scrolling
        const lastEventReceipt = events.length > 0 ? events[0] : null;
        dispatch(setRoomEventsList(mergeArrays(events, liveEvents)));
        scrollIntoView(lastEventReceipt?.getId());
      }
    } catch (e) {
      logger.error(`${module}: Error in updateEvents - isStart:${isStart} - ${e.message}`);
    }
  };

  const getFilter = async () => {
    return client.getFilter(user.user_id, filterId, false);
  };

  const loadMoreHistory = async () => {
    try {
      // there are no more events to request
      if (fromToken === undefined) {
        setCanLoadMore(false);
        setIsFetching(false);
        return;
      }

      updateEvents();
      setIsFetching(false);
    } catch (e) {
      logger.error(`${module}: Error in loadMoreHistory - ${e.message}`);
    }
  };

  const populateTimeline = async () => {
    try {
      const filter: Filter = await getFilter();

      const timelineSets = currentRoom.getTimelineSets();
      timelineSets.forEach((set) => {
        set.setFilter(filter);
      });

      updateEvents(true);
      setRoomInitialized(true);
    } catch (e) {
      logger.error(`${module}: Error in populateTimeline - ${e.message}`);
    }
  };

  const handleNewTimelineEvent = async ([event, eventRoom, toStartOfTimeline, removed, data]: [
    MatrixEvent,
    Room,
    any,
    any,
    any,
  ]) => {
    try {
      if (currentRoom.roomId !== eventRoom.roomId) return;
      if (toStartOfTimeline) return;
      const prevEvents = events;
      // checks if the new event is of types in eventTypes
      const includedInEventsType = eventsType.find((type) => type === event.getType());
      // remove the cancelled event from the list of events
      if (includedInEventsType && event.status === "cancelled") {
        const newEventsList = prevEvents.filter((ev: MatrixEvent) => ev.getId() !== event.getId());
        dispatch(setRoomEventsList(newEventsList));
        return;
      }
      // events of type redaction (delete) must be updated on event list (redux)
      if (event.getType() === "m.room.redaction") {
        const eventIndex = prevEvents.findIndex((ev) => ev.getId() === event.event.redacts);
        if (eventIndex) {
          const origEvent = prevEvents[eventIndex];
          origEvent.event.unsigned = {
            redacted_because: {
              sender: event.event.sender,
            },
            ...origEvent.event.unsigned,
          };
          prevEvents[eventIndex] = origEvent;
          dispatch(setRoomEventsList(prevEvents));
          return;
        }
      }
      if (!data.liveEvent) return;
      await sendReceipt(event);
      if (includedInEventsType) {
        if (prevEvents.length >= MAX_EVENTS) {
          prevEvents.shift();
        } else {
          setTriggerScrollBottom((prevCount: number) => prevCount + 1);
        }
        dispatch(setRoomEventsList([...prevEvents, event]));
      }
    } catch (e) {
      logger.error(`${module}: Error in handleNewTimelineEvent - ${e.message}`);
    }
  };

  useEffect(() => {
    if (!roomTimelineEvent) return;
    handleNewTimelineEvent(roomTimelineEvent as [any, any, any, any, any]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomTimelineEvent]);

  useEffect(() => {
    if (!roomlocalEchoUpdated) return;
    const [event, , , oldStatus] = roomlocalEchoUpdated;
    logger.debug(`${module}: Event updated from ${oldStatus} to ${event.status}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomlocalEchoUpdated]);

  useEffect(() => {
    if (!currentRoom) return;
    resetState();
    // Note: decouple resetState() from populateTimeline() so state vars can be set
    // (otherwise stale state variables read)
    setPopulateRoom(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoom]);

  useEffect(() => {
    if (populateRoom) {
      currentRoom.recalculate();
      populateTimeline();
      setPopulateRoom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [populateRoom]);

  useEffect(() => {
    return () => {
      resetState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    events,
    roomInitialized,
    canLoadMore,
    isFetching,
    triggerScrollBottom,
    handleScroll,
  };
};

export default useMatrixTimeline;
