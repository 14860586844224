import { useState, useMemo } from "react";
import { LocalStorageValue } from "types/Hooks";
import { getLogger } from "logger/appLogger";

function useLocalStorage<T>(key: string, initialValue?: T): LocalStorageValue<T> {
  const logger = useMemo(() => getLogger("app"), []);
  const module = "useLocalStorage";

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      logger.error(`${module}: Error - ${error.message}`);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T) => {
    try {
      if (value === undefined) return;
      if (value === null) {
        window.localStorage.removeItem(key);
        return;
      }
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      logger.error(`${module}: Error in setValue - ${error.message}`);
    }
  };

  return [storedValue, setValue];
}

export default useLocalStorage;
