import { useLightBox } from "hooks";
import { fileName } from "utils/helpers";
import { test } from "utils/helpers";
import { checkText } from "smile2emoji";
import Linkify from "react-linkify";

const Text = ({ content, imageSrc }: { content: any; imageSrc: string | undefined }) => {
  const { showLightBox, renderLightBox } = useLightBox();

  return (
    <div {...test("MESSAGE_EVENT_TEXT")}>
      <Linkify>{checkText(content.body)}</Linkify>
      {renderLightBox()}
      {imageSrc ? (
        <img src={imageSrc} alt={fileName(imageSrc)} data-attribute="mxImages" onClick={showLightBox} />
      ) : null}
    </div>
  );
};

export default Text;
