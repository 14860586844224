import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import BackIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { IconButton } from "components";
import { t } from "i18next";

const CustomDialog = ({ ...props }) => {
  const theme = useTheme();
  const { id, dialogTitle, buttons, children, onClose, onBack, showIconClose, showIconBack, open, ...other } = props;

  return (
    <Dialog
      id={`${id}-dialog`}
      maxWidth="xs"
      //   onEntering={handleEntering}
      aria-labelledby="custom-dialog"
      open={open}
      PaperProps={{
        sx: {
          backdropFilter: "blur(5px)",
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paperWithOpacity,
        },
      }}
      {...other}
    >
      <DialogTitle
        id="custom-dialog-title"
        sx={
          dialogTitle
            ? typeof dialogTitle === `string`
              ? {
                  margin: 0,
                  padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)}`,
                }
              : {
                  margin: 0,
                  padding: "26px 55px 10px 55px",
                  display: "flex",
                  justifyContent: "center",
                }
            : {}
        }
      >
        {showIconBack && (
          <IconButton
            aria-label="back"
            tooltip={t("BACK")}
            tooltipPlacement="top"
            onClick={onBack}
            size="large"
            sx={{ position: "absolute", left: theme.spacing(1), top: "25px", color: theme.palette.icon.main }}
          >
            <BackIcon />
          </IconButton>
        )}
        {dialogTitle ? dialogTitle : null}
        {showIconClose && (
          <IconButton
            aria-label="close"
            tooltip={t("CLOSE")}
            tooltipPlacement="top"
            onClick={onClose}
            size="large"
            sx={{ position: "absolute", right: theme.spacing(1), top: "13px", color: theme.palette.icon.main }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className="scroll-bar" sx={{ padding: "8px 0px" }}>
        {children}
      </DialogContent>
      {buttons && <DialogActions>{buttons}</DialogActions>}
    </Dialog>
  );
};

export default CustomDialog;
