import React, { useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "store/hooks";
import { MatrixEvent } from "matrix-js-sdk";
import Avatar from "../_shared/Avatar";
import Typography from "@mui/material/Typography";
import MessageActions from "./MessageActions";
import MessageErrorActions from "./MessageErrorActions";
import { getLogger } from "logger/appLogger";
import { useMatrixAvatar, useMatrixMessage } from "hooks";
import { test } from "utils/helpers";
import { Text, Image, File, Html, Map } from "./MessageEventType";
import styles from "./Messages.module.scss";
import { LocalMatrixRoom, MatrixRoom } from "types/Matrix";

const MessageEvent = ({
  mxEvent,
  content,
  isDifferentDay,
  isCurrentUser,
  room,
  shouldContinue = false,
  isLast = true,
  isRedacted = false,
}: {
  mxEvent: MatrixEvent;
  content: any;
  isDifferentDay: boolean;
  isCurrentUser: boolean;
  room: MatrixRoom;
  shouldContinue?: boolean;
  isLast?: boolean;
  isRedacted?: boolean;
}) => {
  const logger = useMemo(() => getLogger("matrix"), []);
  const module = "MessageEvent";
  const theme = useTheme();
  const { getRoomMemberAvatar, getHttpUrl, getMatrixImage } = useMatrixAvatar();
  const { getSenderDisplayName, getForwardMsg, getReplyMsg, messageDeletedElement } = useMatrixMessage(room);

  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  const [eventWithErrors, setEventWithErrors] = useState(false);
  const [filteredRoomList, setFilteredRoomList] = useState<LocalMatrixRoom[]>([]);
  const senderName = getSenderDisplayName(mxEvent);

  const sendMessageErrorData = useAppSelector((state) => state.matrix.sendMessageErrorData);
  const roomList = useAppSelector((state) => state.matrix.roomList);

  useEffect(() => {
    if (!roomList) return;
    const filteredRooms: LocalMatrixRoom[] = [];
    Object.keys(roomList).forEach((list) =>
      roomList[list].forEach((localRoom: LocalMatrixRoom) => {
        const { tags }: { tags: any } = localRoom;
        if (
          room &&
          localRoom.roomId !== room.roomId &&
          !localRoom.isHidden &&
          !tags["m.lowpriority"] &&
          room.name.length > 0
        )
          filteredRooms.push(localRoom);
      })
    );
    setFilteredRoomList(filteredRooms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomList]);

  useEffect(() => {
    if (sendMessageErrorData && sendMessageErrorData.eventId === mxEvent.event.event_id) {
      setEventWithErrors(true);
    } else setEventWithErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessageErrorData]);

  const messageContentStyle = isCurrentUser
    ? {
        backgroundColor: `${
          eventWithErrors ? theme.palette.background.chat.messageError : theme.palette.background.chat.currentUser
        }`,
        color: theme.palette.text.chat.currentUser,
        border: `1px solid ${
          eventWithErrors ? theme.palette.border.chat.messageError : theme.palette.border.chat.currentUser
        }`,
        "--blockquoteBackColor": theme.palette.background.chat.currentUserReply,
        "--blockquoteBorder": theme.palette.border.chat.currentUserReply,
        "--blockquoteColor": theme.palette.text.chat.currentUserReply,
      }
    : {
        backgroundColor: theme.palette.background.chat.otherUser,
        color: theme.palette.text.chat.otherUser,
        "--blockquoteBackColor": theme.palette.background.chat.otherUserReply,
        "--blockquoteBorder": theme.palette.border.chat.otherUserReply,
        "--blockquoteColor": theme.palette.text.chat.otherUserReply,
      };

  const navigateToEventMsg = (eventId: string | undefined) => {
    if (eventId) {
      const element = document.getElementById(eventId);
      if (element) element.scrollIntoView();
    }
  };

  const renderMessage = (content: any, contentSrc: string | undefined) => {
    if (!content) return;

    let element = null;

    const replyEventId = mxEvent.replyEventId;

    const isFwdMsg =
      getForwardMsg(content) || (content.formatted_body && (content.formatted_body as string).includes("<mx-forward>"));
    if (isFwdMsg && content.formatted_body) {
      element = <Html content={content} imageSrc={imageSrc} />;
    } else {
      if (replyEventId && room) getReplyMsg(mxEvent);

      switch (content.msgtype) {
        case "m.text":
          if (content.formatted_body) {
            element = <Html content={content} imageSrc={imageSrc} />;
          } else {
            element = <Text content={content} imageSrc={imageSrc} />;
          }
          break;
        case "m.image":
          element = <Image content={content} />;
          break;
        case "m.file":
          element = <File content={content} contentSrc={contentSrc} />;
          break;
        case "m.location":
          element = <Map geoUri={content.geo_uri} body={content.body} imageSrc={imageSrc} />;
          break;
        default:
          if (content.formatted_body) {
            element = <Html content={content} imageSrc={imageSrc} />;
          } else {
            element = <Text content={content} imageSrc={imageSrc} />;
          }
          break;
      }
    }

    return (
      <div onClick={() => navigateToEventMsg(replyEventId)} style={{ cursor: replyEventId ? "pointer" : "auto" }}>
        {element}
      </div>
    );
  };

  useEffect(() => {
    if (!content) return;
    try {
      const imgRegex = /<img [^>]*src="[^"]*"[^>]*>/gm;
      const srcRegex = /.*src="([^"]*)".*/;
      if (content.msgtype === "m.location") {
        let contentUrl = content.info && content.info.thumbnail_url ? content.info.thumbnail_url : "";
        const imageScr = getMatrixImage(contentUrl);
        if (imageScr) setImageSrc(imageScr);
      } else if (content.formatted_body) {
        const sources = content.formatted_body.match(imgRegex)?.map((x: string) => x.replace(srcRegex, "$1"));
        if (sources && sources.length > 0) {
          setImageSrc(sources[0]);
        }
      } else {
        let contentUrl = null;
        if (content.msgtype === "m.image")
          contentUrl = content.url && content.url.content_uri ? content.url.content_uri : content.url;
        if (contentUrl) setImageSrc(getMatrixImage(contentUrl));
      }
    } catch (e) {
      logger.error(`${module}: Error handling the update of message content - ${e.message}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <>
      {isDifferentDay ? (
        <>
          <li className={`${styles.dateSeperator} ${styles.separatorBG}`} {...test("MESSAGE_DATE_SEPARATOR")} />
          <Moment
            className={`${styles.dateSeperatorText} ${styles.separatorText}`}
            format="MM/DD/YYYY"
            fromNowDuring={2592000000}
            date={mxEvent.getDate() ?? undefined}
          />
        </>
      ) : null}
      <li
        id={mxEvent.getId()}
        className={`${styles.message} ${isCurrentUser ? styles.myMessage : styles.memberMessage}`}
        {...test("MESSAGE_EVENT")}
      >
        {(!isCurrentUser && !shouldContinue) || (!isCurrentUser && isDifferentDay) ? (
          <div className={styles.messageMemberInfoContainer}>
            <Avatar
              name={senderName}
              size="46"
              src={
                mxEvent.getRoomId() && mxEvent.getSender()
                  ? getRoomMemberAvatar(mxEvent.getRoomId(), mxEvent.getSender()!)
                  : ""
              }
              disableBadge={true}
            />
            <Typography variant="subtitle2" sx={{ marginLeft: "13px", textTransform: "none" }}>
              {senderName}
            </Typography>
          </div>
        ) : null}
        <div id="message_event_content_container" className={styles.messageEventContentContainer}>
          {isCurrentUser && eventWithErrors && <MessageErrorActions data={mxEvent} />}
          <div
            id="message_event_content"
            className={`${mxEvent.isSending() ? styles.isSending : ""} ${styles.messageContent}
                    ${isCurrentUser ? `${styles.myMessageContent}` : styles.memberMessageContent}`}
            style={{
              ...messageContentStyle,
            }}
          >
            {isRedacted
              ? messageDeletedElement(mxEvent)
              : renderMessage(
                  content,
                  getHttpUrl(content.url && content.url.content_uri ? content.url.content_uri : content.url)
                )}
            {!eventWithErrors && <MessageActions data={mxEvent} filteredRoomList={filteredRoomList} />}
          </div>
        </div>

        {isCurrentUser && eventWithErrors && (
          <Typography variant="body3" color="error" sx={{ marginTop: "5px" }}>
            Failed to send
          </Typography>
        )}

        {isLast ? (
          <div className={styles.messageInfoContainer}>
            <div className={`${styles.messageInfo} ${isCurrentUser ? styles.myMessageInfo : styles.memberMessageInfo}`}>
              <Moment
                className={styles.messageInfoDate}
                fromNowDuring={2592000000}
                local
                date={mxEvent.getDate() ?? undefined}
              />
            </div>
          </div>
        ) : null}
      </li>
    </>
  );
};

export default React.memo(MessageEvent);
