import { MatrixClient } from "matrix-js-sdk";
import { useState, useCallback } from "react";
import useEventEmitter from "./useEventEmitter";

const useMatrixEvent = (client: MatrixClient, eventType: string | null) => {
  const [value, setValue] = useState<any[]>();

  const handler = useCallback((...args: any[]): void => {
    setValue(args);
  }, []);

  useEventEmitter(client, eventType, handler);

  return value;
};

export default useMatrixEvent;
