import { useState } from "react";

import File from "./File";

import styles from "../Messages.module.scss";
import { useMatrixAvatar, useLightBox } from "hooks";
import { test } from "utils/helpers";

const Image = ({ content }: { content: any }) => {
  const contentUrl = content.url && content.url.content_uri ? content.url.content_uri : content.url;
  const { getMatrixImage, getHttpUrl } = useMatrixAvatar();
  const [src] = useState(getMatrixImage(contentUrl));
  const contentScr = getHttpUrl(contentUrl);
  const { showLightBox, renderLightBox } = useLightBox();

  return (
    <div className={styles.messageEvent} {...test("MESSAGE_EVENT_IMAGE")}>
      {renderLightBox()}
      <File content={content} contentSrc={contentScr} />
      <img
        src={src ? src : ""}
        alt={content.body}
        data-attribute="mxImages"
        onClick={showLightBox}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

export default Image;
