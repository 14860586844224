import { Stack, Typography } from "@mui/material";
import TypographyWithTooltip from "components/_shared/Typography";
import { Avatar } from "components";
import { CustomAvatarProps } from "./Avatar";

interface AvatarWithLabelsProps extends CustomAvatarProps {
  id: string;
  statusMsg: string | undefined;
  size?: string;
}

const AvatarWithLabels = ({ ...props }: AvatarWithLabelsProps) => {
  const { statusMsg, id, size = "46", ...params } = props;

  return (
    <Stack id={id} direction="row" alignItems="center" spacing={"15px"} sx={{ width: "100%" }}>
      <Avatar {...params} size={size} />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="baseline"
        spacing={"2px"}
        sx={{ width: "calc(70%)" }}
      >
        <TypographyWithTooltip variant="body1"> {props.name}</TypographyWithTooltip>
        {props.isPerson && statusMsg && <Typography variant="body2">{statusMsg}</Typography>}
      </Stack>
    </Stack>
  );
};

export default AvatarWithLabels;
