import { put, takeLatest } from "redux-saga/effects";
import { getCallAPIProvider } from "services/CallAPIProvider";
import * as actionTypes from "../actions/types/config";

const callProvider = getCallAPIProvider();

function* setExtData(action) {
  try {
    yield callProvider.setAdvancedConfiguration(action.payload);
    yield put({
      type: actionTypes.SET_EXT_DATA_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.SET_EXT_DATA_FAILED,
      message: e.message || e,
    });
  }
}

function* setStatisticsOverlay(action) {
  try {
    yield callProvider.setAdvancedConfiguration({
      showStatisticsOverlay: action.show,
    });
    yield put({
      type: actionTypes.SET_STATISTICS_OVERLAY_SUCCEEDED,
      show: action.show,
    });
  } catch (e) {
    yield put({
      type: actionTypes.SET_STATISTICS_OVERLAY_FAILED,
      message: e.message || e,
    });
  }
}

function* actionWatcher() {
  yield takeLatest(actionTypes.SET_EXT_DATA, setExtData);
  yield takeLatest(actionTypes.SET_STATISTICS_OVERLAY, setStatisticsOverlay);
}

export default actionWatcher;
