import {
  getLogger_,
  getLoggers,
  ILogger,
  LogLevel,
  setDefaultLevel,
  storeOriginalConsole,
  setLoggerHook,
  ILoggerOptions,
  defaultLoggerOptions,
} from "./logger";
import { logCollector, logCollectorInitialize } from "logger/logCollector";
import { localStorageKeys } from "../utils/constants";

let loggingConfigurationAsJsonString: string | null;
let loggingConfiguration: ILoggingConfiguration | null;
let appLogger: ILogger;
let initDone = false;

const levelMapper: { [key: string]: LogLevel } = {
  none: LogLevel.None,
  error: LogLevel.Error,
  warn: LogLevel.Warn,
  info: LogLevel.Info,
  debug: LogLevel.Debug,
};

export interface ILoggingConfiguration {
  version: string;
  legacyHook: boolean;
  useLogStorage: boolean;
  globalLevel: string;
  debugLoggers: string[];
  silentLoggers: string[];
  meta: any;
}

export const defaultLoggingConfiguration: ILoggingConfiguration = {
  version: getAppVersion(),
  legacyHook: true,
  useLogStorage: true,
  globalLevel: "info",
  debugLoggers: [],
  silentLoggers: [],
  meta: {},
};

function getAppVersion() {
  return window.appConfig?.APP_VERSION || "1.2.3";
}

export function mapLevel(logLevel: LogLevel): string | null {
  const key = Object.keys(levelMapper).find((key) => levelMapper[key] === logLevel);
  return key ? key.toLowerCase() : null;
}

export function onUpdatedConfiguration() {
  try {
    const diff = localStorage.getItem(localStorageKeys.LOGGING_CONFIGURATION) !== loggingConfigurationAsJsonString;
    if (diff) {
      readLoggingConfiguration();
      configureLoggers();
    }
  } catch (error) {
    console.error(`Failed to update logging : ${error}`);
  }
}

window.addEventListener("storage", () => onUpdatedConfiguration());

function readLoggingConfiguration() {
  loggingConfigurationAsJsonString = localStorage.getItem(localStorageKeys.LOGGING_CONFIGURATION);
  loggingConfiguration = loggingConfigurationAsJsonString ? JSON.parse(loggingConfigurationAsJsonString) : null;
  if (loggingConfiguration == null || loggingConfiguration.version !== defaultLoggingConfiguration.version) {
    localStorage.setItem(localStorageKeys.LOGGING_CONFIGURATION, JSON.stringify(defaultLoggingConfiguration));
    const createdOrUpdated = loggingConfiguration == null ? "Created initial" : "Overwritten with default";
    appLogger.warn(
      `${createdOrUpdated} logging configuration (${loggingConfiguration?.version} -> ${defaultLoggingConfiguration.version}).`
    );
    loggingConfigurationAsJsonString = localStorage.getItem(localStorageKeys.LOGGING_CONFIGURATION);
    loggingConfiguration = loggingConfigurationAsJsonString ? JSON.parse(loggingConfigurationAsJsonString) : null;
  }
  appLogger.info(`Logging configuration: ${JSON.stringify(loggingConfiguration)}`);
  if (!loggingConfiguration) {
    throw new Error(`Failed to update/initialize logging configuration (${loggingConfiguration})`);
  }
}

function configureLoggers() {
  if (loggingConfiguration) {
    const level: LogLevel = levelMapper[loggingConfiguration.globalLevel];
    setDefaultLevel(level);
    appLogger.info(`Setting global log level to ${loggingConfiguration.globalLevel}`);
    getLoggers().forEach((logger) => setLoggerLevel(logger));
  } else {
    throw Error("Missing logging configuration");
  }
}

function matchPrefix(loggerName: string, prefix: string) {
  const prefixDot = prefix + ".";
  return prefix === loggerName || loggerName.startsWith(prefixDot);
}

function setLoggerLevel(logger: ILogger) {
  if (loggingConfiguration) {
    let match = false;
    let level: LogLevel = levelMapper[loggingConfiguration.globalLevel];

    // debug loggers
    loggingConfiguration.debugLoggers?.forEach((loggerName) => {
      if (matchPrefix(logger.getName(), loggerName)) {
        level = LogLevel.Debug;
        match = true;
      }
    });

    // silence loggers
    loggingConfiguration.silentLoggers?.forEach((loggerName) => {
      if (matchPrefix(logger.getName(), loggerName)) {
        level = LogLevel.None;
        match = true;
      }
    });
    if (match) {
      appLogger.info(`Setting ${logger.getName()} loglevel to ${mapLevel(level)}`);
    }
    logger.setLevel(level);
  }
}

export function initializeLoggers() {
  try {
    if (!initDone) {
      initDone = true;

      // default logger
      appLogger = getLogger_();

      // store original console.log & co
      storeOriginalConsole();

      // read+parse
      readLoggingConfiguration();

      // configure
      configureLoggers();

      if (loggingConfiguration?.legacyHook) {
        // legacy logger
        const legacyLogger = getLogger_("legacy");
        // hook into console.log() and forward to legacy logger
        hookIntoConsoleLogs(legacyLogger);
      }

      if (loggingConfiguration?.useLogStorage) {
        // hook logger connector into output collecting in memory only until indexedDb ready
        setLoggerHook(logCollector);

        // initialize indexedDB logger collector
        logCollectorInitialize()
          .then((initialized) => {
            if (!initialized) {
              setLoggerHook(undefined);
              appLogger.error("Logstorage/IndexedDB not available");
            }
          })
          .catch(() => {
            setLoggerHook(undefined);
            appLogger.error("Logstorage/IndexedDB not available");
          });
      }
    }
  } catch (error) {
    console.error(`Failed to initialize logging: ${error}`);
  }
}

export function getLogger(name?: string | undefined, loggerOptions: ILoggerOptions | undefined = undefined): ILogger {
  // make sure we have been initialized
  initializeLoggers();

  const logger = getLogger_(name, loggerOptions ?? defaultLoggerOptions);
  if (!logger.configured) {
    setLoggerLevel(logger);
    logger.configured = true; // do not reconfig if same logger referenced from multiple files at startup
  }
  return logger;
}

function hookIntoConsoleLogs(logger: ILogger) {
  //TEMPORARY FIX FOR ELECTRON/BROWSER ISSUE
  // let electronLogger = null;
  // if (isElectron) {
  //   const { remote } = window.require("electron");
  //   electronLogger = remote?.getGlobal("logger");
  //   if (electronLogger) {
  //     defaultLog = electronLogger.log;
  //     defaultError = electronLogger.error;
  //     defaultWarn = electronLogger.warn;
  //     defaultDebug = electronLogger.debug;
  //   }
  // }

  window.console.log = function () {
    logger.log(...arguments);
  };
  window.console.error = function () {
    logger.error(...arguments);
  };
  window.console.warn = function () {
    logger.warn(...arguments);
  };
  window.console.debug = function () {
    logger.debug(...arguments);
  };
}
