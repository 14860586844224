import * as actionTypes from "../actions/types/user";
import { UserState } from "../types/user";
import { Types } from "Types";

const initialState: UserState = {
  editMessageData: null,
  replyMessageData: null,
  forwardMessageData: null,
};

const userReducer = (state = initialState, action: Types.RootAction): UserState => {
  switch (action.type) {
    case actionTypes.EDIT_MESSAGE:
      return {
        ...state,
        editMessageData: action.data,
      };
    case actionTypes.REPLY_MESSAGE:
      return {
        ...state,
        replyMessageData: action.data,
      };
    case actionTypes.FORWARD_MESSAGE:
      return {
        ...state,
        forwardMessageData: action.data,
      };
    default:
      return state;
  }
};

export default userReducer;
