import { useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { getCallAPIProvider } from "services/CallAPIProvider/CallAPIProvider";
import { CustomStyleToExtStream } from "services/CustomRenderer";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import styled from "themes/theme/baseTheme";
import { CallMediaType } from "types/UC";
import { ParticipantBottomInfo } from "./ParticipantBottomInfo";

interface LocalCameraProps {
  tile: any;
  selfViewState: string;
  you: string;
  in169: boolean;
}

export const LocalCamera = ({ tile, in169, selfViewState, you }: LocalCameraProps) => {
  const theme = useTheme();
  const callProvider = getCallAPIProvider();
  const self = useRef<HTMLDivElement>(null);
  const { AssignViewToExtStream } = CustomStyleToExtStream();

  const StyledTypography = styled(Typography)(({ theme }) => ({
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: '"Noto Sans", "Helvetica", "Tahoma", sans-serif',
    color: theme.palette.text.callTiles,
  }));

  useEffect(() => {
    if (tile.isMatrixTile) {
      AssignViewToExtStream(tile.id, tile.isSelfView, tile.type);
      return;
    }
    if (tile.stream) {
      callProvider
        .assignViewToStream({
          id: tile.id,
          mediaObject: tile.stream,
          mediaType: tile.type,
        })
        .then(() => {
          if (tile && tile.type === CallMediaType.localCamera) {
            let d = document.createElement("div");
            const bottomInfo = (
              <div className="bottom-info">
                <StyledTypography
                  className="participant-name"
                  sx={{
                    backgroundColor: theme.palette.background.callTiles.participantName,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {tile.name || ""}
                </StyledTypography>
                <StyledTypography
                  className="you"
                  sx={{
                    backgroundColor: theme.palette.background.callTiles.youLabel,
                  }}
                >
                  {you}
                </StyledTypography>
              </div>
            );
            d.innerHTML = ReactDOMServer.renderToString(bottomInfo);
            if (self.current) self.current.appendChild(d);
          }
        });
    }
    return () => {
      callProvider.hideView(tile.id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tile.stream, tile.isMatrixTile]);

  return (
    <div
      id={tile.id}
      ref={self}
      className={`participant-tile ${selfViewState} ${in169 ? "participant-tile-aspect-ratio-16-9" : ""}`}
    >
      {tile.isMatrixTile && <ParticipantBottomInfo name={tile.name || ""} you={you} />}
    </div>
  );
};
