import { useTranslation } from "react-i18next";
import { Avatar, IconButton } from "components";
import { useTheme } from "@mui/material/styles";
import styled from "themes/theme/baseTheme";
import "./DialoutCallsOnHold.scss";
import { useSip } from "hooks";
import { CallMediaType } from "types/UC";
import { useAppSelector } from "store/hooks";
import { getSipCallsHeld } from "store/selectors";
import PhonePausedIcon from "@mui/icons-material/PhonePaused";
import Typography from "@mui/material/Typography";
import { test } from "utils/helpers";

const StyledDiv = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.detached,
  border: `1px solid ${theme.palette.brand.secondary.light}`,
}));

interface CallsOnHoldProps {
  dialpadActive: boolean;
}

const DialoutCallsOnHold = ({ dialpadActive }: CallsOnHoldProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { holdInProgress, retrieveCall } = useSip();
  const sipCallsHeld = useAppSelector((state) => getSipCallsHeld(state, dialpadActive));

  if (sipCallsHeld.length > 0) {
    return (
      <div className="call-on-hold-container" {...test("CALLS_ON_HOLD")}>
        {sipCallsHeld.map((item, index, array) => (
          <StyledDiv className="call-on-hold-entry column-container" key={index} style={{}}>
            <Avatar
              name={item.peer.name || ""}
              src={item.peer.avatarUrl}
              size="80"
              disableBadge
              status="call"
              sx={{ margin: "20px 10px 16px 20px" }}
            />
            <div className="column-text">
              <Typography variant="h6">{item.peer.name}</Typography>
              <Typography variant="body1" style={{ color: theme.palette.text.secondary }}>
                {t("CALL_ON_HOLD")}
              </Typography>
            </div>
            <div className="column-icon">
              <IconButton
                disabled={holdInProgress}
                onClick={() => retrieveCall(item.id, CallMediaType.audio)}
                sx={{
                  backgroundColor: theme.palette.background.detached,
                  color: theme.palette.sentiment.error.base,
                  minWidth: "36px !important",
                }}
              >
                <PhonePausedIcon sx={{ fontSize: "2.2rem" }} />
              </IconButton>
            </div>
          </StyledDiv>
        ))}
      </div>
    );
  }
  return null;
};

export default DialoutCallsOnHold;
