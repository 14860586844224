import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getLogger } from "logger/appLogger";
import { useTranslation } from "react-i18next";

import { useTheme } from "@mui/material/styles";
import styled from "themes/theme/baseTheme";
import styles from "./SideBar.module.scss";

import { NavLink, useHistory } from "react-router-dom";

import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsPhoneIcon from "@mui/icons-material/SettingsPhone";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import NoConversationIcon from "icons/NoConversationIcons";
import NoEventsIcon from "icons/NoEventsIcon";

import Button from "@mui/material/Button";
import { Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SideBarRoomList from "./SideBarRoom/SideBarRoomList";
import DialpadSidebar from "containers/Dialpad/DialpadSidebar";
import GlobalFilter from "containers/GlobalFilter/GlobalFilter";

import { useMatrixRooms, useRouter } from "hooks";
import { getCalendarEvents, getNextEvent } from "services/CalendarProvider/CalendarProvider";

import { matrixActions } from "store/actions/matrix";
import { useAppSelector } from "store/hooks";

import { MatrixRoomTypes, RoomListFilters } from "types/Matrix";
import { getDayOfTheWeek, sortByEvents, sortByLetters, test } from "utils/helpers";

const StyledSettingsButton = styled(Button)(({ theme }) => ({
  justifyContent: "start !important",
  height: "100%",
  minHeight: "56px",
  border: "1px solid transparent",
  padding: "4px 20px",
  backgroundColor: "transparent",
  "&:hover": {
    color: theme.palette.icon.navLink.selected,
    backgroundColor: theme.palette.background.navLink.hover,
  },
}));

const SideBarContent = ({
  roomList,
  isAddingContacts = false,
  userList,
  isMeeting = false,
  isDialpad = false,
  isSettings = false,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const logger = useMemo(() => getLogger("sidebar"), []);
  const module = "SideBarContent";

  const [, setContactUsers] = useState({});
  const { isGroup, generateRoomName } = useMatrixRooms();
  const [hasNoRooms, setHasNoRooms] = useState(false);
  const { location } = useRouter();
  const history = useHistory();
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [calendarItems, setCalendarItems] = useState([]);
  const [selectedCalendarEvent, setSelectedCalendarEvent] = useState(0);
  const { t, i18n } = useTranslation();
  const [needRefreshCounter, setNeedRefreshCounter] = useState(0);
  const [language, setlanguage] = useState(i18n.language);

  const activeCategory = useAppSelector((state) => state.matrix.roomListFilter);

  const roomCategories = Object.keys(RoomListFilters).map(function (filter) {
    return RoomListFilters[filter];
  });

  const settingsSx = {
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    color: theme.palette.icon.primary,
  };

  const defaultSettingRoutes = [
    {
      route: "/settings/general",
      label: "SETTINGS_SB_GENERAL",
      icon: <SettingsIcon sx={settingsSx} />,
      desc: "SETTINGS_SB_GENERAL_DESCRIPTION",
    },
    {
      route: "/settings/devices",
      label: "SETTINGS_SB_AUDIO_VIDEO",
      icon: <VideoCameraBackIcon sx={settingsSx} />,
      desc: "SETTINGS_SB_AV_DESCRIPTION",
    },
    {
      route: "/settings/notifications",
      label: "SETTINGS_SB_NOTIFICATIONS",
      icon: <NotificationsNoneIcon sx={settingsSx} />,
      desc: "SETTINGS_SB_NOTIFICATIONS_DESCRIPTION",
    },
    {
      route: "/settings/phone",
      label: "SETTINGS_SB_PHONE",
      icon: <SettingsPhoneIcon sx={settingsSx} />,
      desc: "SETTINGS_SB_PHONE_DESCRIPTION",
    },
    {
      route: "/settings/integrations",
      label: "SETTINGS_SB_INTEGRATIONS",
      icon: <IntegrationInstructionsIcon sx={settingsSx} />,
      desc: "SETTINGS_SB_INTEGRATIONS_DESCRIPTION",
    },
    {
      route: "/settings/help",
      label: "SETTINGS_SB_HELP",
      icon: <HelpIcon sx={settingsSx} />,
      desc: "SETTINGS_SB_HELP_DESCRIPTION",
    },
    {
      route: "/settings/about",
      label: "SETTINGS_SB_ABOUT",
      icon: <InfoIcon sx={settingsSx} />,
      desc: "SETTINGS_SB_ABOUT_DESCRIPTION",
    },
  ];

  const [settingRoutes, setSettingRoutes] = useState(defaultSettingRoutes);

  const pbxConfig = useSelector((state) => state.pbx.pbxConfig);
  const calendarUpdate = useSelector((state) => state.app.calendarUpdate);

  document.addEventListener(
    "update",
    function (e) {
      if (e.detail.shouldUpdate) {
        setNeedRefreshCounter(needRefreshCounter + 1);
      }
    },
    false
  );

  const validateRoomNames = (list) => {
    list.forEach((room) => {
      room.name = generateRoomName(room);
    });
    return list;
  };

  const sortByType = (data, type) => {
    const groups = [];
    const rooms = [];

    [...data].forEach((item) => {
      if (item.roomId) {
        if (isGroup(item.roomId)) {
          groups.push(item);
        } else {
          rooms.push(item);
        }
      }
    });

    return type === "group" ? validateRoomNames(groups) : rooms;
  };

  const updateContactUserList = () => {
    try {
      const newList = {};
      roomList.contacts.forEach((room) => {
        if (room.type !== MatrixRoomTypes.direct) return;
        room.name = generateRoomName(room);
        newList[room.guessDMUserId()] = room;
      });
      roomList.favorites.forEach((room) => {
        if (room.type !== MatrixRoomTypes.direct) return;
        newList[room.guessDMUserId()] = room;
      });
      setContactUsers(newList);
    } catch (e) {
      logger.error(`${module}: Erro in updateContactUserList - ${e.message}`);
    }
  };

  const openMeetingDetails = (data) => {
    if (data && data.id) {
      history.replace({
        pathname: "/meeting",
        search: "?query=" + data.id,
        state: data,
      });
    } else {
      logger.error(`${module}: Error in openMeetingDetails - data:${data}`);
    }
  };

  const onCalendarItemClick = (data, index) => {
    if (data && data.id) {
      setSelectedCalendarEvent(data.id);
      openMeetingDetails(data);
    } else {
      logger.error(`${module}: Error in onCalendarItemClick - data:${data}`);
    }
  };

  const getEventById = (id) => {
    return calendarItems.find((item) => {
      return item.id === id;
    });
  };

  const updateCalendarItems = () => {
    if (location.pathname === "/meeting") {
      getCalendarEvents().then((body) => {
        setLoadingEvents(false);
        setCalendarItems(body);
      });
    }
  };

  const navLinkStyle = (isActive) => {
    const style = {
      backgroundColor: isActive ? theme.palette.background.navLink.selected : "",
      border: isActive ? `1px solid ${theme.palette.border.navLink}` : `1px solid transparent`,
      borderRadius: "4px",
      textDecoration: "none",
    };
    return style;
  };

  const handleOnSelectFilter = (filter) => {
    dispatch(matrixActions.setRoomListFilter(filter));
  };

  useEffect(() => {
    if (!pbxConfig) return;
    try {
      const { hostedPbxEnabled } = pbxConfig;

      // Remove the Phone Features Settings Item if HostedPBX Feature is not enabled.
      if (!hostedPbxEnabled) {
        const newRoutes = settingRoutes.filter((item) => {
          return item.route !== "/settings/phone";
        });
        setSettingRoutes(newRoutes);
      } else {
        const exists = settingRoutes.find((item) => item.route === "/settings/phone");
        if (!exists) {
          setSettingRoutes(defaultSettingRoutes);
        }
      }
    } catch (error) {
      logger.error(`${module}: Error in pbxConfig.useEffect - ${error}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pbxConfig]);

  useEffect(() => {
    if (!location.search) {
      updateCalendarItems();
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    updateCalendarItems();
    // eslint-disable-next-line
  }, [needRefreshCounter, calendarUpdate]);

  useEffect(() => {
    if (calendarItems.length) {
      const event = getEventById(location?.state?.id) || getNextEvent(calendarItems);
      if (event && event.id) {
        setSelectedCalendarEvent(event.id);
      }
      openMeetingDetails(event);
    }
    // eslint-disable-next-line
  }, [calendarItems]);

  useEffect(() => {
    if (!isAddingContacts) return;
    userList.sort((a, b) => sortByLetters(a.name ? a.name : a.displayName, b.name ? b.name : b.displayName));
  }, [userList, isAddingContacts]);

  useEffect(() => {
    if (!roomList.contacts || (roomList.contacts && roomList.contacts.length <= 0)) return;
    updateContactUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomList.contacts, roomList.contacts.length, roomList.favorites, roomList.favorites.length]);

  useEffect(() => {
    if (!roomList) return;
    if (
      roomList.favorites.length <= 0 &&
      roomList.contacts.length <= 0 &&
      roomList.private.length <= 0 &&
      roomList.public.length <= 0 &&
      roomList.groups?.length <= 0 &&
      !isAddingContacts
    ) {
      setHasNoRooms(true);
    } else {
      setHasNoRooms(false);
    }
  }, [
    isAddingContacts,
    roomList,
    roomList.contacts.length,
    roomList.favorites.length,
    roomList.private.length,
    roomList.groups?.length,
  ]);

  useEffect(() => {
    setlanguage(i18n.language);
  }, [i18n.language]);

  if (hasNoRooms && !isSettings && location.pathname !== "/dialpad") {
    return (
      <div className={`${styles.sideBarContent} ${styles.noRoomContainer} scroll-bar`} {...test("SIDEBAR_NO_ROOMS")}>
        <div className={`${styles.noRoomContainer}`}>
          <NoConversationIcon alt="no_conversation_img" />
          <p className={`${styles.noConversationText}`}>{t("NOCONVERSATION")}</p>
          <p className={`${styles.newConversationText}`}>{t("NOCONVERSATIONSYET")}</p>
        </div>
      </div>
    );
  }

  if (isMeeting) {
    return (
      <div className={`${styles.sideBarContent} scroll-bar`}>
        {loadingEvents ? (
          <div className={`${styles.loader}`}>
            <CircularProgress color="inherit" size={25} />
          </div>
        ) : (
          <>
            {!calendarItems.length && (
              <div
                className={`${styles.sideBarContent} ${styles.noRoomContainer} scroll-bar`}
                {...test("SIDEBAR_NO_EVENTS")}
              >
                <div className={`${styles.noRoomContainer}`}>
                  <NoEventsIcon />
                  <Typography variant="h6" sx={{ paddingTop: "20px" }}>
                    {t("NO_EVENTS")}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      maxWidth: "280px",
                      textAlign: "center",
                      paddingTop: "7px",
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {t("NO_EVENTS_YET")}
                  </Typography>
                </div>
              </div>
            )}

            {calendarItems.length > 0 && (
              <div className={`${styles.scheduleSection}`} {...test("SIDEBAR_MEETINGS")}>
                {calendarItems.map((item, index, array) => {
                  return (
                    <div
                      key={index}
                      className={styles.meetingListItem}
                      style={
                        item.id === selectedCalendarEvent
                          ? {
                              backgroundColor: theme.palette.background.navLink.selected,
                              border: `1px solid ${theme.palette.border.navLink}`,
                            }
                          : {}
                      }
                      onClick={() => {
                        onCalendarItemClick(item, index);
                      }}
                    >
                      <div className={`${styles.dateSection}`}>
                        {!(array[index - 1] && array[index - 1].date.dayOfMonth === item.date.dayOfMonth) && (
                          <div
                            className={styles.date}
                            style={{
                              backgroundColor: theme.palette.background.snackbarBackground,
                              color: theme.palette.common.white,
                            }}
                          >
                            <Typography variant="title">{item.date.dayOfMonth}</Typography>
                            <span className={`${styles.day}`}>
                              {getDayOfTheWeek(item.date.timestamp, language).substring(0, 3).toUpperCase()}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className={`${styles.meetingDetails}`}>
                        <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
                          {item.subject || item.summary}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: "2px" }}>
                          {item.isAllDay ? t("ALL_DAY") + " " + item.date.fullDate : item.date.meetingHours}
                        </Typography>
                        <div className={`${styles.border}`}></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  if (isDialpad) {
    return <DialpadSidebar />;
  }

  if (isSettings) {
    return (
      <div className={`${styles.sideBarContent} scroll-bar`} {...test("SIDEBAR_SETTINGS")}>
        {settingRoutes.map((b) => (
          <NavLink
            key={b.route}
            to={b.route}
            style={(isActive) => navLinkStyle(isActive)}
            className={styles.settingsLink}
          >
            <StyledSettingsButton
              id="sidebar_content_room_button"
              aria-label={t(b.label)}
              className={`${styles.settingsButton}`}
              disableRipple
            >
              <Stack id="settings_options" direction="row" alignItems="center" spacing={"14px"}>
                <div
                  style={{
                    height: 46,
                    width: 46,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: theme.palette.background.icons,
                  }}
                >
                  {b.icon}
                </div>
                <Stack
                  id={`settings_${b.label}`}
                  direction="column"
                  spacing={"2px"}
                  sx={{ textTransform: "none", maxWidth: "270px" }}
                >
                  <Typography variant="body1" align="left" sx={{ color: theme.palette.text.navLink.primary }}>
                    {t(b.label)}
                  </Typography>
                  <Typography variant="body2" align="left" sx={{ color: theme.palette.text.navLink.secondary }}>
                    {t(b.desc)}
                  </Typography>
                </Stack>
              </Stack>
            </StyledSettingsButton>
          </NavLink>
        ))}
      </div>
    );
  }

  return (
    <>
      <GlobalFilter activeFilter={activeCategory} filters={roomCategories} onSelectFilter={handleOnSelectFilter} />

      <div className={`${styles.sideBarContent} scroll-bar`}>
        <div className={activeCategory === "ALL" || activeCategory === "FAVORITES" ? "" : styles.hide}>
          <SideBarRoomList
            list={validateRoomNames(roomList[MatrixRoomTypes.favorites])
              .filter((room) => !room.isHidden)
              .sort((a, b) => sortByLetters(a.name ? a.name : a.displayName, b.name ? b.name : b.displayName))}
            category={t("FAVORITES")}
          />
        </div>
        <div className={activeCategory === "ALL" || activeCategory === "ROOMS" ? "" : styles.hide}>
          <SideBarRoomList
            list={[...sortByType(roomList[MatrixRoomTypes.private], "rooms"), ...roomList[MatrixRoomTypes.public]]
              .filter((room) => !room.isHidden)
              .sort((a, b) => sortByEvents(a, b))}
            category={t("ROOMS_2")}
          />
        </div>
        <div className={activeCategory === "ALL" || activeCategory === "CONVERSATIONS" ? "" : styles.hide}>
          <SideBarRoomList
            list={[
              ...validateRoomNames(roomList[MatrixRoomTypes.contacts]),
              ...sortByType(roomList[MatrixRoomTypes.private], "group"),
            ]
              .filter((room) => !room.isHidden)
              .sort((a, b) => sortByEvents(a, b))}
            category={t("CONVERSATIONS")}
          />
        </div>
      </div>
    </>
  );
};

SideBarContent.propTypes = {
  roomList: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  isAddingContacts: PropTypes.bool,
  userList: PropTypes.array,
};

export default SideBarContent;
