import { IUserSettings, UserSettings, USER_SETTINGS_VERSION } from "data/settings/UserSettings";
import { localStorageKeys } from "utils/constants";
import { LocalStorageVariable } from "utils/localStorageVariable";
import isElectron from "is-electron";

interface IUserSettingsType {
  getUserSettings: () => IUserSettings;
  setUserSettings: (userSettings: IUserSettings) => void;
}

export const useUserSettings = (): IUserSettingsType => {
  const userSettingsStore: LocalStorageVariable<UserSettings> = new LocalStorageVariable(
    localStorageKeys.USER_SETTINGS
  );
  const getUserSettings = (): IUserSettings => {
    let userSettings: IUserSettings = userSettingsStore.get();
    if (!userSettings || userSettings.version !== USER_SETTINGS_VERSION) {
      userSettings = new UserSettings();
      userSettingsStore.set(userSettings);
    }
    return userSettings;
  };
  const setUserSettings = (userSettings: IUserSettings) => {
    if (userSettings) {
      userSettingsStore.set(userSettings);
      if (isElectron()) {
        window.ipcRenderer.send("updateUserConfig", userSettings);
      }
    }
  };
  return {
    getUserSettings,
    setUserSettings,
  };
};
