import React, { HTMLAttributes, useMemo, useEffect, useState, useRef } from "react";
import { useAppSelector } from "store/hooks";
import { getLogger } from "logger/appLogger";
import { useTheme } from "@mui/material/styles";
import {
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { FilterOptionsState } from "@mui/material";
import SearchField from "components/_shared/SearchField";
import SearchUsersAutocomplete, { ParamsWithInputAdornment } from "components/_shared/SearchUsersAutocomplete";
import AvatarWithLabels from "components/_shared/AvatarWithLabels";

import { SearchData, LocalMatrixRoom } from "types/Matrix";
import { useMatrixRooms, useRouter, useSearchProvisioning, useMatrixAvatar } from "hooks";
import { isEmpty, sortByLetters, tryParse } from "utils/helpers";

interface GlobalSearchProps {
  autoFocus?: boolean;
}

const GlobalSearch: React.FC<GlobalSearchProps> = ({ autoFocus = false }) => {
  const theme = useTheme();
  const logger = useMemo(() => getLogger("global-search"), []);
  const mountedRef = useRef(true);
  const { push } = useRouter();

  const currentUser = useAppSelector((state) => state.matrix.currentUser);
  const matrixRoomList = useAppSelector((state) => state.matrix.roomList);

  const {
    getPublicRoomList,
    joinRoom,
    getRoom,
    setRoomTopic,
    getRoomTopic,
    deleteRoomLowPrio,
    createDirectRoom,
    isGroup,
    generateRoomName,
    checkForExistingRooms,
    getNoDirectAndPublicMatrixRooms,
    convertMatrixRoomToMappedRoom,
  } = useMatrixRooms();
  const { searchResult, search, loading } = useSearchProvisioning();
  const { getRoomAvatar, getMatrixImage } = useMatrixAvatar();
  const [publicRooms, setPublicRooms] = useState<SearchData[]>([]);
  const [roomList, setRoomList] = useState<SearchData[]>([]);
  const [list, setList] = useState<SearchData[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);

  const searchFieldWidth = 250;

  const handleOnSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handleListClick = async (value: SearchData | null) => {
    try {
      setSearchValue("");
      if (value) {
        const { hasExistingRoom, roomId, isRoom } = value;
        if (hasExistingRoom) {
          const room = getRoom(roomId);
          const { tags }: { tags: any } = room!;
          if (tags && tags["m.lowpriority"]) {
            await deleteRoomLowPrio(roomId);
          }
          push(`/room/${roomId}`);
        } else if (isRoom) {
          joinNewRoom(value);
        } else {
          createUserRoom(value);
        }
      }
    } catch (e) {
      logger.error(`Error in handleListClick - value:${value} - ${e.message}`);
    }
  };

  /** Join a room */
  const joinNewRoom = async (value: SearchData) => {
    try {
      const { roomId } = value;
      const room: SearchData | undefined = list.find((r) => r.roomId === roomId);
      if (room) {
        await joinRoom(roomId);
        let count = 0;
        const rintvl = setInterval(() => {
          const matrixRoom = getRoom(roomId);
          if (matrixRoom) {
            clearInterval(rintvl);
            updateRoomList(roomList);
            push(`/room/${matrixRoom.roomId}`);
            return;
          }
          if (count >= 150) {
            clearInterval(rintvl);
          }
          count = count + 1;
        }, 200);
      }
    } catch (e) {
      logger.error(`Error in JoinNewRoom - value:${value} - ${e.message}`);
    }
  };

  /** Create a new contact room */
  const createUserRoom = async (value: SearchData) => {
    try {
      const { displayName, userId, roomId } = value;
      const room = getRoom(roomId);
      if (room) {
        const { tags }: { tags: any } = room;
        if (tags && tags["m.lowpriority"]) {
          await deleteRoomLowPrio(roomId);
          let roomTopic: any = tryParse(getRoomTopic({ room }));
          if (!roomTopic) {
            roomTopic = {
              [userId]: {
                name: displayName,
              },
              [currentUser!.userId]: {
                name: currentUser!.displayName,
              },
            };
            await setRoomTopic(roomId, JSON.stringify(roomTopic));
          }
        }
        push(`/room/${roomId}`);
      } else {
        const newRoomId = await createDirectRoom(userId, displayName);
        push(`/room/${newRoomId}`);
      }
    } catch (e) {
      logger.error(`Error in createUserRoom - value:${value} - ${e.message}`);
    }
  };

  const updateRoomList = (list: SearchData[]) => {
    try {
      for (let i in matrixRoomList) {
        if (matrixRoomList[i] && matrixRoomList[i].length > 0) {
          matrixRoomList[i].forEach((room: LocalMatrixRoom) => {
            checkForExistingRooms(list, room);
          });
        }
      }
    } catch (e) {
      logger.error(`Error in updateRoomList - list:${list}- ${e.message}`);
    }
  };

  /** Gets the public rooms */
  const getPublicRooms = (searchText?: string) => {
    const publicRoomList: SearchData[] = [];
    getPublicRoomList(searchText).then((data) => {
      if (mountedRef.current) {
        data.chunk.forEach((item: any) => {
          const room: any = {
            roomId: item.room_id,
            displayName: item.name,
            avatarUrl: getMatrixImage(item.avatar_url),
            isRoom: true,
            isPrivateRoom: false,
          };
          publicRoomList.push(room);
        });
      }
      if (mountedRef.current) {
        setPublicRooms(publicRoomList);
      }
    });
  };

  useEffect(() => {
    if (!matrixRoomList) return;
    if (
      matrixRoomList.favorites.length <= 0 &&
      matrixRoomList.contacts.length <= 0 &&
      matrixRoomList.private.length <= 0 &&
      matrixRoomList.public.length <= 0
    ) {
      if (publicRooms.length <= 0) return;
      else {
        setRoomList(publicRooms);
      }
    } else {
      const matrixRooms: SearchData[] = getNoDirectAndPublicMatrixRooms(matrixRoomList);
      setRoomList([...publicRooms, ...matrixRooms]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    matrixRoomList.contacts.length,
    matrixRoomList.favorites.length,
    matrixRoomList.public.length,
    matrixRoomList.private.length,
    publicRooms,
  ]);

  useEffect(() => {
    if (isEmpty(searchValue)) {
      setList([]);
    } else {
      try {
        getPublicRooms(searchValue);
      } catch (e) {
        logger.error(`Error in useEffect getting public rooms - searchValue:${searchValue} - ${e.message}`);
      }
      /** Search for users on keycloak */
      search(searchValue);

      const newList: SearchData[] = [...roomList];
      setList(newList.sort((a, b) => sortByLetters(a.displayName, b.displayName)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (!searchValue || searchValue.length === 0) {
      setList([]);
      return;
    }
    updateRoomList(searchResult);
    const newList: SearchData[] = [...searchResult, ...roomList];
    setList(newList.sort((a, b) => sortByLetters(a.displayName, b.displayName)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult]);

  useEffect(() => {
    try {
      getPublicRooms();
    } catch (e) {
      logger.error(`Error in useEffect getting public rooms - ${e.message}`);
    }
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchUsersAutocomplete
      id="global-search"
      sx={{ width: `${searchFieldWidth}px` }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => (option as SearchData).displayName}
      options={list}
      loading={loading}
      filterOptions={(options: SearchData[], state: FilterOptionsState<SearchData>) => {
        // returns all the users from the keycloak search and the only rooms (private, public and group chats) that match the searchValue
        const rooms = options.filter((room: SearchData) => {
          return !room.isRoom || room.displayName.toLowerCase().includes(searchValue.toLowerCase());
        });

        return rooms;
      }}
      onInputChange={(event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === "input") {
          handleOnSearchChange(value);
        }
      }}
      onChange={(event, value, reason) => {
        if (reason === "selectOption") handleListClick(value as SearchData);
      }}
      renderTags={() => null}
      renderInput={(params: AutocompleteRenderInputParams) => {
        const value =
          !searchValue || searchValue.length === 0
            ? searchValue
            : // @ts-ignore
              params.inputProps.value;
        return (
          <SearchField
            {...ParamsWithInputAdornment(params)}
            autoFocus={autoFocus}
            inputProps={{
              ...params.inputProps,
              value,
              sx: { padding: "0 6px !important" },
            }}
            onBlur={(event: Event) => {
              setSearchValue("");
            }}
            size="small"
            sx={{ height: "36px", width: "100%", marginBottom: "0px" }}
          />
        );
      }}
      renderOption={(
        props: HTMLAttributes<HTMLLIElement>,
        option: SearchData,
        state: AutocompleteRenderOptionState
      ) => {
        const { displayName, avatarUrl, isRoom, presenceStatus, roomId, isPrivateRoom } = option;
        const matrixRoom = getRoom(roomId);
        const room = matrixRoom ? convertMatrixRoomToMappedRoom(matrixRoom) : null;
        const avatar = isRoom && room ? getRoomAvatar({ roomId: room.roomId }) : avatarUrl;
        return (
          <Box component="li" {...props} key={roomId}>
            <AvatarWithLabels
              id={`global-search-avatar-${roomId}`}
              src={avatar}
              name={isGroup(roomId) ? (room ? generateRoomName(room) : displayName) : displayName}
              isPerson={!isRoom}
              isPrivateRoom={isPrivateRoom}
              isGroupRoom={isGroup(roomId)}
              disableBadge={(isRoom && !isPrivateRoom) || isGroup(roomId)}
              status={presenceStatus}
              statusMsg={undefined}
              sx={{ border: `1px solid ${avatar ? "transparent" : theme.palette.neutrals.lightGrey3}` }}
            />
          </Box>
        );
      }}
    />
  );
};

export default GlobalSearch;
