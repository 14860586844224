import { Components, Theme } from "@mui/material";

const MuiDialog: Components["MuiDialog"] = {
  styleOverrides: {
    root: ({ theme }) => {
      const palette = (theme as Theme).palette;
      return {
        "& .MuiBackdrop-root": {
          backgroundColor: palette.background.backdrop,
        },
      };
    },
    paper: ({ theme }) => {
      const palette = (theme as Theme).palette;
      return {
        backgroundColor: palette.background.paper,
        borderRadius: "4px",
        boxShadow: "0px 0px 32px 0px rgba(0, 0, 0, 0.16)",
        backgroundImage: "none",
      };
    },
  },
};

export default MuiDialog;
