import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getLogger } from "logger/appLogger";
import { useRouter } from "hooks";
import { matrixActions } from "store/actions/matrix";
import { AdHocActions } from "types/Matrix";

export const useHandleIncomingCalls = () => {
  const dispatch = useDispatch();
  const logger = useMemo(() => getLogger("matrix.handleevents"), []);
  const { push } = useRouter();

  const [showGroupInviteNotification, setShowGroupInviteNotification] = useState(false);

  const onAcceptGroupCall = (roomId: string, choice: string) => {
    try {
      logger.debug(`Join group chat`);
      const location = `/room/${roomId}${choice === AdHocActions.join ? "/joinCall" : ""}`;
      push(location);
      setShowGroupInviteNotification(false);
      dispatch(matrixActions.stopMatrixCallRingtone());
    } catch (e) {
      logger.error(`Error in onAcceptGroupCall - roomId:${roomId} - choice:${choice} - ${e.message}`);
    }
  };

  const onRejectGroupCall = () => {
    try {
      logger.debug("Not joining group chat");
      setShowGroupInviteNotification(false);
      dispatch(matrixActions.stopMatrixCallRingtone());
    } catch (e) {
      logger.error(`Error in onRejectGroupCall - ${e.message}`);
    }
  };
  return {
    showGroupInviteNotification,
    setShowGroupInviteNotification,
    onAcceptGroupCall,
    onRejectGroupCall,
  };
};
