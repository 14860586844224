import { CallActionType, CallingState, MutedState } from "./UC";

export const MAX_SIP_CALLS: number = 2;
export const MERGE_CALLS_TIMEOUT_MS = 5000;

export interface SipCallType {
  callType: CallActionType;
}

export enum CallHistoryType {
  All = "-1",
  Outbound = "0",
  Inbound = "1",
  Missed = "2",
  OnNet = "3",
}

// UI representation of peer
export interface UiSipPeer {
  name: string; // remote party: display name
  user: string; // remote party: extension | sip-user
  matrixUser?: string;
  avatarUrl?: string;
}

// UI representation of an incoming call
export interface UiSipCallInfo {
  id: string;
  peer: UiSipPeer;
  local: UiSipPeer;
  via?: UiSipPeer;
}

// UI represenation of a call
export interface UiSipCall extends UiSipCallInfo {
  active: boolean;
  held: boolean;
  lastHeld: boolean;
  outbound: boolean;
  callingState: CallingState;
  callType: CallActionType;
  mutedState: MutedState;
  conferencePeers: UiSipPeer[];
  groupCallBeforeConnect: boolean;
  error: boolean;
}

export interface UiSipConferenceInfo {
  conferencePeers: UiSipPeer[];
  conferenceId: string;
}
