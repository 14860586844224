import { Components, Theme } from "@mui/material";

const MuiTypography: Components["MuiTypography"] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const palette = (theme as Theme).palette;
      switch (ownerState.variant) {
        case "body1":
          return { color: palette.text.primary };
        case "body2":
          return { color: palette.text.secondary };
        case "link":
          return { color: palette.text.link };
        default:
          return;
      }
    },
  },
};

export default MuiTypography;
