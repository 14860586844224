import { Components } from "@mui/material";

const MuiMenuItem: Components["MuiMenuItem"] = {
  styleOverrides: {
    root: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "140%",
    },
  },
};

export default MuiMenuItem;
