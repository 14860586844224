import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import { IconButton } from "components";
import { useDispatch } from "react-redux";
import { cycleCamera } from "store/actions/devices";

const FlipCameraButton = (props) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(cycleCamera());
  };

  return (
    <IconButton
      id="flip_camera_button"
      onClick={onClick}
      sx={{
        width: "48px",
        height: "48px",
        borderRadius: "50%",
        outline: "none",
        border: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        "&:focus": {
          outline: "none",
        },
      }}
    >
      <FlipCameraIosIcon />
    </IconButton>
  );
};

export default FlipCameraButton;
