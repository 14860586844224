import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

export const useIsTouchScreen = () => {
  const [isTouchScreen, setIsTouchScreen] = useState(false);

  const handleMediaQueryChange = (matches: boolean) => {
    setIsTouchScreen(matches);
  };

  const isTouchScreenQuery = useMediaQuery(
    { query: "(hover: none) and (pointer: coarse)" },
    undefined,
    handleMediaQueryChange
  );

  useEffect(() => {
    setIsTouchScreen(isTouchScreenQuery);
  }, [isTouchScreenQuery, isTouchScreen]);

  return isTouchScreen;
};
export const useMobileDimension = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleMediaQueryChange = (matches: boolean) => {
    setIsMobile(matches);
  };

  const isMobileQuery = useMediaQuery(
    { query: "(max-width: 480px), (max-height: 480px)" },
    undefined,
    handleMediaQueryChange
  );

  const isPortrait = useMediaQuery({ orientation: "portrait" });

  useEffect(() => {
    setIsMobile(isMobileQuery);
  }, [isMobileQuery, isMobile]);

  return [isMobile, isPortrait];
};
