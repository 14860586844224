import React, { useRef, useEffect } from "react";
import { useAppSelector } from "store/hooks";

import styles from "./Messages.module.scss";
import styled from "themes/theme/baseTheme";

import CircularProgress from "@mui/material/CircularProgress";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";

import Message from "./Message";
import { useMatrixTimeline, useMatrixTyping } from "hooks";

import { test } from "utils/helpers";
import { useTranslation } from "react-i18next";
import { LocalEventTypeEnum, MatrixRoom } from "types/Matrix";
import { MatrixEvent } from "matrix-js-sdk";

const StyledDiv = styled("div")(({ theme }) => ({
  backgroundColor: `${theme.palette.background.secondary}`,
}));

const MessageList = ({ room }: { room: MatrixRoom }) => {
  const messagesRef: React.Ref<HTMLUListElement> | undefined = useRef(null);
  const listEndRef: React.Ref<HTMLLIElement> | undefined = useRef(null);
  const { events, roomInitialized, canLoadMore, isFetching, triggerScrollBottom, handleScroll } = useMatrixTimeline(
    room,
    messagesRef,
    [LocalEventTypeEnum.RoomMessage, LocalEventTypeEnum.RoomMessageFeedback]
  );
  const { typingStatus } = useMatrixTyping(room, "RoomMember.typing");
  const { t } = useTranslation();

  const isCallActive = useAppSelector((state) => state.call.active);
  const hasActiveMatrixCall = useAppSelector((state) => state.matrix.hasActiveMatrixCall);

  const scrollToBottom = () => {
    const lastElement = listEndRef.current;
    if (lastElement) {
      setTimeout(() => {
        return lastElement.scrollIntoView(false);
      }, 500);
    }
  };

  const findMessageReplies = (array: MatrixEvent[], id: string | undefined) => {
    return array.filter((mEvent) => {
      return mEvent.replyEventId === id;
    });
  };

  const findMessageEdits = (array: MatrixEvent[], id: string | undefined) => {
    return array.filter((item) => {
      return (
        item.event.content &&
        item.event.content["m.relates_to"] &&
        item.event.content["m.relates_to"]["rel_type"] === "m.replace" &&
        item.event.content["m.relates_to"].event_id === id
      );
    });
  };

  const filterEvents = (messages: MatrixEvent[]) => {
    const list = messages.filter((item) => {
      return !(
        item.event.content &&
        item.event.content["m.relates_to"] &&
        item.event.content["m.relates_to"]["rel_type"] === "m.replace"
      );
    });

    list.forEach((item) => {
      const edits = findMessageEdits(messages, item.event.event_id);
      if (edits.length) {
        const latest = edits.reduce((a, b) =>
          a.event.origin_server_ts && b.event.origin_server_ts && a.event.origin_server_ts > b.event.origin_server_ts
            ? a
            : b
        );

        item.event.content = {
          edited: true,
          body: latest.event.content && latest.event.content.body,
          formatted_body: latest.event.content && latest.event.content.formatted_body,
          msgtype: latest.event.content && latest.event.content.msgtype,
        };
      }

      // if the message was deleted, checks all the replies from this message and add the info
      // so we can replace the original message with a indication that was deleted
      if (item.isRedacted() || item.isRedaction()) {
        const replys = findMessageReplies(messages, item.event.event_id);
        replys.forEach((reply) => {
          reply.event.content = {
            original_msg_deleted: true,
            ...reply.event.content,
          };
        });
      }
    });

    return list;
  };

  useEffect(() => {
    scrollToBottom();
  }, [room, triggerScrollBottom, isCallActive, hasActiveMatrixCall]);

  return (
    <ul
      className={`${styles.main} scroll-bar chat-content`}
      onScroll={(event) => handleScroll(event.currentTarget)}
      ref={messagesRef}
      {...test("MESSAGE_LIST")}
    >
      {!canLoadMore ? (
        <li className={styles.loadMore}>
          <SpeakerNotesOffIcon /> &nbsp; {t("REACHED_THE_TOP")}
        </li>
      ) : null}
      {isFetching ? (
        <div className={`${styles.loadMessages}`}>
          <CircularProgress color="inherit" size={25} />
        </div>
      ) : null}
      {roomInitialized ? (
        filterEvents(events).map((event: MatrixEvent, index: number, array: MatrixEvent[]) => (
          <Message
            key={event.getId() ?? "" + index}
            mxEvent={event}
            prevEvent={array[index - 1]}
            nextEvent={array[index + 1]}
            room={room}
          />
        ))
      ) : (
        <CircularProgress color="inherit" size={25} />
      )}
      {typingStatus ? (
        <StyledDiv id="message_typing" className={`${styles.typing}`}>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <p className={styles.typingStatus}>{typingStatus}</p>
        </StyledDiv>
      ) : null}
      <li ref={listEndRef}></li>
    </ul>
  );
};

export default MessageList;
