import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { getProperCase, unsafeParseTextFromHTMLString, test } from "utils/helpers";
import { Custom } from "services/CustomRenderer";
import RecorderBar from "containers/RecorderBar/RecorderBar";
import { ModerationCall } from "containers/ModerationCall/ModerationCall";
import { ModerationUser } from "containers/ModerationUser/ModerationUser";
import IconButton from "components/_shared/IconButton";
import { useTheme } from "@mui/material/styles";
import styled from "themes/theme/baseTheme";
import {
  Divider,
  ListItemButton,
  Typography,
  List,
  ListItemText,
  Collapse,
  ClickAwayListener,
  SvgIcon,
  Stack,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import MicOffIcon from "@mui/icons-material/MicOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useMatrixRooms, useLocalStorage, useConferenceModeration } from "hooks";
import { useLanguageDirection } from "hooks/useLanguage";

import "./ParticipantsList.scss";
import { localStorageKeys } from "utils/constants";
import { UCUser } from "types/UC";
import { getLocalRoom } from "store/selectors";

const StyledTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

interface ParticipantsListProps {
  onClose: () => void;
}

const ParticipantsList = ({ onClose }: ParticipantsListProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const languageDirection = useLanguageDirection();

  const [list, setList] = useState<JSX.Element[]>([]);
  const [participantIdExpandUserModeration, setParticipantIdExpandUserModeration] = useState(0);
  const [localParticipantId, setLocalParticipantId] = useState(0);
  const [open, setOpen] = useState(false);
  const { getParticipantId } = useConferenceModeration();
  const [currentUser] = useLocalStorage<UCUser>(localStorageKeys.CURRENT_USER);
  const currentRoomId = useAppSelector((state) => state.matrix.roomId);
  const participants = useAppSelector((state) => state.call.participants);
  const remoteCameras = useAppSelector((state) => state.devices.remoteCameras);
  const remoteMicrophones = useAppSelector((state) => state.devices.remoteMicrophones);
  const isCameraTurnedOn = useAppSelector((state) => state.devices.isCameraTurnedOn);
  const isMicrophoneTurnedOn = useAppSelector((state) => state.devices.isMicrophoneTurnedOn);
  const localRoom = useAppSelector((state) => getLocalRoom(state, currentRoomId ?? ""));

  const { getRoomCreator, calculateUserName } = useMatrixRooms(currentRoomId ?? undefined);

  const roomOwner = currentRoomId ? getRoomCreator(currentRoomId) : null;
  const isRoomOwner = currentUser.user_id === roomOwner;
  const { mapUserAvatar, getAvatarComponent } = Custom();
  const Avatar = getAvatarComponent();

  const handleClick = () => {
    setOpen(!open);
  };

  const checkCameraMuteState = (name: any) => {
    return remoteCameras.some((item) => {
      return item.participant.name === name;
    });
  };

  const checkMicrophoneMuteState = (name: any) => {
    return remoteMicrophones.some((item) => {
      return item.participant.name === name && item.microphoneOn;
    });
  };

  const setParticipantMediaDeviceState = (participant: {
    isLocal: any;
    cameraOn: boolean;
    microphoneOn: boolean;
    name: any;
  }) => {
    if (participant.isLocal) {
      participant.cameraOn = isCameraTurnedOn;
      participant.microphoneOn = isMicrophoneTurnedOn;
    } else {
      participant.cameraOn = checkCameraMuteState(participant.name);
      participant.microphoneOn = checkMicrophoneMuteState(participant.name);
    }
  };

  const expandUserModeration = (participantId: React.SetStateAction<number>) => {
    setParticipantIdExpandUserModeration(participantId === participantIdExpandUserModeration ? 0 : participantId);
  };

  const isParticipantRoomModerator = (participant: any) => {
    const members = localRoom ? localRoom.getMembers() : [];
    const ownerMember = members.find((member) => member.userId === roomOwner);
    const name: string = calculateUserName(roomOwner!, ownerMember?.user).toLowerCase();
    participant.guest = participant.userId.indexOf("Guest") !== -1 && participant.name !== name;

    const member = members.find((member) => member.name === participant.name);
    const isModerator = currentRoomId && member && ownerMember && ownerMember.userId === member.userId;
    return isModerator;
  };

  useEffect(() => {
    let tempList = [];
    let participantsList = participants.list.sort((item1: any, item2: any) => item1.name.localeCompare(item2.name));
    for (const participant of participantsList) {
      const participantId = getParticipantId(participant.userId);
      if (participant.isLocal) {
        setLocalParticipantId(participantId);
      }

      const isModerator = isParticipantRoomModerator(participant);

      setParticipantMediaDeviceState(participant);
      let unsafeName = unsafeParseTextFromHTMLString(participant.name);
      const isExpanded = participantIdExpandUserModeration === participantId;

      let microphoneOn;
      let cameraOn;
      if (participant.isLocal) {
        microphoneOn = isMicrophoneTurnedOn;
        cameraOn = isCameraTurnedOn;
      } else {
        let remoteMic = remoteMicrophones.find((x) => x.participant.userId === participant.userId);
        microphoneOn = remoteMic ? remoteMic.microphoneOn : false;
        let remoteCam = remoteCameras.find((x) => x.participant.userId === participant.userId);
        cameraOn = remoteCam ? true : false;
      }

      tempList.push(
        <div
          className={`list-item ${isExpanded ? "expanded" : ""}`}
          {...test("PARTICIPANT")}
          key={participantId}
          style={{ backgroundColor: isExpanded ? theme.palette.background.expanded : "inherit" }}
        >
          <div className="list-item-container">
            <div className="list-item-avatar-container">
              <div className="avatar" {...test("PARTICIPANT_AVATAR")}>
                <Avatar
                  name={getProperCase(unsafeName)}
                  src={mapUserAvatar(participant, currentRoomId)}
                  size="45"
                  disableBadge
                />
              </div>
              <Stack
                id="details-container"
                direction="column"
                justifyContent="center"
                sx={{ marginLeft: "14px", flexWrap: "nowrap", overflow: "hidden" }}
              >
                <StyledTypography variant="body1" {...test("PARTICIPANT_NAME")}>
                  {getProperCase(unsafeName)}
                </StyledTypography>
                <Stack id="devices_info" direction="row" alignItems="center" gap={"10px"}>
                  {isModerator && currentRoomId && (
                    <Typography
                      variant="subText"
                      sx={{ marginTop: "5px", color: theme.palette.text.secondary }}
                      {...test("MODERATOR_LABEL")}
                    >
                      {t("MODERATOR")}
                    </Typography>
                  )}
                  {(!microphoneOn || !cameraOn || !participant.guest) && (
                    <div className="additional-info">
                      {!microphoneOn && (
                        <SvgIcon sx={{ fontSize: "16px" }} {...test("MICROPHONE_MUTED")}>
                          <MicOffIcon />
                        </SvgIcon>
                      )}
                      {!cameraOn && (
                        <SvgIcon sx={{ fontSize: "16px", marginLeft: "5px" }} {...test("CAMERA_TURNED_OFF")}>
                          <VideocamOffIcon />
                        </SvgIcon>
                      )}
                    </div>
                  )}
                </Stack>
              </Stack>
            </div>
            {isRoomOwner && currentRoomId && (
              <IconButton onClick={() => expandUserModeration(participantId)} sx={{ fontSize: "16px" }}>
                <MoreVertIcon />
              </IconButton>
            )}
          </div>
          {isExpanded && (
            <ModerationUser participantId={participantId} microphoneOn={microphoneOn} cameraOn={cameraOn} />
          )}
        </div>
      );
    }
    setList(tempList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    participants,
    participantIdExpandUserModeration,
    remoteCameras,
    remoteMicrophones,
    isMicrophoneTurnedOn,
    isCameraTurnedOn,
  ]);

  return (
    <div
      className="participants-list-container"
      dir={languageDirection}
      style={{
        backgroundColor: theme.palette.background.paperWithOpacity,
      }}
    >
      <div className="participants-list-header" data-test-id="PARTICIPANTS_LIST_HEADER">
        <Typography variant="h6" sx={{ padding: `${theme.spacing(2)} ${theme.spacing(3)}` }}>{`${t("PARTICIPANTS")} (${
          participants.list.length
        })`}</Typography>
        <IconButton
          aria-label="close"
          tooltip={t("CLOSE")}
          onClick={onClose}
          size="large"
          sx={{ position: "absolute", right: theme.spacing(1), top: "13px" }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={`participants-list-content ${isRoomOwner ? "roomOwner" : ""} scroll-bar`}>{list}</div>
      {isRoomOwner && currentRoomId && (
        <List
          component="div"
          aria-labelledby="nested-list-subheader"
          disablePadding
          sx={{ width: "100%", bottom: "0", marginTop: "auto" }}
        >
          <ClickAwayListener
            onClickAway={() => {
              if (open) setOpen(false);
            }}
          >
            <div>
              <Divider style={{ backgroundColor: theme.palette.background.default }} />
              <ListItemButton
                sx={{
                  height: "55px",
                  color: theme.palette.text.button.text.active,
                  backgroundColor: theme.palette.background.expanded,
                  "&:hover": {
                    backgroundColor: theme.palette.background.expanded,
                  },
                }}
                onClick={handleClick}
                data-test-id="MODERATE_CALL_BUTTON"
              >
                <ListItemText
                  primary={t("MODERATE_CALL")}
                  primaryTypographyProps={{
                    variant: "regular",
                    sx: { color: theme.palette.text.button.text.active, textAlign: "center" },
                  }}
                />
                {open ? <ExpandMore /> : <ExpandLess />}
              </ListItemButton>
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                sx={{ backgroundColor: theme.palette.background.expanded }}
              >
                <ModerationCall participantId={localParticipantId} />
              </Collapse>
            </div>
          </ClickAwayListener>
          <RecorderBar />
        </List>
      )}
    </div>
  );
};

export default ParticipantsList;
