import { useEffect, useState, useCallback, useMemo } from "react";

export type keyboardOptions = {
  shiftKey: boolean;
  ctrlKey: boolean;
  code: string;
};

export const useWindowDimensions = () => {
  const hasWindow = typeof window !== "undefined";

  const getWindowDimensions = useCallback(() => {
    const windowWidth = hasWindow ? window.innerWidth : null;
    const windowHeight = hasWindow ? window.innerHeight : null;

    return {
      windowWidth,
      windowHeight,
    };
  }, [hasWindow]);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowDimensions]);

  return windowDimensions;
};

export function useWindowBlur() {
  const [isBlurred, setBlur] = useState(false);

  useEffect(() => {
    const handleWindowBlur = () => {
      setBlur(true);
    };

    const handleWindowFocus = () => {
      setBlur(false);
    };

    window.addEventListener("focus", handleWindowFocus, false);
    window.addEventListener("blur", handleWindowBlur, false);
    return () => {
      window.removeEventListener("focus", handleWindowFocus);
      window.removeEventListener("blur", handleWindowBlur);
    };
  }, []);

  return isBlurred;
}

export function useVisibility(handleStateChange?: (state: DocumentVisibilityState) => void) {
  const [visibility, setVisibility] = useState(document.visibilityState);

  const handleVisibilityChange = () => {
    if (handleStateChange) handleStateChange(document.visibilityState);
    else setVisibility(document.visibilityState); // hidden, visible
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange, false);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return visibility;
}

export function useOnUnload() {
  const [isUnloading, setIsUnloading] = useState(false);

  const handleOnUnload = () => {
    setIsUnloading(true);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleOnUnload);
    return () => {
      console.debug("Unloading hook");
      window.removeEventListener("beforeunload", handleOnUnload);
    };
  }, []);

  return isUnloading;
}

export function useKeyboardShortcut(options: keyboardOptions, callback: () => void) {
  const [isLocked, setLock] = useState(false);
  const hotKeys = useMemo(() => Object.entries(options), [options]);

  const handleKeyDown = useCallback(
    (event) => {
      if (!isLocked && hotKeys.every(([key, value]) => event[key] === value)) {
        callback();
        setLock(true);
      }
    },
    [hotKeys, callback, isLocked]
  );

  const handleKeyUp = useCallback(() => {
    if (isLocked) {
      setLock(false);
    }
  }, [isLocked]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  return [handleKeyDown, handleKeyUp];
}
