import React, { useEffect, useState, useRef, useMemo } from "react";
import { CircularProgress } from "@mui/material";
import Video from "components/Video/Video";
import { getLogger } from "logger/appLogger";
import { isAndroid, isMobileSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ConnectedProps, connect } from "react-redux";
import { RootState } from "store/root-reducer";
import { debounce } from "throttle-debounce";
import { deviceDisableReason } from "utils/constants";
import { test } from "utils/helpers";
import "./SelfView.scss";
import { useMobileDimension } from "hooks/useMobile";

interface SelfViewOwnProps {
  ignoreMuteState?: boolean;
  externalControls?: any;
}

const mapStateToProps = (state: RootState) => ({
  selectedCamera: state.devices.selectedCamera,
  isCameraTurnedOn: state.devices.isCameraTurnedOn,
  isCameraDisabled: state.devices.isCameraDisabled,
  cameraDisableReasons: state.devices.cameraDisableReasons,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type SelfViewProps = ConnectedProps<typeof connector> & SelfViewOwnProps;

const SelfView = ({
  ignoreMuteState,
  externalControls,
  selectedCamera,
  isCameraTurnedOn,
  isCameraDisabled,
  cameraDisableReasons,
}: SelfViewProps) => {
  const logger = useMemo(() => getLogger(), []);
  const module = "SelfView";
  const { t } = useTranslation();
  const [isMobileDimension] = useMobileDimension();
  const [isPlaying, setPlaying] = useState(false);
  const [facingMode, setFacingMode] = useState("");
  const [stream, setStream] = useState<MediaStream | null>(null);
  const isMounted = useRef(false);

  const isCameraOff = !ignoreMuteState && !isCameraTurnedOn;
  const isNotPermitted = cameraDisableReasons.includes(deviceDisableReason.NO_PERMISSION);

  const startSource = debounce(500, (retry?) => {
    stopSource();
    if (selectedCamera) {
      const deviceId = selectedCamera.id;
      logger.info(`${module} - asking stream ${retry ? "on retry" : ""} for device ${deviceId}`);
      navigator.mediaDevices
        .getUserMedia({ video: { deviceId } })
        .then((stream) => {
          if (isMounted.current) {
            logger.info(`${module} - Stream ${stream.id} started for device ${deviceId}`);
            //@ts-ignore
            stream.oninactive = () => {
              logger.info(`${module} - oninactive for stream ${stream.id} incative for device ${deviceId}`);
            };
            setStream(stream);
            stream.getVideoTracks().forEach((track) => {
              setFacingMode(track.getSettings().facingMode || "desktop");
            });
          } else {
            logger.info(`${module} - Stream ${stream.id} stopped for device ${deviceId} as component is not mounted`);
            stream.getVideoTracks().forEach((track) => track.stop());
          }
        })
        .catch((e) => {
          if (stream) {
            logger.error(
              `Error in ${module} - Stream ${stream.id} for device ${deviceId} is not started. ${e.toString()}`
            );
          } else {
            logger.error(`Error in ${module} - problems getting stream for device ${deviceId}. ${e.toString()}`);
          }

          setStream(null);
          if (!retry && e.name === "NotReadableError") {
            startSource(true);
          }
        });
    }
  });

  const stopSource = () => {
    if (stream) {
      logger.info(`${module} - Stream ${stream.id} stopped`);
      stream.getVideoTracks().forEach((track) => track.stop());
    }
  };

  const onPlaying = () => {
    if (stream) {
      logger.info(`${module} - onPlaying for stream ${stream.id}`);
      setPlaying(true);
    }
  };

  const onPause = () => {
    logger.info(`${module} - onPause for stream ${(stream || {}).id}`);
    setPlaying(false);
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      stopSource();
      setStream(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ignoreMuteState) {
      if (!isCameraDisabled) {
        startSource();
      }
    } else {
      if (!isCameraDisabled && isCameraTurnedOn) {
        if (selectedCamera) {
          startSource();
        } else {
          stopSource();
        }
      } else {
        stopSource();
      }
    }
    return () => {
      startSource.cancel();
      if (stream) {
        logger.info(`${module} - Stream ${stream.id} stopped as component unmounted`);
        stream.getVideoTracks().forEach((track) => track.stop());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCameraDisabled, isCameraTurnedOn, selectedCamera]);

  useEffect(() => {
    if (!ignoreMuteState && selectedCamera && !isCameraTurnedOn && stream) {
      stopSource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream]);

  const Loading = () => {
    if (!isCameraOff && !isPlaying) {
      return <CircularProgress size={24} />;
    }
    return null;
  };

  const ExternalControls = () => {
    if ((isMobileSafari || isAndroid || isMobileDimension) && externalControls) {
      return <div className="external-controls-container">{externalControls}</div>;
    }
    return null;
  };

  if (isCameraOff || isNotPermitted) {
    return (
      <div className="self-view">
        <div className="placeholder" {...test("SELF_VIEW_OFF")}>
          {t("CAMERA_OFF")}
        </div>
      </div>
    );
  }

  return (
    <div className="self-view">
      <div className="self-view-render">
        <Loading />
        <Video
          stream={stream}
          data-playing={isPlaying}
          className={`video-tile ${facingMode}`}
          onPlaying={onPlaying}
          onPause={onPause}
          {...test("SELF_VIEW")}
        />
        <ExternalControls />
      </div>
    </div>
  );
};

export default connector(SelfView);
