import {
  MappedRoomList,
  MatrixClientOptions,
  MatrixRoom,
  Visibility,
  Membership,
  LocalEventTypeEnum,
  LocalEventType,
  Mx_ITagMetadata,
  LocalMatrixRoom,
  LocalMatrixRoomMember,
  MatrixRoomTypes,
} from "types/Matrix";
import { generateRandomString, isJson, tryParse } from "utils/helpers";
import * as sdk from "matrix-js-sdk";
import {
  ICreateRoomOpts,
  Filter,
  MatrixClient as MxClient,
  Room,
  IStartClientOpts,
  RoomMember,
  MatrixEvent,
  IRoomEvent,
  User,
} from "matrix-js-sdk";
import { getLogger } from "logger/appLogger";
import { ILogger, isDebugLogger } from "logger/logger";
import { JsonStringify } from "utils/utils";

export interface IMatrixUCClient {
  addRoomToPublicRoomList(roomId: string): Promise<void>;
  categorizeRoom(room: MatrixRoom): Promise<void>;
  setPublicRooms(): Promise<void>;
  removeRoom(roomId: string): boolean;
  forgetRoom(roomId: string, deleteRoom: boolean): Promise<{}>;
  leaveRoom(roomId: string): Promise<{}>;
  getRoomDirectoryVisibility(roomId: string): Promise<object | undefined>;
  setRoomDirectoryVisibility(roomId: string, visibility: Visibility): Promise<{} | undefined>;
  joinRoom(roomId: string): Promise<Room>;
  createRoom(opts: { visibility?: Visibility; name?: string }): Promise<{ room_id: string } | undefined>;
  removeUserFromRoom(roomId: string, userId: string, reason: string): Promise<{}>;
  getRoomTags(roomId: string): Promise<object>;
  inviteToRoom(roomId: string, userId: string): Promise<{}>;
  sendInternalMessageEvent(roomId: string, eventType: LocalEventType, content: object): Promise<{}>;
  mapRoomList(): Promise<MappedRoomList>;
  setRoomTopic(roomId: string, topic: string): Promise<any>;
  getRoomTopic(room: MatrixRoom): any | null;
  setRoomName(roomId: string, name: string): Promise<any>;
  deleteRoomTag(roomId: string, tagName: string): Promise<{}>;
  setRoomTag(roomId: string, tagName: string, metadata: object): Promise<{}>;
  setRoomEventsTimestamp(event: any): void;
  getPublicRooms(searchText?: string): Promise<any>;
  isPublic(room: LocalMatrixRoom): Promise<boolean>;
  getRoomList(): MappedRoomList;
  getRoomListWithType(type?: string): LocalMatrixRoom[];
  getRoomCreator(room: MatrixRoom): string | null;
  uploadContent(
    file: any,
    opts: {
      name?: string | undefined;
      includeFilename?: boolean | undefined;
      type?: string | undefined;
      rawResponse?: boolean | undefined;
      onlyContentUri?: boolean | undefined;
      callback?: ((...args: any[]) => any) | undefined;
      progressHandler?: ((...args: any[]) => any) | undefined;
    }
  ): Promise<string | any>;
  getPushers(): Promise<object | undefined>;
  createFilter(content: object): Promise<Filter | undefined>;
  stop(): void;
  start(opts: IStartClientOpts): void;
  loginWithToken(token: string): void;
  login(u: string, p: string): void;
  create(opts: string | MatrixClientOptions, caller: string): void;
  convertMatrixRoomToMappedRoom(matrixRoom: MatrixRoom): LocalMatrixRoom;
  setAvatarUrl(url: string): Promise<{}>;
  createMessagesRequest: (
    currentRoom: MatrixRoom,
    fromToken: string | null,
    limit: number,
    dir: sdk.Direction,
    timelineFilter?: sdk.Filter
  ) => Promise<[MatrixEvent[], string | undefined]>;
  getUserProfileInfo(userId: string): Promise<{
    avatar_url?: string | undefined;
    displayname?: string | undefined;
  }>;
  getCurrentUser(): User | null;
  client: MxClient;
  _client: MxClient;
  _usercreds: any;
  _mappedRoomList: MappedRoomList;
  _publicRoomList: string[];
  _needToSetNewAccountData: boolean;
}

class MatrixUCClient implements IMatrixUCClient {
  _client!: MxClient;
  _usercreds: any;
  _mappedRoomList: MappedRoomList = {
    invites: [],
    private: [],
    public: [],
    contacts: [],
    favorites: [],
  };
  _publicRoomList: string[] = [];
  _needToSetNewAccountData: boolean = false;
  _logger: ILogger | undefined = undefined;

  get logger(): ILogger {
    if (!this._logger) {
      this._logger = getLogger("matrix.client");
    }
    // @ts-ignore
    return this._logger;
  }

  get isDebugLogger(): boolean {
    return isDebugLogger(this.logger);
  }

  get client() {
    return this._client;
  }

  set client(cl) {
    this._client = cl;
  }

  get credentials() {
    return this._usercreds;
  }

  set credentials(creds) {
    this._usercreds = creds;
  }

  create(opts: MatrixClientOptions | string, caller: string) {
    if (typeof opts === "string") {
      opts = {
        baseUrl: opts,
      };
    }

    const deviceId = generateRandomString(16);

    opts = {
      ...opts,
      iceCandidatePoolSize: 10,
      supportsCallTransfer: true,
      // forceTurn will be enabled by default until SIP/HostedPBX is added
      // due to media packets being blocked by some user's NAT Devices/Routers.
      forceTURN: true,
      deviceId,
    };

    this.logger.log(`[MatrixClient.create] from ${caller} =>  Configuration Options: ${JSON.stringify(opts)}`);
    this.client = sdk.createClient(opts);
    this.client.setMaxListeners(500);
  }

  async login(username: string, password: string) {
    try {
      return await this.client.loginWithPassword(username, password);
    } catch (e) {
      this.logger.error(`Error in matrix.login - ${e.message}`);
      throw e;
    }
  }

  async loginWithToken(token: string) {
    try {
      return await this.client.loginWithToken(token);
    } catch (e) {
      throw e;
    }
  }

  async getSsoLoginUrl(redirectUrl: string) {
    try {
      return this.client.getSsoLoginUrl(redirectUrl, "sso");
    } catch (e) {
      this.logger.error(`Error in matrix.getSsoLoginUrl - redirectUrl:${redirectUrl} - ${e.message}`);
      throw e;
    }
  }

  async createFilter(content: object) {
    try {
      return await this.client.createFilter(content);
    } catch (e) {
      this.logger.error(`Error in matrix.createFilter - content:${content} - ${e.message}`);
      throw e;
    }
  }

  async getPushers() {
    try {
      return await this.client.getPushers();
    } catch (e) {
      this.logger.error(`Error in matrix.getPushers - ${e.message}`);
      throw e;
    }
  }

  async start(opts: IStartClientOpts) {
    try {
      //@ts-ignore
      this.client.setFallbackICEServerAllowed(true);
      return await this.client.startClient(opts);
    } catch (e) {
      this.logger.error(`Error in matrix.start - ${e.message}`);
      throw e;
    }
  }

  async uploadContent(
    file: any,
    opts: {
      name?: string | undefined;
      includeFilename?: boolean | undefined;
      type?: string | undefined;
      rawResponse?: boolean | undefined;
      onlyContentUri?: boolean | undefined;
      callback?: ((...args: any[]) => any) | undefined;
      progressHandler?: ((...args: any[]) => any) | undefined;
    }
  ) {
    try {
      return await this.client.uploadContent(file, opts);
    } catch (e) {
      // The error returned by API Gateway or Synapse server is vague and technical.
      // logging to stderr and suggesting possible cause.
      // TODO:  React Hook useTranslation not supported in this context.
      // Alternative translation mechanism should be used.
      this.logger.error(`Error in matrix.uploadContent - ${e.message}`);
      throw Error("Uploaded file may be too large.");
    }
  }

  async stop() {
    try {
      if (!this.client) return;
      this.client.stopClient();
      await this.client.logout();
    } catch (e) {
      this.logger.error(`Error in matrix.stop - ${e.message}`);
      throw e;
    }
  }

  async setRoomTopic(roomId: string, topic: string) {
    try {
      this.logger.debug(`matrix.setRoomTopic - roomId:${roomId}, topic:${topic}`);
      return await this.client.setRoomTopic(roomId, topic);
    } catch (e) {
      this.logger.error(`Error in matrix.setRoomTopic - ${e.message}`);
      throw e;
    }
  }

  getRoomTopic(room: MatrixRoom): any | null {
    try {
      const event: MatrixEvent[] | MatrixEvent = room?.currentState?.getStateEvents(LocalEventTypeEnum.RoomTopic, "")!;
      if (event) {
        //@ts-ignore
        const { topic } = event.getContent();
        this.logger.debug(`matrix.getRoomTopic - roomId:${room.roomId} -> topic:${topic}`);
        return topic;
      } else {
        return null;
      }
    } catch (e) {
      this.logger.error(`Error in matrix.getRoomTopic - ${e.message}`);
      return null;
    }
  }

  async setRoomName(roomId: string, name: string) {
    try {
      this.logger.debug(`matrix.setRoomName - roomId:${roomId}, name:${name}`);
      return await this.client.setRoomName(roomId, name);
    } catch (e) {
      this.logger.error(`Error in matrix.setRoomName - ${e.message}`);
      throw e;
    }
  }

  getRoomList() {
    try {
      return this._mappedRoomList;
    } catch (e) {
      this.logger.error(`Error in matrix.getRoomList - type:all - ${e.message}`);
      return {};
    }
  }

  getRoomListWithType(type: string = "all") {
    try {
      return this._mappedRoomList[type];
    } catch (e) {
      this.logger.error(`Error in matrix.getRoomList - type:${type} - ${e.message}`);
      return [];
    }
  }

  async setRoomTag(roomId: string, tagName: string, metadata: Mx_ITagMetadata): Promise<{}> {
    try {
      this.logger.debug(`matrix.setRoomTag - roomId:${roomId}, tagName:${tagName}, metadata:${metadata}`);
      return await this.client.setRoomTag(roomId, tagName, metadata);
    } catch (e) {
      throw e;
    }
  }

  /**
   * Sets the eventsTimestamp value of the room with the TS value of the event
   *
   * @return {void} void
   */
  setRoomEventsTimestamp(event: any) {
    try {
      for (let i in this._mappedRoomList) {
        const room: LocalMatrixRoom | undefined = this._mappedRoomList[i].find(
          (room) => room.roomId === event.getRoomId()
        );
        if (room) {
          room.eventsTimestamp = event.getTs();
        }
      }
    } catch (e) {
      throw e;
    }
  }

  async deleteRoomTag(roomId: string, tagName: string): Promise<{}> {
    try {
      this.logger.debug(`matrix.deleteRoomTag - roomId:${roomId}, tagName:${tagName}`);
      return await this.client.deleteRoomTag(roomId, tagName);
    } catch (e) {
      throw e;
    }
  }

  async getRoomTags(roomId: string) {
    try {
      const tags = await this.client.getRoomTags(roomId);
      this.logger.debug(`matrix.getRoomTags - roomId:${roomId} -> tags:${tags}`);
      return tags;
    } catch (e) {
      throw e;
    }
  }

  async inviteToRoom(roomId: string, userId: string): Promise<{}> {
    try {
      this.logger.debug(`matrix.inviteToRoom - roomId:${roomId}, userId:${userId}`);
      return await this.client.invite(roomId, userId);
    } catch (e) {
      this.logger.error(`Error in matrix.inviteToRoom - roomId:${roomId}, userId:${userId} - ${e.message}`);
      return Promise.reject({});
    }
  }

  async sendInternalMessageEvent(roomId: string, eventType: LocalEventType, content: object): Promise<{}> {
    try {
      const result = await this.client.sendEvent(roomId, eventType, content);
      this.logger.debug(
        `matrix.sendInternalMessageEvent - roomId:${roomId}, eventType:${eventType}, content:${content} -> result:${result}`
      );
      return result;
    } catch (e) {
      this.logger.error(
        `Error in matrix.sendInternalMessageEvent - roomId:${roomId}, eventType:${eventType}, content:${content} - ${e.message}`
      );
      return Promise.reject({});
    }
  }

  async setAvatarUrl(url: string): Promise<{}> {
    try {
      this.logger.debug(`matrix.setAvatarUrl - url:${url}`);
      return await this.client.setAvatarUrl(url);
    } catch (e) {
      this.logger.error(`Error in matrix.setAvatarUrl - url:${url} - ${e.message}`);
      throw e;
    }
  }

  async removeUserFromRoom(roomId: string, userId: string, reason: string): Promise<{}> {
    try {
      this.logger.debug(`matrix.removeUserFromRoom - roomId:${roomId}, userId:${userId}, reason:${reason}`);
      return await this.client.kick(roomId, userId, reason);
    } catch (e) {
      this.logger.error(
        `Error in matrix.removeUserFromRoom - roomId:${roomId}, userId:${userId}, reason:${reason} - ${e.message}`
      );
      return Promise.reject({});
    }
  }

  async createRoom(opts: ICreateRoomOpts) {
    try {
      const roomId = await this.client.createRoom(opts);
      this.logger.debug(`matrix.createRoom - opts:${opts} -> roomId:${roomId}`);
      return roomId;
    } catch (e) {
      throw e;
    }
  }

  async joinRoom(roomId: string) {
    try {
      this.logger.debug(`matrix.joinRoom - roomId:${roomId}`);
      return await this.client.joinRoom(roomId);
    } catch (e) {
      throw e;
    }
  }

  async leaveRoom(roomId: string): Promise<{}> {
    try {
      this.logger.debug(`matrix.leaveRoom - roomId:${roomId}`);
      return await this.client.leave(roomId);
    } catch (e) {
      this.logger.error(`Error in matrix.leaveRoom - roomId:${roomId} - ${e.message}`);
      return Promise.reject({});
    }
  }

  async forgetRoom(roomId: string, deleteRoom: boolean): Promise<{}> {
    try {
      this.logger.debug(`matrix.forgetRoom - roomId:${roomId}, deleteRoom:${deleteRoom}`);
      return await this.client.forget(roomId, deleteRoom);
    } catch (e) {
      this.logger.error(`Error in matrix.forgetRoom - roomId:${roomId}, deleteRoom:${deleteRoom} - ${e.message}`);
      return Promise.reject({});
    }
  }

  async setRoomDirectoryVisibility(roomId: string, visibility: Visibility) {
    try {
      this.logger.debug(`matrix.setRoomDirectoryVisibility - roomId:${roomId}, visibility:${visibility}`);
      return await this.client.setRoomDirectoryVisibility(roomId, visibility);
    } catch (e) {
      this.logger.error(
        `Error in matrix.setRoomDirectoryVisibility - roomId:${roomId}, visibility:${visibility} - ${e.message}`
      );
      throw e;
    }
  }

  async getRoomDirectoryVisibility(roomId: string) {
    try {
      const visibility = await this.client.getRoomDirectoryVisibility(roomId);
      this.logger.debug(`matrix.getRoomDirectoryVisibility - roomId:${roomId} -> visibility:${visibility}`);
      return visibility;
    } catch (e) {
      this.logger.error(`Error in matrix.getRoomDirectoryVisibility - roomId:${roomId} - ${e.message}`);
      throw e;
    }
  }

  getRoomCreator(room: MatrixRoom): string | null {
    try {
      const event: MatrixEvent[] | MatrixEvent = room?.currentState?.getStateEvents(LocalEventTypeEnum.RoomCreate, "")!;
      let roomCreator = null;
      if (event) {
        //@ts-ignore
        const { creator }: { creator: string } = event.getContent();
        roomCreator = creator;
      }
      this.logger.debug(`matrix.getRoomCreator - roomId:${room.roomId} -> roomCreator:${roomCreator}`);
      return roomCreator;
    } catch (e) {
      this.logger.error(`Error in matrix.getRoomCreator - room:${room} - ${e.message}`);
      return null;
    }
  }

  removeFromArray(arr: MappedRoomList, key: string, prop: string, value: any) {
    const exists = this.findInArray(arr, key, prop, value) ? 1 : 0;
    //@ts-ignore
    arr[key] = arr[key].filter((el) => el[prop] !== value);
    return exists;
  }

  findInArray(arr: MappedRoomList, key: string, prop: string, value: any) {
    //@ts-ignore
    return arr[key].find((el) => el[prop] === value);
  }

  findInArrayIndex(arr: MappedRoomList, key: string, prop: string, value: any) {
    //@ts-ignore
    return arr[key].findIndex((el) => el[prop] === value);
  }

  removeRoom(roomId: string) {
    this.logger.debug(`matrix.removeRoom - roomId:${roomId}`);
    let removed = 0;
    if (this._mappedRoomList[MatrixRoomTypes.favorites]) {
      removed += this.removeFromArray(this._mappedRoomList, MatrixRoomTypes.favorites, "roomId", roomId);
    }

    if (this._mappedRoomList[MatrixRoomTypes.public]) {
      removed += this.removeFromArray(this._mappedRoomList, MatrixRoomTypes.public, "roomId", roomId);
    }

    if (this._mappedRoomList[MatrixRoomTypes.private]) {
      removed += this.removeFromArray(this._mappedRoomList, MatrixRoomTypes.private, "roomId", roomId);
    }

    if (this._mappedRoomList[MatrixRoomTypes.contacts]) {
      removed += this.removeFromArray(this._mappedRoomList, MatrixRoomTypes.contacts, "roomId", roomId);
    }

    if (this._mappedRoomList["invites"]) {
      removed += this.removeFromArray(this._mappedRoomList, "invites", "roomId", roomId);
    }

    this._publicRoomList = this._publicRoomList.filter((r) => {
      return r !== roomId;
    });

    return removed > 0;
  }

  async mapRoomList() {
    try {
      this.logger.log(`Mapping Room List...`);
      this._needToSetNewAccountData = false;
      await Promise.all(
        this.client.getRooms().map(async (room) => {
          let mRoom = room as MatrixRoom;
          await this.categorizeRoom(mRoom);
        })
      );
      if (this._needToSetNewAccountData) {
        this.logger.log("Setting new acccount data");
        const accountData = this.client.getAccountData("m.direct");
        if (accountData) {
          //@ts-ignore
          const content = accountData.getContent();
          if (content) {
            await this.client.setAccountData("m.direct", content);
          }
          this._needToSetNewAccountData = false;
        }
      }
      this.logger.log(`Room List Mapped`);
    } catch (e) {
      this.logger.error(`Error in matrix.mapRoomList - ${e.message}`);
      throw e;
    }
    return this._mappedRoomList;
  }

  convertMatrixRoomMembersToLocalRoomMembers(members: { [userId: string]: RoomMember }) {
    const membersList: LocalMatrixRoomMember[] = [];
    Object.keys(members).forEach((userId: string) => {
      membersList.push({
        userId: userId,
        membership: members[userId].membership,
        name: members[userId].name,
      });
    });
    return membersList;
  }

  convertMatrixRoomToMappedRoom(room: MatrixRoom): LocalMatrixRoom {
    const topic = this.getRoomTopic(room);
    const roomTopic = tryParse(topic);

    return {
      roomId: room.roomId,
      name: room.name,
      tags: room.tags,
      members: this.convertMatrixRoomMembersToLocalRoomMembers(room.currentState!.members),
      eventsTimestamp: room.eventsTimestamp ? room.eventsTimestamp : 0,
      type: room.type,
      myUserId: room.myUserId,
      isHidden: roomTopic?.isHidden,
      isGroupChat: roomTopic?.isGroupChat,
      description: roomTopic?.description,
      topic: topic,
      roomURL: roomTopic?.roomURL,
      ownerId: this.getRoomCreator(room),
      unreadNotificationCount: room.getUnreadNotificationCount(),

      getJoinedMemberCount: () => room.getJoinedMemberCount(),
      getJoinedMembers: () => room.getJoinedMembers(),
      getLiveTimeline: () => room.getLiveTimeline(),
      getMyMembership: () => room.getMyMembership(),
      getTimelineSets: () => room.getTimelineSets(),
      guessDMUserId: () => room.guessDMUserId(),
      recalculate: () => room.recalculate(),
      getMembers: () => room.getMembers(),

      getUnreadNotificationCount: (type?: sdk.NotificationCountType | undefined) =>
        room.getUnreadNotificationCount(type),
      getMember: (userId: string) => room.getMember(userId),
    };
  }

  /**
   * Creates a rooms list, separating the rooms by category (invite, favorites, private, public and contacts)
   **/
  async categorizeRoom(room: MatrixRoom) {
    try {
      if (!room) return;
      let localRoom: LocalMatrixRoom = this.convertMatrixRoomToMappedRoom(room);

      const hasLeftRoom = room.getMyMembership() === Membership.leave;
      if (hasLeftRoom) return;

      const { roomId } = localRoom;

      if (localRoom.type === MatrixRoomTypes.invite) {
        this.removeFromArray(this._mappedRoomList, "invites", "roomId", roomId);
      }

      // sets the eventsTimestamp of the room with the TS value of the last event
      localRoom.eventsTimestamp = localRoom.eventsTimestamp ? localRoom.eventsTimestamp : 0;
      let timestamp = room.getLastActiveTimestamp();
      localRoom.eventsTimestamp =
        timestamp > 0 && timestamp > localRoom.eventsTimestamp ? timestamp : localRoom.eventsTimestamp;

      /** favourites rooms */
      //@ts-ignore
      if (room.tags["m.favourite"]) {
        this._mappedRoomList[MatrixRoomTypes.favorites] = this._mappedRoomList[MatrixRoomTypes.favorites] || [];

        const existsInFavorites = this.findInArray(this._mappedRoomList, MatrixRoomTypes.favorites, "roomId", roomId);

        if (!existsInFavorites) {
          this._mappedRoomList[MatrixRoomTypes.favorites].push(localRoom);
        }
      } else {
        this.removeFromArray(this._mappedRoomList, MatrixRoomTypes.favorites, "roomId", roomId);
      }

      const existsInFavorites = this.findInArray(this._mappedRoomList, MatrixRoomTypes.favorites, "roomId", roomId);

      /** direct rooms - contacts */
      const isDirect = room.type === MatrixRoomTypes.direct ? MatrixRoomTypes.direct : this.isDirect(localRoom);
      if (isDirect) {
        localRoom.type = MatrixRoomTypes.direct;
        room.type = MatrixRoomTypes.direct;
        this._mappedRoomList[MatrixRoomTypes.contacts] = this._mappedRoomList[MatrixRoomTypes.contacts] || [];

        const existsInContacts = this.findInArray(this._mappedRoomList, MatrixRoomTypes.contacts, "roomId", roomId);

        const existsInPrivate = this.findInArray(this._mappedRoomList, MatrixRoomTypes.private, "roomId", roomId);

        if (!existsInFavorites) {
          if (!existsInContacts) this._mappedRoomList[MatrixRoomTypes.contacts].push(localRoom);
          else {
            const index = this.findInArrayIndex(this._mappedRoomList, MatrixRoomTypes.contacts, "roomId", roomId);
            if (index >= 0) this._mappedRoomList[MatrixRoomTypes.contacts][index] = localRoom;
          }
        }

        if (existsInFavorites) {
          this.removeFromArray(this._mappedRoomList, MatrixRoomTypes.contacts, "roomId", roomId);
        }

        if (existsInPrivate) {
          this.removeFromArray(this._mappedRoomList, MatrixRoomTypes.private, "roomId", roomId);
        }
        return;
      }

      /** public rooms */
      const isPublic = await this.isPublic(localRoom);
      if (isPublic) {
        localRoom.type = MatrixRoomTypes.public;
        room.type = MatrixRoomTypes.public;
        this._mappedRoomList[MatrixRoomTypes.public] = this._mappedRoomList[MatrixRoomTypes.public] || [];

        const existsInPublic = this.findInArray(this._mappedRoomList, MatrixRoomTypes.public, "roomId", roomId);

        const existsInPrivate = this.findInArray(this._mappedRoomList, MatrixRoomTypes.private, "roomId", roomId);

        if (!existsInFavorites) {
          if (!existsInPublic) this._mappedRoomList[MatrixRoomTypes.public].push(localRoom);
          else {
            const index = this.findInArrayIndex(this._mappedRoomList, MatrixRoomTypes.public, "roomId", roomId);
            if (index >= 0) this._mappedRoomList[MatrixRoomTypes.public][index] = localRoom;
          }
        }

        if (existsInFavorites) {
          this.removeFromArray(this._mappedRoomList, MatrixRoomTypes.public, "roomId", roomId);
        }

        if (existsInPrivate) {
          this.removeFromArray(this._mappedRoomList, MatrixRoomTypes.private, "roomId", roomId);
        }

        return;
      } else {
        this.removeFromArray(this._mappedRoomList, MatrixRoomTypes.public, "roomId", roomId);
      }

      /** private rooms */
      localRoom.type = MatrixRoomTypes.private;
      room.type = MatrixRoomTypes.private;
      this._mappedRoomList[MatrixRoomTypes.private] = this._mappedRoomList[MatrixRoomTypes.private] || [];
      const existsInPrivate = this.findInArray(this._mappedRoomList, MatrixRoomTypes.private, "roomId", roomId);
      const existsInPublic = this.findInArray(this._mappedRoomList, MatrixRoomTypes.public, "roomId", roomId);
      const existsInContacts = this.findInArray(this._mappedRoomList, MatrixRoomTypes.contacts, "roomId", roomId);

      if (!existsInFavorites) {
        if (existsInContacts) return;
        if (!existsInPrivate) this._mappedRoomList[MatrixRoomTypes.private].push(localRoom);
        else {
          const index = this.findInArrayIndex(this._mappedRoomList, MatrixRoomTypes.private, "roomId", roomId);
          if (index >= 0) this._mappedRoomList[MatrixRoomTypes.private][index] = localRoom;
        }
      }

      if (existsInFavorites) {
        this.removeFromArray(this._mappedRoomList, MatrixRoomTypes.private, "roomId", roomId);
      }

      if (existsInPublic) {
        this.removeFromArray(this._mappedRoomList, MatrixRoomTypes.public, "roomId", roomId);
      }
      return;
    } catch (e) {
      this.logger.error(`Error in matrix.categorizeRoom - roomId:${room.roomId} - ${e.message}`);
      throw e;
    }
  }

  checkRoomForRoomTopicName(roomId: string) {
    try {
      const room = this.client.getRoom(roomId) as MatrixRoom;
      const { myUserId } = room;
      let name = null;
      //@ts-ignore
      const [event] = room?.currentState?.getStateEvents(LocalEventTypeEnum.RoomTopic);
      if (event) {
        const { topic } = event.getContent();
        if (topic && isJson(topic)) {
          const roomTopic = tryParse(topic);
          name = roomTopic[myUserId!]?.name || null;
        }
      }
      this.logger.debug(`matrix.checkRoomForRoomTopicName - roomId:${roomId} -> name:${name}`);
      return name;
    } catch (e) {
      this.logger.error(`Error in matrix.checkRoomForRoomTopicName - roomId:${roomId} - ${e.message}`);
      return null;
    }
  }

  isDirect(room: LocalMatrixRoom) {
    let isDirect = false,
      myName = null;
    try {
      const { roomId, myUserId } = room;
      const accountData = this.client.getAccountData("m.direct");
      this.logger.debug(`matrix.isDirect - roomId:${room.roomId} -> accountData:${accountData}`);
      if (accountData) {
        //@ts-ignore
        const content = accountData.getContent();

        for (let i in content) {
          const dmRoomIdList = content[i];
          this.logger.debug(`matrix.isDirect - dmRoomIdList:${dmRoomIdList}`);
          if (dmRoomIdList.includes(roomId)) {
            isDirect = true;
          } else {
            myName = this.checkRoomForRoomTopicName(roomId);
            if (myName && myUserId && i === myUserId) {
              content[myUserId].push(roomId);
              isDirect = true;
              this._needToSetNewAccountData = true;
              this.logger.debug(`matrix.isDirect - _needToSetNewAccountData:${this._needToSetNewAccountData}`);
            }
          }
        }
      }
    } catch (e) {
      this.logger.error(`Error in matrix.isDirect - roomId:${room.roomId} - ${e.message}`);
    }
    return isDirect;
  }

  async isPublic(room: LocalMatrixRoom) {
    let isPublic = false;
    try {
      const res = await this.client.getRoomDirectoryVisibility(room.roomId);
      this.logger.debug(`matrix.isPublic - getRoomDirectoryVisibility:${res}`);
      if (res) {
        const visibility = (res as { visibility: string }).visibility;
        isPublic = visibility === Visibility.Public;
      }
    } catch (e) {
      this.logger.error(`Error in matrix.isPublic - roomId:${room.roomId} - ${e.message}`);
    }
    return isPublic;
  }

  async setPublicRooms() {
    try {
      const data = await this.getPublicRooms();
      //@ts-ignore
      data?.chunk.forEach((room: { room_id: string }) => {
        this.addRoomToPublicRoomList(room.room_id);
      });
      return;
    } catch (e) {
      this.logger.error(`Error in matrix.setPublicRooms - ${e.message}`);
    }
  }

  async addRoomToPublicRoomList(roomId: string) {
    this.logger.debug(`matrix.addRoomToPublicRoomList - roomId:${roomId}`);
    const isInArray = this._publicRoomList.find((r) => r === roomId);
    if (isInArray) return;
    this._publicRoomList.push(roomId);
  }

  async getPublicRooms(searchText?: string) {
    try {
      const options = {
        limit: 1000,
        filter: {
          generic_search_term: searchText ? searchText : "",
        },
      };
      const publicRooms = await this.client.publicRooms(options);
      this.logger.debug(`matrix.getPublicRooms - searchText:${searchText} -> publicRooms:${publicRooms}`);
      return publicRooms;
    } catch (e) {
      this.logger.error(`Error in matrix.getPublicRooms - ${e.message}`);
      throw e;
    }
  }

  async createMessagesRequest(
    currentRoom: MatrixRoom,
    fromToken: string | null,
    limit: number,
    dir: sdk.Direction,
    timelineFilter?: sdk.Filter
  ): Promise<[MatrixEvent[], string | undefined]> {
    try {
      const results = await this.client.createMessagesRequest(
        currentRoom.roomId,
        fromToken,
        limit,
        dir,
        timelineFilter
      );
      this.logger.debug(
        `matrix.createMessagesRequest - currentRoom:${currentRoom}, fromToken:${fromToken}, limit:${limit}, dir:${dir}, timelineFilter:${timelineFilter} -> results:${results}`
      );

      // The token the pagination ends at. If doesn't have more events, the end is undefined
      const lastToken: string | undefined = results.end;
      const rawEvents: IRoomEvent[] = results.chunk || [];
      const matrixEvents: MatrixEvent[] = [];

      rawEvents.forEach((rawEvent) => {
        if (this.isDebugLogger) {
          this.logger.debug(`liveEvent: ${JsonStringify(rawEvent)}`);
        }
        const matrixEvent = this.client.getEventMapper()(rawEvent);
        if (matrixEvent) {
          matrixEvent.sender = currentRoom.getMember(rawEvent.sender);
          matrixEvents.push(matrixEvent);
        } else {
          this.logger.error(`Error mapping raw matrix event ${rawEvent}`);
        }
      });
      return [matrixEvents, lastToken];
    } catch (e) {
      throw e;
    }
  }

  async getUserProfileInfo(userId: string): Promise<{
    avatar_url?: string | undefined;
    displayname?: string | undefined;
  }> {
    try {
      return await this.client.getProfileInfo(userId);
    } catch (e) {
      throw e;
    }
  }

  getCurrentUser = (): User | null => {
    try {
      const user = this.client.credentials.userId ? this.client.getUser(this.client.credentials.userId) : null;
      this.logger.debug(`${module}: getCurrentUser - user:${user}`);
      return user;
    } catch (e) {
      this.logger.error(`${module}: Error in getCurrentUser - ${e.message}`);
      return null;
    }
  };
}

if (!window.matrixClient) {
  window.matrixClient = new MatrixUCClient();
}

const MatrixClient = window.matrixClient;

export default MatrixClient;
