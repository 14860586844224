import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/root-reducer";
import useLocalStorage from "./useLocalStorage";
import useMedia from "./useMedia";
import { DarkMode } from "../types/Hooks";
import { getTheme } from "themes/theme/baseTheme";
import { ThemeOptions } from "@mui/material";

const useDarkMode = (): DarkMode => {
  const [theme, setTheme] = useState<ThemeOptions>(getTheme("enghouse", "light"));

  const currentBrand = useSelector((state: RootState) => state.theme.currentBrand);

  // Use our useLocalStorage hook to persist state through a page refresh.
  // Read the recipe for this hook to learn more: usehooks.com/useLocalStorage
  const [enabledState, setEnabledState] = useLocalStorage<boolean>("dark-mode-enabled");

  // See if user has set a browser or OS preference for dark mode.
  // The usePrefersDarkMode hook composes a useMedia hook (see code below).
  const prefersDarkMode = usePrefersDarkMode();

  // If enabledState is defined use it, otherwise fallback to prefersDarkMode.
  // This allows user to override OS level setting on our website.
  const enabled = typeof enabledState !== "undefined" ? enabledState : prefersDarkMode;

  // Fire off effect that add/removes dark mode class
  useEffect(
    () => {
      if (enabled) {
        setTheme(getTheme(currentBrand, "dark"));
      } else {
        setTheme(getTheme(currentBrand, "light"));
      }
    },
    [enabled, currentBrand] // Only re-call effect when value changes
  );

  // Return enabled state and setter
  return [theme, enabled, setEnabledState];
};

const usePrefersDarkMode = (): boolean => {
  return useMedia(["(prefers-color-scheme: dark)"], [true], false);
};

export default useDarkMode;
