import { useEffect, useMemo, useState } from "react";
import { Custom } from "services/CustomRenderer";
import { getProperCase } from "utils/helpers";
import { CallMediaType, TileData } from "types/UC";
import Layout from "./layout/Layout";
import { getLogger } from "logger/appLogger";
import { useAppSelector } from "store/hooks";

const Render = () => {
  const logger = useMemo(() => getLogger("render-layout"), []);

  const participants = useAppSelector((state) => state.call.participants);
  const remoteWindowShares = useAppSelector((state) => state.call.remoteWindowShares);
  const localWindowShares = useAppSelector((state) => state.call.selectedShare);
  const remoteCameras = useAppSelector((state) => state.devices.remoteCameras);
  const isCallActive = useAppSelector((state) => state.call.active);
  const isCameraTurnedOn = useAppSelector((state) => state.devices.isCameraTurnedOn);
  const selectedCamera = useAppSelector((state) => state.devices.selectedCamera);
  const hasActiveMatrixCall = useAppSelector((state) => state.matrix.hasActiveMatrixCall);
  const matrixVideoMuted = useAppSelector((state) => state.matrix.matrixVideoMuted);
  const inMatrixScreenShare = useAppSelector((state) => state.matrix.inMatrixScreenShare);
  const currentRoomId = useAppSelector((state) => state.matrix.roomId);

  const { mapUserAvatar, getExtData } = Custom();

  const [extData, setExtData] = useState<any[]>([]);
  let tiles: TileData[] = [];

  participants.list.forEach(async (participant) => {
    const localParticipant = participant.isLocal;
    const avatarUrl = mapUserAvatar(
      participant,
      (hasActiveMatrixCall || isCallActive) && currentRoomId ? currentRoomId : undefined
    );
    const participantName = getProperCase(participant.name);
    const tile = {
      title: participantName,
      name: participantName,
      id: participant.userId,
      type: CallMediaType.audio,
      active: participant.loudest || false,
      avatarUrl,
      stream: null,
      isSelfView: false,
    };

    if (localParticipant) {
      tile.type = CallMediaType.audio;
      tile.stream = null;
      tile.isSelfView = true;
      tile.id = "self_view";
      tile.active = false;
    }

    tiles.push(tile);
  });

  tiles = [...tiles, ...extData];

  remoteCameras.forEach((item) => {
    tiles.forEach((tile) => {
      if (item.participant.userId === tile.id) {
        tile.type = CallMediaType.remoteCamera;
        tile.stream = item;
      }
    });
  });

  if (hasActiveMatrixCall && matrixVideoMuted) {
    tiles.forEach((tile) => {
      if (tile.isSelfView) {
        tile.type = CallMediaType.audio;
        tile.stream = null;
        tile.isSelfView = true;
        tile.id = "self_view";
      }
    });
  }

  if (
    (isCallActive && isCameraTurnedOn) ||
    (hasActiveMatrixCall && !matrixVideoMuted) ||
    (hasActiveMatrixCall && inMatrixScreenShare)
  ) {
    tiles.forEach((tile) => {
      if (tile.isSelfView) {
        tile.type = CallMediaType.localCamera;
        tile.stream = selectedCamera;
        tile.isSelfView = true;
        tile.id = "local_self_view";
      }
    });
  }

  remoteWindowShares.forEach((item, index) => {
    tiles.push({
      title: item.participant.name + "share",
      id: "remote_share",
      type: CallMediaType.remoteWindowShare,
      stream: item,
    });
  });

  if (localWindowShares) {
    tiles.push({
      title: "local share",
      id: "local_share",
      type: CallMediaType.localWindowShare,
      stream: localWindowShares,
    });
  }

  if (
    !tiles.some((item) => {
      return item.active;
    })
  ) {
    const i = tiles.findIndex((item) => {
      return item.type === CallMediaType.remoteCamera || item.type === CallMediaType.audio;
    });
    if (i !== -1) {
      tiles[i].active = true;
    }
  }

  /**
   * Sort tiles by following order:
   * self > local/remote share > active > all other tiles
   */
  tiles.sort((a, b) => {
    if (a.isSelfView) return -1;
    if (b.isSelfView) return 1;
    if (a.type === "localWindowShare" || a.type === "remoteWindowShare") return -1;
    if (b.type === "localWindowShare" || b.type === "remoteWindowShare") return 1;
    if (a.active) return -1;
    if (b.active) return 1;

    return 0;
  });

  useEffect(() => {
    const fetchData = async () => {
      const res = await getExtData();
      setExtData(res);
    };
    if (hasActiveMatrixCall) fetchData().catch((e) => logger.error(`Error in render.fetchData - ${e.message}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Layout tiles={tiles} />;
};

export default Render;
