import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import { Select } from "components";

interface GeneralSelectListProps {
  id: string;
  title: string | null;
  items: any;
  selectedItemName: string;
  onItemSelect: (event: SelectChangeEvent<unknown>) => void;
  noResultsText?: string;
  noSelectText?: string;
  tooltip?: string;
  className?: string;
}

const GeneralSelectList = ({
  id,
  title,
  items,
  onItemSelect,
  noResultsText,
  noSelectText,
  selectedItemName,
  tooltip,
  className,
}: GeneralSelectListProps) => {
  const getSelectList = () => {
    const result = items;
    if (items && items.length > 0) {
      /* no value selected */
      if (!selectedItemName || selectedItemName === "") {
        result.unshift(
          <MenuItem key={0} disabled value="">
            {noSelectText}
          </MenuItem>,
        );
      }
      return result;
    } else {
      /* no results */
      return (
        <MenuItem key={0} disabled value="">
          {noResultsText}
        </MenuItem>
      );
    }
  };

  return (
    <Select
      id={id}
      value={selectedItemName}
      label={title}
      className={className}
      displayEmpty
      onChange={onItemSelect}
      tooltip={tooltip}
    >
      {getSelectList()}
    </Select>
  );
};

export default GeneralSelectList;
