import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

import { useTheme } from "@mui/material/styles";

import "containers/Call/Call.scss";
import { useEffect, useState } from "react";
import IconButton from "components/_shared/IconButton";

interface DeviceToggleProps {
  tooltip: string;
  disabled: boolean;
  on: boolean;
  onClick: () => void;
  device: Toggle_Devices;
  onMouseDown?: (event: { nativeEvent: { which: number } }) => void;
}

export enum Toggle_Devices {
  SPEAKER = "speaker",
  MICROPHONE = "microphone",
  CAMERA = "camera",
}

const DeviceToggle = (props: DeviceToggleProps) => {
  const [icon, setIcon] = useState(<></>);
  const { device, tooltip, on, onClick, onMouseDown, disabled } = props;
  const theme = useTheme();

  useEffect(() => {
    switch (device) {
      case Toggle_Devices.SPEAKER: {
        setIcon((icon) => (icon = disabled || !on ? <VolumeOffIcon /> : <VolumeUpIcon />));
        break;
      }
      case Toggle_Devices.CAMERA: {
        setIcon((icon) => (icon = disabled || !on ? <VideocamOffIcon /> : <VideocamIcon />));
        break;
      }
      case Toggle_Devices.MICROPHONE: {
        setIcon((icon) => (icon = disabled || !on ? <MicOffIcon /> : <MicIcon />));
        break;
      }
      default: {
        setIcon((icon) => (icon = <></>));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [on, disabled]);

  return (
    <IconButton
      sx={{ color: !on ? theme.palette.sentiment.error.base : "#fff" }}
      id={`device_toggle_${device}_button`}
      disabled={disabled}
      tooltip={tooltip}
      tooltipPlacement="top"
      onClick={onClick}
      onMouseDown={onMouseDown}
      className="toggle"
    >
      {icon}
    </IconButton>
  );
};

export default DeviceToggle;
