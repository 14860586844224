import { useState, useEffect } from "react";
import mxClient from "matrix/matrix";

import useMatrixEvent from "./useMatrixEvent";
import useLocalStorage from "./useLocalStorage";
import { MatrixRoom } from "types/Matrix";
import { UCUser } from "types/UC";
import { localStorageKeys } from "utils/constants";

const useMatrixTyping = (room: MatrixRoom, eventType: string | null) => {
  const client = mxClient.client;
  const [user] = useLocalStorage<UCUser>(localStorageKeys.CURRENT_USER);
  const roomMemberTyping = useMatrixEvent(client, eventType);
  const [typingMembers, setTypingMembers] = useState<any[]>([]);
  const [typingStatus, setTypingStatus] = useState<string | null>("");

  useEffect(() => {
    setTypingStatus("");
    setTypingMembers([]);
  }, [room.roomId]);

  useEffect(() => {
    if (!roomMemberTyping) return;
    const [, member] = roomMemberTyping;
    if (member.userId === user.user_id) return;
    if (member.roomId === room.roomId) {
      if (member.typing) {
        setTypingMembers((prevTypingMembers: any[]) => {
          const isInArray = typingMembers.find((mem: any) => mem.userId === member.userId);
          if (isInArray) return prevTypingMembers;
          return [...prevTypingMembers, member];
        });
      } else {
        setTypingMembers((prevTypingMembers) => prevTypingMembers.filter((mem) => mem.userId !== member.userId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomMemberTyping]);

  useEffect(() => {
    if (typingMembers.length === 0) {
      setTypingStatus(null);
      return;
    }

    if (typingMembers.length === 1) {
      setTypingStatus(`${typingMembers[0].name} is typing`);
    } else if (typingMembers.length === 2) {
      setTypingStatus(`${typingMembers[0].name} and ${typingMembers[1].name} are typing`);
    } else {
      setTypingStatus(`Several people are typing`);
    }
  }, [typingMembers]);

  return { typingStatus, typingMembers };
};

export default useMatrixTyping;
