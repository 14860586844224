export enum PbxEventListenerType {
  Conference = "conference",
  Call = "call",
}

export interface PbxEvent {
  subscriptionId: string;
  callId: string;
  peerExtension: string;
}

export interface PbxEventListenerDelegate {
  onEvent(event: PbxEvent): void;
}

export interface PbxEventSubscription {
  subscriptionId: string;
  href: string;
  notificationUrl: string;
  callId: string;
}

export interface PbxCallInfo {
  callId: string;
  originator: string;
  callQueue: string;
  agent: string;
  origCallInfo: string;
  termCallInfo: string;
  type: string;
  timeAnswer: any;
}
