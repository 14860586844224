import React, { useState, useEffect, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import Avatar from "components/_shared/Avatar";
import { DialoutCallTransfer } from "containers/DialoutCall/DialoutCallTransfer";
import { useTranslation } from "react-i18next";
// import MicrophoneToggle from "containers/QuickMediaSettings/toggles/MicrophoneToggle";
// import CameraToggle from "containers/QuickMediaSettings/toggles/CameraToggle";
import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";

import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/system";
import { useMatrixAvatar, useMatrixRooms } from "hooks";
import { useLanguageDirection } from "hooks/useLanguage";
import styled from "themes/theme/baseTheme";

import "./CallNotification.scss";
import { UiSipCallInfo } from "types/SIP";
import { getLogger } from "logger/appLogger";
import { JsonStringify } from "utils/utils";
import { getActiveIncomingCallInfo } from "store/selectors";
import { useAppSelector } from "store/hooks";
import { isEmpty } from "utils/helpers";
import { CallType } from "types/UC";

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: "0px 0px 10px 10px",
  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  opacity: 0.95,
  backgroundColor: theme.palette.background.dark,
  backgroundImage: "none",
  backdropFilter: "blur(25px)",
  width: "375px",
  height: "329px",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  width: "56px",
  height: "56px",
  "&:focus": {
    outline: "none",
  },
  svg: {
    fontSize: "2.5rem",
  },
}));

const StyledEndIconButton = styled(StyledIconButton)(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.sentiment.error.base,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.sentiment.error.dark1,
  },
}));

const StyledStartIconButton = styled(StyledIconButton)(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    filter: "brightness(85%)",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.common.white,
}));

interface CallNotificationProps {
  matrixCallInfo?: any;
  sipCallInfoPreliminary?: UiSipCallInfo;
  type?: CallType;
  onAccept: () => void;
  onReject: () => void;
}

const CallNotification = ({
  matrixCallInfo,
  sipCallInfoPreliminary,
  type,
  onAccept,
  onReject,
}: CallNotificationProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const logger = useMemo(() => getLogger("notification"), []);
  const languageDirection = useLanguageDirection();
  const { getRoomAvatar, getRoomMemberAvatar } = useMatrixAvatar();
  const { isDirect } = useMatrixRooms();
  const [showCallTransfer, setShowCallTransfer] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState("");
  const incomingSipCall = useAppSelector((state) => getActiveIncomingCallInfo(state, sipCallInfoPreliminary?.id || ""));
  const [callerName, setCallerName] = useState("");
  const [callerInfo, setCallerInfo] = useState("");
  const [callerInfoVia, setCallerInfoVia] = useState("");
  const [callType, setCallType] = useState("");

  const toggleTransferCall = () => {
    setShowCallTransfer(!showCallTransfer);
  };

  useEffect(() => {
    if (matrixCallInfo) {
      logger.debug("callNotification/matrix:", JsonStringify(matrixCallInfo));
      setCallType(t("INCOMING_CALL_TYPE", { type: type === CallType.voice ? t("AUDIO") : t("VIDEO") }));
      setCallerName(matrixCallInfo.name);
      let avatarUrl = "";
      if (isDirect(matrixCallInfo.roomId)) {
        const memberAvatar = getRoomMemberAvatar(matrixCallInfo.roomId!, matrixCallInfo.userId);
        avatarUrl = memberAvatar ?? "";
      } else {
        const roomAvatar = getRoomAvatar({ roomId: matrixCallInfo.roomId! });
        avatarUrl = roomAvatar ?? "";
      }
      setAvatarUrl(avatarUrl ? avatarUrl : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrixCallInfo]);

  useEffect(() => {
    if (sipCallInfoPreliminary) {
      logger.debug("callNotification/sip-preliminary:", JsonStringify(sipCallInfoPreliminary));
      setCallType(t("INCOMING_CALL_TYPE", { type: t("AUDIO") }));
      setCallerName(sipCallInfoPreliminary.peer.name || "");
      setCallerInfo(`${t("EXTENSION")} ${sipCallInfoPreliminary.peer.user}` || "");
      collectViaData(sipCallInfoPreliminary);
    }
    if (incomingSipCall) {
      logger.debug("callNotification/sip-call:", JsonStringify(incomingSipCall));
      if (!isEmpty(incomingSipCall.peer.name)) {
        setCallerName(incomingSipCall.peer.name);
      }
      setAvatarUrl(incomingSipCall.peer.avatarUrl || "");
      collectViaData(incomingSipCall);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sipCallInfoPreliminary, incomingSipCall]);

  const collectViaData = (sipCallInfo: UiSipCallInfo) => {
    if (sipCallInfo.via && (!isEmpty(sipCallInfo.via.name) || !isEmpty(sipCallInfo.via.user))) {
      const viaInfo = !isEmpty(sipCallInfo.via.name) ? sipCallInfo.via.name : sipCallInfo.via.user;
      setCallerInfoVia(`${t("CALL_VIA")}: ${viaInfo}`);
    }
  };

  return (
    <StyledPaper>
      <div className="call-notification-content" dir={languageDirection}>
        <Stack
          id="call_type"
          direction="row"
          gap="5px"
          alignItems="center"
          justifyContent="center"
          sx={{ marginTop: "10px" }}
        >
          {!type || type === CallType.voice ? (
            <CallRoundedIcon sx={{ color: theme.palette.neutrals.pureWhite }} />
          ) : (
            <VideocamRoundedIcon sx={{ color: theme.palette.neutrals.pureWhite }} />
          )}
          <StyledTypography variant="subtitle2" sx={{ textTransform: "uppercase" }}>
            {callType}
          </StyledTypography>
        </Stack>
        <StyledTypography variant="subText" sx={{ marginTop: "4px", height: "15px" }}>
          {callerInfoVia}
        </StyledTypography>
        <Avatar sx={{ marginTop: "16px" }} size="100" name={callerName} src={avatarUrl} disableBadge />
        <StyledTypography variant="h6" sx={{ marginTop: "10px" }}>
          {callerName}
        </StyledTypography>
        <StyledTypography variant="subText" sx={{ marginTop: "10px" }}>
          {callerInfo}
        </StyledTypography>
        {/* will be enabled in a next task */}
        {/* {!isSipCall && (
            <div className="media-settings">
              <MicrophoneToggle showLabel={false} />
              <CameraToggle showLabel={false} />
            </div>
          )} */}
        <Stack
          direction="row"
          alignItems="center"
          spacing={"51px"}
          sx={{ position: "relative", marginBottom: "26px", marginTop: "auto" }}
        >
          <StyledEndIconButton onClick={onReject} size="small">
            <CallEndIcon />
          </StyledEndIconButton>
          <StyledStartIconButton onClick={onAccept} size="small">
            <CallIcon />
          </StyledStartIconButton>
        </Stack>
        {/* TODO: will be enabled when the incoming call transfer works  */}
        {/*{isSipCall && (
            <Typography
              variant="h4"
              className="transfer-call"
              onClick={() => {
                setShowCallTransfer(true);
              }}
            >
              {t("TRANSFER_CALL")}
            </Typography>
            )}*/}
      </div>
      {showCallTransfer && <DialoutCallTransfer close={toggleTransferCall} />}
    </StyledPaper>
  );
};

export default CallNotification;
