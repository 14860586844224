import React, { useRef, useState, useEffect } from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

const CustomTypography = ({ ...props }: TypographyProps & { disableTooltip?: boolean }) => {
  const { children, sx, disableTooltip, ...params } = props;
  const labelRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    const labelChild = labelRef?.current;
    //@ts-ignore
    if (labelChild && labelChild!.scrollWidth > labelChild!.clientWidth) {
      setIsEllipsisActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelRef?.current]);

  return (
    <>
      {disableTooltip ? (
        <Typography
          ref={labelRef}
          sx={{ ...sx, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}
          {...params}
        >
          {children}
        </Typography>
      ) : (
        <Tooltip title={children} disableHoverListener={!isEllipsisActive} arrow>
          <Typography
            ref={labelRef}
            sx={{ ...sx, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}
            {...params}
          >
            {children}
          </Typography>
        </Tooltip>
      )}
    </>
  );
};

export default CustomTypography;
