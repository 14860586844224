import { RoomMember, User } from "matrix-js-sdk";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/root-reducer";
import { getLogger } from "logger/appLogger";
import { useMatrixRooms, useMatrixAvatar } from "hooks";
import { getUserName, getProperCase, isValidDisplayName } from "utils/helpers";
import {
  MappedRoomList,
  MatrixMember,
  MatrixUser,
  SearchData,
  StatusValues,
  MatrixRoomTypes,
  LocalMatrixRoom,
  MembershipType,
} from "types/Matrix";
import { UserKeycloakData } from "services/ProvisioningApiProvider/types/Provisioning";
import { getStatusMessage } from "services/PresenceProvider/PresenceProvider";

export const useMatrixMembers = () => {
  const logger = useMemo(() => getLogger("matrix"), []);
  const module = "useMatrixMembers";

  const { calculateUserName, isRoomLowPrio } = useMatrixRooms();
  const { getRoomMemberAvatar } = useMatrixAvatar();

  const { usersList, usersListToIgnore } = useSelector((state: RootState) => state.provisioning);

  const getMembersInfo = (members: RoomMember[], roomId: string) => {
    const membersInfo: MatrixMember[] = [];

    members.forEach((member) => {
      if (member) {
        let memberUser: MatrixMember;
        const username = getUserName(member.userId);
        const membership = member.membership ? (member.membership as MembershipType) : undefined;

        // if this user has been searched before
        const keycloakUser = usersList?.find((user: UserKeycloakData) => user.username === username);
        if (keycloakUser) {
          const { firstName, lastName, presence } = keycloakUser;
          const avatarUrl = presence && presence.avatarUrl ? presence.avatarUrl : "";
          const status = presence && presence.status ? presence.status : "offline";
          memberUser = {
            userId: member.userId,
            displayName: `${firstName} ${lastName}`,
            avatarUrl: avatarUrl,
            presence: status,
            presenceStatus: status,
            presenceStatusMsg: getStatusMessage(status),
            membership: membership,
          };
        } else {
          // if this user already exists on matrix
          const mxUser: MatrixUser | undefined = member.user;
          if (mxUser && mxUser.currentlyActive !== undefined) {
            const avatar = getRoomMemberAvatar(roomId, member.userId);
            memberUser = {
              userId: mxUser.userId,
              displayName: calculateUserName(mxUser.displayName, mxUser),
              avatarUrl: avatar ?? "",
              presence: mxUser.presence,
              presenceStatus: mxUser.presenceStatus ? mxUser.presenceStatus : mxUser.presence,
              presenceStatusMsg: getStatusMessage(
                mxUser.presenceStatus ? mxUser.presenceStatus : mxUser.presenceStatusMsg || mxUser.presence
              ),
              membership: membership,
            };
          } else {
            memberUser = {
              userId: member.userId,
              displayName: calculateUserName(member.name),
              avatarUrl: "",
              presence: StatusValues.offline.toString(),
              presenceStatus: StatusValues.offline.toString(),
              presenceStatusMsg: getStatusMessage(StatusValues.offline),
              membership: membership,
            };
          }
        }
        if (memberUser) membersInfo.push(memberUser);
      }
    });

    return membersInfo;
  };

  /** Check if the user already exists on the matrix users */
  const checkForExistingUser = (list: SearchData[], matrixUsers: User[]) => {
    try {
      list.forEach((item: SearchData) => {
        const { userId } = item;
        if (!userId) return;
        const user = matrixUsers.find((user: User) => user.userId === userId);
        if (!user) return;
        const displayName: string = item.displayName;
        item = Object.assign(item, user);
        item.displayName = getProperCase(displayName);
      });
    } catch (e) {
      logger.error(`${module}: Error in checkForExistingUser - ${e.message}`);
    }
  };

  const checkMembersToSearch = (members: RoomMember[], matrixUsers: User[]) => {
    const usersToSearch: RoomMember[] = [];

    members.forEach((member) => {
      if (member) {
        const username = getUserName(member.userId);
        // if this user already exists on matrix
        const mxUser = matrixUsers.find((item) => item.userId === member.userId);
        const alreadyOnMatrix = mxUser ? mxUser.currentlyActive !== undefined : false;
        // if this user has been searched before
        const alreadySearched = usersList?.find((user: UserKeycloakData) => user.username === username);
        // if this user is to be ignored - there was an error when searching before
        const toIgnore = usersListToIgnore?.find((user: UserKeycloakData) => user === username);
        if (
          (!alreadyOnMatrix || (alreadyOnMatrix && !isValidDisplayName(member.name))) &&
          !alreadySearched &&
          !toIgnore
        ) {
          usersToSearch.push(member);
          return;
        }
      }
    });
    return usersToSearch;
  };

  const getUsersFromDirectRooms = (roomList: MappedRoomList, currentUserId: string) => {
    const userList: string[] = [currentUserId];
    for (let i in roomList) {
      if (
        (i === MatrixRoomTypes.contacts || i === MatrixRoomTypes.favorites) &&
        roomList[i] &&
        roomList[i].length > 0
      ) {
        roomList[i].forEach((room: LocalMatrixRoom) => {
          if (!isRoomLowPrio(room.roomId) && room.type === MatrixRoomTypes.direct) {
            const contactUser = room.guessDMUserId();
            if (contactUser) userList.push(contactUser);
          }
        });
      }
    }
    return userList;
  };

  const checkRoomMembersToSearch = (room: LocalMatrixRoom, matrixUsers: User[]) => {
    const currentUserId = room.myUserId;
    const members = room.members;
    const usersToSearch: string[] = [];

    members.forEach((member) => {
      const userId = member.userId;
      if (userId !== currentUserId) {
        const username = getUserName(userId);
        // if this user already exists on matrix
        const mxUser = matrixUsers.find((item) => item.userId === userId);
        const alreadyOnMatrix = mxUser ? mxUser.currentlyActive !== undefined : false;
        // if this user has been searched before
        const alreadySearched = usersList?.find((user: UserKeycloakData) => user.username === username);
        // if this user is to be ignored - there was an error when searching before
        const toIgnore = usersListToIgnore?.find((user: UserKeycloakData) => user === username);
        if (!alreadyOnMatrix && !alreadySearched && !toIgnore) {
          usersToSearch.push(username);
          return;
        }
      }
    });
    return usersToSearch;
  };

  return {
    getMembersInfo,
    checkMembersToSearch,
    checkForExistingUser,
    getUsersFromDirectRooms,
    checkRoomMembersToSearch,
  };
};
