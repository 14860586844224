import * as actionTypes from "../actions/types/app";

const initialState = {
  inited: false,
  generatingLogsInProgress: false,
  generatingLogsSuccess: false,
  generateLogsUrl: null,
  generateLogsFailed: null,
  onlyRequestLogs: false,
  loginClientInProgress: false,
  loginStatusMessage: null,
  loginStatusErrorMessage: null,
  notificationCount: 0,
  outlookCalendarConnected: false,
  googleCalendarConnected: false,
  calendarUpdate: 0,
  joinRoomUrl: "",
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INIT_APP:
      return {
        ...state,
        inited: false,
      };

    case actionTypes.INIT_SUCCEEDED:
      return {
        ...state,
        inited: true,
      };

    case actionTypes.INIT_FAILED:
      return {
        ...state,
        inited: false,
      };
    case actionTypes.NOTIFICATION_COUNT_CHANGE:
      return {
        ...state,
        notificationCount: action.count,
      };

    case actionTypes.GENERATE_LOGS:
      return {
        ...state,
        generatingLogsInProgress: true,
        generatingLogsSuccess: false,
        generateLogsFailed: null,
      };

    case actionTypes.GENERATE_LOGS_SUCCEEDED:
      return {
        ...state,
        generatingLogsInProgress: false,
        generatingLogsSuccess: true,
        generateLogsUrl: action.url,
        onlyRequestLogs: action.onlyRequestLogs,
      };
    case actionTypes.GENERATE_LOGS_FAILED:
      return {
        ...state,
        generatingLogsInProgress: false,
        generatingLogsSuccess: false,
        generateLogsFailed: action.message,
        onlyRequestLogs: false,
      };
    case actionTypes.CLEAN_GENERATE_LOGS_ERROR:
      return {
        ...state,
        generateLogsFailed: undefined,
        onlyRequestLogs: false,
      };
    case actionTypes.RESET_LOG_STATE:
      return {
        ...state,
        generatingLogsInProgress: false,
        generatingLogsSuccess: false,
        generateLogsUrl: null,
      };
    case actionTypes.OUTLOOK_CALENDAR_EVENT:
      return {
        ...state,
        outlookCalendarConnected: action.connected,
      };
    case actionTypes.GOOGLE_CALENDAR_EVENT:
      return {
        ...state,
        googleCalendarConnected: action.connected,
      };
    case actionTypes.CALENDAR_UPDATE:
      return {
        ...state,
        calendarUpdate: state.calendarUpdate + 1,
      };
    case actionTypes.SET_JOIN_ROOM_URL:
      return {
        ...state,
        joinRoomUrl: action.joinRoomUrl,
      };
    default:
      return state;
  }
};

export default appReducer;
