import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { test } from "utils/helpers";

interface ConfirmDialogProps {
  title: string;
  openDialog: boolean;
  message: string;
  handleDialogClose: () => void;
  handleDialogAgree: () => void;
}

const ConfirmDialog = ({ title, message, openDialog, handleDialogClose, handleDialogAgree }: ConfirmDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="join/leave confirmation"
      open={openDialog}
      PaperProps={{ sx: { width: "356px" } }}
      {...test("CONFIRM_DIALOG")}
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Typography id="alert-dialog-description" variant="body2" sx={{ color: theme.palette.text.primary }}>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button id="confirm_dialog_cancel_button" onClick={handleDialogClose} variant="outlined">
          {t("CANCEL")}
        </Button>
        <Button
          id="confirm_dialog_agree_button"
          onClick={handleDialogAgree}
          variant="contained"
          color="primary"
          autoFocus
        >
          {t("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
