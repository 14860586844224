import React from "react";
import { CallActionType } from "types/UC";
import Dialpad from "./Dialpad";
import DialpadSettings from "./DialpadSettings";

import "./DialpadSidebar.scss";

const DialpadSidebar = () => {
  return (
    <div className="dialpad-sidebar scroll-bar">
      <Dialpad callType={CallActionType.Call} />
      <DialpadSettings />
    </div>
  );
};

export default DialpadSidebar;
