import { Components, Theme } from "@mui/material";
import { inputClasses } from "@mui/material/Input";

const MuiInputBase: Components["MuiInputBase"] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const palette = (theme as Theme).palette;
      return {
        backgroundColor: palette.background.filledInputBackground,
        color: palette.text.secondary,
        borderBottomColor: palette.border.input.notSelected,
        height: !inputClasses.multiline ? "44px" : "100%",
        width: "100%",
        [`&.MuiFilledInput-root`]: {
          [`&.${inputClasses.focused}`]: {
            color: palette.text.primary,
            [`&:before, :after`]: {
              borderBottomColor: palette.border.input.focused,
            },
          },
          [`&.${inputClasses.error}`]: {
            [`&:before, :after`]: {
              borderBottomColor: `${palette.border.input.error} !important`,
            },
          },
          [`&.${inputClasses.disabled}`]: {
            backgroundColor: palette.background.filledInputBackground,
            [`&:before, :after`]: {
              borderBottomColor: palette.border.input.disabled,
              borderBottomStyle: "solid",
            },
          },
        },
      };
    },
    input: {
      padding: "10px 15px",
    },
  },
};

export default MuiInputBase;
