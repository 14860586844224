const accent = {
  accentBP1: "#ADCAFF",
  accentBP2: "#85AFFF",
  accentBP3: "#337AFF",
  accentBP4: "#004FE0",
  accentBP5: "#00153B",
  accentBP6: "#000E29",
  accentBS1: "#DDF1F8",
  accentBS2: "#B0D9E8",
  accentBS3: "#235C80",
  accentBS4: "#184C60",
  accentBS5: "#12333F",
  accentBS6: "#091A20",
};

export type Accent = typeof accent;

export default accent;
