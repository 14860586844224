import { useEffect, useRef, useState } from "react";
import { isMobile as isMobileDevice, isAndroid } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import { callActions } from "store/actions/call";
import { matrixActions } from "store/actions/matrix";
import { test } from "utils/helpers";
import "containers/Call/Call.scss";

import isElectron from "is-electron";

import { useTranslation } from "react-i18next";
import { RootState } from "store/root-reducer";
import { Events } from "types/UC";
import { useEventListener } from "hooks";
import IosShareIcon from "@mui/icons-material/IosShare";

import { CallState } from "matrix-js-sdk/lib/webrtc/call";
import IconButton from "components/_shared/IconButton";

const ShareButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const documentRef = useRef<Document>(document);

  const { placeMatrixScreenShare, stopMatrixScreenShare } = matrixActions;

  const localWindowShares = useSelector((state: RootState) => state.call.localWindowShares);
  const selectedShare = useSelector((state: RootState) => state.call.selectedShare);
  const isCallActive = useSelector((state: RootState) => state.call.active);
  const screenShareSelecting = useSelector((state: RootState) => state.call.screenShareSelecting);
  const inMatrixScreenShare = useSelector((state: RootState) => state.matrix.inMatrixScreenShare);
  const hasActiveMatrixCall = useSelector((state: RootState) => state.matrix.hasActiveMatrixCall);
  const matrixCallClientState = useSelector((state: RootState) => state.matrix.matrixCallClientState);
  const matrixScreenShareSelecting = useSelector((state: RootState) => state.matrix.matrixScreenShareSelecting);

  useEffect(() => {
    if (!hasActiveMatrixCall && !matrixCallClientState) return;
    if (matrixCallClientState === CallState.Connected) setDisabled(false);
    else setDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrixCallClientState]);

  useEffect(() => {
    if (
      !hasActiveMatrixCall &&
      (!matrixCallClientState || (matrixCallClientState && matrixCallClientState !== CallState.Connected))
    )
      return;
    if (!matrixScreenShareSelecting) setDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrixScreenShareSelecting]);

  useEffect(() => {
    if (!isCallActive) return;
    if (!screenShareSelecting) setDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenShareSelecting]);

  const handleStartShare = () => {
    setDisabled(true);
    if (hasActiveMatrixCall) {
      if (inMatrixScreenShare) {
        dispatch(stopMatrixScreenShare());
        setDisabled(false);
      } else {
        dispatch(placeMatrixScreenShare());
      }
    } else {
      const windowShare = localWindowShares[0];
      if (selectedShare) {
        dispatch(callActions.stopWindowShare());
        setDisabled(false);
      } else if (windowShare) {
        dispatch(callActions.startWindowShare(windowShare));
      }
    }
  };

  useEventListener(
    Events.SHARE_CLICKED,
    () => {
      setDisabled(false);
    },
    isElectron() ? documentRef : null
  );

  if (isMobileDevice || isAndroid) {
    return null;
  }

  return (
    <IconButton
      id="share_button"
      className={`toggle ${selectedShare || inMatrixScreenShare ? "active" : ""}`}
      tooltip={inMatrixScreenShare || selectedShare ? t("TOOLTIP_SHARE_ON")! : t("TOOLTIP_SHARE_OFF")!}
      tooltipPlacement="top"
      onClick={handleStartShare}
      disabled={disabled}
      style={{ color: selectedShare || inMatrixScreenShare ? "#E84B40" : "white" }}
      {...test("SHARE_BUTTON")}
    >
      <IosShareIcon />
    </IconButton>
  );
};

export default ShareButton;
