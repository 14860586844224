import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import SmsIcon from "@mui/icons-material/Sms";
import Typography from "@mui/material/Typography";
import { useState, useMemo } from "react";
import IconButton from "components/_shared/IconButton";
import { useSip } from "hooks";
import { getLogger } from "logger/appLogger";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PbxUserData } from "services/PBXServerApi/types/PbxUsers";
import { sipActions } from "store/actions/sip";
import { useAppSelector } from "store/hooks";
import { getSipCallActive } from "store/selectors";
import "./DialoutCallTransfer.scss";
import { useTheme } from "@mui/material/styles";
import { CallActionType, CallMediaType } from "types/UC";
import { createSipDisplayName } from "utils/helpers";

interface DialoutCallTransferActionsProps {
  user: PbxUserData;
  withText: boolean;
  size: number;
  startChat?: (user: PbxUserData) => void;
  consultDoneCallback?: () => void;
}

export const DialoutCallTransferActions = (args: DialoutCallTransferActionsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const logger = useMemo(() => getLogger("sip.transfer"), []);

  const { holdInProgress, holdCall, transferCallToUser } = useSip();
  const [consultInProgress, setConsultInProgress] = useState<boolean>(false);

  const { firstName, lastName, extension } = args.user;
  const displayName = createSipDisplayName(firstName, lastName);

  const sipCallActive = useAppSelector((state) => getSipCallActive(state, true)); // includes active held call

  const transferCall = async () => {
    logger.debug(`transferCall sipCall=${sipCallActive?.id} extension=${extension}`);
    if (sipCallActive && extension && extension.length > 0) {
      await transferCallToUser(sipCallActive, extension);
    }
  };

  const startConsultCall = async () => {
    logger.debug(`startConsultCall extension='${extension}'`);
    // place current call on hold and initiate a call to extension
    if (!consultInProgress && !holdInProgress && extension && extension.length > 0) {
      try {
        setConsultInProgress(true);
        await holdCall(CallMediaType.audio);
        dispatch(
          sipActions.startSipCall({
            sipCallNumber: extension,
            sipCallType: CallActionType.Call,
            sipCallDisplayName: displayName,
          })
        );
        if (args.consultDoneCallback) {
          args.consultDoneCallback();
        }
      } finally {
        setConsultInProgress(false);
      }
    }
  };

  return (
    <div
      className="callTransferActionsContainer"
      id="callTransferActionsContainer"
      style={{ gap: `${args.startChat ? 32 : 25}px` }}
    >
      {args.startChat && (
        <div className="contactUserIconEntry" style={{ color: theme.palette.icon.selected }}>
          <IconButton
            tooltip={args.withText ? "" : t("TRANSFER_CHAT")}
            size="small"
            onClick={() => args.startChat && args.user && args.startChat(args.user)}
          >
            <SmsIcon style={{ height: `${args.size}px`, width: `${args.size}px` }} />
          </IconButton>

          {args.withText && (
            <div className="contactUserIconEntryText">
              <Typography variant="body2">{t("TRANSFER_CHAT")}</Typography>
            </div>
          )}
        </div>
      )}
      <div className="contactUserIconEntry" style={{ color: theme.palette.icon.selected }}>
        <IconButton
          tooltip={args.withText ? "" : t("TRANSFER_CONSULT")}
          disabled={consultInProgress || holdInProgress}
          size="small"
          onClick={() => startConsultCall()}
        >
          <HeadsetMicIcon sx={{ height: `${args.size}px`, width: `${args.size}px` }} />
        </IconButton>

        {args.withText && (
          <div className="contactUserIconEntryText">
            <Typography variant="body2">{t("TRANSFER_CONSULT")}</Typography>
          </div>
        )}
      </div>
      <div className="contactUserIconEntry" style={{ color: theme.palette.icon.selected }}>
        <IconButton tooltip={args.withText ? "" : t("TRANSFER_CALL")} size="small" onClick={() => transferCall()}>
          <PhoneForwardedIcon style={{ height: `${args.size}px`, width: `${args.size}px` }} />
        </IconButton>

        {args.withText && (
          <div className="contactUserIconEntryText">
            <Typography variant="body2">{t("TRANSFER_CALL")}</Typography>
          </div>
        )}
      </div>
    </div>
  );
};
