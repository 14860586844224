import { toast } from "react-toastify";

import "./Notifications.scss";
import { templates } from "./Templates";
import { TemplateData, TemplateKey } from "types/UC";

export const showToastNotification = (type: TemplateKey, data: TemplateData) => {
  if (!data) {
    return null;
  }

  if (document.visibilityState === "visible") {
    toast(templates[(type || "default") as TemplateKey](data), {
      pauseOnFocusLoss: false,
    });
  }
};

export default showToastNotification;
