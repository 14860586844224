import React, { useRef, useEffect, useMemo } from "react";
import { useAppSelector } from "store/hooks";

import { checkText } from "smile2emoji";
import DOMPurify from "dompurify";
import styles from "../Messages.module.scss";
import "../Messages.scss";
//@ts-ignore
import { ReactTinyLink, useScrapper } from "react-tiny-link";
import { useLightBox, useLocalStorage } from "hooks";
import { fileName, isUrl, test } from "utils/helpers";
import Linkify from "react-linkify";
import parse from "html-react-parser";
import twemoji from "twemoji";
import { getLogger } from "logger/appLogger";
import { localStorageKeys } from "utils/constants";
import { MAP_ID, handleClickOnMap } from "./Map";

const Html = ({ content, imageSrc }: { content: any; imageSrc: string | undefined }) => {
  const logger = useMemo(() => getLogger("matrix"), []);
  const module = "MessageEventType-Html";
  const isDarkMode = useAppSelector((state) => state.theme.isDarkMode);
  const { showLightBox, renderLightBox } = useLightBox();
  const formatted_body = useRef<HTMLDivElement | null>(null);
  const htmlBody = checkText(content.formatted_body.replace("<p>", "<p> ").replace("</p>", " </p>"));
  const sanitizedHtmlBody = DOMPurify.sanitize(htmlBody);
  const [linkPreviewEnabled] = useLocalStorage(localStorageKeys.SHOW_LINK_PREVIEW, false);
  const linkPreviewProxy = process.env.REACT_APP_LINK_PREVIEW_PROXY_URL;

  const [hasPreview] = useScrapper(
    linkPreviewEnabled
      ? {
          url: isUrl(content.body) ? content.body : null,
          proxyUrl: linkPreviewProxy,
        }
      : {}
  );

  const showLinkPreview = hasPreview && linkPreviewEnabled;

  useEffect(() => {
    // handle the onclick event in the map preview for forwarded location messages
    if (content.msgtype === "m.location" && content.geo_uri) {
      const map = document.getElementById(`${MAP_ID}${content.geo_uri}`);
      if (map) {
        map.addEventListener(
          "click",
          function () {
            handleClickOnMap(content.geo_uri);
          },
          false
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!formatted_body.current) return;
    try {
      const anchors = formatted_body.current ? [...formatted_body.current!.getElementsByTagName("a")] : [];
      anchors.forEach((anchor) => {
        if (anchor.href.includes(`https://matrix.to/#/@`)) {
          anchor.removeAttribute("href");
          anchor.classList.add(`${styles.mentionStyle}`);
        } else {
          anchor.setAttribute("target", "_blank");
        }
      });
    } catch (e) {
      logger.error(`${module}: Error handling the update of html formatted_body and isDarkMode - ${e.message}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatted_body, isDarkMode]);

  return (
    <div {...test("MESSAGE_EVENT_TEXT")}>
      {content.edited && <span className={`${styles.edited}`}>(edited)</span>}
      <div className={`${styles.messageBody}`} ref={formatted_body}>
        {showLinkPreview ? (
          <ReactTinyLink proxyUrl={linkPreviewProxy} cardSize="small" url={content.body} />
        ) : (
          <Linkify>{parse(twemoji.parse(sanitizedHtmlBody, { folder: "svg", ext: ".svg" }))}</Linkify>
        )}
      </div>
      {renderLightBox()}
      {imageSrc && content.msgtype !== "m.location" ? (
        <img src={imageSrc} alt={fileName(imageSrc)} data-attribute="mxImages" onClick={showLightBox} />
      ) : null}
    </div>
  );
};

export default Html;
