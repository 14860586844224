export enum PresenceStates {
  available = "available",
  away = "away",
  busy = "busy",
  call = "call",
  conf = "conf",
  dnd = "dnd",
  idle = "idle",
  offline = "offline",
  presenting = "presenting",
}
