import * as actionTypes from "../actions/types/presence";

export const presenceActions = {
  startPresenceService: (config) => ({ type: actionTypes.START_PRESENCE_SERVICE, config }),
  stopPresenceService: () => ({ type: actionTypes.STOP_PRESENCE_SERVICE }),
  changePresence: (opts) => ({ type: actionTypes.CHANGE_PRESENCE, opts }),
  onPresenceChange: (status) => ({ type: actionTypes.ON_PRESENCE_CHANGE, status }),
  onOtherPresenceChange: (status) => ({
    type: actionTypes.ON_OTHER_PRESENCE_CHANGE,
    status,
  }),
  subscribeToPresenceChange: () => ({ type: actionTypes.SUBSCRIBE_PRESENCE_CHANGE }),
  updatePresenceAvatar: (avatarUrl) => ({ type: actionTypes.UPDATE_PRESENCE_AVATAR, avatarUrl }),
};
