import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { getLogger } from "logger/appLogger";
import { getUA } from "react-device-detect";
import getStore from "./store";
import "./translations/i18n";
import "url-search-params-polyfill";
import RouterComponent from "./router/Router";
import { ToastContainer, toast } from "react-toastify";

import "styles/styles.scss";
import "./styles/index.scss";
import "react-awesome-lightbox/build/style.css";
import "react-toastify/dist/ReactToastify.min.css";
import { localStorageKeys } from "utils/constants";
import { stopMemoryMonitor } from "utils/memoryMonitor";
import { logCollectorFlushMemoryBufferToDb } from "logger/logCollector";

import "@fontsource/noto-sans/400.css";
import "@fontsource/noto-sans/500.css";
import "@fontsource/noto-sans/700.css";

const logger = getLogger();

logger.warn(`App version is ${window.appConfig.APP_VERSION}`);
logger.warn("Environment", getUA);

if (process.env.NODE_ENV !== "production") {
  logger.warn("Not in production mode");
}

window.addEventListener("orientationchange", () => {
  setTimeout(() => {
    window.scroll(0, 1);
  }, 500);
});

window.addEventListener("resize", () => {
  setTimeout(() => {
    window.scroll(0, 1);
  }, 500);
});

window.addEventListener("visibilitychange", (event) => {
  if (document.visibilityState === "hidden") {
    toast.dismiss();
  }
});

const onUnloadCleanup = () => {
  try {
    stopMemoryMonitor();
    logCollectorFlushMemoryBufferToDb();
  } catch (e) {}
};

window.addEventListener("unload", () => {
  logger.info("Unload-event");
  onUnloadCleanup();
});

window.addEventListener("error", (event: ErrorEvent) => {
  logger.error("Error (event): ", event);
  logger.error("Error (stack): ", event.error.stack);
  onUnloadCleanup();
});

window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
  logger.error("Error (unhandledrejection): ", event);
  onUnloadCleanup();
});

window.onerror = (errorMsg, url, lineNumber, columnNumber, error) => {
  logger.error("Error (window.onerror): ", errorMsg);
  logger.error("Error (window.onerror): ", error);
  onUnloadCleanup();
  return false;
};

// Get join link from query string
const params = new URLSearchParams(window.location.search);
const meetingId = params.get("meetingId");

if (meetingId) {
  window.localStorage.setItem(localStorageKeys.JOIN_LINK, meetingId);
}

ReactDOM.render(
  <Provider store={getStore()}>
    <RouterComponent />
    <ToastContainer
      autoClose={3000}
      position={toast.POSITION.TOP_RIGHT}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      closeOnClick
      hideProgressBar={true}
      newestOnTop={true}
    />
  </Provider>,
  document.getElementById("root")
);
