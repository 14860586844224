import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import styles from "../Messages.module.scss";
import { test } from "utils/helpers";
import { Typography } from "components";

export const MAP_ID = "map_";
export const handleClickOnMap = (geoUri: string | undefined) => {
  const geo = geoUri ? geoUri.replace("geo:", "").split(",") : null;
  if (geo && geo.length === 2)
    window.open(`https://maps.google.com?q=${geo[0]},${geo[1]}`, "_blank", "noopener,noreferrer");
};

const Map = ({ geoUri, body, imageSrc }: { geoUri: string | undefined; body: string; imageSrc?: string }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className={styles.messageEvent} {...test("MESSAGE_EVENT_MAP")}>
      <img
        id={`map_${geoUri}`}
        src={imageSrc ?? ""}
        alt={body}
        data-attribute="mxImages"
        onClick={(e) => handleClickOnMap(geoUri)}
        style={{ cursor: "pointer", marginBottom: "5px" }}
      />
      <Typography
        disableTooltip={true}
        sx={{
          fontSize: "12px",
          lineHeight: "normal",
          color: theme.palette.text.secondary,
          textAlign: "right",
        }}
      >
        {t("CLICK_TO_VIEW_LOCATION")}
      </Typography>
    </div>
  );
};

export default Map;
