import MessageEvent from "./MessageEvent";
// import MemberEvent from './MemberEvent'
import { useLocalStorage } from "hooks";
import {
  shouldFormContinuation,
  shouldFormContinuationByDate,
  isLastFormContinuation,
  isCurrentUser,
} from "matrix/message";
import { LocalEventTypeEnum, MatrixRoom } from "types/Matrix";
import { MatrixEvent } from "matrix-js-sdk";
import { localStorageKeys } from "utils/constants";
import { UCUser } from "types/UC";

const Message = ({
  prevEvent,
  mxEvent,
  nextEvent,
  room,
}: {
  prevEvent: MatrixEvent;
  mxEvent: MatrixEvent;
  nextEvent: MatrixEvent;
  room: MatrixRoom;
}) => {
  const [user] = useLocalStorage<UCUser>(localStorageKeys.CURRENT_USER);

  switch (mxEvent.getType()) {
    case LocalEventTypeEnum.RoomMessage:
      return (
        <MessageEvent
          mxEvent={mxEvent}
          content={mxEvent.getContent()}
          isRedacted={mxEvent.isRedacted() || mxEvent.isRedaction()}
          isDifferentDay={shouldFormContinuationByDate(prevEvent, mxEvent)}
          isCurrentUser={isCurrentUser(mxEvent, user?.user_id)}
          shouldContinue={shouldFormContinuation(prevEvent, mxEvent)}
          isLast={isLastFormContinuation(nextEvent, mxEvent)}
          room={room}
        />
      );
    case LocalEventTypeEnum.RoomMember:
      return null;
    // return <MemberEvent
    //             mxEvent={mxEvent}
    //             content={mxEvent.getContent()}
    //             prevContent={mxEvent.getPrevContent()}
    //         />;
    default:
      return null;
  }
};

export default Message;
