import InputBase from "@mui/material/InputBase";

const CustomInput = ({ ...props }) => {
  return (
    <InputBase
      sx={{
        height: !props.multiline ? "44px" : "100%",
        width: "100%",
        "& .MuiInputBase-input": {
          padding: "10px 15px",
        },
      }}
      {...props}
    />
  );
};

export default CustomInput;
