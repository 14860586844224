import { combineReducers } from "redux";

import appReducer from "./reducers/app";
import devices from "./reducers/devices";
import configReducer from "./reducers/config";
import callReducer from "./reducers/call";
import userReducer from "./reducers/user";
import theme from "./reducers/theme";
import auth from "./reducers/auth";
import matrix from "./reducers/matrix";
import presence from "./reducers/presence";
import sip from "./reducers/sip";
import pbx from "./reducers/pbx";
import provisioningReducer from "./reducers/provisioning";

const rootReducer = combineReducers({
  matrix,
  app: appReducer,
  devices,
  call: callReducer,
  user: userReducer,
  config: configReducer,
  theme,
  auth,
  provisioning: provisioningReducer,
  presence,
  sip,
  pbx,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
