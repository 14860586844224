import { CalendarEventAttendee } from "services/CalendarProvider/types/CalendarEvent";
import { LocalMatrixRoom, MappedRoomList } from "types/Matrix";

// ============================================
// pretty print bytes in Kb,Mb or Gb
// ============================================
const Kb: number = 1024;
const Mb: number = Kb * 1024;
const Gb: number = Mb * 1024;

export const formatBytes = (b: number, bytesOnly: boolean = false): string => {
  if (bytesOnly) return `${b}`;
  else if (Math.abs(b) < Kb) return `${b}b`;
  else if (Math.abs(b) < Mb) return `${(b / Kb).toFixed(1)}Kb`;
  else if (Math.abs(b) < Gb) return `${(b / Mb).toFixed(1)}Mb`;
  return `${(b / Gb).toFixed(1)}Gb`;
};

// ============================================
// generate a random sequence os ascii test
// ============================================
const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const generateString = (length: number): string => {
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// ============================================
// JSON stringify which can handle circular
//  referenes and other exceptions
// ============================================
const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: string, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export const JsonStringify = (obj: any): string => {
  try {
    return JSON.stringify(obj);
  } catch (error) {
    try {
      return "#/reduced " + JSON.stringify(obj, getCircularReplacer());
    } catch (error) {
      return `#/error: ${error}`;
    }
  }
};

export const getMeetingParticipantsEmails = (attendees: CalendarEventAttendee[]): string[] => {
  const emails: string[] = [];
  if (attendees?.length > 0) {
    attendees.forEach((attendee) => {
      const email = attendee.emailAddress.address;
      emails.push(email);
    });
  }
  return emails;
};

export const getCurrentTimestamp = () => {
  return Date.now();
};

export const compareNumbers = (a: number, b: number) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  // a must be equal to b
  return 0;
};

export const getRoomFromRoomList = (roomList: MappedRoomList, roomId: string) => {
  let filteredRoom: LocalMatrixRoom | undefined;

  Object.keys(roomList).forEach((k) => {
    const result = roomList[k].find((p) => p.roomId === roomId);
    if (result) {
      filteredRoom = result;
      return;
    }
  });

  return filteredRoom;
};

// ============================================
// simplified & ts-ified & taken from:
// https://github.com/vovacodes/url-schemify/blob/master/url-schemify.js
// ============================================
export function schemify(url: string, scheme: string = "http") {
  const URL_SCHEME_REGEXP = /^((?:f|ht)tps?:)?\/\//;
  if (!URL_SCHEME_REGEXP.test(url)) {
    url = scheme ? scheme + "://" + url : "//" + url;
  }
  return url;
}

/**
 * Checks if an element has overflow content.
 * @param element - The HTML element to check for overflow.
 * @returns True if the element scrollHeight is bigger than clientHeight.
 */
export const checkOverflow = (element: HTMLElement): boolean => {
  return element.scrollHeight > element.clientHeight;
};
