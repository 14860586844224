import React from "react";
import { TemplateKey, TemplateType } from "types/UC";

const getImgElement = (icon: string | JSX.Element) => {
  return typeof icon === "string" ? <img src={icon} alt="" /> : icon;
};

export const templates: TemplateType = {
  [TemplateKey.default]: (data) => {
    return (
      <div className="notification-content default">
        {getImgElement(data.icon)}
        <p>{data.message}</p>
      </div>
    );
  },
  [TemplateKey.banner]: (data) => {
    return (
      <div className="notification-content banner">
        <div className="notification-icon">{getImgElement(data.icon)}</div>
        <div className="notification-body">
          <div className="notification-title">
            <span>{data.title}</span>
          </div>
          <div className="notification-message">
            <span>{data.message}</span>
          </div>
        </div>
      </div>
    );
  },
  [TemplateKey.invite]: (data) => {
    return (
      <div className="notification-content invite">
        <div className="notification-body">
          <div className="notification-title">
            <span>{data.title}</span>
          </div>
          <div className="notification-message">
            <span>{data.message}</span>
          </div>
        </div>
      </div>
    );
  },
};
