import React, { useEffect, useState } from "react";
import styles from "../MeetingSideBar/MeetingSideBar.module.scss";
import Avatar from "components/_shared/Avatar";
import { UserKeycloakData } from "services/ProvisioningApiProvider/types/Provisioning";
import { useSelector } from "react-redux";
import { RootState } from "store/root-reducer";
import useMatrixAvatar from "hooks/useMatrixAvatar";
import useLocalStorage from "hooks/useLocalStorage";
import { PresenceStates } from "types/Presence";
import { Typography } from "@mui/material";
import { localStorageKeys } from "utils/constants";

const MeetingParticipant = ({ participantInfo }: { participantInfo: UserKeycloakData }) => {
  const { getUserProfileAvatar } = useMatrixAvatar();
  const [currentUser] = useLocalStorage(localStorageKeys.CURRENT_USER);
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [status, setStatus] = useState<string>(PresenceStates.offline);

  const onOtherPresenceChange = useSelector((state: RootState) => state.presence.onOtherPresenceChange);

  useEffect(() => {
    setName(`${participantInfo.firstName} ${participantInfo.lastName}`);
    if (participantInfo.presence) {
      const { avatarUrl, status } = participantInfo.presence;
      setAvatarUrl(avatarUrl ?? "");
      setStatus(status ?? PresenceStates.offline);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantInfo]);

  useEffect(() => {
    if (!onOtherPresenceChange) return;

    const updateParticipantAvatar = async (userId: string) => {
      const avatarUrl = await getUserProfileAvatar(userId);
      setAvatarUrl(avatarUrl ? avatarUrl : "");
    };
    //@ts-ignore
    const userId: string = `@${participantInfo.username}:${currentUser?.home_server}`;

    if (onOtherPresenceChange && onOtherPresenceChange.userId === userId) {
      const { status } = onOtherPresenceChange;
      setStatus(status ? status : PresenceStates.offline);
      updateParticipantAvatar(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOtherPresenceChange]);

  return (
    <>
      <div className={`${styles.participant}`}>
        <Avatar name={name} src={avatarUrl} size="46" status={status} />
        <Typography variant="body1" sx={{ marginLeft: "14px !important" }}>
          {name}
        </Typography>
      </div>
    </>
  );
};

export default MeetingParticipant;
