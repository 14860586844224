import { useAppSelector } from "store/hooks";
import { useMatrixAvatar, useMatrixRooms } from "hooks";
import { MatrixUserProvider } from "services/MatrixAPIProvider";
import { Avatar } from "components";
import { getProperCase } from "utils/helpers";
import { CallMediaType } from "types/UC";

const Custom = () => {
  const { getRoomMemberAvatar, getMatrixImage } = useMatrixAvatar();
  const { getRoom } = useMatrixRooms();
  const { getUsers } = MatrixUserProvider();

  const participantsInfo = useAppSelector((state) => state.call.participantsInfo);
  const callParticipants = useAppSelector((state) => state.matrix.callParticipants);

  const getAvatarComponent = () => {
    return Avatar;
  };

  // Gets the matrix avatar of participant
  const getMatrixUserAvatar = (participant: { name: string }, roomId?: string | null) => {
    const users = getUsers();
    const matrixUser = users.find((user) => user.displayName?.toLowerCase() === participant.name?.toLowerCase());
    // validates if the user exists on matrix
    if (matrixUser) {
      const member = roomId ? getRoom(roomId)?.getMember(matrixUser.userId) : null;
      // validates if the user is a member of the room, and get the avatar
      if (member) return getRoomMemberAvatar(roomId!, matrixUser.userId);
      // if the user is not a member of the room, need to use the user from matrix (even if sometimes is not the right avatar)
      else return getMatrixImage(matrixUser.avatarUrl);
    } else return "";
  };

  // Gets the avatar from the participanstInfo. In not have info, gets the matrix avatar
  const mapUserAvatar = (participant: { name: string }, roomId?: string | null) => {
    let avatarUrl = "";
    if (participantsInfo && participantsInfo.length > 0) {
      const participantInfo = participantsInfo.find(
        (info) => info.displayName?.toLowerCase() === participant.name?.toLowerCase()
      );
      if (participantInfo) {
        avatarUrl = participantInfo.avatarUrl ?? "";
      } else {
        const matrixAvatar = getMatrixUserAvatar(participant, roomId);
        avatarUrl = matrixAvatar ?? "";
      }
    } else {
      const matrixAvatar = getMatrixUserAvatar(participant, roomId);
      avatarUrl = matrixAvatar ?? "";
    }

    return avatarUrl;
  };

  const getExtData = async () => {
    const tiles = Array<any>();

    for (const info of callParticipants) {
      const { isLocal, name, userId, type, avatarUrl } = info;
      const localParticipant = isLocal;
      info.name = name ?? "Call Participant";
      const tile = {
        name: getProperCase(name),
        title: getProperCase(name),
        id: localParticipant ? "self_view" : userId,
        type:
          type === CallMediaType.audio
            ? CallMediaType.audio
            : localParticipant
              ? CallMediaType.localCamera
              : CallMediaType.remoteCamera,
        active: localParticipant ? false : true,
        isSelfView: localParticipant,
        isMatrixTile: true,
        avatarUrl: avatarUrl ?? "",
      };
      tiles.push(tile);
    }
    return tiles;
  };

  return {
    getAvatarComponent,
    mapUserAvatar,
    getExtData,
  };
};

export default Custom;
