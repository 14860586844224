import { eventChannel, buffers } from "redux-saga";
import { put, call, takeLatest, all, select, take } from "redux-saga/effects";
import { getMatrixAPIProvider } from "services/MatrixAPIProvider";
import * as actionTypes from "../actions/types/matrix";
import { matrixActions } from "../actions/matrix";
import { presenceActions } from "../actions/presence";
import * as deviceActions from "../actions/devices";
import { callActions } from "../actions/call";
import { forwardTo } from "utils/helpers";
import { CallMediaType } from "types/UC";
import { getHttpUriForMxc } from "matrix-js-sdk";
import { CallEvent, CallType, CallState } from "matrix-js-sdk/lib/webrtc/call";
import { getLogger } from "logger/appLogger";

const matrixProvider = getMatrixAPIProvider();

const logger = getLogger("redux");

function* createMatrixCallClient(action) {
  try {
    yield put(matrixActions.setMatrixCallRoomId(action.opts.roomId));
    yield put(matrixActions.setParticipants(action.opts.participants));
    const callClient = yield matrixProvider.createMatrixCallClient(action.opts);
    yield put(matrixActions.subscribeOnMatrixCallState(callClient));
    yield put(
      matrixActions.placeMatrixCall({
        type: action.opts.type,
        data: action.opts,
      })
    );
    yield take(actionTypes.PLACE_MATRIX_CALL_SUCCEEDED);
    yield put(matrixActions.playMatrixCallRingtone());
    const devOpts = {
      speakerMuted: false,
      microMuted: false,
      videoMuted: action.opts.type !== CallType.Video,
    };
    yield put(matrixActions.setDeviceSettings(devOpts));
    yield put({
      type: actionTypes.CREATE_MATRIX_CALL_CLIENT_SUCCEEDED,
      callClient,
    });
  } catch (e) {
    yield put({
      type: actionTypes.CREATE_MATRIX_CALL_CLIENT_FAILED,
      message: e.message,
    });
  }
}

function* incomingMatrixCall(action) {
  try {
    const callClient = yield matrixProvider.incomingCallInvite(action.callClient);
    yield put(matrixActions.subscribeOnMatrixCallState(callClient));
    const devOpts = {
      speakerMuted: false,
      microMuted: false,
      videoMuted: callClient.type !== CallType.Video,
    };
    yield put(matrixActions.setDeviceSettings(devOpts));
  } catch (e) {
    logger.error(`matrix-saga: Error in incomingMatrixCall - ${e.message}`);
  }
}

function* placeMatrixScreenShare() {
  try {
    const matrixSelectedShareStream = yield matrixProvider.placeScreenSharingCall();
    if (matrixSelectedShareStream) {
      const opts = {
        transitionName: "start-sharing",
      };
      yield put(presenceActions.changePresence(opts));
      yield put({
        type: actionTypes.PLACE_MATRIX_SCREENSHARE_SUCCEEDED,
        matrixSelectedShareStream: matrixSelectedShareStream,
      });
    } else {
      yield put({
        type: actionTypes.PLACE_MATRIX_SCREENSHARE_CANCELED,
      });
    }
  } catch (e) {
    yield put({
      type: actionTypes.PLACE_MATRIX_SCREENSHARE_FAILED,
      message: e.message,
    });
  }
}

function* endScreenSharingCall() {
  try {
    yield matrixProvider.endScreenSharingCall();
    const opts = {
      transitionName: "stop-sharing",
    };
    yield put(presenceActions.changePresence(opts));
    yield put({
      type: actionTypes.STOP_MATRIX_SCREENSHARE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.STOP_MATRIX_SCREENSHARE_FAILED,
      message: e.message,
    });
  }
}

function* placeMatrixCall(action) {
  try {
    const callClient = yield matrixProvider.placeMatrixCall(action.opts);
    const opts = {
      transitionName: "outgoing-call",
    };
    yield put(presenceActions.changePresence(opts));
    yield put({
      type: actionTypes.PLACE_MATRIX_CALL_SUCCEEDED,
      callClient,
    });
  } catch (e) {
    yield put({
      type: actionTypes.PLACE_MATRIX_CALL_FAILED,
      message: e.message,
    });
  }
}

function* hangupMatrixCall() {
  try {
    const hasActiveMatrixCall = yield select((state) => state.matrix.hasActiveMatrixCall);
    const matrixCallClient = yield select((state) => state.matrix.matrixCallClient);

    if (!hasActiveMatrixCall || !matrixCallClient) return;
    yield matrixProvider.hangupMatrixCall();
    const opts = {
      transitionName: "exit-call",
    };
    yield put(presenceActions.changePresence(opts));
    yield put({
      type: actionTypes.HANGUP_MATRIX_CALL_SUCCEEDED,
    });

    const devOpts = {
      speakerMuted: true,
      microMuted: true,
      videoMuted: true,
    };
    yield put(matrixActions.setDeviceSettings(devOpts));
  } catch (e) {
    yield put({
      type: actionTypes.HANGUP_MATRIX_CALL_FAILED,
      message: e.message,
    });
  }
}

function* answerMatrixCall(action) {
  try {
    const { incomingMatrixCallList } = action.opts;
    yield all(
      incomingMatrixCallList
        .filter((invite) => invite.callId !== action.opts.matrixCall.callId)
        .map((invite) => put(matrixActions.rejectMatrixCall(invite)))
    );
    yield call(forwardTo, action.opts.matrixCall.roomId);
    yield put(matrixActions.setMatrixCallRoomId(action.opts.matrixCall.roomId));
    yield put(matrixActions.setParticipants(action.opts.participants));
    const callClient = yield matrixProvider.answerCallInvite(action.opts);
    const opts = {
      transitionName: "incoming-call",
    };
    yield put(presenceActions.changePresence(opts));
    yield put({
      type: actionTypes.ANSWER_MATRIX_CALL_SUCCEEDED,
      callClient,
    });

    // need to ensure call type
    if (!action.opts.type) {
      action.opts.type = CallMediaType.audio;
    }
  } catch (e) {
    yield put({
      type: actionTypes.ANSWER_MATRIX_CALL_FAILED,
      message: e.message,
    });
  }
}

function* rejectMatrixCall(action) {
  try {
    yield matrixProvider.rejectCallInvite(action.callClient);
    yield put({
      type: actionTypes.REJECT_MATRIX_CALL_SUCCEEDED,
      callClient: action.callClient,
    });
  } catch (e) {
    yield put({
      type: actionTypes.REJECT_MATRIX_CALL_FAILED,
      message: e.message,
    });
  }
}

function* muteMatrixCallAudio(action) {
  try {
    yield matrixProvider.setMicrophoneMuted(action.muted);
    yield put({
      type: actionTypes.MATRIX_CALL_MUTE_AUDIO_SUCCEEDED,
      muted: action.muted,
    });
  } catch (e) {
    yield put({
      type: actionTypes.MATRIX_CALL_MUTE_AUDIO_FAILED,
      message: e.message,
    });
  }
}

function* muteMatrixCallVideo(action) {
  try {
    yield matrixProvider.setLocalVideoMuted(action.muted);
    yield put({
      type: actionTypes.MATRIX_CALL_MUTE_VIDEO_SUCCEEDED,
      muted: action.muted,
    });
  } catch (e) {
    yield put({
      type: actionTypes.MATRIX_CALL_MUTE_VIDEO_FAILED,
      message: e.message,
    });
  }
}

function* muteMatrixCallSpeaker(action) {
  try {
    yield matrixProvider.setSpeakerMuted(action.muted);
    yield put({
      type: actionTypes.MATRIX_CALL_MUTE_SPEAKER_SUCCEEDED,
      muted: action.muted,
    });
  } catch (e) {
    yield put({
      type: actionTypes.MATRIX_CALL_MUTE_SPEAKER_FAILED,
      message: e.message,
    });
  }
}

function* playMatrixCallRingTone(action) {
  try {
    yield matrixProvider.playMatrixCallRingTone();
    yield put({
      type: actionTypes.PLAY_MATRIX_CALL_RINGTONE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.PLAY_MATRIX_CALL_RINGTONE_FAILED,
      message: e.message,
    });
  }
}

function* stopMatrixCallRingTone(action) {
  try {
    yield matrixProvider.stopMatrixCallRingTone();
    yield put({
      type: actionTypes.STOP_MATRIX_CALL_RINGTONE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.STOP_MATRIX_CALL_RINGTONE_FAILED,
      message: e.message,
    });
  }
}

function* uploadMatrixAvatar(action) {
  try {
    const { data } = action;
    let url = "";
    let matrixUrl = "";
    if (data.file) {
      const client = matrixProvider.getClient();
      const res = yield matrixProvider.uploadContent(data.file, data.opts);
      matrixUrl = res.content_uri;
      url = getHttpUriForMxc(client.baseUrl, matrixUrl);
    }
    yield matrixProvider.setAvatar(matrixUrl);
    const currentUser = yield select((state) => state.matrix.currentUser);
    currentUser.avatarUrl = url;
    yield put(matrixActions.setMatrixCurrentUser(currentUser));
    yield put(presenceActions.updatePresenceAvatar(url));
    yield;
    yield put({
      type: actionTypes.MATRIX_UPLOAD_AVATAR_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.MATRIX_UPLOAD_AVATAR_FAILED,
      message: "Uploaded file may be too large.",
    });
  }
}

function* redirectToAdhocConf(action) {
  try {
    const { isRedirectToAdhocConf, roomId } = action;

    // will store matrix devices state on call devices state
    // this is used for group chat conf - when we hangup the P2P call and join the group chat conf
    // we want to remain the state of devices in the conf
    if (isRedirectToAdhocConf) {
      const isCameraTurnedOff = yield select((state) => state.matrix.matrixVideoMuted);
      const isMicrophoneTurnedOff = yield select((state) => state.matrix.matrixAudioMuted);
      const isSpeakerTurnedOff = yield select((state) => state.matrix.matrixSpeakerMuted);

      if (!isCameraTurnedOff) yield put(deviceActions.cameraTurnOn());
      if (!isMicrophoneTurnedOff) yield put(deviceActions.microphoneTurnOn());
      if (!isSpeakerTurnedOff) yield put(deviceActions.speakerTurnOn());

      if (roomId) yield put(callActions.setCallRoomId(roomId));
    }
  } catch (e) {
    logger.error(`matrix-saga: Error in redirectToAdhocConf - ${e.message}`);
  }
}

function* getCurrentUserProfileInfo() {
  try {
    const currentUser = yield select((state) => state.matrix.currentUser);
    let newCurrentUser = JSON.parse(JSON.stringify(currentUser));

    // if currentUser is null, get first the current user from matrix
    if (!currentUser) {
      const user = yield matrixProvider.getCurrentUser();
      newCurrentUser = JSON.parse(JSON.stringify(user));
    }

    if (newCurrentUser) {
      const client = matrixProvider.getClient();
      const profInfo = yield matrixProvider.getUserProfileInfo(newCurrentUser.userId);

      if (profInfo) {
        newCurrentUser.displayName = profInfo.displayname;
        //@ts-ignore
        const baseUrl = client.baseUrl;
        const url = profInfo.avatar_url ? getHttpUriForMxc(baseUrl, profInfo.avatar_url) : "";
        newCurrentUser.avatarUrl = url ?? "";
        yield put({
          type: actionTypes.SET_CURRENTUSER,
          currentUser: newCurrentUser,
        });
      }
    }
  } catch (e) {
    logger.error(`matrix-saga: Error in getCurrentUserProfileInfo - ${e.message}`);
  }
}

function createCallStateChannel(callClient) {
  return eventChannel((emit) => {
    matrixProvider.subscribeOnMatrixCallState(callClient, (...args) => {
      emit(...args);
    });
    return () => {
      matrixProvider.unsubscribeOnMatrixCallState(callClient);
    };
  }, buffers.expanding());
}

function* subscribeOnMatrixCallState(action) {
  try {
    const eventChannel = yield call(createCallStateChannel, action.callClient);
    yield takeLatest(eventChannel, handleCallStateChanges, action);

    yield put({
      type: actionTypes.SUBSCRIBE_MATRIX_CALL_STATE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.SUBSCRIBE_MATRIX_CALL_STATE_FAILED,
      message: e.message,
    });
  }
}

function* unsubscribeOnMatrixCallState(action) {
  try {
    yield matrixProvider.unsubscribeOnMatrixCallState(action.eventEmitter);
    yield put({
      type: actionTypes.UNSUBSCRIBE_MATRIX_CALL_STATE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.UNSUBSCRIBE_MATRIX_CALL_STATE_FAILED,
      message: e.message,
    });
  }
}

function* handleCallStateChanges(...args) {
  const [, state] = args;
  const [eventName, currentState, callClient] = state;
  logger.debug(`matrix-saga: P2P Call | Matrix Call State Changes: eventName: ${eventName}, ${currentState}`);
  switch (eventName) {
    case CallEvent.State:
      switch (currentState) {
        case CallState.Connecting:
          matrixProvider.createDataChannel();
          break;
        case CallState.Connected:
          yield put(matrixActions.stopMatrixCallRingtone());
          break;
        case CallState.Ended:
          yield put(matrixActions.stopMatrixCallRingtone());
          break;
        default:
          break;
      }
      yield put(matrixActions.updateMatrixCallState(currentState));
      return;
    case CallEvent.Hangup:
      const matrixCallClient = callClient ? callClient : currentState;
      yield put(matrixActions.updateMatrixCallState(eventName));
      yield matrixProvider.deleteMatrixCallClient(matrixCallClient);
      yield put(matrixActions.deleteMatrixCallClient(matrixCallClient));
      yield put(matrixActions.deleteIncomingMatrixCall(matrixCallClient));
      yield put(matrixActions.unsubscribeOnMatrixCallState(matrixCallClient));
      const opts = {
        transitionName: "exit-call",
      };
      yield put(presenceActions.changePresence(opts));
      const isElectron = window.navigator.userAgent.indexOf("Electron") !== -1;
      if (isElectron) {
        window.ipcRenderer.send("EndCall", matrixCallClient.callId);
      }
      const devOpts = {
        speakerMuted: true,
        microMuted: true,
        videoMuted: true,
      };
      yield put(matrixActions.setDeviceSettings(devOpts));
      return;
    case CallEvent.FeedsChanged:
      const [, feeds] = state;
      yield matrixProvider.updateCallElements(feeds);
      return;
    case CallEvent.DataChannel:
      yield matrixProvider.handleDataChannelEvents(state[1]);
      return;
    case CallEvent.Error:
      yield put(matrixActions.stopMatrixCallRingtone());
      return;
    default:
      return;
  }
}

function* subscribeOnMatrixEvent(action) {
  try {
    const eventChannel = yield call(createEventChannel, action);
    yield takeLatest(eventChannel, handleEventChanges, action);

    yield put({
      type: actionTypes.SUBSCRIBE_MATRIX_EVENT_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.SUBSCRIBE_MATRIX_EVENT_FAILED,
      message: e.message,
    });
  }
}

function* unsubscribeOnMatrixEvent(action) {
  try {
    yield matrixProvider.unsubscribeOnMatrixEvent(action.eventName);
    yield put({
      type: actionTypes.UNSUBSCRIBE_MATRIX_EVENT_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: actionTypes.UNSUBSCRIBE_MATRIX_EVENT_FAILED,
      message: e.message,
    });
  }
}

function createEventChannel(action) {
  return eventChannel((emit) => {
    matrixProvider.subscribeOnMatrixEvent(action.eventName, (...args) => {
      emit(...args);
    });
    return () => {
      matrixProvider.unsubscribeOnMatrixEvent(action.eventName);
    };
  }, buffers.expanding());
}

function* handleEventChanges(...args) {
  const [, state] = args;
  yield put(matrixActions.updateMatrixEvent(state));
}

function* actionWatcher() {
  yield takeLatest(actionTypes.PLAY_MATRIX_CALL_RINGTONE, playMatrixCallRingTone);
  yield takeLatest(actionTypes.STOP_MATRIX_CALL_RINGTONE, stopMatrixCallRingTone);
  yield takeLatest(actionTypes.CREATE_MATRIX_CALL_CLIENT, createMatrixCallClient);
  yield takeLatest(actionTypes.PLACE_MATRIX_CALL, placeMatrixCall);
  yield takeLatest(actionTypes.PLACE_MATRIX_SCREENSHARE, placeMatrixScreenShare);
  yield takeLatest(actionTypes.STOP_MATRIX_SCREENSHARE, endScreenSharingCall);
  yield takeLatest(actionTypes.HANGUP_MATRIX_CALL, hangupMatrixCall);
  yield takeLatest(actionTypes.ANSWER_MATRIX_CALL, answerMatrixCall);
  yield takeLatest(actionTypes.REJECT_MATRIX_CALL, rejectMatrixCall);
  yield takeLatest(actionTypes.MATRIX_CALL_MUTE_AUDIO, muteMatrixCallAudio);
  yield takeLatest(actionTypes.MATRIX_CALL_MUTE_VIDEO, muteMatrixCallVideo);
  yield takeLatest(actionTypes.MATRIX_CALL_MUTE_SPEAKER, muteMatrixCallSpeaker);
  yield takeLatest(actionTypes.INCOMING_MATRIX_CALL, incomingMatrixCall);
  yield takeLatest(actionTypes.MATRIX_UPLOAD_AVATAR, uploadMatrixAvatar);
  yield takeLatest(actionTypes.SUBSCRIBE_MATRIX_CALL_STATE, subscribeOnMatrixCallState);
  yield takeLatest(actionTypes.UNSUBSCRIBE_MATRIX_CALL_STATE, unsubscribeOnMatrixCallState);
  yield takeLatest(actionTypes.SUBSCRIBE_MATRIX_EVENT, subscribeOnMatrixEvent);
  yield takeLatest(actionTypes.UNSUBSCRIBE_MATRIX_EVENT, unsubscribeOnMatrixEvent);
  yield takeLatest(actionTypes.SET_REDIRECT_TO_ADHOC_CONF, redirectToAdhocConf);
  yield takeLatest(actionTypes.GET_CURRENTUSER_PROFILEINFO, getCurrentUserProfileInfo);
}

export default actionWatcher;
