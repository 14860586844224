import React from "react";
import { Accordion } from "components";
import MicrophoneSelectList from "containers/MediaSettings/selects/MicrophoneSelectList";
import SpeakerSelectList from "containers/MediaSettings/selects/SpeakerSelectList";
import TestSound from "containers/TestSound";
import { useTranslation } from "react-i18next";

import "./DialpadSettings.scss";

const DialpadSettings = () => {
  const { t } = useTranslation();
  return (
    <Accordion
      id={"accordion-dialpad-devices"}
      defaultExpanded
      title={t("DEVICES")}
    >
      <div className="device-settings">
        <SpeakerSelectList />
        <TestSound />
        <MicrophoneSelectList showEnergyLevel={true} />
      </div>
    </Accordion>
  );
};

export default DialpadSettings;
