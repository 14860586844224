import { AnswerRule } from "./types/AnswerRule";
import { SubscriberInformation } from "./types/SubscriberInformation";
import { PBXUserConfig, PBXUserToken, PbxCallQueueResult } from "./types/PBXUserConfig";
import { PBXServerApiProvider } from "./Providers/PBXServerApiProvider";
import { isEmpty, asyncWaitWithTimer } from "../../utils/helpers";
import { CallHistoryEntries } from "./types/CallHistoryEntries";
import { getLogger } from "logger/appLogger";
import getStore from "store/store";
import * as actionTypes from "store/actions/types/pbx";
import { DEVICES } from "types/UC";
import { PbxMergeCallsResult } from "./types/PbxUsers";
import { PbxCallInfo, PbxEventListenerType, PbxEventSubscription } from "./types/EventListener";

const logger = getLogger("pbx");

export class PBXServerApiClient {
  module = "PbxServerApiClient";
  // singleton
  private static _instance: PBXServerApiClient;
  static get Instance(): PBXServerApiClient {
    if (!this._instance) {
      this._instance = new PBXServerApiClient();
    }
    return this._instance;
  }

  // constants
  private readonly _emptyPbxUserToken: PBXUserToken = {
    access_token: "",
    refresh_token: "",
    expires_in: 0,
  };

  private readonly _emptyPbxUserConfig: PBXUserConfig = {
    hostedPbxEnabled: false,
    hostedPbxConfig: undefined,
  };

  private readonly _errorPbxNotInitialized = "PBX not configured/initialized";

  private readonly _myDevice: number = DEVICES.Web;

  // fields
  private _provider: PBXServerApiProvider = PBXServerApiProvider.Instance;
  private _pbxUserConfig: PBXUserConfig = this._emptyPbxUserConfig;
  private _pbxUserToken: PBXUserToken = this._emptyPbxUserToken;
  private _isInitialized!: Promise<boolean>;
  private _periodicRun: boolean = true;

  // properties
  private get Username(): string {
    return this._pbxUserConfig.hostedPbxEnabled && this._pbxUserConfig.hostedPbxConfig
      ? this._pbxUserConfig.hostedPbxConfig.apiUsername
      : "";
  }

  // get user from username
  private get User(): string {
    return this.Username.split("@", 1)[0];
  }

  // initialized?
  private get IsPBXInitialized(): boolean {
    return (
      this._pbxUserConfig.hostedPbxEnabled &&
      this._pbxUserConfig.hostedPbxConfig !== undefined &&
      this._pbxUserToken &&
      !isEmpty(this._pbxUserToken.access_token) &&
      !isEmpty(this._pbxUserToken.refresh_token) &&
      !isEmpty(this.Username) &&
      !isEmpty(this.User)
    );
  }

  // constructor
  private constructor() {
    // async method, not waitable in constructor
    // therefore a constructor has completed only when _isInitialized promise resolved
    this.initPbxUserToken();

    // this is safety guard because a "await this._isInitialized" is a no-error
    // flythrough for _isInitialized still undefined
    if (!this._isInitialized) {
      throw new Error(`${this.module} init failed`);
    }
  }

  // init - read only once at startup!
  // for all runtime changes to pbx config a logout/login is required
  private async initPbxUserToken() {
    if (!this._isInitialized) {
      this._isInitialized = new Promise(async (resolve, reject) => {
        try {
          this._pbxUserConfig = await this._provider.getPBXUserConfig(this._myDevice);
          this._pbxUserToken =
            this._pbxUserConfig.hostedPbxEnabled && this._pbxUserConfig.hostedPbxConfig
              ? this._pbxUserConfig.hostedPbxConfig
              : this._emptyPbxUserToken;
          if (this._pbxUserConfig.hostedPbxConfig !== undefined) {
            this._pbxUserConfig.hostedPbxConfig.userExtension = this.User;
          }

          // dispatch to state
          getStore().dispatch({
            type: actionTypes.GET_PBX_CONFIG_SUCCEEDED,
            config: this._pbxUserConfig,
          });

          // kickstart periodic token refresher
          // no await!
          this.periodicRunner();

          logger.info(`${this.module}: Initialized PBX=${this.IsPBXInitialized}`);

          resolve(this.IsPBXInitialized);
        } catch (error) {
          // do not reject, leave unresolved only
          logger.error(`${this.module}: Error in initPbxUserToken - ${error}`);
        }
      });
    }
  }

  // map token expires time to interval
  private calculateSleepTimeFromTokenExpiresSeconds(expires: number): number {
    let timeoutTimeSec =
      expires && expires > 0
        ? Math.max((expires * 2) / 3, 5) // 2/3 of expires time but not less than 5
        : 60; // default
    return timeoutTimeSec;
  }

  private async readPBXRefreshToken() {
    try {
      this._pbxUserToken = await this._provider.postPBXRefreshToken(this._pbxUserToken.refresh_token);
      logger.debug(`${this.module}: Refreshed pbx token, expires in ${this._pbxUserToken.expires_in}`);
    } catch (error) {
      logger.error(`${this.module} - Error in readPBXRefreshToken - ${error}`);
    }
  }

  // periodic token refresher
  private async periodicRunner() {
    if (this.IsPBXInitialized)
      while (this._periodicRun) {
        const timeoutTimeSec = this.calculateSleepTimeFromTokenExpiresSeconds(this._pbxUserToken.expires_in);
        logger.debug(`${this.module}: Sleep until pbx token refresh: ${timeoutTimeSec} sec`);
        await asyncWaitWithTimer(timeoutTimeSec * 1000);
        await this.readPBXRefreshToken();
      }
  }

  // API
  public async getPBXUserConfig(deviceType: number = DEVICES.Web): Promise<PBXUserConfig> {
    try {
      // NOTE: do not invoke getPBXUserConfig() on our device multiple times
      return deviceType === this._myDevice
        ? Promise.resolve(this._pbxUserConfig)
        : this._provider.getPBXUserConfig(deviceType);
    } catch (error) {
      logger.error(`${this.module}: Error in getPBXUserConfig: ${error}`);
      return Promise.reject();
    }
  }

  public async getAnswerRule(recursiveCall: boolean = false): Promise<AnswerRule[]> {
    try {
      if (await this._isInitialized) {
        return await this._provider.getAnswerRule(this._pbxUserToken.access_token, this.Username);
      } else {
        throw this._errorPbxNotInitialized;
      }
    } catch (error) {
      if (!recursiveCall && error?.status === 500) {
        await this.readPBXRefreshToken();
        return this.getAnswerRule(true);
      }
      logger.error(`${this.module}: Error in getAnswerRule: ${error}`);
      throw error;
    }
  }

  public async putAnswerRule(answerRule: AnswerRule, recursiveCall: boolean = false): Promise<AnswerRule> {
    try {
      if (await this._isInitialized) {
        return await this._provider.putAnswerRule(this._pbxUserToken.access_token, this.Username, answerRule);
      } else {
        throw this._errorPbxNotInitialized;
      }
    } catch (error) {
      if (!recursiveCall && error?.status === 500) {
        await this.readPBXRefreshToken();
        return this.putAnswerRule(answerRule, true);
      }
      logger.error(`${this.module}: Error in putAnswerRule: ${error}`);
      throw error;
    }
  }

  public async getSubscriberInformation(recursiveCall: boolean = false): Promise<SubscriberInformation[]> {
    try {
      if (await this._isInitialized) {
        return await this._provider.getSubscriberInformation(this._pbxUserToken.access_token, this.User);
      } else {
        throw this._errorPbxNotInitialized;
      }
    } catch (error) {
      if (!recursiveCall && error?.status === 500) {
        await this.readPBXRefreshToken();
        return this.getSubscriberInformation(true);
      }
      logger.error(`${this.module}: Error in getSubscriberInformation: ${error}`);
      throw error;
    }
  }

  public async getCallHistory(
    first: number,
    max: number,
    startDate: Date,
    endDate: Date,
    recursiveCall: boolean = false
  ): Promise<CallHistoryEntries> {
    try {
      if (await this._isInitialized) {
        return await this._provider.getCallHistory(
          this._pbxUserToken.access_token,
          this.Username,
          first,
          max,
          startDate,
          endDate
        );
      } else {
        throw this._errorPbxNotInitialized;
      }
    } catch (error) {
      if (!recursiveCall && error?.status === 500) {
        await this.readPBXRefreshToken();
        return this.getCallHistory(first, max, startDate, endDate, true);
      }
      logger.error(`${this.module}: Error in getCallHistory: ${error}`);
      throw error;
    }
  }

  private callIdMergeSuffixOutgoing: string = "xfer_term";
  private callIdMergeSuffixIncomiming: string = "xfer_orig";

  public async mergeCalls(
    incomingCallIds: string[],
    outgoingCallIds: string[],
    recursiveCall: boolean = false
  ): Promise<PbxMergeCallsResult> {
    try {
      if (await this._isInitialized) {
        const incomingCallIdsWithSuffix = incomingCallIds.map((id) => id + this.callIdMergeSuffixIncomiming);
        const outgoingCallIdsWithSuffix = outgoingCallIds.map((id) => id + this.callIdMergeSuffixOutgoing);
        const callsWithSuffix = [...incomingCallIdsWithSuffix, ...outgoingCallIdsWithSuffix];
        return await this._provider.mergeCalls(this._pbxUserToken.access_token, this.Username, callsWithSuffix);
      } else {
        throw this._errorPbxNotInitialized;
      }
    } catch (error) {
      if (!recursiveCall && error?.status === 500) {
        await this.readPBXRefreshToken();
        return this.mergeCalls(incomingCallIds, outgoingCallIds, true);
      }
      logger.error(`${this.module}: Error in mergeCalls: ${error}`);
      throw error;
    }
  }

  public async getCallQueues(search: string, recursiveCall: boolean = false): Promise<PbxCallQueueResult> {
    try {
      if (await this._isInitialized) {
        return await this._provider.getCallQueues(this._pbxUserToken.access_token, search);
      } else {
        throw this._errorPbxNotInitialized;
      }
    } catch (error) {
      if (!recursiveCall && error?.status === 500) {
        await this.readPBXRefreshToken();
        return this.getCallQueues(search, true);
      }
      logger.error(`${this.module}: Error in getCallQueues: ${error}`);
      throw error;
    }
  }

  public async getCallInfo(callId: string, recursiveCall: boolean = false): Promise<PbxCallInfo> {
    try {
      if (await this._isInitialized) {
        return await this._provider.getCallInfo(this._pbxUserToken.access_token, callId);
      } else {
        throw this._errorPbxNotInitialized;
      }
    } catch (error) {
      if (!recursiveCall && error?.status === 500) {
        await this.readPBXRefreshToken();
        return this.getCallInfo(callId, true);
      }
      logger.error(`${this.module}: Error in getCallInfo: ${error}`);
      throw error;
    }
  }

  public async postSubscription(
    pbxSubscriptionType: PbxEventListenerType,
    id: string,
    recursiveCall: boolean = false
  ): Promise<PbxEventSubscription> {
    try {
      if (await this._isInitialized) {
        return await this._provider.postSubscription(this._pbxUserToken.access_token, pbxSubscriptionType, id);
      } else {
        throw this._errorPbxNotInitialized;
      }
    } catch (error) {
      if (!recursiveCall && error?.status === 500) {
        await this.readPBXRefreshToken();
        return this.postSubscription(pbxSubscriptionType, id, true);
      }
      logger.error(`${this.module}: Error in postSubscription: ${error}`);
      throw error;
    }
  }
}
