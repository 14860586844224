export const INIT_APP = "APP::INIT";
export const INIT_SUCCEEDED = "APP::INIT_SUCCEEDED";
export const INIT_FAILED = "APP::INIT_FAILED";
export const GENERATE_LOGS = "APP::GENERATE_LOGS";
export const RESET_LOG_STATE = "APP::RESET_LOG_STATE";
export const GENERATE_LOGS_SUCCEEDED = "APP::GENERATE_LOGS_SUCCEEDED";
export const GENERATE_LOGS_FAILED = "APP::GENERATE_LOGS_FAILED";
export const CLEAN_GENERATE_LOGS_ERROR = "APP::CLEAN_GENERATE_LOGS_ERROR";
export const ENABLE_DEBUG_LOG_LEVEL = "APP::ENABLE_DEBUG_LOG_LEVEL";

export const NOTIFICATION_COUNT_CHANGE = "APP::NOTIFICATION_COUNT_CHANGE";
export const OUTLOOK_CALENDAR_EVENT = "APP::OUTLOOK_CALENDAR_EVENT";
export const GOOGLE_CALENDAR_EVENT = "APP::GOOGLE_CALENDAR_EVENT";
export const CALENDAR_UPDATE = "APP::CALENDAR_UPDATE";

export const PERMISSIONS_CHANGES_SUBSCRIBE = "APP::PERMISSIONS_CHANGES_SUBSCRIBE";
export const PERMISSIONS_CHANGES_SUBSCRIBE_SUCCEEDED = "APP::PERMISSIONS_CHANGES_SUBSCRIBE_SUCCEEDED";
export const PERMISSIONS_CHANGES_SUBSCRIBE_FAILED = "APP::PERMISSIONS_CHANGES_SUBSCRIBE_FAILED";

export const SET_JOIN_ROOM_URL = "APP::SET_JOIN_ROOM_URL";
