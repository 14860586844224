import SipUserAgent from "./SipUserAgent";
import {
  Web,
  Registerer,
  RegistererState,
  SessionState,
  Inviter,
  Invitation,
  RequestPendingError,
  URI,
  Session,
  LogLevel,
  EmitterImpl,
  SessionInviteOptions,
  InvitationAcceptOptions,
  Bye,
} from "sip.js";
import storage from "utils/storage";
import { CallMediaType, CallActionType, MediaStreamDevices, CallingState, MutedState } from "types/UC";
import { getLogger } from "logger/appLogger";
import { JsonStringify, compareNumbers } from "utils/utils";
import { sipUAPayload } from "store/types/sip";
import { SipDelegate, SipUserStates } from "./SipTypes";
import { isEmpty, isNumber } from "utils/helpers";
import { SessionDescriptionHandler } from "sip.js/lib/platform/web";
import { SIPRinger, Tones } from "./SIPRinger";
import { UiSipCall, UiSipCallInfo, MAX_SIP_CALLS, UiSipPeer, UiSipConferenceInfo } from "types/SIP";
import { stripCN } from "./SipUtils";
import { IncomingRequestMessage, IncomingResponse, OutgoingRequestDelegate } from "sip.js/lib/core";
import { isDebugLogger } from "logger/logger";

const log = getLogger("sip");

const logConnector = (level: LogLevel, category: string, label: string | undefined, content: string): void => {
  if (level === "log") level = "debug";
  log[level](`${category} => ${content}`);
};

type MediaStreamType = "receivers" | "senders";

class MediaPlayState {
  playing: boolean = false;
  startInProgress: boolean = false;
}

enum SipStatusCode {
  TemporarilyUnavailable = 480,
  BusyHere = 486,
  InternalServerError = 500,
  ServiceUnavailable = 503,
}

enum InviteSdpModifier {
  None = 0,
  Hold,
  Retrieve,
}

enum HoldRetrieveType {
  BySendOnlyRecvOnly = 0,
  ByInactive = 1,
}

enum CallActionToPerform {
  Refer,
}

const holdRetrieveType: HoldRetrieveType = HoldRetrieveType.ByInactive;
const SDP_ATTR_STREAM_DIRECTION_REGEX = new RegExp("a=(sendrecv|sendonly|recvonly|inactive)");
const TERMINATING_CALL_LIFETIME_MS = 2500;
const END_CALL_LIFETIME_MS = 2000;

/*
 * internal sip user representation
 */
class SipPeer {
  name: string;
  user: string;
  matrixUser: string;
  avatarUrl: string;

  constructor(uiSipPeer: UiSipPeer | undefined = undefined) {
    this.name = uiSipPeer?.name || "";
    this.user = uiSipPeer?.user || "";
    this.matrixUser = uiSipPeer?.matrixUser || "";
    this.avatarUrl = uiSipPeer?.avatarUrl || "";
  }

  toUiSipPeerPeer(): UiSipPeer {
    return {
      user: this.user,
      name: this.name,
      matrixUser: this.matrixUser || "",
      avatarUrl: this.avatarUrl || "",
    };
  }
}

/*
 * internal call representation
 */
class SipCall {
  id: string;
  active: boolean;
  held: boolean;
  heldTimestamp: number;
  outbound: boolean;
  callingState: CallingState;
  peer: SipPeer;
  local: SipPeer;
  via: SipPeer;
  conferencePeers: SipPeer[];
  callType: CallActionType;
  session: Session;
  mutedState: MutedState;
  visible: boolean;
  conferenceId: string | null;
  receivedBye: boolean;
  error: boolean;
  subscriptionId: string | null;
  groupCallBeforeConnect: boolean;

  constructor(id: string, session: Session, callType: CallActionType, outbound: boolean) {
    this.id = id;
    this.active = false;
    this.held = false;
    this.heldTimestamp = 0;
    this.outbound = outbound;
    this.callingState = CallingState.NONE;
    this.peer = new SipPeer();
    this.local = new SipPeer();
    this.via = new SipPeer();
    this.conferencePeers = [];
    this.callType = callType;
    this.session = session;
    this.mutedState = { microphone: false, camera: false, speaker: false };
    this.visible = true;
    this.conferenceId = null;
    this.receivedBye = false;
    this.error = false;
    this.subscriptionId = null;
    this.groupCallBeforeConnect = false;
  }

  toString(): string {
    return JsonStringify(this);
  }
}

type SipCallNullable = SipCall | null;

/*
 * Sip user wrapper
 */
export class SipUser {
  _options: sipUAPayload | undefined = undefined;

  // A user agent sends and receives requests using a `Transport`.
  _sipUserAgent: SipUserAgent | undefined = undefined;

  // A registerer registers a contact for an address of record (outgoing REGISTER)
  _sipUserRegisterer: Registerer | undefined = undefined;

  // Current active call session
  _activeCall: SipCallNullable = null;

  // all call sessions
  _allCalls: SipCall[] = [];

  //Delegate
  _delegate: SipDelegate = {};

  // Current state of the user
  _state: SipUserStates = SipUserStates.STOPPED;

  // Default Session Description Handler Options
  _sdhOptions = {
    iceGatheringTimeout: 1000,
  };

  _localMediaElement: HTMLMediaElement | null = null;
  _remoteMediaElement: HTMLMediaElement | null = null;

  _localPlay: MediaPlayState = new MediaPlayState();
  _remotePlay: MediaPlayState = new MediaPlayState();

  _ringer: SIPRinger = new SIPRinger();

  _mediaDevices = {
    savedLocalCamera: storage.getItem("savedLocalCamera") || "default",
    savedLocalMicrophone: storage.getItem("savedLocalMicrophone") || "default",
    savedLocalSpeaker: storage.getItem("savedLocalSpeaker") || "default",
  };

  // muted state
  _muted = false;
  module = "SipProvider";

  _restarting: boolean = false;

  _localPeer: SipPeer | null = null;

  constructor() {
    const msg = this.getMsg("constructor");
    log.info(`${msg}`);
    log.debug(`${msg}: ${JsonStringify(this._mediaDevices)}`);
  }

  getMsg(fn: string) {
    return `${this.module}: ${fn}`;
  }

  getOptions(): sipUAPayload | undefined {
    return this._options;
  }

  setLocalPeer(localPeer: UiSipPeer) {
    this._localPeer = new SipPeer(localPeer);
  }

  setActiveCall(sipCall: SipCallNullable, caller: string) {
    const msg = this.getMsg("setActiveCall");
    log.debug(`${msg}: ${sipCall ? sipCall.id : "null"} from '${caller}'`);
    if (this._activeCall) {
      this._activeCall.active = false;
    }
    this._activeCall = sipCall;
    if (this._activeCall) {
      this._activeCall.active = true;
      this.restoreMutedState();
    }
    this.updateSipCalls(`setActiveCall/${caller}`);
  }

  addCall(callId: string, session: Session, callType: CallActionType, outbound: boolean): SipCall {
    const msg = this.getMsg("addCall");
    log.debug(`${msg}: callId=${callId}`);
    if (isEmpty(callId)) {
      throw new Error(`${msg}: failed: Call-Id from sip-session is empty`);
    }
    if (!this.haveCall(callId)) {
      const sipCall = new SipCall(callId, session, callType, outbound);
      this._allCalls.push(sipCall);
      return sipCall;
    } else {
      throw new Error(`${msg}: failed: call ${callId} already exists`);
    }
  }

  removeCall(callId: string) {
    const msg = this.getMsg("removeCall");
    var index = this._allCalls.findIndex((s) => s.id === callId);
    if (index > -1) {
      this._allCalls.splice(index, 1);
    } else {
      log.error(`${msg}: failed: call ${callId} not found`);
    }
  }

  haveCall(callId: string) {
    return !!this._allCalls.find((s) => s.id === callId);
  }

  setCallVisible(callId: string, visible: boolean) {
    const sipCall = this._allCalls.find((s) => s.id === callId);
    if (sipCall) {
      sipCall.visible = visible;
    }
  }

  getCall(callId: string, errorOnFail: boolean = true): SipCall | undefined {
    const msg = this.getMsg("getCall");
    const sipCall = this._allCalls.find((s) => s.id === callId);
    if (!sipCall && errorOnFail) {
      log.error(`${msg}: failed: call ${callId} not found`);
    }
    return sipCall;
  }

  /**
   * Instance identifier.
   */
  get displayName() {
    return this._options?.userAgentOptions?.displayName || "Enghouse Connect User";
  }

  /**
   * Current state of the user
   */
  get state() {
    return this._state;
  }

  get domain() {
    return this._options?.domain;
  }

  haveActiveCall(): boolean {
    return this._activeCall != null;
  }

  getActiveCallId(): string | undefined {
    return this._activeCall?.id;
  }

  haveAnyCalls(): boolean {
    return this._allCalls.length > 0;
  }

  getHeldCallIds(): string[] {
    return this._allCalls.filter((call) => !call.active).map((call) => call.id);
  }

  getAllCallIds(): string[] {
    return this._allCalls.map((call) => call.id);
  }

  getCallId(callingState: CallingState): string | undefined {
    return this._allCalls.find((sipCall) => sipCall.callingState === callingState)?.id;
  }

  getAllVisibleNotRingingCallIds(): string[] {
    return this._allCalls
      .filter((sipCall) => sipCall.visible)
      .filter((sipCall) => sipCall.callingState !== CallingState.RINGING)
      .map((call) => call.id);
  }

  haveRingingCall(): boolean {
    return !!this.getCallId(CallingState.RINGING);
  }

  haveTerminatingCall(): boolean {
    return !!this.getCallId(CallingState.TERMINATING);
  }

  canInitiateCall(): boolean {
    const calls = this.getAllVisibleNotRingingCallIds();
    return (
      !this.haveTerminatingCall() && !this.haveRingingCall() && !this.haveActiveCall() && calls.length < MAX_SIP_CALLS
    );
  }

  canAcceptCall(): boolean {
    const calls = this.getAllVisibleNotRingingCallIds();
    return !this.haveTerminatingCall() && !this.haveRingingCall() && calls.length < MAX_SIP_CALLS;
  }

  /**
   * Delegates
   */
  get delegate(): SipDelegate {
    return this._delegate;
  }

  /**
   * Set New Delegates
   */
  set delegate(d: SipDelegate) {
    this._delegate = { ...this._delegate, ...d };
  }

  /** The local media stream. Undefined if call not answered. */
  get localMediaStream(): MediaStream | undefined {
    const msg = this.getMsg("localMediaStream");
    if (!this._activeCall) {
      return undefined;
    }
    const sdh = this._activeCall.session.sessionDescriptionHandler;
    if (!sdh) {
      return undefined;
    }
    if (!(sdh instanceof SessionDescriptionHandler)) {
      throw new Error(`${msg}: Session description handler not instance of web SessionDescriptionHandler`);
    }
    return sdh.localMediaStream;
  }

  /** The remote media stream. Undefined if call not answered. */
  get remoteMediaStream() {
    const msg = this.getMsg("remoteMediaStream");
    if (!this._activeCall) {
      return undefined;
    }
    const sdh = this._activeCall.session.sessionDescriptionHandler;
    if (!sdh) {
      return undefined;
    }
    if (!(sdh instanceof SessionDescriptionHandler)) {
      throw new Error(`${msg}: Session description handler not instance of web SessionDescriptionHandler`);
    }
    return sdh.remoteMediaStream;
  }

  /**
   * Initialize the identity of the sip user
   */
  async start(options?: sipUAPayload) {
    const msg = this.getMsg("start");
    try {
      log.info(`${msg}: options=${JsonStringify(options)}`);
      if (options) {
        this._options = { ...options };
      }
      if (!this._options) {
        throw new Error(`${msg}: Missing sip options`);
      }
      this._createUserAgent();
      if (!this._sipUserAgent) {
        throw new Error(`${msg}: Missing user agent`);
      }

      this._createRegisterer();
      if (!this._sipUserRegisterer) {
        throw new Error("Missing registerer");
      }
      this._attachUserAgentDelegate();
      this._attachSipUserRegistererListener();

      return await this._sipUserAgent.start();
    } catch (e) {
      throw new Error(`${msg}: ${e.message}`);
    }
  }

  /**
   * Stop and clean up the current sip user
   */
  async stop() {
    const msg = this.getMsg("stop");
    try {
      log.info(`${msg}`);
      if (!this._sipUserAgent) {
        throw new Error(`${msg}: Missing User Agent`);
      }
      await this._sipUserAgent.stop();
      log.debug(`${msg}: stopping of user agent done`);
      this._detachUserAgentDelegate();

      if (this._activeCall) {
        await this.endCallSession(this._activeCall.id);
      }
      for (const session of this._allCalls) {
        await this.endCallSession(session.id);
      }
      this._sipUserAgent = undefined;
      log.debug(`${msg}: disposing of user agent done`);

      if (this._sipUserRegisterer) {
        this._detachSipUserRegistererListener();
        await this._sipUserRegisterer.dispose();
        this._sipUserRegisterer = undefined;
        log.debug(`${msg}: disposing of registrer done`);
      }
      log.info(`${msg} done`);
    } catch (e) {
      throw new Error(`${msg}: ${e.message}`);
    }
  }

  async restart() {
    const msg = this.getMsg("restart");
    try {
      log.info(`${msg}`);
      this._restarting = true;
      await this.stop();
      await this.start();
      log.info(`${msg} done`);
    } catch (e) {
      log.error(`${msg}: ${e.message}`);
      throw new Error(`${msg}: ${e.message}`);
    } finally {
      this._restarting = false;
    }
  }

  /**
   * Get "real" SIP Call-ID from header from "our timestamp based" call-id
   * @param callId
   * @returns
   */
  getSipCallId(callId: string): string | undefined {
    const sipCall = this.getCall(callId);
    if (sipCall && sipCall.session) {
      return sipCall.session.id;
    }
    return undefined;
  }

  /**
   * Setup the current call  session. Note: You must call the invite function to send an invite.
   * i.e this._activeCall.invite()
   * @param targetURI - Request URI identifying the target of the message.
   */
  async initiateOutgoingCallSession(targetURI: string, displayName: string, callType: CallActionType): Promise<string> {
    const msg = this.getMsg("initiateOutgoingCallSession");
    let sipCall: SipCall | null = null;

    try {
      log.debug(
        `${msg}: uri='${targetURI}' displayName='${displayName}' callType=${callType} currentSession=${this._activeCall}`
      );

      if (!this._sipUserAgent) {
        throw new Error(`${msg}: unable to start session: no user agent`);
      }

      if (this._activeCall) {
        throw new Error(`${msg}: unable to start session: already have an active call`);
      }

      if (!targetURI) {
        throw new Error(`${msg}: unable to start session: target empty`);
      }

      if (!this.canInitiateCall()) {
        throw new Error(`${msg}: unable to start session: no call slots left`);
      }

      if (callType === CallActionType.Call || callType === CallActionType.Conference) {
        // OK
      } else {
        throw new Error(`unexpected sipCall.callType: ${callType}`);
      }

      const invitee: URI = this._createInvitee(targetURI);
      const session = new Inviter(this._sipUserAgent, invitee, {});
      const callId = session.request?.callId;
      sipCall = this.addCall(callId, session, callType, true);
      sipCall.peer.user = targetURI;
      sipCall.peer.name = displayName || targetURI;
      sipCall.callingState = CallingState.CALLING;

      this.setActiveCall(sipCall, "initiateOutgoingCallSession");
      this.attachSessionListeners(sipCall);

      const inviterOptions = this.createSessionInviteOptions(
        sipCall.id,
        CallMediaType.audio,
        InviteSdpModifier.None,
        sipCall.outbound
      );
      await sipCall.session.invite(inviterOptions);

      return sipCall.id;
    } catch (e) {
      if (sipCall) {
        // call ended by error
        this.stopToneInCall();
        this.playToneInCall(Tones.Error);
        sipCall.callingState = CallingState.TERMINATING;
        sipCall.error = true;
        setTimeout(() => this.terminateCall(sipCall!), TERMINATING_CALL_LIFETIME_MS);
        this.updateSipCalls("initiateOutgoingCallSession:Error");
      }
      log.error(`${msg}: failed: ${e.message}`);
      throw new Error(`${msg}: ${e.message}`);
    }
  }

  /**
   * Transfer the call session to another user
   * @param targetURI - Request URI identifying the target.
   */
  async transferCallToUser(callId: string, user: string) {
    const msg = this.getMsg("transferCallToUser");
    try {
      log.debug(`${msg}: target=${user}`);
      if (!this._sipUserAgent || !user) {
        log.warn(`${msg}: unable to transfer session`);
        return false;
      }
      const callToTransfer = this.getCall(callId);
      if (!callToTransfer) {
        log.warn(`${msg}: unable to transfer session - no call`);
        return false;
      }
      this.validateCallAction(callToTransfer, CallActionToPerform.Refer);
      const invitee = this._createInvitee(user);
      await callToTransfer.session.refer(invitee);
      return true;
    } catch (e) {
      throw new Error(`${msg}: ${e.message}`);
    }
  }

  /**
   * Transfer the call session to another existing session
   * @param targetURI - Request URI identifying the target.
   */
  async transferCallToSession(callId: string) {
    const msg = this.getMsg("transferCallToSession");
    try {
      log.debug(`${msg}: callId=${callId}`);
      if (!this._activeCall || !this._sipUserAgent || !callId) {
        log.warn(`${msg}: unable to transfer to session`);
        return false;
      }
      const sipCall = this.getCall(callId);
      if (!sipCall) {
        throw new Error(`${msg}: Call does not exist.`);
      }
      this.validateCallAction(sipCall, CallActionToPerform.Refer);
      await this._activeCall.session.refer(sipCall.session);
      return true;
    } catch (e) {
      throw new Error(`${msg}: ${e.message}`);
    }
  }

  async holdCallSession(type: CallMediaType) {
    const msg = this.getMsg("holdCallSession");
    try {
      log.debug(`${msg}: type=${type}`);

      if (!this._activeCall) {
        return Promise.reject(new Error(`${msg}: No active call.`));
      }
      this.pauseLocal();
      this.pauseRemote();
      await this._reInviteToHoldCallSession(this._activeCall, true, type);
      this._activeCall.heldTimestamp = Date.now();
      this.setActiveCall(null, "holdCallSession");
    } catch (e) {
      throw new Error(`${msg}: ${e.message}`);
    }
  }

  async retreiveCallSession(callId: string, type: CallMediaType) {
    const msg = this.getMsg("retreiveCallSession");
    try {
      log.debug(`${msg}: callId=${callId} type=${type}`);

      const sipCall = this.getCall(callId);

      if (!sipCall) {
        return Promise.reject(new Error(`${msg}: Call does not exist.`));
      }
      if (sipCall.active) {
        return Promise.reject(new Error(`${msg}: Call already active.`));
      }

      await this._reInviteToHoldCallSession(sipCall, false, type);
      this.setActiveCall(sipCall, "retrieveCallSession");
      await this.playLocal();
      await this.playRemote();
    } catch (e) {
      throw new Error(`${msg}: ${e.message}`);
    }
  }

  async reInviteCallSession(callId: string, type: CallMediaType) {
    const msg = this.getMsg("reInviteCallSession");
    try {
      log.debug(`${msg}: callId=${callId} type=${type}`);

      const sipCall = this.getCall(callId);

      if (!sipCall) {
        return Promise.reject(new Error(`${msg}: Call does not exist.`));
      }

      if (sipCall.active) {
        // if call already on-hold, skip, because a retrieve will fix it
        // somewhat of a hack to re-invite and re-negotiate channels
        await this.holdCallSession(CallMediaType.audio);
        await this.retreiveCallSession(sipCall.id, CallMediaType.audio);
      }
    } catch (e) {
      throw new Error(`${msg}: ${e.message}`);
    }
  }

  /***
   * Central place to implement call action validations
   */
  validateCallAction(sipCall: SipCall, action: CallActionToPerform) {
    if (!sipCall) {
      throw new Error("No sip call");
    }
    if (action === CallActionToPerform.Refer) {
      if (sipCall.callType !== CallActionType.Call) {
        throw new Error(`Validate error: action refer call can not be performed on call type ${sipCall.callType}`);
      }
    }
  }

  /**
   * Puts Session on hold or retrieves call from hold
   * @param hold - Hold on if true, off if false.
   */
  async _reInviteToHoldCallSession(sipCall: SipCall, hold: boolean, type: CallMediaType): Promise<void> {
    const msg = this.getMsg("_reInviteToHoldCallSession");
    const holdAction = hold ? "hold" : "retrieve";
    log.debug(`${msg}: ${holdAction} type=${type}`);

    return new Promise((resolve, reject) => {
      try {
        if (!sipCall) {
          return Promise.reject(new Error(`${msg}: Session does not exist.`));
        }

        const sessionDescriptionHandler = sipCall.session.sessionDescriptionHandler;
        if (!sessionDescriptionHandler) {
          throw new Error(`${msg}: Missing SessionDescriptionHandler`);
        }
        if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
          throw new Error(`${msg}: Session description handler not instance of web SessionDescriptionHandler`);
        }

        const inviterOptions = this.createSessionInviteOptions(
          sipCall.id,
          type,
          hold ? InviteSdpModifier.Hold : InviteSdpModifier.Retrieve,
          sipCall.outbound
        );

        if (!inviterOptions.sessionDescriptionHandlerOptions || !inviterOptions.sessionDescriptionHandlerModifiers) {
          throw new Error(`${msg}: Broken inviter options`);
        }

        sipCall.session.sessionDescriptionHandlerOptionsReInvite = inviterOptions.sessionDescriptionHandlerOptions;

        const options = {
          sessionDescriptionHandlerModifiers: inviterOptions.sessionDescriptionHandlerModifiers,
          requestDelegate: {
            onAccept: () => {
              log.debug(`${msg}: re-invite ${holdAction} accepted from peer`);
              sipCall.held = hold;
              try {
                this._enableMediaStreamTracks(sipCall.session, "receivers", !sipCall.held);
                this._enableMediaStreamTracks(sipCall.session, "senders", !sipCall.held && !this._muted);
              } catch (e) {
                log.error(`${msg}: re-invite ${holdAction} enableMediaStreamTracks failed, rejecting`);
                reject();
              }
              resolve();
            },
            onReject: () => {
              log.warn(`${msg}: re-invite ${holdAction} request was rejected from peer`);
              try {
                this._enableMediaStreamTracks(sipCall.session, "receivers", !sipCall.held);
                this._enableMediaStreamTracks(sipCall.session, "senders", !sipCall.held && !this._muted);
              } catch (e) {
                log.error(`${msg}: re-invite ${holdAction} enableMediaStreamTracks failed`);
              }
              reject();
            },
          },
        };

        // Send re-INVITE
        sipCall.session
          .invite(options)
          .then(/* success resolved in options.requestDelegate.onAccept() */)
          .catch((error) => {
            log.warn(`${msg}: [${sipCall.id}] re-invite ${holdAction} request returned an error: ${error}`);
            reject();
          });
      } catch (error) {
        if (error instanceof RequestPendingError) {
          log.warn(
            `${msg}: [${sipCall.id}] re-invite ${holdAction} request exception: a hold request is already in progress.`
          );
        } else {
          log.warn(`${msg}: [${sipCall.id}] re-invite ${holdAction} request exception: ${error}`);
        }
        reject();
      }
    });
  }

  /**
   * Accept call invitation
   * @param type - type of media call
   * @param session - invitation session
   */
  async acceptCallInvitation(type: CallMediaType, callId: string) {
    const msg = this.getMsg("acceptCallInvitation");
    const sipCall = this.getCall(callId);
    try {
      log.debug(`${msg}: type=${type} callId=${callId}`);

      if (!sipCall) {
        return Promise.reject(new Error(`${msg}: Call does not exist.`));
      }
      if (!(sipCall.session instanceof Invitation)) {
        throw new Error(`${msg}: Session not instance of Invitation.`);
      }
      if (this._activeCall) {
        await this.holdCallSession(CallMediaType.audio);
      }
      if (this._activeCall) {
        throw new Error(`${msg}: Current call session exists.`);
      }
      sipCall.callingState = CallingState.ACCEPTING;
      this.setActiveCall(sipCall, "acceptCallInvitation");
      const inviterOptions = this.createSessionInviteOptions(
        sipCall.id,
        type,
        InviteSdpModifier.None,
        sipCall.outbound
      );

      const res = await sipCall.session.accept(inviterOptions);
      return res;
    } catch (e) {
      if (sipCall) {
        // call ended by error
        this.stopToneInCall();
        this.playToneInCall(Tones.Error);
        sipCall.callingState = CallingState.TERMINATING;
        sipCall.error = true;
        setTimeout(() => this.terminateCall(sipCall!), TERMINATING_CALL_LIFETIME_MS);
        this.updateSipCalls("acceptCallInvitation:Error");
      }
      log.error(`${msg}: failed: ${e.message}`);
      throw new Error(`${msg}: ${e.message}`);
    }
  }

  /**
   * Reject call invitation
   * @param session - invitation session
   */
  async rejectCallInvitation(callId: string) {
    const msg = this.getMsg("rejectCallInvitation");
    try {
      log.debug(`${msg}: callId=${callId}`);
      const sipCall = this.getCall(callId);

      if (!sipCall) {
        return Promise.reject(new Error(`${msg}: Call does not exist.`));
      }
      if (!(sipCall.session instanceof Invitation)) {
        throw new Error(`${msg}: Session not instance of Invitation.`);
      }

      sipCall.session.reject({ statusCode: SipStatusCode.TemporarilyUnavailable });
    } catch (e) {
      throw new Error(`${msg}: ${e.message}`);
    }
  }

  /**
   * End call session
   */
  async endCallSession(callId: string) {
    const msg = this.getMsg("endCallSession");
    log.debug(`${msg}: callId=${callId}`);
    if (!this.haveCall(callId)) {
      return;
    }
    const sipCall = this.getCall(callId);
    if (!sipCall) {
      return;
    }
    try {
      log.debug(`${msg}: ${sipCall.session?.state}`);
      switch (sipCall.session.state) {
        case SessionState.Initial:
        // Fall through
        case SessionState.Establishing:
          if (sipCall.session instanceof Inviter) {
            return sipCall.session.cancel().then(() => {
              this.cleanupCallSession(sipCall);
              log.debug(`${msg}: Inviter never sent INVITE (canceled)`);
            });
          } else if (sipCall.session instanceof Invitation) {
            return sipCall.session.reject({ statusCode: SipStatusCode.TemporarilyUnavailable }).then(() => {
              this.cleanupCallSession(sipCall);
              log.debug(`${msg}: Invitation rejected (sent 480)`);
            });
          } else {
            log.error(`${msg}: Unknown session type.`);
          }
          break;
        case SessionState.Established:
          return sipCall.session.bye().then(() => {
            this.cleanupCallSession(sipCall);
            log.debug(`${msg}: Session ended (sent BYE)`);
          });
        case SessionState.Terminated:
          break;
        default:
          break;
      }
      this.cleanupCallSession(sipCall);
    } catch (e) {
      throw new Error(`${msg}: ${e.message}`);
    }
  }

  /**
   * Clean up the call session
   */
  cleanupCallSession(sipCall: SipCall) {
    const msg = this.getMsg("cleanupCallSession");
    try {
      log.debug(`${msg}`);
      if (!sipCall) return;
      if (!this.haveCall(sipCall.id)) return;

      // detach delegates and listeners
      sipCall.session.delegate = undefined;
      (sipCall.session.stateChange as EmitterImpl<SessionState>).removeAllListeners();

      this.removeCall(sipCall.id);

      if (this._activeCall === sipCall) {
        // only cleanup media if this is an active call
        this.cleanupMedia();
        this.setActiveCall(null, "cleanupCallSession");
      } else {
        this.updateSipCalls("cleanupCallSession");
      }
    } catch (e) {
      throw new Error(`${msg}: ${e.message}`);
    }
  }

  /**
   * Set up the local element for the call
   * @param element - Media Element.
   */
  async setupLocalMedia(element: HTMLMediaElement) {
    const msg = this.getMsg("setupLocalMedia");
    try {
      log.debug(`${msg}: ${element}`);
      if (!element) {
        throw new Error(`${msg}: Missing local media element`);
      }
      if (!this.localMediaStream) {
        throw new Error(`${msg}: Missing local media stream`);
      }

      this._localMediaElement = element;
      this._localMediaElement.autoplay = true; // Safari hack, because you cannot call .play() from a non user action
      this._localMediaElement.srcObject = this.localMediaStream;
      this._localMediaElement.volume = 0;

      const { savedLocalMicrophone } = this._mediaDevices;
      await this.changeCurrentCallDevice(MediaStreamDevices.microphone, savedLocalMicrophone);

      // LISTEN TO LOCAL STREAM MEDIA TRACK UPDATES.
      this.localMediaStream.onaddtrack = (event) => {
        log.debug(`${msg}: Local media onaddtrack`);
        // FIX A BUG INTRODUCED WHEN THE USER CALLS WITH VIDEO BUT THE CALLEE ANSWERED WITH AUDIO
        // THE SENDERS ONLY CONTAINS AN AUDIO TRACK WHICH CAUSES TRACK MISMATCH
        // WE CLEAN UP THE OLD TRACK AND SAVE THE NEW TRACK
        try {
          const { track } = event;
          const tracks = this.localMediaStream?.getTracks();
          tracks?.forEach((t) => {
            const { kind, id, readyState } = t;
            if (kind === track.kind && id !== track.id && readyState === "live") {
              t.stop();
              this.localMediaStream?.removeTrack(t);
            }
          });
        } catch (e) {
          log.error(`${msg}: Mismatch trach fix failed - ${e.message}`);
        }
      };
      this.localMediaStream.onremovetrack = () => {
        log.debug(`${msg}: Local media onremovetrack`);
      };

      this._localMediaElement.onplaying = () => {
        this._localPlay.playing = true;
      };
      this._localMediaElement.onpause = () => {
        this._localPlay.playing = false;
      };
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Set up the remote element for the call
   * @param element - Media Element.
   */
  async setupRemoteMedia(element: HTMLMediaElement) {
    const msg = this.getMsg("setupRemoteMedia");
    try {
      log.debug(`${msg}: ${element}`);
      if (!element) {
        throw new Error(`${msg}: Missing remote media element`);
      }
      if (!this.remoteMediaStream) {
        throw new Error(`${msg}: Missing remote media stream`);
      }

      this._remoteMediaElement = element;
      this._remoteMediaElement.autoplay = this.getRemoteAutoplay();
      this._remoteMediaElement.srcObject = this.remoteMediaStream;

      const { savedLocalSpeaker } = this._mediaDevices;
      await this.changeCurrentSpeaker(savedLocalSpeaker);

      // LISTEN TO REMOTE STREAM MEDIA TRACK UPDATES.
      this.remoteMediaStream.onaddtrack = () => {
        log.debug(`${msg}: Remote media onaddtrack`);
        this._remoteMediaElement?.load(); // Safari hack, as it doesn't work otheriwse
        this._remoteMediaElement?.play().catch((e) => {
          log.error(`${msg}: Failed to set up remote media - ${e.message}`);
        });
      };
      this.remoteMediaStream.onremovetrack = () => {
        log.debug(`${msg}: Remote media onremovetrack`);
      };

      this._remoteMediaElement.onplaying = () => {
        this._remotePlay.playing = true;
      };
      this._remoteMediaElement.onpause = () => {
        this._remotePlay.playing = false;
      };
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  getRemoteAutoplay() {
    // fine-tuning fix for short audio noise on new render (minimize/maximize)
    // return false if active call muted
    // return true as Safari hack, because you cannot call .play() from a non user action
    return this._activeCall ? !this._activeCall.mutedState.speaker : true;
  }

  pauseLocal() {
    const msg = this.getMsg("pauseLocal");
    log.debug(`${msg}`);
    if (this._localMediaElement) {
      this._localMediaElement.pause();
    }
  }

  pauseRemote() {
    const msg = this.getMsg("pauseRemote");
    log.debug(`${msg}`);
    if (this._remoteMediaElement) {
      this._remoteMediaElement.pause();
    }
  }

  async playLocal() {
    const msg = this.getMsg("playLocal");
    log.debug(`${msg}: startInProgress=${this._localPlay.startInProgress} playing=${this._localPlay.playing}`);
    if (this._localPlay.startInProgress) {
      return;
    }
    try {
      this._localPlay.startInProgress = true;
      if (!this._localMediaElement) {
        throw new Error(`${msg}: Missing local media element`);
      }
      if (!this.localMediaStream) {
        throw new Error(`${msg}: Missing local media stream`);
      }

      this._localMediaElement.srcObject = this.localMediaStream;

      if (!this._localPlay.playing) {
        this._localMediaElement.load();
        await this._localMediaElement.play();
      }

      if (isDebugLogger(log)) {
        const isMicrophoneMuted = this.isMicrophoneMuted();
        log.debug(`${msg} success (isMicrophoneMuted=${isMicrophoneMuted})`);
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    } finally {
      this._localPlay.startInProgress = false;
    }
  }

  async playRemote() {
    const msg = this.getMsg("playRemote");
    log.debug(`${msg}: startInProgress=${this._remotePlay.startInProgress} playing=${this._remotePlay.playing}`);
    if (this._remotePlay.startInProgress) {
      return;
    }
    try {
      this._remotePlay.startInProgress = true;
      if (!this._remoteMediaElement) {
        throw new Error(`${msg}: Missing remote media element`);
      }
      if (!this.remoteMediaStream) {
        throw new Error(`${msg}: Missing remote media stream`);
      }

      this._remoteMediaElement.srcObject = this.remoteMediaStream;

      if (!this._remotePlay.playing) {
        this._remoteMediaElement.load();
        await this._remoteMediaElement.play();
      }

      if (isDebugLogger(log)) {
        const isSpeakerMuted = this.isSpeakerMuted();
        log.debug(`${msg} success (muted=${this._remoteMediaElement.muted} speakerMuted=${isSpeakerMuted})`);
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    } finally {
      this._remotePlay.startInProgress = false;
    }
  }

  /**
   * Clean up the media elements
   */
  cleanupMedia() {
    const msg = this.getMsg("cleanupMedia");
    try {
      log.debug(`${msg}`);

      if (this.localMediaStream) {
        this._killTracks(this.localMediaStream);
      }
      if (this._localMediaElement) {
        this._disableMediaElement(this._localMediaElement);
      }

      if (this.remoteMediaStream) {
        this._killTracks(this.remoteMediaStream);
      }
      if (this._remoteMediaElement) {
        this._disableMediaElement(this._remoteMediaElement);
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Toggle microphone
   * @param muted - True or False.
   */
  setMicrophoneMuted(muted: boolean) {
    const msg = this.getMsg("setMicrophoneMuted");
    try {
      log.debug(`${msg}: ${muted}`);
      if (!this._activeCall) return;
      if (!this.localMediaStream) return;
      this._setTracksEnabled(this.localMediaStream.getAudioTracks(), !muted);
      this._activeCall.mutedState.microphone = muted;
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /***
   * Read microphone state
   */
  isMicrophoneMuted(): boolean {
    const msg = this.getMsg("isMicrophoneMuted");
    let ret = false;
    try {
      log.debug(`${msg}`);
      if (!this._activeCall) return ret;
      if (!this.localMediaStream) return ret;
      ret = !this._getTracksEnabled(this.localMediaStream.getAudioTracks());
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
    return ret;
  }

  /**
   * Toggle video
   * @param muted - True or False.
   */
  setVideoMuted(muted: boolean) {
    const msg = this.getMsg("setVideoMuted");
    try {
      log.debug(`${msg}: ${muted}`);
      if (!this._activeCall) return;
      if (!this.localMediaStream) return;
      this._setTracksEnabled(this.localMediaStream.getVideoTracks(), !muted);
      this._activeCall.mutedState.camera = muted;
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /***
   * Read video state
   */
  isVideoMuted(): boolean {
    const msg = this.getMsg("isVideoMuted");
    let ret = false;
    try {
      log.debug(`${msg}`);
      if (!this._activeCall) return ret;
      if (!this.localMediaStream) return ret;
      ret = !this._getTracksEnabled(this.localMediaStream.getVideoTracks());
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
    return ret;
  }

  haveVideo(): boolean {
    return (this.localMediaStream ? this.localMediaStream.getVideoTracks()?.length : 0) > 0;
  }

  /**
   * Toggle speaker
   * @param muted - True or False.
   */
  setSpeakerMuted(muted: boolean) {
    const msg = this.getMsg("setSpeakerMuted");
    try {
      log.debug(`${msg}: ${muted}`);
      if (this._activeCall && this._remoteMediaElement) {
        this._remoteMediaElement.muted = muted;
        this._remoteMediaElement.autoplay = this.getRemoteAutoplay();
        this._activeCall.mutedState.speaker = muted;
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /***
   * Read speaker state
   */
  isSpeakerMuted(): boolean {
    const msg = this.getMsg("isSpeakerMuted");
    let ret = false;
    try {
      log.debug(`${msg}`);
      if (this._activeCall && this._remoteMediaElement) {
        ret = this._remoteMediaElement.muted;
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
    return ret;
  }

  /**
   *
   * @returns Restore mute state of active call
   */
  restoreMutedState() {
    const msg = this.getMsg("restoreMutedState");
    try {
      if (!this._activeCall) return;
      this.setMicrophoneMuted(this._activeCall.mutedState.microphone);
      this.setVideoMuted(this._activeCall.mutedState.camera);
      this.setSpeakerMuted(this._activeCall.mutedState.speaker);
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Compare set state against read-from-device state, report error and repair accordingly
   */
  validateMutedState() {
    const msg = this.getMsg("validateMutedState");
    try {
      log.debug(`${msg} start`);
      if (!this._activeCall) return;
      if (!this._localMediaElement || !this._remoteMediaElement) return;

      const mutedStateFromDevice = {
        microphone: this.isMicrophoneMuted(),
        speaker: this.isSpeakerMuted(),
        camera: this.isVideoMuted(),
      };

      if (this._activeCall.mutedState.microphone !== mutedStateFromDevice.microphone) {
        log.error(`${msg}: Microphone mute states differ, expected ${this._activeCall.mutedState.microphone}`);
        this._activeCall.mutedState.microphone = mutedStateFromDevice.microphone;
      }
      if (this._activeCall.mutedState.speaker !== mutedStateFromDevice.speaker) {
        log.error(`${msg}: Speaker mute states differ, expected ${this._activeCall.mutedState.speaker}`);
        this._activeCall.mutedState.speaker = mutedStateFromDevice.speaker;
      }
      if (this.haveVideo()) {
        if (this._activeCall.mutedState.camera !== mutedStateFromDevice.camera) {
          log.error(`${msg}: Video mute states differ, expected ${this._activeCall.mutedState.camera}`);
          this._activeCall.mutedState.camera = mutedStateFromDevice.camera;
        }
      }
      log.debug(`${msg} done`);
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Change current speaker device.
   * @param deviceId - Device id.
   */
  async changeCurrentSpeaker(deviceId: string) {
    const msg = this.getMsg("changeCurrentSpeaker");
    try {
      log.debug(`${msg}: ${deviceId}`);
      if (!deviceId || !this._remoteMediaElement) {
        throw new Error(`${msg}: Change speaker missing elements`);
      }
      if (!this._remoteMediaElement.setSinkId) {
        throw new Error(`${msg}: setSinkId broken`);
      }
      await this._remoteMediaElement.setSinkId(deviceId);
      log.debug(`${msg}: Set Sink Success - ${deviceId}`);
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Change current call device. !!! NOTE: NEEDS TO BE TESTED WHEN UI IS IMPLEMENTED !!!
   * @param type - Session call type.
   * @param deviceId - Device id.
   */
  async changeCurrentCallDevice(type: MediaStreamDevices, deviceId: string) {
    const msg = this.getMsg("changeCurrentCallDevice");
    try {
      log.debug(`${msg}: changeCurrentCallDevice type=${type} deviceId=${deviceId}`);
      if (!this._activeCall?.session.sessionDescriptionHandler || !deviceId || !this._localMediaElement) {
        throw new Error(`${msg}: Change device missing elements`);
      }

      // Generate appropriate media constraint depending on the device change type
      let callMediaType: CallMediaType;
      switch (type) {
        case MediaStreamDevices.microphone:
          callMediaType = CallMediaType.audio;
          break;
        case MediaStreamDevices.camera:
          callMediaType = CallMediaType.video;
          break;
        case MediaStreamDevices.speaker:
          throw new Error(`${msg}: Unexpected media device type (speaker).`);
        default:
          throw new Error(`${msg}: Unknown media device type.`);
      }
      const constraint = this._getUserMediaContraints(callMediaType, deviceId);

      // Generate a new stream based on the new device
      const newDeviceStream = await navigator.mediaDevices.getUserMedia(constraint);

      // this uses protected API of Web.SessionDescriptionHandler
      const sdh = this._activeCall.session.sessionDescriptionHandler as Web.SessionDescriptionHandler;
      return await sdh["setLocalMediaStream"](newDeviceStream);
    } catch (e) {
      throw new Error(`${msg}: ${e.message}`);
    }
  }

  /**
   * Send DTMF via RTP (RFC 4733).
   * Returns true if DTMF send is successful, false otherwise.
   * @param tone - A string containing DTMF digits.
   * @param options - Options object to be used by sendDtmf
   */
  sendDTMF(tone: string, options: any = {}) {
    const msg = this.getMsg("sendDTMF");
    try {
      log.debug(`${msg}: tone=${tone}`);
      if (!this._activeCall) {
        throw new Error(`${msg}: Current call session missing`);
      }
      const sdh = this._activeCall.session.sessionDescriptionHandler;
      if (!sdh) {
        throw new Error(`${msg}: Missing SessionDescriptionHandler`);
      }
      if (!(sdh instanceof SessionDescriptionHandler)) {
        throw new Error(`${msg}: Session description handler not instance of web SessionDescriptionHandler`);
      }
      const sent = sdh.sendDtmf(tone, options);
      log.debug(`${msg}: sent: ${sent}`);
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Play ringtone
   */
  async playToneInCall(tone: Tones) {
    const { savedLocalSpeaker } = this._mediaDevices;
    await this._ringer.play(tone, savedLocalSpeaker);
  }

  /**
   * Stop ringtone
   */
  async stopToneInCall() {
    this._ringer.stop();
  }

  /**
   * Save media device
   * @param type - media device type
   * @param id - media device id
   */
  async saveMediaDevice(type: MediaStreamDevices, id: string) {
    const msg = this.getMsg("saveMediaDevice");
    try {
      log.debug(`${msg}: id=${id}`);
      if (!id) {
        throw new Error(`${msg}: Missing device id.`);
      }
      switch (type) {
        case MediaStreamDevices.microphone:
          this._mediaDevices.savedLocalMicrophone = id;
          break;
        case MediaStreamDevices.speaker:
          this._mediaDevices.savedLocalSpeaker = id;
          break;
        case MediaStreamDevices.camera:
          this._mediaDevices.savedLocalCamera = id;
          break;
        default:
          throw new Error(`${msg}: Unknown media device type.`);
      }
      if (this._activeCall) {
        switch (type) {
          case MediaStreamDevices.microphone:
            await this.changeCurrentCallDevice(type, id);
            break;
          case MediaStreamDevices.speaker:
            await this.changeCurrentSpeaker(id);
            break;
          case MediaStreamDevices.camera:
            await this.changeCurrentCallDevice(type, id);
            break;
          default:
            throw new Error(`${msg}: Unknown media device type.`);
        }
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Attach conferenceId to a call (call being merged into a conference)
   */
  attachConferencelId(callId: string | null, owner: string | null, conferenceId: string): string | null {
    const msg = this.getMsg("attachMergeCallId");
    let ret = null;
    try {
      log.debug(`${msg}: callId=${callId} owner=${owner} confererenceId=${conferenceId}`);
      if (callId) {
        // we're conference owner
        const sipCall = this.getCall(callId);
        if (sipCall) {
          if (sipCall.conferenceId) {
            throw new Error("ConferenceId already set locally");
          }
          if (sipCall.callType !== CallActionType.Conference) {
            throw new Error("Wrong call type found");
          }
          sipCall.conferenceId = conferenceId;
          ret = sipCall.id;
        } else {
          throw new Error("CallId not found");
        }
      } else if (owner) {
        // find call associated with this owner
        const sipCall = this._allCalls.find((s) => s.peer.user === owner);
        if (sipCall) {
          sipCall.conferenceId = conferenceId;
          ret = sipCall.id;
        } else {
          log.warn(`Cannot find call with owner '${owner}' to attach to conference`);
        }
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
    return ret;
  }

  /**
   * Remove a user from a list of conference users
   */
  removeConferenceCallUser(conferenceId: string, user: string) {
    const msg = this.getMsg("removeConferenceCallUser");
    try {
      log.debug(`${msg}: conferenceId=${conferenceId} user=${user}`);
      if (conferenceId) {
        const sipCall = this._allCalls.find((s) => s.conferenceId === conferenceId);
        if (sipCall) {
          const countBefore = sipCall.conferencePeers?.length;
          sipCall.conferencePeers = sipCall.conferencePeers.filter((p) => p.user !== user);
          if (countBefore !== sipCall.conferencePeers?.length) {
            log.debug(`${msg}: removed user=${user}`);
            this.updateSipCalls("removeConferenceCallUser");
          }
        } else {
          log.warn(`Cannot find call with conferenceId '${conferenceId}' to remove a user`);
        }
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Add a user to a list of conference users
   */
  addConferenceCallUser(conferenceId: string, peer: UiSipPeer) {
    const msg = this.getMsg("addConferenceCallUser");
    try {
      log.debug(`${msg}: conferenceId=${conferenceId} peer=${JsonStringify(peer)}`);
      if (conferenceId) {
        const sipCall = this._allCalls.find((s) => s.conferenceId === conferenceId);
        if (sipCall) {
          if (!sipCall.conferencePeers.find((p) => p.user === peer.user)) {
            sipCall.conferencePeers.push(new SipPeer(peer));
            log.debug(`${msg}: added user=${peer.user}`);
            this.updateSipCalls("addConferenceCallUser");
          }
        } else {
          log.warn(`Cannot find call with conferenceId '${conferenceId}' to remove a user`);
        }
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  getConferenceInfo(callId: string): UiSipConferenceInfo | null {
    const msg = this.getMsg("getConferenceInfo");
    try {
      const sipCall = this.getCall(callId, false);
      if (sipCall && sipCall.conferencePeers?.length > 0 && sipCall.conferenceId) {
        return {
          conferenceId: sipCall.conferenceId,
          conferencePeers: sipCall.conferencePeers.map((p) => new SipPeer(p)), // deep copy of peers
        };
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
    return null;
  }

  isIncomingLoopCall(invitation: Invitation): boolean {
    let isLoop = false;
    if (this._localPeer) {
      const callerIsMyNumber = this._localPeer.user === invitation.remoteIdentity.uri.user;
      const outgoingCall = this._allCalls.find((sipCall) => sipCall.callingState === CallingState.CALLING);
      const calledIsMyNumber = !!outgoingCall && outgoingCall.peer.user === this._localPeer.user;
      isLoop = callerIsMyNumber && calledIsMyNumber;
    }
    if (isLoop) log.info(`detected call loop for incoming call from ${invitation.remoteIdentity.uri}`);
    return isLoop;
  }

  setSubscription(callId: string, subscriptionId: string | null) {
    const msg = this.getMsg("setSubscription");
    log.debug(`${msg}: callId=${callId} subscriptionId=${subscriptionId}`);

    const sipCall = this.getCall(callId);
    if (sipCall) {
      if (subscriptionId && sipCall.subscriptionId !== subscriptionId) {
        log.warn(`${msg}: rewriting an existing subscription`);
      }
      sipCall.subscriptionId = subscriptionId;
    } else {
      log.warn(`${msg}: call with id '${callId}' not found`);
    }
  }

  getSubscription(callId: string): string | null {
    const msg = this.getMsg("getSubscription");
    const sipCall = this.getCall(callId, false);
    log.debug(`${msg}: callId=${callId} subscriptionId=${sipCall?.subscriptionId}`);
    return sipCall ? sipCall.subscriptionId : null;
  }

  changeGroupCallState(callId: string, groupCallBeforeConnect: boolean): boolean {
    let changed = false;
    const msg = this.getMsg("changeGroupCall");
    log.debug(`${msg}: callId=${callId} groupCallBeforeConnect=${groupCallBeforeConnect}`);

    const sipCall = this.getCall(callId);
    if (sipCall) {
      if (sipCall.callType === CallActionType.Call) {
        if (sipCall.groupCallBeforeConnect !== groupCallBeforeConnect) {
          sipCall.groupCallBeforeConnect = groupCallBeforeConnect;
          this.updateSipCalls("changeGroupCallState");
          changed = true;
        }
      } else {
        log.warn(`${msg}: call with id '${callId}' is of invalid type '${sipCall.callType}' for this operation`);
      }
    } else {
      log.warn(`${msg}: call with id '${callId}' not found`);
    }
    return changed;
  }

  async updateSipCallInfo(callId: string, peer: UiSipPeer | null, conferencePeers: UiSipPeer[] | null = null) {
    const msg = this.getMsg("updateSipCallInfo");
    log.debug(`${msg}: callId=${callId} peer=${JsonStringify(peer)} conferencePeers=${JsonStringify(conferencePeers)}`);
    if (!this.haveCall(callId)) {
      log.warn(`${msg}: unknown callId ${callId}`);
      return;
    }

    try {
      const sipCall = this.getCall(callId);
      if (sipCall) {
        if (peer) {
          sipCall.peer = new SipPeer(peer);
        }
        if (conferencePeers) {
          sipCall.conferencePeers = conferencePeers.map((peer) => new SipPeer(peer));

          if (this._localPeer) {
            // sort to place local-peer first in array
            sipCall.conferencePeers.sort((a, b) => (a.user === this._localPeer?.user ? -1 : 0));
          } else {
            log.warn("Cannot sort conference peers - no local peer provided");
          }
        }
        this.updateSipCalls("updateSipCallInfo");
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /*
   * push update calls to redux state
   */
  updateSipCalls(caller: string) {
    const msg = this.getMsg("updateSipCalls");
    this.validateMutedState();
    try {
      log.debug(`${msg} from ${caller}`);
      if (this.delegate && this.delegate.onUpdateSipCalls) {
        this.delegate.onUpdateSipCalls(this._getSipCalls(), this.canInitiateCall());
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  // --------------------- PRIVATE FUNCTIONS

  _createInvitee(targetURI: string): URI {
    let invitee: URI | undefined = undefined;
    if (isNumber(targetURI)) {
      if (this._options?.domain) {
        invitee = SipUserAgent.makeURI(`sip:${targetURI}@${this._options.domain}`);
      } else {
        throw new Error("Invitee error: domain undefined");
      }
    } else {
      if (!targetURI.startsWith("sip:")) {
        targetURI = `sip:${targetURI}`;
      }
      invitee = SipUserAgent.makeURI(targetURI);
    }
    if (!invitee) {
      throw new Error("Invitee error: undefined");
    }
    return invitee;
  }

  /**
   * Create new user agent
   */
  _createUserAgent() {
    const msg = this.getMsg("_createUserAgent");
    try {
      log.debug(`${msg}`);
      if (this._sipUserAgent) {
        throw new Error(`${msg}: User Agent already exist`);
      }
      if (!this._options) {
        throw new Error(`${msg}: Options not set`);
      }
      const { phoneApp, userAgent, domain } = this._options;
      const uri = phoneApp?.phoneName ? `${phoneApp.phoneName}@${domain}` : "";
      const authorizationUsername = phoneApp?.username ? `${phoneApp.username}@${domain}` : "";
      const authorizationPassword = phoneApp?.password ? phoneApp.password : "";
      const server = phoneApp?.host ? `wss://${phoneApp.host}:${phoneApp.port}/` : process.env.REACT_APP_SIP_WS;
      const userAgentOptions = {
        uri: SipUserAgent.makeURI(`sip:${uri}`),
        authorizationUsername,
        authorizationPassword,
        displayName: this.displayName,
        transportOptions: {
          server,
        },
        userAgentString: userAgent || process.env.REACT_APP_UA_STRING,
        logBuiltinEnabled: false,
        logConnector,
      };
      log.debug(`${msg}: userAgentOptions: ${JSON.stringify(userAgentOptions)}`);
      this._sipUserAgent = new SipUserAgent(userAgentOptions, this.onReconnectsFailed.bind(this));
      log.info(`${msg}: Creating User Agent Success`);
    } catch (e) {
      throw new Error(`${msg}: ${e.message}`);
    }
  }

  /**
   * Create new sip registerer
   */
  _createRegisterer() {
    const msg = this.getMsg("_createRegisterer");
    try {
      log.debug(`${msg}`);
      if (!this._sipUserRegisterer) {
        const registererOptions = {
          logConfiguration: true,
          params: {
            toDisplayName: this.displayName,
          },
          refreshFrequency: 66,
        };

        if (!this._sipUserAgent) {
          throw new Error(`${msg}: Missing user agent`);
        }
        this._sipUserRegisterer = new Registerer(this._sipUserAgent, registererOptions);
      }
      log.info(`${msg}: Creating Registerer Success`);
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Handle call session state change
   * @param state
   */
  _handleSessionEvents(sipCall: SipCall, state: SessionState) {
    const msg = this.getMsg("_handleSessionEvents");
    try {
      log.debug(`${msg}: Session new event - ${state}`);
      switch (state) {
        case SessionState.Initial:
          break;
        case SessionState.Establishing:
          if (sipCall.session instanceof Inviter) {
            this.playToneInCall(Tones.Ringback);
            this.updateSipCalls("_handleSessionEvents:SessionState.Establishing");
          }
          break;
        case SessionState.Established:
          this.stopToneInCall();
          sipCall.callingState = CallingState.CONNECTED;
          this.updateSipCalls("_handleSessionEvents:SessionState.Established");
          break;
        case SessionState.Terminating:
          this.stopToneInCall();
          break;
        case SessionState.Terminated:
          this.stopToneInCall();

          if (sipCall.outbound && sipCall.callingState === CallingState.CALLING) {
            // outgoing call received a busy/unavailable
            // tone will be played in inviteRecvResponse()
            sipCall.callingState = CallingState.TERMINATING;
            setTimeout(() => this.terminateCall(sipCall), TERMINATING_CALL_LIFETIME_MS);
            this.updateSipCalls("_handleSessionEvents:SessionState.Terminated");
          } else if (sipCall.receivedBye && sipCall.active) {
            // call ended by peer
            this.playToneInCall(Tones.CallEnd);
            sipCall.callingState = CallingState.TERMINATING;
            setTimeout(() => this.terminateCall(sipCall), END_CALL_LIFETIME_MS);
            this.updateSipCalls("_handleSessionEvents:SessionState.Terminated");
          } else {
            this.terminateCall(sipCall);
          }

          break;
        default:
          throw new Error(`${msg}: Unknown session state.`);
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  terminateCall(sipCall: SipCall) {
    log.debug("terminateCall");
    this.stopToneInCall();
    // Send delegate event if available
    if (this.delegate && this.delegate.onCallSessionTerminated) {
      this.delegate.onCallSessionTerminated(SessionState.Terminated, sipCall.id);
    }
    this.endCallSession(sipCall.id);
  }

  onReconnectsFailed() {
    const msg = this.getMsg("onReconnectsFailed");
    log.debug(`${msg}`);
    if (this.delegate && this.delegate.onReconnectsFailed) {
      log.info(`=> ${msg}: restarting=${this._restarting}`);
      this.delegate.onReconnectsFailed(this._restarting);
    }
  }

  /**
   * Setup registerer delegate and state change handler
   * @param state - Current Register State.
   */
  _handleRegisterEvents(state: RegistererState) {
    const msg = this.getMsg("_handleRegisterEvents");
    try {
      log.debug(`${msg}: state:${state} restarting:${this._restarting}`);
      switch (state) {
        case RegistererState.Initial:
          break;
        case RegistererState.Registered:
          this._transitionState(SipUserStates.STARTED);
          if (this.delegate && this.delegate.onRegistered) {
            this.delegate.onRegistered(this._restarting);
          }
          break;
        case RegistererState.Unregistered:
          if (this.state !== SipUserStates.STOPPED) {
            this._transitionState(SipUserStates.STOPPED);
          }
          if (this.delegate && this.delegate.onUnregistered) {
            this.delegate.onUnregistered(this._restarting);
          }
          break;
        case RegistererState.Terminated:
          if (this.delegate && this.delegate.onTerminated) {
            this.delegate.onTerminated(this._restarting);
          }
          break;
        default:
          throw new Error(`${msg}: Unknown register event state`);
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Setup the user registerer listeners
   */
  _attachSipUserRegistererListener() {
    log.debug("_attachSipUserRegistererListener");
    if (!this._sipUserRegisterer) {
      throw new Error("Missing registerer");
    }
    this._sipUserRegisterer.stateChange.addListener((state) => this._handleRegisterEvents(state));
  }

  /**
   * Cleanup user registerer listeners
   */
  _detachSipUserRegistererListener() {
    log.debug("_detachSipUserRegistererListener");
    if (this._sipUserRegisterer) {
      (this._sipUserRegisterer.stateChange as EmitterImpl<RegistererState>).removeAllListeners();
    }
  }

  /**
   * Setup the user agent delegates
   */
  _attachUserAgentDelegate() {
    const msg = this.getMsg("userAgentDelegate");
    try {
      log.debug(`${msg}: create`);
      if (!this._sipUserAgent) {
        throw new Error(`${msg}: Missing user agent`);
      }
      this._sipUserAgent.delegate = {
        // Handle connection with server established
        onConnect: () => {
          log.debug(`${msg}: onConnect`);
          // Send delegate event if available
          if (this.delegate && this.delegate.onServerConnect) {
            this.delegate.onServerConnect();
          }

          // On connecting, register the user agent
          if (this._sipUserRegisterer) {
            this._sipUserRegisterer.register().catch((e) => log.error(`${msg}: Register Failed`));
          }
        },
        // Handle connection with server lost
        onDisconnect: (error) => {
          log.debug(`${msg}: onDisconnect`);

          // Send delegate event if available
          if (this.delegate && this.delegate.onServerDisconnect) {
            this.delegate.onServerDisconnect(error);
          }
          // On disconnect, cleanup invalid registrations
          if (this._sipUserRegisterer) {
            this._sipUserRegisterer.unregister().catch((e) => log.error(`${msg}: Unregister  Failed`));
          }
          // Only attempt to reconnect if network/server dropped the connection (if there is an error)
          if (error) {
            this._sipUserAgent?.doAttemptReconnection("userAgentDelegate.onDisconnect");
          }
        },
        // Handle incoming invitations
        onInvite: (invitation: Invitation) => {
          log.debug(`${msg}: onInvite`);

          if (this.canAcceptCall() && !this.isIncomingLoopCall(invitation)) {
            // Send delegate event - to accept or reject a call
            if (this.delegate && this.delegate.onCallReceived) {
              const callId = invitation.request?.callId;
              const sipCall = this.addCall(callId, invitation, CallActionType.Call, false);
              sipCall.callingState = CallingState.RINGING;
              sipCall.peer.name = invitation.remoteIdentity.friendlyName;
              sipCall.peer.user = invitation.remoteIdentity.uri.user || "";
              sipCall.local.name = invitation.localIdentity.friendlyName;
              sipCall.local.user = invitation.localIdentity.uri.user || "";

              const pServedQueue = invitation.request.headers["P-Served-Queue"];
              if (pServedQueue && pServedQueue.length > 0) {
                const queueCalled = pServedQueue[0].raw;
                if (!isEmpty(queueCalled)) {
                  sipCall.via.user = queueCalled.split("@")[0]?.trim();
                }
              }

              this.attachSessionListeners(sipCall);

              if (!(sipCall.session instanceof Invitation)) {
                throw new Error("sipCall.session of wrong type");
              }

              this.playToneInCall(this.haveActiveCall() ? Tones.CallWaiting : Tones.Ringtone);

              const sipCallInfo: UiSipCallInfo = {
                id: sipCall.id,
                peer: { name: sipCall.peer.name, user: sipCall.peer.user },
                local: { name: sipCall.local.name, user: sipCall.local.user },
                via: { name: sipCall.via.name, user: sipCall.via.user },
              };
              this.delegate.onCallReceived(sipCallInfo);
            } else {
              // implementation error
              log.error(`${msg}: Rejecting INVITE, delegate.onCallReceived undefined`);
              invitation.reject({ statusCode: SipStatusCode.InternalServerError });
            }
          } else {
            // Reject call if a call session is active
            log.debug(`${msg}: Rejecting INVITE, cannot accept further incoming calls`);
            invitation
              .reject({ statusCode: SipStatusCode.TemporarilyUnavailable })
              .then(() => {
                log.debug(`${msg}: rejected INVITE.`);
              })
              .catch((e) => {
                log.error(`${msg}: Failed to reject INVITE - ${e.message}`);
              });
          }
        },
      };
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Cleanup user agent delegates
   */
  _detachUserAgentDelegate() {
    const msg = this.getMsg("_detachUserAgentDelegate");
    log.debug(`${msg}: create`);
    if (!this._sipUserAgent) {
      throw new Error(`${msg}: Missing user agent`);
    }
    this._sipUserAgent.delegate = {};
  }

  /**
   * Create session description handler delegate
   * @param SessionDescriptionHandler - WebRTC session description handler for sip.js
   */
  createSessionDescriptionHandlerDelegate(sessionDescriptionHandler: Web.SessionDescriptionHandler) {
    const msg = this.getMsg("createSessionDescriptionHandlerDelegate");
    const msgCb = this.getMsg("peer-connection");
    try {
      log.debug(`${msg}`);
      if (!sessionDescriptionHandler) throw new Error(`${msg}: Missing SessionDescriptionHandler`);

      sessionDescriptionHandler.peerConnectionDelegate = {
        onconnectionstatechange: (event: Event) => {
          log.debug(`${msgCb}: onconnectionstatechange ${JsonStringify(event)}`);
        },
        ondatachannel: (event: RTCDataChannelEvent) => {
          log.debug(`${msgCb}: ondatachannel ${JsonStringify(event)}`);
        },
        onicecandidate: (event: RTCPeerConnectionIceEvent) => {
          log.debug(`${msgCb}: onicecandidate ${JsonStringify(event)}`);
        },
        onicecandidateerror: (event: RTCPeerConnectionIceErrorEvent) => {
          log.debug(`${msgCb}: onicecandidateerror ${JsonStringify(event)}`);
        },
        oniceconnectionstatechange: (event: Event) => {
          log.debug(`${msgCb}: oniceconnectionstatechange ${JsonStringify(event)}`);
        },
        onicegatheringstatechange: (event: Event) => {
          log.debug(`${msgCb}: onicegatheringstatechange ${JsonStringify(event)}`);
        },
        onnegotiationneeded: (event: Event) => {
          log.debug(`${msgCb}: onnegotiationneeded ${JsonStringify(event)}`);
        },
        onsignalingstatechange: (event: Event) => {
          log.debug(`${msgCb}: onsignalingstatechange ${JsonStringify(event)}`);
        },
        ontrack: (event: Event) => {
          log.debug(`${msg}: peerconnection ontrack ${JsonStringify(event)}`);
        },
      };
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Create session description handler delegate
   * @param session - SIP Call Session
   */
  attachSessionListeners(sipCall: SipCall) {
    const msg = this.getMsg("attachSessionListeners");
    try {
      log.debug(`${msg}`);
      const callId = sipCall.id;

      // in-dialog events
      sipCall.session.delegate = {
        onSessionDescriptionHandler: (sessionDescriptionHandler, provisional) => {
          // listen to session description changes
          log.debug(`${msg}: Session Description handler created.`);
          this.createSessionDescriptionHandlerDelegate(sessionDescriptionHandler as Web.SessionDescriptionHandler);
        },
        onInvite: (request: IncomingRequestMessage, response: string, statusCode: number) => {
          log.debug(
            `${msg}: onInvite (in-dialog) callId=${callId} groupCallBeforeConnect=${sipCall.groupCallBeforeConnect}`
          );
          if (this.delegate.onInCallInvite) {
            this.delegate.onInCallInvite(callId);
          }
        },
        onBye: (bye: Bye) => {
          log.debug(`${msg}: onBye callId=${callId}`);
          const sipCall = this.getCall(callId, false);
          if (sipCall) {
            sipCall.receivedBye = true;
          }
        },
      };

      // Listen to Call Session State Changes
      sipCall.session.stateChange.addListener((state) => this._handleSessionEvents(sipCall, state));
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Transition new sip user state.
   */
  _transitionState(newState: SipUserStates) {
    const msg = this.getMsg("_transitionState");
    log.debug(`${msg}: ${this._state} to ${newState}`);
    const invalidTransition = () => {
      throw new Error(`${msg}: Invalid state transition from ${this._state} to ${newState}`);
    };
    // Validate state transition
    switch (this._state) {
      case SipUserStates.STARTED:
        if (newState !== SipUserStates.STOPPED) {
          invalidTransition();
        }
        break;
      case SipUserStates.STOPPED:
        if (newState !== SipUserStates.STARTED) {
          invalidTransition();
        }
        break;
      default:
        throw new Error(`${msg}: Unknown state - ${this._state}`);
    }
    // Update state
    this._state = newState;
  }

  /**
   * Enable/Disable media stream tracks
   * @param tracks - Media stream tracks.
   * @param enabled - True or False.
   */
  _setTracksEnabled(tracks: MediaStreamTrack[], enabled: boolean) {
    const msg = this.getMsg("_setTracksEnabled");
    log.debug(`${msg}: to ${enabled}`);
    try {
      for (let i = 0; i < tracks.length; i++) {
        tracks[i].enabled = enabled;
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /***
   * Read oou tracks state
   */
  _getTracksEnabled(tracks: MediaStreamTrack[]) {
    // all disabled => disabled
    // at least one enabled => enabled
    const msg = this.getMsg("_getTracksEnabled");
    log.debug(`${msg}`);
    try {
      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].enabled) {
          return true;
        }
      }
      return false;
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Enable/Disable media stream tracks
   * @param session - session to work on
   * @param mediaStreamType - "receivers" or "senders" string
   * @param enabled - True or False.
   */
  _enableMediaStreamTracks(session: Session, mediaStreamType: MediaStreamType, enable: boolean) {
    const msg = this.getMsg("_enableMediaStreamTracks");
    log.debug(`${msg}: to ${enable}`);
    const sessionDescriptionHandler = session.sessionDescriptionHandler;
    if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
      throw new Error(`${msg}: Session's session description handler not instance of SessionDescriptionHandler`);
    }

    const peerConnection = sessionDescriptionHandler.peerConnection;
    if (!peerConnection) {
      throw new Error(`${msg}: Peer connection closed.`);
    }

    if (mediaStreamType === "receivers") {
      peerConnection.getReceivers().forEach((receiver) => {
        if (receiver.track) {
          receiver.track.enabled = enable;
        }
      });
    } else if (mediaStreamType === "senders")
      peerConnection.getSenders().forEach((sender) => {
        if (sender.track) {
          sender.track.enabled = enable;
        }
      });
  }

  /**
   * Prepare list of calls for UI components
   */
  _getSipCalls(): UiSipCall[] {
    const timeSortedHeldCalls = this._allCalls
      .filter((call) => call.held)
      .sort((call1, call2) => compareNumbers(call1.heldTimestamp, call2.heldTimestamp))
      .reverse();
    const lastHeldCallId = timeSortedHeldCalls.length > 0 ? timeSortedHeldCalls[0].id : null;

    const calls = this._allCalls
      .filter((sipCall) => sipCall.visible)
      .filter((sipCall) => sipCall.callingState !== CallingState.RINGING)
      .map((sipCall) => {
        const uiSipCall: UiSipCall = {
          id: sipCall.id,
          active: sipCall.active,
          held: sipCall.held,
          lastHeld: sipCall.id === lastHeldCallId,
          outbound: sipCall.outbound,
          callingState: sipCall.callingState,
          callType: sipCall.callType,
          peer: sipCall.peer.toUiSipPeerPeer(),
          local: sipCall.local.toUiSipPeerPeer(),
          via: sipCall.via.toUiSipPeerPeer(),
          mutedState: { ...sipCall.mutedState },
          conferencePeers: sipCall.conferencePeers.map((p) => p.toUiSipPeerPeer()),
          groupCallBeforeConnect: sipCall.groupCallBeforeConnect,
          error: sipCall.error,
        };
        return uiSipCall;
      });

    if (calls.length > MAX_SIP_CALLS) {
      throw new Error(`Number of calls exceeds ${MAX_SIP_CALLS}`);
    }

    return calls;
  }

  /*
   * Kill media stream tracks
   * @param stream - Media stream.
   */
  _killTracks(stream: MediaStream) {
    const msg = this.getMsg("_killTracks");
    try {
      log.debug(`${msg}`);
      if (!stream) return;
      stream.getTracks().forEach((track) => track.stop());
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Disable media element
   * @param element - Media Element.
   */
  _disableMediaElement(element: HTMLMediaElement) {
    const msg = this.getMsg("_disableMediaElement");
    try {
      log.debug(`${msg}`);
      if (!element) return;
      element.srcObject = null;
      element.pause();
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
  }

  /**
   * Generate a media stream constraint based on the type
   * @param type - Session call type.
   * @param deviceId - Device id.
   */
  _getUserMediaContraints(type: CallMediaType, deviceId: string): MediaStreamConstraints {
    const msg = this.getMsg("_getUserMediaContraints");
    try {
      log.debug(`${msg}: type=${type} deviceId=${deviceId}`);
      switch (type) {
        case CallMediaType.audio:
          return {
            audio: {
              deviceId: deviceId ? { ideal: deviceId } : undefined,
            },
            video: false,
          };
        case CallMediaType.video:
          return {
            video: {
              deviceId: deviceId ? { ideal: deviceId } : undefined,
            },
          };
        case CallMediaType.screenshare:
          return {
            audio: false,
          };
        default:
          return {};
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
      return {};
    }
  }

  /**
   * Hold SDP modifier
   * Note: we are unaware if intercepting incoming or outgoing re-invites
   */
  holdModifier(description: RTCSessionDescriptionInit): Promise<RTCSessionDescriptionInit> {
    if (!description.sdp || !description.type) {
      throw new Error("Invalid SDP");
    }
    let sdp = description.sdp;
    const type = description.type;
    const streamDirection = sdp.match(SDP_ATTR_STREAM_DIRECTION_REGEX);

    if (type === "offer") {
      switch (holdRetrieveType) {
        case HoldRetrieveType.BySendOnlyRecvOnly:
          if (streamDirection) {
            sdp = sdp.replace(/a=sendrecv\r\n/g, "a=sendonly\r\n");
            sdp = sdp.replace(/a=recvonly\r\n/g, "a=inactive\r\n");
          } else {
            sdp = sdp.replace(/(m=[^\r]*\r\n)/g, "$1a=sendonly\r\n");
          }
          break;
        case HoldRetrieveType.ByInactive:
          if (streamDirection) {
            sdp = sdp.replace(/a=sendrecv\r\n/g, "a=inactive\r\n");
            sdp = sdp.replace(/a=sendonly\r\n/g, "a=inactive\r\n");
            sdp = sdp.replace(/a=recvonly\r\n/g, "a=inactive\r\n");
          } else {
            sdp = sdp.replace(/(m=[^\r]*\r\n)/g, "$1a=inactive\r\n");
          }
          break;
      }
    }
    const streamDirectionAfterModification = sdp.match(SDP_ATTR_STREAM_DIRECTION_REGEX);
    log.debug(
      `holdModifier/${type}/${holdRetrieveType}: ${streamDirection?.[0]} => ${streamDirectionAfterModification?.[0]}`
    );

    return Promise.resolve({ sdp, type });
  }

  /**
   * Retrieve SDP modifier
   * Note: we are unaware if intercepting incoming or outgoing re-invites
   */
  retrieveModifier(description: RTCSessionDescriptionInit): Promise<RTCSessionDescriptionInit> {
    if (!description.sdp || !description.type) {
      throw new Error("Invalid SDP");
    }
    let sdp = description.sdp;
    const type = description.type;
    const streamDirection = sdp.match(SDP_ATTR_STREAM_DIRECTION_REGEX);

    if (type === "offer") {
      switch (holdRetrieveType) {
        case HoldRetrieveType.BySendOnlyRecvOnly:
          if (streamDirection) {
            sdp = sdp.replace(/a=inactive\r\n/g, "a=recvonly\r\n");
            sdp = sdp.replace(/a=sendonly\r\n/g, "a=sendrecv\r\n");
          } else {
            sdp = sdp.replace(/(m=[^\r]*\r\n)/g, "$1a=sendrecv\r\n");
          }
          break;
        case HoldRetrieveType.ByInactive:
          if (streamDirection) {
            // sdp = sdp.replace(/a=inactive\r\n/g, "a=sendrecv\r\n");
            // sdp = sdp.replace(/a=sendonly\r\n/g, "a=sendrecv\r\n");

            // Special fix for:
            // 1) Peer puts local on hold
            // 2) Local puts peer on hold
            // 3) Peer retrives the call
            // 4) Local retrieves the call
            // 5) Here, local intercepts outgoing INVITE from 4) and replaces a=recvonly => a=sendrecv
            // *) log of interest: retrieveModifier/offer/1: a=recvonly => a=sendrecv

            sdp = sdp.replace(/a=recvonly\r\n/g, "a=sendrecv\r\n");
          } else {
            sdp = sdp.replace(/(m=[^\r]*\r\n)/g, "$1a=sendrecv\r\n");
          }
          break;
      }
    }

    const streamDirectionAfterModification = sdp.match(SDP_ATTR_STREAM_DIRECTION_REGEX);
    log.debug(
      `retrieveModifier/${type}/${holdRetrieveType}: ${streamDirection?.[0]} => ${streamDirectionAfterModification?.[0]}`
    );

    return Promise.resolve({ sdp, type });
  }

  /**
   * Create SDP options
   */
  createSessionInviteOptions(
    callId: string,
    type: CallMediaType,
    inviteSdpModifier: InviteSdpModifier,
    outbound: boolean
  ): SessionInviteOptions | InvitationAcceptOptions {
    const sessionDescriptionHandlerOptions =
      inviteSdpModifier === InviteSdpModifier.Hold
        ? {
            ...this._sdhOptions,
            constraints: this._createMediaConstraint(type),
          }
        : {
            constraints: undefined,
          };

    const sessionDescriptionHandlerModifiers = [Web.stripG722, stripCN];

    switch (inviteSdpModifier) {
      case InviteSdpModifier.Hold:
        sessionDescriptionHandlerModifiers.push(this.holdModifier);
        break;
      case InviteSdpModifier.Retrieve:
        sessionDescriptionHandlerModifiers.push(this.retrieveModifier);
        break;
      default:
        break;
    }

    log.debug(`createSessionInviteOptions with Modifier/${inviteSdpModifier}`);

    return {
      sessionDescriptionHandlerOptions,
      sessionDescriptionHandlerModifiers,
      ...(outbound && { requestDelegate: this.createSessionInviteDelegate(callId) }),
    };
  }

  /**
   * Failed invite handling
   */
  inviteRecvResponse(callId: string, response: IncomingResponse, cbName: string) {
    const responseString = `${response.message.statusCode}/${response.message.reasonPhrase}`;
    log.debug(`inviteRecvResponse: ${cbName} => ${responseString}`);
    const sipCall = this.getCall(callId, false);
    if (sipCall && sipCall.active) {
      if (sipCall.callingState === CallingState.TERMINATING) {
        const statusCode = response.message.statusCode || 0;
        if (statusCode >= 400 && statusCode < 700) {
          switch (statusCode) {
            case SipStatusCode.BusyHere:
            case SipStatusCode.TemporarilyUnavailable:
            case SipStatusCode.ServiceUnavailable:
              this.playToneInCall(Tones.Busy);
              break;
            default:
              this.playToneInCall(Tones.Error);
              break;
          }
        }
      }
    }
  }

  /**
   * Create OutgoingRequestDelegate
   */

  createSessionInviteDelegate(callId: string): OutgoingRequestDelegate {
    return {
      onAccept: (response: IncomingResponse) => {
        // Received a 2xx positive final response to this request.
        this.inviteRecvResponse(callId, response, "onAccept");
      },
      onProgress: (response: IncomingResponse) => {
        // Received a 1xx provisional response to this request. Excluding 100 responses.
        this.inviteRecvResponse(callId, response, "onProgress");
      },
      onRedirect: (response: IncomingResponse) => {
        // Received a 3xx negative final response to this request.
        this.inviteRecvResponse(callId, response, "onRedirect");
      },
      onReject: (response: IncomingResponse) => {
        // Received a 4xx, 5xx, or 6xx negative final response to this request.
        this.inviteRecvResponse(callId, response, "onReject");
      },
      onTrying: (response: IncomingResponse) => {
        // Received a 100 provisional response.
        this.inviteRecvResponse(callId, response, "onTrying");
      },
    };
  }

  /**
   * Create audio/video constraints
   * @param type
   * @returns
   */
  _createMediaConstraint(type: CallMediaType): MediaStreamConstraints {
    const msg = this.getMsg("_createMediaConstraint");
    log.debug(`${msg}: type=${type}`);
    const { savedLocalCamera, savedLocalMicrophone } = this._mediaDevices;
    let constraint: MediaStreamConstraints = {
      audio: {
        deviceId: savedLocalMicrophone ? { ideal: savedLocalMicrophone } : undefined,
      },
      video: false,
    };
    try {
      switch (type) {
        case CallMediaType.video:
          constraint.video = {
            deviceId: savedLocalCamera ? { ideal: savedLocalCamera } : undefined,
          };
          return constraint;
        case CallMediaType.audio:
        default:
          return constraint;
      }
    } catch (e) {
      log.error(`${msg}: failed: ${e.message}`);
    }
    return constraint;
  }
}

if (!window.sipUser) {
  window.sipUser = new SipUser();
}
export const sipUser: SipUser = window.sipUser;

export default sipUser as SipUser;
