import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/root-reducer";
import { useTranslation } from "react-i18next";
import styled from "themes/theme/baseTheme";
import { useTheme } from "@mui/material/styles";
import Dialog from "components/_shared/Dialog";
import { MatrixRoom } from "types/Matrix";
import { MessageList } from "components";
import RoomFooter from "views/room/RoomFooter";
import { useMatrixRooms } from "hooks";
import { createSipDisplayName, test } from "utils/helpers";
import { PbxUserData } from "services/PBXServerApi/types/PbxUsers";
import { getStatusMessage } from "services/PresenceProvider/PresenceProvider";
import { DialoutCallTransferActions } from ".";
import AvatarWithLabels from "components/_shared/AvatarWithLabels";
import "./DialoutCallTransfer.scss";

export const StyledCallTransferDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "530px",
    height: "653px",
    maxWidth: "530px",
    "& .MuiIconButton-root:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
}));

interface DialoutCallChatProps {
  data: { user: PbxUserData; roomId: string };
  openDialog: boolean;
  handleDialogClose: () => void;
  handleDialogBack: () => void;
}

export const DialoutCallTransferChat = ({
  data,
  openDialog,
  handleDialogClose,
  handleDialogBack,
}: DialoutCallChatProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const { getRoom } = useMatrixRooms();

  const [currentRoom, setCurrentRoom] = useState<MatrixRoom | undefined>(undefined);

  const TitleComponent = () => {
    const { firstName, lastName, presence } = data.user;
    const { status, avatarUrl } = presence;
    const displayName = createSipDisplayName(firstName, lastName);
    return (
      <div className="chatTitleComponent">
        <div className="chatContactUser">
          <AvatarWithLabels
            id={`call-transfer-avatar-${data.roomId}`}
            src={avatarUrl ?? ""}
            name={displayName}
            disableBadge={false}
            isPerson
            status={status}
            statusMsg={t(getStatusMessage(status).toUpperCase())}
            size="40"
            sx={{ border: `1px solid ${theme.palette.neutrals.lightGrey3}"` }}
          />
          <div className="chatContactUserIcons">
            <DialoutCallTransferActions
              user={data.user}
              withText={false}
              size={25}
              consultDoneCallback={handleDialogClose}
            />
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!data) return;
    const room = getRoom(data.roomId);
    if (room) setCurrentRoom(room);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledCallTransferDialog
      id={"call-chat"}
      dialogTitle={<TitleComponent />}
      showIconClose={true}
      showIconBack={true}
      onBack={handleDialogBack}
      onClose={handleDialogClose}
      open={openDialog}
      {...test("TRANSFER_CALL_DIALOG")}
    >
      <div className={`container chatContent ${isDarkMode ? "chatContentDark" : ""}`}>
        {currentRoom && <MessageList room={currentRoom} />}
        {currentRoom && <RoomFooter room={currentRoom} memberList={null} />}
      </div>
    </StyledCallTransferDialog>
  );
};
