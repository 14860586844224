import { useState, useCallback, useEffect, useMemo } from "react";
import i18n from "translations/i18n";
import useLocalStorage from "./useLocalStorage";
import { useTranslation } from "react-i18next";
import { getLogger } from "logger/appLogger";
import { localStorageKeys } from "utils/constants";

interface useLanguageType {
  restoreLanguage: () => void;
  changeLanguage: (lang: string) => void;
  language: string;
  languages: string[];
}

export const useLanguage = (): useLanguageType => {
  const [language, setLanguage] = useLocalStorage(localStorageKeys.LANGUAGE, "en");
  const { i18n } = useTranslation();
  const logger = useMemo(() => getLogger(), []);
  const languages = ["en", "uk", "pt", "es", "fr", "de", "it"];

  const restoreLanguage = useCallback(() => {
    if (language !== i18n.language) {
      logger.info(`Setting language to '${language}'`);
      i18n.changeLanguage(language);
    }
  }, [language, i18n, logger]);

  const changeLanguage = (lang: string) => {
    if (languages.includes(lang)) {
      setLanguage(lang);
    } else {
      logger.error(`Language '${lang}' not found`);
    }
  };

  useEffect(() => {
    restoreLanguage();
  }, [restoreLanguage]);

  return {
    changeLanguage,
    restoreLanguage,
    language,
    languages,
  };
};

export default useLanguage;

export const useSystemDefaultName = () => {
  const { t } = useTranslation();

  return useCallback((item) => (item.id === "default" ? t("SYSTEM_DEFAULT") : item.name), [t]);
};

export const useLanguageDirection = () => {
  const [direction, setDirection] = useState(i18n.dir());

  i18n.on("languageChanged", function (lng) {
    setDirection(i18n.dir());
  });

  useEffect(() => {
    setDirection(i18n.dir());
  }, [direction]);

  return direction;
};
