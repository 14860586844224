import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import { Alert, AlertTitle, TextField } from "@mui/material";
import Switch from "components/_shared/Switch";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { test } from "utils/helpers";
import { useTranslation } from "react-i18next";

interface CreateRoomDialogProps {
  errorMessage: string;
  openDialog: boolean;
  isCreating: boolean;
  clearErrorMessage: () => void;
  handleDialogClose: () => void;
  handleDialogAgree: (isPrivate: boolean) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, isRoomNameChange: boolean) => void;
}

const CreateRoomDialog = ({
  openDialog,
  isCreating,
  errorMessage,
  clearErrorMessage,
  handleDialogClose,
  handleDialogAgree,
  handleChange,
}: CreateRoomDialogProps) => {
  const [openAlert, setopenAlert] = useState(false);
  const { t } = useTranslation();

  const [isPrivate, setIsPrivate] = useState(true);
  const [creatingMsg, setCreatingMsg] = useState<string>("");

  const onAction = (action: any, withArgs: boolean) => {
    if (withArgs) {
      action(isPrivate);
    } else {
      action();
    }

    setCreatingMsg(
      `${t("CREATING")} ${isPrivate ? t("PRIVATE").toLowerCase() : t("PUBLIC").toLowerCase()} ${t(
        "ROOM_1"
      ).toLowerCase()}`
    );

    // need to set the default of isPrivate,
    // because this component is on SideBarHeader and remains even after the dialog close
    setIsPrivate(true);
  };

  useEffect(() => {
    setopenAlert(Boolean(errorMessage));
  }, [errorMessage]);

  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="upload confirmation"
      open={openDialog}
      PaperProps={{ sx: { width: "600px", height: "260px" } }}
      {...test("CREATE_ROOM_DIALOG")}
    >
      <DialogTitle id="simple-dialog-title">{t("CREATE_ROOM")}</DialogTitle>
      <DialogContent>
        <Collapse in={openAlert}>
          <Alert
            onClose={() => {
              setopenAlert(false);
              clearErrorMessage();
            }}
            severity="error"
            style={{ marginBottom: "1rem" }}
          >
            <AlertTitle>{t("FAILEDTOCREATEROOM")}</AlertTitle>
            {errorMessage}
          </Alert>
        </Collapse>
        {isCreating ? (
          <DialogContentText id="alert-dialog-description">{creatingMsg}</DialogContentText>
        ) : (
          <div>
            <TextField
              id="room-name-input"
              variant="filled"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, true)}
              name="roomName"
              className="my-2"
              label={t("ROOMNAME")}
            />
            {/* <TextField
              id="room-description-input"
              variant="filled"
              multiline={true}
              rows={3}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, false)}
              label={t("ROOMDESCRIPTION")}
            /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Switch
                color="primary"
                checked={isPrivate}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => setIsPrivate(checked)}
                name="room_type"
                label={t("PRIVATE_ROOM")}
                {...test("PRIVATE_ROOM_TOGGLE")}
              />
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          id="create_room_dialog_cancel_button"
          variant="outlined"
          onClick={() => onAction(handleDialogClose, false)}
          disabled={isCreating}
        >
          {t("CANCEL")}
        </Button>
        <Button
          id="create_room_dialog_create_button"
          onClick={() => onAction(handleDialogAgree, true)}
          autoFocus
          disabled={isCreating}
        >
          {t("CREATE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRoomDialog;
