import { Components, Theme } from "@mui/material";
import { inputClasses } from "@mui/material/Input";

const MuiInputLabel: Components["MuiInputLabel"] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const palette = (theme as Theme).palette;
      return {
        backgroundColor: palette.background.filledInputBackground,
        color: palette.text.secondary,
        [`&.${inputClasses.focused}`]: {
          color: palette.text.focused,
        },
        [`&.${inputClasses.disabled}`]: {
          color: palette.text.disabled,
        },
        [`&.${inputClasses.error}`]: {
          color: palette.text.error,
        },
      };
    },
  },
};

export default MuiInputLabel;
