import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { getLogger } from "logger/appLogger";
import { useAppSelector } from "store/hooks";
import { matrixActions } from "store/actions/matrix";
import { themeActions } from "store/actions/theme";

import styled from "themes/theme/baseTheme";

import SideBarHeader from "./SideBarHeader";
import SideBarContent from "./SideBarContent";
import SideBarFooter from "./SideBarFooter";

import Drawer from "@mui/material/Drawer";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useRouter, useSearchByListOfUsernamesProvisioning, useMatrixRooms, useMatrixMembers } from "hooks";
import { MatrixUserProvider } from "services/MatrixAPIProvider";

import { test, filterList } from "utils/helpers";
import { MatrixRoomTypes, RoomListFilters } from "types/Matrix";

export const SideBarDrawerWidth = 375;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: SideBarDrawerWidth,
    flexShrink: 0,
  },
  position: "relative",
  height: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  marginRight: "auto",
}));

const SideBar = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const logger = useMemo(() => getLogger("sidebar"), []);

  const { toggleDrawer, canToggleDrawer } = themeActions;
  const { getUsers } = MatrixUserProvider();
  const { pathname } = useRouter();
  const { isGroup, updateRoomsName } = useMatrixRooms();
  const { checkRoomMembersToSearch } = useMatrixMembers();
  const { searchResults, search } = useSearchByListOfUsernamesProvisioning();

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const currentUser = useAppSelector((state) => state.matrix.currentUser);
  const openDrawer = useAppSelector((state) => state.theme.openDrawer);
  const canToggle = useAppSelector((state) => state.theme.canToggleDrawer);
  const notificationCount = useAppSelector((state) => state.app.notificationCount);
  const roomList = useAppSelector((state) => state.matrix.roomList);

  const [isMeeting, setIsMeeting] = useState();
  const [isSettings, setIsSettings] = useState();
  const [isAddingContacts, setIsAddingContacts] = useState();
  const [isDialpad, setIsDialpad] = useState();
  const [currentRoomList, setCurrentRoomList] = useState(roomList);
  const [userList, setUserList] = useState([]);
  const [usernamesToSearch, setUsernamesToSearch] = useState([]);

  const handleFilterChange = (e) => {
    try {
      const value = e.target.value;
      if (value.length <= 0) {
        setCurrentRoomList(roomList);
        return;
      }
      const filteredRooms = Object.assign({}, roomList);
      for (let i in filteredRooms) {
        const list = filterList(value, filteredRooms[i], "name");
        filteredRooms[i] = list;
      }
      setCurrentRoomList(filteredRooms);
    } catch (e) {
      logger.error(`Error in SideBar.handleFilterChange - event:${e} - ${e.message}`);
    }
  };

  const handleDrawerToggle = () => {
    dispatch(toggleDrawer(false));
  };

  /**
   * This function validate the room name. If the display name is wrong, sets the username to search
   * @param {list of rooms to verify the name } data
   */
  const checkRoomsUsers = (data) => {
    const listUsernamesToSearch = [];
    const matrixUsers = getUsers()?.filter((user) => user.userId !== currentUser?.userId);

    [...data].forEach((room) => {
      if (room.type === MatrixRoomTypes.direct || (room.type === MatrixRoomTypes.private && isGroup(room.roomId))) {
        const newUsers = checkRoomMembersToSearch(room, matrixUsers ? matrixUsers : []);

        newUsers.forEach((username) => {
          const alreadyToSearch = listUsernamesToSearch.find((user) => user === username);

          if (!alreadyToSearch) {
            listUsernamesToSearch.push(username);
          }
        });
      }
    });

    if (listUsernamesToSearch && listUsernamesToSearch.length > 0) {
      setUsernamesToSearch(listUsernamesToSearch);
    }
  };

  useEffect(() => {
    setCurrentRoomList(roomList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!roomList) return;
    setCurrentRoomList(roomList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomList]);

  useEffect(() => {
    dispatch(toggleDrawer(matches));
    dispatch(canToggleDrawer(!matches));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  useEffect(() => {
    if (!currentRoomList.private || !currentRoomList.contacts || !currentRoomList.favorites) return;

    let roomsToCheck = [];

    if (currentRoomList.private.length > 0) roomsToCheck = [...roomsToCheck, ...currentRoomList.private];

    if (currentRoomList.contacts.length > 0) roomsToCheck = [...roomsToCheck, ...currentRoomList.contacts];

    if (currentRoomList.favorites.length > 0) roomsToCheck = [...roomsToCheck, ...currentRoomList.favorites];

    checkRoomsUsers(roomsToCheck);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomList.private.length, currentRoomList.contacts.length, currentRoomList.favorites.length]);

  useEffect(() => {
    if (!usernamesToSearch || (usernamesToSearch && usernamesToSearch.length < 1)) return;

    search(usernamesToSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usernamesToSearch]);

  useEffect(() => {
    if (!searchResults || searchResults.length === 0) return;

    if (currentRoomList) {
      let roomsToCheck = [];
      if (currentRoomList.private.length > 0) roomsToCheck = [...roomsToCheck, ...currentRoomList.private];

      if (currentRoomList.contacts.length > 0) roomsToCheck = [...roomsToCheck, ...currentRoomList.contacts];

      if (currentRoomList.favorites.length > 0) roomsToCheck = [...roomsToCheck, ...currentRoomList.favorites];

      checkRoomsUsers(roomsToCheck);
      updateRoomsName(roomsToCheck);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults]);

  useEffect(() => {
    if (pathname === "/meeting" || pathname === "/code") {
      setIsAddingContacts(false);
      setIsMeeting(true);
      setIsDialpad(false);
      setIsSettings(false);
    } else if (pathname.startsWith("/settings")) {
      setIsAddingContacts(false);
      setIsMeeting(false);
      setIsDialpad(false);
      setIsSettings(true);
    } else if (pathname === "/dialpad") {
      setIsAddingContacts(false);
      setIsMeeting(false);
      setIsDialpad(true);
      setIsSettings(false);
    } else {
      setIsAddingContacts(false);
      setIsMeeting(false);
      setIsDialpad(false);
      setIsSettings(false);
      // only clean the filter if the pathname is home
      if (pathname === "/home") {
        dispatch(matrixActions.setRoomListFilter(RoomListFilters.all));
      }
      setCurrentRoomList(roomList);
    }
    setUserList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className="sidebar-container main-sidebar" {...test("SIDEBAR")}>
      <StyledDrawer
        sx={{ display: { xs: "block", sm: "block", md: "none" } }}
        anchor="left"
        open={canToggle ? openDrawer : false}
        variant="temporary"
        onClose={handleDrawerToggle}
        transitionDuration={300}
        className={`main-sidebar`}
        PaperProps={{ sx: { width: SideBarDrawerWidth } }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <SideBarHeader
          isAddingContacts={isAddingContacts}
          isMeeting={isMeeting}
          isSettings={isSettings}
          isDialpad={isDialpad}
        />
        <SideBarContent
          currentUser={currentUser}
          roomList={currentRoomList}
          isAddingContacts={isAddingContacts}
          userList={userList}
          isMeeting={isMeeting}
          isSettings={isSettings}
          isDialpad={isDialpad}
        />
        <SideBarFooter notificationCount={notificationCount} />
      </StyledDrawer>
      <StyledDrawer
        sx={{ display: { xs: "none", sm: "none", md: "block" } }}
        anchor="left"
        open
        variant="permanent"
        className={`main-sidebar`}
        PaperProps={{ sx: { width: SideBarDrawerWidth } }}
      >
        <SideBarHeader
          isAddingContacts={isAddingContacts}
          isMeeting={isMeeting}
          isSettings={isSettings}
          isDialpad={isDialpad}
          onFilterRooom={handleFilterChange}
        />
        <SideBarContent
          currentUser={currentUser}
          roomList={currentRoomList}
          isAddingContacts={isAddingContacts}
          userList={userList}
          isMeeting={isMeeting}
          isSettings={isSettings}
          isDialpad={isDialpad}
        />
        <SideBarFooter notificationCount={notificationCount} />
      </StyledDrawer>
    </div>
  );
};

export default SideBar;
