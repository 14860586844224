import { useEffect, useMemo, useState } from "react";
import { getLogger } from "logger/appLogger";
import { useInterval } from "hooks";
import { getCallAPIProvider } from "services/CallAPIProvider";
import { isDebugLogger } from "logger/logger";

const POLLING_INTERVAL = 10000;

const copyFieldsSubset = (rtcStats: RTCStats, type: RTCStatsType, fieldNames: string[]) => {
  let dstObj: any = null;
  if (rtcStats.type === type) {
    dstObj = {};
    fieldNames.forEach((fieldName) => {
      if (fieldName in rtcStats) {
        const fieldTyped = fieldName as keyof typeof rtcStats;
        dstObj[fieldName] = rtcStats[fieldTyped];
      }
    });
  }
  return dstObj;
};

export const useWebRTCStats = (isCallActive: boolean, callRoomId: string) => {
  const logger = useMemo(() => getLogger("webrtc-stats"), []);
  const storageLogger = useMemo(
    () => getLogger("webrtc-stats/logstore", { consoleOutput: false, logStorageOutput: true }),
    []
  );

  const [isCollecting, setIsCollecting] = useState(false);
  const vidyoConnector = window.appConfig.REACT_APP_WEBRTC_STATS_ENABLED ? getCallAPIProvider().vidyoConnector : null;

  const getWebRTCStats = () => {
    vidyoConnector.GetWebRTCStats().then((statsReport: RTCStatsReport) => {
      let entry: any = {};
      statsReport.forEach((stats: RTCStats, key: string) => {
        const e =
          copyFieldsSubset(stats, "transport", ["type", "timestamp", "bytesSent", "bytesReceived"]) ||
          copyFieldsSubset(stats, "outbound-rtp", [
            "type",
            "timestamp",
            "kind",
            "nackCount",
            "firCount",
            "pliCount",
            "qualityLimitationReason",
            "qualityLimitationDurations",
          ]) ||
          copyFieldsSubset(stats, "remote-inbound-rtp", [
            "type",
            "timestamp",
            "kind",
            "jitter",
            "packetsLost",
            "roundTripTime]",
          ]);
        if (e) {
          entry[key] = e;
        }
      });
      storageLogger.debug("webrtc-stats", entry);
    });
  };

  const getVidyoStats = () => {
    vidyoConnector.GetStatsJson().then((stats: any) => {
      const data = JSON.parse(stats);
      const entry = {
        stream: {
          User: data.userStats[0].id,
          Roomkey: callRoomId,
          Router: data.userStats[0].roomStats[0].reflectorId.split(":")[1],
          AppType: window.appConfig.REACT_APP_NAME,
        },
        data,
      };
      storageLogger.debug("vidyo-stats", entry);
    });
  };

  useEffect(() => {
    return () => {
      if (vidyoConnector && isCollecting) {
        logger.debug("stop");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollecting, vidyoConnector]);

  useInterval(() => {
    if (vidyoConnector && isCallActive) {
      if (!isCollecting) {
        setIsCollecting(true);
        logger.debug("start");
      }
      if (isDebugLogger(storageLogger)) {
        getWebRTCStats();
        getVidyoStats();
      }
    }
  }, POLLING_INTERVAL);

  return {};
};

export default useWebRTCStats;
