import { getLogger } from "logger/appLogger";
import { isEmpty } from "utils/helpers";
import { PbxEvent, PbxEventListenerDelegate, PbxEventListenerType, PbxEventSubscription } from "../types/EventListener";

class DefaultDelegate implements PbxEventListenerDelegate {
  onEvent(event: PbxEvent): void {}
}

enum Errors {
  EmptySubscriptionId = "Empty subscription id",
  InvalidSubscriptionFormat = "Invalid subscription format",
  BaseWsUrlNotSet = "Base Ws Url not set",
}

const PBX_EVENT_LISTENER_PROTOCOL_VERSION: number = 1;

interface PbxEventFromListener {
  version: number;
  type: PbxEventListenerType;
  subscriptionId: string;
  callId: string;
  callMsg?: {
    originator: string;
    callQueue: string;
    agent: string;
    timeAnswer: any;
    origCallInfo: string;
    termCallInfo: string;
  };
}

export class PBXEventListenerProvider {
  private logger = getLogger("pbx.event-listener");
  private connections = new Map<string, WebSocket>();
  private delegate: PbxEventListenerDelegate = new DefaultDelegate();
  private baseWsUrl: string | null = null;

  private static instance: PBXEventListenerProvider;
  static get Instance(): PBXEventListenerProvider {
    if (!this.instance) {
      this.instance = new PBXEventListenerProvider();
    }
    return this.instance;
  }

  private constructor() {}

  public setDelegate(delegate: PbxEventListenerDelegate) {
    this.delegate = { ...this.delegate, ...delegate };
  }

  public setBaseWsUrl(baseWsUrl: string) {
    this.logger.debug(`setBaseWsUrl '${baseWsUrl}'`);

    if (isEmpty(baseWsUrl)) {
      this.throwError(Errors.BaseWsUrlNotSet, "setBaseWsUrl");
    }

    if (!baseWsUrl.startsWith("wss://")) {
      baseWsUrl = `wss://${baseWsUrl}`;
    }
    this.baseWsUrl = baseWsUrl;
  }

  private throwError(error: Errors, msg: string) {
    const errorMsg = `${msg} ${error.toString()}`;
    this.logger.error(errorMsg);
    throw new Error(errorMsg);
  }

  public removeSubscription(subscriptionId: string, doClose: boolean) {
    const msg = "removeSubscription";
    this.logger.debug(`${msg}: subscriptionId: ${subscriptionId}`);

    if (isEmpty(subscriptionId)) {
      this.throwError(Errors.EmptySubscriptionId, msg);
    }

    const websocket = this.connections.get(subscriptionId);

    if (websocket) {
      if (!this.connections.delete(subscriptionId)) {
        this.logger.warn(`${msg}: subscriptionId '${subscriptionId}' not found`);
      }

      if (doClose) {
        try {
          websocket?.close();
        } catch (e) {
          this.logger.warn(`${msg}: subscriptionId '${subscriptionId}' close socket failed with ${e}`);
        }
      }
    }
  }

  public createSubscription(subscription: PbxEventSubscription, type: PbxEventListenerType) {
    const msg = "createSubscription";
    try {
      this.logger.debug(`${msg} subscription=${JSON.stringify(subscription)}`);

      if (isEmpty(this.baseWsUrl)) {
        this.throwError(Errors.BaseWsUrlNotSet, msg);
      }
      if (!subscription) {
        this.throwError(Errors.InvalidSubscriptionFormat, msg);
      }
      if (isEmpty(subscription.subscriptionId)) {
        this.throwError(Errors.EmptySubscriptionId, msg);
      }
      if (isEmpty(subscription.notificationUrl)) {
        this.throwError(Errors.InvalidSubscriptionFormat, msg);
      }

      let { subscriptionId, notificationUrl } = subscription;

      const apiWord = "/api/";
      const notificationUrlPbxApi = notificationUrl.slice(notificationUrl.indexOf(apiWord));
      notificationUrl = this.baseWsUrl + notificationUrlPbxApi;

      const msgConn = `callback subscription ${notificationUrl}`;

      this.logger.debug(`${msg}: opening websocket '${notificationUrl}'`);
      const websocket = new WebSocket(notificationUrl);

      websocket.onopen = () => {
        this.logger.debug(`${msgConn} : onOpen`);
        this.connections.set(subscriptionId, websocket);
      };

      websocket.onclose = (e) => {
        this.logger.debug(`${msgConn} : onClose code=${e.code}`);
        this.removeSubscription(subscriptionId, false);
      };

      websocket.onerror = (error) => {
        this.logger.debug(`${msgConn} : onError error=${JSON.stringify(error)}`);
      };

      websocket.onmessage = (event) => {
        const msgError = `${msgConn} : onMessage error -`;
        this.logger.debug(`${msgConn} : onMessage message=${event?.data ? JSON.stringify(event.data) : ""}`);

        const pbxEventFromListener: PbxEventFromListener = event?.data ? JSON.parse(event?.data) : undefined;

        if (!pbxEventFromListener) {
          this.logger.warn(`${msgError} data parse failed`);
        } else if (pbxEventFromListener.version !== PBX_EVENT_LISTENER_PROTOCOL_VERSION) {
          this.logger.warn(
            `${msgError} version mismatch recv:${pbxEventFromListener.version} expected:${PBX_EVENT_LISTENER_PROTOCOL_VERSION}`
          );
        } else if (pbxEventFromListener.subscriptionId !== subscriptionId) {
          this.logger.warn(
            `${msgError} subscriptionId mismatch recv:${pbxEventFromListener.subscriptionId} expected:${subscriptionId}`
          );
        } else {
          switch (pbxEventFromListener.type) {
            case PbxEventListenerType.Call:
              if (!pbxEventFromListener.callMsg) {
                this.logger.warn(`${msgError} call message empty`);
              } else {
                if (
                  pbxEventFromListener.callMsg.origCallInfo === "active" &&
                  pbxEventFromListener.callMsg.termCallInfo === "active"
                ) {
                  const messageEvent: PbxEvent = {
                    callId: pbxEventFromListener.callId,
                    subscriptionId,
                    peerExtension: pbxEventFromListener.callMsg.agent,
                  };
                  this.delegate.onEvent(messageEvent);
                }
              }
              break;
            default:
              this.logger.warn(`${msgError} unhandled message type: '${pbxEventFromListener.type}'`);
          }
        }
      };
    } catch (e) {
      this.logger.error(`${msg} subscriptionId=${subscription?.subscriptionId} error=${e}`);
      throw e;
    }
  }
}
