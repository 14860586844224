import { Components } from "@mui/material";

const MuiLink: Components["MuiLink"] = {
  styleOverrides: {
    root: {
      fontSize: "14px",
      lineHeight: "22px",
    },
  },
};

export default MuiLink;
