import React, { useEffect, useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { localStorageKeys } from "utils/constants";
import styled from "themes/theme/baseTheme";
import styles from "./ApplicationScreen.module.scss";
import { SideBar, SplashScreen, ToggleDrawer } from "components";
import { CallNotification } from "components/Notifications";
import AdHocNotification from "components/Notifications/AdHoc/AdHocNotification";

import {
  useLanguage,
  useMatrix,
  useMatrixAvatar,
  useMatrixRooms,
  useNotification,
  useRouter,
  useSip,
  useMatrixJoinConference,
  useHandleMatrixEvents,
  useEventListener,
} from "hooks";

import CallPage from "containers/CallPage/CallPage";
import isElectron from "is-electron";
import { getLogger } from "logger/appLogger";
import { startMemoryMonitor } from "utils/memoryMonitor";
import { useHistory, useLocation } from "react-router-dom";
import { PBXEventListenerProvider } from "services/PBXServerApi/Providers/PBXEventListenerProvider";
import { getPresenceProvider } from "services/PresenceProvider";
import sipUser from "services/SIPProvider/SIPProvider";
import { StatusCode } from "status-code-enum";
import * as appActionCreators from "store/actions/app";
import { matrixActions } from "store/actions/matrix";
import { sipActions } from "store/actions/sip";
import { useAppSelector } from "store/hooks";
import { getSipCallActive, getSipCallsHeld, selectDetached, selectDialpadActive } from "store/selectors";
import { CallMediaType, CallType, Events } from "types/UC";
import WatchCalendar from "utils/WatchCalendar";
import { test } from "utils/helpers";
import { migrationsCleanup } from "utils/migrationsCleanup";
import { JsonStringify } from "utils/utils";
import { useSnackbar } from "notistack";
import { useUserSettings } from "hooks/useUserSettings";
import GoogleProvider from "services/CalendarProvider/providers/GoogleProvider";

const StyledRightPage = styled("div")(({ theme }) => ({
  flexGrow: "1",
  maxWidth: "100%",
  maxHeight: "100vh",
  minHeight: "100vh",
  position: "relative",
  backgroundColor: theme.palette.background.secondary,
  "--linkColor": theme.palette.text.link,
}));

const ApplicationScreen = ({ children }) => {
  const { t } = useTranslation();
  const { restoreLanguage } = useLanguage();
  let history = useHistory();
  let location = useLocation();
  const { push } = useRouter();
  const dispatch = useDispatch();
  const dialpadActive = selectDialpadActive(location.pathname);

  const syncState = useAppSelector((state) => state.matrix.matrixSyncState);
  const matrixEvent = useAppSelector((state) => state.matrix.matrixEvent);
  const currentUser = useAppSelector((state) => state.matrix.currentUser);
  const hasActiveMatrixCall = useAppSelector((state) => state.matrix.hasActiveMatrixCall);
  const incomingMatrixCallList = useAppSelector((state) => state.matrix.incomingMatrixCallList);
  const matrixCallClientState = useAppSelector((state) => state.matrix.matrixCallClientState);
  const pbxConfig = useAppSelector((state) => state.pbx.pbxConfig);
  const vidyoCallActive = useAppSelector((state) => state.call.active);
  const vidyoCallLeaving = useAppSelector((state) => state.call.leaving);
  const outCall = useAppSelector((state) => state.call.outCall);
  const generateLogsFailed = useAppSelector((state) => state.app.generateLogsFailed);
  const generatingLogsSuccess = useAppSelector((state) => state.app.generatingLogsSuccess);
  const onlyRequestLogs = useAppSelector((state) => state.app.onlyRequestLogs);
  const userStatus = useAppSelector((state) => state.presence.currentPresence);
  const endpointData = useAppSelector((state) => state.provisioning.endpointData);
  const appInited = useAppSelector((state) => state.app.inited);
  const speakers = useAppSelector((state) => state.devices.speakers);
  const sipEnabled = useAppSelector((state) => state.sip.sipEnabled);
  const sipCallActive = useAppSelector((state) => getSipCallActive(state, true));
  const sipCallsHeld = useAppSelector((state) => getSipCallsHeld(state, dialpadActive));
  const detached = useAppSelector((state) => selectDetached(state, location.pathname));
  const incomingSipCalls = useAppSelector((state) => state.sip.incomingSipCalls);

  const vidyoOrMatrixCall = (vidyoCallActive || hasActiveMatrixCall) && !vidyoCallLeaving;
  const sipCall = !!sipCallActive;

  const shouldRejectIncomingMatrixCall = () =>
    userStatusRef.current === "dnd" ||
    userStatusRef.current === "presenting" ||
    userStatusRef.current === "conf" ||
    hasActiveMatrixCall ||
    syncState === "PREPARED";

  const [sipInvitationList, setSipInvitationList] = useState([]);
  const [listOfConferenceInfosToCleanup, setListOfConferenceInfosToCleanup] = useState([]);

  const { getRoomMemberAvatar } = useMatrixAvatar();
  const { showTrayNotification } = useNotification();
  const { client, startClient } = useMatrix("");
  const { deleteRoomLowPrio } = useMatrixRooms();
  const { enqueueSnackbar } = useSnackbar();
  const { getMeetingURLWithMeetingId } = useMatrixJoinConference();
  const {
    showGroupInviteNotification,
    groupInviteData,
    onAcceptGroupCall,
    onRejectGroupCall,
    fetchedPreparedRoomList,
  } = useHandleMatrixEvents(client, shouldRejectIncomingMatrixCall);
  const { leaveConferenceCall } = useSip();
  const { getUserSettings } = useUserSettings();

  const userStatusRef = useRef(userStatus);

  const listRef = useRef(incomingMatrixCallList);
  const sipListRef = useRef(sipInvitationList);
  const vidyoCallActiveRef = useRef(vidyoCallActive);
  const matrixCallActiveRef = useRef(hasActiveMatrixCall);

  const { extractGoogleCodeFromUrl } = GoogleProvider;

  const logger = useMemo(() => getLogger("application-screen"), []);
  const initializeMatrixClient = async () => {
    await startClient({ initialSyncLimit: 10 });

    dispatch(matrixActions.subscribeOnMatrixEvent("Call.incoming"));
  };

  WatchCalendar();

  const calculateInviterInfo = (invite) => {
    if (!invite.getOpponentMember()) {
      setTimeout(() => {
        return invite.getOpponentMember();
      }, 500);
    } else {
      return invite.getOpponentMember();
    }
  };

  useEffect(() => {
    vidyoCallActiveRef.current = vidyoCallActive;
  }, [vidyoCallActive]);

  useEffect(() => {
    matrixCallActiveRef.current = hasActiveMatrixCall;
  }, [hasActiveMatrixCall]);

  useEffect(() => {
    if (!sipInvitationList) return;
    logger.debug("SIP Call | incomingSIPCallInvatation", sipInvitationList);
    sipInvitationList.forEach((sipCallInfo) => {
      const buttons = ["Reject", "Accept"];
      const type = CallMediaType.audio;
      if (isElectron()) {
        const data = {
          callId: sipCallInfo.id,
          type,
          message: t("INCOMING_CALL_TYPE", { type: type === CallMediaType.audio ? t("AUDIO") : t("VIDEO") }),
          messageVia: t("CALL_VIA"),
          messageExtension: t("EXTENSION"),
          remoteIdentity: sipCallInfo.peer.name,
          remoteNumber: sipCallInfo.peer.user,
          viaIdentity: sipCallInfo.via?.name,
          viaNumber: sipCallInfo.via?.user,
          avatarUrl: null,
          sipCall: true,
          // TODO: will be enabled when the incoming call transfer works
          // footerAction: "CallTransfer",
          // footerActionMessage: t("TRANSFER_CALL"),
          buttons,
        };
        window.ipcRenderer.send("onIncomingNotification", data);
      }
    });
    sipListRef.current = sipInvitationList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sipInvitationList]);

  useEffect(() => {
    if (!incomingSipCalls) return;
    logger.debug("SIP Call | incomingSipCalls:", incomingSipCalls, " -- isElectron:", isElectron());
    if (isElectron()) {
      incomingSipCalls.forEach((incomingSipCall) => {
        const data = {
          callId: incomingSipCall.id,
          remoteIdentity: incomingSipCall.peer.name,
          viaIdentity: incomingSipCall.via?.name,
          avatarUrl: incomingSipCall.peer.avatarUrl,
        };

        window.ipcRenderer.send("onUpdateIncomingNotification", data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingSipCalls]);

  useEffect(() => {
    userStatusRef.current = userStatus;
  }, [userStatus]);

  // =======================================================
  // STARTUP / TEARDOWN
  // =======================================================
  useEffect(() => {
    restoreLanguage();
    migrationsCleanup();
    initializeMatrixClient();
    dispatch(matrixActions.getCurrentUserProfileInfo());
    startMemoryMonitor();

    if (isElectron()) {
      window.ipcRenderer.on("onJoin", handleOnJoin);
      window.ipcRenderer.on("setCode", handleSetCode);

      const userSettings = getUserSettings();
      if (userSettings) {
        window.ipcRenderer.send("updateUserConfig", userSettings);
      }
    }

    return () => {
      if (isElectron()) {
        window.ipcRenderer.removeListener("onJoin", handleOnJoin);
        window.ipcRenderer.removeListener("setCode", handleSetCode);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetCode = (event, arg) => {
    logger.debug(`setCode event: ${arg}`);
    extractGoogleCodeFromUrl(arg, "application-screen/electron");
  };

  const handleOnJoin = (event, arg) => {
    logger.debug(`onJoin event: ${arg}`);
    joinRoom(arg);
  };

  const joinRoom = async (arg) => {
    try {
      logger.debug("joinRoom arg:", arg);
      var queryString = arg.substring(arg.indexOf("?") + 1);
      const params = new URLSearchParams(queryString);
      const meetingId = params.get("meetingId");

      if (!queryString || !params || !meetingId) {
        logger.log("Invalid joinRoom parameters");
        return;
      }

      const meetingLink = await getMeetingURLWithMeetingId(meetingId);

      if (!meetingLink) {
        logger.log("Invalid joinRoom meeting parameters");
        return;
      }
      const encodedLink = Buffer.from(meetingLink).toString("base64");
      logger.debug("joinRoom encodedLink:", encodedLink);
      await dispatch(appActionCreators.setJoinRoomUrl(encodedLink));
      push("/home");
    } catch (e) {
      logger.error(`Error in ApplicationScreen.joinRoom event - ${e.message}`);
    }
  };

  // =======================================================
  // Settings Help (Submit issue)
  // =======================================================
  useEffect(() => {
    if (!generateLogsFailed) return;

    let errorMessage = "";
    if (generateLogsFailed.status) {
      switch (generateLogsFailed.status) {
        case StatusCode.ClientErrorPayloadTooLarge:
          errorMessage = t("FILE_TOO_LARGE");
          break;
        default:
          errorMessage = t("ISSUE_SUBMITTED_FAILED");
      }
    }

    enqueueSnackbar(errorMessage, {
      anchorOrigin: {
        horizontal: "center",
        vertical: "bottom",
      },
      variant: "error",
      onClose: () => dispatch(appActionCreators.cleanGenerateLogsError()),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateLogsFailed]);

  useEffect(() => {
    if (!generatingLogsSuccess || onlyRequestLogs) return;
    logger.debug(`${module}: Generating logs success`);
    enqueueSnackbar(t("ISSUE_SUBMITTED_SUCCESSFULLY"), {
      anchorOrigin: {
        horizontal: "center",
        vertical: "bottom",
      },
      variant: "success",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatingLogsSuccess]);
  // =======================================================

  useEffect(() => {
    if (!matrixEvent) return;
    const [eventName, state] = matrixEvent;
    if (eventName === "Call.incoming") {
      if (shouldRejectIncomingMatrixCall()) {
        setTimeout(() => {
          dispatch(matrixActions.rejectMatrixCall(state));
        }, 500);
      } else {
        dispatch(matrixActions.incomingMatrixCall(state));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrixEvent]);

  useEffect(() => {
    if (!incomingMatrixCallList) return;
    listRef.current = incomingMatrixCallList;
    logger.debug("P2P Call | incomingMatrixCallList", incomingMatrixCallList);
    if (incomingMatrixCallList.length > 0) {
      if (hasActiveMatrixCall) {
        incomingMatrixCallList.forEach((invite) => {
          deleteRoomLowPrio(invite.roomId);
          dispatch(matrixActions.rejectMatrixCall(invite));
        });
      } else {
        dispatch(matrixActions.playMatrixCallRingtone());
        incomingMatrixCallList.forEach(async (invite) => {
          const { callId, type, roomId } = invite;
          deleteRoomLowPrio(roomId);
          if (isElectron()) {
            const { name, userId } = invite.getOpponentMember();
            const avatar = getRoomMemberAvatar(roomId, userId);
            const buttons = ["Reject", "Accept"];
            const data = {
              callId,
              type,
              message: t("INCOMING_CALL_TYPE", { type: type === CallType.voice ? t("AUDIO") : t("VIDEO") }),
              remoteIdentity: name,
              avatarUrl: avatar ?? "",
              buttons,
            };
            window.ipcRenderer.send("onIncomingNotification", data);
          }
        });
      }
    } else {
      if (!showGroupInviteNotification) {
        dispatch(matrixActions.stopMatrixCallRingtone());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingMatrixCallList]);

  useEffect(() => {
    if (!matrixCallClientState) return;
    logger.debug(`Update of matrixCallClientState - ${matrixCallClientState}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrixCallClientState]);

  useEffect(
    () => {
      dispatch(appActionCreators.initApp());
      logger.debug(`Current URL is ${location.pathname}: ${JSON.stringify(location.state || {})}`);
      history.listen((location) => {
        logger.debug(`URL changed to ${location.pathname}: ${JSON.stringify(location.state || {})}`);
      });
      if (isElectron()) {
        window.ipcRenderer.on("onIncomingNotifAck", (event, arg) => {
          const { isAccepted, choice, data } = arg;
          const { callId, sipCall, groupChat } = data;
          if (sipCall) {
            const invObject = sipListRef.current.find((inv) => inv.id === callId);
            if (invObject) {
              if (choice === "CallTransfer") {
                // TODO: call transfer of incoming call
              } else if (isAccepted) {
                onSIPAccept(invObject);
              } else {
                onSIPReject(invObject);
              }
            } else {
              logger.error(
                `Error in ApplicationScreen.onIncomingNotifAck event, callId ${callId} not found in invitation list`
              );
            }
          } else if (groupChat) {
            const roomId = callId;
            if (isAccepted) {
              onAcceptGroupCall(roomId, choice);
            } else {
              onRejectGroupCall();
            }
          } else {
            const invite = listRef.current.find((inv) => inv.callId === callId);
            if (!invite) return;
            if (isAccepted) {
              onAcceptMatrixCall(invite);
            } else {
              onRejectMatrixCall(invite);
            }
          }
        });

        window.ipcRenderer.on("onLogData", (event, arg) => {
          if (!arg) return;
          dispatch(appActionCreators.generateLogs(arg));
        });

        window.ipcRenderer.on("onAwake", (event) => {
          try {
            logger.debug("onAwake event");
            const presenceProvider = getPresenceProvider();
            if (!presenceProvider?.presenceApi?.connection) return;
            const { presenceApi } = presenceProvider;
            presenceApi.createWSKeepAlive();
          } catch (e) {
            logger.error(`Error in ApplicationScreen.onAwake event - ${e.message}`);
          }
        });
      }
    },
    // eslint-disable-next-line
    [
      /* on mount only */
    ]
  );

  useEffect(() => {
    try {
      if (!pbxConfig || sipEnabled) {
        return;
      }

      const { hostedPbxEnabled } = pbxConfig;
      if (hostedPbxEnabled === false) {
        logger.log("The HostedPBX feature is not enabled for this user account.");
        return;
      }
      if (!sipEnabled) {
        const { hostedPbxConfig } = pbxConfig;
        const { phoneApp, userAgent, domain, userExtension } = hostedPbxConfig;
        const displayName = currentUser?.displayName;

        attachSipDelegates();
        setupPbxEventListenerDelegate();

        dispatch(
          sipActions.startSipUA({
            hostedPbxEnabled,
            phoneApp,
            userAgent,
            domain,
            userAgentOptions: {
              displayName, //Identity of the current user
              userExtension,
            },
          })
        );
      }
    } catch (error) {
      logger.error(`Error in ApplicationScreen.pbxConfig.useEffect - ${error}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pbxConfig]);

  const skippingNotificationSip = (nr) => logger.debug(`Skipping notification/sip (rule #${nr})`);

  const attachSipDelegates = () => {
    const msg = "sipUserDelegate";
    const delegates = {
      onCallReceived: (sipCallInfo /* : UiSipCallInfo */) => {
        logger.debug(`${msg}.onCallReceived - ${JSON.stringify(sipCallInfo)}`);

        // check if we have an active vidyo/matrix call, and reject sip call here if so
        if (vidyoCallActiveRef.current || matrixCallActiveRef.current) {
          logger.debug("Rejecting a call invite - have active vidyo/matrix call");
          dispatch(sipActions.endSipCall(sipCallInfo.id));
        } else {
          // get the correct displayName and avatar  of the user
          if (sipCallInfo) {
            dispatch(sipActions.getSipCallInfo(sipCallInfo.id, sipCallInfo.peer.user, sipCallInfo.via?.user));
          }
          setSipInvitationList((prevList) => [...prevList, sipCallInfo]);

          if (document.hasFocus()) {
            // do not notify if window app focus is set
            skippingNotificationSip(1);
          } else if (isElectron()) {
            // do not notify if electron (has its own out of window message for ringing)
            skippingNotificationSip(2);
          } else {
            showTrayNotification({ sipCallInfo });
          }
        }
      },
      onCallSessionTerminated: (state, callId) => {
        logger.debug(`${msg}.onCallSessionTerminated: callId=${callId}`);
        setSipInvitationList((prevList) => prevList.filter((inv) => inv.id !== callId));
        const conferenceInfo = sipUser.getConferenceInfo(callId);
        if (conferenceInfo) {
          // we cannot invoke leaveConference form a sip-hook here since coming from a sip-callback context
          // so store all the necessary conference-info before the call gets terminated, and cleanup in useEffect below
          setListOfConferenceInfosToCleanup((prevList) => [...prevList, conferenceInfo]);
        }
        dispatch(sipActions.endSipCall(callId));
        document.dispatchEvent(new Event(Events.END_SIP_CALL));
        if (isElectron()) {
          window.ipcRenderer.send("EndCall", callId);
        }
      },
      onRegistered: (restarting) => {
        logger.debug(`${msg}.onRegistered restarting=${restarting}`);
        dispatch(sipActions.updateRegistrerState(true));
      },
      onUnregistered: (restarting) => {
        logger.debug(`${msg}.onUnregistered restarting=${restarting}`);
        dispatch(sipActions.updateRegistrerState(false));
      },
      onTerminated: (restarting) => {
        logger.debug(`${msg}.onTerminated restarting=${restarting}`);
        if (!restarting) {
          dispatch(sipActions.restartSipUA());
        }
      },
      onReconnectsFailed: (restarting) => {
        logger.debug(`${msg}.onReconnectsFailed restarting=${restarting}`);
        if (!restarting) {
          dispatch(sipActions.restartSipUA());
        }
      },
      onUpdateSipCalls: (_sipCalls, _canInitateSipCall) => {
        logger.debug(`${msg}.onUpdateSipCalls`);
        dispatch(sipActions.updateSipCalls(_sipCalls, _canInitateSipCall));
      },
      onInCallInvite: (callId) => {
        logger.debug(`${msg}.onInCallInvite`);
        dispatch(sipActions.updateSipStateInfo(callId));
      },
    };
    sipUser.delegate = delegates;
  };

  const setupPbxEventListenerDelegate = () => {
    PBXEventListenerProvider.Instance.setBaseWsUrl(endpointData.pbxEventListenerWS);
    const pbxEventListenerDelegate /* : PbxEventListenerDelegate */ = {
      onEvent(msg) {
        // update peer and connected state for a group call
        logger.debug(`pbxEventListenerDelegate.onEvent ${JSON.stringify(msg)}`);
        dispatch(sipActions.updateSipStateInfo(msg.callId, msg.peerExtension));
      },
    };
    PBXEventListenerProvider.Instance.setDelegate(pbxEventListenerDelegate);
  };

  useEffect(() => {
    if (listOfConferenceInfosToCleanup.length > 0) {
      // do not loop, we will be invoked again if any left
      const conferenceInfo = listOfConferenceInfosToCleanup[0];
      setListOfConferenceInfosToCleanup((prevList) =>
        prevList.filter((ci) => ci.conferenceId !== conferenceInfo.conferenceId)
      );
      logger.debug(
        `useEffect/listOfConferenceInfosToCleanup ${JsonStringify(conferenceInfo)} #${
          listOfConferenceInfosToCleanup.length
        }`
      );
      leaveConferenceCall(conferenceInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfConferenceInfosToCleanup]);

  useEffect(() => {
    // only runs the code to automatically join the meeting:
    // - if the application has been started
    // - the first list of rooms has been obtained
    // - the application has loaded the available speakers
    if (!appInited) return;
    if (!fetchedPreparedRoomList) return;

    if (speakers && speakers.length > 0) {
      if (isElectron()) {
        window.ipcRenderer.invoke("getJoinLink", null).then((result) => {
          if (!result) return;
          const joinRoomLink = result;
          logger.debug(`got joinRoom link: ${joinRoomLink}`);
          joinRoom(joinRoomLink);
        });
      } else {
        const meetingId = window.localStorage.getItem(localStorageKeys.JOIN_LINK);

        if (meetingId) {
          localStorage.removeItem(localStorageKeys.JOIN_LINK);

          getMeetingURLWithMeetingId(meetingId).then((meetingLink) => {
            const encondedLink = Buffer.from(meetingLink).toString("base64");

            dispatch(appActionCreators.setJoinRoomUrl(encondedLink));
            push("/home");
          });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appInited, fetchedPreparedRoomList, speakers]);

  const onSIPAccept = async (sipCallInfo) => {
    try {
      if (!sipCallInfo) return;
      const invObject = sipListRef.current.find((inv) => inv.id === sipCallInfo.id);
      if (invObject) {
        dispatch(sipActions.acceptSipCall(sipCallInfo));
        setSipInvitationList((prevList) => prevList.filter((inv) => inv.id !== sipCallInfo.id));
      } else {
        logger.error(`Error in ApplicationScreen.onSIPAccept - invitation: ${sipCallInfo.id} - not found`);
      }
    } catch (e) {
      logger.error(`Error in ApplicationScreen.onSIPAccept - invitation:${sipCallInfo.id} - ${e.message}`);
    }
  };

  const onSIPReject = async (sipCallInfo) => {
    try {
      if (!sipCallInfo) return;
      dispatch(sipActions.rejectSipCall(sipCallInfo.id));
      setSipInvitationList((prevList) => prevList.filter((inv) => inv.callId !== sipCallInfo.id));
    } catch (e) {
      logger.error(`Error in ApplicationScreen.onSIPReject - callId: ${sipCallInfo.id} - ${e.message}`);
    }
  };

  const onAcceptMatrixCall = async (matrixCall) => {
    try {
      logger.debug(`P2P Call - answer call`);
      const opponentMember = matrixCall.getOpponentMember();
      const opponentAvatar = getRoomMemberAvatar(matrixCall.roomId, opponentMember.userId);
      const participants = [
        {
          name: currentUser ? currentUser.displayName : "current user",
          isLocal: true,
          userId: currentUser ? currentUser.userId : "currentUserId",
          type: matrixCall.type === CallType.voice ? CallMediaType.audio : matrixCall.type,
          avatarUrl: currentUser ? currentUser.avatarUrl : "",
        },
        {
          name: opponentMember ? opponentMember.name : "other user",
          isLocal: false,
          userId: opponentMember ? opponentMember.userId : "otherUserId",
          type: matrixCall.type === CallType.voice ? CallMediaType.audio : matrixCall.type,
          avatarUrl: opponentAvatar ?? "",
        },
      ];
      const answerOptions = {
        participants,
        matrixCall,
        incomingMatrixCallList,
      };
      dispatch(matrixActions.answerMatrixCall(answerOptions));
    } catch (e) {
      logger.error(`Error in ApplicationScreen.onAcceptMatrixCall - matrixCall:${matrixCall} - ${e.message}`);
    }
  };

  const onRejectMatrixCall = (matrixCall) => {
    try {
      logger.debug(`P2P Call - reject call`);
      dispatch(matrixActions.rejectMatrixCall(matrixCall));
    } catch (e) {
      logger.error(`Error in ApplicationScreen.onRejectMatrixCall - matrixCall:${matrixCall}- ${e.message}`);
    }
  };

  useEventListener(
    "message",
    (e) => {
      const key = e.message ? "message" : "data";
      const data = e[key];
      if (
        data?.source === Events.GOOGLE_SET_CODE &&
        data?.payload?.event === Events.GOOGLE_SET_CODE &&
        data?.payload?.payload
      ) {
        logger.debug(`message key=${key} data=${JSON.stringify(data)}`);
        handleSetCode(data.payload, data.payload.payload);
      }
    },
    window
  );

  //
  // detached tiles permutations:
  // 1) none
  // 2) sipCall or matrixCall
  // 3) one-held-call
  // 4) one-held-call and sipCall
  // 5) two-held-calls
  // 6) two-held-calls and sipCall

  return (
    <div
      detached={`${detached}`}
      heldcalls={`${sipCallsHeld.length}`}
      outcall={`${outCall}`}
      sipcall={`${sipCall}`}
      activecall={`${vidyoOrMatrixCall}`}
      className={styles.main}
      {...test("APPLICATION_SCREEN")}
    >
      {syncState === "SYNCING" || syncState === "PREPARED" ? (
        <>
          <SideBar />
          <StyledRightPage>
            {children}
            <div className="toggler">
              <ToggleDrawer />
            </div>
          </StyledRightPage>
          <>
            <CallPage />
          </>
        </>
      ) : (
        <SplashScreen message={`Client ${syncState}`} />
      )}
      {incomingMatrixCallList.length > 0 &&
        !isElectron() &&
        incomingMatrixCallList.map((invite) => {
          return (
            <div key={invite.callId} className="callNotificationContainer">
              <CallNotification
                matrixCallInfo={calculateInviterInfo(invite)}
                type={invite.type}
                onAccept={(e) => onAcceptMatrixCall(invite)}
                onReject={(e) => onRejectMatrixCall(invite)}
              />
            </div>
          );
        })}
      {sipInvitationList.length > 0 &&
        !isElectron() &&
        sipInvitationList.map((sipCallInfo /* :UiSipCallInfo */) => {
          return (
            <div key={sipCallInfo.id} className="callNotificationContainer">
              <CallNotification
                sipCallInfoPreliminary={sipCallInfo}
                onAccept={(e) => onSIPAccept(sipCallInfo)}
                onReject={(e) => onSIPReject(sipCallInfo)}
              />
            </div>
          );
        })}

      {showGroupInviteNotification && !isElectron() && <AdHocNotification data={groupInviteData} />}
    </div>
  );
};

export default ApplicationScreen;
