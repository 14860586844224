import { useEffect, useMemo, useState } from "react";
import { getLogger } from "logger/appLogger";
import { useMatrix, useMatrixRooms } from "hooks";
import {
  ConferenceInitializeMessageBody,
  ConferenceLeaveMessageBody,
  LocalEventTypeEnum,
  InternalMessageContentType,
  TransferInternalMessageBody,
} from "types/Matrix";
import { UiSipCall, MERGE_CALLS_TIMEOUT_MS, UiSipConferenceInfo } from "types/SIP";
import { PBXServerApiClient } from "services/PBXServerApi/PBXServerApiClient";
import { CallActionType, CallMediaType } from "types/UC";
import { asyncWaitWithTimer } from "utils/helpers";
import { useTranslation } from "react-i18next";
import { JsonStringify } from "utils/utils";
import { useAppSelector } from "store/hooks";
import { getSipLocalPeer } from "store/selectors";
import sipUser from "services/SIPProvider/SIPProvider";

export type callStateType = "calling" | "connected" | undefined;

export interface useSipType {
  error: string;
  holdInProgress: boolean;
  holdCall: (type: CallMediaType) => Promise<void>;
  retrieveCall: (callId: string, type: CallMediaType) => Promise<void>;
  transferCallToUser: (callToTransfer: UiSipCall, user: string) => Promise<void>;
  transferCallToSession: (callHeld: UiSipCall, callActive: UiSipCall) => Promise<void>;
  mergeCallsInProgress: boolean;
  mergeCalls: (calls: UiSipCall[]) => Promise<void>;
  leaveConferenceCall: (conferenceInfo: UiSipConferenceInfo) => Promise<void>;
  setSipMediaElements: (localMediaRef: any, remoteMediaRef: any) => Promise<void>;
  muteMicrophone: (mute: boolean) => void;
  muteSpeaker: (mute: boolean) => void;
  muteCamera: (mute: boolean) => void;
}

export const useSip = (): useSipType => {
  const { t } = useTranslation();
  const logger = useMemo(() => getLogger("sip"), []);
  const [error, setError] = useState<string>("");
  const [holdInProgress, setHoldInProgress] = useState<boolean>(false);
  const [mergeCallsInProgress, setMergeCallsInProgress] = useState<boolean>(false);
  const getMsg = (fn: string) => `SipHook: ${fn}`;
  const { getOrCreateDirectRoom } = useMatrixRooms();
  const { sendInternalMessage } = useMatrix("");
  const localPeer = useAppSelector((state) => getSipLocalPeer(state));

  useEffect(() => {
    if (localPeer) {
      sipUser.setLocalPeer(localPeer);
    }
  }, [localPeer]);

  const holdCall = async (type: CallMediaType) => {
    const msg = getMsg("holdCall");
    try {
      if (holdInProgress) {
        logger.warn(`${msg}: hold in progress`);
        return;
      }
      logger.debug(`${msg}: type=${type}`);
      if (!sipUser.haveActiveCall()) {
        logger.warn(`${msg}: no active calls`);
        return;
      }
      setHoldInProgress(true);
      await sipUser.holdCallSession(type);
      setHoldInProgress(false);
    } catch (e) {
      const msgError = `${msg}: failed: ${e?.message}`;
      logger.error(msgError);
      setError(msgError);
    }
  };

  const retrieveCall = async (callId: string, type: CallMediaType) => {
    const msg = getMsg("retrieveCall");
    try {
      logger.debug(`${msg}: type=${type} holdInProgress=${holdInProgress}`);
      if (sipUser.haveActiveCall()) {
        logger.debug(`${msg}: already have an active call, putting on hold first`);
        await holdCall(type);
      }
      if (holdInProgress) {
        logger.warn(`${msg}: hold in progress`);
        return;
      }
      setHoldInProgress(true);
      await sipUser.retreiveCallSession(callId, type);
      setHoldInProgress(false);
    } catch (e) {
      const msgError = `${msg}: failed: ${e?.message}`;
      logger.error(msgError);
      setError(msgError);
    }
  };

  const setSipMediaElements = async (localMediaRef: any, remoteMediaRef: any) => {
    sipUser.pauseRemote();
    sipUser.pauseLocal();
    await sipUser.setupLocalMedia(localMediaRef.current);
    await sipUser.setupRemoteMedia(remoteMediaRef.current);
    sipUser.restoreMutedState();
    await sipUser.playLocal();
    await sipUser.playRemote();
    sipUser.updateSipCalls("setSipMediaElements"); // push device state to UI
  };

  const muteMicrophone = (mute: boolean) => {
    sipUser.setMicrophoneMuted(mute);
    sipUser.updateSipCalls("muteMicrophone"); // push device state to UI
  };

  const muteSpeaker = (mute: boolean) => {
    sipUser.setSpeakerMuted(mute);
    sipUser.updateSipCalls("muteSpeaker"); // push device state to UI
  };

  const muteCamera = (mute: boolean) => {
    sipUser.setVideoMuted(mute);
    sipUser.updateSipCalls("muteCamera"); // push device state to UI
  };

  const transferCallToUser = async (callToTransfer: UiSipCall, user: string) => {
    const msg = getMsg("transferCallToUser ");
    try {
      logger.debug(`${msg}: user=${user}`);
      const result = await sipUser.transferCallToUser(callToTransfer.id, user);
      if (result) {
        const body: TransferInternalMessageBody = {
          isNewRoom: false,
          userExtension: user,
        };
        await sendMatrixMessage(
          callToTransfer.peer.matrixUser,
          callToTransfer.peer.name,
          InternalMessageContentType.Transfer,
          body
        );
      }
    } catch (e) {
      const msgError = `${msg}: failed: ${e?.message}`;
      logger.error(msgError);
      setError(msgError);
    }
  };

  const transferCallToSession = async (callHeld: UiSipCall, callActive: UiSipCall) => {
    const msg = getMsg("transferCallToSession");
    try {
      logger.debug(
        `${msg}: callHeldId=${callHeld.id} - callHeldUser=${callHeld.peer.user} - callActiveUser=${callActive.peer.user}`
      );
      const result = await sipUser.transferCallToSession(callHeld.id);
      if (result) {
        let body: TransferInternalMessageBody = {
          isNewRoom: false,
          userExtension: callHeld.peer.user,
        };
        await sendMatrixMessage(
          callActive.peer.matrixUser,
          callActive.peer.name,
          InternalMessageContentType.Transfer,
          body
        );

        body = {
          isNewRoom: false,
          userExtension: callActive.peer.user,
        };
        await sendMatrixMessage(
          callHeld.peer.matrixUser,
          callHeld.peer.name,
          InternalMessageContentType.Transfer,
          body
        );
      }
    } catch (e) {
      const msgError = `${msg}: failed: ${e?.message}`;
      logger.error(msgError);
      setError(msgError);
    }
  };

  /**
   * Merge calls
   * @param calls
   */
  const mergeCalls = async (calls: UiSipCall[]) => {
    const msg = getMsg("mergeCalls");
    let callIds: string[] = [];
    setMergeCallsInProgress(true);

    try {
      const dbgMsg = `${msg}: calls=${JsonStringify(calls)}`;
      logger.debug(dbgMsg);

      if (!localPeer?.user) {
        throw new Error(`Missing local peer: ${dbgMsg}`);
      }

      if (!calls || calls.length === 0) {
        throw new Error(`Merge call id's incomplete: ${dbgMsg}`);
      }
      const incomingCallIds = calls.filter((call) => !call.outbound).map((call) => call.id);
      const outgoingCallIds = calls.filter((call) => call.outbound).map((call) => call.id);
      callIds = [...incomingCallIds, ...outgoingCallIds];

      // 1. if we have an active call, put on hold
      if (sipUser.haveActiveCall()) {
        await holdCall(CallMediaType.audio);
      }

      // 2. hide calls from UI promptly
      callIds.forEach((callId) => sipUser.setCallVisible(callId, false));

      // 3. make deep copy of peers and add myself
      const conferencePeers = calls.map((call) => {
        return { ...call.peer };
      });
      conferencePeers.push(localPeer);

      // 4. merge calls initaites a pbx-originating "BYE" sent to both held calls
      const mergeResult = await PBXServerApiClient.Instance.mergeCalls(incomingCallIds, outgoingCallIds);
      if (!mergeResult.destination || mergeResult.destination.length === 0) {
        throw new Error("Merge destination is empty");
      }
      logger.debug(`${msg}: destination=${mergeResult.destination}`);

      // 5. we proceed with "INVITE" to a conference url in parallel with calls being terminated by pbx
      const mergeCallId = await sipUser.initiateOutgoingCallSession(
        mergeResult.destination,
        t("CONFERENCE"),
        CallActionType.Conference
      );
      logger.debug(`${msg}: mergeCallId=${mergeCallId}`);

      // 6. wait for both calls to have been terminated by pbx, or timeout hits
      const ITERATIONS = 5;
      let iteration = ITERATIONS;
      let callsTerminated = false;
      while (iteration-- > 0) {
        callsTerminated = callIds.every((callId) => !sipUser.haveCall(callId));
        if (callsTerminated) break;
        await asyncWaitWithTimer(MERGE_CALLS_TIMEOUT_MS / iteration);
      }
      logger.debug(`${msg}: callsTerminated=${callsTerminated}`);

      if (callsTerminated) {
        // 7. attach conferenceId (sip uri of conference bridge) to conference call
        sipUser.attachConferencelId(mergeCallId, null, mergeResult.destination);

        // 8. update conference peers information in newly created call, leave original peer as is by passing null
        await sipUser.updateSipCallInfo(mergeCallId, null, conferencePeers);

        // 9. publish conferenceId and conference list to all participants
        const conferenceUsers = conferencePeers.map((peer) => peer.user);

        conferencePeers
          .filter((peer) => localPeer.user !== peer.user)
          .forEach(async (peer) => {
            const body: ConferenceInitializeMessageBody = {
              isNewRoom: false,
              ownerExtension: localPeer.user,
              conferenceId: mergeResult.destination,
              userExtensions: conferenceUsers,
            };
            await sendMatrixMessage(
              peer.matrixUser,
              peer.name,
              InternalMessageContentType.SipConferenceInitialize,
              body
            );
          });

        // 10. ok
        logger.debug(`${msg}: done`);
      } else {
        // fail: remove merge call
        await sipUser.endCallSession(mergeCallId);
        throw new Error("Merge failed: timeout reached and calls not terminated by PBX");
      }
    } catch (e) {
      const msgError = `${msg}: failed: ${e?.message}`;
      logger.error(msgError);
      setError(msgError);
    } finally {
      // show calls again if any survived a failed merge
      callIds.forEach((callId) => sipUser.setCallVisible(callId, true));

      setMergeCallsInProgress(false);
    }
  };

  /**
   * inform conference participants about us leaving the conference call
   */
  const leaveConferenceCall = async (conferenceInfo: UiSipConferenceInfo) => {
    const msg = getMsg("leaveConferenceCall");
    try {
      logger.debug(`${msg} conferenceInfo=${JsonStringify(conferenceInfo)}`);

      if (!localPeer?.user) {
        throw new Error(`Missing local peer: ${msg}`);
      }

      if (conferenceInfo) {
        const { conferenceId, conferencePeers } = conferenceInfo;
        if (conferenceId && conferencePeers) {
          conferencePeers
            .filter((peer) => localPeer.user !== peer.user)
            .forEach(async (peer) => {
              const body: ConferenceLeaveMessageBody = {
                isNewRoom: false,
                conferenceId,
                userExtension: localPeer.user,
              };
              await sendMatrixMessage(peer.matrixUser, peer.name, InternalMessageContentType.SipConferenceLeave, body);
            });
        }
      }
    } catch (e) {
      const msgError = `${msg}: failed: ${e?.message}`;
      logger.error(msgError);
      setError(msgError);
    }
  };

  // special fix (sip/matrix bridge) for nuso/sipjs bug
  // TransferInternalMessageBody:
  // - terms definition: "transferer" transfers a call from "transferee" (orginal call)
  //   to "transfer-target"
  // - send a matrix-message containing the transfer-destination extension to the transferee so
  //   they can update the remote-party display upon transfer-completion
  // - it is a proprietary solution - it will not work with 3rd party phones
  const sendMatrixMessage = async (
    matrixUser: string | null | undefined,
    matrixRoom: string,
    msgType: InternalMessageContentType,
    body: TransferInternalMessageBody | ConferenceInitializeMessageBody | ConferenceLeaveMessageBody
  ) => {
    logger.debug(`sendMatrixMessage matrixUser=${matrixUser} type=${msgType} body=${JsonStringify(body)}`);
    if (matrixUser && matrixUser.length > 0) {
      const data = await getOrCreateDirectRoom(matrixUser, matrixRoom, false);
      if (data) {
        try {
          const { roomId, newRoom } = data;
          if (roomId) {
            body.isNewRoom = newRoom;
            const content = { type: msgType, body: body };
            sendInternalMessage(roomId, LocalEventTypeEnum.InternalMessage, content);
          }
        } catch (e) {
          logger.warn(`${module}: Failed to send transfer message: ${e.message}`);
        }
      }
    }
  };

  return {
    error,
    holdInProgress,
    holdCall,
    retrieveCall,
    transferCallToUser,
    transferCallToSession,
    mergeCalls,
    mergeCallsInProgress,
    leaveConferenceCall,
    setSipMediaElements,
    muteMicrophone,
    muteSpeaker,
    muteCamera,
  };
};
