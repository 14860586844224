import { useEffect, useState, useRef, useMemo } from "react";
import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import CloseFullscreenRoundedIcon from "@mui/icons-material/CloseFullscreenRounded";
import DialpadIcon from "@mui/icons-material/Dialpad";
import InfoIcon from "@mui/icons-material/Info";
import OpenInFullRoundedIcon from "@mui/icons-material/OpenInFullRounded";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import PhonePausedIcon from "@mui/icons-material/PhonePaused";
import ReplyIcon from "@mui/icons-material/Reply";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { AppBar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import CallingAnimation from "components/CallingAnimation/CallingAnimation";
import DeviceToggle, { Toggle_Devices } from "components/DeviceToggle/DeviceToggle";
import Avatar from "components/_shared/Avatar";
import IconButton from "components/_shared/IconButton";
import { DialoutCallTransfer } from "containers/DialoutCall/DialoutCallTransfer";
import MediaSettings from "containers/MediaSettings/MediaSettings";
import { useRouter, useSip } from "hooks";
import SwapCallsIcon from "icons/SwapCallsIcon";
import { getLogger } from "logger/appLogger";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { sipActions } from "store/actions/sip";
import { useAppSelector } from "store/hooks";
import { getSipCallActive, getSipCallsHeld, selectDetached } from "store/selectors";
import styled from "themes/theme/baseTheme";
import { UiSipCall } from "types/SIP";
import { CallActionType, CallMediaType, CallingState } from "types/UC";
import { test } from "utils/helpers";
import "./DialoutCall.scss";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.neutrals.pureWhite,
}));

const StyledEndIconButton = styled(IconButton)(({ theme }) => ({
  height: "42px",
  width: "42px",
  padding: 0,
  backgroundColor: theme.palette.sentiment.error.base,
  color: theme.palette.common.white,
  border: "none",
  outline: "none",
  "&:hover": {
    backgroundColor: theme.palette.sentiment.error.dark1,
    boxShadow: "none",
  },
  "&:focus": {
    outline: "none",
  },
}));

let fullscreenLast: boolean = true;

const callTypeIsConferenceCall = (sipCall: UiSipCall | undefined) => {
  return sipCall && (sipCall.callType === CallActionType.Conference || sipCall.conferencePeers?.length > 0);
};

const callIsGroupCallBeforeConnect = (sipCall: UiSipCall | undefined) => {
  return sipCall && sipCall.groupCallBeforeConnect;
};

interface DialoutCallProps {
  localUserName: string;
}

const DialoutCall = ({ localUserName }: DialoutCallProps) => {
  const logger = useMemo(() => getLogger("sip"), []);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localMediaRef = useRef(null);
  const remoteMediaRef = useRef(null);
  const { push } = useRouter();
  const theme = useTheme();
  const [mediaSettingsOpen, setMediaSettingsOpen] = useState(false);
  const [showCallTransfer, setShowCallTransfer] = useState(false);
  const {
    holdInProgress,
    holdCall,
    retrieveCall,
    mergeCallsInProgress,
    mergeCalls,
    transferCallToSession,
    setSipMediaElements,
    muteSpeaker,
    muteMicrophone,
  } = useSip();

  const location = useLocation();
  const detached = useAppSelector((state) => selectDetached(state, location.pathname));
  const [fullscreen, setFullscreen] = useState(detached || fullscreenLast);

  const sipLocalPeer = useAppSelector((state) => state.sip.localPeer);
  const sipCallActive = useAppSelector((state) => getSipCallActive(state, true)); // includes active held call
  const sipCallsHeld = useAppSelector((state) => getSipCallsHeld(state, true)); // excludes active held call
  const sipCallHeld = sipCallsHeld?.length > 0 ? sipCallsHeld[0] : null;
  const callingState = sipCallActive ? sipCallActive.callingState : CallingState.NONE;

  const sipCallAudioMuted = sipCallActive ? sipCallActive.mutedState.microphone : false;
  const sipCallSpeakerMuted = sipCallActive ? sipCallActive.mutedState.speaker : false;

  const callingStateConnected = callingState === CallingState.CONNECTED;
  const groupCallBeforeConnect = sipCallActive ? sipCallActive.groupCallBeforeConnect : false;

  const holdButtonDisabled = holdInProgress || !callingStateConnected || groupCallBeforeConnect;
  const toggleMicrophoneDisabled = !sipCallActive || sipCallActive.held || !callingStateConnected;
  const toggleSpeakerDisabled = !sipCallActive || sipCallActive.held || !callingStateConnected;
  const swapCallsButtonPossible = sipCallActive && !sipCallActive.held && sipCallHeld;
  const swapCallsButtonDisabled = !swapCallsButtonPossible || holdInProgress || !callingStateConnected;

  const canCompleteTransfer =
    sipCallActive &&
    sipCallActive.active &&
    !callTypeIsConferenceCall(sipCallActive) &&
    sipCallHeld &&
    !callTypeIsConferenceCall(sipCallHeld);
  const areBothCallsHeld = sipCallActive?.held && sipCallHeld?.held;
  const haveOneCallInConference =
    (sipCallHeld && callTypeIsConferenceCall(sipCallHeld)) || callTypeIsConferenceCall(sipCallActive);
  const transferButtonDisabled =
    !callingStateConnected || areBothCallsHeld || haveOneCallInConference || groupCallBeforeConnect;

  const callsMergeable =
    sipCallActive &&
    callingStateConnected &&
    !callTypeIsConferenceCall(sipCallActive) &&
    sipCallHeld &&
    !callTypeIsConferenceCall(sipCallHeld) &&
    !groupCallBeforeConnect &&
    !callIsGroupCallBeforeConnect(sipCallHeld);
  const mergeCallsButtonPossible = mergeCallsInProgress || callsMergeable;
  const mergeCallsButtonDisabled = mergeCallsInProgress;

  // sanity check
  if (sipCallActive && sipCallHeld && sipCallActive.id === sipCallHeld.id) {
    logger.error(
      `Active- and held-call are the same call: sipCallActive=${sipCallActive?.id} sipCallHeld=${sipCallHeld?.id}`
    );
  }

  const toggleMediaSettings = () => {
    setMediaSettingsOpen(!mediaSettingsOpen);
  };

  const transferCallAction = () => {
    if (canCompleteTransfer) {
      transferCallToSession(sipCallHeld, sipCallActive);
    } else {
      setShowCallTransfer(!showCallTransfer);
    }
  };

  const toggleFullScreen = () => {
    fullscreenLast = !fullscreen;
    setFullscreen(!fullscreen);
  };

  const toggleHold = () => {
    if (sipCallActive) {
      if (sipCallActive.held) {
        retrieveCall(sipCallActive?.id, CallMediaType.audio);
      } else {
        holdCall(CallMediaType.audio);
      }
    }
  };

  const mergeCallsAction = async () => {
    // merge 2 held calls into one conference call - hosted on pbx
    if (mergeCallsButtonPossible && callsMergeable) {
      await mergeCalls([sipCallActive, sipCallHeld]);
    }
  };

  const swapCallsAction = () => {
    // swap calls = active call should be placed on hold and the held call should be retrieved
    // we actually *only* retrieve the held call - which automatically places active call on hold
    if (swapCallsButtonPossible) {
      retrieveCall(sipCallHeld.id, CallMediaType.audio);
    }
  };

  const onBack = () => {
    push(`/dialpad`);
  };

  const endCall = () => {
    if (sipCallActive) {
      dispatch(sipActions.endSipCall(sipCallActive.id));
    }
  };

  const toggleMicrophone = () => {
    muteMicrophone(!sipCallAudioMuted);
  };

  const toggleSpeaker = () => {
    muteSpeaker(!sipCallSpeakerMuted);
  };

  const endCallControl = () => {
    return (
      <StyledEndIconButton
        tooltip={t("TOOLTIP_END_CALL")}
        tooltipPlacement="top"
        id="dialoutcall_end_button"
        onClick={endCall}
        {...test("CALL_END_CALL_BUTTON")}
      >
        <CallEndIcon />
      </StyledEndIconButton>
    );
  };

  const microphoneControl = () => {
    return (
      <DeviceToggle
        on={!sipCallAudioMuted}
        tooltip={sipCallAudioMuted ? t("TOOLTIP_MICROPHONE_OFF") : t("TOOLTIP_MICROPHONE_ON")}
        disabled={toggleMicrophoneDisabled}
        onClick={toggleMicrophone}
        device={Toggle_Devices.MICROPHONE}
      ></DeviceToggle>
    );
  };

  const backControl = () => {
    return (
      <StyledIconButton
        id="call_go_back_button"
        className="toggle go-back"
        tooltip={t("TOOLTIP_GO_BACK")}
        tooltipPlacement="top"
        onClick={onBack}
        {...test("CALL_GO_BACK_BUTTON")}
      >
        <ReplyIcon />
      </StyledIconButton>
    );
  };

  const holdControl = () => {
    return (
      <StyledIconButton
        id="dialoutcall_hold_button"
        tooltip={holdInProgress ? "" : sipCallActive?.held ? t("TOOLTIP_UNHOLD") : t("TOOLTIP_HOLD")}
        tooltipPlacement="top"
        data-on={!sipCallActive?.held}
        className="toggle"
        disabled={holdButtonDisabled}
        onClick={toggleHold}
        sx={
          sipCallActive?.held
            ? {
                color: theme.palette.icon.off,
              }
            : {}
        }
      >
        <PhonePausedIcon />
      </StyledIconButton>
    );
  };

  const deviceControls = () => {
    return (
      <div className="dial-controls">
        <div className="dial-controls-buttons">
          {endCallControl()}
          {microphoneControl()}

          <DeviceToggle
            on={!sipCallSpeakerMuted}
            tooltip={sipCallSpeakerMuted ? t("TOOLTIP_SPEAKER_OFF") : t("TOOLTIP_SPEAKER_ON")}
            disabled={toggleSpeakerDisabled}
            onClick={toggleSpeaker}
            device={Toggle_Devices.SPEAKER}
          ></DeviceToggle>

          <StyledIconButton
            id="dialoutcall_fullscreen_button"
            className="toggle expand"
            tooltip={fullscreen ? t("TOOLTIP_FULLSCREEN_ON") : t("TOOLTIP_FULLSCREEN_OFF")}
            tooltipPlacement="top"
            onClick={toggleFullScreen}
            {...test("CALL_EXPAND_BUTTON")}
          >
            {fullscreen ? <CloseFullscreenRoundedIcon /> : <OpenInFullRoundedIcon />}
          </StyledIconButton>

          {fullscreen && (
            <StyledIconButton
              id="dialoutcall_dialpad_button"
              className={`toggle dialpad ${fullscreen ? "" : "active"}`}
              tooltip={fullscreen ? t("TOOLTIP_DIALPAD_ON") : t("TOOLTIP_DIALPAD_OFF")}
              tooltipPlacement="top"
              onClick={toggleFullScreen}
              {...test("CALL_DIALPAD_BUTTON")}
            >
              <DialpadIcon />
            </StyledIconButton>
          )}

          <StyledIconButton
            id="dialoutcall_settings_button"
            className="toggle settings"
            tooltip={t("TOOLTIP_SETTINGS")}
            tooltipPlacement="top"
            onClick={toggleMediaSettings}
            {...test("CALL_SETTINGS_BUTTON")}
          >
            <SettingsOutlinedIcon />
          </StyledIconButton>
          {backControl()}
        </div>
        <div className="dial-controls-label">
          <Typography style={{ fontSize: "10px", color: theme.palette.common.white }}>
            {t("DEVICE_MODERATION_CONTROLS")}
          </Typography>
        </div>
      </div>
    );
  };

  const callControls = () => {
    return sipCallActive ? (
      <div className="dial-controls">
        <div className="dial-controls-buttons">
          <StyledIconButton
            id="dialoutcall_transfer_button"
            className="toggle transfer"
            tooltip={canCompleteTransfer ? t("TOOLTIP_COMPLETE_TRANSFER_CALL") : t("TOOLTIP_TRANSFER_CALL")}
            tooltipPlacement="top"
            onClick={transferCallAction}
            disabled={transferButtonDisabled}
            {...test("CALL_EXPAND_BUTTON")}
          >
            <PhoneForwardedIcon />
          </StyledIconButton>

          {holdControl()}

          {mergeCallsButtonPossible && (
            <StyledIconButton
              id="dialoutcall_mergecalls_button"
              className="toggle mergecalls"
              tooltip={t("TOOLTIP_MERGE_CALLS")}
              tooltipPlacement="top"
              onClick={mergeCallsAction}
              disabled={mergeCallsButtonDisabled}
            >
              <CallMergeIcon />
            </StyledIconButton>
          )}

          {swapCallsButtonPossible && (
            <StyledIconButton
              id="dialoutcall_swapcalls_button"
              className="toggle swapcalls"
              tooltip={t("TOOLTIP_SWAP_CALLS")}
              tooltipPlacement="top"
              onClick={swapCallsAction}
              disabled={swapCallsButtonDisabled}
            >
              <SwapCallsIcon />
            </StyledIconButton>
          )}
        </div>
        <div className="dial-controls-label">
          <Typography style={{ fontSize: "10px", color: theme.palette.common.white }}>{t("CALL_CONTROLS")}</Typography>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const detachedControls = () => {
    return (
      <div className="dial-controls">
        <div className="dial-controls-buttons">
          {endCallControl()}
          {microphoneControl()}
          {holdControl()}
          {backControl()}
        </div>
      </div>
    );
  };

  const detachedAttribute = { detached: detached ? "true" : "false" };

  const callingTileComponent = (user: string, name: string, status: string) => {
    const nameUserDiff = name !== user;
    return (
      <div className="call-status">
        <CallingAnimation content={<CallIcon sx={{ fontSize: "2rem" }} />} />
        <div className="call-status-message">{status}</div>
        <div className="call-status-message">{name}</div>
        {nameUserDiff && <div className="call-status-message">{user}</div>}
      </div>
    );
  };

  const callingStateComponent = (sipCall: UiSipCall) => {
    const statusMessage = sipCall.callType === CallActionType.Conference ? t("JOINING_CONFERENCE") : `${t("CALLING")}`;
    return callingTileComponent(sipCall.peer.user, sipCall.peer.name, statusMessage);
  };

  const acceptingStateComponent = (sipCall: UiSipCall) => {
    return callingTileComponent(sipCall.peer.user, sipCall.peer.name, t("ACCEPTING"));
  };

  const terminatingStateComponent = (sipCall: UiSipCall) => {
    return callingTileComponent(
      sipCall.peer.user,
      sipCall.peer.name,
      sipCall.error ? t("CALL_FAILED") : t("CALL_TERMINATED")
    );
  };

  const getTileGridClass = (length: number) => {
    switch (length) {
      case 1:
      case 2:
        return length;
      case 3:
      case 4:
        return 2;
      default:
        return 3;
    }
  };

  const conferenceCallTiles = (sipCall: UiSipCall) => {
    let divId = 0;
    const aspectRatio = sipCall.conferencePeers?.length <= 2 ? "participant-tile-aspect-ratio-16-9" : "";
    return (
      <div
        className={`compositor-grid conference-connected tiles-${getTileGridClass(sipCall.conferencePeers?.length)}`}
      >
        <video ref={localMediaRef}></video>
        <video ref={remoteMediaRef}></video>
        {sipCall.conferencePeers.map((peer) => (
          <div className={`tile ${aspectRatio}`} key={++divId}>
            <Avatar name={peer.name || ""} src={peer.avatarUrl} size="70" disableBadge status="call" />
            <div className="name">{peer.name || ""}</div>
            {peer.name !== peer.user && <div className="number">{peer.user}</div>}
          </div>
        ))}
      </div>
    );
  };

  const basicCallTiles = (sipCall: UiSipCall) => {
    const peerNameAndUserDiffer = sipCall.peer.name !== sipCall.peer.user;
    return (
      <div className="compositor-grid call-connected tiles-2">
        <div className="tile local participant-tile-aspect-ratio-16-9">
          <Avatar
            name={(sipLocalPeer ? sipLocalPeer.name : localUserName) || ""}
            src={(sipLocalPeer && sipLocalPeer.avatarUrl) || ""}
            size="70"
            disableBadge
            status="call"
          />
          <div className="name">{(sipLocalPeer ? sipLocalPeer.name : localUserName) || ""}</div>
          <div className="number">{sipLocalPeer ? sipLocalPeer.user : ""}</div>
          <video ref={localMediaRef}></video>
        </div>
        <div className="tile remote participant-tile-aspect-ratio-16-9">
          {callingState === CallingState.CONNECTED && (
            <>
              <Avatar
                name={sipCall.peer.name || ""}
                src={sipCall.peer.avatarUrl}
                size="70"
                disableBadge
                status="call"
              />
              <div className="name">{sipCall.peer.name}</div>
              {peerNameAndUserDiffer && <div className="number">{sipCall.peer.user}</div>}
              <video ref={remoteMediaRef}></video>
            </>
          )}
          {callingState === CallingState.CALLING && callingStateComponent(sipCall)}
          {callingState === CallingState.ACCEPTING && acceptingStateComponent(sipCall)}
          {callingState === CallingState.TERMINATING && terminatingStateComponent(sipCall)}
        </div>
      </div>
    );
  };

  const callTiles = (sipCall: UiSipCall) => {
    const conferenceView = !!callTypeIsConferenceCall(sipCall) && callingState === CallingState.CONNECTED;
    return conferenceView ? conferenceCallTiles(sipCall) : basicCallTiles(sipCall);
  };

  useEffect(() => {
    logger.debug(`DialoutCall mount`);
    return () => {
      logger.debug(`DialoutCall unmount`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    logger.debug(`Callstate => ${callingState}`);
    if (callingState === CallingState.CONNECTED) {
      logger.debug("Connect elements to media, restore muted state");
      setSipMediaElements(localMediaRef, remoteMediaRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingState]);

  return sipCallActive ? (
    <div className={`dial-out-call-container ${fullscreen ? "full-screen" : ""}`}>
      <AppBar position="static" color="default" className="header">
        <div className="status">
          <CallIcon></CallIcon>
          <div className="text">{sipCallActive.peer.name}</div>
        </div>
      </AppBar>

      <div className="call-section">
        <div className="call-held-anchor">
          {!detached &&
            sipCallsHeld.length > 0 &&
            sipCallsHeld.map((call) => (
              <div
                className="call-held-rectangle"
                key={call.id}
                style={{ backgroundColor: theme.palette.background.dark }}
              >
                <InfoIcon sx={{ color: theme.palette.sentiment.inform.light2 }} />
                <div className="call-held-text">
                  <Typography variant="body3" sx={{ color: theme.palette.common.white }}>{`${call.peer.name} ${t(
                    "IS_ON_HOLD"
                  )}`}</Typography>
                </div>
              </div>
            ))}
        </div>
        {callTiles(sipCallActive)}
      </div>
      <div className="footer" {...detachedAttribute}>
        <div className="dial-controls-container">
          {!detached && deviceControls()}
          {!detached && callControls()}
          {detached && detachedControls()}
        </div>
      </div>
      {mediaSettingsOpen && <MediaSettings closeSettings={toggleMediaSettings} />}
      {showCallTransfer && <DialoutCallTransfer close={transferCallAction} />}
    </div>
  ) : (
    <></>
  );
};

export default DialoutCall;
