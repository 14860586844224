import { Components, Theme } from "@mui/material";

const MuiSwitch: Components["MuiSwitch"] = {
  styleOverrides: {
    root: ({ theme }) => {
      const palette = (theme as Theme).palette;
      return {
        "& .MuiSwitch-switchBase": {
          color: palette.background.switch.notSelected,
          "& + .MuiSwitch-track": {
            backgroundColor: palette.background.switch.notSelectedSlide,
            opacity: "unset",
          },
          "& .MuiSwitch-thumb": {
              border: `1px solid ${palette.border.switch.notSelected}`,
            },
          "&.Mui-checked": {
            color: palette.background.switch.selected,
            "& + .MuiSwitch-track": {
              backgroundColor: palette.background.switch.selectedSlide,
              opacity: "unset",
              border: 0,
            },
            "& .MuiSwitch-thumb": {
             border: `1px solid ${palette.border.switch.selected}`,
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color: palette.background.switch.disabled,
              border: `1px solid ${palette.border.switch.disabled}`
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              backgroundColor: palette.background.switch.disabledSlide,
              opacity: "unset",
            },
          },
          // "&.Mui-disabled .MuiSwitch-thumb": {
          //   color: palette.background.switch.disabled,
          // },
          "&.Mui-disabled + .MuiSwitch-track": {
            backgroundColor: palette.background.switch.disabledSlide,
            opacity: "unset",
          },
        },
      };
    },
  },
};

export default MuiSwitch;
