import * as actionTypes from "../actions/types/app";

export const initApp = (payload) => ({
  type: actionTypes.INIT_APP,
  payload,
});

export const changeNotificationCount = (count) => ({
  type: actionTypes.NOTIFICATION_COUNT_CHANGE,
  count,
});

export const outlookCalendarEvent = (connected) => ({
  type: actionTypes.OUTLOOK_CALENDAR_EVENT,
  connected,
});

export const googleCalendarEvent = (connected) => ({
  type: actionTypes.GOOGLE_CALENDAR_EVENT,
  connected,
});

export const calendarUpdate = () => ({
  type: actionTypes.CALENDAR_UPDATE,
});

export const generateLogs = (payload) => ({
  type: actionTypes.GENERATE_LOGS,
  payload,
});

export const cleanGenerateLogsError = () => ({
  type: actionTypes.CLEAN_GENERATE_LOGS_ERROR,
});

export const resetLogState = (payload) => ({
  type: actionTypes.RESET_LOG_STATE,
  payload,
});

export const subscribeOnPermissionsChanges = () => ({
  type: actionTypes.PERMISSIONS_CHANGES_SUBSCRIBE,
});

export const enableDebugLogLevel = () => ({
  type: actionTypes.ENABLE_DEBUG_LOG_LEVEL,
});

export const setJoinRoomUrl = (joinRoomUrl) => ({
  type: actionTypes.SET_JOIN_ROOM_URL,
  joinRoomUrl,
});
