import { useEffect, useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getLogger } from "logger/appLogger";
import mxClient from "matrix/matrix";
import DOMPurify from "dompurify";
import { MatrixRoom, ReceiptType } from "types/Matrix";
import { IContent, MatrixEvent } from "matrix-js-sdk";
import { matrixActions } from "store/actions/matrix";
import { convertLineBreakTextToHTML, getProperCase } from "utils/helpers";
import { useAppSelector } from "store/hooks";
import { MatrixSendMessageError } from "types/Matrix";
import twemoji from "twemoji";
import { File, Map } from "components/Messages/MessageEventType";
import { renderToString } from "react-dom/server";
import { useMatrixAvatar } from "hooks";
import { MatrixUserProvider } from "services/MatrixAPIProvider";
import { imageDimensions } from "matrix/message";
import styles from "components/Messages/Messages.module.scss";

const useMatrixMessage = (room: MatrixRoom | null = null) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const logger = useMemo(() => getLogger("matrix"), []);
  const module = "useMatrixMessage";

  const { getUser } = MatrixUserProvider();
  const { getMatrixImage, getHttpUrl } = useMatrixAvatar();

  const sendMessageErrorData = useAppSelector((state) => state.matrix.sendMessageErrorData);
  const eventsList = useAppSelector((state) => state.matrix.roomEventsList);

  const client = mxClient.client;

  const resetState = () => {};

  const sendReceipt = async (event: any) => {
    try {
      return await client.sendReceipt(event, ReceiptType.Read, null);
    } catch (e) {
      logger.error(`${module}: Error in sendReceipt - ${e.message}`);
    }
  };

  const handleErrorMessage = (e: any) => {
    let errorData: MatrixSendMessageError | null = null;
    if (e && e.event) {
      errorData = {
        eventId: e.event.event.event_id,
        message: e.message,
      };
      dispatch(matrixActions.sendMessageErrorData(errorData));
    }
  };

  const sendTextMessage = async (message: any) => {
    let sendRes = null;
    try {
      sendRes = (await client.sendTextMessage(room?.roomId!, message)) as any;
      const eventId = sendRes.event_id;
      const event = room?.findEventById(eventId);
      if (sendMessageErrorData && sendMessageErrorData.eventId === eventId)
        dispatch(matrixActions.sendMessageErrorData(null));
      return await client.sendReceipt(event!, ReceiptType.Read, null);
    } catch (e) {
      logger.error(`${module}: Error in sendTextMessage - message:${message} - ${e.message}`);
      handleErrorMessage(e);
    }
  };

  const sendHtmlMessage = async (body: any, htmlBody: string | Node) => {
    let sendRes = null;
    try {
      htmlBody = DOMPurify.sanitize(htmlBody);
      sendRes = (await client.sendHtmlMessage(room?.roomId!, body, htmlBody)) as any;
      const eventId = sendRes.event_id;
      const event = room?.findEventById(eventId);
      if (sendMessageErrorData && sendMessageErrorData.eventId === eventId)
        dispatch(matrixActions.sendMessageErrorData(null));
      return await client.sendReceipt(event!, ReceiptType.Read, null);
    } catch (e) {
      logger.error(`${module}: Error in sendHtmlMessage - ${e.message}`);
      handleErrorMessage(e);
    }
  };

  const sendMessage = async (content: any) => {
    let sendRes = null;
    try {
      sendRes = (await client.sendMessage(room?.roomId!, content)) as any;
      const eventId = sendRes.event_id;
      const event = room?.findEventById(eventId);
      if (sendMessageErrorData && sendMessageErrorData.eventId === eventId)
        dispatch(matrixActions.sendMessageErrorData(null));
      return await client.sendReceipt(event!, ReceiptType.Read, null);
    } catch (e) {
      logger.error(`${module}: Error in sendMessage - ${e.message} - sendRes:${sendRes}`);
      handleErrorMessage(e);
    }
  };

  const removeMessage = async (roomId: string, eventId: any) => {
    try {
      return await client.redactEvent(roomId, eventId);
    } catch (e) {
      logger.error(`${module}: Error in removeMessage - roomId:${roomId}, eventId:${eventId} - ${e.message}`);
    }
  };

  const sendTyping = async (roomId: string, isTyping = true) => {
    try {
      return await client.sendTyping(roomId, isTyping, 2000);
    } catch (e) {
      logger.error(`${module}: Error in sendTyping - roomId:${roomId}, isTyping:${isTyping} - ${e.message}`);
    }
  };

  const resendMessage = async (mEvent: MatrixEvent, room: MatrixRoom) => {
    try {
      const sendRes = await client.resendEvent(mEvent, room);
      const eventId = sendRes.event_id;
      const event = room?.findEventById(eventId);
      if (sendMessageErrorData && sendMessageErrorData.eventId === eventId)
        dispatch(matrixActions.sendMessageErrorData(null));
      return await client.sendReceipt(event!, ReceiptType.Read, null);
    } catch (e) {
      logger.error(
        `${module}: Error in resendMessage - roomId:${room.roomId}, eventId:${mEvent.event.event_id} - ${e.message}`
      );
      handleErrorMessage(e);
    }
  };

  const cancelPendingMessage = async (mEvent: MatrixEvent) => {
    try {
      if (sendMessageErrorData && sendMessageErrorData.eventId === mEvent.event.event_id)
        dispatch(matrixActions.sendMessageErrorData(null));
      return await client.cancelPendingEvent(mEvent);
    } catch (e) {
      logger.error(`${module}: Error in cancelPendingMessage - eventId:${mEvent.event.event_id} - ${e.message}`);
    }
  };

  const getSenderDisplayName = (matrixEvent: MatrixEvent | null) => {
    if (matrixEvent && matrixEvent?.sender?.name) {
      let name = matrixEvent.sender.name;

      if (matrixEvent.sender.userId) {
        const userId = ` (${matrixEvent.sender.userId})`;
        if (name.indexOf(userId) > 0) {
          name = name.replace(userId, "");
        }
      }
      name = getProperCase(name);

      return name;
    }
    return "";
  };

  const renderFileHTMLString = (content: any, contentSrc: string | undefined) => {
    return ReactDOMServer.renderToStaticMarkup(<File content={content} contentSrc={contentSrc} />);
  };

  const isReplyMessage = (content: IContent | undefined) => {
    if (content) {
      const mRelatesTo = content["m.relates_to"];
      if (mRelatesTo?.["m.in_reply_to"]?.event_id) return true;
      else if (content.formatted_body && content.formatted_body.includes("<mx-reply>")) return true;
      else return false;
    } else return false;
  };

  const isForwardedMessage = (content: IContent | undefined) => {
    if (content) {
      const regex = /(?<=&gt; )(?<sender>.*): (?<orig><p>.+<\/p>).*\r?(?<=<br\/>)(?<fwd>.*)/;
      if (content.formatted_body.match(regex)) return true;
      else if (content.formatted_body && content.formatted_body.includes("<mx-forward>")) return true;
      else return false;
    } else return false;
  };

  const getEventContent = (event: MatrixEvent | undefined) => {
    let eventContent = undefined;
    if (event) {
      let originalEvent = room && event.event.event_id ? room.findEventById(event.event.event_id) : undefined;
      originalEvent =
        originalEvent ?? eventsList.find((localEvent) => localEvent.event.event_id === event.event.event_id);
      eventContent = originalEvent ? originalEvent.getContent() : event.event.content;
    }
    return eventContent;
  };

  const getMessageData = (content: any, skipParseEmoji: boolean, message: string) => {
    let contentUrl = undefined;
    if (content.msgtype === "m.image") {
      contentUrl = content.url && content.url.content_uri ? content.url.content_uri : content.url;
    } else if (content.msgtype === "m.location") {
      contentUrl = content.info && content.info.thumbnail_url ? content.info.thumbnail_url : "";
    }
    const contentSrc = getHttpUrl(contentUrl);
    const matrixImage = getMatrixImage(contentUrl);
    return parseData(content, contentSrc, matrixImage, skipParseEmoji, message);
  };

  const getForwardMessageData = (event: MatrixEvent) => {
    if (event.event.content) {
      const fwdData = processForwardMessage(event.event.content);
      return getMessageData(
        event.event.content,
        false,
        fwdData ? fwdData.fwdMsg : convertLineBreakTextToHTML(event.event.content.body)
      );
    }
  };

  const parseData = (
    content: any,
    contentSrc: string | undefined,
    matrixImage: string | undefined,
    skipParseEmoji: boolean,
    message: string
  ) => {
    const messageType = content.msgtype;

    if (messageType === "m.text") {
      const parse = twemoji.parse(message, { folder: "svg", ext: ".svg" });
      const result = skipParseEmoji && (parse as string).includes('class="emoji"') ? message : parse;
      return result;
    }

    if (messageType === "m.file") {
      return renderFileHTMLString(content, contentSrc);
    }

    if (messageType === "m.image") {
      return `<div className="file-container">
        ${renderFileHTMLString(content, contentSrc)}
        <img width="200px" src=${matrixImage}/>
      </div>`;
    }

    if (messageType === "m.location") {
      return ReactDOMServer.renderToString(<Map geoUri={content.geo_uri} body={message} imageSrc={matrixImage} />);
    }
  };

  const messageDeletedElement = (event: MatrixEvent) => {
    return (
      <p className={styles.redacted}>{`${t("MESSAGE_DELETED")} ${
        event.event?.unsigned?.redacted_because?.sender
          ? `${t("BY")} ${getUser(event.event?.unsigned?.redacted_because?.sender)?.displayName}.`
          : ""
      }`}</p>
    );
  };

  const getReplyWithOriginalMsg = (event: MatrixEvent, originalMsg: string, replyMsg: string) => {
    const sender = getSenderDisplayName(event);
    return `<mx-reply><blockquote><span>${t(
      "IN_REPLY_TO"
    )} </span><i>${sender}</i><br><p>${originalMsg}</p></blockquote></mx-reply><p>${replyMsg}</p>`;
  };

  const processForwardMessage = (content: any) => {
    let message = null;
    // if the content have formatted_body, use that instead of body to get the separation
    // between the original and the forward message (<br/>)
    if (content.formatted_body) {
      let match = null;
      // formated_body with the forward style
      if (content.formatted_body.includes("<mx-forward><blockquote>")) {
        // regex to get the sender, original and the foward msg
        // ex msg: "<mx-forward><blockquote><span>Forward message from </span><i>User1 Test</i><br><p>original msg</p></blockquote></mx-forward><p>response</p>"
        let regex = null;

        if (content.msgtype === "m.image" || content.msgtype === "m.file") {
          regex = /(?<=<i>)(?<sender>.*)<\/i><br>(?<orig>(.|\W)+)<\/blockquote><\/mx-forward>(?<fwd>.*)/;
        } else {
          regex = /(?<=<i>)(?<sender>.*)<\/i><br>(?<orig><p>.+<\/p>)<\/blockquote><\/mx-forward>(?<fwd>.*)/;
        }
        match = content.formatted_body.match(regex);
      } else {
        // regex to get the sender, original and the foward msg
        // ex msg: "&gt;&gt;&gt; User3 Test: <p>original msg</p><br/><p>response</p>"
        const regex = /(?<=&gt; )(?<sender>.*): (?<orig><p>.+<\/p>).*\r?(?<=<br\/>)(?<fwd>.*)/;
        match = content.formatted_body.match(regex);
      }
      const groups = match ? match.groups : null;
      if (groups) {
        message = {
          sender: groups.sender,
          origMsg: groups.orig,
          fwdMsg: groups.fwd,
        };
      }
    } else {
      // if the content doesn't have formatted_body, use the body - possibly forward of files or images from mobile
      // ex msg: ">>> User3 Test: blabla/nresponse"
      const lineBreakIndex = content.body.search(/\r?\n/g);
      if (lineBreakIndex > 0) {
        let firstPart = content.body.slice(0, lineBreakIndex);
        let fwdMsg = content.body.slice(lineBreakIndex + 1);
        // regex to get the sender and the original msg
        firstPart = firstPart.replaceAll("\n", "\\n");
        const match = firstPart.match(/(?<=> )(?<sender>.*): (?<orig>.*)/);
        const groups = match ? match.groups : null;
        if (groups) {
          message = {
            sender: groups.sender,
            origMsg: convertLineBreakTextToHTML(groups.orig.replaceAll("\\n", `\n`)),
          };
        }
        message = { ...message, fwdMsg: convertLineBreakTextToHTML(fwdMsg) };
      }
    }
    return message;
  };

  const getReplyMsg = async (event: MatrixEvent) => {
    const replyEventId = event.replyEventId;
    const content = event.getContent();
    if (replyEventId && room) {
      let replyMsg = content.body;
      let originalEvent = room.findEventById(replyEventId);

      originalEvent = originalEvent ?? eventsList.find((event) => event.event.event_id === replyEventId);

      if (originalEvent && originalEvent.getContent() && !content.original_msg_deleted) {
        const originalEventContent = originalEvent.getContent();
        let originalMsg = originalEventContent.body;
        const fwdData = processForwardMessage(originalEventContent);
        if (fwdData) originalMsg = fwdData.origMsg;
        const preview = getMessageData(originalEventContent, true, fwdData ? originalMsg : originalEventContent.body);

        replyMsg = getReplyWithOriginalMsg(originalEvent, preview ? preview : originalMsg, content.body);
      } else if (originalEvent && content.original_msg_deleted) {
        // the original message was deleted
        replyMsg = getReplyWithOriginalMsg(
          originalEvent,
          renderToString(messageDeletedElement(originalEvent)),
          content.body
        );
      }
      content.formatted_body = replyMsg;
    }
  };

  const getForwardMsg = (content: any) => {
    let finalMsg = content.body;
    let isFwdMsg = false;
    const fwdData = processForwardMessage(content);

    if (fwdData) {
      const { sender, origMsg, fwdMsg } = fwdData;
      const preview = getMessageData(content, true, origMsg);
      finalMsg = `<mx-forward><blockquote><span>${t("FORWARD_FROM")} </span><i>${sender}</i><br>${
        preview ?? origMsg
      }</blockquote></mx-forward>${fwdMsg}`;
      content.formatted_body = finalMsg;
      isFwdMsg = true;
    }
    return isFwdMsg;
  };

  const sendImage = async (res: any, file: any) => {
    try {
      const { width, height } = await imageDimensions(file);
      const content = {
        url: res ? res.content_uri : "",
        msgtype: "m.image",
        body: file.name,
        info: {
          mimetype: file.type,
          size: file.size,
          h: height,
          w: width,
        },
      };
      await sendMessage(content);
    } catch (e) {
      logger.error(`${module}: Error in sendImage - ${e.message}`);
    }
  };

  const sendFile = async (res: any, file: any) => {
    try {
      const content = {
        url: res ? res.content_uri : "",
        msgtype: "m.file",
        body: file.name,
        info: {
          mimetype: file.type,
          size: file.size,
        },
      };
      await sendMessage(content);
    } catch (e) {
      logger.error(`${module}: Error in sendFile - ${e.message}`);
    }
  };

  const updateMessage = (event: MatrixEvent, valueHTML: string) => {
    const value = valueHTML.replaceAll("<p>", "").replaceAll("</p>", "");
    let msg = null;
    const finalEventContent = getEventContent(event);
    if (finalEventContent) {
      const isReplyMsg = isReplyMessage(finalEventContent);
      const isForwardedMsg = isForwardedMessage(finalEventContent);
      if (isReplyMsg || isForwardedMsg) {
        let htmlBody = null,
          htmlFormattedBody = null;
        if (isReplyMsg) {
          const match = finalEventContent.formatted_body.match(/<mx-reply>.*<\/mx-reply><p>(?<origMsg>.*)<\/p>/);
          const groups = match ? match.groups : null;
          htmlBody = value;
          htmlFormattedBody = groups
            ? finalEventContent.formatted_body.replace(groups.origMsg, value)
            : finalEventContent.formatted_body;
        }

        if (isForwardedMsg) {
          const fwdDataHTML = processForwardMessage(event.event.content);
          const fwdData = fwdDataHTML?.fwdMsg?.replaceAll("<p>", "").replaceAll("</p>", "");
          htmlBody = fwdData ? finalEventContent.body.replace(fwdData, value) : finalEventContent.body;
          htmlFormattedBody = fwdDataHTML
            ? finalEventContent.formatted_body.replace(fwdDataHTML.fwdMsg, valueHTML)
            : finalEventContent.formatted_body;
        }

        if (htmlBody && htmlFormattedBody) {
          msg = {
            "m.new_content": finalEventContent,
            "m.relates_to": {
              ...finalEventContent["m.relates_to"],
              rel_type: "m.replace",
              event_id: event.event.event_id,
            },
            msgtype: finalEventContent.msgtype,
            body: htmlBody,
            formatted_body: htmlFormattedBody,
          };
          msg["m.new_content"].edited = true;
          msg["m.new_content"].body = htmlBody;
          msg["m.new_content"].formatted_body = htmlFormattedBody;
        }
      } else {
        msg = {
          "m.new_content": {
            msgtype: "m.text",
            body: value,
            formatted_body: valueHTML,
            edited: true,
          },
          "m.relates_to": {
            rel_type: "m.replace",
            event_id: finalEventContent["m.relates_to"]
              ? finalEventContent["m.relates_to"].event_id
              : event.event.event_id,
          },
          msgtype: "m.text",
          body: value,
          formatted_body: valueHTML,
        };
      }

      if (msg) sendMessage(msg);
    }
  };

  const replyMessage = (event: MatrixEvent, reply: string, htmlReply: string) => {
    if (event.event.content) {
      let msg = {
        msgtype: "m.text",
        body: reply,
        format: "org.matrix.custom.html",
        formatted_body: htmlReply,
        "m.relates_to": {
          "m.in_reply_to": {
            event_id: event.event.event_id,
          },
        },
      };
      sendMessage(msg);
    }
  };

  const forwardMessage = (event: MatrixEvent, fwdMsg: string, htmlForward: string) => {
    if (event.event.content) {
      let msg = JSON.parse(JSON.stringify(event.event.content));
      const senderName = getSenderDisplayName(event);
      const formatedMessage = `>>> ${senderName}: ${event.event.content.body}\n${fwdMsg}`;
      msg.body = formatedMessage;
      msg.formatted_body = `&gt;&gt;&gt; ${senderName}: ${convertLineBreakTextToHTML(
        event.event.content.body
      )}<br/>${htmlForward}`;
      // remove the indication that the original message was edited
      if (msg.edited) msg.edited = false;
      sendMessage(msg);
    }
  };

  useEffect(() => {
    if (!room) return;
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  return {
    sendMessage,
    sendTextMessage,
    sendHtmlMessage,
    sendReceipt,
    sendTyping,
    removeMessage,
    resendMessage,
    cancelPendingMessage,
    getSenderDisplayName,
    parseData,
    getReplyMsg,
    getForwardMsg,
    messageDeletedElement,
    sendImage,
    sendFile,
    getMessageData,
    getForwardMessageData,
    processForwardMessage,
    isReplyMessage,
    isForwardedMessage,
    updateMessage,
    replyMessage,
    forwardMessage,
    getEventContent,
  };
};

export default useMatrixMessage;
