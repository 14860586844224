import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useTheme } from "@mui/material/styles";
import DarkThemeIcon from "icons/DarkThemeIcon";
import LightThemeIcon from "icons/LightThemeIcon";
import SystemThemeIcon from "icons/SystemThemeIcon";
import styled from "themes/theme/baseTheme";

import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  root: {
    width: "100px",
    height: "110px",
  },
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(1),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },

    "&.Mui-selected": {
      "& svg": {
        border: "3px solid",
        borderColor: theme.palette.icon.main,
        borderRadius: "13px",
      },
      backgroundColor: "unset",
    },
  },
}));

interface ThemePickerProps {
  currentTheme: string;
  onChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void;
}

const ThemePicker = ({ ...props }: ThemePickerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { currentTheme, onChange } = props;
  const iconStyle = {
    width: "101px",
    height: "109px",
    color: theme.palette.icon.main,
    padding: "4px",
  };

  return (
    <StyledToggleButtonGroup orientation="horizontal" exclusive value={currentTheme} onChange={onChange}>
      <ToggleButton value="light" aria-label="light">
        <Stack direction="column" spacing={1}>
          <LightThemeIcon sx={iconStyle} />
          <Typography variant="regular" sx={{ textTransform: "capitalize" }}>
            {t("LIGHT")}
          </Typography>
        </Stack>
      </ToggleButton>
      <ToggleButton value="dark" aria-label="dark">
        <Stack direction="column" spacing={1}>
          <DarkThemeIcon sx={iconStyle} />
          <Typography variant="regular" sx={{ textTransform: "capitalize" }}>
            {t("DARK")}
          </Typography>
        </Stack>
      </ToggleButton>
      <ToggleButton value="system" aria-label="system">
        <Stack direction="column" spacing={1}>
          <SystemThemeIcon sx={iconStyle} />
          <Typography variant="regular" sx={{ textTransform: "capitalize" }}>
            {t("SYSTEM_THEME")}
          </Typography>
        </Stack>
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};

export default ThemePicker;
