import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import Fab from "@mui/material/Fab";
import styles from "./InitialScreen.module.scss";
import { useRouter, useLocalStorage } from "hooks";
import { localStorageKeys } from "utils/constants";
import { getLogger } from "logger/appLogger";
import { RootState } from "store/root-reducer";
import { isEmpty } from "utils/helpers";

const InitialScreen = () => {
  const { origin, search } = window.location;
  const endpointData = useSelector((state: RootState) => state.provisioning.endpointData);
  const router = useRouter();
  const currentState = "Initializing Application";
  const isElectron = window.navigator.userAgent.indexOf("Electron") !== -1;
  const [, setCurrentTenantName] = useLocalStorage(localStorageKeys.TENANT_NAME);
  const logger = useMemo(() => getLogger("initial"), []);

  const handleElectronInit = (): { electronLogin: boolean; path: string | null } => {
    let path = null;
    let electronLogin = false;
    logger.debug(`handleElectronInit: search='${search}'`);

    if (search) {
      const urlParams = new URLSearchParams(search);
      let tenantName = urlParams.get("tenantName");
      // tenant name should be store in lower case to avoid token issues
      tenantName = tenantName ? tenantName.toLowerCase() : tenantName;
      const autoLogin = urlParams.get("autoLogin");
      const redirectUrl = urlParams.get("redirect_url");
      electronLogin = urlParams.get("electronLogin") !== null;

      logger.debug(
        `handleElectronInit: tenantName='${tenantName}', autoLogin=${autoLogin}, electronLogin=${electronLogin}, redirectUrl='${redirectUrl}'`
      );

      if (autoLogin && !isEmpty(tenantName)) {
        if (window.ipcRenderer) {
          const hsStore = { hs: origin, tenant: tenantName, last: true };
          logger.debug(`${module}: handleElectronInit:setHSStore ${JSON.stringify(hsStore)}`);
          window.ipcRenderer.invoke("setHSStore", hsStore);
        }
        setCurrentTenantName(tenantName);
        path = `${origin}/tenant/${tenantName}/tenant-provisioning/api/v1/auth/login?originalRedirectUrl=${getHomeUrl()}`;
      }
    }
    const ret = { electronLogin, path };
    logger.log(`handleElectronInit: ${JSON.stringify(ret)}`);
    return ret;
  };

  const getHomeUrl = function () {
    try {
      let host = "";
      if (endpointData) host = `https://${endpointData.redirect_url}`;
      else {
        const pathSep = origin.charAt(origin.length - 1) === "/" ? "" : "/";
        host = `${origin}${pathSep}`;
      }
      return `${host}#/home`;
    } catch (e) {
      logger.error(`Error in InitialScreen.getHomeUrl - ${e}`);
    }
    return origin;
  };

  useEffect(() => {
    if (isElectron) {
      const { electronLogin, path } = handleElectronInit();
      if (electronLogin) {
        window.ipcRenderer.send("openLoginPage", {});
        return;
      } else if (!isEmpty(path)) {
        window.location.href = path!;
        return;
      }
    }
    // ==> route to LoginScreen with initial token data in params
    router.push("/auth");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className={styles.main}>
      <Fab aria-label="checked" color="primary">
        <CheckIcon fontSize="large" />
      </Fab>
      <div className={`${styles.message}`}>{currentState}</div>
    </div>
  );
};

export default InitialScreen;
