import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as NoEvents } from "assets/images/no-events.svg";

const NoEventsIcon = (props: any) => {
  const theme = useTheme();

  return (
    <SvgIcon
      component={NoEvents}
      inheritViewBox
      sx={{
        fontSize: "161px",
        color: theme.palette.primary.main,
      }}
    />
  );
};

export default NoEventsIcon;
