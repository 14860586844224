import { SessionState } from "sip.js";
import { UiSipCall, UiSipCallInfo } from "types/SIP";

// The overall state of the SIP User
export enum SipUserStates {
  STARTED = "Started",
  STOPPED = "Stopped",
}

// Callbacks into application
export interface SipDelegate {
  onCallSessionTerminated?: (sessionState: SessionState, callId: string) => void;
  onRegistered?: (restarting: boolean) => void;
  onUnregistered?: (restarting: boolean) => void;
  onTerminated?: (restarting: boolean) => void;
  onReconnectsFailed?: (restarting: boolean) => void;
  onServerConnect?: () => void;
  onServerDisconnect?: (error: Error | undefined) => void;
  onCallReceived?: (sipCallInfo: UiSipCallInfo) => void;
  onInCallInvite?: (callId: string) => void;
  onUpdateSipCalls?: (sipCalls: UiSipCall[], canInitateSipCall: boolean) => void;
}
