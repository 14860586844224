import { put, call, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/types/provisioning";
import { Types } from "Types";
import { TenantProvisioningApiProvider } from "services/ProvisioningApiProvider/TenantProvisioningApiProvider";
import { getMatrixAPIProvider } from "services/MatrixAPIProvider";
import { matrixActions } from "../actions/matrix";

import { forwardTo, getApiResponseErrorMessage } from "utils/helpers";
import { localStorageKeys } from "utils/constants";
import { LocalEventTypeEnum, InternalMessageContentType, AdHocInviteType } from "types/Matrix";
import {
  EndpointData,
  ProvisioningConfiguration,
  SearchResult,
  UserKeycloakData,
} from "services/ProvisioningApiProvider/types/Provisioning";
import { ChatRoomInfo, RoomInfo } from "services/ProvisioningApiProvider/types/Rooms";
import { getLogger } from "logger/appLogger";

const matrixProvider = getMatrixAPIProvider();
const logger = getLogger("redux");

function* getProvisioningConfig() {
  try {
    const res: ProvisioningConfiguration = yield TenantProvisioningApiProvider.Instance.getProvisioningConfiguration();
    localStorage.setItem(localStorageKeys.PROVISIONING_CONFIG, JSON.stringify(res));

    const isElectron = window.navigator.userAgent.indexOf("Electron") !== -1;
    if (isElectron) {
      const appUpdateConfig = { url: res?.appUpdateUrl };
      window.ipcRenderer.send("updateAppConfig", appUpdateConfig);
    }

    yield put({
      type: actionTypes.GET_PROVISIONING_CONFIG_SUCCEEDED,
      config: res,
    });
  } catch (e) {
    yield put({
      type: actionTypes.GET_PROVISIONING_CONFIG_FAILED,
      message: getApiResponseErrorMessage(e),
    });
  }
}

function* setProvisioiningEndpointData(action: Types.RootAction) {
  try {
    const res: EndpointData = action.payload;
    localStorage.setItem(localStorageKeys.ENDPOINT_DATA, JSON.stringify(res));

    yield put({
      type: actionTypes.SET_ENDPOINT_DATA_SUCCEEDED,
      data: res,
    });
  } catch (e) {
    yield put({
      type: actionTypes.SET_ENDPOINT_DATA_FAILED,
      message: getApiResponseErrorMessage(e),
    });
  }
}

function* searchProvisioiningUser(action: Types.RootAction) {
  try {
    const result: SearchResult = yield TenantProvisioningApiProvider.Instance.searchUser(action.payload);

    yield put({
      type: actionTypes.SEARCH_PROVISIONING_USER_SUCCEEDED,
      userSearchResults: result?.results,
    });
  } catch (e) {
    yield put({
      type: actionTypes.SEARCH_PROVISIONING_USER_FAILED,
      message: getApiResponseErrorMessage(e),
    });
  }
}

function* searchProvisioiningByUsername(action: Types.RootAction) {
  try {
    const result: UserKeycloakData = yield TenantProvisioningApiProvider.Instance.searchByUsername(action.payload);
    yield put({
      type: actionTypes.SEARCH_BY_USERNAME_PROVISIONING_USER_SUCCEEDED,
      userSearchByUsernameResults: result,
    });
  } catch (e) {
    yield put({
      type: actionTypes.SEARCH_BY_USERNAME_PROVISIONING_USER_FAILED,
      message: getApiResponseErrorMessage(e),
    });
  }
}

function* searchProvisioiningByListOfUsernames(action: Types.RootAction) {
  try {
    const result: UserKeycloakData[] = yield TenantProvisioningApiProvider.Instance.searchByListOfUsernames(
      action.payload
    );
    yield put({
      type: actionTypes.SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS_SUCCEEDED,
      userSearchByListOfUsernamesResults: result,
    });
  } catch (e) {
    yield put({
      type: actionTypes.SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS_FAILED,
      message: getApiResponseErrorMessage(e),
    });
  }
}

export function* searchProvisioiningByListOfEmails(action: Types.RootAction) {
  try {
    const result: UserKeycloakData[] = yield TenantProvisioningApiProvider.Instance.searchByListOfEmails(
      action.payload
    );
    yield put({
      type: actionTypes.SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS_SUCCEEDED,
      userSearchByListOfEmailsResults: result,
    });
    return result;
  } catch (e) {
    yield put({
      type: actionTypes.SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS_FAILED,
      message: getApiResponseErrorMessage(e),
    });
  }
}

function* createGroupChatRoom(action: Types.RootAction) {
  try {
    const { data, type } = action.payload;

    const result: ChatRoomInfo = yield TenantProvisioningApiProvider.Instance.createProvisioningGroupChatRoom(data);

    yield call(forwardTo, result.matrix_room_id, type === AdHocInviteType.conf);

    if (type === AdHocInviteType.conf) {
      yield put(matrixActions.setRedirectToAdhocConf(true, result.matrix_room_id));
      yield put(matrixActions.hangupMatrixCall());
    }

    const content = {
      type: InternalMessageContentType.AdHocInvite,
      body: { inviteType: type, inviteIds: data.invite },
    };
    yield matrixProvider.sendMatrixEvent(result.matrix_room_id, LocalEventTypeEnum.InternalMessage, content);
  } catch (e) {
    logger.error(`provisioning-saga: Error in createGroupChatRoom - ${getApiResponseErrorMessage(e)}`);
  }
}

function* createRoom(action: Types.RootAction) {
  try {
    const { data } = action.payload;
    const { description, ...other } = data;
    const result: RoomInfo = yield TenantProvisioningApiProvider.Instance.createProvisioningRoom(other);

    if (data?.is_hidden === true) {
      // do not route to newly created room if hidden/meeting room
    } else {
      yield call(forwardTo, result.matrix_room_id);
    }

    yield put({
      type: actionTypes.CREATE_ROOM_SUCCEEDED,
      createdRoom: result,
    });
    return result;
  } catch (e) {
    yield put({
      type: actionTypes.CREATE_ROOM_FAILED,
      message: getApiResponseErrorMessage(e),
    });
  }
}

function* updateRoom(action: Types.RootAction) {
  try {
    const { roomId, data } = action.payload;
    yield TenantProvisioningApiProvider.Instance.updateProvisioningRoom(roomId, data);
    yield put({
      type: actionTypes.UPDATE_ROOM_SUCCEEDED,
      data: action.payload,
    });
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_ROOM_FAILED,
      message: getApiResponseErrorMessage(e),
    });
  }
}

function* updateGroupChatRoom(action: Types.RootAction) {
  try {
    const { roomId, data } = action.payload;
    yield TenantProvisioningApiProvider.Instance.updateProvisioningGroupChatRoom(roomId, data);
    yield put({
      type: actionTypes.UPDATE_GROUP_CHAT_ROOM_SUCCEEDED,
      data: action.payload,
    });
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_GROUP_CHAT_ROOM_FAILED,
      message: getApiResponseErrorMessage(e),
    });
  }
}

function* actionWatcher() {
  yield takeLatest(actionTypes.GET_PROVISIONING_CONFIG, getProvisioningConfig);
  yield takeLatest(actionTypes.SET_ENDPOINT_DATA, setProvisioiningEndpointData);
  yield takeLatest(actionTypes.SEARCH_PROVISIONING_USER, searchProvisioiningUser);
  yield takeLatest(actionTypes.SEARCH_BY_USERNAME_PROVISIONING_USER, searchProvisioiningByUsername);
  yield takeLatest(actionTypes.SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS, searchProvisioiningByListOfUsernames);
  yield takeLatest(actionTypes.SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS, searchProvisioiningByListOfEmails);
  yield takeLatest(actionTypes.CREATE_ROOM, createRoom);
  yield takeLatest(actionTypes.UPDATE_ROOM, updateRoom);
  yield takeLatest(actionTypes.UPDATE_GROUP_CHAT_ROOM, updateGroupChatRoom);

  yield takeLatest(actionTypes.CREATE_GROUP_CHAT_ROOM, createGroupChatRoom);
}

export default actionWatcher;
