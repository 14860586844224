import axios from "axios";
import { Method } from "axios";
import { ApiError } from "./types/ApiError";
import { getAccessToken, refreshAccessToken, validateAccessToken } from "./ProvisioningTokenProvider";
import { getLogger } from "logger/appLogger";

export class ApiProvider {
  // fields
  protected _headers: any;
  protected _options: any;

  // invocation counter
  static seq: number = 0;

  // log
  private log = getLogger("api-provider");

  // constructor
  // setup initial options and header values
  constructor(apiPath: string, extraHeaders: any = {}) {
    this._headers = {
      Accept: "application/json",
      ...extraHeaders,
    };

    this._options = {
      baseURL: apiPath,
    };
  }

  //
  // generic REST API invocation
  //
  public async restApiCall<T>({
    path,
    method,
    params = null,
    body = null,
    headers = {},
    retry = false,
    withToken = true,
    ignoreErrors = [],
  }: {
    path: string;
    method: Method;
    params?: any;
    body?: any;
    headers?: any;
    retry?: boolean;
    withToken?: boolean;
    ignoreErrors?: number[];
  }): Promise<T> {
    const seq = ++ApiProvider.seq;
    const traceTxt =
      `request #${seq} path=${path} method=${method} params=${JSON.stringify(params)} ` +
      `body=${JSON.stringify(body)} headers=${JSON.stringify(headers)} ` +
      `retry=${retry} withToken=${withToken}`;
    this.log.debug(`${traceTxt}`);

    // check for hibernate token-refresh miss
    try {
      await validateAccessToken();
    } catch (err: any) {
      const errorText: string = `failed to validate token in ${traceTxt}`;
      this.log.error("ApiProvider.genericRestApiCall() error: ", errorText);
      return Promise.reject(new ApiError(errorText));
    }

    // do a retry upon 401
    let token: string;
    try {
      if (retry) {
        await refreshAccessToken();
      }
      token = getAccessToken();
    } catch (err: any) {
      const errorText: string = `failed to fetch token in ${traceTxt}`;
      this.log.error("ApiProvider.genericRestApiCall() error: ", errorText);
      return Promise.reject(new ApiError(errorText));
    }

    let callHeaders: any = {
      ...this._headers,
      ...headers,
      ...(withToken && token?.length > 0 ? { Authorization: `Bearer ${token}` } : {}),
    };

    const validateStatus = (status: number) => {
      return (status >= 200 && status < 300) || ignoreErrors.some((st) => st === status);
    };

    let options: any = {
      ...this._options,
      ...(params ? { params: params } : {}),
      ...(body ? { data: body } : {}),
      url: path,
      method,
      headers: callHeaders,
      validateStatus,
    };

    try {
      const response = await axios.request<T>(options);

      // Note: do not check for e.g. response.status == 200 because we may as well receive a 201
      // All the invalid requests end up in the catch block
      this.log.debug(`response #${seq}/${response.status}:${JSON.stringify(response.data)}`);

      return Promise.resolve(response.data);
    } catch (error: any) {
      const errorText: string = `Http response error in ${traceTxt}`;
      let apiError: ApiError = new ApiError(errorText);

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        apiError.data = error.response.data;
        apiError.status = error.response.status;
        apiError.headers = error.response.headers;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        apiError.request = error.request;
      } else {
        // Something happened in setting up the request that triggered an Error
        apiError.message = error.message;
      }

      this.log.debug(`error #${seq}: ${JSON.stringify(apiError)}`);

      // try again by fetching the token first
      if (!retry && apiError.status === 401) {
        return this.restApiCall<T>({
          path,
          method,
          params,
          body,
          headers: callHeaders,
          retry: true,
          withToken,
        });
      } else {
        const errorText: string = `Http response error in ${traceTxt}`;
        this.log.error(`Error ${apiError?.status} in ${errorText} : ${JSON.stringify(apiError)}`);
        return Promise.reject(apiError);
      }
    }
  }
}
