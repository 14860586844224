import { Components, Theme } from "@mui/material";
import { buttonClasses } from "@mui/material/Button";

const MuiButton: Components["MuiButton"] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const palette = (theme as Theme).palette;
      return {
        ...(ownerState.size === "large" && { lineHeight: "26px", letterSpacing: "0.46px", height: 42 }),
        ...(ownerState.size === "medium" && { lineHeight: "24px", letterSpacing: "0.4px", height: 36 }),
        ...(ownerState.size === "small" && { lineHeight: "22px", letterSpacing: "0.46px", height: 22 }),
        ...(ownerState.variant === "contained" &&
          ownerState.color === "primary" && {
            color: `${palette.text.button.contained.active}`,
            "&:hover": {
              backgroundColor: `${palette.background.button.contained.hover}`,
              color: `${palette.text.button.contained.hover}`,
            },
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: `${palette.background.button.contained.disabled}`,
              border: `1px solid ${palette.border.button.contained.disabled}`,
              color: `${palette.text.button.contained.disabled}`,
            },
          }),
        ...(ownerState.variant === "outlined" &&
          ownerState.color === "primary" && {
            color: `${palette.text.button.outlined.active}`,
            backgroundColor: palette.background.button.outlined.active,
            border: `1px solid ${palette.border.button.outlined.active}`,
            "&:hover": {
              backgroundColor: `${palette.background.button.outlined.hover}`,
              color: `${palette.text.button.outlined.hover}`,
              border: `1px solid ${palette.border.button.outlined.hover}`,
            },
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: `${palette.background.button.outlined.disabled}`,
              border: `1px solid ${palette.border.button.outlined.disabled}`,
              color: `${palette.text.button.outlined.disabled}`,
            },
          }),
        ...(ownerState.variant === "text" &&
          ownerState.color === "primary" && {
            color: `${palette.text.button.text.active}`,
            backgroundColor: palette.background.button.text.active,
            "&:hover": {
              backgroundColor: `${palette.background.button.text.hover}`,
              color: `${palette.text.button.text.hover}`,
            },
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: `${palette.background.button.text.disabled}`,
              color: `${palette.text.button.text.disabled}`,
            },
          }),
        width: "100%",
      };
    },
  },
};

export default MuiButton;
