import styled from "themes/theme/baseTheme";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./Accordion.module.scss";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import { IconButton } from "components";

const StyledExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  height: "25px",
  width: "25px",
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.icon.main,
  border: `1px solid ${theme.palette.border.accordion}`,
  borderRadius: "50%",
}));

const CustomAccordion = ({ ...props }) => {
  return (
    <Accordion
      elevation={0}
      sx={{
        backgroundColor: "transparent !important",
        "&::before": {
          backgroundColor: "transparent !important",
        },
      }}
      className={props.className}
      {...props}
    >
      <AccordionSummary
        sx={{ minHeight: "48px !important", height: "48px !important" }}
        expandIcon={<StyledExpandMoreIcon />}
      >
        <div className={styles.separator}>
          <Typography variant="h6">{props.title}</Typography>
          {props.tooltip && (
            <IconButton aria-label="info" tooltip={props.tooltip} tooltipPlacement="top" style={{ padding: "8px" }}>
              <InfoOutlinedIcon />
            </IconButton>
          )}
        </div>
      </AccordionSummary>

      <AccordionDetails sx={{ padding: "0 0 0 0 !important", display: "flex", flexDirection: "column" }}>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
