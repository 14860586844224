import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { presenceActions } from "store/actions/presence";
import { calendarUpdate } from "store/actions/app";
import { PresenceStates } from "types/Presence";
import { RootState } from "store/root-reducer";
import { JsonStringify } from "./utils";
import { getLogger } from "logger/appLogger";
import { getCalendarEvents, getNextEvent } from "services/CalendarProvider/CalendarProvider";
import { CalendarEvent } from "services/CalendarProvider/types/CalendarEvent";
import { onlyInLeft } from "./helpers";
import { useMatrixRooms } from "hooks";

let statusChanged = false;
let presenceStatus = PresenceStates.available;
let isOutlookCalendarConnected = false;
let isGoogleCalendarConnected = false;
let currentEvent: CalendarEvent | undefined = undefined;

const WatchCalendar = () => {
  const logger = useMemo(() => getLogger("calendar"), []);
  const dispatch = useDispatch();
  const { updateRoomsMembersBasedOnCalendarEvents } = useMatrixRooms();
  const { changePresence } = presenceActions;
  const currentPresence = useSelector((state: RootState) => state.presence.currentPresence);
  const outlookCalendarConnected = useSelector((state: RootState) => state.app.outlookCalendarConnected);
  const googleCalendarConnected = useSelector((state: RootState) => state.app.googleCalendarConnected);
  presenceStatus = currentPresence;
  isOutlookCalendarConnected = outlookCalendarConnected;
  isGoogleCalendarConnected = googleCalendarConnected;
  let calendarHashes: string[] = [];

  // check if any calendar entry changed, added, deleted
  const updateHashes = (calendarEntries: CalendarEvent[]) => {
    const createCalendarHashes = (calendarEntries: any[]) => {
      return calendarEntries.map((e: any) => e?.hash || "");
    };

    const areEqualCalendarHashes = (arr1: any[], arr2: any[]) => {
      if (arr1.length !== arr2.length) {
        return false;
      }
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }
      return true;
    };

    const newCalendarHashes = createCalendarHashes(calendarEntries);

    if (!areEqualCalendarHashes(calendarHashes, newCalendarHashes)) {
      updateCalendarEvents(calendarEntries, calendarHashes, newCalendarHashes);
      calendarHashes = newCalendarHashes;
      dispatch(calendarUpdate()); // for next->current->next toggle
    }
  };

  const updateCalendarEvents = (
    calendarEntries: CalendarEvent[],
    calendarHashes: string[],
    newCalendarHashes: string[]
  ) => {
    // A comparer used to determine if two entries are equal.
    const isSameHash = (a: string, b: string) => a === b;

    const newHashes: string[] = onlyInLeft(newCalendarHashes, calendarHashes, isSameHash);

    if (newHashes.length > 0) {
      const newCalendarEntries = calendarEntries.filter((el) => {
        return newHashes.some((h) => h === el.hash);
      });

      updateRoomsMembersBasedOnCalendarEvents(newCalendarEntries);
    }
  };

  const changePresenceStatus = (status: PresenceStates) => {
    const opts = {
      transitionName: "local-status-change",
      status,
    };
    dispatch(changePresence(opts));
    dispatch(calendarUpdate());
  };

  const handleChangedCurrentEvent = (event: CalendarEvent | undefined) => {
    let changed = false;

    if (!currentEvent && !event) {
      changed = false;
    } else if ((currentEvent && !event) || (!currentEvent && event)) {
      changed = true;
    } else {
      const hash1 = currentEvent?.hash;
      const hash2 = event?.hash;
      if (hash1 && hash2) {
        changed = hash1 !== hash2;
      } else {
        const json1 = JsonStringify(currentEvent);
        const json2 = JsonStringify(event);
        changed = json1 !== json2;
      }
    }

    currentEvent = event;

    if (changed) {
      logger.debug(`changed current-event: ${JsonStringify(currentEvent)}`);
      dispatch(calendarUpdate());
    }
  };

  const tick = () => {
    if (
      !isOutlookCalendarConnected &&
      !isGoogleCalendarConnected &&
      statusChanged &&
      presenceStatus === PresenceStates.busy
    ) {
      changePresenceStatus(PresenceStates.available);
      statusChanged = false;
    }
    getCalendarEvents().then((body) => {
      updateHashes(body);
      let event = getNextEvent(body);
      handleChangedCurrentEvent(event);

      if (event?.type === "CURRENT_EVENT" && presenceStatus === PresenceStates.available && !statusChanged) {
        changePresenceStatus(PresenceStates.busy);
        statusChanged = true;
      }
      if (event?.type === "NEXT_EVENT" && statusChanged && presenceStatus === PresenceStates.busy) {
        changePresenceStatus(PresenceStates.available);
        statusChanged = false;
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        tick();
      } catch (err) {
        console.error(err);
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default WatchCalendar;
