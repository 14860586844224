import isElectron from "is-electron";
import { getLogger } from "logger/appLogger";
import { OpenWindow } from "types/Electron";

const logger = getLogger("child-window");

class ChildWindows {
  windows = new Map<string, Window>();
  static instance = new ChildWindows();
}

const openChildWindowNative = (url: string, name: string, id: string) => {
  logger.debug(`openChildWindowNative url=${url} name=${name} id=${id}`);
  try {
    // window features
    const strWindowFeatures = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

    let childWindow = ChildWindows.instance.windows.get(id);

    if (childWindow) {
      logger.debug(`openChildWindowNative id=${id} found`);
      try {
        childWindow.close();
      } catch (e) {
        logger.warn(`openChildWindowNative: closing existing window: ${e}`);
      }
      ChildWindows.instance.windows.delete(id);
      childWindow = undefined;
    }

    childWindow = window.open(url, name, strWindowFeatures) || undefined;

    if (childWindow) {
      ChildWindows.instance.windows.set(id, childWindow);
    }
  } catch (e) {
    logger.error(`openChildWindowNative: ${e}`);
  }
};

export const openChildWindow = (url: string, name: string, id: string) => {
  if (isElectron()) {
    const openWindow: OpenWindow = { url, name, id };
    window.ipcRenderer.send("openWindow", openWindow);
  } else {
    openChildWindowNative(url, name, id);
  }
};

// close popup integration window
// see IntegrationView.jsx for different ways of openeing windows
export const closeChildWindow = (id: string) => {
  logger.debug(`closeChildWindow id=${id}`);
  try {
    if (isElectron()) {
      window.ipcRenderer.send("closeWindow", id);
    } else {
      const childWindow = ChildWindows.instance.windows.get(id);
      if (childWindow) {
        logger.debug(`closeChildWindow id=${id} found`);
        ChildWindows.instance.windows.delete(id);
        childWindow.close();
      }
    }
  } catch (e) {
    logger.error(`closeChildWindow: ${e}`);
  }
};
