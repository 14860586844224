import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { localStorageKeys } from "utils/constants";

import en from "./locales/en.json";
import uk from "./locales/uk.json";
import pt from "./locales/pt.json";
import es from "./locales/es.json";
import de from "./locales/de.json";
import it from "./locales/it.json";
import fr from "./locales/fr.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      uk: {
        translation: uk,
      },
      pt: {
        translation: pt,
      },
      es: {
        translation: es,
      },
      de: {
        translation: de,
      },
      fr: {
        translation: fr,
      },
      it: {
        translation: it,
      },
    },

    lng: localStorage.getItem(localStorageKeys.LANGUAGE) || "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
  });

if (process.env.NODE_ENV !== "production") {
  console.warn("window.i18n is available");
  window.i18n = i18n;
}

export default i18n;
