import * as actionTypes from "../actions/types/theme";
import { Types } from "Types";
import { ThemeState } from "store/types/theme";
import { defaultTheme } from "themes/theme/baseTheme";

const initialThemeState: ThemeState = {
  isDarkMode: false,
  preferSystemScheme: true,
  currentTheme: defaultTheme,
  currentBrand: "enghouse",
  openDrawer: true,
  canToggleDrawer: false,
};

const themeReducer = (state = initialThemeState, action: Types.RootAction): ThemeState => {
  switch (action.type) {
    case actionTypes.SET_DARK_MODE:
      return { ...state, isDarkMode: action.isDarkMode };
    case actionTypes.SET_THEME:
      return { ...state, currentTheme: action.currentTheme };
    case actionTypes.SET_PREFER_SYSTEM_SCHEME:
      return { ...state, preferSystemScheme: action.preferSystemScheme };
    case actionTypes.SET_TOGGLE:
      return { ...state, openDrawer: action.openDrawer };
    case actionTypes.SET_CANTOGGLE:
      return { ...state, canToggleDrawer: action.canToggleDrawer };
    default:
      return state;
  }
};

export default themeReducer;
