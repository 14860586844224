import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { test } from "utils/helpers";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const ConferenceActiveIndicator = (props) => {
  const { t } = useTranslation();
  const currentRoomId = useSelector((state) => state.matrix.roomId);
  const isCallActive = useSelector((state) => state.call.active);
  const isCallLeaving = useSelector((state) => state.call.leaving);
  const roomInCall = useSelector((state) => state.call.callRoomId);
  const recordingOn = useSelector((state) => state.call.recorderOn);
  const recordingPaused = useSelector((state) => state.call.recorderPaused);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const [color, setColor] = useState("");
  const isActiveConference = !isCallLeaving && isCallActive && roomInCall && roomInCall === currentRoomId;

  useEffect(() => {
    if (recordingOn && !recordingPaused) setColor("RGB(226, 87, 76)");
    else if (props.fullScreen) setColor("RGB(255, 255, 255)");
    else if (isDarkMode) setColor("RGB(255,255,255)");
    else setColor("RGB(0, 0, 0)");
  }, [isDarkMode, props.fullScreen, recordingOn, recordingPaused]);

  if (isActiveConference) {
    return (
      <span className="conference-active-indicator" style={{ color: color }} {...test("CONFERENCE_ACTIVE_INDICATOR")}>
        {recordingOn && !recordingPaused && (
          <FiberManualRecordIcon
            style={{
              color: "RGB(226, 87, 76)",
              fontSize: "1.2rem",
              verticalAlign: "top",
            }}
          />
        )}
        {t("CONFERENCEINPROGRESS")}{" "}
      </span>
    );
  } else {
    return null;
  }
};

export default ConferenceActiveIndicator;
