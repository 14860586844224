export const CLEAR = "CLEAR";

export const GET_PROVISIONING_CONFIG = "GET_PROVISIONING_CONFIG";
export const GET_PROVISIONING_CONFIG_SUCCEEDED = "GET_PROVISIONING_CONFIG_SUCCEEDED";
export const GET_PROVISIONING_CONFIG_FAILED = "GET_PROVISIONING_CONFIG_FAILED";

export const SET_ENDPOINT_DATA = "SET_ENDPOINT_DATA";
export const SET_ENDPOINT_DATA_SUCCEEDED = "SET_ENDPOINT_DATA_SUCCEEDED";
export const SET_ENDPOINT_DATA_FAILED = "SET_ENDPOINT_DATA_FAILED";

export const SEARCH_PROVISIONING_USER = "SEARCH_PROVISIONING_USER";
export const SEARCH_PROVISIONING_USER_SUCCEEDED = "SEARCH_PROVISIONING_USER_SUCCEEDED";
export const SEARCH_PROVISIONING_USER_FAILED = "SEARCH_PROVISIONING_USER_FAILED";
export const SEARCH_PROVISIONING_USER_RESET = "SEARCH_PROVISIONING_USER_RESET";

export const SEARCH_BY_USERNAME_PROVISIONING_USER = "SEARCH_BY_USERNAME_PROVISIONING_USER";
export const SEARCH_BY_USERNAME_PROVISIONING_USER_SUCCEEDED = "SEARCH_BY_USERNAME_PROVISIONING_USER_SUCCEEDED";
export const SEARCH_BY_USERNAME_PROVISIONING_USER_FAILED = "SEARCH_BY_USERNAME_PROVISIONING_USER_FAILED";

export const SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS = "SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS";
export const SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS_SUCCEEDED =
  "SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS_SUCCEEDED";
export const SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS_FAILED = "SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS_FAILED";
export const SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS_RESET = "SEARCH_BY_LIST_USERNAMES_PROVISIONING_USERS_RESET";

export const SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS = "SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS";
export const SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS_SUCCEEDED = "SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS_SUCCEEDED";
export const SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS_FAILED = "SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS_FAILED";
export const SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS_RESET = "SEARCH_BY_LIST_EMAILS_PROVISIONING_USERS_RESET";

export const CREATE_ROOM = "CREATE_ROOM";
export const CREATE_ROOM_SUCCEEDED = "CREATE_ROOM_SUCCEEDED";
export const CREATE_ROOM_FAILED = "CREATE_ROOM_FAILED";

export const UPDATE_ROOM = "UPDATE_ROOM";
export const UPDATE_ROOM_SUCCEEDED = "UPDATE_ROOM_SUCCEEDED";
export const UPDATE_ROOM_FAILED = "UPDATE_ROOM_FAILED";

export const UPDATE_GROUP_CHAT_ROOM = "UPDATE_GROUP_CHAT_ROOM";
export const UPDATE_GROUP_CHAT_ROOM_SUCCEEDED = "UPDATE_GROUP_CHAT_ROOM_SUCCEEDED";
export const UPDATE_GROUP_CHAT_ROOM_FAILED = "UPDATE_GROUP_CHAT_ROOM_FAILED";

export const CREATE_GROUP_CHAT_ROOM = "CREATE_GROUP_CHAT_ROOM";
export const CREATE_GROUP_CHAT_ROOM_SUCCEEDED = "CREATE_GROUP_CHAT_ROOM_SUCCEEDED";
export const CREATE_GROUP_CHAT_ROOM_FAILED = "CREATE_GROUP_CHAT_ROOM_FAILED";
