import moment from "moment";
import { getFromLocalStorage } from "utils/helpers";
import { localStorageKeys } from "utils/constants";
import axios from "axios";
import "isomorphic-fetch";

// based on Microsoft Authentication Library for JavaScript (MSAL.js)
// https://github.com/AzureAD/microsoft-authentication-library-for-js/
import {
  PublicClientApplication,
  BrowserCacheLocation,
  SilentRequest,
  CacheLookupPolicy,
  InteractionRequiredAuthError,
  BrowserAuthError,
  AccountInfo,
} from "@azure/msal-browser";

import { Event as MicrosoftGraphEvent } from "@microsoft/microsoft-graph-types";

import { getLogger } from "logger/appLogger";
import { outlookCalendarEvent } from "store/actions/app";
import getStore from "store/store";
import { LocalStorageVariableUser } from "utils/localStorageVariableUser";
import { isDebugLogger } from "logger/logger";
import { JsonStringify } from "utils/utils";

const logger = getLogger("outlook");
const msgLogger = getLogger("outlook.msg");
const scopes = ["openid", "offline_access", "Calendars.Read", "user.read"];

const TOKEN_REFRESH_INTERVAL_PERCENTAGE = 66;
const MAX_REFRESH_FAILS_BEFORE_LOGOUT = 10;

// ====================================================
// Silent request types
// ====================================================

enum SilentRequestType {
  Silent = "silent",
  Refresh = "refresh",
  ForceRefresh = "force-refresh",
}

// ====================================================
// A subset of popup types
// ====================================================
type LoginPopupPrompt = "login" | "select_account";

// ====================================================
// state
// ====================================================
interface IState {
  loggedIn: boolean;
  accessToken: string | null;
  isRefreshing: boolean;
  isPopupActive: boolean;
  refreshFails: number;
}

let defaultState: IState = {
  loggedIn: false,
  accessToken: null,
  isRefreshing: false,
  isPopupActive: false,
  refreshFails: 0,
};

let _state: IState = { ...defaultState };
let _msalInstance: PublicClientApplication;
let _runOnce = false;

const _cachedAuthenticationRecord: LocalStorageVariableUser<AccountInfo> = new LocalStorageVariableUser(
  localStorageKeys.OUTLOOK_AUTHENTICATION_RECORD
);

const isLoggedIn = () => _state.loggedIn && _state.accessToken != null;

const resetState = () => (_state = { ...defaultState });

const getHeaders = () => {
  return { Authorization: `Bearer ${_state.accessToken}` };
};

const getClientId = () => {
  const provisioningConfig = getFromLocalStorage(localStorageKeys.PROVISIONING_CONFIG);
  return provisioningConfig?.outlookIntegrationClientId || process.env.REACT_APP_OUTLOOK_CLIENT_ID;
};

// ====================================================
// dispatch to redux state
// ====================================================
const dispatch = (connected: boolean) => {
  getStore().dispatch(outlookCalendarEvent(connected));
};

// ====================================================
// init at start
// ====================================================
(() => {
  const msalConfig = {
    auth: {
      clientId: getClientId(),
      authority: "https://login.microsoftonline.com/common",
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
  };

  _msalInstance = new PublicClientApplication(msalConfig);
})();

// ====================================================
// logout using Microsoft Authentication Library
// - Initialization of MSAL (https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md)
// - Logging Out of MSAL (https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md)
// ====================================================
const logoutByMsal = async () => {
  try {
    const cachedAuthenticationRecord = _cachedAuthenticationRecord.get();
    if (cachedAuthenticationRecord) {
      const { homeAccountId } = cachedAuthenticationRecord;
      const currentAccount = _msalInstance.getAccountByHomeId(homeAccountId);
      const redirectPage = window.location.origin;

      await _msalInstance.logoutPopup({
        account: currentAccount,
        postLogoutRedirectUri: redirectPage,
        // mainWindowRedirectUri: redirectPage,
      });
    }
  } catch (error: any) {
    logger.error("Logout error: ", error);
  }
};

// ====================================================
// logout
// ====================================================
const logout = async () => {
  if (isLoggedIn()) {
    logger.debug("logout");
    await logoutByMsal();
    logoutSuccess();
  }
};

// logout helper
const logoutSuccess = () => {
  logger.info("logout success");
  if (_state.loggedIn) {
    dispatch(false);
  }
  resetState();
  logger.info("Removing cached authentication record (logout)");
  _cachedAuthenticationRecord.delete();
};

// ====================================================
// login fail helper
// ====================================================
const loginFail = (error: string) => {
  logger.error(error);
  if (_state.loggedIn) {
    dispatch(false);
  }
  resetState();
  logger.info("Removing cached authentication record (failed login)");
  _cachedAuthenticationRecord.delete();
};

// ====================================================
// login success helper
// ====================================================
function loginSuccess<T>(
  token: string,
  authenticationRecord: AccountInfo | null,
  dbgMsg: string | null,
  infoMsg: string | null,
  ret: T
): Promise<T> {
  if (dbgMsg) {
    logger.debug(dbgMsg);
  }
  if (infoMsg) {
    logger.info(infoMsg);
  }
  if (!_state.loggedIn) {
    dispatch(true);
  }
  _state.loggedIn = true;
  _state.accessToken = token;
  _state.isRefreshing = false;
  _state.isPopupActive = false;
  _state.refreshFails = 0;

  if (authenticationRecord) {
    _cachedAuthenticationRecord.set(authenticationRecord);
  }

  return Promise.resolve(ret);
}

// ====================================================
// token expiration calculations
// ====================================================
const isTokenExpirationSoon = (authenticationRecord: AccountInfo): boolean => {
  const nowSec = Math.floor(Date.now() / 1000);
  return authenticationRecord?.idTokenClaims?.exp
    ? nowSec + tokenRefreshTimeSec(authenticationRecord) > authenticationRecord.idTokenClaims.exp
    : true;
};

const tokenRefreshTimeSec = (authenticationRecord: AccountInfo): number => {
  if (
    authenticationRecord?.idTokenClaims?.exp &&
    authenticationRecord?.idTokenClaims?.iat &&
    authenticationRecord.idTokenClaims.exp > authenticationRecord.idTokenClaims.iat
  ) {
    const tokenValidityTimeSec: number =
      authenticationRecord.idTokenClaims.exp - authenticationRecord.idTokenClaims.iat;
    return (tokenValidityTimeSec * TOKEN_REFRESH_INTERVAL_PERCENTAGE) / 100;
  }
  return 0;
};

const tokenValidTimeLeftSec = (authenticationRecord: AccountInfo): number => {
  const nowSec = Math.floor(Date.now() / 1000);
  return authenticationRecord?.idTokenClaims?.exp ? authenticationRecord.idTokenClaims.exp - nowSec : 0;
};

const getSilentRequest = (type: SilentRequestType, authenticationRecord: AccountInfo): SilentRequest => {
  switch (type) {
    // @ts-ignore
    default:
      logger.error(`getSilentRequest: unknown type ${type}`);
    /* falls through */
    case SilentRequestType.Silent:
    /* falls through */
    case SilentRequestType.Refresh:
      return {
        scopes,
        account: authenticationRecord,
        forceRefresh: false,
        cacheLookupPolicy: CacheLookupPolicy.Default,
      };
    case SilentRequestType.ForceRefresh:
      return {
        scopes,
        account: authenticationRecord,
        forceRefresh: true,
        cacheLookupPolicy: CacheLookupPolicy.RefreshTokenAndNetwork,
      };
  }
};

// ====================================================
// warn less and less progressivly
// ====================================================
const warningFrequency = (nr: number): boolean => {
  if (nr <= 5) return true;
  else if (nr <= 200) return nr % 10 === 0;
  return nr % 50 === 0;
};

// ====================================================
// token refresh
// ====================================================
enum RefreshTokenResult {
  Sucess,
  InteractionRequired,
  Error,
}

const refreshToken = async (caller: string): Promise<RefreshTokenResult> => {
  if (!isLoggedIn()) {
    logger.error("can not refresh token when logged out");
    return RefreshTokenResult.Error;
  }

  const authenticationRecord = _cachedAuthenticationRecord.get();
  if (!authenticationRecord) {
    logger.error(`giving up refresh token without authentication record`);
    return RefreshTokenResult.Error;
  }

  let refreshType: SilentRequestType;
  const tokenTimeLeftSec = tokenValidTimeLeftSec(authenticationRecord);

  if (tokenTimeLeftSec < 0 && _state.refreshFails === 0) {
    // (potential) fix for hibernate/good-morning issue with long missed refresh, try silent first
    refreshType = SilentRequestType.Silent;
  } else if (isTokenExpirationSoon(authenticationRecord)) {
    refreshType = SilentRequestType.ForceRefresh;
  } else {
    refreshType = SilentRequestType.Refresh;
  }

  const dbgLine = (prefix: string, aR: AccountInfo | null) => {
    return aR
      ? `${prefix} refresh token (${refreshType}) ` +
          `(${tokenRefreshTimeSec(aR)}/${tokenValidTimeLeftSec(aR)} sec left)` +
          ` for caller '${caller}' with ${printer.stringify(aR)}`
      : "";
  };
  logger.debug(dbgLine("trying to", authenticationRecord));

  try {
    _state.isRefreshing = true;

    const silentRequest = getSilentRequest(refreshType, authenticationRecord);
    const authResult = await _msalInstance.acquireTokenSilent(silentRequest);

    return loginSuccess(
      authResult.accessToken,
      authResult.account,
      dbgLine("success", authResult.account),
      null,
      RefreshTokenResult.Sucess
    );
  } catch (error: any) {
    if (error instanceof InteractionRequiredAuthError) {
      logger.warn("Token refresh requires interaction");
      return RefreshTokenResult.InteractionRequired;
    }

    ++_state.refreshFails;
    const errorString = `Failed to refresh token (${refreshType}) tokenTimeLeft=${tokenTimeLeftSec} fails=${_state.refreshFails} failed with ${error}`;

    // token still valid?
    if (tokenTimeLeftSec > 0) {
      // keep trying, just do not warn as frequently
      const logMethod = warningFrequency(_state.refreshFails) ? "warn" : "debug";
      logger[logMethod](errorString);
    } else {
      // token timed out
      if (_state.refreshFails < MAX_REFRESH_FAILS_BEFORE_LOGOUT) {
        // keep trying, always warn
        logger.warn(errorString);
      } else {
        // give up, try interactive login
        logger.warn(`Token refresh requires interaction: ${error}`);
        return RefreshTokenResult.InteractionRequired;
      }
    }
    return RefreshTokenResult.Sucess;
  } finally {
    _state.isRefreshing = false;
  }
};

// ====================================================
// silent login
// ====================================================
const loginSilent = async (): Promise<boolean> => {
  const authenticationRecord = _cachedAuthenticationRecord.get();

  if (authenticationRecord) {
    logger.debug(`Silent login with cachedAuthenticationRecord=${printer.stringify(authenticationRecord)}`);
  } else {
    logger.info(`No cached authentication record found`);
    return false;
  }

  try {
    const silentRequest = getSilentRequest(SilentRequestType.Silent, authenticationRecord);
    const authResult = await _msalInstance.acquireTokenSilent(silentRequest);
    return loginSuccess(
      authResult.accessToken,
      authResult.account,
      `silent login success with ${printer.stringify(authResult.account)}`,
      `silent login success as ${authResult.account?.username}`,
      true
    );
  } catch (error: any) {
    if (error instanceof InteractionRequiredAuthError) {
      logger.warn("silent login would require interaction");
    } else if (error instanceof BrowserAuthError) {
      logger.warn(`silent login ran into browser-auth-error: ${error}`);
    } else {
      logger.warn(`silent login failed with error: ${error}`);
    }
    return false;
  }
};

// ====================================================
// login-popup
// ====================================================
const loginPopup = async (prompt?: LoginPopupPrompt): Promise<boolean> => {
  try {
    if (_state.isPopupActive) {
      throw new Error("Interaction window already active");
    }
    _state.isPopupActive = true;
    let authResult = await _msalInstance.acquireTokenPopup({ scopes, ...(prompt && { prompt }) });
    return loginSuccess(
      authResult.accessToken,
      authResult.account,
      `authenticate success with ${JSON.stringify(authResult.account)}`,
      `login success as ${authResult.account?.username}`,
      true
    );
  } catch (error: any) {
    loginFail(`failed popup authenticate with ${error}`);
    return Promise.resolve(false);
  } finally {
    _state.isPopupActive = false;
  }
};

// ====================================================
// full login: try silent, go into popup if it fails, read out /me information with access token
// ====================================================
const login = async (): Promise<void> => {
  resetState();
  const success = (await loginSilent()) || (await loginPopup("login"));
  const userInfo = success ? await getUserProfile() : null;
  return userInfo ? Promise.resolve() : Promise.reject();
};

// ====================================================
// isAuthorized
// ====================================================
const isAuthorized = async (caller: string): Promise<boolean> => {
  logger.debug(`isAuth => caller=${caller}`);
  let ret = false;
  if (!_runOnce) {
    _runOnce = true;
    const cachedAuthenticationRecord = _cachedAuthenticationRecord.get();
    if (cachedAuthenticationRecord) {
      (await loginSilent()) || (await loginPopup("select_account"));
    }
  }
  if (isLoggedIn()) {
    if (_state.isRefreshing) {
      logger.debug("A refresh is already in progress, assuming isAuthorized");
      ret = true;
    } else if (_state.isPopupActive) {
      logger.warn("Can not refresh token while an interaction login is in progress");
      ret = true;
    } else {
      switch (await refreshToken(caller)) {
        case RefreshTokenResult.Sucess:
          ret = true;
          break;
        case RefreshTokenResult.InteractionRequired:
          loginPopup(); // run&forget, don't wait
          break;
        case RefreshTokenResult.Error:
          ret = false;
      }
    }
  }
  logger.debug(`isAuth <= caller=${caller} : ${ret}`);
  return ret;
};

// ====================================================
// events
// ====================================================
const events = async (): Promise<MicrosoftGraphEvent[]> => {
  const caller = "events";
  try {
    if (await isAuthorized(caller)) {
      return await getUserCalendar();
    }
  } catch (error) {
    logger.error(`Failed '${caller}': ${error}`);
  }
  return [];
};

// ====================================================
// profile
// ====================================================
const profile = async () => {
  const caller = "profile";
  try {
    if (await isAuthorized(caller)) {
      return await getUserProfile();
    }
  } catch (error) {
    logger.error(`Failed '${caller}': ${error}`);
  }
  return null;
};

// ====================================================
// attachments
// ====================================================
const attachments = async (eventId: string) => {
  const caller = "attachments";
  try {
    if (await isAuthorized(caller)) {
      return await getAttachments(eventId);
    }
  } catch (error) {
    logger.error(`Failed '${caller}': ${error}`);
  }
  return [];
};

// ====================================================
// getUserProfile
// ====================================================
const getUserProfile = async () => {
  let response = await axios.get("https://graph.microsoft.com/v1.0/me", { headers: getHeaders() });
  return response.data;
};

// ====================================================
// getUserCalendar
// ====================================================
const getUserCalendar = async function (): Promise<MicrosoftGraphEvent[]> {
  const now = moment();
  const beginDateTime = encodeURIComponent(now.startOf("day").format());
  const endDateTime = encodeURIComponent(moment().add(4, "days").endOf("day").format());

  let response = await axios.get(
    `https://graph.microsoft.com/v1.0/me/calendarview?$top=999&startdatetime=${beginDateTime}&enddatetime=${endDateTime}`,
    { headers: getHeaders() }
  );
  const { value = [] } = response.data;
  const calendarEvents: MicrosoftGraphEvent[] = value as MicrosoftGraphEvent[];

  logger.debug(`events: from '${beginDateTime}' to '${endDateTime}' : #${calendarEvents.length}`);

  if (isDebugLogger(msgLogger) && calendarEvents && calendarEvents.length > 0) {
    value.forEach((m: any) => msgLogger.debug(JsonStringify(m)));
  }

  return calendarEvents;
};

// ====================================================
// getAttachments
// ====================================================
const getAttachments = async function (eventId: string) {
  let response = await axios.get(`https://graph.microsoft.com/v1.0/me/events/${eventId}/attachments`, {
    headers: getHeaders(),
  });
  return response.data;
};

// ====================================================
// getCreateUrl
// ====================================================
const getCreateUrl = (subject: string, body: string, location: string): string => {
  const start: moment.Moment = moment().add(1, "hour").startOf("hour");
  const end: moment.Moment = moment(start).add(30, "minutes");

  let url = new URL("https://outlook.office.com/calendar/deeplink/compose");
  url.searchParams.set("allday", "false");
  url.searchParams.set("path", "/calendar/action/compose");
  url.searchParams.set("rru", "addevent");
  url.searchParams.set("startdt", start.format("YYYY-MM-DDTHH:mm:00Z"));
  url.searchParams.set("enddt", end.format("YYYY-MM-DDTHH:mm:00Z"));
  url.searchParams.set("subject", subject);
  url.searchParams.set("location", location);
  url.searchParams.set("body", body);

  logger.debug(`createUrl=${url.href}`);

  return url.href;
};

// custom toString()
class printer {
  public static stringify(accountInfo: AccountInfo | null): string {
    return `[username=${accountInfo?.username} iat=${accountInfo?.idTokenClaims?.iat} exp=${accountInfo?.idTokenClaims?.exp}]`;
  }
}

interface OutlookProviderType {
  login: () => Promise<void>;
  logout: () => void;
  getCreateUrl: (subject: string, body: string, location: string) => string;
  isAuthorized: (caller: string) => Promise<boolean>;
  events: () => Promise<MicrosoftGraphEvent[]>;
  attachments: (eventId: string) => Promise<any>;
  profile: () => Promise<any>;
}

const OutlookProvider: OutlookProviderType = {
  login,
  logout,
  getCreateUrl,
  isAuthorized,
  events,
  profile,
  attachments,
};

export default OutlookProvider;
