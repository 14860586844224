import axios from "axios";
import { EventEmitter } from "events";
import { getAccessToken, refreshAccessToken } from "services/Providers/ProvisioningTokenProvider";
import { getLogger } from "logger/appLogger";
import { isEmpty } from "utils/helpers";

const log = getLogger("presence");

class PresenceApi extends EventEmitter {
  constructor(apiConfig) {
    super();
    this.module = "PresenceApi";
    log.debug(`${this.module}: PresenceApi starting...`);
    this.presenceUrl = apiConfig.presenceUrl;
    this.apiUrl = apiConfig.apiUrl;
    this.wsUrl = apiConfig.wsUrl;
    this.presentityId = apiConfig.presentityId;
    this.userList = apiConfig.userList;
    this.tenantName = apiConfig.tenantName;
    this.subscriptionId = "";
    this.statusListener = null;
    this.axiosInstance = null;
    this.connection = null;
    this.userStop = false;
    this.wsKeepAlive = null;
    this.wsKeepAliveInterval = 30000;
    this.currentStatus = "offline";
    this.currentAvatarUrl = apiConfig.avatarUrl ? apiConfig.avatarUrl : "";
    this.createAxiosInstance();
  }

  createAxiosInstance() {
    try {
      this.axiosInstance = axios.create({
        baseURL: this.apiUrl,
      });
    } catch (e) {
      this.log_error_response(e);
    }
  }

  getHeadersRequest(args) {
    return {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "X-Enghouse-UC-Tenant-Name": this.tenantName,
        ...args,
      },
    };
  }

  async init() {
    try {
      log.debug(`${this.module}: Initialize Presence`);
      const res = await this.check_presentity();
      if (res) {
        // Presentity exists
        const { data } = res;
        const { avatarUrl, status } = data.resources.im;
        // Case that the user don't have any contact or room, and the matrix don't store the avatar
        // but the presence api have a avatar.
        if (this.currentAvatarUrl && this.currentAvatarUrl.length === 0 && avatarUrl && avatarUrl.length > 0)
          this.currentAvatarUrl = avatarUrl ? avatarUrl : "";
        this.currentStatus = status;
        await this.init_subscription();
      } else {
        await this.create_presentity();
        await this.init_subscription();
      }
      return { status: this.currentStatus, avatarUrl: this.currentAvatarUrl };
    } catch (e) {
      this.log_error_response(e);
      throw e;
    }
  }

  async check_presentity() {
    log.debug(`${this.module}: Checking presence entity for ${this.presentityId}`);
    try {
      return await this.axiosInstance.get(`/presentities/${this.presentityId}`, this.getHeadersRequest());
    } catch (e) {
      const { response } = e;
      if (response) {
        const { data } = response;
        if (data && data.message === "Unknown presentity") {
          return false;
        } else {
          this.log_error_response(e);
          throw e;
        }
      } else {
        this.log_error_response(e);
        throw e;
      }
    }
  }

  async get_presentity(presentityId) {
    log.debug(`${this.module}: Getting presence entity`);
    try {
      return await this.axiosInstance.get(`/presentities/${presentityId}`, this.getHeadersRequest());
    } catch (e) {
      const { response } = e;
      if (response) {
        const { data } = response;
        if (data && data.message === "Unknown presentity") {
          return null;
        } else {
          this.log_error_response(e);
          throw e;
        }
      } else {
        this.log_error_response(e);
        throw e;
      }
    }
  }

  async create_presentity() {
    try {
      log.debug(`${this.module}: Creating presence entity`);
      const jsonPayloadObject = {
        resources: {
          im: {
            status: this.currentStatus,
            avatarUrl: this.currentAvatarUrl,
          },
        },
      };
      return await this.axiosInstance.put(
        `/presentities/${this.presentityId}`,
        jsonPayloadObject,
        this.getHeadersRequest()
      );
    } catch (e) {
      this.log_error_response(e);
      throw e;
    }
  }

  async update_presentity(myStatus, myAvatarUrl) {
    try {
      log.debug(`${this.module}: Update presence entity`);
      const status = myStatus === null ? this.currentStatus : myStatus;
      const avatarUrl = myAvatarUrl === null ? this.currentAvatarUrl : myAvatarUrl;
      const jsonPayloadObject = [
        {
          op: "add",
          path: "/resources",
          value: {
            im: {
              status: status,
              avatarUrl: avatarUrl,
            },
          },
        },
      ];
      const reqConfig = this.getHeadersRequest({
        Accept: "application/json",
        "Content-Type": "application/json-patch+json",
      });
      this.currentStatus = status;
      this.currentAvatarUrl = avatarUrl;
      return await this.axiosInstance.patch(`/presentities/${this.presentityId}`, jsonPayloadObject, reqConfig);
    } catch (e) {
      this.log_error_response(e);
      throw e;
    }
  }

  async delete_presentity() {
    try {
      log.debug(`${this.module}: Delete presence entity`);
      if (this.presentityId !== null && this.presentityId !== "") {
        return await this.axiosInstance.delete(`/presentities/${this.presentityId}`, this.getHeadersRequest());
      }
    } catch (e) {
      this.log_error_response(e);
      throw e;
    }
  }

  async init_subscription() {
    try {
      log.debug(`${this.module}: Intializing Subscription`);
      // Create a subscription
      const payload = {
        presentities: this.userList,
        presentityId: this.presentityId,
      };
      const reConfig = this.getHeadersRequest({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      const res = await this.axiosInstance.post("/subscriptions", payload, reConfig);
      // Subscription created
      const { data, status } = res;
      if (status === 201 && data) {
        log.debug(`${this.module}: Subscription created`);
        const subscriptionInfo = data;
        this.subscriptionId = subscriptionInfo.subscriptionId;
        this.watch_subscription();
        return;
      } else {
        log.debug(`${this.module}: Unable to create subscription for ${this.presentityId} [${status}]`);
        throw new Error(`Unable to create subscription for ${this.presentityId} [${status}]`);
      }
    } catch (e) {
      this.log_error_response(e);
      throw e;
    }
  }

  watch_subscription() {
    try {
      log.debug(`${this.module}: Watch presence subscription`);

      this.connection = null;
      const url = `${this.wsUrl}/subscriptions/${this.subscriptionId}/notifications`;
      this.connection = new WebSocket(url);

      this.connection.onopen = () => {
        log.debug(`${this.module}: Listening for presence updates...`);
      };

      this.connection.onclose = (e) => {
        log.debug(`${this.module}: Presence updates closed: ${e.reason}`);
        this.cleanupConnection();
        if (this.clientStop) {
          log.debug(`${this.module}: Presence updates properly closed`);
          return;
        }

        switch (e.code) {
          case 4003: // Normal closure
            log.debug(`${this.module}: Presence updates Normal closure`);
            this.cleanupConnection(true);
            break;
          default:
            // Abnormal closure
            log.debug(`${this.module}: Presence updates Abnormal closure`);
            this.tryReconnect();
            break;
        }
      };

      this.connection.onerror = (error) => {
        log.error(`Websocket error: ${error}`);
      };

      this.connection.onmessage = (message) => {
        const presenceInfo = JSON.parse(message.data);
        log.debug(
          `${this.module}: Presence update for ${presenceInfo.presentityId} received: ${presenceInfo.resources.im.status}`
        );
        // Signal listener
        if (presenceInfo.presentityId === this.presentityId) {
          if (!this.statusListener) return;
          this.statusListener(presenceInfo.resources.im.status);
        } else {
          presenceInfo.resources.im.userId = presenceInfo.presentityId;
          this.emit("user-update", presenceInfo.resources.im);
        }
      };

      this.createWSKeepAlive();
    } catch (e) {
      this.log_error_response(e);
      throw e;
    }
  }

  createWSKeepAlive() {
    try {
      if (this.wsKeepAlive) {
        clearInterval(this.wsKeepAlive);
      }
      this.tryReconnect(); // Try first
      this.wsKeepAlive = setInterval(async () => {
        this.tryReconnect();
      }, this.wsKeepAliveInterval);
    } catch (e) {
      this.log_error_response(e);
    }
  }

  appendIfNotLast(str, char) {
    return !isEmpty(str) && str.charAt(str.length - 1) !== char ? str + char : str;
  }

  async isOnline() {
    let ret = false;
    try {
      await axios.get(this.appendIfNotLast(this.presenceUrl, "/"));
      ret = true;
    } catch (e) {
      log.warn(`isOnline check failed with: ${e}`);
    }
    log.debug(`isOnline: ${ret}`);
    return ret;
  }

  async tryReconnect() {
    try {
      if (!this.connection) return;
      const online = await this.isOnline();
      log.debug(`${this.module}: Presence current state ${this.connection.readyState}`);
      log.debug(`${this.module}: Online state: ${online}`);
      if (this.connection.readyState === WebSocket.CLOSED && (await this.isOnline())) {
        this.reconnect();
      }
    } catch (e) {
      this.log_error_response(e);
    }
  }

  async reconnect() {
    try {
      if (this.reconnecting) return;
      log.debug(`${this.module}: Reconnecting`);
      this.reconnecting = true;
      await this.init_subscription();
      log.debug(`${this.module}: Reconnecting Success`);
    } catch (e) {
      this.log_error_response(e);
    }
    this.reconnecting = false;
  }

  async update_subscription() {
    try {
      log.debug(`${this.module}: Update presence subscription`);
      const jsonPayloadObject = [
        {
          op: "replace",
          path: "/presentities",
          value: this.userList,
        },
      ];
      const reqConfig = this.getHeadersRequest({
        Accept: "application/json",
        "Content-Type": "application/json-patch+json",
      });
      return await this.axiosInstance.patch(`/subscriptions/${this.subscriptionId}`, jsonPayloadObject, reqConfig);
    } catch (e) {
      this.log_error_response(e);
      throw e;
    }
  }

  async delete_subscription() {
    try {
      log.debug(`${this.module}: Delete presence subscription`);
      if (this.subscriptionId !== null && this.subscriptionId !== "") {
        return await this.axiosInstance.delete(`/subscriptions/${this.subscriptionId}`, this.getHeadersRequest());
      }
    } catch (e) {
      this.log_error_response(e);
      throw e;
    }
  }

  set_status_listener(listener) {
    this.statusListener = listener;
  }

  log_error_response(e) {
    const { response } = e;
    if (response) {
      log.error(`${this.module}: Error statusCode: ${response.status}`);
      const { data } = response;
      if (data && data.message) {
        log.error(`${this.module}: Error message: ${data.message} `);
      }
      if (response.status === 401) {
        log.info(`${this.module}: Initiate refresh access token`);
        refreshAccessToken();
      }
    } else {
      log.error(`${this.module}: Error message: ${e.message}`);
    }
  }

  async cleanupConnection(closeConnection = false) {
    try {
      log.debug(`${this.module}: Starting cleanup...`);
      if (this.wsKeepAlive) {
        clearInterval(this.wsKeepAlive);
      }
      this.userStop = true;
      this.connection.onclose = null;
      this.connection.onerror = null;
      this.connection.onopen = null;
      this.connection.onmessage = null;
      if (closeConnection) {
        this.connection.close();
      }
    } catch (e) {
      this.log_error_response(e);
      throw e;
    }
  }

  async cleanup() {
    try {
      log.debug(`${this.module}: Starting cleanup...`);
      await this.delete_subscription();
      this.cleanupConnection(true);
    } catch (e) {
      this.log_error_response(e);
      throw e;
    }
  }
}

export default PresenceApi;
