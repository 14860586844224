import { useState, useEffect, useMemo, useRef } from "react";
import { getLogger } from "logger/appLogger";
import {
  IRoomTimelineData,
  MatrixClient,
  MatrixEvent,
  Room,
  RoomEvent,
  RoomMember,
  RoomMemberEvent,
  RoomState,
  RoomStateEvent,
} from "matrix-js-sdk";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { matrixActions } from "store/actions/matrix";
import { sipActions } from "store/actions/sip";
import { provisioningActionsCreator } from "store/actions/provisioning";
import { PBXServerApiClient } from "services/PBXServerApi/PBXServerApiClient";
import {
  useMatrixRooms,
  useMatrixAvatar,
  useRouter,
  useMatrixEvent,
  useNotification,
  usePresence,
  useMatrixMembers,
  useHandleIncomingCalls,
} from "hooks";
import isElectron from "is-electron";
import {
  AdHocInviteDate,
  AdHocInviteType,
  InternalMessageContentType,
  MappedRoomList,
  MatrixContent,
  MatrixRoom,
  Membership,
  LocalEventTypeEnum,
  LocalEventType,
  RoomListFilters,
} from "types/Matrix";
import { JsonStringify, getRoomFromRoomList } from "utils/utils";
import { useAppSelector } from "store/hooks";
import { tryParse } from "utils/helpers";
import { getSipCallActive } from "store/selectors";
import { TemplateKey } from "types/UC";
import { showToastNotification } from "components/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";

export const useHandleMatrixEvents = (client: MatrixClient, shouldRejectIncomingMatrixCall: () => boolean) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { push } = useRouter();
  const logger = useMemo(() => getLogger("matrix.handleevents"), []);

  const user = useAppSelector((state) => state.matrix.currentUser);
  const currentRoomId = useAppSelector((state) => state.matrix.roomId);
  const sipCallActive = useAppSelector((state) => getSipCallActive(state, true));
  const roomList = useAppSelector((state) => state.matrix.roomList);
  const syncState = useAppSelector((state) => state.matrix.matrixSyncState);
  const hasActiveMatrixCall = useAppSelector((state) => state.matrix.hasActiveMatrixCall);
  const matrixCallRoomId = useAppSelector((state) => state.matrix.matrixCallRoomId);

  const [groupInviteData, setGroupInviteData] = useState<AdHocInviteDate | null>(null);
  const [fetchedPreparedRoomList, setFetchedPreparedRoomList] = useState(false);
  const [currentDate] = useState(Math.floor(Date.now()));

  const sipCallActiveRef = useRef(sipCallActive);
  const roomListRef = useRef(roomList);
  const currentRoomIdRef = useRef(currentRoomId);
  const intvlRef = useRef();

  const {
    mapRoomList,
    handleRoomEvent,
    handleRoomMembershipEvent,
    removeRoom,
    getRoom,
    handleRoomTagEvent,
    isGroup,
    deleteRoomLowPrio,
    setRoomEventsTimestamp,
    updateLocalRoomName,
    isDirect,
    getRoomList,
    markRoomAsLowPriority,
    changeRoomHidden,
    generateRoomName,
    convertMatrixRoomToMappedRoom,
  } = useMatrixRooms(currentRoomId ?? undefined, "Room", "deleteRoom", "accountData");
  const { getRoomAvatar, getHttpUrl } = useMatrixAvatar();
  const syncEvent = useMatrixEvent(client, "sync");
  const { handleBadgeCount, showTrayNotification } = useNotification();
  const { startUserPresence, removeUserFromInitialPresenceList, addUserToInitialPresenceList, updatePresenceList } =
    usePresence();
  const { getUsersFromDirectRooms } = useMatrixMembers();
  const { showGroupInviteNotification, setShowGroupInviteNotification, onAcceptGroupCall, onRejectGroupCall } =
    useHandleIncomingCalls();

  useEffect(() => {
    sipCallActiveRef.current = sipCallActive;
  }, [sipCallActive]);

  useEffect(() => {
    roomListRef.current = roomList;
  }, [roomList]);

  useEffect(() => {
    currentRoomIdRef.current = currentRoomId;
  }, [currentRoomId]);

  useEffect(() => {
    if (!syncEvent || !(syncEvent.length > 0)) return;

    const [state] = syncEvent;
    if (syncState !== state) {
      dispatch(matrixActions.setMatrixSyncState(state));
    }
    switch (state) {
      case "ERROR":
        logger.info(`Current state: ${state}`);
        if (hasActiveMatrixCall) {
          dispatch(matrixActions.hangupMatrixCall());
        }
        break;
      case "SYNCING":
        if (client) {
          handleBadgeCount();
        }
        break;
      case "RECONNECTING":
      case "CATCHUP":
        dispatch(matrixActions.setRoomListFilter(RoomListFilters.all));
        break;
      case "PREPARED":
        logger.info(`Current state: ${state}`);
        mapRoomList().then((list) => {
          setFetchedPreparedRoomList(true);
          if (list) startUserPresence(list as MappedRoomList);
        });
        dispatch(provisioningActionsCreator.getProvisioningConfig());
        PBXServerApiClient.Instance.getPBXUserConfig();

        handleBadgeCount();
        if (client) {
          client.on(RoomStateEvent.NewMember, (...args) => {
            logger.debug("In new room member event");
            handleNewMemberEvent(args);
          });
          client.on(RoomEvent.Timeline, (...args) => {
            logger.debug("In room timeline event");
            handleNewTimelineEvent(args);
          });
          client.on(RoomEvent.Tags, (...args) => {
            logger.debug("In room tags event");
            handleChangeRoomTagEvent([...args, currentRoomIdRef.current]);
          });
          client.on(RoomMemberEvent.Membership, (...args) => {
            logger.debug("In room membership event");
            handleRoomMembershipEvent(args);
          });
        }
        break;
      default:
        break;
    }

    return () => {
      if (client) {
        client.removeListener(RoomStateEvent.NewMember, (...args) => {
          handleNewMemberEvent(args);
        });
        client.removeListener(RoomEvent.Timeline, (...args) => {
          handleNewTimelineEvent(args);
        });
        client.removeListener(RoomEvent.Tags, (...args) => {
          logger.debug("In room tags event");
          handleChangeRoomTagEvent([...args, currentRoomIdRef.current]);
        });
        client.removeListener(RoomMemberEvent.Membership, (...args) => {
          logger.debug("In room membership event");
          handleRoomMembershipEvent(args);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncEvent]);

  const handleUpdateRoomAvatar = (url: string | null, room: Room) => {
    const opts = {
      roomAvatar: getHttpUrl(url),
      roomId: room.roomId,
    };
    dispatch(matrixActions.updateRoomAvatar(opts));
  };

  const handleNewTimelineEvent = async (
    args: [
      event: MatrixEvent,
      room: Room | undefined,
      toStartOfTimeline: boolean | undefined,
      removed: boolean,
      data: IRoomTimelineData,
    ]
  ) => {
    try {
      const [event, eventRoom, toStartOfTimeline, , data] = args;
      if (toStartOfTimeline) return;
      const content = event.getContent();
      const { membership } = content;
      const eventType = event.getType();
      const direction = user && user.userId === event.getSender() ? "(snd)" : "(rcv)";

      logger.debug(
        "handleNewTimelineEvent ",
        direction,
        " membership:",
        membership,
        " eventType:",
        eventType,
        " event:",
        event,
        " liveEvent:",
        data.liveEvent,
        " eventRoom:",
        eventRoom
      );

      if (event.getType() === LocalEventTypeEnum.RoomName) {
        const { name } = content;
        if (eventRoom) updateLocalRoomName(eventRoom as MatrixRoom, name);
      }

      if (event.getType() === LocalEventTypeEnum.RoomMessage) setRoomEventsTimestamp(event);

      if (eventType === LocalEventTypeEnum.InternalMessage) {
        if (eventRoom) handleInternalMessage(event, eventRoom as MatrixRoom);
      }

      if (eventType === LocalEventTypeEnum.RoomTopic) {
        if (eventRoom) handleNewRoomTopic(content, eventRoom as MatrixRoom);
      }

      // event in which a room's avatar changed
      if (eventType === LocalEventTypeEnum.RoomAvatar) {
        if (eventRoom && event.event.content) handleUpdateRoomAvatar(event.event.content.url, eventRoom);
      }

      if (eventType === LocalEventTypeEnum.RoomMember) {
        if (membership === Membership.leave && user && event.target && user.userId === event.target.userId) {
          if (eventRoom) removeRoom(eventRoom.roomId);
        }

        // special case, for when a direct user's avatar has changed
        if (
          membership === Membership.join &&
          user &&
          user.userId !== event.getSender() &&
          eventRoom &&
          isDirect(eventRoom.roomId) &&
          event.event.content
        ) {
          handleUpdateRoomAvatar(event.event.content.avatar_url ?? "", eventRoom);
        }
      }

      if (
        membership === Membership.leave &&
        event.getType() === LocalEventTypeEnum.RoomMember &&
        user &&
        event.target &&
        user.userId === event.target.userId &&
        eventRoom
      ) {
        removeRoom(eventRoom.roomId);
        if (eventRoom.roomId === currentRoomIdRef.current) {
          push("/home");
        }
      }

      if (!data.liveEvent) return;

      // here, we react on incoming hangup/reject from peer by hanging up locally
      if (
        hasActiveMatrixCall &&
        matrixCallRoomId &&
        user &&
        user.userId !== event.getSender() &&
        matrixCallRoomId === event.getRoomId()
      ) {
        switch (eventType) {
          case LocalEventTypeEnum.CallHangup:
            logger.debug("handleNewTimelineEvent - Incoming call hangup");
            dispatch(matrixActions.hangupMatrixCall());
            break;
          case LocalEventTypeEnum.CallReject:
            logger.debug("handleNewTimelineEvent - Incoming call reject");
            dispatch(matrixActions.hangupMatrixCall());
            showToastNotification(TemplateKey.banner, {
              title: event.sender ? event.sender.name : "",
              message: t("REJECTED_CALL"),
              icon: <LogoutIcon sx={{ color: theme.palette.icon.notification.logout }} />,
            });
            break;
          default:
            break;
        }
      }

      if (event.getType() === LocalEventTypeEnum.RoomMessage || event.getType() === LocalEventTypeEnum.CallInvite) {
        const skippingNotification = (nr: number) => logger.debug(`Skipping notification/matrix (rule #${nr})`);
        const hasFocus = document.hasFocus();

        // for incoming mesasages: do not notify if current room active and window app focus is set
        if (
          event.getType() === LocalEventTypeEnum.RoomMessage &&
          currentRoomIdRef.current === event.getRoomId() &&
          hasFocus
        ) {
          skippingNotification(1);
          return;
        }

        // for incoming calls: do not notify if window app focus is set
        if (event.getType() === LocalEventTypeEnum.CallInvite) {
          if (hasFocus) {
            skippingNotification(2);
            return;
          }
          if (isElectron()) {
            skippingNotification(3);
            return;
          }
        }

        if (user && user.userId === event.getSender()) {
          skippingNotification(4);
          return;
        }
        if (toStartOfTimeline) {
          skippingNotification(5);
          return;
        }
        if (!data.liveEvent) {
          skippingNotification(6);
          return;
        }
        if (event.getTs() < currentDate) {
          skippingNotification(7);
          return;
        }
        if (shouldRejectIncomingMatrixCall()) {
          skippingNotification(8);
          return;
        }

        logger.debug(`handleNewTimelineEvent currentRoom: ${currentRoomIdRef.current}`);

        const roomId = event.getRoomId();

        if (
          event.getType() === LocalEventTypeEnum.RoomMessage &&
          roomId &&
          isGroup(roomId) &&
          eventRoom &&
          eventRoom.tags["m.lowpriority"]
        ) {
          skippingNotification(9);
          return;
        }
        const matrix = { roomId, userId: event.getSender(), content, eventType: event.getType() as LocalEventType };
        showTrayNotification({ matrix });
      }
    } catch (e) {
      logger.error(`Error in ApplicationScreen.handleNewTimelineEvent - ${e.message}`);
      intvlRef.current = undefined;
    }
  };

  const handleNewMemberEvent = async (args: [event: MatrixEvent, state: RoomState, member: RoomMember]) => {
    try {
      const [event, , member] = args;
      logger.debug("handleNewMemberEvent member:", member);
      if (!member) return;
      const eventContent = event.getContent();
      const { is_direct } = eventContent;

      if (event.event.room_id) {
        const room = getRoom(event.event.room_id);
        if (room) {
          room.recalculate();
          handleRoomEvent([room]);
        }
      }

      // new direct room - must update the initial list of users on presence
      if (is_direct) addUserToInitialPresenceList(member.userId);
      updatePresenceList([member.userId]);
    } catch (e) {
      logger.error(`Error in handleNewMemberEvent - args:${args} - ${e.message}`);
    }
  };

  const handleChangeRoomTagEvent = (args: [event: MatrixEvent, room: Room, currentRoomId: string | null]) => {
    try {
      const [event, room, ,] = args;
      logger.debug("handleChangeRoomTagEvent event:", event, " - eventRoom:", room);
      handleRoomTagEvent(args);
      const { tags } = event.getContent();
      if (room && isDirect(room.roomId)) {
        const contactUser = room.guessDMUserId();
        if (tags && tags["m.lowpriority"]) {
          removeUserFromInitialPresenceList(contactUser);
          updatePresenceList([]);
        } else if (user) {
          const userList = getUsersFromDirectRooms(getRoomList(), user.userId);
          addUserToInitialPresenceList(contactUser);
          updatePresenceList(userList);
        }
      }
    } catch (e) {
      logger.error(`Error in ApplicationScreen.handleChangeRoomTagEvent - args:${args} - ${e.message}`);
    }
  };

  const handleGrouChatInvite = (event: MatrixEvent, room: MatrixRoom, inviteType: string) => {
    const localRoom = convertMatrixRoomToMappedRoom(room);
    const roomName = localRoom ? generateRoomName(localRoom) : "";
    const message = `${event.sender ? event.sender.name : ""} ${
      inviteType === AdHocInviteType.conf ? t("CALLING") : t("INVITED_GROUP_CHAT")
    }`;
    if (isElectron()) {
      const buttons = inviteType === AdHocInviteType.conf ? ["Reject", "Reply", "Join"] : ["Close", "Reply"];
      const roomAvatar = getRoomAvatar({ roomId: room.roomId });
      let data = {
        callId: room.roomId,
        type: inviteType,
        avatarUrl: roomAvatar ?? "",
        buttons,
        groupChat: true,
        remoteIdentity: roomName,
        message,
      };
      window.ipcRenderer.send("onIncomingNotification", data);
    } else {
      const data: AdHocInviteDate = {
        title: roomName,
        message,
        action: (choice: string) => onAcceptGroupCall(room.roomId, choice),
        close: () => onRejectGroupCall(),
        type: inviteType,
      };
      setGroupInviteData(data);
    }
  };

  const handleInternalMessage = (event: MatrixEvent, room: MatrixRoom) => {
    try {
      const { type, body } = event.event.content as MatrixContent;
      logger.debug(`handleInternalMessage: type=${type} body=${JsonStringify(body)}`);

      if (body) {
        switch (type) {
          case InternalMessageContentType.AdHocInvite:
            const timeSinceInvite = event.event.origin_server_ts
              ? (Date.now() - event.event.origin_server_ts) / 1000
              : 0;
            logger.debug(
              `AdhocInvite Message: inviteType=${body.inviteType} inviteIds=${body.inviteIds} timeSinceInvite=${timeSinceInvite}`
            );
            if (user && user.userId !== event.getSender() && body.inviteIds.includes(user.userId)) {
              // remove lowpriority from the room
              deleteRoomLowPrio(room.roomId);

              // checks if the invite is valid - younger than a minute
              if (timeSinceInvite < 60) {
                handleGrouChatInvite(event, room, body.inviteType);
                setShowGroupInviteNotification(true);
                if (body.inviteType === AdHocInviteType.conf) dispatch(matrixActions.playMatrixCallRingtone());
              }
            }
            break;
          case InternalMessageContentType.Transfer:
            const callActive = sipCallActiveRef.current;
            logger.debug(`TransferMessage: sipCallActive=${callActive?.id} body=${body ? JSON.stringify(body) : ""}`);
            if (callActive && user && user.userId !== event.getSender()) {
              // if the isNewRoom value from the message is true,
              // means that this room was created to send an internal message,
              // so we need to set the lowPriority to true
              if (body.isNewRoom) {
                markRoomAsLowPriority(room.roomId);
              }
              dispatch(sipActions.updateSipCallInfo(callActive.id, body.userExtension, ""));
            }
            break;
          case InternalMessageContentType.RoomChangeHidden:
            logger.debug(`RoomChangeHiddenMessage: body=${body}`);
            if (user && body.ownerId === user.userId) {
              changeRoomHidden(body.roomId, body.isHidden);
            }
            break;

          case InternalMessageContentType.SipConferenceInitialize:
            if (user && user.userId !== event.getSender()) {
              if (body.isNewRoom) {
                markRoomAsLowPriority(room.roomId);
              }
              dispatch(
                sipActions.updateSipConferenceInfo(
                  null,
                  body.conferenceId,
                  body.ownerExtension,
                  body.userExtensions,
                  null,
                  null,
                  t("CONFERENCE"),
                  true
                )
              );
            }
            break;
          case InternalMessageContentType.SipConferenceJoin:
            if (user && user.userId !== event.getSender()) {
              if (body.isNewRoom) {
                markRoomAsLowPriority(room.roomId);
              }
              dispatch(
                sipActions.updateSipConferenceInfo(
                  null,
                  body.conferenceId,
                  null,
                  [],
                  body.userExtension,
                  null,
                  null,
                  false
                )
              );
            }
            break;
          case InternalMessageContentType.SipConferenceLeave:
            if (user && user.userId !== event.getSender()) {
              if (body.isNewRoom) {
                markRoomAsLowPriority(room.roomId);
              }
              dispatch(
                sipActions.updateSipConferenceInfo(
                  null,
                  body.conferenceId,
                  null,
                  [],
                  null,
                  body.userExtension,
                  null,
                  false
                )
              );
            }
            break;
          default:
            logger.warn(`Unhandled message: ${type}`);
            break;
        }
      }
    } catch (e) {
      logger.error(`Error in ApplicationScreen.handleInternalMessage - ${e.message}`);
    }
  };

  const handleNewRoomTopic = (content: any, eventRoom: MatrixRoom) => {
    const { topic } = content;
    const localRoom = getRoomFromRoomList(roomListRef.current, eventRoom.roomId);
    if (localRoom) {
      const eventTopic = tryParse(topic);
      let changed = false;

      if (localRoom.isHidden !== eventTopic.isHidden) {
        localRoom.isHidden = eventTopic.isHidden;
        changed = true;
      }
      if (localRoom.isGroupChat !== eventTopic.isGroupChat) {
        localRoom.isGroupChat = eventTopic.isGroupChat;
        changed = true;
      }
      if (localRoom.roomURL !== eventTopic.roomURL) {
        localRoom.roomURL = eventTopic.roomURL;
        changed = true;
      }
      if (localRoom.topic !== topic) {
        localRoom.topic = topic;
        changed = true;
      }

      if (changed) {
        dispatch(matrixActions.updateRoomInRoomList(localRoom));
      }
    }
  };

  return {
    fetchedPreparedRoomList,
    showGroupInviteNotification,
    groupInviteData,
    handleInternalMessage,
    handleNewRoomTopic,
    onAcceptGroupCall,
    onRejectGroupCall,
  };
};
