import { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/root-reducer";
import { PBXServerApiClient } from "services/PBXServerApi/PBXServerApiClient";
import { PbxCallQueue, PBXUserConfig } from "services/PBXServerApi/types/PBXUserConfig";
import { GetPbxUsersRequest, PbxUserData } from "services/PBXServerApi/types/PbxUsers";
import { pbxActions } from "store/actions/pbx";
import moment from "moment";
import { CallHistoryEntry } from "services/PBXServerApi/types/CallHistoryEntry";
import { CallHistoryType } from "types/SIP";
import { CDREntry } from "types/CDREntry";
import { DEVICES } from "types/UC";
import { debounce } from "throttle-debounce";
import { PBX_SEARCH_DEBOUNCE_DELAY_MS } from "utils/constants";

export const useGetPbxUserConfig = (deviceType: DEVICES) => {
  const [response, setResponse] = useState<undefined | PBXUserConfig>(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      PBXServerApiClient.Instance.getPBXUserConfig(deviceType)
        .then((result) => {
          setResponse(result);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => setLoading(false));
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { response, error, loading };
};

export interface useGetPbxCallQueuesType {
  search: (value: string) => void;
  response: PbxCallQueue[] | undefined;
  error: string | undefined;
  loading: boolean;
}

export const useGetPbxCallQueues = () => {
  const [response, setResponse] = useState<PbxCallQueue[] | undefined>(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const search = useMemo(
    () =>
      debounce(PBX_SEARCH_DEBOUNCE_DELAY_MS, (value: string) => {
        setLoading(true);
        PBXServerApiClient.Instance.getCallQueues(value)
          .then((result) => {
            setResponse(result.results);
          })
          .catch((error) => {
            setError(error);
          })
          .finally(() => setLoading(false));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { search, response, error, loading };
};

export const useSearchPbxUsers = () => {
  const [response, setResponse] = useState<PbxUserData[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const pbxUserSearchResults = useSelector((state: RootState) => state.pbx.pbxUserSearchResults);
  const { getPBXUsers } = pbxActions;
  const dispatch = useDispatch();

  const search = useMemo(
    () =>
      debounce(PBX_SEARCH_DEBOUNCE_DELAY_MS, (value: string, maxResults: number) => {
        setLoading(true);
        const opts: GetPbxUsersRequest = {
          queryString: value,
          includePresence: 1,
          max: maxResults,
        };
        dispatch(getPBXUsers(opts));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setResponse(pbxUserSearchResults);
    setLoading(false);
  }, [pbxUserSearchResults]);

  return { search, response, loading };
};

export const useGetCallHistory = () => {
  const mountedRef = useRef(true);
  const [response, setResponse] = useState<undefined | CDREntry[]>(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const max = 20;

  const formatName = (name: string) => {
    return name.replace("sip:", "").replace("X", "").split("@")[0];
  };

  const createCDREntry = (item: CallHistoryEntry) => {
    const number = item.type === CallHistoryType.Outbound ? formatName(item.orig_to_uri) : item.number;
    const result: CDREntry = {
      id: item.cdr_id,
      name: item.name,
      number: number,
      orig_to_uri: item.orig_to_uri,
      history: [],
      callee: item.orig_to_uri,
      date: parseInt(item.time_start, 10) * 1000,
      type: item.type,
      missed: "",
      duration: item.duration,
    };
    return result;
  };

  const fetchData = async (start?: number | undefined) => {
    const first = start !== undefined ? start : response ? response.length : 0;
    const timeNow: Date = new Date();
    const start_date: Date = moment(timeNow).utc().subtract(3, "months").toDate();
    const end_date: Date = moment(timeNow).utc().add(1, "days").toDate();

    PBXServerApiClient.Instance.getCallHistory(first, max, start_date, end_date)
      .then((result) => {
        if (mountedRef.current) {
          const values = result.results?.map((e) => createCDREntry(e));
          if (first > 0) setResponse((entries) => (entries ? entries.concat(values) : values));
          else setResponse((entries) => values);
        }
      })
      .catch((error) => {
        if (mountedRef.current) {
          setError(error);
        }
      })
      .finally(() => {
        if (mountedRef.current) {
          setLoading(false);
        }
      });
  };

  const clear = () => {
    setResponse([]);
  };

  useEffect(() => {
    fetchData();
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { response, error, loading, fetchData, clear };
};
