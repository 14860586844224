import Popover, { PopoverOrigin } from "@mui/material/Popover";
import { useWindowDimensions } from "hooks/useHandleWindowsEvents";

export enum PopoverDirection {
  left = "left",
  bottom = "bottom",
}

export type PopoverDirectionType = keyof typeof PopoverDirection;

const CustomPopoverWithArrow = ({ ...props }) => {
  const { direction, children, ...others } = props;
  const minWindowHeight = 550;

  const { windowHeight } = useWindowDimensions();

  const lessThanMinHeight = () => {
    return windowHeight ? windowHeight < minWindowHeight : false;
  };

  const getAnchorOrigin = (): PopoverOrigin => {
    switch (direction) {
      case PopoverDirection.left:
        return { vertical: "center", horizontal: "left" };
      case PopoverDirection.bottom:
      default:
        return { vertical: !lessThanMinHeight() ? "bottom" : "top", horizontal: "center" };
    }
  };

  const getTransformOrigin = (): PopoverOrigin => {
    switch (direction) {
      case PopoverDirection.left:
        return { vertical: "center", horizontal: "right" };
      case PopoverDirection.bottom:
      default:
        return { vertical: !lessThanMinHeight() ? "top" : "bottom", horizontal: "center" };
    }
  };

  const getArrowPosition = () => {
    let borderWidth, borderColor, left, top, width;
    switch (direction) {
      case PopoverDirection.left:
        borderWidth = `14px 14px 14px 18px`;
        borderColor = `transparent transparent transparent rgba(0, 0, 0, 0.9)`;
        left = "442px";
        top = "85px";
        width = "462px";
        break;
      case PopoverDirection.bottom:
      default:
        borderWidth = `${!lessThanMinHeight() ? 0 : "18px"} 14px ${!lessThanMinHeight() ? "18px" : 0} 14px`;
        borderColor = `${!lessThanMinHeight() ? "transparent" : "rgba(0, 0, 0, 0.9)"} transparent ${
          !lessThanMinHeight() ? "rgba(0, 0, 0, 0.9)" : "transparent"
        } transparent`;
        left = "205px";
        top = !lessThanMinHeight() ? 0 : "192px";
        width = "inherit";
        break;
    }

    return {
      "& .MuiPopover-paper": {
        "&:before": {
          width: 0,
          height: 0,
          display: "block",
          content: '""',
          borderStyle: "solid",
          borderWidth,
          borderColor,
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          opacity: 0.9200000167,
          left,
          top,
          position: "absolute",
        },
        width,
        boxShadow: "none",
        borderRadius: "10px",
        backgroundColor: "transparent",
        margin: 0,
      },
    };
  };

  return (
    <Popover
      open={Boolean(others.anchorEl)}
      anchorOrigin={getAnchorOrigin()}
      transformOrigin={getTransformOrigin()}
      sx={getArrowPosition()}
      {...others}
    >
      <div
        style={{ margin: direction === PopoverDirection.bottom ? (!lessThanMinHeight() ? "18px 0 0" : "0 0 18px") : 0 }}
      >
        {children}
      </div>
    </Popover>
  );
};

export default CustomPopoverWithArrow;
