import { UiSipCall, UiSipCallInfo } from "types/SIP";
import { sipCallPayload, sipUAPayload } from "store/types/sip";
import * as actionTypes from "../actions/types/sip";

export const sipActions = {
  startSipUA: (payload: sipUAPayload) => ({
    type: actionTypes.START_SIP_UA,
    payload,
  }),

  restartSipUA: () => ({ type: actionTypes.RESTART_SIP_UA }),

  stopSipUA: () => ({ type: actionTypes.STOP_SIP_UA }),

  startSipCall: (payload: sipCallPayload) => ({
    type: actionTypes.START_SIP_CALL,
    payload,
  }),

  acceptSipCall: (callInfo: UiSipCallInfo) => ({
    type: actionTypes.ACCEPT_SIP_CALL,
    callInfo,
  }),

  rejectSipCall: (callId: string) => ({
    type: actionTypes.REJECT_SIP_CALL,
    callId,
  }),

  addToSpeedDial: (payload: any[]) => ({
    type: actionTypes.SIP_CALL_ADD_SPEEDDIAL,
    payload,
  }),

  updateRegistrerState: (registered: boolean) => ({
    type: actionTypes.UPDATE_SIP_REGISTER_STATE,
    registered,
  }),

  updateSipCalls: (sipCalls: UiSipCall[], canInitateSipCall: boolean) => ({
    type: actionTypes.UPDATE_SIP_CALLS,
    sipCalls,
    canInitateSipCall,
  }),

  endSipCall: (callId: string) => ({
    type: actionTypes.END_SIP_CALL,
    callId,
  }),

  onSipCallTerminated: () => ({
    type: actionTypes.END_SIP_CALL_SUCCEEDED,
  }),

  updateSipCallInfo: (callId: string, user: string, name: string) => ({
    type: actionTypes.UPDATE_SIP_CALL_INFO,
    callId,
    user,
    name,
  }),

  updateSipStateInfo: (callId: string, user: string | undefined = undefined) => ({
    type: actionTypes.UPDATE_SIP_STATE_INFO,
    callId,
    user,
  }),

  updateSipConferenceInfo: (
    callId: string | null,
    conferenceId: string,
    owner: string | null,
    users: string[],
    userJoining: string | null,
    userLeaving: string | null,
    conferenceName: string | null,
    resetChannel: boolean
  ) => ({
    type: actionTypes.UPDATE_SIP_CONFERENCE_INFO,
    callId,
    conferenceId,
    owner,
    users,
    userJoining,
    userLeaving,
    conferenceName,
    resetChannel,
  }),

  getSipCallInfo: (callId: string, user: string, viaUser?: string) => ({
    type: actionTypes.GET_SIP_CALL_INFO,
    callId,
    user,
    viaUser,
  }),
};
