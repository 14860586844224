import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store/hooks";
import PropTypes from "prop-types";

import styles from "./SideBar.module.scss";

import Button from "@mui/material/Button";
import { CreateRoomDialog } from "components";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

import { useLocalStorage } from "hooks";
import { test } from "utils/helpers";

import { provisioningActionsCreator } from "store/actions/provisioning";

import { GlobalSearch } from "containers/GlobalSearch";
import CurrentUserAvatar from "containers/CurrentUserAvatar/CurrentUserAvatar";

import {
  getCreateMeetingUrl,
  createMeetingRoomName,
  checkIfAuthorized,
} from "services/CalendarProvider/CalendarProvider";
import { getLogger } from "logger/appLogger";
import { Visibility } from "types/Matrix";
import ExtensionStatus from "components/ExtensionStatus/ExtensionStatus";
import { Tooltip, Typography } from "@mui/material";
import { localStorageKeys, GUEST_APPSERVICE_USERNAME } from "utils/constants";

const SideBarHeader = ({ isMeeting = false, isSettings = false, isDialpad = false, onFilterRooom }) => {
  const dispatch = useDispatch();
  const logger = useMemo(() => getLogger("sidebar"), []);
  const module = "SideBarHeader";

  const createdRoom = useAppSelector((state) => state.provisioning.createdRoom);
  const createdRoomFailed = useAppSelector((state) => state.provisioning.createdRoomFailed);
  const currentUser = useAppSelector((state) => state.matrix.currentUser);
  const outlookCalendarConnected = useAppSelector((state) => state.app.outlookCalendarConnected);
  const googleCalendarConnected = useAppSelector((state) => state.app.googleCalendarConnected);
  const sipExtension = useAppSelector((state) => {
    if (state.pbx.pbxConfig && state.pbx.pbxConfig.hostedPbxConfig)
      return state.pbx.pbxConfig.hostedPbxConfig.userExtension;
    else return "";
  });
  const sipStateRegistered = useAppSelector((state) => state.sip.sipEnabled && state.sip.sipRegistered);

  const { createRoom } = provisioningActionsCreator;
  const [authorized, setAuthorized] = useState(false);
  const [user] = useLocalStorage(localStorageKeys.CURRENT_USER);
  const [openDialog, setOpenDialog] = useState(false);
  const [createMeeting, setCreateMeeting] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [roomName, setRoomName] = useState(null);
  const [roomDescription, setRoomDescription] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation();

  const handleDialogClose = () => {
    setOpenDialog(false);
    setRoomName(null);
    setRoomDescription(null);
  };

  const handleChange = (e, isRoomNameChange) => {
    const value = e?.target?.value;
    if (isRoomNameChange) setRoomName(value);
    else setRoomDescription(value);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const createNewMeeting = () => {
    setCreateMeeting(true);

    const guestAppServiceUsername = `@${GUEST_APPSERVICE_USERNAME}:${user.home_server}`;

    const opts = {
      name: createMeetingRoomName(currentUser.displayName),
      visibility: Visibility.Private,
      is_direct: false,
      is_hidden: true,
      invite: [guestAppServiceUsername],
      matrix_token: user.access_token,
      user_id: user.user_id,
    };
    dispatch(createRoom({ data: opts }));
    // finish in useEffect(),[createdRoom]
  };

  const handleDialogAgree = async (isPrivate) => {
    try {
      if (!roomName) {
        setErrorMessage(`${t("NOTEMPTY", { value: t("ROOMNAME") })}`);
        return;
      }

      if (roomName.length > 0) {
        setIsCreating(true);
        setErrorMessage(null);
        const opts = {
          name: roomName,
          visibility: isPrivate ? Visibility.Private : Visibility.Public,
          is_direct: false,
          invite: [],
          matrix_token: user.access_token,
          user_id: user.user_id,
          description: roomDescription,
        };
        dispatch(createRoom({ data: opts }));
        setRoomName(null);
        setRoomDescription(null);
      }
    } catch (e) {
      setIsCreating(false);
      setErrorMessage(e.message);
      logger.error(`${module}: Error in handleDialogAgree - ${e.message}`);
    }
  };

  const handleCreateRoom = (e) => {
    setOpenDialog(true);
  };

  const clearErrorMessage = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    if (!createdRoom) return;
    setIsCreating(false);
    setOpenDialog(false);
    logger.debug(`${module}: Created room: ${JSON.stringify(createdRoom)}`);

    if (createMeeting) {
      openInNewTab(getCreateMeetingUrl(createdRoom, currentUser.displayName));
      setCreateMeeting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdRoom]);

  useEffect(() => {
    if (!createdRoomFailed) return;
    setIsCreating(false);
    setErrorMessage(t("FAILEDTOCREATEROOM"));
    setCreateMeeting(false);
    logger.error(`${module}: Error in createdRoomFailed.useEffect - ${JSON.stringify(createdRoomFailed)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdRoomFailed]);

  useEffect(() => {
    if (openDialog === false) {
      setErrorMessage(null);
    }
  }, [openDialog]);

  useEffect(() => {
    if (roomName) {
      setRoomName(roomName.trim());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomName]);

  useEffect(() => {
    if (roomDescription) {
      setRoomDescription(roomDescription.trim());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomDescription]);

  useEffect(() => {
    checkIfAuthorized("sidebar").then((auth) => setAuthorized(auth));
  }, [outlookCalendarConnected, googleCalendarConnected]);

  if (isMeeting || isSettings || isDialpad) {
    let title = "";
    if (isMeeting) title = "MEETINGS";
    if (isSettings) title = "SETTINGS";
    if (isDialpad) title = "DIAL_OUT";
    return (
      <div className={`${styles.sideBarHeaderDiv}`} {...test(`SIDEBAR_${title}`)}>
        <div className={`${styles.sideBarHeader}`}>
          <div className={`${styles.header} ${styles.sideBarTitle}`}>
            <Typography variant="title">{t(title)}</Typography>
          </div>
        </div>
        {isDialpad && <ExtensionStatus extension={sipExtension} registered={sipStateRegistered} />}
        {isMeeting && authorized && (
          <Tooltip title={t("CREATE_MEETING")} aria-label="create_meeting" arrow>
            <Button
              id="sidebar_header_create_meeting_button"
              className={`${styles.headerButtons}`}
              sx={{ position: "absolute", top: "20px", right: "20px", padding: "6px 16px" }}
              aria-label="actions"
              aria-haspopup="true"
              aria-controls="room-menu"
              type="submit"
              variant="contained"
              color="primary"
              onClick={createNewMeeting}
            >
              <AddIcon />
            </Button>
          </Tooltip>
        )}
      </div>
    );
  }

  return (
    <div className={`${styles.header}`} {...test("SIDEBAR_HEADER")}>
      <CreateRoomDialog
        openDialog={openDialog}
        isCreating={isCreating}
        errorMessage={errorMessage}
        clearErrorMessage={clearErrorMessage}
        handleDialogClose={handleDialogClose}
        handleDialogAgree={handleDialogAgree}
        handleChange={handleChange}
      />
      <CurrentUserAvatar />
      <div style={{ margin: "0 10px" }}>
        <GlobalSearch />
      </div>

      <Tooltip title={t("CREATE_ROOM")} aria-label="create_room" arrow>
        <Button
          id="sidebar_header_create_room_button"
          className={`${styles.headerButtons}`}
          sx={{ padding: "6px 16px" }}
          aria-label="actions"
          aria-haspopup="true"
          aria-controls="room-menu"
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleCreateRoom}
        >
          <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
};

SideBarHeader.propTypes = {
  onSearchContactChange: PropTypes.func,
  isAddingContacts: PropTypes.bool,
};

export default SideBarHeader;
