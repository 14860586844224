/// <reference path="types.d.ts"/>
import { Types } from "Types";
import { Store } from "redux";
import rootReducer from "./root-reducer";
import configureStore from "./configureStore";

// store singleton instance
export type StoreType = Store<Types.RootState>;
let store: StoreType | undefined;

const getStore = () => {
  if (!store) {
    store = configureStore<Types.RootState>({
      reducer: rootReducer,
      devToolsName: "MAIN",
    });
  }

  return store;
};

export default getStore;
