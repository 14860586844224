import { useLocalStorage, useMatrixRooms } from "hooks";
import { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ConferenceModerationApiProvider } from "services/ConferenceModerationApiProvider/ConferenceModerationApiProvider";
import { RoomInfo } from "services/ConferenceModerationApiProvider/types/RoomInfo";
import { RootState } from "store/root-reducer";
import { UCUser } from "types/UC";
import { getLogger } from "logger/appLogger";
import { localStorageKeys } from "utils/constants";
import { callActions } from "store/actions/call";
import { isEmpty, isNumber } from "utils/helpers";

interface useConferenceModerationType {
  isRoomOwner: boolean;
  portalRoomId: number;
  matrixRoomId: string;
  roomTopic: string | undefined;
  meetingName: string;
  conferenceModerationError: string;

  getParticipantId: (userId: string) => number;
  getLocalParticipantId: () => number;

  getRoomInfo: () => Promise<RoomInfo>;
  pendingOperationRoomInfo: boolean;

  muteAllMicrophones: () => Promise<void>;
  pendingOperationMuteAllMicrophones: boolean;

  muteAllCameras: () => Promise<void>;
  pendingOperationMuteAllCameras: boolean;

  muteMicrophone: (participantId: number) => Promise<void>;
  pendingOperationMuteMicrophone: boolean;

  muteCamera: (participantId: number) => Promise<void>;
  pendingOperationMuteCamera: boolean;

  disableAllMicrophones: (participantId: number) => Promise<void>;
  enableAllMicrophones: () => Promise<void>;
  pendingOperationAllEnableDisableMicrophones: boolean;

  disableAllCameras: (participantId: number) => Promise<void>;
  enableAllCameras: () => Promise<void>;
  pendingOperationAllEnableDisableCameras: boolean;
}

export const useConferenceModeration = (): useConferenceModerationType => {
  const logger = useMemo(() => getLogger("conference-moderation"), []);
  const module = "useConferenceModeration";
  const dispatch = useDispatch();

  const confModeration = ConferenceModerationApiProvider.Instance();

  const [conferenceModerationError, setConferenceModerationError] = useState<string>("");

  const [pendingOperationRoomInfo, setPendingOperationRoomInfo] = useState<boolean>(false);
  const [pendingOperationMuteAllMicrophones, setPendingOperationMuteAllMicrophones] = useState<boolean>(false);
  const [pendingOperationMuteAllCameras, setPendingOperationMuteAllCameras] = useState<boolean>(false);
  const [pendingOperationMuteMicrophone, setPendingOperationMuteMicrophone] = useState(false);
  const [pendingOperationMuteCamera, setPendingOperationMuteCamera] = useState(false);
  const [pendingOperationAllEnableDisableMicrophones, setPendingOperationAllEnableDisableMicrophones] = useState(false);
  const [pendingOperationAllEnableDisableCameras, setPendingOperationAllEnableDisableCameras] = useState(false);

  const [currentUser] = useLocalStorage<UCUser>(localStorageKeys.CURRENT_USER);

  const participants = useSelector((state: RootState) => state.call.participants);
  const matrixRoomId = useSelector((state: RootState) => state.matrix.roomId)!;
  const meetingName = useSelector((state: RootState) => state.call.meetingName);
  const { getRoomTopic, getRoomCreator } = useMatrixRooms(matrixRoomId ?? undefined);
  const roomOwner = matrixRoomId ? getRoomCreator(matrixRoomId) : null;
  const isRoomOwner = currentUser.user_id === roomOwner;

  const roomTopic = getRoomTopic({ roomId: matrixRoomId ?? undefined });
  let portalRoomId: number = 0;
  if (roomTopic) {
    const { roomID } = JSON.parse(roomTopic);
    if (roomID) portalRoomId = parseInt(roomID);
  }

  type methodName =
    | "getRoomInfo"
    | "muteAllParticipantsAudio"
    | "muteAllParticipantsVideo"
    | "disableAllParticipantsAudio"
    | "enableAllParticipantsAudio"
    | "disableAllParticipantsVideo"
    | "enableAllParticipantsVideo"
    | "muteParticipantAudio"
    | "muteParticipantVideo";

  function invoke<T>(
    method: methodName,
    setPendingOperation: (pending: boolean) => void,
    participantId: number = 0
  ): Promise<T> {
    return new Promise<T>(function (resolve, reject) {
      logger.debug(`${module} call ${method}`);
      setPendingOperation(true);
      setConferenceModerationError("");
      confModeration[method](portalRoomId, participantId)
        .then((result: any) => {
          resolve(result);
        })
        .catch((err) => {
          logger.error(`Error in ${method} - ${err.data}`);
          setConferenceModerationError(err?.text);
          reject();
        })
        .finally(() => setPendingOperation(false));
    });
  }

  const getRoomInfo = async () => invoke<RoomInfo>("getRoomInfo", setPendingOperationRoomInfo);

  const muteAllMicrophones = async () =>
    invoke<void>("muteAllParticipantsAudio", setPendingOperationMuteAllMicrophones);

  const muteAllCameras = () => invoke<void>("muteAllParticipantsVideo", setPendingOperationMuteAllCameras);

  const muteMicrophone = (participantId: number) =>
    invoke<void>("muteParticipantAudio", setPendingOperationMuteMicrophone, participantId);

  const muteCamera = (participantId: number) =>
    invoke<void>("muteParticipantVideo", setPendingOperationMuteCamera, participantId);

  const disableAllMicrophones = (participantId: number) => {
    dispatch(callActions.updateModerationAction({ audioHardMute: true }));
    return invoke<void>(
      "disableAllParticipantsAudio",
      setPendingOperationAllEnableDisableMicrophones,
      participantId
    ).catch(() => {
      dispatch(callActions.updateModerationAction({ audioHardMute: false }));
    });
  };

  const enableAllMicrophones = () => {
    dispatch(callActions.updateModerationAction({ audioHardMute: false }));
    return invoke<void>("enableAllParticipantsAudio", setPendingOperationAllEnableDisableMicrophones);
  };

  const disableAllCameras = (participantId: number) => {
    dispatch(callActions.updateModerationAction({ videoHardMute: true }));
    return invoke<void>("disableAllParticipantsVideo", setPendingOperationAllEnableDisableCameras, participantId).catch(
      () => {
        dispatch(callActions.updateModerationAction({ videoHardMute: false }));
      }
    );
  };

  const enableAllCameras = () => {
    dispatch(callActions.updateModerationAction({ videoHardMute: false }));
    return invoke<void>("enableAllParticipantsVideo", setPendingOperationAllEnableDisableCameras);
  };

  const getParticipantId = (userId: string): number => {
    if (!isNumber(userId)) {
      userId = userId.replace("Guest_", "");
      userId = userId.replace("User_", "");
    }
    return isNumber(userId) ? parseInt(userId) : (logger.error(`Unrecognized userId format: ${userId}`), 0);
  };

  const getLocalParticipantId = (): number => {
    const localParticipant = participants.list.find((u) => u.isLocal);
    if (localParticipant) {
      let { userId } = localParticipant;
      return isEmpty(userId) ? (logger.error(`Empty userId for localParticipant`), 0) : getParticipantId(userId);
    }
    return 0;
  };

  return {
    isRoomOwner,
    portalRoomId,
    matrixRoomId,
    roomTopic,
    meetingName,
    conferenceModerationError,

    getParticipantId,
    getLocalParticipantId,

    getRoomInfo,
    pendingOperationRoomInfo,

    muteAllMicrophones,
    pendingOperationMuteAllMicrophones,

    muteAllCameras,
    pendingOperationMuteAllCameras,

    muteMicrophone,
    pendingOperationMuteMicrophone,

    muteCamera,
    pendingOperationMuteCamera,

    disableAllMicrophones,
    enableAllMicrophones,
    pendingOperationAllEnableDisableMicrophones,

    disableAllCameras,
    enableAllCameras,
    pendingOperationAllEnableDisableCameras,
  };
};
