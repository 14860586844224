import { SvgIconProps } from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material/styles";
import BrandLogoIcon from "icons/BrandLogoIcon";
import { test } from "utils/helpers";

const BrandLogo = (props: SvgIconProps) => {
  const theme = useTheme();
  return (
    <BrandLogoIcon {...test(theme.palette.mode === "light" ? "BRAND-LIGHT-ICON" : "BRAND-DARK-ICON")} {...props} />
  );
};

export default BrandLogo;
