import { useMemo, useState } from "react";
import { ConferenceModerationApiProvider } from "services/ConferenceModerationApiProvider/ConferenceModerationApiProvider";
import { RecordingEntry } from "services/ConferenceModerationApiProvider/types/RecordingEntry";
import { RecordingState } from "services/ConferenceModerationApiProvider/types/RoomInfo";
import { getLogger } from "logger/appLogger";

interface useRecordingType {
  pendingRecordingOperation: boolean;
  recordingState: string;
  recordingError: string;
  readState: () => Promise<void>;
  start: () => Promise<void>;
  stop: () => Promise<void>;
  pause: () => Promise<void>;
  resume: () => Promise<void>;
  getLastConferenceRecording: () => Promise<RecordingEntry>;
  getAllConferenceRecordings: (start: number, limit: number) => Promise<RecordingEntry[]>;
}

export const useRecording = (portalRoomId: number): useRecordingType => {
  const logger = useMemo(() => getLogger("recording"), []);
  const confModeration = ConferenceModerationApiProvider.Instance();

  const [pendingRecordingOperation, setPendingRecordingOperation] = useState<boolean>(false);
  const [recordingState, setRecordingState] = useState<string>(RecordingState.RECORDING_STATE_OFF);
  const [recordingError, setRecordingError] = useState("");

  type methodName =
    | "getRecordingConferenceState"
    | "startRecordingConferenceAsync"
    | "stopRecordingConferenceAsync"
    | "pauseRecordingConferenceAsync"
    | "resumeRecordingConferenceAsync";

  const invokeGeneric = (methodName: methodName): Promise<void> => {
    return new Promise(function (resolve, reject) {
      logger.debug(`${module} call ${methodName} ${portalRoomId}`);
      setPendingRecordingOperation(true);
      setRecordingError("");
      confModeration[methodName](portalRoomId)
        .then((ret: string) => {
          setRecordingState(confModeration.validateRecordingState(ret));
          resolve();
        })
        .catch((err: any) => {
          const text = err?.message || err?.data || "Recording error";
          logger.error(`Error in ${methodName} - ${text}`);
          setRecordingError(text);
          reject(err);
        })
        .finally(() => setPendingRecordingOperation(false));
    });
  };

  const readState = () => invokeGeneric("getRecordingConferenceState");

  const start = () => invokeGeneric("startRecordingConferenceAsync");

  const stop = () => invokeGeneric("stopRecordingConferenceAsync");

  const pause = () => invokeGeneric("pauseRecordingConferenceAsync");

  const resume = () => invokeGeneric("resumeRecordingConferenceAsync");

  const getLastConferenceRecording = (): Promise<RecordingEntry> => {
    return new Promise(function (resolve, reject) {
      logger.debug(`${module} call getLastConferenceRecording`);
      setPendingRecordingOperation(true);
      setRecordingError("");
      confModeration
        .getLastConferenceRecording(portalRoomId)
        .then((ret) => {
          resolve(ret);
        })
        .catch((err) => {
          logger.error(`Error in getLastConferenceRecording - ${err.data}`);
          setRecordingError(err?.text);
          reject(err);
        })
        .finally(() => setPendingRecordingOperation(false));
    });
  };

  const getAllConferenceRecordings = (start: number = 0, limit: number = 100): Promise<RecordingEntry[]> => {
    return new Promise(function (resolve, reject) {
      logger.debug(`${module} call getAllConferenceRecording`);
      setPendingRecordingOperation(true);
      setRecordingError("");
      confModeration
        .getAllConferenceRecordings(portalRoomId, start, limit)
        .then((ret) => {
          resolve(ret);
        })
        .catch((err) => {
          logger.error(`Error in getAllConferenceRecordings - ${err.data}`);
          setRecordingError(err?.text);
          reject(err);
        })
        .finally(() => setPendingRecordingOperation(false));
    });
  };

  return {
    pendingRecordingOperation,
    recordingState,
    recordingError,
    readState,
    start,
    stop,
    pause,
    resume,
    getLastConferenceRecording,
    getAllConferenceRecordings,
  };
};
