import { getLogger } from "logger/appLogger";

const logger = getLogger("presence");

class CommService {
  constructor() {
    logger.debug(`CommService starting...`);
    this.inCall = false;
    this.inConf = false;
    this.presenting = false;
  }

  getActiveSession() {
    if (this.presenting) {
      return "presenting";
    }

    if (this.inConf) {
      return "conf";
    }

    if (this.inCall) {
      return "call";
    }

    return null;
  }

  reset() {
    this.inCall = false;
    this.inConf = false;
    this.presenting = false;
  }
}

export default CommService;
