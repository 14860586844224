import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getPresenceProvider } from "services/PresenceProvider";
import { MappedRoomList } from "types/Matrix";
import { presenceActions } from "store/actions/presence";
import { getLogger } from "logger/appLogger";
import { useAppSelector } from "store/hooks";
import { useLocalStorage, useMatrixAvatar, useMatrixMembers } from "hooks";
import { localStorageKeys } from "utils/constants";

const presenceProvider = getPresenceProvider();

export const usePresence = () => {
  const dispatch = useDispatch();
  const logger = useMemo(() => getLogger("matrix.presence"), []);

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const endpointData = useAppSelector((state) => state.provisioning.endpointData);
  const user = useAppSelector((state) => state.matrix.currentUser);

  const [currentTenantName] = useLocalStorage(localStorageKeys.TENANT_NAME);
  const { getUsersFromDirectRooms } = useMatrixMembers();
  const { getUserProfileAvatar } = useMatrixAvatar();

  const updatePresenceList = async (userIdList: string[]) => {
    try {
      setLoading(true);
      await presenceProvider.updatePresenceList(userIdList);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
      throw e;
    }
  };

  const addUserToInitialPresenceList = async (userId: string) => {
    try {
      setLoading(true);
      await presenceProvider.addUserToInitialPresenceList(userId);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
      throw e;
    }
  };

  const removeUserFromInitialPresenceList = async (userId: string) => {
    try {
      setLoading(true);
      await presenceProvider.removeUserFromInitialPresenceList(userId);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
      throw e;
    }
  };

  const resetToInitialPresenceList = async () => {
    try {
      setLoading(true);
      await presenceProvider.resetToInitialPresenceList();
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
      throw e;
    }
  };

  const startUserPresence = async (roomList: MappedRoomList) => {
    try {
      const { host, origin } = window.location;
      const presenceUrl = endpointData ? `https://${endpointData.presence}` : `${origin}/presence`;
      const presenceWebsocketUrl =
        endpointData && endpointData.presenceWS
          ? `wss://${endpointData.presenceWS}`
          : `wss://${host}/presence-websocket`;
      const userList = user && getUsersFromDirectRooms(roomList, user.userId);

      if (userList && userList.length > 0) {
        const apiConfig = {
          presenceUrl,
          apiUrl: `${presenceUrl}/api/v1`,
          wsUrl: `${presenceWebsocketUrl}/api/v1`,
          presentityId: user?.userId,
          userList,
          tenantName: currentTenantName,
          avatarUrl: user ? await getUserProfileAvatar(user.userId) : "",
        };
        dispatch(presenceActions.startPresenceService(apiConfig));
      }
    } catch (e) {
      logger.error(`Error in startUserPresence - roomList:${roomList} - ${e.message}`);
    }
  };

  return {
    updatePresenceList,
    addUserToInitialPresenceList,
    removeUserFromInitialPresenceList,
    resetToInitialPresenceList,
    startUserPresence,
    loading,
    error,
  };
};
