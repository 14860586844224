import { useState, useEffect, useMemo } from "react";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import { Button, Divider, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useConferenceModeration, useMatrixRooms, useRecording } from "hooks";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import styled from "themes/theme/baseTheme";

import RecordingLinkDialog from "./RecordingLinkDialog";
import StopRecordingDialog from "./StopRecordingDialog";

import { getLogger } from "logger/appLogger";
import { RecordingState } from "services/ConferenceModerationApiProvider/types/RoomInfo";
import { MatrixRoomTopics } from "types/Matrix";
import { tryParse } from "utils/helpers";
import { Events } from "types/UC";
import { RoomMember } from "matrix-js-sdk";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  height: "55px",
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  height: "55px",
}));

const StyledActionButtons = styled(Button)(({ theme }) => ({
  padding: 0,
  width: "45px",
  minWidth: "45px",
  height: "45px",
  backgroundColor: "transparent",
  color: theme.palette.icon.navLink.notSelected,
  "&:hover": {
    color: theme.palette.icon.navLink.selected,
    backgroundColor: theme.palette.background.navLink.hover,
  },
}));

const StyledButtons = styled(Button)(({ theme }) => ({
  padding: 0,
  width: "24px",
  minWidth: "24px",
  backgroundColor: "transparent !important",
  marginRight: "10px",
}));

const RecorderBar = () => {
  const logger = useMemo(() => getLogger("recording"), []);
  const module = "RecorderBar";

  const { t } = useTranslation();
  const theme = useTheme();

  const [recordingUrl, setRecordingUrl] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openLinkDialog, setOpenLinkDialog] = useState(false);
  const [dialogBusy, setDialogBusy] = useState(false);
  const [roomMembers, setRoomMembers] = useState<RoomMember[]>([]);

  const { isRoomOwner, portalRoomId, matrixRoomId, roomTopic } = useConferenceModeration();
  const { updateRoomTopic, getRoom, updateRoomMembers } = useMatrixRooms();
  const {
    pendingRecordingOperation,
    recordingState,
    recordingError,
    readState,
    start,
    stop,
    pause,
    resume,
    getLastConferenceRecording,
  } = useRecording(portalRoomId);

  const participantsInfo = useAppSelector((state) => state.call.participantsInfo);

  const handleStopRecDialog = async () => {
    if (!dialogBusy) {
      setDialogBusy(true);
      try {
        await stop().catch((reason) => logger.error(`${module}: error stopping recording:${reason}`));
        setOpenDialog(false);
        getLastConferenceRecording()
          .then((entry) => {
            logger.debug(`${module}: handleStopRecDialog: ${JSON.stringify(entry)}`);
            setRecordingUrl(entry.linkUrl);
            setOpenLinkDialog(true);
          })
          .catch((reason) => {
            logger.error(`${module}: error getting the last conference recording:${reason}`);
          });
      } finally {
        try {
          if (roomTopic) {
            const roomTopicObj = tryParse(roomTopic);

            if (roomTopicObj.isHidden) {
              await updateRoomTopic(matrixRoomId, MatrixRoomTopics.isHidden, false, roomTopic);
            }
          }

          updateRoomMembers(
            participantsInfo.map((p) => p.username),
            roomMembers,
            matrixRoomId
          );
        } catch (e) {
          logger.error(`Error in ${module}.handleStopRecDialog - ${e.message}`);
        }
        setDialogBusy(false);
      }
    }
  };

  const handleCancelDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenBrowserLinkDialog = () => {
    setOpenLinkDialog(false);
    logger.debug(`${module}: opening: `, recordingUrl);
    window.open(recordingUrl, "_blank", "noopener,noreferrer");
  };

  const handleCloseLinkDialog = () => {
    setOpenLinkDialog(false);
  };

  useEffect(() => {
    if (recordingError && recordingError !== "") {
      document.dispatchEvent(new Event(Events.RECORDER_ERROR));
      logger.warn(`${module}: error: ${recordingError}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordingError]);

  useEffect(() => {
    logger.debug(`${module}: isRoomOwner:${isRoomOwner}`);
    readState().catch((reason) => logger.error(`${module}: error getting state of recording:${reason}`));
    const matrixRoom = getRoom(matrixRoomId);
    if (matrixRoom) {
      setRoomMembers(matrixRoom.getMembers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ui states:
  // <dot>       start recording
  // <red-dot>   recording <pause><stop>
  // <white-dot> recording paused <resume><stop>

  const ui = () => {
    return (
      <>
        <List component="div" disablePadding sx={{ width: "100%", backgroundColor: theme.palette.background.expanded }}>
          <Divider style={{ backgroundColor: theme.palette.background.default }} />
          {recordingState === RecordingState.RECORDING_STATE_OFF && !dialogBusy && (
            <StyledListItemButton
              onClick={() => start().catch((reason) => logger.error(`${module}: error starting recording:${reason}`))}
              disabled={pendingRecordingOperation}
            >
              <ListItemText
                primary={t("START_RECORDING")}
                primaryTypographyProps={{
                  variant: "regular",
                  sx: { color: theme.palette.text.button.text.active, textAlign: "center" },
                }}
              />
            </StyledListItemButton>
          )}
          {recordingState === RecordingState.RECORDING_STATE_RECORDING && (
            <StyledListItem>
              <StyledButtons
                size="small"
                disabled
                disableFocusRipple
                disableRipple
                disableTouchRipple
                style={{ color: "RGB(226, 87, 76)" }}
              >
                <RadioButtonCheckedIcon />
              </StyledButtons>
              <ListItemText primary={t("RECORDING")} primaryTypographyProps={{ variant: "regular" }} />
              <StyledActionButtons
                disabled={pendingRecordingOperation}
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={() => pause().catch((reason) => logger.error(`${module}: error pausing recording:${reason}`))}
                size="large"
              >
                <PauseRoundedIcon />
              </StyledActionButtons>
              <StyledActionButtons
                size="medium"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={() => setOpenDialog(true)}
                disabled={pendingRecordingOperation}
              >
                <StopRoundedIcon />
              </StyledActionButtons>
            </StyledListItem>
          )}
          {recordingState === RecordingState.RECORDING_STATE_PAUSED && (
            <StyledListItem>
              <StyledButtons size="small" disableFocusRipple disableRipple disableTouchRipple disabled>
                <RadioButtonCheckedIcon />
              </StyledButtons>
              <ListItemText primary={t("RECORDING_PAUSED")} primaryTypographyProps={{ variant: "regular" }} />
              <StyledActionButtons
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={() =>
                  resume().catch((reason) => logger.error(`${module}: error resuming recording:${reason}`))
                }
                disabled={pendingRecordingOperation}
                size="large"
                sx={{
                  color: theme.palette.icon.navLink.selected,
                  backgroundColor: theme.palette.background.navLink.selected,
                  border: `1px solid ${theme.palette.border.navLink}`,
                }}
              >
                <PauseRoundedIcon />
              </StyledActionButtons>
              <StyledActionButtons
                size="small"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={() => setOpenDialog(true)}
                disabled={pendingRecordingOperation}
              >
                <StopRoundedIcon />
              </StyledActionButtons>
            </StyledListItem>
          )}
          {recordingState === RecordingState.RECORDING_STATE_OFF && dialogBusy && (
            <StyledListItemButton disabled={pendingRecordingOperation}>
              <ListItemText
                primary={t("PROCESSING_RECORDING")}
                primaryTypographyProps={{ variant: "regular", sx: { textAlign: "center" } }}
              />
            </StyledListItemButton>
          )}
        </List>
        <StopRecordingDialog
          openDialog={openDialog}
          handleCancelDialog={handleCancelDialog}
          handleStopRecDialog={handleStopRecDialog}
        />
        <RecordingLinkDialog
          recordingUrl={recordingUrl}
          openLinkDialog={openLinkDialog}
          handleCloseLinkDialog={handleCloseLinkDialog}
          handleOpenBrowserLinkDialog={handleOpenBrowserLinkDialog}
        />
      </>
    );
  };

  return ui();
};

export default RecorderBar;
