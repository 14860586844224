import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import styled from "themes/theme/baseTheme";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.border.searchField.notSelected,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.border.searchField.focused,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.border.searchField.focused,
    },
  },
}));

const SearchField = ({ ...props }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { InputProps, placeholder, ...other } = props;

  return (
    <StyledTextField
      placeholder={placeholder ?? t("SEARCH")}
      InputProps={
        InputProps ?? {
          startAdornment: (
            <InputAdornment position="start" sx={{ marginLeft: "-5px" }}>
              <SearchIcon fontSize="small" sx={{ color: theme.palette.text.secondary }} />
            </InputAdornment>
          ),
          endAdornment: null,
        }
      }
      {...other}
    />
  );
};

export default SearchField;
