import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "components/_shared/IconButton";

export interface StopRecordingDialogProps {
  openDialog: boolean;
  handleCancelDialog: () => void;
  handleStopRecDialog: () => void;
}

const StopRecordingDialog = ({ openDialog, handleCancelDialog, handleStopRecDialog }: StopRecordingDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Dialog onClose={handleCancelDialog} open={openDialog}>
      <DialogTitle id="simple-dialog-title">
        {t("STOP_RECORDING")}
        <IconButton
          aria-label="close"
          tooltip={t("CLOSE")}
          tooltipPlacement="top"
          onClick={handleCancelDialog}
          size="large"
          sx={{ position: "absolute", right: "4px", top: "4px", color: theme.palette.icon.main }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: "16px !important" }}>
        <Typography id="alert-dialog-description" variant="body2" width="324px">
          {t("STOP_RECORDING_TEXT")}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button id="stop_recording_dialog_cancel_button" variant="outlined" onClick={handleCancelDialog} autoFocus>
          {t("CANCEL")}
        </Button>
        <Button
          id="stop_recording_dialog_stop_button"
          onClick={handleStopRecDialog}
          sx={{ backgroundColor: `${theme.palette.error.main} !important` }}
        >
          {t("STOP")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StopRecordingDialog;
