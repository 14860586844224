import { useState, useMemo } from "react";
import { getLogger } from "logger/appLogger";
import { useMatrix, useLocalStorage } from "hooks";
import { presenceActions } from "store/actions/presence";
import { authActions } from "store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/root-reducer";
import { sipActions } from "store/actions/sip";
import { localStorageKeys } from "utils/constants";
import isElectron from "is-electron";
import { isEmpty } from "utils/helpers";
import { ProvisioningTokenProvider } from "services/Providers/ProvisioningTokenProvider";

interface useLogoutType {
  doLogout: (forceLogout: boolean) => Promise<void>;
  isLoggingOut: boolean;
}

export const useLogout = (): useLogoutType => {
  const logger = useMemo(() => getLogger("logout"), []);
  const module = "useLogout";

  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [, setCurrentUser] = useLocalStorage(localStorageKeys.CURRENT_USER);
  const [, setAppStorage] = useLocalStorage(localStorageKeys.APP_STORAGE);
  const [currentTenantName] = useLocalStorage<string>(localStorageKeys.TENANT_NAME);
  const { stopClient } = useMatrix("");
  const dispatch = useDispatch();
  const { changePresence, stopPresenceService } = presenceActions;
  const { logout } = authActions;
  const endpointData = useSelector((state: RootState) => state.provisioning.endpointData);

  const doLogout = async (forceLogout: boolean) => {
    try {
      logger.warn(`doLogout start forceLogout=${forceLogout}`);
      setIsLoggingOut(true);
      await stopClient();
      setCurrentUser(null);
      setAppStorage(null);
      setIsLoggingOut(false);
      dispatch(logout());
      const opts = {
        transitionName: "logout",
        status: null,
      };
      if (!forceLogout) {
        // presence would only run into errors on forceLogout - because it requires a valid token
        dispatch(changePresence(opts));
        dispatch(stopPresenceService());
      }
      dispatch(sipActions.stopSipUA());
    } catch (e) {
      logger.error(`${module}: Error in doLogout - ${e.message}`);
    }

    const redirectUrl = endpointData ? `https://${endpointData.redirect_url}` : window.location.origin;

    if (!isEmpty(currentTenantName)) {
      let logoutURL = `${redirectUrl}/tenant/${currentTenantName}/tenant-provisioning/api/v1/auth/logout?redirect_url=${redirectUrl}/`;
      if (isElectron()) {
        logoutURL += encodeURIComponent("?electronLogin=true");
      }
      const idToken = ProvisioningTokenProvider.Instance.idToken;

      if (forceLogout) {
        if (endpointData?.backchannelLogout) {
          // see https://www.keycloak.org/docs/latest/server_admin/#_oidc-logout => RP-Initiated Logout
          // see https://openid.net/specs/openid-connect-rpinitiated-1_0.html#RPLogout

          const backchannelLogoutUrl = new URL(endpointData.backchannelLogout);
          const haveParams = backchannelLogoutUrl.searchParams.size > 0;
          let url = endpointData.backchannelLogout.concat(haveParams ? "&" : "?");

          if (!backchannelLogoutUrl.searchParams.has("client_id")) {
            logger.warn("logout: client_id not found in provided backchannel logout url");
          }

          if (!isEmpty(idToken)) {
            url = url.concat(`id_token_hint=${encodeURIComponent(idToken)}&`);
          } else {
            logger.warn("logout: id_token not found");
          }

          // trailing / is important!
          url = url.concat(`post_logout_redirect_uri=${encodeURIComponent(redirectUrl)}/`);

          logoutURL = url;
        } else {
          logger.error("Backchannel logout undefined in endpoint");
        }
      }

      logger.debug(`${module}: navigating to logoutURL => ${logoutURL}`);
      window.location.href = logoutURL;

      if (isElectron()) {
        window.ipcRenderer.send("logout", forceLogout);
      }
    } else {
      logger.error(`${module}: tenant name is empty, clean logout will fail`);
    }

    logger.warn("doLogout done");
  };

  return {
    doLogout,
    isLoggingOut,
  };
};
