import { Components } from "@mui/material";

const MuiAccordionSummary: Components["MuiAccordionSummary"] = {
  styleOverrides: {
    content: {
      margin: 0,
    },
  },
};

export default MuiAccordionSummary;
