import { Components, Theme } from "@mui/material";

const MuiCheckbox: Components["MuiCheckbox"] = {
  styleOverrides: {
    root: ({ theme }) => {
      const palette = (theme as Theme).palette;
      return {
        color: palette.background.radioAndCheckbox.notSelected,
        "&:hover": {
          backgroundColor: palette.background.radioAndCheckbox.hover,
        },
        "&:focus": {
          backgroundColor: palette.background.radioAndCheckbox.focused,
        },
        "&.Mui-checked": {
          color: palette.background.radioAndCheckbox.selected,
        },
        "&.Mui-disabled": {
          color: palette.background.radioAndCheckbox.disabled,
        },
      };
    },
  },
};

export default MuiCheckbox;
