import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import "./ExtensionStatus.css";
import { Typography } from "@mui/material";

interface ExtensionStatusProps {
  extension: string;
  registered: boolean;
}

const ExtensionStatus: FC<ExtensionStatusProps> = (args: ExtensionStatusProps) => {
  const { t } = useTranslation();

  return (
    <div className="extension">
      <Typography variant="body3">{`${t("EXTENSION")} ${args.extension}`}</Typography>

      <div className="extensionStatus">
        <svg
          className={`extensionSvg ${args.registered ? "registered" : "unregistered"}`}
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle id="Misc/UserStatus" cx="5" cy="5" r="4.5" stroke="white" />
        </svg>
        <Typography variant="body3">{args.registered ? t("SIP_REGISTERED") : t("SIP_UNREGISTERED")}</Typography>
      </div>
    </div>
  );
};

export default ExtensionStatus;
