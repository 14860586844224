import React from "react";
import { useTranslation } from "react-i18next";
import { test } from "utils/helpers";
import SoundSample from "assets/audio/Ringtone.mp3";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useSelector } from "react-redux";

import "./TestSound.scss";
import { Typography } from "@mui/material";
import { IconButton } from "components";

const TestSound = () => {
  const { t } = useTranslation();
  const audio = new Audio(SoundSample);
  const selectedSpeaker = useSelector((state) => state.devices.selectedSpeaker);

  const toggle = () => {
    if (selectedSpeaker?.id && audio.setSinkId) {
      audio.setSinkId(selectedSpeaker.id);
    }
    audio.pause();
    audio.play();
  };

  return (
    <div className="test-sound-container" {...test("TEST_SOUND")}>
      <IconButton onClick={toggle}>
        <PlayArrowIcon />
      </IconButton>
      <Typography variant="regular" sx={{ cursor: "default" }}>
        {t("TEST_SOUND")}
      </Typography>
    </div>
  );
};

export default TestSound;
