const neutrals = {
  pureWhite: "#FFFFFF",
  lightGrey1: "#F4F5F6",
  lightGrey2: "#E9EBEC",
  lightGrey3: "#D4D8DA",
  lightGrey4: "#C8CDD0",
  lightGrey5: "#BEC3C6",
  lightGrey6: "#A8AFB3",
  darkGrey1: "#879197",
  darkGrey2: "#687378",
  darkGrey3: "#555E62",
  darkGrey4: "#393F41",
  darkGrey5: "#1C1F21",
  darkGrey6: "#121416",
  almostBlack: "#090A0B",
  regularBlack: "#2F3138",
};

export type Neutrals = typeof neutrals;

export default neutrals;
