import CommService from "./CommService";
import PresenceAPI from "./PresenceAPI";
import PresenceService from "./PresenceService";
import { getLogger } from "logger/appLogger";

const logger = getLogger("presence");

class PresenceProviderAPI {
  constructor() {
    if (PresenceProviderAPI.instance) {
      return PresenceProviderAPI.instance;
    }
    this.commService = null;
    this.presenceApi = null;
    this.presenceService = null;
    this.savedHandlers = {};
    this.initialUserList = [];
    this.module = "PresenceProviderApi";
  }

  async startPresenceService(config) {
    try {
      this.commService = new CommService();
      this.presenceApi = new PresenceAPI(config);
      this.initialUserList = [...this.presenceApi.userList];
      this.presenceService = new PresenceService(this.presenceApi, this.commService);
      this.presenceApi.set_status_listener((status) => {
        this.presenceService.doTransition("remote-status-update", status);
      });
      return await this.presenceApi.init();
    } catch (e) {
      throw e;
    }
  }

  async stopPresenceService() {
    try {
      await this.presenceApi.cleanup();
      logger.debug(`${this.module}: Successfully cleaned up Presence`);
    } catch (e) {
      logger.error(`${this.module}: Error in stopPresenceService - ${e.message}`);
    }
  }

  async resetToInitialPresenceList() {
    try {
      this.presenceApi.userList = this.initialUserList;
    } catch (e) {
      logger.error(`${this.module}: Error in resetToInitialPresenceList - ${e.message}`);
    }
  }

  async addUserToInitialPresenceList(userId) {
    try {
      if (!userId) return;
      const isInArray = this.initialUserList.includes(userId) || false;
      if (!isInArray) {
        this.initialUserList.push(userId);
      }
    } catch (e) {
      logger.error(`${this.module}: Error in addUserToInitialPresenceList - userId:${userId} - ${e.message}`);
    }
  }

  async removeUserFromInitialPresenceList(userId) {
    try {
      if (!userId) return;
      const newList = this.initialUserList.filter((presenceUserId) => presenceUserId !== userId);
      this.initialUserList = newList;
    } catch (e) {
      logger.error(`${this.module}: Error in removeUserFromInitialPresenceList - userId:${userId} - ${e.message}`);
    }
  }

  async updatePresenceList(usersIdList) {
    try {
      if (!usersIdList) return;

      let newList = [...this.presenceApi.userList];
      if (usersIdList.length > 0) {
        usersIdList.forEach((userId) => {
          const isInArray = newList.includes(userId) || false;
          if (!isInArray) {
            newList.push(userId);
          }
        });
      } else {
        newList = this.initialUserList;
      }

      if (newList.length > 0) {
        this.presenceApi.userList = newList;
        return await this.presenceApi.update_subscription();
      }
    } catch (e) {
      logger.error(`${this.module}: Error in updatePresenceList - usersIdList:${usersIdList} - ${e.message}`);
    }
  }

  async updatePresenceAvatar(avatarUrl) {
    try {
      return await this.presenceService.broadcastAvatar(avatarUrl);
    } catch (e) {
      logger.error(`${this.module}: Error in updatePresenceAvatar - avatarUrl:${avatarUrl} - ${e.message}`);
    }
  }

  async fetchPresenceStatus(userId) {
    try {
      return await this.presenceService.fetchPresenceStatus(userId);
    } catch (e) {
      logger.error(`${this.module}: Error in fetchPresenceState - userId:${userId} - ${e.message}`);
    }
  }

  subscribeToPresenceChange(handler) {
    try {
      this.presenceService.setFSMObservers({
        onEnterState: handler,
      });
      this.eventEmitter(this.presenceApi, "user-update", handler);
    } catch (e) {
      throw e;
    }
  }

  unSubscribeToPresenceChange() {
    try {
      this.removeEventEmitter(this.presenceApi, "user-update");
    } catch (e) {
      throw e;
    }
  }

  changePresence(transitionName, status) {
    logger.debug(`${this.module} changePresence transitionName=${transitionName} status=${status}`);
    try {
      // Setup transition
      switch (transitionName) {
        case "local-status-change":
        case "remote-status-update":
          // Payload must also contain a status
          logger.debug(`${this.module}: New status: ${status}`);
          break;
        case "network-online":
        case "login":
        case "logout":
          this.commService.reset();
          break;
        case "incoming-call":
        case "outgoing-call":
          this.commService.inCall = true;
          break;
        case "exit-call":
          this.commService.presenting = false;
          this.commService.inCall = false;
          break;
        case "join-conf":
          this.commService.inConf = true;
          break;
        case "exit-conf":
          this.commService.presenting = false;
          this.commService.inConf = false;
          break;
        case "start-sharing":
          this.commService.presenting = true;
          break;
        case "stop-sharing":
          this.commService.presenting = false;
          break;
        default:
          break;
      }
      this.presenceService.doTransition(transitionName, status);
    } catch (e) {
      logger.error(
        `${this.module}: Error in changePresence - transitionName:${transitionName}, status:${status} - ${e.message}`
      );
    }
  }

  //  -----------------------------------------

  eventEmitter(emitter, eventName, handler) {
    try {
      if (!emitter) return;
      if (!eventName) return;
      const eventListener = (...args) => {
        handler(...args);
      };
      this.savedHandlers[eventName] = this.savedHandlers[eventName] || eventListener;
      return emitter.on(eventName, this.savedHandlers[eventName]);
    } catch (e) {
      logger.error(`${this.module}: Error in eventEmitter - ${e.message}`);
    }
  }

  removeEventEmitter(emitter, eventName) {
    try {
      if (!emitter) return;
      if (!eventName) return;
      const res = emitter.removeListener(eventName, this.savedHandlers[eventName]);
      delete this.savedHandlers[eventName];
      return res;
    } catch (e) {
      logger.error(`${this.module}: Error in removeEventEmitter - ${e.message}`);
    }
  }
}

export default PresenceProviderAPI;
