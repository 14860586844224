import { AUTH_LOGIN, AUTH_LOGOUT } from "../actions/types/auth";

const initialAuthState = {
  isAuthorized: false,
};

const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return { isAuthorized: true };
    case AUTH_LOGOUT:
      return { isAuthorized: false };
    default:
      return state;
  }
};

export default authReducer;
