// ====================================================
// local storage variable
// ====================================================
export class LocalStorageVariable<T> {
  private _key: string;
  constructor(key: string) {
    this._key = key;
  }
  public get = (): T => {
    const str = localStorage.getItem(this._key);
    return str ? JSON.parse(str) : undefined;
  };

  public set = (value: T | null) => {
    if (value) {
      localStorage.setItem(this._key, JSON.stringify(value));
    } else {
      this.delete();
    }
  };

  public delete = () => {
    localStorage.removeItem(this._key);
  };
}
