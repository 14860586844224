import { getLogger } from "logger/appLogger";
import mxClient from "matrix/matrix";
import { CallMediaType } from "types/UC";
import * as sdk from "matrix-js-sdk";
import { ICreateClientOpts, MatrixCall, UploadOpts } from "matrix-js-sdk";
import { LocalEventType } from "types/Matrix";
import { CallErrorCode, CallEvent, CallType, CallState } from "matrix-js-sdk/lib/webrtc/call";
import { CallFeed } from "matrix-js-sdk/lib/webrtc/callFeed";
import { CustomStyleToExtStream } from "services/CustomRenderer";
import { MatrixDataChannelMsgs } from "types/Matrix";
import getStore from "store/store";
import * as actionTypes from "store/actions/types/matrix";
// @ts-ignore
import ringtone from "assets/audio/Ringtone.mp3";

const logger = getLogger("matrix.api");

class SavedCallElements {
  remoteElement: any = null;
  localElement: any = null;
}

class SavedDevices {
  video: any = null;
  audio: any = null;
  speaker: any = null;
}

class MatrixRingtone {
  audio = new Audio(ringtone) as HTMLAudioElement;
  stats = { playing: false };
}

class MatrixAPI {
  callClient: MatrixCall | null = null;
  inScreenshare: boolean = false;
  inRemoteScreenshare: boolean = false;
  cloneLocalScreenshareStream: MediaStream | undefined = undefined;
  cloneRemoteScreenshareStream: MediaStream | undefined = undefined;
  isVideoMuted: boolean = false;
  savedHandlers: any = {};
  savedCallInvites: { [index: string]: MatrixCall } = {};
  savedCallElements: SavedCallElements = new SavedCallElements();
  savedDevices: SavedDevices = new SavedDevices();
  matrixRingtone = new MatrixRingtone();
  module = "MatrixApi";
  sendChannel: RTCDataChannel | undefined = undefined;
  receiveChannel: RTCDataChannel | undefined = undefined;
  sendChannelQueue: string[] = [];

  private static _instance: MatrixAPI;
  public static Instance(): MatrixAPI {
    if (!MatrixAPI._instance) {
      MatrixAPI._instance = new MatrixAPI();
    }
    return MatrixAPI._instance;
  }

  private constructor() {}

  getClient() {
    if (!mxClient.client) {
      throw new Error("Mxclient.client not initialized");
    }
    return mxClient.client;
  }

  public setInRemoteScreenshare(inRemoteScreenshare: boolean) {
    this.inRemoteScreenshare = inRemoteScreenshare;
    // send action to update remote screen sharing in state
    // it's necessary to give a few seconds of timeout, to give time
    // for the process of removing the tracks and only then update the layout
    setTimeout(() => {
      getStore().dispatch({
        type: actionTypes.SET_IN_MATRIX_REMOTE_SCREENSHARE,
        inRemoteScreenshare: inRemoteScreenshare,
      });
    }, 500);
  }

  async validateMatrix(opts: ICreateClientOpts) {
    try {
      const client = sdk.createClient(opts);
      return await client.getVersions();
    } catch (e) {
      logger.error(`${this.module}: Error in validateMatrix - opts:${opts}`);
      throw e;
    }
  }

  async sendMatrixEvent(roomId: string, eventType: LocalEventType, content: object) {
    try {
      return await mxClient.sendInternalMessageEvent(roomId, eventType, content);
    } catch (e) {
      logger.error(`${this.module}: Error in sendMatrixEvent - roomId:${roomId}, content:${content} - ${e.message}`);
    }
  }

  async uploadContent(file: XMLHttpRequestBodyInit, opts: UploadOpts) {
    try {
      return await mxClient.uploadContent(file, opts);
    } catch (e) {
      logger.error(`${this.module}: Error in uploadContent - file:${file}, opts:${opts} - ${e.message}`);
      throw e;
    }
  }

  async setAvatar(url: string) {
    try {
      return await mxClient.setAvatarUrl(url);
    } catch (e) {
      logger.error(`${this.module}: Error in setAvatar - url:${url} - ${e.message}`);
      throw e;
    }
  }

  async getUserProfileInfo(userId: string) {
    try {
      return await mxClient.getUserProfileInfo(userId);
    } catch (e) {
      logger.error(`${this.module}: Error in getUserProfileInfo - userId:${userId} - ${e.message}`);
      throw e;
    }
  }

  getCurrentUser() {
    try {
      return mxClient.getCurrentUser();
    } catch (e) {
      logger.error(`${this.module}: Error in getCurrentUser - ${e.message}`);
      throw e;
    }
  }

  setMatrixMicrophone(id: any) {
    const msg = `${this.module}: P2P Call | Setting Mic for matrix: `;
    try {
      logger.debug(msg, id);
      if (!id) return;
      if (this.savedDevices.audio === id) return;
      this.getClient().getMediaHandler().setAudioInput(id);
      this.savedDevices.audio = id;
      this._upgradeCallStream(CallMediaType.audio, id);
    } catch (e) {
      logger.error(msg, e);
    }
  }

  setMatrixSpeaker(id: any) {
    const msg = `${this.module}: P2P Call | Setting Speaker for matrix: `;
    try {
      logger.debug(msg, id);
      if (!id) return;
      if (this.savedDevices.speaker === id) return;
      this.savedDevices.speaker = id;
      if (this.callClient) {
        this._playRemoteAudio();
      }
    } catch (e) {
      logger.error(msg, e);
    }
  }

  setMatrixVideo(id: any) {
    const msg = `${this.module}: P2P Call | Setting Video for matrix: `;
    try {
      logger.debug(msg, id);
      if (!id) return;
      if (this.savedDevices.video === id) return;
      this.getClient().getMediaHandler().setVideoInput(id);
      this.savedDevices.video = id;
      this._upgradeCallStream(CallMediaType.video, id);
    } catch (e) {
      logger.error(msg, e);
    }
  }

  createMatrixCallClient(opts: { roomId: any; options: any }) {
    const msg = `${this.module}: P2P Call | CreateMatrix Call Client: `;
    try {
      logger.debug(msg, opts);
      const { roomId, options } = opts;
      this.callClient = sdk.createNewMatrixCall(this.getClient(), roomId, options);
      if (!this.callClient) {
        throw new Error(`Missing call in ${msg}`);
      }
      this.callClient.on(CallEvent.State, (state: CallState, prevState?: CallState | undefined) => {
        logger.log(`=== ${prevState} -> ${state}`);
      });

      return this.callClient;
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  deleteMatrixCallClient(matrixCall: MatrixCall) {
    const msg = `${this.module}: P2P Call | Deleting Matrix Call Client: `;
    try {
      const { callId } = matrixCall;
      logger.debug(msg, callId);
      delete this.savedCallInvites[callId];
      if (this.callClient && this.callClient.callId === callId) {
        this.callClient = null;
      }
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  incomingCallInvite(matrixCall: MatrixCall) {
    const msg = `${this.module}: P2P Call | Incoming Matrix Call Invite: `;
    try {
      const { callId } = matrixCall;
      logger.log(msg, callId);

      this.savedCallInvites[callId] = this.savedCallInvites[callId] || matrixCall;
      return matrixCall;
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  async answerCallInvite(opts: { matrixCall: MatrixCall }) {
    const msg = `${this.module}: P2P Call | Answer Matrix Call Invite: `;
    try {
      const { matrixCall } = opts;
      const { callId } = matrixCall;
      logger.debug(msg, callId);
      this.callClient = matrixCall;
      if (!this.callClient) {
        throw new Error(`Missing call in ${msg}`);
      }
      await this.callClient.answer();
      delete this.savedCallInvites[callId];
      for (let i in this.savedCallInvites) {
        this.rejectCallInvite(this.savedCallInvites[i]);
      }
      this.isVideoMuted = this.callClient.type === CallType.Voice;
      return this.callClient;
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  rejectCallInvite(matrixCall: MatrixCall) {
    const msg = `${this.module}: P2P Call | Reject Matrix Call Invite: `;
    try {
      const { callId } = matrixCall;
      logger.debug(msg, callId);
      matrixCall.reject();
      delete this.savedCallInvites[callId];
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  async placeMatrixCall(opts: { data: any; type: any }) {
    const msg = `${this.module}: P2P Call | Place Matrix Call: `;
    try {
      const { type } = opts;
      logger.log(msg, type);

      if (!this.callClient) {
        throw new Error(`Missing call in ${msg}`);
      }

      if (type === CallType.Video) {
        await this.callClient.placeVideoCall();
      } else {
        this.isVideoMuted = true;
        await this.callClient.placeVoiceCall();
      }
    } catch (e) {
      logger.error(msg, e.message);
    }
  }
  private async placeScreenSharingCallWithoutSDPStreamMetadata() {
    logger.warn("Call opponent does not support SDPStreamMetadata, using localusermedia");
    if (this.callClient && this.callClient.localUsermediaStream) {
      // Generate appropriate media constraint depending on the device change type
      const constraint = this._getUserMediaContraints(CallMediaType.screenshare, undefined);
      // Generate a new stream based on the new device
      const newDeviceStream = await navigator.mediaDevices.getDisplayMedia(constraint);

      // Add the audio tracks to the new media stream because the screenshare media don't have audio
      const audioTracks = this.callClient.localUsermediaStream!.clone().getAudioTracks();
      audioTracks.forEach((track) => {
        if (track.kind === "audio") {
          newDeviceStream.addTrack(track);
        }
      });
      // update the localUsermediaStream on the matrix
      this.callClient.updateLocalUsermediaStream(newDeviceStream, undefined, true).then(() => {
        setTimeout(() => this.sendDataChannnelMessages(MatrixDataChannelMsgs.unmute), 1000);
      });
      this._setLocalVideoElement();
      this.inScreenshare = true;
      return this.callClient.localUsermediaStream;
    }
    return null;
  }

  async placeScreenSharingCall() {
    try {
      logger.debug(`${this.module}: P2P Call | Place Matrix Screenshare`);
      if (this.callClient) {
        // validates if the other user don't support SDPStreamMetadata,
        // and in that case handles localy the media streams and tracks
        if (!this.callClient.opponentSupportsSDPStreamMetadata()) {
          return await this.placeScreenSharingCallWithoutSDPStreamMetadata();
        } else {
          this.inScreenshare = await this.callClient!.setScreensharingEnabled(true);
          if (this.inScreenshare) {
            this._addAudioTrackToStream(
              this.callClient!.localScreensharingStream,
              this.callClient!.localUsermediaStream,
              true
            );
            this.sendDataChannnelMessages(MatrixDataChannelMsgs.inRemoteScreenshare);
            this._setLocalVideoElement();
            return this.callClient!.localScreensharingStream;
          } else return null;
        }
      }
      return null;
    } catch (e) {
      throw e;
    }
  }

  private async endScreenSharingCallWithoutSDPStreamMetadata() {
    logger.warn("Call opponent does not support SDPStreamMetadata, using localusermedia");
    if (this.callClient) {
      const { audio, video } = this.savedDevices;

      // Generate appropriate media constraint depending on the device type
      const constraint = this._getUserMediaContraints(
        this.isVideoMuted ? CallMediaType.audio : CallMediaType.video,
        this.isVideoMuted ? audio : video
      );
      // Generate a new stream based on the new device
      const newDeviceStream = await navigator.mediaDevices.getUserMedia(constraint);
      const that = this;
      this.callClient
        .updateLocalUsermediaStream(newDeviceStream, undefined, this.isVideoMuted ? false : true)
        .then(async () => {
          that.inScreenshare = false;
          // update the video element
          await that._setLocalVideoElement();

          // send message to the remote user with the video status
          this.sendDataChannnelMessages(this.isVideoMuted ? MatrixDataChannelMsgs.mute : MatrixDataChannelMsgs.unmute);
        });
    }
  }
  async endScreenSharingCall() {
    try {
      logger.debug(`${this.module}: P2P Call | End Matrix Screenshare`);
      if (this.callClient) {
        // validates if the other user don't support SDPStreamMetadata,
        // and in that case handles localy the media streams and tracks
        if (!this.callClient.opponentSupportsSDPStreamMetadata()) {
          await this.endScreenSharingCallWithoutSDPStreamMetadata();
        } else {
          this.inScreenshare = await this.callClient!.setScreensharingEnabled(false, { audio: true });
          this.removeTracks(true);
          this.sendDataChannnelMessages(MatrixDataChannelMsgs.notInRemoteScreenshare);
          await this.setLocalVideoMuted(this.isVideoMuted);
        }
      }
    } catch (e) {
      throw e;
    }
  }

  async hangupMatrixCall() {
    const msg = `${this.module}: P2P Call | Matrix Call Hangup`;
    try {
      logger.debug(msg);
      this.stopMatrixCallRingTone();
      if (this.inScreenshare) await this.endScreenSharingCall();
      if (this.callClient) {
        this.callClient.hangup(CallErrorCode.UserHangup, false);
        this.callClient = null;
      }
    } catch (e) {
      logger.error(msg, e.message);
    }
  }
  private async setLocalVideoMutedWithoutSDPStreamMetadata(muted: boolean) {
    const msg = `${this.module}: P2P Call | Set Local Video Muted without SDP MetaData: `;
    logger.warn("Call opponent does not support SDPStreamMetadata, using localusermedia");
    const { video } = this.savedDevices;
    if (!this.callClient) {
      throw new Error(`Missing call in ${msg}`);
    }

    if (muted === false && video) {
      // Generate appropriate media constraint depending on the device change type
      const constraint = this._getUserMediaContraints(CallMediaType.video, video);
      // Generate a new stream based on the new device
      const newDeviceStream = await navigator.mediaDevices.getUserMedia(constraint);
      this.callClient.updateLocalUsermediaStream(newDeviceStream, undefined, true).then(() => {
        // send message to the remote user with the video status
        setTimeout(() => this.sendDataChannnelMessages(MatrixDataChannelMsgs.unmute), 1000);
      });
    } else this.sendDataChannnelMessages(MatrixDataChannelMsgs.mute); // send message to the remote user with the video status
  }

  async setLocalVideoMuted(muted: boolean) {
    const msg = `${this.module}: P2P Call | Set Local Video Muted: `;
    try {
      logger.debug(msg, muted);
      if (!this.callClient) {
        throw new Error(`Missing call in ${msg}`);
      }
      if (!this.inScreenshare) {
        // validates if the other user don't support SDPStreamMetadata,
        // and in that case handles localy the media streams and tracks
        if (!this.callClient.opponentSupportsSDPStreamMetadata()) {
          this.setLocalVideoMutedWithoutSDPStreamMetadata(muted);
        } else {
          const that = this;
          this.callClient!.setLocalVideoMuted(muted).then(async (isLocalVideoMuted) => {
            // update the video status on feeds to the right status
            // this is necessary because the setLocalVideoMuted add new video tracks when unmuted,
            // but not update the video status on the feed
            const callFeed = that.callClient?.localUsermediaFeed!;
            if (!muted && isLocalVideoMuted && callFeed) callFeed.setAudioVideoMuted(null, false);

            // // send message to the remote user with the video status
            this.sendDataChannnelMessages(muted ? MatrixDataChannelMsgs.mute : MatrixDataChannelMsgs.unmute);
          });
        }
        // stops and remove the video tracks when the video is muted, to turn off the light of the camera
        if (this.callClient && this.callClient.localUsermediaStream && muted === true) {
          this._stopTracks(this.callClient?.localUsermediaStream.getVideoTracks());
        }

        // update the video element
        await this._setLocalVideoElement();
      }
      this.isVideoMuted = muted;
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  setMicrophoneMuted(muted: boolean) {
    const msg = `${this.module}: P2P Call | Set Microphone Muted: `;
    try {
      logger.debug(msg, muted);
      if (!this.callClient?.localUsermediaStream) return;
      this.callClient.setMicrophoneMuted(muted);
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  setSpeakerMuted(muted: boolean) {
    const msg = `${this.module}: P2P Call | Set Speaker Muted: `;
    try {
      logger.debug(msg, muted);
      const { remoteElement } = this.savedCallElements;
      remoteElement.muted = muted;
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  async playMatrixCallRingTone() {
    const msg = `${this.module}: P2P Call | Place Matrix Call Ringtone`;
    try {
      logger.debug(msg);
      const { audio, stats } = this.matrixRingtone;
      const { speaker } = this.savedDevices;
      audio.volume = 0.5;
      audio.loop = true;
      audio.currentTime = 0;
      if (audio.setSinkId !== undefined && speaker) {
        await audio.setSinkId(speaker);
        logger.debug(`${this.module}: P2P Call | Set Sink Success: ${speaker}`);
        audio
          .play()
          .then(() => {
            stats.playing = true;
          })
          .catch((error) => {
            audio.load();
            audio.play();
          });
      }
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  async stopMatrixCallRingTone() {
    const msg = `${this.module}: P2P Call | Call Ringtone Pause`;
    try {
      const { audio, stats } = this.matrixRingtone;
      if (stats.playing) {
        logger.debug(msg);
        audio.pause();
        stats.playing = false;
      }
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  updateCurrentUserStatus(info: { to: any }) {
    try {
      const client = this.getClient();
      const userId = client?.getUserId();
      if (userId) {
        let currentUser = client.getUser(userId);
        if (currentUser) {
          currentUser.presenceStatusMsg = info.to;
        }
      } else {
        throw new Error("Missing userId");
      }
    } catch (e) {
      logger.error(`${this.module}: Error in updateCurrentUserStatus - info:${info} - ${e.message}`);
    }
  }

  updateOtherCurrentUserStatus(info: { userId: any; status: any }) {
    try {
      const client = this.getClient();
      let user = client.getUser(info.userId);
      //@ts-ignore
      if (user) user.presenceStatus = info.status;
      return;
    } catch (e) {
      logger.error(`${this.module}: Error in updateOtherCurrentUserStatus - info:${info} - ${e.message}`);
    }
  }

  subscribeOnMatrixCallState(emitter: any, handler: any) {
    try {
      const eventNames = ["hangup", "state", "error", "feeds_changed", "datachannel"];
      let eventEmitter;
      eventNames.forEach((eventName) => {
        eventEmitter = this.eventEmitter(emitter, eventName, handler);
      });
      return eventEmitter;
    } catch (e) {
      logger.error(`${this.module}: Error in subscribeOnMatrixCallState - ${e.message}`);
    }
  }

  unsubscribeOnMatrixCallState(emitter: any) {
    try {
      const eventNames = ["hangup", "state", "error", "feeds_changed", "datachannel"];
      let eventEmitter;
      eventNames.forEach((eventName) => {
        return this.removeEventEmitter(emitter, eventName);
      });
      return eventEmitter;
    } catch (e) {
      logger.error(`${this.module}: Error in unsubscribeOnMatrixCallState - ${e.message}`);
    }
  }

  subscribeOnMatrixEvent(eventName: any, handler: any) {
    try {
      return this.eventEmitter(this.getClient(), eventName, handler);
    } catch (e) {
      logger.error(`${this.module}: Error in subscribeOnMatrixEvent - eventName:${eventName} - ${e.message}`);
    }
  }

  unsubscribeOnMatrixEvent(eventName: any) {
    try {
      return this.removeEventEmitter(this.getClient(), eventName);
    } catch (e) {
      logger.error(`${this.module}: Error in unsubscribeOnMatrixEvent - eventName:${eventName} - ${e.message}`);
    }
  }

  setCallElements(remoteElement: any, localElement: any) {
    const msg = `${this.module}: P2P Call | Set Call Elements: `;
    try {
      logger.debug(msg, {
        remoteElement,
        localElement,
      });

      if (!this.callClient) {
        throw new Error(`Missing call in ${msg}`);
      }

      if (remoteElement) {
        this.savedCallElements.remoteElement = remoteElement;
        this._setRemoteAudioElement(remoteElement);
        this._setRemoteVideoElement(remoteElement);
      }

      if (localElement) {
        this.savedCallElements.localElement = localElement;
        this._setLocalVideoElement(localElement);
      }
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  async updateCallElements(feeds?: CallFeed[]) {
    const msg = "P2P Call | Update Call Elements: ";
    try {
      logger.debug(msg, {
        feeds,
      });

      if (this.callClient) {
        if (this.inRemoteScreenshare && feeds) {
          if (this.callClient.remoteScreensharingStream) {
            this._addAudioTrackToStream(
              this.callClient.remoteScreensharingStream,
              this.callClient.remoteUsermediaFeed?.stream,
              false
            );
            // we need to set this timeout to give time to layout to switch to "strip" mode and only then show the screenshare
            setTimeout(() => CustomStyleToExtStream().UpdateElemStyleToExtStream(CallMediaType.video, false), 500);
            await this._playRemoteVideo(this.cloneRemoteScreenshareStream);
          }
        } else if (this.callClient.remoteUsermediaStream) {
          if (this.callClient.hasRemoteUserMediaAudioTrack) await this._playRemoteAudio();
          if (this.callClient.hasRemoteUserMediaVideoTrack) await this._playRemoteVideo();
        }
      }

      return this.inRemoteScreenshare;
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  createDataChannel() {
    const msg = "P2P Call | Create Data Channel: ";
    try {
      if (this.callClient && this.callClient.peerConn && this.callClient.direction === "outbound") {
        this.sendChannel = this.callClient.createDataChannel("MatrixPeerConnDataChannel", undefined);
        logger.debug(`${msg}${this.sendChannel.id}`);
      }
      return;
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  handleDataChannelEvents(dataChannel: RTCDataChannel) {
    const msg = "P2P Call | Handle Data Events: ";
    try {
      logger.debug(`${msg}${dataChannel.id}`);
      if (this.callClient && dataChannel) {
        dataChannel.onmessage = this._handleReceiveMessage;
        if (this.callClient && this.callClient.direction === "inbound") this.sendChannel = dataChannel;
        // send messages on datachannel queue
        dataChannel.onopen = () => this.sendDataChannnelMessages();
      }
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  sendDataChannnelMessages(msg?: string) {
    const log = "P2P Call | Send Data Channel Messages";
    if (this.sendChannel) {
      switch (this.sendChannel.readyState) {
        case "connecting":
          if (msg) {
            logger.debug(`${log} - Connection not open --> queueing: ${msg}`);
            this.sendChannelQueue.push(msg);
          }
          break;
        case "open":
          const openLog = `${log} - Connection open ->`;
          if (this.sendChannelQueue.length > 0) {
            logger.debug(`${openLog} send messages on queue: ${this.sendChannelQueue}`);
            this.sendChannelQueue.forEach((msg) => {
              this.sendChannel!.send(msg);
            });
            this.sendChannelQueue = [];
          }
          if (msg) {
            logger.debug(`${openLog} send new msg: ${msg}`);
            this.sendChannel.send(msg);
          }
          break;
        case "closing":
          logger.debug(`${log} - Attempted to send message while closing: ${msg}`);
          this.sendChannelQueue = [];
          break;
        case "closed":
          logger.debug(`${log} - Error! Attempt to send while connection closed.`);
          this.sendChannelQueue = [];
          break;
      }
    }
  }

  // --------------------PRIVATE METHODS ----------------------

  private _handleReceiveMessage(event: MessageEvent<any>) {
    logger.debug(`P2P Call | Handle Received Data Channel Message: ${event.data}`);
    switch (event.data) {
      case MatrixDataChannelMsgs.inRemoteScreenshare:
      case MatrixDataChannelMsgs.notInRemoteScreenshare:
        const inRemoteScreenshare = event.data === MatrixDataChannelMsgs.inRemoteScreenshare;
        if (!inRemoteScreenshare) MatrixAPI.Instance().removeTracks(false);
        MatrixAPI.Instance().setInRemoteScreenshare(inRemoteScreenshare);
        return;
      case MatrixDataChannelMsgs.mute:
      case MatrixDataChannelMsgs.unmute:
        CustomStyleToExtStream().UpdateElemStyleToExtStream(
          event.data === MatrixDataChannelMsgs.mute ? CallMediaType.audio : CallMediaType.video,
          false
        );
        return;
      default:
        return;
    }
  }

  /**
   *  Stops and remove the tracks from the local stream
   * @param {tracks} tracks to stop and remove
   */
  private _stopTracks(tracks: MediaStreamTrack[]) {
    try {
      for (const track of tracks) {
        this.callClient!.localUsermediaStream!.removeTrack(track);
        track.stop();
      }
    } catch (e) {
      logger.error(`${this.module}: Error in _stopTracks - ${e.message}`);
    }
  }

  /**
   * Add a audio track to the screenshare media stream
   * @param {screenshareStream} screenshare media stream
   * @param {stream} local or remote media stream to get the audio track
   * @param {isLocal} if is a local or remote stream
   */
  private _addAudioTrackToStream(
    screenshareStream: MediaStream | undefined,
    stream: MediaStream | undefined,
    isLocal: boolean
  ) {
    const msg = `${this.module}: P2P Call | Add track to screenshare stream: `;
    try {
      logger.debug(`${msg} screenshareStream: ${screenshareStream?.id}, stream: ${stream?.id} isLocal: ${isLocal}`);
      if (screenshareStream && stream) {
        // Create an empty media stream object (will replace the local stream)
        const newStream = screenshareStream.clone();

        const audioTracks = stream.getAudioTracks();
        audioTracks.forEach((track) => {
          if (track.kind === "audio") {
            // Add the audio tracks to the screenshare media stream
            newStream.addTrack(track);
          }
        });
        if (isLocal) this.cloneLocalScreenshareStream = newStream;
        else this.cloneRemoteScreenshareStream = newStream;
      }
      return;
    } catch (e) {
      logger.error(msg, e.message);
      throw e;
    }
  }

  /**
   * Removes all the tracks from the cloned stream
   * @param {isLocal} if is a local or remote stream
   */
  private removeTracks(isLocal: boolean) {
    const msg = `${this.module}: P2P Call | Remove audio track from the screenshare stream: `;
    try {
      const stream = isLocal ? this.cloneLocalScreenshareStream : this.cloneRemoteScreenshareStream;
      logger.debug(msg, stream?.id);
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          // remove all the tracks from the stream
          stream.removeTrack(track);
        });

        if (isLocal) this.cloneLocalScreenshareStream = undefined;
        else this.cloneRemoteScreenshareStream = undefined;
      }
    } catch (e) {
      logger.error(msg, e.message);
      throw e;
    }
  }

  private async _upgradeCallStream(type: CallMediaType, id: string) {
    const msg = `${this.module}: P2P Call | Upgrade Call stream: `;
    try {
      logger.debug(`${msg} type:${type}, id: ${id}`);
      if (!this.callClient) return;

      // Generate appropriate media constraint depending on the device change type
      const constraint = this._getUserMediaContraints(type, id);

      const audio = this.callClient.type === CallType.Voice;
      const video = this.callClient.type === CallType.Video;

      // Generate a new stream based on the new device
      const newDeviceStream = await navigator.mediaDevices.getUserMedia(constraint);

      // updateLocalUsermediaStream() will take the tracks, use them as
      // replacement and throw the stream away, so it isn't reusable
      await this.callClient.updateLocalUsermediaStream(newDeviceStream, audio, video);
    } catch (e) {
      logger.error(msg, e.message);
      throw e;
    }
  }

  private _getUserMediaContraints(type: CallMediaType, id: any) {
    const msg = `${this.module}: P2P Call | Get User Media Constraint: `;
    try {
      logger.debug(`${msg} type:${type}, id: ${id}`);
      const isWebkit = !!navigator.mediaDevices.getUserMedia;
      switch (type) {
        case CallMediaType.audio:
          const { video } = this.savedDevices;
          return {
            audio: {
              deviceId: id ? { ideal: id } : undefined,
            },
            video: this.isVideoMuted
              ? false
              : {
                  deviceId: video ? { ideal: video } : undefined,
                  width: isWebkit ? { exact: 640 } : { ideal: 640 },
                  height: isWebkit ? { exact: 360 } : { ideal: 360 },
                },
          };
        case CallMediaType.video:
          return {
            audio: {
              deviceId: id ? { ideal: id } : undefined,
            },
            video: {
              deviceId: id ? { ideal: id } : undefined,
              width: isWebkit ? { exact: 640 } : { ideal: 640 },
              height: isWebkit ? { exact: 360 } : { ideal: 360 },
            },
          };
        case CallMediaType.screenshare:
          return {
            audio: false,
            video: true,
          };
        default:
          return {};
      }
    } catch (e) {
      logger.error(msg, e.message);
    }
  }

  /**
   * Set the local video DOM element. If this call is active,
   * video will be rendered to it immediately.
   * @param {Element} The video DOM element.
   */
  private async _setLocalVideoElement(newElement?: HTMLVideoElement) {
    const msg = "P2P Call | Set Local Video Element";
    const element = newElement ? newElement : this.savedCallElements.localElement;
    logger.log(msg, element);

    if (!this.callClient) {
      throw new Error(`Missing call in ${msg}`);
    }

    if (!element && this.callClient.type !== CallType.Video) return;

    let stream =
      this.callClient!.opponentSupportsSDPStreamMetadata() && this.inScreenshare && this.cloneLocalScreenshareStream
        ? this.cloneLocalScreenshareStream
        : this.callClient.localUsermediaStream;

    if (stream) {
      element.autoplay = true;
      element.srcObject = stream;
      element.muted = true;
      try {
        await element.play();
      } catch (e) {
        logger.info("Failed to play local video element", e);
      }
    }
  }

  /**
   * Set the remote video DOM element. If this call is active,
   * the first received video-capable stream will be rendered to it immediately.
   * @param {Element} The video DOM element.
   */
  private async _setRemoteVideoElement(newElement?: HTMLVideoElement) {
    const msg = "P2P Call | Set Remote Video Element";
    const element = newElement ? newElement : this.savedCallElements.remoteElement;
    logger.log(msg, element);

    if (!this.callClient) {
      throw new Error(`Missing call in ${msg}`);
    }

    if (!element) return;

    element.autoplay = true;

    if (this.callClient.remoteUsermediaStream) {
      this._playRemoteVideo();
    }
  }

  /**
   * Set the remote audio DOM element. If this call is active,
   * the first received audio-only stream will be rendered to it immediately.
   * The audio will *not* be rendered from the remoteVideoElement.
   * @param {Element} The video DOM element.
   */
  private async _setRemoteAudioElement(newElement?: HTMLVideoElement) {
    const msg = "P2P Call | Set Remote Audio Element";
    const element = newElement ? newElement : this.savedCallElements.remoteElement;
    logger.log(msg, element);

    if (!this.callClient) {
      throw new Error(`Missing call in ${msg}`);
    }

    if (this.callClient.remoteUsermediaStream) {
      this._playRemoteAudio();
    }
  }

  private async _playRemoteAudio(stream?: MediaStream) {
    const msg = "P2P Call | Play remote audio";
    logger.log(msg);

    if (!this.callClient) {
      throw new Error(`Missing call in ${msg}`);
    }

    const { remoteElement } = this.savedCallElements;
    const { speaker } = this.savedDevices;
    let newStream = stream ? stream : this.callClient.remoteUsermediaStream;
    if (remoteElement && newStream) {
      remoteElement.muted = false;
      remoteElement.srcObject = newStream;

      try {
        if (speaker) {
          // This seems quite unreliable in Chrome, although I haven't yet managed to make a jsfiddle where
          // it fails.
          // It seems reliable if you set the sink ID after setting the srcObject and then set the sink ID
          // back to the default after the call is over
          logger.info("Setting audio sink to " + speaker + ", was " + remoteElement.sinkId);
          await remoteElement.setSinkId(speaker);
        }
      } catch (e) {
        logger.warn("Couldn't set requested audio output device: using default", e);
      }

      try {
        await remoteElement.play();
      } catch (e) {
        logger.warn("Failed to play remote audio element", e);
      }
    }
  }

  private async _playRemoteVideo(stream?: MediaStream) {
    const msg = "P2P Call | Play remote video";
    logger.log(msg);

    if (!this.callClient) {
      throw new Error(`Missing call in ${msg}`);
    }

    const { remoteElement } = this.savedCallElements;
    let newStream = stream ? stream : this.callClient.remoteUsermediaStream;
    if (remoteElement && newStream) {
      remoteElement.srcObject = newStream;

      try {
        await remoteElement.play();
      } catch (e) {
        logger.warn("Failed to play remote video element", e);
      }
    }
  }

  //  -----------------------------------------

  eventEmitter(emitter: any, eventName: any, handler: any) {
    try {
      if (!emitter) return;
      if (!eventName) return;
      const eventListener = (...args: any) => {
        handler([eventName, ...args, emitter]);
      };
      this.savedHandlers[eventName] = this.savedHandlers[eventName] || eventListener;
      return emitter.on(eventName, this.savedHandlers[eventName]);
    } catch (e) {
      logger.error(`${this.module}: Error in eventEmitter - eventName:${eventName} - ${e.message}`);
    }
  }

  removeEventEmitter(emitter: any, eventName: any) {
    try {
      if (!emitter) return;
      if (!eventName) return;
      const res = emitter.removeListener(eventName, this.savedHandlers[eventName]);
      delete this.savedHandlers[eventName];
      return res;
    } catch (e) {
      logger.error(`${this.module}: Error in removeEventEmitter - eventName:${eventName} - ${e.message}`);
    }
  }
}

export default MatrixAPI;
