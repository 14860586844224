import React, { useRef, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

const CustomChip = ({ ...props }) => {
  const { tooltip, label, isActive, ...other } = props;
  const theme = useTheme();
  const labelRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    // @ts-ignore
    const labelChild = labelRef?.current?.childNodes[0];
    if (labelChild.scrollWidth > labelChild.clientWidth) {
      setIsEllipsisActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelRef?.current]);

  return (
    <Tooltip title={tooltip ? tooltip : props.label} disableHoverListener={!isEllipsisActive} arrow>
      <Chip
        ref={labelRef}
        label={props.label}
        size="small"
        sx={{
          mx: -1,
          margin: "0 3.5px",
          width: "fit-content",
          minWidth: "50px",
          height: "24px",
          minHeight: "24px",
          fontSize: "14px",
          lineHeight: "140%",
          backgroundColor: `${
            isActive ? theme.palette.background.filterChip.selected : theme.palette.background.filterChip.notSelected
          }`,
          color: `${isActive ? theme.palette.text.filterChip.selected : theme.palette.text.filterChip.notSelected}`,
          border: `1px solid ${
            isActive ? theme.palette.border.filterChip.selected : theme.palette.border.filterChip.notSelected
          }`,
          borderRadius: "4px",
          "&:hover, &:focus": {
            backgroundColor: theme.palette.background.filterChip.selected,
            border: `1px solid ${theme.palette.border.filterChip.selected}`,
            color: theme.palette.text.filterChip.selected,
          },
        }}
        {...other}
      />
    </Tooltip>
  );
};

export default CustomChip;
