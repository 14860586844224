import {
  ICreateClientOpts,
  IContent,
  MatrixClient,
  Room,
  User,
  EventTimeline,
  EventTimelineSet,
  RoomMember,
  NotificationCountType,
  EventType,
} from "matrix-js-sdk";

export interface MatrixClientOptions extends ICreateClientOpts {
  iceCandidatePoolSize?: number;
  supportsCallTransfer?: boolean;
}

export interface LocalMatrixRoomMember {
  userId: string; // The user ID of this member.
  name: string; // The human-readable name for this room member. This will be disambiguated with a suffix of " (@user_id:matrix.org)" if another member shares the same displayname.
  membership: string | undefined; // The membership state for this room member e.g. 'join'.
}

export interface LocalMatrixRoom {
  roomId: string; // The ID of this room.
  name: string; // The human-readable display name for this room.
  tags: object; // Dict of room tags; the keys are the tag name and the values are any metadata associated with the tag - e.g. { "fav" : { order: 1 } }
  members: LocalMatrixRoomMember[];
  eventsTimestamp: number;
  type?: string;
  myUserId?: string;
  isHidden: boolean;
  description: string;
  roomURL: string;
  ownerId: string | null;
  isGroupChat: boolean;
  topic: string;
  unreadNotificationCount: number;

  getJoinedMemberCount(): number;
  getJoinedMembers(): RoomMember[];
  getLiveTimeline(): EventTimeline;
  getMyMembership(): string;
  getTimelineSets(): EventTimelineSet[];
  guessDMUserId(): string;
  recalculate(): void;
  getMembers(): RoomMember[];

  getUnreadNotificationCount(type?: NotificationCountType | undefined): number;
  getMember(userId: string): RoomMember | null;
}

export interface MatrixRoom extends Room {
  eventsTimestamp: number;
  getMembers(): RoomMember[];
  getLastActiveTimestamp(): number;
  type?: string;
}

export interface MatrixUser extends User {
  presenceStatus?: Status;
}

export interface DirectUser {
  displayName: string;
  userId: string | null;
  avatarUrl: string;
  presence?: string;
  presenceStatusMsg?: string;
  presenceStatus?: Status;
}

export interface MappedRoomList {
  [key: string]: LocalMatrixRoom[];
}

export interface MatrixMember {
  userId: string;
  displayName: string;
  avatarUrl: string | undefined;
  presence: string;
  presenceStatus: string;
  presenceStatusMsg: string | undefined;
  membership: MembershipType | undefined;
}

export interface SearchData {
  roomId: string;
  userId: string;
  displayName: string;
  avatarUrl: string | undefined;
  isRoom: boolean;
  isPrivateRoom: boolean;
  presenceStatus: string;
  presenceStatusMsg: string;
  email?: string;
  hasExistingRoom?: boolean;
}

export interface MxClient extends MatrixClient {
  create(): void;
}

// Enums imported from https://github.com/matrix-org/matrix-js-sdk/
export const LocalEventTypeEnum = {
  ...EventType,
  // custom events
  InternalMessage: "com.enghouse.enghouseuc.internal_message",
} as const;

// create the LocalEventType type
export type LocalEventType = (typeof LocalEventTypeEnum)[keyof typeof LocalEventTypeEnum];

export enum EventStatus {
  /** The event was not sent and will no longer be retried. */
  NOT_SENT = "not_sent",

  /** The message is being encrypted */
  ENCRYPTING = "encrypting",

  /** The event is in the process of being sent. */
  SENDING = "sending",

  /** The event is in a queue waiting to be sent. */
  QUEUED = "queued",

  /** The event has been sent to the server, but we have not yet received the echo. */
  SENT = "sent",

  /** The event was cancelled before it was successfully sent. */
  CANCELLED = "cancelled",
}

export enum SyncState {
  /** Emitted after we try to sync more than `FAILED_SYNC_ERROR_THRESHOLD`
   * times and are still failing. Or when we enounter a hard error like the
   * token being invalid. */
  Error = "ERROR",
  /** Emitted after the first sync events are ready (this could even be sync
   * events from the cache) */
  Prepared = "PREPARED",
  /** Emitted when the sync loop is no longer running */
  Stopped = "STOPPED",
  /** Emitted after each sync request happens */
  Syncing = "SYNCING",
  /** Emitted after a connectivity error and we're ready to start syncing again */
  Catchup = "CATCHUP",
  /** Emitted for each time we try reconnecting. Will switch to `Error` after
   * we reach the `FAILED_SYNC_ERROR_THRESHOLD`
   */
  Reconnecting = "RECONNECTING",
}

export enum InternalMessageContentType {
  Transfer = "transfer",
  RoomChangeHidden = "room.hidden",
  AdHocInvite = "adhoc_invite",
  SipConferenceInitialize = "sipConferenceInitialize",
  SipConferenceJoin = "sipConferenceJoin",
  SipConferenceLeave = "sipConferenceLeave",
}

export enum MsgType {
  Text = "m.text",
  Emote = "m.emote",
  Notice = "m.notice",
  Image = "m.image",
  File = "m.file",
  Audio = "m.audio",
  Location = "m.location",
  Video = "m.video",
  KeyVerificationRequest = "m.key.verification.request",
}

export enum ReceiptType {
  Read = "m.read",
  FullyRead = "m.fully_read",
  ReadPrivate = "org.matrix.msc2285.read.private",
}

export enum Visibility {
  Public = "public",
  Private = "private",
}

export enum Preset {
  PrivateChat = "private_chat",
  TrustedPrivateChat = "trusted_private_chat",
  PublicChat = "public_chat",
}

export enum RelationType {
  Annotation = "m.annotation",
  Replace = "m.replace",
  /**
   * Note, "io.element.thread" is hardcoded
   * Should be replaced with "m.thread" once MSC3440 lands
   * Can not use `UnstableValue` as TypeScript does not
   * allow computed values in enums
   * https://github.com/microsoft/TypeScript/issues/27976
   */
  Thread = "io.element.thread",
}

export enum Membership {
  never = "never",
  ban = "ban",
  invite = "invite",
  join = "join",
  leave = "leave",
}

export type MembershipType = keyof typeof Membership;

export enum StatusValues {
  status = "status",
  online = "online",
  available = "available",
  busy = "busy",
  call = "call",
  conf = "conf",
  dnd = "dnd",
  presenting = "presenting",
  away = "away",
  idle = "idle",
  unavailable = "unavailable",
  offline = "offline",
}

export type Status = keyof typeof StatusValues;

export interface IEventRelation {
  rel_type: RelationType | string;
  event_id: string;
  key?: string;
}

export interface Mx_IContent extends IContent {
  is_direct?: boolean;
}

export interface Mx_ITagMetadata {
  [key: string]: any;
  order: number;
}

export interface IInvite3PID {
  id_server: string;
  id_access_token?: string; // this gets injected by the js-sdk
  medium: string;
  address: string;
}

export interface ICreateRoomStateEvent {
  type: string;
  state_key?: string; // defaults to an empty string
  content: IContent;
}

export interface ICreateRoomOpts {
  room_alias_name?: string;
  visibility?: Visibility;
  name?: string;
  topic?: string;
  preset?: Preset;
  power_level_content_override?: object;
  creation_content?: object;
  initial_state?: ICreateRoomStateEvent[];
  invite?: string[];
  invite_3pid?: IInvite3PID[];
  is_direct?: boolean;
  room_version?: string;
}

export interface DevicesSettings {
  speakerMuted: boolean;
  microMuted?: boolean;
  videoMuted?: boolean;
}

export enum AdHocInviteType {
  conf = "conf",
  chat = "chat",
}

export enum AdHocActions {
  join = "Join",
  reply = "Reply",
}

export enum MatrixRoomTopics {
  description = "description",
  isHidden = "isHidden",
}

export enum MatrixRoomTypes {
  direct = "direct",
  private = "private",
  public = "public",
  favorites = "favorites",
  contacts = "contacts",
  invite = "invite",
}

export enum MatrixDataChannelMsgs {
  mute = "mute",
  unmute = "unmute",
  inRemoteScreenshare = "inRemoteScreenshare",
  notInRemoteScreenshare = "notInRemoteScreenshare",
}

export interface TransferInternalMessageBody {
  userExtension: string;
  isNewRoom: boolean;
}

export interface RoomChangeHiddenInternalMessageBody {
  ownerId: string;
  roomId: string;
  isHidden: boolean;
}

export interface ConferenceInitializeMessageBody {
  ownerExtension: string;
  conferenceId: string;
  userExtensions: string[];
  isNewRoom: boolean;
}

export interface ConferenceJoinMessageBody {
  conferenceId: string;
  userExtension: string;
  isNewRoom: boolean;
}

export interface ConferenceLeaveMessageBody {
  conferenceId: string;
  userExtension: string;
  isNewRoom: boolean;
}

export interface RoomInContacts {
  name: string;
  id: string;
  portalLink: string;
  shareURL: string;
  isHidden: boolean;
}

export interface MatrixSendMessageError {
  eventId: string;
  message: string;
}

export interface MatrixContent extends IContent {
  type?: any;
  body?: any;
}

export interface AdHocInviteDate {
  title: string;
  message: string;
  action: (choice: string) => void;
  close: () => void;
  type: string;
}

export enum RoomListFilters {
  all = "ALL",
  favorites = "FAVORITES",
  rooms = "ROOMS",
  conversations = "CONVERSATIONS",
}
