import React, { useState, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useConferenceModeration } from "hooks";

import { useTheme } from "@mui/material/styles";
import styled from "themes/theme/baseTheme";
import { getLogger } from "logger/appLogger";
import { List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider } from "@mui/material";
import Switch from "components/_shared/Switch";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";

const ListItemsStyle = {
  paddingLeft: "31px",
  paddingRight: "20px",
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  ...ListItemsStyle,
  paddingTop: "2px",
  paddingBottom: "2px",
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  ...ListItemsStyle,
  paddingTop: "4px",
  paddingBottom: "4px",
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "24px",
  marginRight: "10px",
}));

type ModerationCallProps = {
  participantId: number;
};

export const ModerationCall = ({ participantId }: ModerationCallProps) => {
  const logger = useMemo(() => getLogger("conference-moderation"), []);
  const module = "ModerationCall";
  const DEBUG = false;

  const { t } = useTranslation();
  const theme = useTheme();

  const {
    isRoomOwner,
    conferenceModerationError,
    getRoomInfo,

    muteAllMicrophones,
    pendingOperationMuteAllMicrophones,

    muteAllCameras,
    pendingOperationMuteAllCameras,

    disableAllMicrophones,
    enableAllMicrophones,
    pendingOperationAllEnableDisableMicrophones,

    disableAllCameras,
    enableAllCameras,
    pendingOperationAllEnableDisableCameras,
  } = useConferenceModeration();

  const [stateAllMicrophonesDisabled, setStateAllMicrophonesDisabled] = useState(false);
  const [stateAllCamerasDisabled, setStateAllCamerasDisabled] = useState(false);

  const [pendingOperationGetRoomInfo, setPendingOperationGetRoomInfo] = useState(true);

  const disableAllMicrophonesPending = () => pendingOperationAllEnableDisableMicrophones || pendingOperationGetRoomInfo;
  const disableAllCamerasPending = () => pendingOperationAllEnableDisableCameras || pendingOperationGetRoomInfo;

  const disableOrEnableAllMicrophones = (event: { target: { checked: any } }) => {
    const value = event.target.checked;
    if (value === "undefined") return;
    if (stateAllMicrophonesDisabled) enableAllMicrophones();
    else disableAllMicrophones(participantId);
    setStateAllMicrophonesDisabled(!stateAllMicrophonesDisabled);
  };

  const disableOrEnableAllCameras = (event: { target: { checked: any } }) => {
    const value = event.target.checked;
    if (value === "undefined") return;
    if (stateAllCamerasDisabled) enableAllCameras();
    else disableAllCameras(participantId);
    setStateAllCamerasDisabled(!stateAllCamerasDisabled);
  };

  useEffect(() => {
    if (conferenceModerationError) {
      logger.warn(`${module}: error: ${conferenceModerationError}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conferenceModerationError]);

  useEffect(() => {
    const fetchData = async () => {
      const roomInfo = await getRoomInfo();
      setStateAllMicrophonesDisabled(roomInfo?.roomDetails.audio);
      setStateAllCamerasDisabled(roomInfo?.roomDetails.video);
      setPendingOperationGetRoomInfo(false);
    };
    fetchData().catch((e) => setPendingOperationGetRoomInfo(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ui = () => {
    return (
      <>
        <List
          component="div"
          disablePadding
          sx={{
            width: "100%",
            marginBottom: "10px",
            userSelect: "none",
            "-webkit-user-select": "none",
          }}
        >
          <StyledListItemButton
            onClick={muteAllMicrophones}
            disabled={pendingOperationMuteAllMicrophones}
            data-test-id="MUTE_ALL_MICROPHONES_BUTTON"
          >
            <StyledListItemIcon>
              <MicIcon />
            </StyledListItemIcon>
            <ListItemText primary={t("MUTE_ALL_MICROPHONES")} primaryTypographyProps={{ variant: "body1" }} />
          </StyledListItemButton>
          <StyledListItemButton
            onClick={muteAllCameras}
            disabled={pendingOperationMuteAllCameras}
            data-test-id="TURN_OFF_ALL_CAMERAS_BUTTON"
          >
            <StyledListItemIcon>
              <VideocamIcon />
            </StyledListItemIcon>
            <ListItemText primary={t("TURN_OFF_ALL_CAMERAS")} primaryTypographyProps={{ variant: "body1" }} />
          </StyledListItemButton>
          <StyledListItem>
            <StyledListItemIcon>
              <MicOffIcon />
            </StyledListItemIcon>
            <Switch
              color="primary"
              checked={stateAllMicrophonesDisabled}
              onChange={(event: any) => disableOrEnableAllMicrophones(event)}
              name="disable_all_micro"
              label={t("DISABLE_ALL_MICROPHONES")}
              variant="body1"
              data-test-id="DISABLE_ALL_MICROPHONES_SWITCH"
              disabled={disableAllMicrophonesPending()}
            />
          </StyledListItem>
          <StyledListItem>
            <StyledListItemIcon>
              <VideocamOffIcon />
            </StyledListItemIcon>
            <Switch
              color="primary"
              checked={stateAllCamerasDisabled}
              onChange={(event: any) => disableOrEnableAllCameras(event)}
              name="disable_all_cameras"
              label={t("DISABLE_ALL_CAMERAS")}
              variant="body1"
              data-test-id="DISABLE_ALL_CAMERAS_SWITCH"
              disabled={disableAllCamerasPending()}
            />
          </StyledListItem>
        </List>
        {DEBUG && (
          <List
            component="div"
            disablePadding
            sx={{ width: "100%", userSelect: "none", "-webkit-user-select": "none" }}
          >
            <Divider style={{ backgroundColor: theme.palette.background.default }} />
            <StyledListItemButton onClick={getRoomInfo}>
              <ListItemText primary="INFO" primaryTypographyProps={{ variant: "body1" }} />
            </StyledListItemButton>
          </List>
        )}
      </>
    );
  };

  return isRoomOwner ? ui() : null;
};

export default ModerationCall;
