import OutlookProvider from "./providers/OutlookProvider";
import GoogleProvider from "./providers/GoogleProvider";
import { CalendarEvent } from "./types/CalendarEvent";
import moment from "moment";
import { getFormattedDateShort, isValidConferenceLink } from "utils/helpers";
import { getMeetingHours, getFormattedDate } from "utils/helpers";
import i18n from "translations/i18n";
import { RoomInfo } from "services/ProvisioningApiProvider/types/Rooms";

let outlookAuthorized: boolean = false;
let googleAuthorized: boolean = false;

const createMeetingBody = (location: string, extension: string, userName: string) => {
  return `Join ${userName}'s video & audio conference by clicking the following link:<br/><br/>
          <a href="${location}" rel="noreferrer" target="_blank" alt="{data.location}">
              ${location}
          </a>
          <br/><br/>
          If this is your first time using Enghouse Vidyo, you will be asked to download the client.<br/><br/>
          Alternatively, you can call into the meeting via phone:<br/>
          US Toll-Free  +1 8003813252,,${extension}#<br/> 
          US Local  +1 2018628769,,${extension}#<br/>
          Enghouse Toronto - +1 4378883132,,${extension}#<br/>
          Enghouse UK - +44 2035141775,,${extension}#<br/>
          Enghouse Germany - +49 32221092129,,${extension}#<br/><br/>
          Or join using an H.323 or SIP endpoint with the meeting ID <b>${extension}#</b><br/><br/>
          Americas:  35.192.195.195`;
};

const createMeetingSubject = (userName: string) => {
  return `Meeting Invitation by ${userName}`;
};

export const createMeetingRoomName = (userName: string) => {
  return `Meeting by ${userName}`;
};

const formatAllDayEventDate = (start: string, end: string) => {
  let multipleDays = 0;
  let meetingHours;

  const startDateTime = moment(start);
  const startDate = getFormattedDateShort(startDateTime, i18n.language);

  const endTimeMinusOneDay = moment(end).subtract(1, "days");
  const endDate = getFormattedDateShort(endTimeMinusOneDay, i18n.language);
  let fullDate = `${startDate}`;

  if (moment(end).diff(startDateTime, "days") > 1) {
    fullDate += ` - ${endDate}`;
    multipleDays = moment(end).diff(startDateTime, "days");
  }
  meetingHours = "";
  return {
    dayOfMonth: startDateTime.format("D"),
    dayOfWeek: startDateTime.format("ddd").toUpperCase(),
    timestamp: startDateTime.format("X"),
    fullDate,
    meetingHours,
    time: `${startDateTime.format("LT")} - ${endTimeMinusOneDay.format("LT")}`,
    multipleDays,
  };
};

const formatEventDate = (start: string, end: string, isAllDay: boolean) => {
  if (isAllDay) {
    // special case for calender entries spreading over several days
    return formatAllDayEventDate(start, end);
  }
  const localStartTimeDate = moment.utc(start).toDate();
  const localStartTime = moment(localStartTimeDate);
  const localEndTimeDate = moment.utc(end).toDate();
  const localEndTime = moment(localEndTimeDate);
  let localStartDate = getFormattedDate(localStartTime);

  let fullDate = `${localStartDate}`;
  let multipleDays = 0;
  let meetingHours = getMeetingHours(start, end, i18n.language);

  return {
    dayOfMonth: localStartTime.format("D"),
    dayOfWeek: localStartTime.format("ddd").toUpperCase(),
    timestamp: localStartTime.format("X"),
    fullDate,
    meetingHours,
    time: `${localStartTime.format("LT")} - ${localEndTime.format("LT")}`,
    multipleDays,
  };
};

const getLocation = (value: any) => {
  return value.constructor === String ? value : value.displayName;
};

export const checkIfAuthorized = async (caller: string) => {
  outlookAuthorized = await OutlookProvider.isAuthorized(`provider/${caller}`);
  googleAuthorized = GoogleProvider.isAuthorized();
  return outlookAuthorized || googleAuthorized;
};

export const getCreateMeetingUrl = (createdRoom: RoomInfo, userName: string) => {
  const roomURL = createdRoom.share_url ? createdRoom.share_url : createdRoom.room_url;
  const subject = createMeetingSubject(userName);
  const body = createMeetingBody(roomURL, createdRoom.extension, userName);
  const location = roomURL;

  if (outlookAuthorized) {
    return OutlookProvider.getCreateUrl(subject, body, location);
  } else if (googleAuthorized) {
    return GoogleProvider.getCreateUrl(subject, body, location);
  }
  return null;
};

const calculateHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36); // to uint to remove minus prefix and radix 36
};

const calculateEventHash = (event: CalendarEvent) => {
  // only start/end date and subject
  let compact = JSON.stringify(event.date) + event?.subject;
  if (event.isOutlook) {
    compact += event.lastModifiedDateTime;
  }
  return calculateHash(JSON.stringify(compact));
};

export const getCalendarEvents = async (): Promise<CalendarEvent[]> => {
  const outlookEvents = await OutlookProvider.events();
  const googleEvents = await GoogleProvider.events();
  const allEvents = [...outlookEvents, ...googleEvents];

  for (const event of allEvents) {
    const { dayOfMonth, dayOfWeek, time, timestamp, fullDate, meetingHours, multipleDays } = formatEventDate(
      event.start.dateTime,
      event.end.dateTime,
      event.isAllDay
    );
    event.date = {
      dayOfMonth,
      dayOfWeek,
      timestamp,
      fullDate,
      meetingHours,
      time,
      multipleDays,
    };

    event.body = event.body?.content || event.bodyPreview || event.description;
    event.attachments = event.attachments || [];
    event.location = getLocation(event.location || "");
    event.subject = event.subject || event.summary;
    event.isOutlook = event["@odata.etag"] != null;
    event.hasMeetingLink = isValidConferenceLink(event.location);
    event.hash = calculateEventHash(event);
  }
  const momentNow = moment();

  const allDayEvents = allEvents
    .map((event: CalendarEvent) => {
      let startTime = moment(event.start.dateTime);
      if (event.isAllDay) {
        let allDayEvent: CalendarEvent[] = [];

        if (startTime.isSameOrAfter(momentNow, "day")) {
          allDayEvent = [{ ...event }];
        } else {
        }
        if (event.date.multipleDays > 1) {
          for (let i = 1; i < event.date.multipleDays; i++) {
            startTime = moment(startTime).add(1, "days");

            if (startTime.isBefore(momentNow, "day")) {
              continue;
            }
            allDayEvent.push({
              ...event,
              date: {
                ...event.date,
                dayOfMonth: moment(startTime).format("D"),
                dayOfWeek: moment(startTime).format("D"),
                timestamp: moment(startTime).format("X"),
              },
            });
          }
        }

        return allDayEvent;
      } else {
        const localEndTime = moment.utc(event.end.dateTime);
        if (momentNow.isBefore(localEndTime)) {
          return event;
        } else return [];
      }
    })
    .flat();

  return allDayEvents.sort((a, b) => {
    const timestampA = parseInt(a.date.timestamp, 10);
    const timestampB = parseInt(b.date.timestamp, 10);
    if (timestampA === timestampB) {
      //check starting date
      return a.start.dateTime < b.start.dateTime ? -1 : 1;
    } else return timestampA - timestampB;
  });
};

export const getNextEvent = (events: CalendarEvent[]) => {
  const momentNow = moment();

  const currentTimed = events.find((event) => {
    const localStartTime = moment.utc(event.start.dateTime).toDate();
    const localEndTime = moment.utc(event.end.dateTime).toDate();
    return !event.isAllDay && momentNow.isBetween(localStartTime, localEndTime) && !event.isCancelled;
  });
  if (currentTimed) {
    currentTimed.type = "CURRENT_EVENT";
  }

  const currentAllDay = events.find((event) => {
    const localStartTime = moment.utc(event.start.dateTime).toDate();
    const localEndTime = moment.utc(event.end.dateTime).toDate();
    return event.isAllDay && momentNow.isBetween(localStartTime, localEndTime) && !event.isCancelled;
  });
  if (currentAllDay) {
    currentAllDay.type = "CURRENT_EVENT";
  }

  // all matching events later but no allday
  let nextTodayTimed = events.find((event) => {
    const localStartTime = moment.utc(event.start.dateTime).toDate();
    const momentLocalStartTime = moment(localStartTime);
    return (
      !event.isAllDay && momentLocalStartTime.isAfter() && momentNow.isSame(localStartTime, "d") && !event.isCancelled
    );
  });
  if (nextTodayTimed) {
    nextTodayTimed.type = "NEXT_EVENT";
  }

  // desperate, any event in the future is good enough
  const nextAny = events.find((event) => {
    const localStartTime = moment.utc(event.start.dateTime).toDate();
    return moment(localStartTime).isAfter();
  });
  if (nextAny) {
    nextAny.type = "NEXT_EVENT";
  }

  return currentTimed || nextTodayTimed || currentAllDay || nextAny;
};
