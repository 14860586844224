import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { useTranslation } from "react-i18next";
import styled from "themes/theme/baseTheme";

const StyledButton = styled(Button)(({ theme }) => ({
  justifyContent: "flex-start",
  textTransform: "none",
}));

export interface RecordingLinkDialogProps {
  recordingUrl: string;
  openLinkDialog: boolean;
  handleCloseLinkDialog: () => void;
  handleOpenBrowserLinkDialog: () => void;
}

const RecordingLinkDialog = ({
  recordingUrl,
  openLinkDialog,
  handleCloseLinkDialog,
  handleOpenBrowserLinkDialog,
}: RecordingLinkDialogProps) => {
  const { t } = useTranslation();
  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(recordingUrl);
  };

  return (
    <Dialog onClose={handleCloseLinkDialog} aria-labelledby="upload confirmation" open={openLinkDialog}>
      <DialogTitle id="simple-dialog-title">{t("RECORDING_READY_TITLE")}</DialogTitle>
      <DialogContent>
        <StyledButton
          id="recording_link_dialog_copy_link_button"
          onClick={copyLinkToClipboard}
          disableFocusRipple
          disableRipple
          disableTouchRipple
          variant="text"
        >
          {t("LINK")}
        </StyledButton>
        <StyledButton
          id="recording_link_dialog_open_button"
          onClick={handleOpenBrowserLinkDialog}
          disableFocusRipple
          disableRipple
          disableTouchRipple
          variant="text"
        >
          {t("OPEN_IN_BROWSER")}
        </StyledButton>
        <StyledButton
          id="recording_link_dialog_close_button"
          onClick={handleCloseLinkDialog}
          disableFocusRipple
          disableRipple
          disableTouchRipple
          variant="text"
        >
          {t("CANCEL")}
        </StyledButton>
      </DialogContent>
    </Dialog>
  );
};

export default RecordingLinkDialog;
