import React from "react";
import { Tooltip } from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";

interface CustomIconButtonProps extends IconButtonProps {
  tooltip?: string;
  tooltipPlacement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

export type Ref = HTMLButtonElement;

const CustomIconButton = React.forwardRef<Ref, CustomIconButtonProps>((props, ref) => {
  const { tooltip, tooltipPlacement, ...other } = props;
  return (
    <Tooltip title={tooltip ?? ""} placement={tooltipPlacement ?? "bottom"} arrow>
      {props.disabled ? (
        <span>
          <IconButton {...other} />
        </span>
      ) : (
        <IconButton {...other} />
      )}
    </Tooltip>
  );
});

export default CustomIconButton;
