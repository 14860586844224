import { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import "./AdHocNotification.scss";
import { AdHocActions, AdHocInviteType } from "types/Matrix";
import Button from "@mui/material/Button";

interface AdHocNotificationProps {
  data: {
    title: string;
    message: string;
    action: (choice: AdHocActions) => void;
    close: () => void;
    type: AdHocInviteType;
  };
}

const AdHocNotification = ({ data }: AdHocNotificationProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const action = (choice: AdHocActions) => {
    if (data.action) {
      data.action(choice);
    }
  };

  const onClose = () => {
    if (data.close) {
      data.close();
    }
  };

  useEffect(() => {
    setTimeout(onClose, 30000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) {
    return null;
  } else {
    return (
      <div
        className="adhoc-notification-container"
        style={{ backgroundColor: theme.palette.background.dark, color: theme.palette.common.white }}
      >
        <div className="adhoc-notification-content">
          <div className="title">
            <span>{data.title}</span>
          </div>
          <div className="message">
            <span>{data.message}</span>
          </div>
        </div>
        <div className={`adhoc-notification-actions ${data.type}`}>
          {data.type === AdHocInviteType.conf && (
            <Button variant="contained" onClick={() => action(AdHocActions.join)} sx={{ marginBottom: "8px" }}>
              {t("JOIN")}
            </Button>
          )}
          <Button
            variant={data.type === AdHocInviteType.chat ? "contained" : "outlined"}
            onClick={() => action(AdHocActions.reply)}
          >
            {t("REPLY")}
          </Button>
          <Button variant="outlined" onClick={onClose} sx={{ marginTop: "8px" }}>
            {t("CLOSE")}
          </Button>
        </div>
      </div>
    );
  }
};

export default AdHocNotification;
