import { all } from "redux-saga/effects";
import camerasActionWatcher from "./sagas/devices/cameras";
import microphonesActionWatcher from "./sagas/devices/microphones";
import speakersActionWatcher from "./sagas/devices/speakers";
import callActionWatcher from "./sagas/call";
import appActionWatcher from "./sagas/app";
import configActionWatcher from "./sagas/config";
import provisioningActionWatcher from "./sagas/provisioning";
import matrixActionWatcher from "./sagas/matrix";
import presenceActionWatcher from "./sagas/presence";
import sipActionWatcher from "./sagas/sip";
import pbxActionWatcher from "./sagas/pbx";

function* devicesActionWatcher() {
  yield all([
    camerasActionWatcher(),
    microphonesActionWatcher(),
    speakersActionWatcher(),
  ]);
}

export default function* rootSaga() {
  yield all([
    appActionWatcher(),
    callActionWatcher(),
    devicesActionWatcher(),
    configActionWatcher(),
    provisioningActionWatcher(),
    matrixActionWatcher(),
    presenceActionWatcher(),
    sipActionWatcher(),
    pbxActionWatcher(),
  ]);
}
