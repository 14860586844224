import { Components } from "@mui/material";

const MuiDialogContent: Components["MuiDialogContent"] = {
  styleOverrides: {
    root: {
      padding: "16px 16px !important",
    },
  },
};

export default MuiDialogContent;
