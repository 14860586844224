import { getLogger } from "logger/appLogger";
import { MatrixEvent } from "matrix-js-sdk";
import { useCallback, useEffect, useMemo, useState } from "react";

export interface MessageStorageDataType {
  roomId: string;
  message: string;
  edit: MatrixEvent | null;
  reply: MatrixEvent | null;
  forward: MatrixEvent | null;
}

export interface MessageStorageHookType {
  saveMessageText(message: string): void;
  saveEditMessage(edit: MatrixEvent): void;
  saveReplyMessage(reply: MatrixEvent): void;
  saveForwardMessage(forward: MatrixEvent): void;
  savedMessage: string;
  editMessageData: MatrixEvent | null;
  replyMessageData: MatrixEvent | null;
  forwardMessageData: MatrixEvent | null;
}

export const useMessageStorage = (roomId: string): MessageStorageHookType => {
  const logger = useMemo(() => getLogger("message-storage"), []);

  const [savedMessage, setSavedMessage] = useState<string>("");
  const [editMessageData, setEditMessageData] = useState<MatrixEvent | null>(null);
  const [replyMessageData, setReplyMessageData] = useState<MatrixEvent | null>(null);
  const [forwardMessageData, setForwardMessageData] = useState<MatrixEvent | null>(null);

  const createMessageStorageDataType = (roomId: string): MessageStorageDataType => {
    return {
      roomId,
      message: "",
      edit: null,
      reply: null,
      forward: null,
    };
  };

  const getStoredMessageData = useCallback((): MessageStorageDataType => {
    let storedMessage = window.messageStore.find((ms) => ms.roomId === roomId);
    logger.debug(`getStoredMessageData roomId:${roomId} ${storedMessage ? `message:${storedMessage.message}` : ""}`);
    if (!storedMessage) {
      storedMessage = createMessageStorageDataType(roomId);
      window.messageStore.push(storedMessage);
    }
    return storedMessage;
  }, [logger, roomId]);

  const saveMessageText = (message: string): void => {
    logger.debug(`saveMessageText roomId:${roomId} message:${message}`);
    if (message !== savedMessage) {
      setSavedMessage(message);
      const messageStoreIndex = window.messageStore.findIndex((ms) => ms.roomId === roomId);
      if (messageStoreIndex >= 0) {
        window.messageStore[messageStoreIndex] = { ...window.messageStore[messageStoreIndex], message };
      } else {
        window.messageStore.push({ ...createMessageStorageDataType(roomId), message });
      }
    }
  };

  const saveEditMessage = (edit: MatrixEvent | null): void => {
    logger.debug(`saveEditMessage roomId:${roomId} editMessageData:${edit}`);
    // save locally
    setEditMessageData(edit);
    // save to messageStore memory
    const messageStoreIndex = window.messageStore.findIndex((ms) => ms.roomId === roomId);
    if (messageStoreIndex >= 0) {
      window.messageStore[messageStoreIndex] = {
        ...window.messageStore[messageStoreIndex],
        edit,
      };
    } else {
      window.messageStore.push({ ...createMessageStorageDataType(roomId), edit });
    }
  };

  const saveReplyMessage = (reply: MatrixEvent | null): void => {
    logger.debug(`savewReplyMessage roomId:${roomId} replyMessageData:${reply}`);
    // save locally
    setReplyMessageData(reply);
    // save to messageStore memory
    const messageStoreIndex = window.messageStore.findIndex((ms) => ms.roomId === roomId);
    if (messageStoreIndex >= 0) {
      window.messageStore[messageStoreIndex] = {
        ...window.messageStore[messageStoreIndex],
        reply,
      };
    } else {
      window.messageStore.push({ ...createMessageStorageDataType(roomId), reply });
    }
  };

  const saveForwardMessage = (forward: MatrixEvent | null): void => {
    logger.debug(`saveForwardMessage roomId:${roomId} forwardMessageData:${forward}`);
    // save locally
    setForwardMessageData(forward);
    // save to messageStore memory
    const messageStoreIndex = window.messageStore.findIndex((ms) => ms.roomId === roomId);
    if (messageStoreIndex >= 0) {
      window.messageStore[messageStoreIndex] = {
        ...window.messageStore[messageStoreIndex],
        forward,
      };
    } else {
      window.messageStore.push({ ...createMessageStorageDataType(roomId), forward });
    }
  };

  useEffect(() => {
    logger.debug(`useEffect roomId:${roomId}`);
    if (!window.messageStore) {
      window.messageStore = [];
    }
    const smd = getStoredMessageData();
    setSavedMessage(smd.message);
    setEditMessageData(smd.edit);
    setReplyMessageData(smd.reply);
  }, [getStoredMessageData, setSavedMessage, setEditMessageData, setReplyMessageData, logger, roomId]);

  return {
    saveMessageText,
    saveEditMessage,
    saveReplyMessage,
    saveForwardMessage,
    savedMessage,
    editMessageData,
    replyMessageData,
    forwardMessageData,
  };
};

export default useMessageStorage;
