import { Components, Theme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const MuiAlert: Components["MuiAlert"] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const palette = (theme as Theme).palette;
      return {
        ...(ownerState.severity === "info" && {
          border: `1px solid ${palette.info.main}`,
          backgroundColor: palette.mode === "light" ? palette.info.light : palette.info.dark,
          color: palette.info.contrastText,
          "& .MuiAlert-icon": {
            color: palette.mode === "light" ? palette.info.dark : palette.info.light,
          },
        }),
        ...(ownerState.severity === "success" && {
          border: `1px solid ${palette.success.main}`,
          backgroundColor: palette.mode === "light" ? palette.success.light : palette.success.dark,
          color: palette.success.contrastText,
          "& .MuiAlert-icon": {
            color: palette.mode === "light" ? palette.success.dark : palette.success.light,
          },
        }),
        ...(ownerState.severity === "warning" && {
          border: `1px solid ${palette.warning.main}`,
          backgroundColor: palette.mode === "light" ? palette.warning.light : palette.warning.dark,
          color: palette.warning.contrastText,
          "& .MuiAlert-icon": {
            color: palette.mode === "light" ? palette.warning.dark : palette.warning.light,
          },
        }),
        ...(ownerState.severity === "error" && {
          border: `1px solid ${palette.error.main}`,
          backgroundColor: palette.mode === "light" ? palette.error.light : palette.error.dark,
          color: palette.error.contrastText,
          "& .MuiAlert-icon": {
            color: palette.mode === "light" ? palette.error.dark : palette.error.light,
          },
        }),
      };
    },
    message: {
      padding: "10px 0 8px 0",
    },
  },
  defaultProps: {
    iconMapping: {
      info: <InfoOutlinedIcon />,
      success: <CheckCircleOutlineIcon />,
      warning: <ReportProblemOutlinedIcon />,
      error: <ErrorOutlineOutlinedIcon />,
    },
    style: {
      borderRadius: "4px",
    },
  },
};

export default MuiAlert;
